import React, { useEffect, useState } from "react";

import { Card, Divider, Icon, Slider, Switch, Tooltip } from "@mui/material";
import { useOptimiserMutation } from "api/mutations";
import { useParams } from "react-router-dom";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useOptimiserVersionQuery } from "api/query";

import PropTypes from "prop-types";

import XBox from "components/XBox";
import XButton from "components/XButton";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

import isEqual from "lodash/isEqual";
import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";
import SearchIcon from "assets/images/search-icon.svg";

import _ from "lodash";

export const OptimisationList = ({
  setActiveStep,
  setVersionId,
  additionalHeaders,
  setAdditionalHeaders,
}) => {
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();
  const { id } = useParams();

  const url = new URL(window.location.href);
  const versionId = url.searchParams.get("version_id");

  const [description, setDescription] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [createColumnInput, setCreateColumnInput] = useState("");
  const [optimisationData, setOptimisationData] = useState([]);
  const [configuration, setConfiguration] = useState({});
  const [currentConfiguration, setCurrentConfiguration] = useState({});
  const [oldConfiguration, setOldConfiguration] = useState({});
  const [oldDescription, setOldDescription] = useState("");
  const [allConfiguration, setAllConfiguration] = useState([]);
  const [headers, setHeaders] = useState(["Feature", "Locked", "Selectable"]);

  const { data, isLoading } = useOptimiserVersionQuery(activeWorkspace, id, versionId, logout);
  const { resetOptimiserVersion, updateOptimiserVersion } = useOptimiserMutation();

  useEffect(() => {
    if (!data) return;

    const configurations = Object.keys(data.data.data.features_bin_outline).map((item) => {
      return {
        title: item,
        isShow: false,
      };
    });

    setOptimisationData(data.data);
    setConfiguration(data.data.data.configuration);
    setOldConfiguration(data.data.data.configuration);
    setAllConfiguration(configurations);
    setDescription(data.data.description);
    setOldDescription(data.data.description);
    setAdditionalHeaders(data.data.data.targets.map((header) => header.name));
  }, [data]);

  const handleOpenBins = (e, featureKey) => {
    const currentItemIndex = allConfiguration.findIndex((item) => item.title === featureKey);

    if (currentItemIndex !== -1 && e.target.tagName !== "INPUT") {
      const updatedConfiguration = [...allConfiguration];
      updatedConfiguration[currentItemIndex] = {
        ...updatedConfiguration[currentItemIndex],
        isShow: !updatedConfiguration[currentItemIndex].isShow,
      };
      setAllConfiguration(updatedConfiguration);
    }
  };

  const handleBackButton = () => {
    setActiveStep(0);
    setVersionId(null);
  };

  const handleResetButton = () => {
    resetOptimiserVersion([activeWorkspace, id, versionId], {
      onSuccess: (data) => {
        setConfiguration({});
        setOldConfiguration(data.data.data.configuration);
      },
    });
  };

  const featureKey = "defaults";

  const isFeatureInConfiguration = configuration && configuration[featureKey];

  const isModelLocked =
    (isFeatureInConfiguration && configuration[featureKey]?.locked === true) || false;
  const isModelSelectable =
    (isFeatureInConfiguration && configuration[featureKey]?.selectable === true) || false;

  const handleModelDefaultLockedChange = () => {
    const featureKey = "defaults";
    const isFeatureInConfiguration = configuration && configuration[featureKey];

    const isModelLocked =
      (isFeatureInConfiguration && configuration[featureKey]?.locked === true) || false;

    const allFeatures = Object.keys(optimisationData.data.features_bin_outline);

    const newObject = allFeatures.reduce((result, feature) => {
      const { bins } = optimisationData.data.features_bin_outline[feature];

      result[feature] = {
        [featureKey]: {
          locked: !isModelLocked,
        },
        bins: bins.reduce((binsResult, bin) => {
          binsResult[bin] = {
            locked: !isModelLocked,
          };
          return binsResult;
        }, {}),
      };
      return result;
    }, {});

    const configurationCopy = _.cloneDeep(configuration);
    const newObjectCopy = _.cloneDeep(newObject);
    const oldConfigurationCopy = _.cloneDeep(oldConfiguration);
    const currentConfigurationCopy = _.cloneDeep(currentConfiguration);

    const firstMergedObject = _.merge(configurationCopy, newObjectCopy);
    const secondObject = _.merge(firstMergedObject, oldConfigurationCopy);
    const finalMergedObject = _.merge(secondObject, currentConfigurationCopy);

    setConfiguration((prevConfig) => {
      return {
        ...finalMergedObject,
        [featureKey]: {
          ...prevConfig[featureKey],
          locked: !isModelLocked,
        },
      };
    });
    return;
  };

  const handleModelDefaultSelectedChange = () => {
    const featureKey = "defaults";
    const isFeatureInConfiguration = configuration && configuration[featureKey];

    const isModelSelectable =
      (isFeatureInConfiguration && configuration[featureKey]?.selectable === true) || false;

    const allFeatures = Object.keys(optimisationData.data.features_bin_outline);

    const newObject = allFeatures.reduce((result, feature) => {
      const { bins } = optimisationData.data.features_bin_outline[feature];

      result[feature] = {
        [featureKey]: {
          selectable: !isModelSelectable,
        },
        bins: bins.reduce((binsResult, bin) => {
          binsResult[bin] = {
            selectable: !isModelSelectable,
          };
          return binsResult;
        }, {}),
      };
      return result;
    }, {});

    const configurationCopy = _.cloneDeep(configuration);
    const newObjectCopy = _.cloneDeep(newObject);
    const oldConfigurationCopy = _.cloneDeep(oldConfiguration);
    const currentConfigurationCopy = _.cloneDeep(currentConfiguration);

    const firstMergedObject = _.merge(configurationCopy, newObjectCopy);
    const secondObject = _.merge(firstMergedObject, oldConfigurationCopy);
    const finalMergedObject = _.merge(secondObject, currentConfigurationCopy);

    setConfiguration((prevConfig) => {
      return {
        ...finalMergedObject,
        [featureKey]: {
          ...prevConfig[featureKey],
          selectable: !isModelSelectable,
        },
      };
    });
    return;
  };

  if (isLoading) {
    return (
      <Card
        sx={{ minHeight: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoadingSpinner size={50} animationType="pulse" />
      </Card>
    );
  }

  return (
    <XBox p={4} display="flex" flexDirection="column" gap="25px">
      <XButton
        variant="outlined"
        sx={{ padding: 0, alignSelf: "start", background: "transparent", border: "none" }}
        onClick={handleBackButton}
      >
        <XBox display="flex" gap="5px" alignItems="center" sx={{ minHeight: "21px" }}>
          <Icon>arrow_back</Icon>
          <XTypography fontSize="14px" fontWeight="medium" color="secondary">
            select version
          </XTypography>
        </XBox>
      </XButton>
      <XBox display="flex" alignItems="center" justifyContent="space-between">
        <XBox>
          <XTypography fontSize="18px" sx={{ whiteSpace: "nowrap" }}>
            Optimisation Configuration
          </XTypography>
          <XTypography fontSize="16px" color="secondary" fontWeight="medium">
            Configure the optimiser
          </XTypography>
        </XBox>
      </XBox>
      <XButton
        color="button"
        variant="gradient"
        sx={{ whiteSpace: "noWrap", fontSize: "12px", minHeight: "30px" }}
      >
        <XBox src={LightPlusIcon} component="img" />
        Create From This Version
      </XButton>

      <XButton
        disabled={isEqual(configuration, oldConfiguration) && isEqual(oldDescription, description)}
        onClick={() => {
          updateOptimiserVersion(
            [
              activeWorkspace,
              id,
              versionId,
              {
                targets: additionalHeaders.map((header) => {
                  return { name: header, relative_importance: 0 };
                }),
                description: description,
                configuration: configuration,
              },
            ],
            {
              onSuccess: (data) => {
                setOldConfiguration(configuration);
                setCurrentConfiguration({});
              },
            }
          );
        }}
      >
        Save Configuration
      </XButton>
      <XInput value={description} onChange={(e) => setDescription(e.target.value)} />
      <XBox display="flex" alignItems="center">
        <XInput
          placeholder="Search Features..."
          sx={{ padding: "5px" }}
          startAdornment={<XBox component="img" src={SearchIcon} />}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <XButton
          variant="outlined"
          onClick={handleResetButton}
          sx={{ background: "transparent", border: "none" }}
        >
          <XTypography fontSize="14px" color="primary" fontWeight="medium">
            reset
          </XTypography>
        </XButton>
        <XBox sx={{ position: "relative" }}>
          <Tooltip title="Tooltip" placement="top">
            <XButton
              variant="outlined"
              color="secondary"
              size="small"
              circular
              iconOnly
              sx={{ minWidth: "17px", maxWidth: "17px", minHeight: "17px", maxHeight: "17px" }}
            >
              <Icon>question_mark</Icon>
            </XButton>
          </Tooltip>
        </XBox>
      </XBox>
      <XBox sx={{ alignSelf: "end" }} display="flex" gap="10px" alignItems="center">
        <XInput
          sx={{ maxWidth: "75px", width: "100%", padding: "3px", maxHeight: "22px" }}
          value={createColumnInput}
          onChange={(e) => {
            setCreateColumnInput(e.target.value);
          }}
        />
        <XButton
          variant="outlined"
          color="secondary"
          size="small"
          circular
          iconOnly
          sx={{ minWidth: "17px", maxWidth: "17px", minHeight: "17px", maxHeight: "17px" }}
          onClick={() => setAdditionalHeaders([...additionalHeaders, createColumnInput])}
        >
          <Icon>add</Icon>
        </XButton>
      </XBox>
      <XBox sx={{ overflowX: "auto", maxWidth: "450px" }}>
        <XBox sx={{ width: "100%" }}>
          <XBox display="flex" alignItems="space-between" gap="25px" width="100%">
            {headers.map((item, index) => (
              <XBox
                key={index}
                sx={{
                  minWidth: index === 0 ? "100px" : "75px",
                  textAlign: "start",
                  color: "#8393ab",
                }}
              >
                <XTypography width="100%" fontSize="14px" fontWeight="medium" color="secondary">
                  {item}
                </XTypography>

                {item === "Cost" && <Slider defaultValue={30} sx={{ padding: 0 }} />}
              </XBox>
            ))}
            {additionalHeaders.map((item, index) => {
              if (index === 0) {
                return (
                  <XBox key={"header-" + index} sx={{ minWidth: "75px", maxWidth: "75px" }}>
                    <XInput
                      value={additionalHeaders[0]}
                      sx={{
                        width: "100%",
                        padding: "3px",
                        maxHeight: "26px",
                      }}
                      onChange={(e) => {
                        const newHeaders = [...additionalHeaders];
                        newHeaders[0] = e.target.value;
                        setAdditionalHeaders(newHeaders);
                      }}
                    />
                  </XBox>
                );
              }
              return (
                <XBox key={index} sx={{ minWidth: "75px", color: "#8393ab" }}>
                  <XTypography
                    key={index}
                    width="100%"
                    fontSize="14px"
                    fontWeight="medium"
                    color="secondary"
                  >
                    {item}
                  </XTypography>
                </XBox>
              );
            })}
          </XBox>
          <XBox
            sx={{ padding: "10px 0" }}
            display="flex"
            alignItems="center"
            gap="25px"
            justifyContent="space-between"
          >
            <XBox sx={{ minWidth: "100px" }}>
              <XTypography fontWeight="medium" fontSize="12px" sx={{ width: "100%" }}>
                Model Defaults
              </XTypography>
            </XBox>
            <XBox sx={{ minWidth: "75px" }}>
              <Switch
                checked={isModelLocked}
                sx={{ width: "40px" }}
                onChange={handleModelDefaultLockedChange}
              />
            </XBox>
            <XBox sx={{ minWidth: "75px" }}>
              <Switch
                checked={isModelSelectable}
                sx={{ width: "40px" }}
                onChange={handleModelDefaultSelectedChange}
              />
            </XBox>

            {additionalHeaders.map((item, index) => {
              if (index === 0) {
                return (
                  <XBox key={"model-headers-" + index} sx={{ minWidth: "75px", maxWidth: "75px" }}>
                    <XInput
                      sx={{
                        width: "100%",
                        padding: "3px",
                        maxHeight: "26px",
                      }}
                      value={configuration["defaults"]?.cost || ""}
                      onChange={(e) => {
                        const result = e.target.value.replace(/\D/g, "");

                        setConfiguration((prevConfig) => ({
                          ...prevConfig,
                          ["defaults"]: {
                            ...prevConfig["defaults"],
                            cost: result,
                          },
                        }));
                      }}
                    />
                  </XBox>
                );
              }

              return (
                <XBox key={"bin-" + index} sx={{ minWidth: "75px", maxWidth: "75px" }}>
                  <XInput
                    sx={{
                      width: "100%",
                      padding: "3px",
                      maxHeight: "26px",
                    }}
                    value={configuration["defaults"]?.[item.toLowerCase()] || ""}
                    onChange={(e) => {
                      const result = e.target.value.replace(/\D/g, "");

                      setConfiguration((prevConfig) => ({
                        ...prevConfig,
                        ["defaults"]: {
                          ...prevConfig["defaults"],
                          [item.toLowerCase()]: result,
                        },
                      }));
                    }}
                  />
                </XBox>
              );
            })}
          </XBox>

          {Object.entries(optimisationData?.data?.features_bin_outline || []).map((item, index) => {
            const featureKey = item[0];

            const isFeatureInConfiguration = configuration && configuration[featureKey];
            const isShow = allConfiguration.find((item) => item.title === featureKey).isShow;

            const isLocked =
              (isFeatureInConfiguration && configuration[featureKey]?.defaults?.locked === true) ||
              false;
            const isSelectable =
              (isFeatureInConfiguration &&
                configuration[featureKey]?.defaults?.selectable === true) ||
              false;

            const handleLockedChange = () => {
              const allFeatures = Object.keys(optimisationData.data.features_bin_outline);
              const currentFeature = allFeatures.filter((item) => item === featureKey);

              const newObject = currentFeature.reduce((result, feature) => {
                const { bins } = optimisationData.data.features_bin_outline[feature];

                result[feature] = {
                  ["defaults"]: {
                    locked: !isLocked,
                  },
                  bins: bins.reduce((binsResult, bin) => {
                    binsResult[bin] = {
                      locked: !isLocked,
                    };
                    return binsResult;
                  }, {}),
                };

                return result;
              }, {});

              const configurationCopy = _.cloneDeep(configuration);
              const newObjectCopy = _.cloneDeep(newObject);
              const currentConfigurationCopy = _.cloneDeep(currentConfiguration);

              const firstMergedObject = _.merge(configurationCopy, newObjectCopy);
              const secondObject = _.merge(firstMergedObject, currentConfigurationCopy);

              if (isFeatureInConfiguration) {
                setConfiguration((prevConfig) => ({
                  ...secondObject,
                  [featureKey]: {
                    ...secondObject[featureKey],
                    defaults: {
                      ...secondObject[featureKey]?.defaults,
                      locked: !isLocked,
                    },
                  },
                }));

                setCurrentConfiguration((prevConfig) => ({
                  ...prevConfig,
                  [featureKey]: {
                    ...prevConfig[featureKey],
                    defaults: {
                      ...prevConfig[featureKey]?.defaults,
                      locked: !isLocked,
                    },
                  },
                }));
                return;
              }

              setConfiguration((prevConfig) => ({
                ...secondObject,
                [featureKey]: {
                  defaults: {
                    ...secondObject[featureKey]?.defaults,
                    locked: !isLocked,
                  },
                },
              }));
              setCurrentConfiguration((prevConfig) => ({
                ...prevConfig,
                [featureKey]: {
                  defaults: {
                    ...prevConfig[featureKey]?.defaults,
                    locked: !isLocked,
                  },
                },
              }));
            };

            const handleSelectableChange = () => {
              const allFeatures = Object.keys(optimisationData.data.features_bin_outline);
              const currentFeature = allFeatures.filter((item) => item === featureKey);

              const newObject = currentFeature.reduce((result, feature) => {
                const { bins } = optimisationData.data.features_bin_outline[feature];

                result[feature] = {
                  ["defaults"]: {
                    selectable: !isSelectable,
                  },
                  bins: bins.reduce((binsResult, bin) => {
                    binsResult[bin] = {
                      selectable: !isSelectable,
                    };
                    return binsResult;
                  }, {}),
                };

                return result;
              }, {});

              const configurationCopy = _.cloneDeep(configuration);
              const newObjectCopy = _.cloneDeep(newObject);
              const currentConfigurationCopy = _.cloneDeep(currentConfiguration);

              const firstMergedObject = _.merge(configurationCopy, newObjectCopy);
              const secondObject = _.merge(firstMergedObject, currentConfigurationCopy);

              if (isFeatureInConfiguration) {
                setConfiguration((prevConfig) => ({
                  ...secondObject,
                  [featureKey]: {
                    ...secondObject[featureKey],
                    defaults: {
                      ...secondObject[featureKey]?.defaults,
                      selectable: !isSelectable,
                    },
                  },
                }));
                setCurrentConfiguration((prevConfig) => ({
                  ...prevConfig,
                  [featureKey]: {
                    ...prevConfig[featureKey],
                    defaults: {
                      ...prevConfig[featureKey]?.defaults,
                      selectable: !isSelectable,
                    },
                  },
                }));
                return;
              }

              setConfiguration((prevConfig) => ({
                ...secondObject,
                [featureKey]: {
                  defaults: {
                    ...secondObject[featureKey]?.defaults,
                    selectable: !isSelectable,
                  },
                },
              }));

              setCurrentConfiguration((prevConfig) => ({
                ...prevConfig,
                [featureKey]: {
                  defaults: {
                    ...prevConfig[featureKey]?.defaults,
                    selectable: !isSelectable,
                  },
                },
              }));
            };

            return (
              <>
                <XBox
                  key={"feature-" + index}
                  sx={{ padding: "10px 0" }}
                  display="flex"
                  alignItems="center"
                  gap="25px"
                  justifyContent="space-between"
                  onClick={(e) => handleOpenBins(e, featureKey)}
                >
                  <XBox sx={{ minWidth: "100px" }}>
                    <XTypography fontWeight="medium" fontSize="12px" sx={{ width: "100%" }}>
                      {item[0]}
                    </XTypography>
                  </XBox>
                  <XBox sx={{ minWidth: "75px" }}>
                    <Switch
                      checked={isLocked}
                      sx={{ width: "40px" }}
                      onChange={handleLockedChange}
                    />
                  </XBox>
                  <XBox sx={{ minWidth: "75px" }}>
                    <Switch
                      checked={isSelectable}
                      sx={{ width: "40px" }}
                      onChange={handleSelectableChange}
                    />
                  </XBox>

                  {additionalHeaders.map((item, index) => {
                    if (index === 0) {
                      return (
                        <XBox
                          key={"feature-headers-" + index}
                          sx={{ minWidth: "75px", maxWidth: "75px" }}
                        >
                          <XInput
                            sx={{
                              width: "100%",
                              padding: "3px",
                              maxHeight: "26px",
                            }}
                            value={configuration[featureKey]?.defaults?.cost || ""}
                            onChange={(e) => {
                              const result = e.target.value.replace(/\D/g, "");

                              setConfiguration((prevConfig) => ({
                                ...prevConfig,
                                [featureKey]: {
                                  ...prevConfig[featureKey],
                                  defaults: {
                                    ...prevConfig[featureKey]?.defaults,
                                    cost: result,
                                  },
                                },
                              }));
                            }}
                          />
                        </XBox>
                      );
                    }

                    return (
                      <XBox key={"bin-" + index} sx={{ minWidth: "75px", maxWidth: "75px" }}>
                        <XInput
                          sx={{
                            width: "100%",
                            padding: "3px",
                            maxHeight: "26px",
                          }}
                          value={configuration[featureKey]?.defaults?.[item.toLowerCase()] || ""}
                          onChange={(e) => {
                            const result = e.target.value.replace(/\D/g, "");

                            setConfiguration((prevConfig) => ({
                              ...prevConfig,
                              [featureKey]: {
                                ...prevConfig[featureKey],
                                defaults: {
                                  ...prevConfig[featureKey]?.defaults,
                                  [item.toLowerCase()]: result,
                                },
                              },
                            }));
                          }}
                        />
                      </XBox>
                    );
                  })}
                </XBox>
                <XBox
                  sx={{
                    maxHeight: isShow ? "max-content" : "0",
                    transition: isShow ? "max-height 0.5s ease, opacity 0.5s ease" : "",
                    opacity: isShow ? 1 : 0,
                  }}
                >
                  {isShow &&
                    item[1].bins.map((bin, index) => {
                      const binKey = bin;

                      const isBinInConfiguration =
                        configuration[featureKey]?.bins && configuration[featureKey]?.bins[binKey];

                      const isBinLocked =
                        (isBinInConfiguration &&
                          configuration[featureKey]?.bins[binKey]?.locked === true) ||
                        false;
                      const isBinSelectable =
                        (isBinInConfiguration &&
                          configuration[featureKey]?.bins[binKey]?.selectable === true) ||
                        false;

                      const handleBinLockedChange = () => {
                        if (isBinInConfiguration) {
                          setConfiguration((prevConfig) => ({
                            ...prevConfig,
                            [featureKey]: {
                              ...prevConfig[featureKey],
                              bins: {
                                ...prevConfig[featureKey]?.bins,
                                [binKey]: {
                                  ...prevConfig[featureKey]?.bins?.[binKey],

                                  locked: !isBinLocked,
                                },
                              },
                            },
                          }));

                          setCurrentConfiguration((prevConfig) => ({
                            ...prevConfig,
                            [featureKey]: {
                              ...prevConfig[featureKey],
                              bins: {
                                ...prevConfig[featureKey]?.bins,
                                [binKey]: {
                                  ...prevConfig[featureKey]?.bins?.[binKey],

                                  locked: !isBinLocked,
                                },
                              },
                            },
                          }));
                          return;
                        }

                        setConfiguration((prevConfig) => ({
                          ...prevConfig,
                          [featureKey]: {
                            ...prevConfig[featureKey],
                            bins: {
                              ...prevConfig[featureKey]?.bins,
                              [binKey]: {
                                ...prevConfig[featureKey]?.bins?.[binKey],
                                locked: !isBinLocked,
                              },
                            },
                          },
                        }));

                        setCurrentConfiguration((prevConfig) => ({
                          ...prevConfig,
                          [featureKey]: {
                            ...prevConfig[featureKey],
                            bins: {
                              ...prevConfig[featureKey]?.bins,
                              [binKey]: {
                                ...prevConfig[featureKey]?.bins?.[binKey],
                                locked: !isBinLocked,
                              },
                            },
                          },
                        }));
                      };

                      const handleBinSelectableChange = () => {
                        if (isBinInConfiguration) {
                          setConfiguration((prevConfig) => ({
                            ...prevConfig,
                            [featureKey]: {
                              ...prevConfig[featureKey],
                              bins: {
                                ...prevConfig[featureKey]?.bins,
                                [binKey]: {
                                  ...prevConfig[featureKey]?.bins?.[binKey],
                                  selectable: !isBinSelectable,
                                },
                              },
                            },
                          }));

                          setCurrentConfiguration((prevConfig) => ({
                            ...prevConfig,
                            [featureKey]: {
                              ...prevConfig[featureKey],
                              bins: {
                                ...prevConfig[featureKey]?.bins,
                                [binKey]: {
                                  ...prevConfig[featureKey]?.bins?.[binKey],
                                  selectable: !isBinSelectable,
                                },
                              },
                            },
                          }));
                          return;
                        }

                        setConfiguration((prevConfig) => ({
                          ...prevConfig,
                          [featureKey]: {
                            ...prevConfig[featureKey],
                            bins: {
                              ...prevConfig[featureKey]?.bins,
                              [binKey]: {
                                ...prevConfig[featureKey]?.bins?.[binKey],
                                selectable: !isBinSelectable,
                              },
                            },
                          },
                        }));

                        setCurrentConfiguration((prevConfig) => ({
                          ...prevConfig,
                          [featureKey]: {
                            ...prevConfig[featureKey],
                            bins: {
                              ...prevConfig[featureKey]?.bins,
                              [binKey]: {
                                ...prevConfig[featureKey]?.bins?.[binKey],
                                selectable: !isBinSelectable,
                              },
                            },
                          },
                        }));
                      };

                      return (
                        <>
                          <XBox
                            key={"bins-" + index}
                            sx={{
                              padding: "5px 20px",
                            }}
                            display="flex"
                            alignItems="center"
                            gap="25px"
                          >
                            <XTypography
                              fontWeight="medium"
                              fontSize="12px"
                              sx={{ minWidth: "80px", width: "100%" }}
                            >
                              {bin}
                            </XTypography>
                            <XBox sx={{ minWidth: "75px" }}>
                              <Switch
                                checked={isBinLocked}
                                sx={{ width: "40px" }}
                                onChange={handleBinLockedChange}
                              />
                            </XBox>
                            <XBox sx={{ minWidth: "75px" }}>
                              <Switch
                                checked={isBinSelectable}
                                sx={{ width: "40px" }}
                                onChange={handleBinSelectableChange}
                              />
                            </XBox>
                            {additionalHeaders.map((item, index) => {
                              if (index === 0) {
                                return (
                                  <XBox
                                    key={"bin-headers-" + index}
                                    sx={{ minWidth: "75px", maxWidth: "75px" }}
                                  >
                                    <XInput
                                      sx={{
                                        maxWidth: "100px",
                                        width: "100%",
                                        padding: "3px",
                                        maxHeight: "26px",
                                      }}
                                      value={configuration[featureKey]?.bins?.[binKey]?.cost || ""}
                                      onChange={(e) => {
                                        const result = e.target.value.replace(/\D/g, "");

                                        setConfiguration((prevConfig) => ({
                                          ...prevConfig,
                                          [featureKey]: {
                                            ...prevConfig[featureKey],
                                            bins: {
                                              ...prevConfig[featureKey]?.bins,
                                              [binKey]: {
                                                ...prevConfig[featureKey]?.bins?.[binKey],
                                                cost: result,
                                              },
                                            },
                                          },
                                        }));
                                      }}
                                    />
                                  </XBox>
                                );
                              }

                              return (
                                <XBox
                                  key={"bins-additional" + index}
                                  sx={{ minWidth: "75px", maxWidth: "75px" }}
                                >
                                  <XInput
                                    sx={{
                                      maxWidth: "100px",
                                      width: "100%",
                                      padding: "3px",
                                      maxHeight: "26px",
                                    }}
                                    value={
                                      configuration[featureKey]?.bins?.[binKey]?.[
                                        item.toLowerCase()
                                      ] || ""
                                    }
                                    onChange={(e) => {
                                      const result = e.target.value.replace(/\D/g, "");

                                      setConfiguration((prevConfig) => ({
                                        ...prevConfig,
                                        [featureKey]: {
                                          ...prevConfig[featureKey],
                                          bins: {
                                            ...prevConfig[featureKey]?.bins,
                                            [binKey]: {
                                              ...prevConfig[featureKey]?.bins?.[binKey],
                                              [item.toLowerCase()]: result,
                                            },
                                          },
                                        },
                                      }));
                                    }}
                                  />
                                </XBox>
                              );
                            })}
                          </XBox>
                          {item[1].bins.length !== index + 1 && <Divider sx={{ margin: "5px" }} />}
                        </>
                      );
                    })}
                </XBox>
                <Divider sx={{ margin: "10px" }} />
              </>
            );
          })}
        </XBox>
      </XBox>
    </XBox>
  );
};

OptimisationList.propTypes = {
  setActiveStep: PropTypes.func,
  setVersionId: PropTypes.func,
  additionalHeaders: PropTypes.array,
  setAdditionalHeaders: PropTypes.func,
};
