import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useRequestMutation = () => {
  const { mutate: sendRequestMutation } = useMutation((params) =>
    apiHandlers.request.sendRequest(...params)
  );

  return {
    sendRequestMutation,
  };
};
