import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import XTypography from "components/XTypography";
import XBox from "components/XBox";
import XInput from "components/XInput";
import XButton from "components/XButton";

import { useXplainableController } from "context";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Grid, Icon, MenuItem, Modal, Select, Tooltip } from "@mui/material";
import { TeamTable } from "../admin/components/TeamTable";
import { useAdmin } from "hooks";
import { useTeamsMutation } from "api/mutations";

import colors from "assets/theme/base/colors";
import { Users } from "../users";
import { useTeamsQuery, useTeamUsersQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";

export const TeamUsers = ({ organisationId }) => {
  const { organisation, setTeam, setTeamData, teamData } = useAdmin();
  const { workspaces } = useApiKey();
  const { logout } = useAuth0();

  // Fetch the teams data
  const { data } = useTeamsQuery({ organisationId, logout });
  const { data: teamUsersData } = useTeamUsersQuery({ organisationId, logout });

  const inputRef = useRef(null);
  const descRef = useRef(null);

  const [controller] = useXplainableController();
  const { createTeamMutation } = useTeamsMutation();
  const [isAdmin, setIsAdmin] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("person");
  const [tableRows, setTableRows] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const handleTeamClick = (rowData) => {
    // Get the row data from the teams table
    const teamData = rowData.original;

    // Set the selected team
    setTeam(teamData);

    // Extract the team ID
    const teamId = teamData.id;

    // Filter users who belong to the selected team
    const filtered = teamUsersData?.data?.filter((user) => {
      return user.teams.some((team) => team.id === teamId);
    });

    // Update the filtered users state
    setFilteredUsers(filtered);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onAdd = useCallback(() => {
    // Add an ascending ID and the organisation name and expiry days
    const item = {
      name: inputRef.current.value,
      team_description: descRef.current.value,
      display_icon: selectedIcon,
    };

    createTeamMutation([organisation, JSON.stringify(item)], {
      onSuccess: (data) => {
        // Add the id to render the table
        item["team_id"] = data.data.team_id;

        // Update the state of the table
        const newState = [...tableRows];
        newState.push(item);
        setTableRows(newState);

        // Reset the values
        inputRef.current.value = null;
        descRef.current.value = null;
        handleClose();
        window.location.reload();
      },
      onError: (err) => {
        console.log(err);
        handleClose();
      },
    });
  }, [tableRows, createTeamMutation, organisation]);

  useEffect(() => {
    setTeamData(data?.data || []);
  }, [data]);

  useEffect(() => {
    setTableRows(teamData);
  }, [teamData]);

  useEffect(() => {
    if (organisation && workspaces) {
      const currentOrganisation = workspaces.find(
        (item) => item?.organisation_id === organisation?.organisation_id
      );

      setIsAdmin(currentOrganisation?.is_admin);
    }
  }, [organisation, workspaces]);

    // **Added useEffect to set filteredUsers on load**
    useEffect(() => {
        // Set filteredUsers to all users when teamUsersData changes
        setFilteredUsers(teamUsersData?.data || []);
      }, [teamUsersData]);

  const icons = [
    "schedule",
    "timeline",
    "person",
    "group",
    "person_outline",
    "assessment",
    "scatter_plot",
    "insights",
    "build",
    "tune",
    "engineering",
    "chat",
    "contact_mail",
    "message",
    "description",
    "cloud_queue",
    "palette",
    "create",
    "brush",
    "style",
  ];

  return (
    <XBox>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: controller.darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <XTypography fontSize="16px" fontWeight="bold">
              Add New Team
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller.darkMode ? white.main : dark.main,
                stroke: controller.darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>
          <Grid item xs={12}>
            <XBox>
              <Grid container>
                <Grid item xs={12} md={12} >
                <XTypography variant="overline">Team Name</XTypography>
                  <XInput
                    placeholder="Team name..."
                    id="team-name"
                    name="team-name"
                    label="team-name"
                    inputRef={inputRef}
                  />
                <XTypography variant="overline">Team Description</XTypography>
                  <XInput
                    type="description"
                    id="description"
                    label="logo"
                    name="description"
                    placeholder={"This is a team description..."}
                    inputRef={descRef}
                    bgColor="dark"
                  />
                  <XTypography variant="overline">Team Icon</XTypography>
                  <XBox display="flex" flexDirection="column">
                    <XBox display="flex">
                      <XBox width="100%">
                        <Select
                          value={selectedIcon}
                          onChange={(event) => setSelectedIcon(event.target.value)}
                          sx={{ height: 200, overflow: "auto" }}
                        >
                          {icons.map((name) => (
                            <MenuItem key={name} value={name}>
                              <XBox display="flex" sx={{ alignItems: "center" }}>
                                <Icon>{name}</Icon>
                                <XBox ml={1}>{name}</XBox>
                              </XBox>
                            </MenuItem>
                          ))}
                        </Select>
                      </XBox>
                    </XBox>
                  </XBox>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <XBox display="flex" width="100%" justifyContent="flex-end">
                    <Tooltip title={organisation ? "" : "Select an organisation to add a team"}>
                      <span>
                        <XButton
                          type="submit"
                          variant="gradient"
                          color="button"
                          disabled={!organisation}
                          size="medium"
                          onClick={onAdd}
                        >
                          Add
                        </XButton>
                      </span>
                    </Tooltip>
                  </XBox>
                </Grid>
              </Grid>
            </XBox>
          </Grid>
        </XBox>
      </Modal>

      <Grid container>
        <Grid item xs={12}>
          <XBox display="flex" justifyContent="space-between" alignItems="center">
            <XTypography textTransform="capitalize" variant="h5">
              Teams
            </XTypography>
            <XBox display="flex" gap={2} alignItems="center">
              <XButton variant="gradient" color="button" onClick={() => setIsModalOpen(true)}>
                Add New Team
              </XButton>
            </XBox>
          </XBox>
        </Grid>
        <Grid item xs={12}>
          <XBox pt={3}>
            <TeamTable
              data={teamData || []}
              organisationData={organisation}
              handleRowClick={handleTeamClick}
              isAdmin={isAdmin}
              tableRows={tableRows}
              setTableRows={setTableRows}
            />
          </XBox>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Users teamUsersData={filteredUsers} />
      </Grid>
    </XBox>
  );
};

TeamUsers.propTypes = {
  organisationId: PropTypes.string,
};
