import React, { useState } from "react";
import PropTypes from "prop-types";

import { Card } from "@mui/material";
import { OptimisationList, OptimisationsVersions } from "./components";

export const OptimisationConfiguration = ({
  setVersionId,
  additionalHeaders,
  setAdditionalHeaders,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Card pt={3} height={"100%"} mt={10} pb={{ xs: 0, lg: 10 }}>
      {activeStep === 0 && (
        <OptimisationsVersions setActiveStep={setActiveStep} setVersionId={setVersionId} />
      )}
      {activeStep === 1 && (
        <OptimisationList
          setActiveStep={setActiveStep}
          setVersionId={setVersionId}
          additionalHeaders={additionalHeaders}
          setAdditionalHeaders={setAdditionalHeaders}
        />
      )}
    </Card>
  );
};

OptimisationConfiguration.propTypes = {
  setVersionId: PropTypes.func,
  additionalHeaders: PropTypes.array,
  setAdditionalHeaders: PropTypes.func,
};
