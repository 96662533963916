// CreateReportModal.jsx
import React from 'react';
import { Modal, Icon } from '@mui/material';
import XBox from 'components/XBox';
import XTypography from 'components/XTypography';
import XButton from 'components/XButton';
import XSelect from 'components/XSelect';
import XInput from 'components/XInput';
import colors from 'assets/theme/base/colors';

import PropTypes from "prop-types";

function CreateReportModal({ 
    isModalOpen, 
    handleClose, 
    handleModelChange, 
    handleCreateReport, 
    selectedModel,
    models, 
    reportInfo, 
    setReportInfo, 
    controller,
    modalTitle = "New report"
}) {

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="create-report-title"
      aria-describedby="create-report-description"
    >
      <XBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -60%)",
          width: 600,
          background: controller.darkMode ? colors.background.dark : colors.background.default,
          borderRadius: "16px",
          padding: 3,
        }}
      >
        <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <XTypography fontSize="16px" fontWeight="bold">
            {modalTitle}
          </XTypography>
          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              color: controller.darkMode ? white.main : dark.main,
              stroke: controller.darkMode ? white.main : dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
            })}
            onClick={handleClose}
          >
            close
          </Icon>
        </XBox>
        <XBox display="flex" flexDirection="column" gap={1} mt={2}>
          <XTypography variant="button" fontWeight="light">
            Model
          </XTypography>
          <XSelect
            value={selectedModel}
            onChange={handleModelChange}
            fullWidth
            placeholder="Select Model"
            options={models.map((model) => ({
              value: model.model_id,
              label: model.model_name,
            }))}
          />
          <XTypography variant="button" fontWeight="light">
            Name
          </XTypography>
          <XInput
            placeholder="Report"
            value={reportInfo.name}
            onChange={(e) => setReportInfo({ ...reportInfo, name: e.target.value })}
          />

          <XTypography variant="button" fontWeight="light">
            Description
          </XTypography>
          <XInput
            placeholder="Report"
            value={reportInfo.description}
            onChange={(e) => setReportInfo({ ...reportInfo, description: e.target.value })}
          />

          <XBox mt={2} sx={{ alignSelf: "end" }}>
            <XButton
              variant="gradient"
              color="button"
              onClick={handleCreateReport}
              disabled={!selectedModel}
            >
              Create
            </XButton>
          </XBox>
        </XBox>
      </XBox>
    </Modal>
  );
}

export default CreateReportModal;

CreateReportModal.propTypes = {
    isModalOpen: PropTypes.bool,
    modalTitle: PropTypes.string,
    handleClose: PropTypes.func,
    handleModelChange: PropTypes.func,
    handleCreateReport: PropTypes.func,
    selectedModel: PropTypes.string,
    models: PropTypes.array,
    reportInfo: PropTypes.object,
    setReportInfo: PropTypes.func,
    controller: PropTypes.object,
};
