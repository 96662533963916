import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const usePredictionQuery = (
  deployment_key,
  payload,
  setResponse,
  setBaseValue,
  setFieldValues,
  reformatData,
  logout
) => {
  return useQuery(
    [QUERY_CONSTANTS.PREDICTIONS],
    () => apiHandlers.deployments.getPrediction(deployment_key),
    {
      onSuccess: (fetchedPrediction) => {
        console.log("The fetched prediction is", fetchedPrediction[0]);
        setResponse(fetchedPrediction[0]);
        setBaseValue(fetchedPrediction[0].breakdown.base_value);

        // Reformat the data for waterfall chart
        const reformattedData = reformatData(JSON.parse(payload), fetchedPrediction[0]);
        console.log("The reformatted data is", reformattedData);

        // Update the field values
        setFieldValues(reformattedData);
      },
      onError: (error) => {
        console.error("Error fetching deployments:", error);
        handleTokenExpired(error, logout);
      },
    }
  );
};
