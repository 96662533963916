// @mui material components
import Tooltip from "@mui/material/Tooltip";
import PropTypes from 'prop-types'

// xplainable Dashboard components
import XBox from "components/XBox";
import XAvatar from "components/XAvatar";
function Members({ members, size = "sm", numMembers = 5 }) {

  const avatarStyles = {
    border: ({ borders: { borderWidth }, palette: { white } }) =>
      `${borderWidth[2]} solid ${white.main}`,
    cursor: "pointer",
    position: "relative",
    ml: -2,
    "&:hover, &:focus": {
      zIndex: "10",
    },
  };

  if (!members) {
    return (
      <XBox display="flex">
        <XAvatar alt="no-members" bgColor="dark" size={size} sx={avatarStyles}>
          -
        </XAvatar>
      </XBox>
    );
  }

  const displayedMembers = members.slice(0, numMembers);
  const remainingMembersCount = members.length - numMembers;

  return (
    <XBox display="flex">
      {displayedMembers.map(member => (
        <Tooltip title={`${member.given_name} ${member.family_name}`} placement="top" key={member.user_id}>
          <XAvatar src={member.image} alt={`team-${member.user_id}`} bgColor="light" size={size} sx={avatarStyles} />
        </Tooltip>
      ))}

      {remainingMembersCount > 0 && (
        <Tooltip title={`${remainingMembersCount} more`} placement="top">
          <XAvatar alt="more-members" bgColor="xpblue" size={size} sx={avatarStyles}>
            +{remainingMembersCount}
          </XAvatar>
        </Tooltip>
      )}
    </XBox>
  );
}

export default Members;

Members.propTypes = {
  members: PropTypes.array,
  size: PropTypes.string,
  numMembers: PropTypes.number
};


