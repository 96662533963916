// usePreprocessorsQueries.js
import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

/**
 * Hook to fetch all preprocessors.
 */
export const useAllPreprocessorsQuery = (activeWorkspace, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.ALL_PREPROCESSORS, activeWorkspace],
    () => apiHandlers.preprocessors.getAllPreprocessors(activeWorkspace),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};

/**
 * Hook to fetch a specific preprocessor.
 */
export const usePreprocessorQuery = (
  version_id,
  logout
) => {
  return useQuery(
    [QUERY_CONSTANTS.PREPROCESSORS, version_id],
    () =>
      apiHandlers.preprocessors.getPreprocessors(version_id),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};

/**
 * Hook to fetch a specific preprocessor version.
 */
export const usePreprocessorVersionQuery = (activeWorkspace, preprocessor_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.PREPROCESSOR_VERSION, activeWorkspace, preprocessor_id],
    () => apiHandlers.preprocessors.getPreprocessorVersion(activeWorkspace, preprocessor_id),
    {
      enabled: !!preprocessor_id,
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};

/**
 * Hook to fetch a preprocessor sample.
 */
export const usePreprocessorSampleQuery = (activeWorkspace, preprocessor_id, version_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.PREPROCESSOR_SAMPLE, activeWorkspace, preprocessor_id, version_id],
    () => apiHandlers.preprocessors.getPreprocessorSample(activeWorkspace, preprocessor_id, version_id),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};

/**
 * Hook to fetch a preprocessor sample.
 */
export const useRelevantPreprocessorQuery = (model_version_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.PREPROCESSOR_SAMPLE, model_version_id],
    () => apiHandlers.preprocessors.getRelevantPreprocessors(model_version_id),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
