import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const Shimmer = ({ children, shape = 'rectangular', width, height, sx = {} }) => {
    const baseStyle = {
        width: width,
        height: height,
        background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
        backgroundSize: '200% 100%',
        animation: 'shimmer 3s infinite linear',
        '@keyframes shimmer': {
            '0%': {
                backgroundPosition: '-200% 0',
            },
            '100%': {
                backgroundPosition: '200% 0',
            },
        },
    };

    const mask = shape === 'circular' ? { borderRadius: '50%' } : {};

    return (
        <Box sx={{ ...baseStyle, ...mask, ...sx }}>
            {children}
        </Box>
    );
};

Shimmer.propTypes = {
    children: PropTypes.node.isRequired,
    shape: PropTypes.oneOf(['rectangular', 'circular']),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sx: PropTypes.object,
};

export default Shimmer;

