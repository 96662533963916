import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useDeploymentsQuery = (
  activeWorkspace,
  dropdowns,
  isCardViewEnable,
  logout
) => {
  return useQuery(
    [QUERY_CONSTANTS.DEPLOYMENTS, activeWorkspace, dropdowns, isCardViewEnable],
    () => apiHandlers.deployments.getDeployments(activeWorkspace),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
