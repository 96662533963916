import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useProjectsQuery = (activeWorkspace, logout, options = {}) => {
  return useQuery(
    [QUERY_CONSTANTS.MODELS, activeWorkspace?.organisation_id, activeWorkspace?.team_id],
    () => apiHandlers.models.getModels(activeWorkspace),
    {
      enabled: !!activeWorkspace?.organisation_id && !!activeWorkspace?.team_id,
      onError: (err) => {
        handleTokenExpired(err, logout);
        console.error("Error fetching projects:", err);
      },
      cacheTime: 0,
      retry: 3,
      ...options
    }
  );
}; 