// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "examples/Sidenav/styles/sidenavCollapse";

// xplainable dashboard context
import { useXplainableController } from "context";
import XBadge from "components/XBadge";

function SidenavCollapse({
  activeIcon,
  unActiveIcon,
  name,
  children,
  active,
  noCollapse,
  open,
  isBeta,
  ...rest
}) {
  const [controller] = useXplainableController();
  const { miniSidenav, darkSidenav, sidenavColor } = controller;

  const getIcon = () => {
    if (darkSidenav && active) {
      return unActiveIcon;
    }

    if (darkSidenav && !active) {
      return activeIcon;
    }

    if (!darkSidenav && active) {
      return activeIcon;
    }

    return unActiveIcon;
  };

  return (
    <>
      <ListItem component="li" sx={{ mb: "9px" }}>
        <XBox
          {...rest}
          sx={(theme) => collapseItem(theme, { active, darkSidenav, sidenavColor, miniSidenav })}
        >
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { active, darkSidenav, sidenavColor })}
          >
            <XBox component="img" src={getIcon()} width={18} />
          </ListItemIcon>
          <ListItemText
            primary={name}
            sx={(theme) => collapseText(theme, { miniSidenav, darkSidenav, active })}
          />
          {isBeta && (
            <XBadge color="xppurple" badgeContent="Beta" size="sm" container variant="contained" />
          )}

          <Icon
            sx={(theme) => collapseArrow(theme, { noCollapse, darkSidenav, miniSidenav, open })}
          >
            expand_less
          </Icon>
        </XBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  // color: "info",
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  // color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  activeIcon: PropTypes.string,
  unActiveIcon: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
  isBeta: PropTypes.bool,
};

export default SidenavCollapse;
