import React, { useCallback, useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Grid, Card, Tooltip, Icon } from "@mui/material";
import { useToast } from "hooks";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XBadge from "components/XBadge";
import XAvatar from "components/XAvatar";
import XSnackbar from "components/XSnackbar";

import DataTable from "shared/Tables/DataTable";

// xplainable dashboard base styles
import breakpoints from "assets/theme/base/breakpoints";

//Helper Functions
import { getTimeDifference } from "shared/Functions/Date";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

import { ReactComponent as SuccessStatusIcon } from "assets/images/icons/admin/success-status.svg";
import { ReactComponent as RejectStatusIcon } from "assets/images/icons/admin/reject-status.svg";

import TableCellComponent from "./components/TableCellComponent";

function UserTable({
  tableRows,
  setTableRows,
  entriesPerPage = 5,
  isAdmin,
  setIsAdmin,
  messageContent,
  show,
  toggleSnackbar,
}) {
  const { activeWorkspace, workspaces } = useApiKey();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  console.log(activeWorkspace);
  console.log(workspaces);

  useEffect(() => {
    if (workspaces) {

      const organisation = workspaces.find(
        (item) => item?.organisation_id === activeWorkspace?.organisation_id
      );

      setIsAdmin(organisation?.is_admin);
    }
  }, [workspaces]);

  // Debounced search  function
  const onRemove = useCallback(
    (index) => {
      const newState = [...tableRows];

      newState.splice(index, 1);
      setTableRows(newState);
    },
    [tableRows]
  );

  const columns = [
    {
      Header: "",
      width: "5%",
      id: "function",
      accessor: (data) => {
        // console.log("Accessor Data:", data);
        return {
          user_id: data.id,
          org_admin: data.org_admin,
          role_name: data.role_name,
          status: data.status,
          invite_id: data.invite_id,
        };
      },
      Cell: (props) => { 
        return (
          <TableCellComponent
            {...props}
            rowIndex={props.row.index}
            user_id={props?.value?.user_id}
            org_admin={props?.value?.org_admin}
            role_name={props?.value?.role_name}
            status={props?.value?.status}
            invite_id={props?.value?.invite_id}
            onRemove={onRemove}
            disabled={!isAdmin}
          />
        );
      },
    },
    {
      Header: "User",
      id: "user",
      accessor: (data) => ({
        profile_image_url: data?.profile_image_url,
        given_name: data?.personal_details?.given_name,
        family_name: data?.personal_details?.family_name,
        position: data?.position,
      }),
      Cell: ({ value }) => (
        <Author
          image={value?.profile_image_url}
          given_name={value.given_name}
          family_name={value?.family_name}
          position={value?.position}
        />
      ),
    },
    {
      Header: "Username",
      accessor: "username",
      Cell: ({ value }) => value,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => value,
    }, 
    {
      Header: "Teams",
      accessor: "teams",
      Cell: ({ value }) => {
        // Determine if there's only one item
        const isSingleItem = value?.length === 1;
    
        // Styles for the outer container
        const containerStyle = {
          height: '60px',
          minWidth: '170px', // Add minWidth as desired
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isSingleItem ? 'center' : 'flex-start', // Center if only one item
          alignItems: 'stretch', // Ensure items take full width
          overflowY: 'auto',
        };
    
        // Styles for each item
        const itemStyle = {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '150px', // Adjust as needed
          padding: '4px 0', // Optional padding between items
        };
    
        return (
          <div style={containerStyle}>
            {value?.map((currentValue, index) => (
              <div key={index} style={itemStyle}>
                <span style={{ lineHeight: 1.5, color: "#67748e" }}>
                  {currentValue?.name}
                </span>
                <Tooltip title={currentValue?.active ? "Success" : "Pending"}>
                  {currentValue?.active ? (
                    <SuccessStatusIcon fontSize="small" aria-label="Active" />
                  ) : (
                    <RejectStatusIcon fontSize="small" aria-label="Pending" />
                  )}
                </Tooltip>
              </div>
            ))}
          </div>
        );
      },
    },
    
    {
      Header: "Joined",
      accessor: "created",
      Cell: ({ value }) => (value === null ? "" : getTimeDifference(value, true)),
    },
  ];

  //Use effect to vertically sort the dropdown on page break
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  // we need this so the table updates after the timeout expires

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Card>
          <DataTable
            handleRowClick={null}
            table={{ rows: tableRows, columns: columns }}
            entriesPerPage={{
              defaultValue: entriesPerPage,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            canSearch
          />
        </Card>
      </Grid>

      <XSnackbar
        color="success"
        icon="check"
        title="The user has been invited!"
        content={messageContent}
        open={show}
        close={toggleSnackbar}
      />
    </Grid>
  );
}

export default React.memo(UserTable);

function Author({ image, given_name, family_name, position }) {
  return (
    <XBox display="flex" alignItems="center" px={1} py={0.5}>
      <XBox mr={2}>
        <XAvatar src={image} alt={name} size="sm" />
      </XBox>
      <XBox display="flex" flexDirection="column">
        <XTypography variant="button" fontWeight="medium">
          {(given_name || family_name) === null ? "" : given_name + " " + family_name}
        </XTypography>
        <XTypography variant="caption" color="secondary">
          {position ? position : ""}
        </XTypography>
      </XBox>
    </XBox>
  );
}

function Function({ job, org }) {
  return (
    <XBox display="flex" flexDirection="column">
      <XTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </XTypography>
      <XTypography variant="caption" color="secondary">
        {org}
      </XTypography>
    </XBox>
  );
}

// Render the badge content
const renderBadge = (badgeContent) => {
  let color;
  switch (badgeContent) {
    case "admin":
      color = "success";
      break;
    case "creator":
      color = "xppink";
      break;
    case "viewer":
      color = "xpblue";
      break;
    default:
      return null; // Or provide a default badge if needed
  }

  return <XBadge color={color} variant="contained" size="xs" badgeContent={badgeContent} />;
};

Author.propTypes = {
  given_name: PropTypes.string.isRequired,
  family_name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

Function.propTypes = {
  job: PropTypes.string.isRequired,
  org: PropTypes.string.isRequired,
};

UserTable.propTypes = {
  data: PropTypes.object,
  teamData: PropTypes.object,
  organisationId: PropTypes.string,
  handleRowClick: PropTypes.func,
  value: PropTypes.object,
  row: PropTypes.object,
  entriesPerPage: PropTypes.number,
  isAdmin: PropTypes.bool,
  setIsAdmin: PropTypes.func,
  tableRows: PropTypes.array,
  setTableRows: PropTypes.func,
  messageContent: PropTypes.string,
  show: PropTypes.bool,
  toggleSnackbar: PropTypes.func,
};
