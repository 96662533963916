import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import { hexbin } from "d3-hexbin";
import { useXplainableController } from "context";
import colors from "assets/theme/base/colors";

const RegressionChart = (props) => {
  const { data, yLabel, xLabel, pixels, min, max } = props;

  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const t = d3.transition().duration(700);
  const margin = { top: 20, right: 20, bottom: 40, left: 50 };
  const svgContainer = useRef(null);
  const [width, setWidth] = useState(400 - margin.left - margin.right);
  const height = 450 - margin.top - margin.bottom;

  // Function to handle resizing of SVG Container
  const getSvgContainerSize = () => {
    if (svgContainer.current) {
      const newWidth = svgContainer.current.clientWidth - margin.left - margin.right;
      setWidth(newWidth);
    }
  };

  // Event listener for window resize
  useEffect(() => {
    getSvgContainerSize();
    window.addEventListener("resize", getSvgContainerSize);
    return () => window.removeEventListener("resize", getSvgContainerSize);
  }, []);

  const hexbinRef = useRef(null);
  const xAxisRef = useRef(null);
  const yAxisRef = useRef(null);

  const origin = Math.min(min || Math.min(...data.true), min || Math.min(...data.prediction));

  const maxVal = Math.max(max || Math.max(...data.true), max || Math.max(...data.prediction));

  const xScale = d3.scaleLinear().domain([origin, maxVal]).range([0, width]).nice();

  const yScale = d3.scaleLinear().domain([maxVal, origin]).range([0, height]).nice();

  // Process Data
  const processData = (rawData) => {
    const labels = rawData.true;
    const values = rawData.prediction;
    return labels.map((label, index) => ({
      label: label,
      value: values[index],
    }));
  };

  const processedData = processData(data);

  useEffect(() => {
    const node = hexbinRef.current;
    d3.select(node).selectAll("*").remove();

    let inputForHexbinFun = [];
    processedData.forEach((d) => {
      inputForHexbinFun.push([xScale(d.label), yScale(d.value)]);
    });

    let color = d3.scaleLinear().domain([0, 10]).range(["transparent", "#2664eb"]);

    let hexbinPlot = hexbin()
      .radius((1 / pixels) * 200)
      .extent([
        [0, 0],
        [width, height],
      ]);

    d3.select(node)
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", width)
      .attr("height", height);

    d3.select(node)
      .append("g")
      .attr("clip-path", "url(#clip)")
      .selectAll("path")
      .data(hexbinPlot(inputForHexbinFun))
      .enter()
      .append("path")
      .attr("d", hexbinPlot.hexagon())
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      })
      .attr("fill", function (d) {
        return color(d.length);
      })
      .attr("stroke", "white")
      .attr("stroke-width", "0.1");

    // After plotting hexbins, add the diagonal line
    d3.select(node)
      .append("line")
      .attr("x1", xScale(origin))
      .attr("y1", yScale(origin))
      .attr("x2", xScale(maxVal))
      .attr("y2", yScale(maxVal))
      .attr("stroke", "#aaaaaa") // You can choose any color you want
      .attr("stroke-width", "1px")
      .attr("stroke-dasharray", "5,5"); // This makes it a dashed line. Remove this line if you want a solid line.
  }, [data, pixels, xScale, yScale, width, height]);

  // X-Axis
  useEffect(() => {
    const node = xAxisRef.current;
    d3.select(node).call(d3.axisBottom(xScale)).selectAll("path, line, text");
    // .style("stroke", darkMode ? colors.dark.main : colors.black.main);
  }, [xScale]);

  // Y-Axis
  useEffect(() => {
    const node = yAxisRef.current;
    d3.select(node).call(d3.axisLeft(yScale)).selectAll("path, line, text");
    // .style("stroke", darkMode ? colors.dark.main : colors.black.main);

    d3.select(svgContainer.current)
      .selectAll("text")
      .style("fill", darkMode ? colors.white.main : colors.black.main);
    d3.select(svgContainer.current)
      .selectAll(".domain")
      .style("stroke", darkMode ? colors.white.main : colors.black.main);
  }, [yScale]);

  return (
    <div ref={svgContainer}>
      <div className="chart">
        <svg
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
        >
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            {xLabel && (
              <text
                transform={`translate(${width - margin.right}, ${height - 10})`}
                style={{
                  textAnchor: "middle",
                  fontSize: "14px",
                  fill: darkMode ? colors.dark.main : colors.black.main,
                }}
              >
                {"Actual"}
              </text>
            )}
            {yLabel && (
              <text
                transform={`translate(${20}, ${margin.top + 10}), rotate(-90)`}
                style={{
                  textAnchor: "middle",
                  fontSize: "14px",
                  fill: darkMode ? colors.dark.main : colors.black.main,
                }}
              >
                {"Predicted"}
              </text>
            )}
            <g ref={xAxisRef} transform={`translate(0, ${height})`}></g>
            <g ref={yAxisRef}></g>
            <g ref={hexbinRef} className="hexbin-group"></g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default RegressionChart;

RegressionChart.propTypes = {
  parentWidth: PropTypes.number.isRequired,
  parentHeight: PropTypes.number.isRequired,
  title: PropTypes.string,
  data: PropTypes.shape({
    true: PropTypes.arrayOf(PropTypes.number).isRequired,
    prediction: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  yLabel: PropTypes.string,
  xLabel: PropTypes.string,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }).isRequired,
  pixels: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};
