import PropTypes from "prop-types";
import { useXplainableController } from "context";
import { useSubscriptionMutation } from "api/mutations/useSubscriptionMutation";
import { MinimalistButton } from "layouts/autotrain/styled";
import Card from "@mui/material/Card";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

import normalLogo from "assets/images/xplainable-logo.svg";
import whiteLogo from "assets/images/xplainable-logo-white.svg";
import freeImage from "assets/images/subscription-free-png.png";
import premiumImage from "assets/images/subscription-premium-png.png";
import enterpriseImage from "assets/images/subscription-enterprise-png.png";
import XButton from "components/XButton";

function SubscriptionCard({ type, expires, isTrialExpired, onAction }) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  console.log("The type is", type)
  console.log("The expiry date is", expires)
  console.log("Is trial expired", isTrialExpired)

  let mainOpacity,
    secondaryOpacity,
    mainColor,
    secondaryColor,
    image,
    headerColor,
    textColor,
    subscriptionColor,
    logoType;

  switch (type) {
    case "free":
      mainOpacity = 0.2;
      secondaryOpacity = 0;
      mainColor = "light";
      secondaryColor = "light";
      image = freeImage;
      headerColor = "secondary";
      textColor = "secondary";
      subscriptionColor = "black";
      logoType = normalLogo;
      break;

      case "trial":
        if (isTrialExpired) {
          mainOpacity = 0.2;
          secondaryOpacity = 0;
          mainColor = "light";
          secondaryColor = "light";
          image = freeImage;
          headerColor = "secondary";
          textColor = "secondary";
          subscriptionColor = "black";
          logoType = normalLogo;
        } else {
          mainOpacity = 0.1;
          secondaryOpacity = 0.1;
          mainColor = "xppink";
          secondaryColor = "xppink";
          image = premiumImage;
          headerColor = "secondary";
          textColor = "pink";
          subscriptionColor = "xppink";
          logoType = normalLogo;
        }
        break;
    
    case "software":
      mainOpacity = 0.1;
      secondaryOpacity = 0.1;
      mainColor = "xppink";
      secondaryColor = "xppink";
      image = premiumImage;
      headerColor = "secondary";
      textColor = "pink";
      subscriptionColor = "xppink";
      logoType = normalLogo;
      break;

    case "enterprise":
      mainOpacity = 0.7;
      secondaryOpacity = 0.7;
      mainColor = "xpblue";
      secondaryColor = "xppink";
      image = enterpriseImage;
      headerColor = "light";
      textColor = "white";
      subscriptionColor = "white";
      logoType = whiteLogo;
      break;

    default:
      mainOpacity = 0.1;
      secondaryOpacity = 0.4;
      mainColor = "dark";
      secondaryColor = "dark";
      image = freeImage;
      headerColor = "white";
      textColor = "white";
      subscriptionColor = "dark";
      logoType = normalLogo;
      break;
  }

  const handleButtonClick = () => {
    onAction(type);
  };

  return (
    <Card
    sx={({ palette: { gradients }, functions: { linearGradient, rgba } }) => ({
      background: gradients[mainColor]
        ? `${linearGradient(
            rgba(gradients[mainColor].main, mainOpacity),
            rgba(gradients[secondaryColor].state, secondaryOpacity)
          )}, url(${image})`
        : `${linearGradient(
            rgba(gradients.dark.main, mainOpacity),
            rgba(gradients.dark.state, secondaryOpacity)
          )}, url(${image})`,
      backgroundSize: "cover",
      backgroundPositionY: "50%",
      height:"350px",
    })}
  >
      <XBox p={4}>
        <XBox display="flex" justifyContent="space-between" alignItems="top">
          <XBox component="img" src={logoType} alt="xplainable-logo" width="100px" height="100px" mb={2.8} />
          <XBox flexGrow={1} />
        </XBox>
        <XBox py={2}>
          <XTypography variant="button" color={headerColor} fontWeight="regular" opacity={0.8}>
            Plan
          </XTypography>
          <XTypography variant="body1" color={subscriptionColor} fontWeight="bold">
            {type}
          </XTypography>
        </XBox>
        <XBox display="flex" justifyContent="space-between" alignItems="center">
          <XBox lineHeight={1}>
            <XTypography variant="button" color={headerColor} fontWeight="regular" opacity={0.8}>
            { isTrialExpired && (type==="free" || type==="trial") ? "Your free trial has ended" : "Renews"}
            </XTypography>
            {isTrialExpired && (type==="free" || type==="trial") ? 
            <XTypography variant="h3" color="error" fontWeight="medium">
              00:00:00:00
            </XTypography>
            : <XTypography variant="h6" color={textColor} fontWeight="medium">
                {type === "enterprise" ? "Ongoing" : formatDateToDayMonthYear(expires) }
              </XTypography>
            }
          </XBox>
          {isTrialExpired && type==="free" ? 
            <XButton variant="gradient" color="button" onClick={handleButtonClick} size="large">
              Create Organisation
            </XButton>
            : 
            (type === "enterprise" ? <></>:
              <MinimalistButton
              sx={{
                backgroundColor: darkMode ? rgba(colors.dark.main, 0) : rgba(colors.white.main, 0),
                "&:hover": {
                  backgroundColor: darkMode ? rgba(colors.dark.main, 0.1) : rgba(colors.white.main, 0.1),
                },
              }}
              onClick={handleButtonClick}
            >
              <XTypography
                sx={{
                  backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: "bold",
                  transition: "all 0.3s",
                  px: 2,
                }}
                fontSize="16px"
              >
                {type === "free" ? "Create Organisation" : type === "trial" ? "Get Full Access" : type === "software" ? "Cancel Subscription" : "Enterprise Subscription"}
              </XTypography>
            </MinimalistButton>
            )}
        </XBox>
      </XBox>
    </Card>
  );
}

SubscriptionCard.propTypes = {
  type: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
  isTrialExpired: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default SubscriptionCard;


function formatDateToDayMonthYear(inputDate) {
  const date = new Date(inputDate);
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
}
