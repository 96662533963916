// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XBadge from "components/XBadge";

// Timeline context
import { useTimeline } from "../context";

// Custom styles for the TimelineItem
import { timelineItem, timelineItemIcon } from "./styles";

import ReportIcon from "assets/images/icons/timeline/report-icon.svg";
import ModelActivatedIcon from "assets/images/icons/timeline/model-activated-icon.svg";
import ModelDeactivatedIcon from "assets/images/icons/timeline/model-deactivated-icon.svg";
import CreatedIcon from "assets/images/icons/timeline/created-icon.svg";
import UnknownIcon from "assets/images/icons/timeline/unknown.svg";
import DeletedIcon from "assets/images/icons/timeline/delete-icon.svg";
import DeployedIcon from "assets/images/icons/timeline/deployed-icon.svg";
import RevokedIcon from "assets/images/icons/timeline/revoked-icon.svg";

function TimelineItem({ color, icon, title, dateTime, description, badges, lastItem }) {
  const isDark = useTimeline();

  const renderIcon = (title) => {
    switch (title) {
      case "Model Deployed":
        return DeployedIcon;
      case "GPT Report Generated":
        return ReportIcon;
      case "Model Activated":
        return ModelActivatedIcon;
      case "Model Deactivated":
        return ModelDeactivatedIcon;
      case "Deploy Key Created":
      case "Model Created":
      case "API Key Created":
        return CreatedIcon;
      case "Deploy Key Deleted":
      case "Deployment Deleted":
      case "Model Deleted":
      case "API Key Deleted":
        return DeletedIcon;
      case "Unknown Event":
        return UnknownIcon;
      case "API Key Revoked":
      case "All User API Keys Revoked":
      case "Deploy Key Revoked":
      case "All Deploy Keys Revoked":
        return RevokedIcon;
      default:
        break;
    }
  };

  const renderBadges =
    badges.length > 0
      ? badges.map((badge, key) => {
          const badgeKey = `badge-${key}`;

          if (!badge) return;

          return (
            <XBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
              <XBadge
                sx={{ borderRadius: "100px" }}
                color={color}
                variant="contained"
                badgeContent={badge === "app-source" ? "APP" : badge}
                container
              />
            </XBox>
          );
        })
      : null;

  return (
    <XBox position="relative" sx={(theme) => timelineItem(theme, { lastItem })}>
      <XBox
        bgColor={isDark ? "dark" : "white"}
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="5px"
        zIndex={2}
      >
        <XBox component="img" src={renderIcon(title)} />
      </XBox>
      <XBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <XTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
          {title}
        </XTypography>
        <XBox mt={0.5}>
          <XTypography variant="caption" fontWeight="light" color={isDark ? "white" : "dark"}>
            {dateTime}
          </XTypography>
        </XBox>
        <XBox mt={1} mb={1}>
          {description ? (
            <XTypography variant="button" fontSize="12px" fontWeight="light" color="text">
              {description}
            </XTypography>
          ) : null}
        </XBox>
        {badges.length > 0 ? (
          <XBox display="flex" pb={lastItem ? 1 : 2}>
            {renderBadges}
          </XBox>
        ) : null}
      </XBox>
    </XBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  badges: [],
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "xpblue",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  lastItem: PropTypes.bool,
};

export default TimelineItem;
