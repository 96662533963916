import React, { useRef, useState } from "react";
import XTypography from "components/XTypography";
import PropTypes from "prop-types";
import { useResizable } from "react-resizable-layout";
import "./style.css";

export const TextEditorTable = ({ blocks, setBlocks, id, isEditable = false }) => {
  const tableRef = useRef(null);

  const { position, separatorProps } = useResizable({
    axis: "x",
    onResizeEnd: (data, test) => {
      setData((prevData) => {
        const updatedData = prevData.map((row) => ({
          ...row,
          [tableRef.current]: { ...row[tableRef.current], width: data.position },
        }));
        return updatedData;
      });
      tableRef.current = null;
    },
  });

  const [isHovered, setIsHovered] = useState(false);

  const tableBlock = blocks?.find((block) => block.id === id);

  const [data, setData] = useState(
    tableBlock?.data ?? [
      {
        col1: { value: "", width: 140 },
        col2: { value: "", width: 140 },
        col3: { value: "", width: 140 },
      },
      {
        col1: { value: "", width: 140 },
        col2: { value: "", width: 140 },
        col3: { value: "", width: 140 },
      },
      {
        col1: { value: "", width: 140 },
        col2: { value: "", width: 140 },
        col3: { value: "", width: 140 },
      },
    ]
  );

  const handleEdit = (rowIndex, colName, value) => {
    const newData = data.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [colName]: { ...row[colName], value: value } };
      }
      return row;
    });

    setData(newData);
    setBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map((block) => {
        if (block.id === id) {
          return { ...block, data: newData };
        }
        return block;
      });

      return updatedBlocks;
    });
  };

  const handleAddColumn = () => {
    const newData = data.map((row) => {
      const newColIndex = Object.keys(row).length + 1;
      return { ...row, [`col${newColIndex}`]: { value: "", width: 140 } };
    });
    setData(newData);
    setBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map((block) => {
        if (block.id === id) {
          return { ...block, data: newData };
        }
        return block;
      });

      return updatedBlocks;
    });
  };

  const handleAddRow = () => {
    const rows = Object.keys(data[0]);
    const initialObject = {};
    const resultObject = rows.reduce((obj, row) => {
      obj[row] = { value: "", width: 140 };
      return obj;
    }, initialObject);
    setData([...data, resultObject]);
    setBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map((block) => {
        if (block.id === id) {
          return { ...block, data: [...data, resultObject] };
        }
        return block;
      });

      return updatedBlocks;
    });
  };

  return (
    <div
      className="text-editor__container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="text-editor__table-container">
        <table className="text-editor__table">
          <tbody className="text-editor__table-body">
            {data.map((row, i) => (
              <tr key={i}>
                {Object.entries(row).map(([colName, cell], j) => (
                  <td
                    key={`${i}${j}`}
                    className="text-editor__table-cell"
                    style={{ width: colName === tableRef.current ? position : cell.width }}
                    onBlur={(e) => handleEdit(i, colName, e.target.innerText)}
                  >
                    <XTypography
                      sx={{ padding: "8px" }}
                      contentEditable={isEditable}
                      suppressContentEditableWarning={isEditable}
                    >
                      {cell.value}
                    </XTypography>

                    {isEditable && (
                      <div
                        {...separatorProps}
                        className="dragger"
                        onMouseDown={(e) => (tableRef.current = colName)}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {isHovered && isEditable && (
            <>
              <button className="add-column-button" onClick={handleAddColumn}>
                +
              </button>
              <button className="add-row-button" onClick={handleAddRow}>
                +
              </button>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

TextEditorTable.propTypes = {
  blocks: PropTypes.any,
  tableData: PropTypes.array,
  setBlocks: PropTypes.func,
  id: PropTypes.string,
  isEditable: PropTypes.bool,
};
