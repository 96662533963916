import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const usePartitionsQuery = (version_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.PARTITIONS, version_id],
    () => apiHandlers.partitions.getPartitions(version_id),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
