import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";

// @mui material components
import { Grid, Card, Slide, Skeleton } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";

// xplainable dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CollectionItems from "./components/CollectionItems";
import XTypography from "components/XTypography";

import { animateGradient } from "assets/theme/base/animations";

// Teams page components
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import { Comments } from "shared/Comments";
import { useCollectionQuery, useXPanelQuery } from "api/query";
import { useApp } from "hooks";
import { useAuth0 } from "@auth0/auth0-react";

import CollectionIcon from "assets/images/icons/collections/collections-icon.svg";
import ContributorsCard from "shared/Cards/ContributorsCard";


function Collection() {
  const { setActiveTitle } = useApp();
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const navigate = useNavigate();
  const location = useLocation();

  const isFirstRender = useRef(true);

  const [controller, dispatch] = useXplainableController();
  const { globalImage, globalColor } = controller;

  const [collapsedWidth, setCollapsedWidth] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [collectionsData, setCollectionsData] = useState(null);

  const { collection_id, model_id, title, description, contributors } = location.state ?? {
    collection_id: null,
    model_id: null,
    title: null,
    description: null,
    contributors: [],
  };

  const query = useXPanelQuery(
    {
      model_id: model_id,
      event: ["viewed_collection"],
      users: "True",
    },
    logout
  );

  const { data: userData, isLoading: userLoading} = query;
  const { data, isLoading: collectionsLoading } = useCollectionQuery(activeWorkspace, model_id, collection_id, logout);

  useEffect(() => {
    setActiveTitle(title);
  }, [title]);

  useEffect(() => {
    if (!data) return;

    setCollectionsData(data.data);
  }, [data]);

  const toggleCollapse = () => {
    setCollapsedWidth((prev) => !prev);
  };

  //Routing if the activeWorkspace changes
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // it's no longer the first render
      return;
    }
    navigate("/collections");
  }, [activeWorkspace]);

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={title}
        description={description}
        icon={"ni ni-collection"}
        width={"40%"}
        rhsElement={
          <>
            <XBox>
              <XBox display="flex" gap="12px" alignItems="center">
                <XBox
                  src={CollectionIcon}
                  component="img"
                  p={1}
                  bgColor={controller.darkMode ? "#1D1B1B" : "#F7F7F8"}
                  borderRadius="50%"
                  display="flex"
                />
                <XBox display="flex" flexDirection="column" gap="4px">
                  <XTypography variant="button" fontSize="24px">
                    {title}
                  </XTypography>
                  <XTypography variant="button" color="secondary" fontWeight="light">
                    {description}
                  </XTypography>
                </XBox>
              </XBox>
            </XBox>
          </>
        }
      />

      <XBox py={3}>
        <Grid container zIndex={1} spacing={3}>
          <Grid item xs={12} zIndex={1} xl={collapsedWidth ? 12 : 8}>
            {collectionsLoading ? (
              <XBox>
                <Card sx={{ p: 3, mb: 3 }}>
                  <Grid container spacing={3}>
                    {/* Left side - Graph Area */}
                    <Grid item xs={12} md={6}>
                      <XBox display="flex" flexDirection="column" gap={2}>
                        <XBox display="flex" alignItems="center" gap={1}>
                          <Skeleton variant="text" width={200} height={32} />
                          <Skeleton variant="circular" width={24} height={24} />
                        </XBox>
                        <Skeleton variant="rectangular" width="100%" height={300} />
                      </XBox>
                    </Grid>

                    {/* Right side - Prediction Overview */}
                    <Grid item xs={12} md={6}>
                      <XBox display="flex" flexDirection="column" gap={3}>
                        <XBox display="flex" justifyContent="space-between" alignItems="center">
                          <Skeleton variant="text" width={100} />
                          <Skeleton variant="text" width={60} />
                        </XBox>
                        <Skeleton variant="rectangular" width="100%" height={40} />
                        
                        <XTypography variant="h6">
                          <Skeleton width={200} />
                        </XTypography>
                        
                        {/* Prediction metrics */}
                        {[1, 2, 3, 4].map((item) => (
                          <XBox key={item} display="flex" justifyContent="space-between" alignItems="center">
                            <Skeleton variant="text" width={120} />
                            <XBox display="flex" alignItems="center" gap={2}>
                              <Skeleton variant="rectangular" width={200} height={8} />
                              <Skeleton variant="text" width={60} />
                            </XBox>
                          </XBox>
                        ))}
                      </XBox>
                    </Grid>
                  </Grid>
                </Card>

                {/* Tabs */}
                <Card sx={{ p: 3 }}>
                  <XBox display="flex" flexDirection="column" gap={3}>
                    <XBox display="flex" gap={2} mb={2}>
                      <Skeleton variant="text" width={80} />
                      <Skeleton variant="text" width={80} />
                    </XBox>

                    {/* Search and controls */}
                    <XBox display="flex" justifyContent="space-between" mb={3}>
                      <Skeleton variant="rectangular" width={200} height={40} />
                      <XBox display="flex" gap={2}>
                        <Skeleton variant="rectangular" width={120} height={40} />
                        <Skeleton variant="rectangular" width={120} height={40} />
                      </XBox>
                    </XBox>

                    {/* Table */}
                    <XBox>
                      {[1, 2, 3, 4, 5].map((row) => (
                        <XBox
                          key={row}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          py={2}
                          borderBottom="1px solid #eee"
                        >
                          <XBox display="flex" alignItems="center" gap={2}>
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="text" width={100} />
                          </XBox>
                          <Skeleton variant="text" width={80} />
                          <Skeleton variant="text" width={80} />
                          <Skeleton variant="text" width={80} />
                          <Skeleton variant="text" width={120} />
                          <Skeleton variant="text" width={120} />
                          <Skeleton variant="circular" width={32} height={32} />
                        </XBox>
                      ))}
                    </XBox>
                  </XBox>
                </Card>
              </XBox>
            ) : (
              <XBox
                mr={1}
                sx={{
                  width: collapsedWidth ? "100%" : "100",
                  transition: "width 500ms",
                  zIndex: 1,
                }}
              >
                <XBox mb={10}>
                  <XBox>
                    {collectionsData && (
                      <CollectionItems
                        returnedData={collectionsData}
                        collectionId={collection_id}
                        modelId={model_id}
                        collapsedWidth={collapsedWidth}
                      />
                    )}
                  </XBox>
                </XBox>
              </XBox>
            )}
          </Grid>

          <Grid item xs={collapsedWidth ? 0 : 12} xl={collapsedWidth ? 0 : 4} mt={{ xs: 3, xl: 0 }}>
            <Slide direction="left" in={!collapsedWidth} timeout={500}>
              <XBox
                sx={{
                  width: collapsedWidth ? 0 : "100%",
                  overflow: "hidden",
                  transition: "width 500ms",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  alignContent="flex-start"                  
                  sx={{ position: "sticky", top: "0" }}
                >
                  <Grid item xs={12} md={6} xl={12} mb={2}>
                  <ContributorsCard 
                      contributors={contributors} 
                      userData={userData} 
                      isLoading={!userData}
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <Comments
                      tabValue={tabValue} 
                      setTabValue={setTabValue}
                      modelId={model_id}
                      versionNumber={null}
                      collectionId={collection_id}
                      type={"collections"}
                    />
                  </Grid>
                </Grid>
              </XBox>
            </Slide>
          </Grid>
        </Grid>
      </XBox>
    </DashboardLayout>
  );
}

export default Collection;
