import React, {useRef, useState, useEffect} from 'react';
import LoadingSpinner from '../LoadingAnimation';
import colors from 'assets/theme/base/colors';
import XTypography from 'components/XTypography';
import XBox from 'components/XBox';
const openAiLogo = "https://seeklogo.com/images/O/open-ai-logo-8B9BFEDC26-seeklogo.com.png"

const LinkAnimation = () => {
    const svgRef = useRef(null);
    const [pathLength, setPathLength] = useState(400); // Default value

    useEffect(() => {
        if (svgRef.current) {
            const width = svgRef.current.getBoundingClientRect().width;
            const newLength = width ; // This coefficient might need adjustment
            setPathLength(newLength);
        }
    }, []);

    return (
        <XBox 
        display="flex" 
        height={"500px"} 
        ml={-5} 
        flexDirection='column' 
        justifyContent='center' 
        alignItems='center' 
        sx={{
            animation: "pulse 3s infinite"
        }}
    >
        <div  style={{position: 'relative', display: 'flex', alignItems: 'center', width:"350px" }}>
                  <style>
                {`
                  @keyframes pulse {
                    0% { opacity: 0.2; }
                    50% { opacity: 1; }
                    100% { opacity: 0.2; }
                }
                    .path, .dashed-animated {
                        stroke-dasharray: ${pathLength};
                        stroke-dashoffset: ${pathLength}; 
                        animation: dash 2s linear forwards infinite;
                    }
                    

                    @keyframes dash {
                        to {
                            stroke-dashoffset: 0;
                        }
                    }

                  
                `}
            </style>
        {/* Left LoadingSpinner with adjusted positioning */}
        <div style={{ position: 'absolute', top: '50%', left: 0, marginTop: -20, marginLeft: 15 }}>
                    <LoadingSpinner animationType='spin' size={50} />
                </div>
                <svg width="400px" height="150px" viewBox="0 0 315 150" style={{ overflow: 'visible' }}>
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            {/* Static dashed background */}
                            {/* Top semicircle */}
                            <path className="dashed" fill="none" stroke="white" strokeWidth="8" 
                                d="M1.4,25 A157,75 0 0,1 313.6,25" />
                            <path id="Path-1" className="dashed-animated" fill="none" stroke={colors.light.main} strokeWidth="5" 
                                d="M1.4,25 A157,75 0 0,1 313.6,25" />

                            {/* Bottom semicircle */}
                            <path className="dashed" fill="none" stroke="white" strokeWidth="8" 
                                d="M313.6,125 A157,75 0 1,1 1.4,125" />
                            <path id="Path-2" className="dashed-animated" fill="none" stroke={colors.light.main} strokeWidth="5" 
                                d="M313.6,125 A157,75 0 1,1 1.4,125" />
                        {/* Arrow pointing right */}
                        <polyline id="arrow-right" points="-9,-9 9,0 -9,9" fill={colors.light.main}>
                            <animateMotion
                                rotate="auto"
                                begin="0s"
                                dur="2s"
                                repeatCount="indefinite">
                                <mpath href="#Path-1" />
                            </animateMotion>
                        </polyline>

                        {/* Arrow pointing left on the bottom semicircle */}
                        <polyline id="arrow-left" points="-9,9 9,0 -9,-9" fill={colors.light.main}>
                            <animateMotion
                                rotate="auto"
                                begin="0s"
                                dur="2s"
                                repeatCount="indefinite">
                                <mpath href="#Path-2" />
                            </animateMotion>
                        </polyline>

                        </g>
                    </svg>
                    <XTypography style={{ position: 'absolute', top: '45%', right: "15%", }} color="light" variant="h4" >Building Report...</XTypography>
            {/* Right LoadingSpinner with adjusted positioning */}
            <div style={{ position: 'absolute', top: '50%', right: 0, marginTop: -20, marginRight: -30 }}>
                    <LoadingSpinner animationType='spin' size={50} logo={openAiLogo}/>
                </div>
        </div>
        </XBox>
    );
};

export default LinkAnimation;

