import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useRegressionPerformanceQuery } from "api/query";
import { useModel } from "hooks";

import { Card, Grid, Slider } from "@mui/material";
import XSelect from "components/XSelect";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

import RegressionChart from "./components/RegressionChart2";
import ResidualChart from "./components/ResidualChart2";
import Parameters from "../shared/Parameters";

// Removed ScrollReveal for debugging
// import ScrollReveal from "scrollreveal";

const RegressionPerformance = () => {
  const { logout } = useAuth0();
  const { selectedPartition } = useModel();

  const [selectedDataset, setSelectedDataset] = useState({ value: "train", label: "Train" });
  const [sliderValue, setSliderValue] = useState(26);
  const [filteredData, setFilteredData] = useState(null);
  const [regressionData, setRegressionData] = useState([]);
  const [errorData, setErrorData] = useState({});

  // Use React Query to fetch performance data
  const { data, isLoading, error } = useRegressionPerformanceQuery(
    selectedPartition?.value,
    logout
  );

  console.log("Fetched Data:", data);

  // Handle dataset selection
  const handleDatasetChange = (dataset) => {
    setSelectedDataset(dataset);
  };

  // Function to filter and set data based on selected dataset
  const filterAndSetData = (data) => {
    if (data) {
      setFilteredData(data);
      setRegressionData(data.charts);

      const { observed_min, observed_max, prediction_bins } = data;

      const filterBinsByClass = (bins, className) =>
        bins?.find((bin) => bin.class === className)?.values || [];

      const formattedData = {
        min: observed_min,
        max: observed_max,
        bins: {
          true: filterBinsByClass(prediction_bins, "true"),
          pred: filterBinsByClass(prediction_bins, "pred"),
        },
      };

      setErrorData(formattedData);
    } else {
      setFilteredData(null);
      setRegressionData([]);
      setErrorData({});
    }
  };

  // Consolidated useEffect to handle data filtering based on dataset and fetched data
  useEffect(() => {
    if (data && data.data) {
      const evaluationData = data.data.evaluation;
      if (selectedDataset.value === "validation") {
        filterAndSetData(evaluationData?.validation);
      } else if (selectedDataset.value === "train") {
        filterAndSetData(evaluationData?.train);
      }
    } else {
      // If no data is fetched yet, reset filtered data
      filterAndSetData(null);
    }
  }, [data, selectedDataset]);

  // Initialize ScrollReveal animations (commented out for debugging)
  /*
  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, []);
  */

  return (
    <XBox py={2}>
      <Grid container>
        <Grid item xs={12}>
          {/* Header Section - Always Visible */}
          <Card
            sx={{
              p: 2,
              position: "relative",
              zIndex: 100,
              overflow: "visible",
              mb: 3,
            }}
          >
            <XBox display="flex" justifyContent="space-between" gap={3}>
              <XBox>
                <XTypography variant="h5" fontSize="24px">
                  Regression Performance
                </XTypography>
                <XTypography variant="h6" color="secondary" fontWeight="light">
                  Visualize adjusting the granularity of the plots
                </XTypography>
              </XBox>
              <XBox display="flex" gap={2}>
                <XBox>
                  <XTypography variant="overline" fontSize="14px">
                    Selected Dataset
                  </XTypography>
                  <XSelect
                    placeholder="Filter on Dataset"
                    options={[
                      { value: "validation", label: "Validation" },
                      { value: "train", label: "Train" },
                    ]}
                    selected={selectedDataset}
                    onChange={handleDatasetChange}
                  />
                </XBox>
                <XBox width="200px">
                  <XTypography variant="overline" fontSize="14px">
                    Adjust Granularity
                  </XTypography>
                  <Slider
                    color="primary"
                    step={0.1}
                    min={5}
                    max={100}
                    value={sliderValue}
                    onChange={(event, newValue) => {
                      setSliderValue(newValue);
                    }}
                    aria-labelledby="granularity-slider"
                  />
                </XBox>
              </XBox>
            </XBox>
          </Card>

          {/* Conditional Content Section */}
          <Grid container spacing={4}>
            {/* Display Loading Spinner */}
            {isLoading && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    height: "200px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingSpinner size={50} animationType="pulse" />
                </Card>
              </Grid>
            )}

            {/* Display Error Message */}
            {error && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    height: "200px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  <XBox color="error.main">
                    {error.response?.data?.message || "Error loading regression performance data."}
                  </XBox>
                </Card>
              </Grid>
            )}

            {/* Display No Data Message */}
            {!isLoading && !error && !filteredData && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    height: "200px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  <XBox color="text.secondary">
                    No regression performance data available for the selected dataset.
                  </XBox>
                </Card>
              </Grid>
            )}

            {/* Display Main Content If Data is Available */}
            {filteredData && (
              <>
                <Grid item xs={12} lg={6} mb={3}>
                  <Card sx={{ p: 2, minHeight: "510px" }} id="regression-chart">
                    <XBox pt={2} px={2} lineHeight={1}>
                      <XTypography variant="h6" fontSize="18px">
                        Predicted vs Actual (Residuals)
                      </XTypography>
                    </XBox>
                    {regressionData && (
                      <RegressionChart
                        id="regression--error--plot"
                        data={regressionData}
                        pixels={sliderValue}
                        title="Regression Error Plot"
                        xLabel="Actual x"
                        yLabel="Predicted x"
                        xName="error-x"
                        yName="error-y"
                      />
                    )}
                  </Card>
                </Grid>
                <Grid item xs={12} lg={6} mb={3}>
                  <Card sx={{ p: 2, minHeight: "510px" }} id="residual-chart">
                    <XBox pt={2} px={2} lineHeight={1}>
                      <XTypography variant="h6" fontSize="18px">
                        Predicted vs Actual (Histogram)
                      </XTypography>
                    </XBox>
                    {errorData && (
                      <ResidualChart regressionBins={errorData} numBins={sliderValue} />
                    )}
                  </Card>
                </Grid>

                {/* Parameters */}
                <Grid item xs={12} mb={3}>
                  <Parameters />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </XBox>
  );
};

export default RegressionPerformance;
