export const REPORT_COMPONENTS_KEYS = {
  BINARY_OVERVIEW: "binaryoverview",
  ARTICLE: "article",
  COMMENTS: "comments",
  FEATURE_PLOT: "featureplot",
  WATERFALL_PLOT: "waterfallplot",
  THRESHOLD_PLOT: "thresholdPlot",
  CONFUSION_MATRIX: "confusionMatrix",
  METRICS: "metrics",
  PR_CURVE_ROC_CURVE: "prCurveRocCurve",
  LINE_CHART: "lineChart",
  HEALTH: "health",
  PIPELINE: "pipeline",
  CURRENT_OVERVIEW: "currentOverview",
  BASE_VALUE: "baseValue",
  REGRESSION_CHART: "regressionChart",
  RESIDUAL_CHART: "residualChart",
  ORGANISATIONS: "organisations",
  SEARCH_USER: "searchUser",
  THRESHOLD_OPTIMISATION: "thresholdOptimisation",
  DIVIDER: "divider",
  COLUMNS: "columns",
  TABLE: "table",
  BULLET_LIST: "bulletList",
  NOTE: "note",
  MONITORING: "monitoring",
  BATCH_PREDICT: "predict",
};
