// useMixPanelQuery.js
import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useXPanelQuery = (params, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.XPANEL_DATA, params],
    () => apiHandlers.xPanel.getXPanel(params),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
