import React, { useContext, useState, useEffect } from "react";
import { useXplainableController } from "context";
import DeploymentContext from "../../../deployment-context";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import ComingSoon from "shared/Cards/ComingSoon";

const DeploymentSelection = () => {
  const [controller] = useXplainableController();

  const { active_version, versions, activeWorkspace } = useContext(DeploymentContext);
  const [selectedVersion, setSelectedVersion] = useState({
    value: active_version,
    label: "Version " + active_version,
  });
  const [partitions, setPartitions] = useState([]);
  const [selectedPartition, setSelectedPartition] = useState([]);
  console.log("Version in DeploymentSelection:", versions);

  // useEffect(() => {
  //   const fetchPartitions = async () => {
  //     const response = await fetch(
  //       `${hostUrl}/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/1/versions/${selectedVersion.value}`
  //     );
  //     const data = await response.json();
  //     console.log("The partitions are:", data);
  //     setPartitions(data.partitions || []);
  //   };

  //   fetchPartitions();
  // }, [selectedVersion]);

  const handleVersionChange = (event) => {
    setSelectedVersion(event);
  };

  const handlePartitionChange = (event) => {
    setSelectedPartition(event);
  };

  return (
    <XBox display="flex" justifyContent="center" p={2}>
      <XBox display="flex" width="100%" flexDirection="column" minHeight={"600px"}>
        <XBox mb={3}>
          <XTypography variant="h5" fontSize="18px">
            Change Deployed Model
          </XTypography>
        </XBox>
        <ComingSoon
          caption={"The ability to change the model version is coming soon."}
          icon={true}
        />
        {/* <Grid container spacing={12} mb={3}>
          <Grid item xs={12} md={6}>
            <XBox>
              <XTypography variant="h6">Version</XTypography>
              <XSelect
                value={selectedVersion}
                onChange={handleVersionChange}
                fullWidth
                placeholder="Select Version"
                options={versions.map((version) => ({
                  value: version.version_id,
                  label: `Version ${version.version_number}`,
                }))}
              />
            </XBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <XBox>
              <XTypography variant="h6">Partition</XTypography>
              <XSelect
                value={selectedPartition}
                onChange={handleVersionChange}
                fullWidth
                placeholder="Select Version"
                options={versions.map((version) => ({
                  value: version,
                  label: `Version ${version}`,
                }))}
              />
            </XBox>
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={1} alignItems="center">
          <Grid item xs={12} lg={5.5}>
            <XBox>
              <XTypography variant="h6">Current Schema</XTypography>
              <XInput
                placeholder={examplePayload["one"]}
                multiline
                rows={15}
                sx={{
                  background: `${
                    controller.darkMode ? colors.dark.main : colors.light.main
                  } !important`,
                }}
              />
            </XBox>
          </Grid>
          <Grid item xs={12} lg={0.5} display="flex" justifyContent="center">
            <XBox component="img" src={DarkArrowRight} />
          </Grid>
          <Grid item xs={12} lg={5.5}>
            <XBox>
              <XTypography variant="h6">New Schema</XTypography>
              <XInput
                placeholder={examplePayload["two"]}
                multiline
                rows={15}
                sx={{
                  background: `${
                    controller.darkMode ? colors.dark.main : colors.light.main
                  } !important`,
                }}
              />
            </XBox>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
          <XButton color="button" variant="gradient">
            Update
          </XButton>
        </Grid> */}
      </XBox>
    </XBox>
  );
};

export default DeploymentSelection;

const examplePayload = {
  one: `{"houseFeatures": {
        "location": "Downtown",
        "bedrooms": 3,
        "bathrooms": 2,
        "sqft": 2000,
        "builtYear": 1990,
        "hasGarage": true
    },
    "transactionDetails": {
        "transactionDate": "2023-01-01",
        "transactionType": "Sale",
        "previousOwners": 2
    }
    }`,
  two: `{"houseFeatures": {
          "location": "Suburb",
          "bedrooms": 4,
          "bathrooms": 2,
          "sqft": 2200,
          "builtYear": 1995,
          "hasGarage": true
        },
        "transactionDetails": {
          "transactionDate": "2023-03-01",
          "transactionType": "Sale",
          "previousOwners": 1
        }
      }`,
};
