import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useRunsMutation = () => {
  const { mutate: createRunsMutation } = useMutation((params) =>
    apiHandlers.runs.createRun(...params)
  );

  return {
    createRunsMutation,
  };
};
