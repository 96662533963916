import { Card, Grid } from "@mui/material";
import React from "react";
import ProgressLineChart from "shared/Charts/ProgressLineChart";

export const Monitoring = () => {
  return (
    <Grid item xs={11} md={11} xl={11} mt={6}>
      <Card>
        <ProgressLineChart
          icon="group"
          shadow={false}
          title={"API Calls"}
          color={"xpblue"}
          height={"500px"}
          event="inference_server_predict"
          deployment_id={"LLBTdhz71yKVW28y"}
          stack={true}
        />
      </Card>
    </Grid>
  );
};
