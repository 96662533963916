import React, { useState } from "react";

import XBox from "components/XBox";
import XTypography from "components/XTypography";

import { StyledBox } from "./styled";
import { XDivider } from "components/XDivider";
import { useDropzone } from "react-dropzone";

import ImagePickerIcon from "assets/images/image-picker-button.svg";

export const ImagePicker = () => {
  const [fileNames, setFileNames] = useState("");

  const onDropAccepted = (acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name));
  };

  const { getRootProps, getInputProps } = useDropzone({
    addRemoveLinks: true,
    maxFiles: 1,
    onDrop: onDropAccepted,
    accept: {
      "text/jpeg": [".jpeg"],
      "text/png": [".png"],
    },
  });

  return (
    <XBox>
      <StyledBox
        {...getRootProps()}
        sx={{
          height: "230px",
          border: "1px dashed #AFAFAF",
          borderRadius: "12px",
          display: "flex",
          padding: "24px",
          flexDirection: "column",
          gap: "8px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input {...getInputProps()} />
        {fileNames.length === 0 ? (
          <>
            <XTypography variant="caption" style={{ fontSize: "14px" }}>
              Drag and drop image
            </XTypography>
            <XBox display="flex" gap={2} alignItems="center" width="100%">
              <XDivider />
              <XTypography style={{ color: "#EAEAEA", fontSize: "14px" }} variant="caption">
                or
              </XTypography>
              <XDivider />
            </XBox>
            <XBox src={ImagePickerIcon} component="img" />
          </>
        ) : (
          fileNames.map((fileName, i) => (
            <>
              <XTypography key={fileName} style={{ whiteSpace: "wrap", textAlign: "center" }}>
                {fileName}
              </XTypography>
            </>
          ))
        )}
      </StyledBox>
    </XBox>
  );
};
