import { useState } from "react";
import PropTypes from 'prop-types'

import { Modal, Tab, Tabs } from '@mui/material';

import Card from "@mui/material/Card";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";

// Pricing page components
import PricingCards from "./components/PricingCards";
import PricingTable from "layouts/first-login/components/Subscription/components/PricingTable";


function PricingModal({ onClose, open }) {
    const [tabValue, setTabValue] = useState(0);
    const [prices, setPrices] = useState(["0", "Contact Us", "Contact Us"]);
  
    const handleSetTabValue = (event, newValue) => {
      setTabValue(newValue);
  
      if (event.currentTarget.id === "annual") {
        setPrices(["0", "159", "399"]);
      } else {
        setPrices(["0", "89", "99"]);
      }
    };
  
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="pricing-modal-title"
        aria-describedby="pricing-modal-description"
      >
        <Card  
        sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 
            'translate(-50%, -50%)', 
            boxShadow: 24, 
            p: 4,
            minWidth: {xs:"90%",lg:'80vw'} 
            }}>
            <XBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',  mb:3 }}>
                <XBox sx={{ mt: 3, mb: 1, width: '100%', textAlign: 'center' }}>
                    <XTypography variant="h3" fontWeight="bold">
                        See our pricing
                    </XTypography>
                </XBox>
                <XBox sx={{ mb: 2, textAlign: 'center' }}>
                    <XTypography variant="body2" fontWeight="regular" color="secondary">
                        {"It appears you've reached your capped limit in your current plan."}
                    </XTypography>
                </XBox>
            </XBox>
          <XBox sx={{ mt: 2 }}>
            <PricingTable />
            {/* <PricingCards prices={prices} /> */}
          </XBox>
        </Card>
      </Modal>
    );
  }
  

export default PricingModal;

  // Typechecking props for the Feature Chart
PricingModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

