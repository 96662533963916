const getTimeDifference = function (dateString, verbose = false) {
  let date;
  if (typeof dateString === "string") {
    date = Date.UTC(
      dateString.substr(0, 4),
      dateString.substr(5, 2) - 1,
      dateString.substr(8, 2),
      dateString.substr(11, 2),
      dateString.substr(14, 2),
      dateString.substr(17, 2),
      dateString.substr(20, 3)
    );
  } else if (dateString instanceof Date) {
    date = dateString.getTime();
  } else {
    throw new Error("Invalid input: expected string or Date object");
  }
  const now = new Date();
  const timeDifference = now - date;
  const oneMinuteInMs = 1000 * 60;
  const oneHourInMs = oneMinuteInMs * 60;
  const oneDayInMs = oneHourInMs * 24;
  const oneWeekInMs = oneDayInMs * 7;

  if (timeDifference < oneHourInMs) {
    const minutes = Math.floor(timeDifference / oneMinuteInMs);
    const plural = minutes > 1 ? "s" : "";
    return minutes + (verbose ? ` minute${plural} ago` : ` min${plural}`);
  } else if (timeDifference < oneDayInMs) {
    const hours = Math.floor(timeDifference / oneHourInMs);
    const plural = hours > 1 ? "s" : "";
    return hours + (verbose ? ` hour${plural} ago` : ` hr${plural}`);
  } else if (timeDifference < oneWeekInMs) {
    const days = Math.floor(timeDifference / oneDayInMs);
    const plural = days > 1 ? "s" : "";
    return days + (verbose ? ` day${plural} ago` : ` day${plural}`);
  } else {
    const weeks = Math.floor(timeDifference / oneWeekInMs);
    const plural = weeks > 1 ? "s" : "";
    return weeks + (verbose ? ` week${plural} ago` : ` wk${plural}`);
  }
};

export { getTimeDifference };
