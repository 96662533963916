import { Grid } from "@mui/material";
import React, { useContext } from "react";
import ProgressLineChart from "shared/Charts/ProgressLineChart";
import DeploymentContext from "../deployment-context";

export const Monitoring = () => {
  const { deployment_id, model_name } = useContext(DeploymentContext);

  return (
    <Grid item xs={12} md={6} xl={12} mt={6} mb={3}>
      <ProgressLineChart
        icon="group"
        shadow={false}
        title={model_name + " - API Calls"}
        color={"xpblue"}
        height={"500px"}
        event="inference_server_predict"
        deployment_id={deployment_id}
        stack={true}
      />
    </Grid>
  );
};
