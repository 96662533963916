import { Card } from "@mui/material";
import XBox from "components/XBox";
import React, { useEffect, useState } from "react";

import ScrollReveal from "scrollreveal";

import PreprocessingPipeline from "shared/models/Pipeline";
import LinkPreprocessor from "shared/models/LinkPreprocessor";

export const Pipeline = () => {
  const [preprocessorData, setPreprocessorData] = useState(null);

  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, []);

  return (
    <XBox py={2}>
      <XBox className="scrollreveal">
        <Card sx={{ p: 4, position: "relative", zIndex: 1, overflow: "visible" }}>
          <LinkPreprocessor updatePreprocessorData={setPreprocessorData} />
        </Card>
      </XBox>
      <XBox className="scrollreveal" mt={2}>
        <Card sx={{ p: 4, position: "relative", zIndex: 1, overflow: "visible" }}>
          <PreprocessingPipeline preprocessorData={preprocessorData} />
        </Card>
      </XBox>
    </XBox>
  );
};
