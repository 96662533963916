import XBox from "components/XBox";
import React from "react";

import PropTypes from "prop-types";

export const EditTextButton = ({ style, text }) => {
  return (
    <XBox
      sx={{
        padding: "0 8px",
        display: "flex",
        justifyContent: "center",
        width: "30px",
        background: "#0D0C0C",
        cursor: "pointer",
        transition: "all .3s",
        "&:hover": {
          background: "grey",
        },
      }}
      onMouseDown={(evt) => {
        evt.preventDefault();
        document.execCommand(style, false, style);
      }}
    >
      <div key={style} style={{ color: "white" }}>
        {text}
      </div>
    </XBox>
  );
};

EditTextButton.propTypes = {
  style: PropTypes.string,
  text: PropTypes.string,
};
