import { makeHttpRequest } from "./httpClient";

export function getData(url, config) {
  return makeHttpRequest({ url, method: "GET", ...config });
}

export function postData(url, config) {
  return makeHttpRequest({ url, method: "POST", ...config });
}

export function putData(url, config) {
  return makeHttpRequest({ url, method: "PUT", ...config });
}

export function patchData(url, config) {
  return makeHttpRequest({ url, method: "PATCH", ...config });
}

export function deleteData(url, config) {
  return makeHttpRequest({ url, method: "DELETE", ...config });
}
