import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import XInput from "components/XInput";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton"; 
import { MdContentCopy, MdCheck } from "react-icons/md";
import { Tooltip, Icon } from "@mui/material";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  textToConfirm,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  warningIcon,
  darkMode,
  colors,
}) => {
  const [confirmInput, setConfirmInput] = useState("");
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToConfirm);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const isConfirmEnabled = confirmInput === textToConfirm;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="confirmation-modal"
    >
      <XBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -60%)",
          width: 600,
          background: darkMode ? colors.background.dark : colors.background.default,
          borderRadius: "16px",
          padding: 3,
        }}
      >
        {/* Close button header */}
        <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <XBox />
          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              color: darkMode ? white.main : dark.main,
              stroke: darkMode ? white.main : dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
            })}
            onClick={onClose}
          >
            close
          </Icon>
        </XBox>

        {/* Modal content */}
        <XBox
          display="flex"
          flexDirection="column"
          gap={3}
          justifyContent="center"
          textAlign="center"
        >
          {warningIcon && (
            <XBox width="100%" justifyContent="center" alignItems="center">
              <XBox src={warningIcon} component="img" width={120} />
            </XBox>
          )}

          <XTypography fontSize="16px" fontWeight="medium">
            {title}
          </XTypography>

          {/* Copy text box */}
          <XBox
            sx={{
              backgroundColor: "rgba(colors.secondary.main, 0.1)",
              padding: 0.5,
              borderColor: "grey.300",
              border: 1,
              borderStyle: "solid",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <XBox>
              <XTypography fontSize="16px" fontWeight="light" color="secondary">
                {`Type "${textToConfirm}" into the input field to proceed`}
              </XTypography>
            </XBox>
            <Tooltip title="Copy to clipboard" placement="top">
              <XBox
                sx={{
                  p: 1.5,
                  borderColor: "grey.300",
                  border: 1,
                  borderStyle: "solid",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {copied ? (
                  <MdCheck size="24" />
                ) : (
                  <MdContentCopy size="24" onClick={handleCopy} style={{ cursor: "pointer" }} />
                )}
              </XBox>
            </Tooltip>
          </XBox>

          <XInput value={confirmInput} onChange={(e) => setConfirmInput(e.target.value)} />

          {/* Action buttons */}
          <XBox display="flex" justifyContent="space-between" width="100%" gap={2}>
            <XButton
              sx={{ width: "20%" }}
              variant="gradient"
              color="light"
              onClick={onClose}
            >
              {cancelButtonText}
            </XButton>
            <XButton
              sx={{ width: "calc(80% - 8px)", mr: 2 }}
              variant="gradient"
              color="error"
              onClick={() => {
                if (isConfirmEnabled) {
                  onConfirm();
                  onClose();
                }
              }}
              disabled={!isConfirmEnabled}
            >
              {confirmButtonText}
            </XButton>
          
          </XBox>
        </XBox>
      </XBox>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  textToConfirm: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  warningIcon: PropTypes.string,
  darkMode: PropTypes.bool,
  colors: PropTypes.object,
};

export default ConfirmationModal;