import React, { useState } from "react";
import { useXplainableController } from "context";
import { Card } from "@mui/material";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import PropTypes from "prop-types";
import DarkFilterIcon from "assets/images/dark-filter-icon.svg";
import LightFilterIcon from "assets/images/light-filter-icon.svg";

export const Select = ({
  selectOptions,
  handleDropdownItemClick,
  color = "F7F7F8",
  darkColor = "1D1B1B",
}) => {
  const [controller] = useXplainableController();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (selectedOption) => {
    handleDropdownItemClick(selectedOption);
    toggleDropdown();
  };

  return (
    <XBox>
      <XBox
        component="img"
        src={controller.darkMode ? LightFilterIcon : DarkFilterIcon}
        p={2}
        onClick={toggleDropdown}
        display="flex"
        sx={{
          background: controller.darkMode ? `#${darkColor}` : `#${color}`,
          borderRadius: "12px",
          cursor: "pointer",
        }}
      />
      {isOpen && (
        <Card
          sx={{
            position: "absolute",
            top: "65px",
            left: "16px",
            minHeight: "min-content",
            maxHeight: "200px",
            minWidth: "250px",
            overflowY: "scroll",
            borderRadius: "16px",
            background: controller.darkMode ? "#1D1B1B" : "white",
            zIndex: "1000",
          }}
        >
          {selectOptions.map((selectOption, index) => (
            <XTypography
              sx={{
                p: "10px 16px",
                cursor: "pointer",
                "&:hover": {
                  background: "#F7F7F8",
                  color: "#1D1B1B",
                },
              }}
              onClick={() => handleItemClick(selectOption)}
              variant="caption"
              fontSize="14px"
              key={index}
            >
              {selectOption.label}
            </XTypography>
          ))}
        </Card>
      )}
    </XBox>
  );
};

Select.propTypes = {
  selectOptions: PropTypes.array,
  handleDropdownItemClick: PropTypes.func,
  color: PropTypes.string,
  darkColor: PropTypes.string,
};
