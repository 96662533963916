import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";

// Authentication layout components
import Separator from "layouts/authentication/components/Separator";
import Logo from "assets/images/xplainable-logo.svg";

// Images
import dataVisualisationImage from "assets/images/data_visualisation_3.png";
import { useApiKey } from "../ApiKeyContext";
import { XImg } from "components/XImg";
import { ReactComponent as ButtonArrowLeft } from "assets/images/icons/button-arrow-left.svg";

import EmailIcon from "assets/images/icons/email-icon.png";

function Basic() {
  const { loginWithRedirect, logout } = useAuth0();
  const { checkEmail } = useApiKey();

  return (
    <XBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      height="100vh" // Set the height to full viewport height
      width="100vw" // Set the width to full viewport width
      overflow="hidden" // Prevent scrolling
    >
      <XBox
        sx={{
          width: "616px",
          height: "616px",
          borderRadius: "50%",
          filter: "blur(500px)",
          right: -200,
          bottom: -200,
          backgroundColor: "#E14067",
          position: "absolute",
        }}
      />
      <XBox
        sx={{
          width: "616px",
          height: "616px",
          borderRadius: "50%",
          filter: "blur(500px)",
          left: -200,
          top: -200,
          backgroundColor: "#0080EA",
          position: "absolute",
        }}
      />
      <XBox px={1} width="100%" mx="auto" my="auto">
        <Grid container justifyContent="center">
          {checkEmail ? (
            <Card>
              <XBox
                p={5}
                textAlign="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={4}
              >
                <XBox display="flex" alignItems="center" alignSelf="start" gap={2}>
                  <XButton
                    sx={{
                      background: "#F7F7F8",
                      boxShadow: "none",
                      padding: 2,
                    }}
                    onClick={() => {
                      logout();
                    }}
                  >
                    <XImg>
                      <ButtonArrowLeft />
                    </XImg>
                  </XButton>
                  <XTypography fontWeight="medium" color="secondary">
                    Back to sign up
                  </XTypography>
                </XBox>
                <XBox src={EmailIcon} height={"80px"} width={"80px"} component="img" />
                <XBox
                  display="flex"
                  gap={2}
                  justifyContent="center"
                  flexDirection="column"
                  maxWidth="600px"
                >
                  <XTypography fontSize="24px" fontWeight="medium">
                    Verify Your Email Address
                  </XTypography>
                  <XTypography color="secondary" fontWeight="light">
                    {
                      "Please check your inbox to verify your email address and complete the registration process. If you haven't received the email, make sure to check your spam or junk folder."
                    }
                  </XTypography>
                </XBox>
              </XBox>
            </Card>
          ) : (
            <Grid item xs={10} sm={7} md={7} lg={5} xl={5} sx={{ maxWidth: "600px !important" }}>
              <Card>
                <XBox
                  p={5}
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={4}
                >
                  <XBox display="flex" flexDirection="column" gap={2} alignItems="center">
                    <XBox
                      component="img"
                      src={Logo}
                      alt="xplainable-logo"
                      width="60px"
                      height="60px"
                    />
                    <XTypography fontSize="24px" fontWeight="medium" sx={{ lineHeight: "32px" }}>
                      Welcome to Xplainable
                    </XTypography>
                    <XTypography color="secondary" fontSize="14px" sx={{ lineHeight: "20px" }}>
                      Log in to use our Web app or Sign up
                    </XTypography>
                  </XBox>
                  <XBox
                    display="flex"
                    gap="2"
                    flexDirection="column"
                    textAlign="center"
                    width="100%"
                  >
                    <XBox
                      component="form"
                      role="form"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <XBox>
                        <XButton
                          color="button"
                          variant="gradient"
                          fullWidth
                          sx={{ height: "56px", fontSize: "18px" }}
                          onClick={() =>
                            loginWithRedirect({
                              authorizationParams: {
                                screen_hint: "signup",
                              },
                            })
                          }
                        >
                          Sign up
                        </XButton>
                      </XBox>
                      <XBox>
                        <XTypography
                          fontSize="18px"
                          onClick={() => loginWithRedirect()}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            fontWeight: "bold",
                            height: "56px",
                            cursor: "pointer",
                            borderRadius: "0.5rem",
                            transition: "all 0.3s",
                            "&:hover": {
                              boxShadow:
                                "0rem 0.4375rem 0.875rem 0rem rgba(13, 12, 12, 0.1), 0rem 0.1875rem 0.375rem 0rem rgba(0, 0, 0, 0.08)",
                              transform: "translateY(-1px)",
                            },
                          }}
                        >
                          Log in
                        </XTypography>
                      </XBox>
                    </XBox>
                  </XBox>
                </XBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </XBox>
    </XBox>
  );
}

export default Basic;
