import React from "react";
import PropTypes from "prop-types";

import XBox from "components/XBox";
import XTypography from "components/XTypography";

import { Card, Divider, Icon } from "@mui/material";
import { useXplainableController } from "context";

const DummyComponent = ({ title, caption, icon = null, shadow = true }) => {
  const [controller] = useXplainableController();
  return (
    <XBox
      sx={{
        height: "100%",
        display: "flex",
        width: "210px",
        p: 2,
        background: controller.darkMode ? "#1D1B1B" : "#F7F7F8",
        borderRadius: "16px",
        gap: "16px",
      }}
    >
      <XBox component="img" src={icon} height="32px" />

      <XBox display="flex" flexDirection="column" gap={0.5}>
        <XTypography variant="button">{title}</XTypography>
        <XTypography variant="caption" fontSize="12px" sx={{ whiteSpace: "break-spaces" }}>
          {caption}
        </XTypography>
      </XBox>
    </XBox>
  );
};

DummyComponent.propTypes = {
  title: PropTypes.string,
  shadow: PropTypes.bool,
  icon: PropTypes.string,
  caption: PropTypes.string,
  height: PropTypes.any,
};

export default DummyComponent;
