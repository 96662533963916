import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useReportMutation = () => {
  const { mutate: createReportMutation } = useMutation((params) =>
    apiHandlers.reports.createReport(...params)
  );

  const { mutate: createReportVersionMutation } = useMutation((params) =>
    apiHandlers.reports.createReportVersion(...params)
  );

  const { mutate: deleteReportMutation } = useMutation((params) =>
    apiHandlers.reports.deleteReport(...params)
  );

  const { mutate: copyReportMutation } = useMutation((params) =>
    apiHandlers.reports.copyReport(...params)
  );

  return {
    createReportMutation,
    createReportVersionMutation,
    deleteReportMutation,
    copyReportMutation,
  };
};
