/**
 * Finds the minimum or maximum value in an array and its index.
 *
 * @param {Array} arr - The array to search.
 * @param {String} type - The type of search to perform. Use "maximise" to find the maximum value.
 * @returns {Object} - An object containing the minimum or maximum value and its index.
 */
function findMinMaxAndLocation(arr, type) {
  let comparator = (a, b) => a < b; // default to finding minimum
  if (type === "maximise") {
    comparator = (a, b) => a > b;
  }

  let resultVal = arr[0];
  let resultIndex = 0;

  for (let i = 1; i < arr.length; i++) {
    if (comparator(arr[i], resultVal)) {
      resultVal = arr[i];
      resultIndex = i;
    }
  }

  return { value: resultVal, index: resultIndex };
}

/**
 * Finds and returns the object with the highest or lowest score in a given array of objects.
 *
 * @param {Array} data - The array of objects to search for the score object.
 * @param {Boolean} min - Determines whether to find the object with the lowest (true) or highest (false) score.
 * @returns {Object} - The object with the highest or lowest score in the array.
 */
function findScoreObj(data, min = true) {
  // Set the comparator function based on whether we want to find the object with the lowest or highest score
  const comparator = min ? (a, b) => a.score < b.score : (a, b) => a.score > b.score;

  // Reduce the array to a single object with the highest or lowest score
  return data.reduce(
    (result, currentObj) => (comparator(currentObj, result) ? currentObj : result),
    data[0]
  );
}

export function targetValue(fsm, currentState, type = "maximise", filter = true) {
  //Check current state of the inputs
  const filteredData = currentState.filter((obj) => obj.checked === true);
  const numericFeatures = Object.keys(fsm.numeric);

  //Logic to filter out field names if selected
  let fieldNames;
  if (filter) {
    fieldNames = filteredData.map((obj) => obj.name);
  } else {
    fieldNames = [];
  }

  //Concat the Feature Score map and instantiate the output
  const fsmConcat = { ...fsm.numeric, ...fsm.categorical };
  const output = [];

  //Loop through the features in the feature score map and filter on value
  Object.keys(fsmConcat)
    .filter((val) => !fieldNames.includes(val))
    .forEach((val) => {
      const data = fsmConcat[val];

      //Loop through the numeric features and build the response object
      if (numericFeatures.includes(val)) {
        //Return only the score array and find the min/max
        const scoreArray = data.map((val) => val.score);
        // scoreArrays.push(scoreArray)
        const resultObj = findMinMaxAndLocation(scoreArray, type);

        //Return the lowest score of the response
        const lowestScore = resultObj.value;
        let lowerField = data[resultObj.index].lower;

        //If at the lowest index minus off the magnitude
        if (lowerField === -Infinity) {
          lowerField = data[resultObj.index].upper - getMagnitude(data[resultObj.index].upper);
        }

        //Push the response to the output object
        output.push({ name: val, field: lowerField, value: lowestScore, checked: false });
      } else {
        const scoreArray = data.map((val) => val.score);

        let scoreObj = findScoreObj(data, type !== "maximise");

        // Get a random category from the object with the lowest score
        const randomCategory =
          scoreObj.categories[Math.floor(Math.random() * scoreObj.categories.length)];
        output.push({ name: val, field: randomCategory, value: scoreObj.score, checked: false });
      }
    });

  //Concat features that are locked and the response values
  const result = filteredData.concat(output);
  return result;
}

//Class for Targeting a value
export class Target {
  constructor(model, fieldValues, tolerance = 0) {
    this.model = model;
    this.fieldValues = fieldValues;
    this.modelConcat = { ...model.numeric, ...model.categorical };
    this.base_value = model.base_value;
    this.score = model.base_value;
    this.categorical = Object.keys(model.categorical);
    this.numeric = Object.keys(model.numeric);
    this.columns = [];
    this.tolerance = tolerance;
    this.filteredData = {};
  }

  buildProfile() {
    console.log(this.model);
    this._profile = [];

    // Check which values are locked and filter the fieldValues based on 'checked' property
    this.filteredData = Object.keys(this.fieldValues)
      .filter((key) => this.fieldValues[key].checked)
      .map((key) => {
        return {
          keyName: key,
          ...this.fieldValues[key],
        };
      });
    const lockedFields = this.filteredData.map((obj) => obj.keyName);

    // Loop through the features in the model object and build the array
    Object.keys(this.modelConcat)
      .filter((val) => !lockedFields.includes(val)) // Exclude locked fields
      .forEach((val) => {
        const leafNodes = this.modelConcat[val];

        // Skip this feature if leafNodes array is empty
        if (leafNodes.length === 2) {
          // console.log(`Skipping feature '${val}' as it has no nodes.`);
          return;
        }

        // Update the columns following dropping locked values
        this.columns.push(val);

        // Simplify using falsy checks
        let leafArray = this.categorical.includes(val)
          ? leafNodes
              .filter((node) => node.category)
              .map((obj) => [obj.category, obj.category, obj.score])
          : leafNodes
              .filter((node) => node.lower || node.upper)
              .map((obj) => [obj.lower, obj.upper, obj.score]);

        this._profile.push(leafArray);
      });
  }

  start() {
    let mappings = [];
    this.columns = [];

    this.buildProfile();

    for (let i = 0; i < this.columns.length; i++) {
      let _prof = this._profile[i];
      mappings.push([..._prof.map((val, ind) => [ind, val[2]]).sort((a, b) => a[1] - b[1])]);
    }

    this.target_total = this.filteredData.reduce((sum, obj) => sum + obj.value, 0);
    this.score = this.base_value + this.target_total;
    this.mappings = mappings;
    this.not_maxxed = [];
    this.not_minned = [];
    this.scores = [];
    this.idxs = [];
    this.best_score = null;
    this.best_diff = Infinity;
    this.best_idxs = [];
    this.total_permutations = 0;
    this.randomInit();
    this.updateBounds();
  }

  randomInit() {
    for (let i = 0; i < this.mappings.length; i++) {
      let v = this.mappings[i];
      if (v.length === 0) {
        console.error("Empty mapping for index:", i, this.columns[i]);
        continue;
      }

      let c = v[Math.floor(Math.random() * v.length)];
      let score = c[1];
      this.score += score;
      this.scores.push(score);
      this.idxs.push(v.indexOf(c));
    }
  }

  updateBounds() {
    this.not_maxxed = this.idxs.map((v, i) => (v === this.mappings[i].length - 1 ? false : true));
    this.not_minned = this.idxs.map((v, i) => (v === 0 ? false : true));
  }

  randomIncrease() {
    let c = this.columns.filter((val, i) => this.not_maxxed[i]);
    let col_idx = this.columns.indexOf(c[Math.floor(Math.random() * c.length)]);
    let idx = this.idxs[col_idx];
    let new_idx = idx + 1;
    let new_score = this.mappings[col_idx][new_idx][1];
    this.score -= this.scores[col_idx];
    this.score += new_score;
    this.scores[col_idx] = new_score;
    this.idxs[col_idx] = new_idx;
    this.updateBounds();
  }

  randomDecrease() {
    let c = this.columns.filter((val, i) => this.not_minned[i]);
    let col_idx = this.columns.indexOf(c[Math.floor(Math.random() * c.length)]);
    let idx = this.idxs[col_idx];
    let new_idx = idx - 1;
    let new_score = this.mappings[col_idx][new_idx][1];
    this.score -= this.scores[col_idx];
    this.score += new_score;
    this.scores[col_idx] = new_score;
    this.idxs[col_idx] = new_idx;
    this.updateBounds();
  }

  run(target, iterations = 10) {
    const t0 = performance.now();
    this.start();

    for (let i = 0; i < iterations; i++) {
      if (this.score < target) {
        if (this.not_maxxed.reduce((a, b) => a + b, 0) === 0) {
          break;
        }
        this.randomIncrease();
      } else if (this.score > target) {
        if (this.not_minned.reduce((a, b) => a + b, 0) === 0) {
          break;
        }
        this.randomDecrease();
      } else {
        break;
      }

      let diff = Math.abs(this.score - target);
      if (diff < this.best_diff) {
        this.best_diff = diff;
        this.best_score = this.score;
        this.best_idxs = this.idxs.map((v, i) => this.mappings[i][v]);
      }

      if (diff <= this.tolerance) {
        this.total_permutations = this.total_permutations + 1;
      }
    }

    const t1 = performance.now();

    let output = {};

    this.best_idxs.forEach((idx, index) => {
      let column = this.columns[index];
      let leafArray = this.modelConcat[column][idx[0]];

      let classType = leafArray.score >= 0 ? "positive" : "negative";
      if (this.categorical.includes(column)) {
        output[column] = {
          keyName: column,
          field: leafArray.category,
          value: leafArray.score,
          checked: false,
          class: classType,
        };
      } else {
        output[column] = {
          keyName: column,
          field: leafArray.upper,
          value: leafArray.score,
          checked: false,
          class: classType,
        };
      }
    });

    //loop through this.filteredData and update the output object
    for (let i = 0; i < this.filteredData.length; i++) {
      let obj = this.filteredData[i];
      output[obj.keyName] = obj;
    }

    console.log("The score is", this.best_score);
    return output;
  }
}

// //Class for Targeting a value
// export class Target {
//   constructor(model, fieldValues, tolerance = 0) {
//     this.model = model;
//     this.fieldValues = fieldValues;
//     this.modelConcat = {...model.numeric, ...model.categorical};
//     this.base_value = model.base_value;
//     this.score = model.base_value;
//     this.categorical = Object.keys(model.categorical);
//     this.numeric = Object.keys(model.numeric);
//     this.columns = [];
//     this.tolerance = tolerance;
//     this.filteredData = {};
//   }

//   buildProfile() {
//     console.log(this.model);
//     this._profile = [];

//     // Check which values are locked and filter the fieldValues based on 'checked' property
//     this.filteredData = Object.keys(this.fieldValues)
//                             .filter(key => this.fieldValues[key].checked)
//                             .map(key => {
//                                 return {
//                                     keyName: key,
//                                     ...this.fieldValues[key]
//                                 };
//                             });
//     const lockedFields = this.filteredData.map(obj => obj.keyName);

//     // Loop through the features in the model object and build the array
//     Object.keys(this.modelConcat)
//         .filter(val => !lockedFields.includes(val)) // Exclude locked fields
//         .forEach(val => {
//             const leafNodes = this.modelConcat[val];

//             // Skip this feature if leafNodes array is empty
//             if (leafNodes.length === 0) {
//               console.log(`Skipping feature '${val}' as it has no nodes.`);
//               return;
//             }

//             // Update the columns following dropping locked values
//             this.columns.push(val);

//             // Construct leafArray for each feature
//             let leafArray = leafNodes.map(obj => [obj.lower, obj.upper, obj.score]);

//             // Append the nested leaf array to the fields
//             this._profile.push(leafArray);
//         });
//   }

//   start() {
//       let mappings = [];
//       this.columns = [];

//       this.buildProfile();

//     for (let i = 0; i < this.columns.length; i++) {
//       let _prof = this._profile[i];
//       console.log(_prof)
//       mappings.push(
//         [...(_prof.map((val, ind) => [ind, val[2]]).sort((a, b) => a[1] - b[1]))]
//       );
//       console.log(mappings)
//     }

//     this.target_total = this.filteredData.reduce((sum, obj) => sum + obj.value, 0);
//     this.score = this.base_value + this.target_total;
//     this.mappings = mappings;
//     this.not_maxxed = [];
//     this.not_minned = [];
//     this.scores = [];
//     this.idxs = [];
//     this.best_score = null;
//     this.best_diff = Infinity;
//     this.best_idxs = [];
//     this.total_permutations = 0;
//     this.randomInit();
//     this.updateBounds();
//   }

//   randomInit() {
//     for (let i = 0; i < this.mappings.length; i++) {
//       let v = this.mappings[i];
//       if (v.length === 0) {
//         console.error('Empty mapping for index:', i);
//         continue;
//       }

//       let c = v[Math.floor(Math.random() * v.length)];
//       let score = c[1];
//       this.score += score;
//       this.scores.push(score);
//       this.idxs.push(v.indexOf(c));
//     }
//   }

//   updateBounds() {
//     this.not_maxxed = this.idxs.map((v, i) => v === this.mappings[i].length - 1 ? false : true);
//     this.not_minned = this.idxs.map((v, i) => v === 0 ? false : true);
//   }

//   randomIncrease() {
//     let c = this.columns.filter((val, i) => this.not_maxxed[i]);
//     let col_idx = this.columns.indexOf(c[Math.floor(Math.random() * c.length)]);
//     let idx = this.idxs[col_idx];
//     let new_idx = idx + 1;
//     let new_score = this.mappings[col_idx][new_idx][1];
//     this.score -= this.scores[col_idx];
//     this.score += new_score;
//     this.scores[col_idx] = new_score;
//     this.idxs[col_idx] = new_idx;
//     this.updateBounds();
//   }

//   randomDecrease() {
//     let c = this.columns.filter((val, i) => this.not_minned[i]);
//     let col_idx = this.columns.indexOf(c[Math.floor(Math.random() * c.length)]);
//     let idx = this.idxs[col_idx];
//     let new_idx = idx - 1;
//     let new_score = this.mappings[col_idx][new_idx][1];
//     this.score -= this.scores[col_idx];
//     this.score += new_score;
//     this.scores[col_idx] = new_score;
//     this.idxs[col_idx] = new_idx;
//     this.updateBounds();
//   }

//   run(target, iterations=10) {
//       const t0 = performance.now()
//       this.start();

//       for (let i = 0; i < iterations; i++) {
//         if (this.score < target) {
//           if (this.not_maxxed.reduce((a, b) => a + b, 0) === 0) {
//             break;
//           }
//           this.randomIncrease();
//         } else if (this.score > target) {
//           if (this.not_minned.reduce((a, b) => a + b, 0) === 0) {
//             break;
//           }
//           this.randomDecrease();
//         } else {
//           break;
//         }

//         let diff = Math.abs(this.score - target);
//         if (diff < this.best_diff) {
//           this.best_diff = diff;
//           this.best_score = this.score;
//           this.best_idxs = this.idxs.map((v, i) => this.mappings[i][v]);
//         }

//         if (diff <= this.tolerance) {
//           this.total_permutations = this.total_permutations + 1;
//         }

//       }

//       let nodes = this.best_idxs.map((v, i) => this._profile[i][v[0]]);
//       const t1 = performance.now()
//       const elapsedTime = t1 - t0

//       let output = {}

//       this.best_idxs.forEach((idx, index) => {
//           let column = this.columns[index];
//           let leafArray = this.modelConcat[column][idx[0]];

//           let start = 0; // Assign or calculate the start value
//           let end = 0;   // Assign or calculate the end value
//           let classType = leafArray.score >= 0 ? 'positive' : 'negative'; // Determine the class based on value

//           if (this.categorical.includes(column)) {
//               // Handling categorical fields
//               let cat_idx = Math.floor(Math.random() * leafArray.categories.length);
//               const randomCategory = leafArray.categories[cat_idx];
//               output[column] = {
//                   keyName: column,
//                   field: randomCategory,
//                   value: leafArray.score,
//                   checked: false,
//                   start: start,
//                   end: end,
//                   class: classType
//               };
//           } else {
//               // Handling numerical fields
//               let lowerField = leafArray.lower === -Infinity ? leafArray.upper - getMagnitude(leafArray.upper) : leafArray.lower;
//               output[column] = {
//                   keyName: column,
//                   field: lowerField,
//                   value: leafArray.score,
//                   checked: false,
//                   start: start,
//                   end: end,
//                   class: classType
//               };
//           }
//       });

//       // Merge with filteredData (if needed)
//       Object.assign(output, this.filteredData);

//       console.log("The score is", this.best_score);
//       return output;
//     }
// }
