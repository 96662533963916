// useMixPanelQuery.js
import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useOrganisationMetadataQuery = ( logout ) => {
  return useQuery([QUERY_CONSTANTS.ORGANISATION_METADATA], () => apiHandlers.organisations.getOrganisationMetadata(), {
    onError: (err) => {
      handleTokenExpired(err, logout);
    },
  });
};
