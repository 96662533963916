import { createContext, useContext, useReducer, useMemo, useEffect } from "react";
import bgImage from "assets/images/data_visualisation_1.png";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The xplainable dashboard main context
const Xplainable = createContext(null);

// Setting custom name for the context which is visible on react dev tools
Xplainable.displayName = "XplainableContext";

/**
 * Reducer function for the XplainableContext.
 *
 * This reducer listens to dispatched actions and modifies the state accordingly.
 * It handles several properties of the state, each case representing a different state property.
 *
 * For each case, before returning a new state, the reducer checks if the new value is the same
 * as the current state value. If they are the same, it returns the existing state instead of
 * creating a new one. This prevents unnecessary re-renders in React components that consume
 * this context, as React will not re-render a component if the state it relies on has not changed.
 *
 * If the dispatched action doesn't match any of the predefined cases, it throws an error.
 */
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV":
      if (state.miniSidenav === action.value) {
        return state;
      }
      return { ...state, miniSidenav: action.value };
    case "DARK_SIDENAV":
      if (state.darkSidenav === action.value) {
        return state;
      }
      return { ...state, darkSidenav: action.value };
    case "SIDENAV_COLOR":
      if (state.sidenavColor === action.value) {
        return state;
      }
      return { ...state, sidenavColor: action.value };
    case "TRANSPARENT_NAVBAR":
      if (state.transparentNavbar === action.value) {
        return state;
      }
      return { ...state, transparentNavbar: action.value };
    case "FIXED_NAVBAR":
      if (state.fixedNavbar === action.value) {
        return state;
      }
      return { ...state, fixedNavbar: action.value };
    case "OPEN_CONFIGURATOR":
      if (state.openConfigurator === action.value) {
        return state;
      }
      return { ...state, openConfigurator: action.value };
    case "DIRECTION":
      if (state.direction === action.value) {
        return state;
      }
      return { ...state, direction: action.value };
    case "LAYOUT":
      if (state.layout === action.value) {
        return state;
      }
      return { ...state, layout: action.value };
    case "DARK_MODE":
      if (state.darkMode === action.value) {
        return state;
      }
      return { ...state, darkMode: action.value };
    case "SET_GLOBAL_COLOR":
      if (state.globalColor === action.payload) {
        return state;
      }
      return { ...state, globalColor: action.payload };
    case "SET_GLOBAL_IMAGE":
      if (state.globalImage === action.payload) {
        return state;
      }
      return { ...state, globalImage: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function XplainableControllerProvider({ children }) {
  //Check the saved preferences in the local storage
  const savedPreferences = JSON.parse(localStorage.getItem("preferences"));
  const initialState = savedPreferences || {
    miniSidenav: false,
    darkSidenav: false,
    sidenavColor: "#0D0C0C",
    transparentNavbar: false,
    fixedNavbar: false,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    globalColor: null,
    globalImage: bgImage,
    models_view_table: false,
    deployments_view_table: false,
    collections_view_table: false,
    reports_view_table: false,
    batches_view_table: false
  };

  // If no saved preferences, set the initial state in localStorage
  if (!savedPreferences) {
    localStorage.setItem("preferences", JSON.stringify(initialState));
  }

  const [controller, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // Load existing preferences from local storage
    const savedPreferences = JSON.parse(localStorage.getItem("preferences")) || {};

    // Update preferences with the current context values
    const updatedPreferences = {
      ...savedPreferences,
      miniSidenav: controller.miniSidenav,
      darkSidenav: controller.darkSidenav,
      sidenavColor: controller.sidenavColor,
      transparentNavbar: controller.transparentNavbar,
      fixedNavbar: controller.fixedNavbar,
      openConfigurator: controller.openConfigurator,
      direction: controller.direction,
      layout: controller.layout,
      darkMode: controller.darkMode,
      globalColor: controller.globalColor,
      globalImage: controller.globalImage,
      models_view_table: controller.models_view_table,
      deployments_view_table: controller.deployments_view_table,
      collections_view_table: controller.collections_view_table,
      reports_view_table: controller.reports_view_table,
      batches_view_table: controller.batches_view_table
    };

    // Save updated preferences to local storage
    localStorage.setItem("preferences", JSON.stringify(updatedPreferences));
  }, [controller]);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <Xplainable.Provider value={value}>{children}</Xplainable.Provider>;
}

// xplainable dashboard custom hook for using context
function useXplainableController() {
  const context = useContext(Xplainable);

  if (!context) {
    throw new Error(
      "useXplainableController should be used inside the XplainableControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the XplainableControllerProvider
XplainableControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setDarkSidenav = (dispatch, value) => dispatch({ type: "DARK_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARK_MODE", value });
const setGlobalColor = (dispatch, color) => {
  dispatch({ type: "SET_GLOBAL_COLOR", payload: color });
};
const setGlobalImage = (dispatch, image) => {
  dispatch({ type: "SET_GLOBAL_IMAGE", payload: image });
};

export {
  XplainableControllerProvider,
  useXplainableController,
  setMiniSidenav,
  setDarkSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setGlobalColor,
  setGlobalImage,
};
