import React from "react";

import PropTypes from "prop-types";
import { Card, Divider } from "@mui/material";
import XTypography from "components/XTypography";
import XBox from "components/XBox";
import Icon from "@mui/material/Icon";

const ComingSoon = ({
  caption,
  height = { xs: "400px", lg: "750px" },
  shadow = true,
  icon = false,
}) => {
  return (
    <XBox sx={{ width: "100%" }}>
      <XBox p={3}>
        <XBox
          display="flex"
          height={height}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <XBox>
            <XBox
              my={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {icon && (
                <Icon fontSize="40px" color="secondary" sx={{ fontSize: "40px", mb: 1 }}>
                  construction
                </Icon>
              )}
              <XTypography variant="caption" fontSize="16px" color="secondary">
                {caption}
              </XTypography>
            </XBox>
          </XBox>
        </XBox>
      </XBox>
    </XBox>
  );
};

// Typechecking props for the ProfileInfoCard
ComingSoon.propTypes = {
  title: PropTypes.string,
  shadow: PropTypes.bool,
  icon: PropTypes.bool,
  caption: PropTypes.string,
  height: PropTypes.any,
};

export default ComingSoon;
