import PropTypes from "prop-types";
import { createContext, useEffect, useRef, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useModelsMutation, useToggleMutation } from "api/mutations";
import { useModelQuery, useProfileDataQuery, useXPanelQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import breakpoints from "assets/theme/base/breakpoints";
import findVersionNumberByVersionId from "helpers/Versioning";

export const ModelContext = createContext();

export const ModelProvider = ({ children }) => {
  const { viewState, activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const isFirstRender = useRef(true);
  const updatedFromLocationState = useRef(true);
  const [access, setAccess] = useState("creator");

  const location = useLocation();
  const navigate = useNavigate();

  const { id: model_id } = useParams();
  const [modelState, setModelState] = useState({
    model_name: null,
    model_description: null,
    model_type: null,
    target_name: null,
    created: null,
    archived: false,
    active: false,
    user: null,
    active_version: null,
    deployed: false,
    deployment_id: null,
    active_deployment: null,
    contributors: [],
    versions: [],
    number_of_versions: 0,
  });

  const [toggle, setToggle] = useState(modelState.active);
  const [selectedVersion, setSelectedVersion] = useState({
    value: modelState.active_version,
    label:
      "Version " +
      Number(findVersionNumberByVersionId(modelState.versions, modelState.active_version)),
  });
  const [tabValue, setTabValue] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [collapsedWidth, setCollapsedWidth] = useState(false);
  const [allData, setAllData] = useState([]);
  const [partitions, setPartitions] = useState([]);
  const [selectedPartition, setSelectedPartition] = useState(null);
  const [paramData, setParamData] = useState({});
  const [featureData, setFeatureData] = useState([]);
  const [calibrationMap, setCalibrationMap] = useState({});
  const [featureDescriptionData, setFeatureDescriptionData] = useState([]);
  const [probabilityData, setProbabilityData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [trainingMetadata, setTrainingMetadata] = useState({});
  const [regressionBins, setRegressionBins] = useState({ min: null, max: null, bins: null });
  const [openMenu, setOpenMenu] = useState(null);
  const [activeVersion, setActiveVersion] = useState(modelState.active_version);
  const [version, setVersion] = useState(
    Number(findVersionNumberByVersionId(modelState.versions, modelState.active_version))
  );

  //Always call the model query hook but conditionally use it's results
  const modelQueryResult = useModelQuery(model_id, logout);

  //Retrieve Profile Data
  const { data: profileDataFromRequest, isLoading: profileDataLoading } = useProfileDataQuery(
    selectedVersion?.value,
    logout
  );

  const { data: userData } = useXPanelQuery(
    {
      model_id: model_id,
      event: ["viewed_model_profile"],
      users: "True",
    },
    logout
  );

  // Define data based on condition, to stop from hitting backend twice
  let data;
  if (!location.state?.model_id) {
    data = modelQueryResult.data;
  }

  //Add model mutations
  const { modelToggleMutation } = useToggleMutation();
  const { deleteModelVersionMutation, deleteModelMutation, handleActiveVersionMutation } =
    useModelsMutation();

  useEffect(() => {
    // If we have model state AND the active version in location.state
    if (location.state && location.state.active_version) {
      setModelState(location.state);
      setSelectedVersion({
        value: location.state.active_version,
        label:
          "Version " +
          Number(
            findVersionNumberByVersionId(location.state.versions, location.state.active_version)
          ),
      });
      setActiveVersion(location.state.active_version);
      setVersion(
        findVersionNumberByVersionId(location.state.versions, location.state.active_version)
      );
    }

    if (!data?.data) return;

    setModelState(data.data);
    setToggle(data.data?.active);

    setSelectedVersion({
      value: data.data?.active_version,
      label:
        "Version " +
        Number(
          findVersionNumberByVersionId(data.data?.versions, data.data?.active_version)
        ),
    });
    setActiveVersion(data.data?.active_version);
    setVersion(
      Number(findVersionNumberByVersionId(data.data?.versions, data.data?.active_version))
    );
  }, [location.state, data]); // this effect hook runs whenever location.state changes

  // When you need to use the variables from modelState
  const { model_type, target_name, versions } = modelState;

  const toggleCollapse = () => {
    setCollapsedWidth((prev) => !prev);
  };

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
       The event listener that's calling the handleTabsOrientation function when resizing the window.
      */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    if (!profileDataFromRequest) return;

    setAllData(profileDataFromRequest?.data);

    //Set the values for the partition dropdown
    const partitions = profileDataFromRequest?.data.map((item) => ({
      partition_id: item?.partition_id,
      partition_value: item?.partition,
    }));
    setPartitions(partitions);

    //Filter the data on the __dataset__ partition on instantiation
    const filteredData = profileDataFromRequest?.data.filter((x) => {
      return x.partition === "__dataset__";
    });


    setSelectedPartition({ value: filteredData[0]?.partition_id, label: "__dataset__" });
    setParamData(filteredData[0]?.parameters);
    setProfileData(filteredData[0]?.profile);
    setFeatureData(filteredData[0]?.feature_importances);
    setCalibrationMap(filteredData[0]?.calibration_map);
    setProbabilityData(filteredData[0]?.probability_bins);
    setFeatureDescriptionData(filteredData[0]?.feature_info);
    setTrainingMetadata(filteredData[0]?.training_metadata);

    // Regression metadata
    setRegressionBins({
      min: filteredData[0]?.observed_min,
      max: filteredData[0]?.observed_max,
      bins: filteredData[0]?.bins,
    });
  }, [profileDataFromRequest]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleVersionChange = (event) => {
    // console.log("The version change event is", event)
    setSelectedVersion(event);
  };

  const handlePartitionChange = (event) => {
    setSelectedPartition(event);
  };

  const handleToggleChange = async () => {
    const action = toggle ? "deactivate" : "activate";
    modelToggleMutation([model_id, action], {
      onSuccess: () => {
        console.log("Toggle Success");
        setToggle(!toggle);
      },
      onError: () => {
        console.log("Toggle error");
      },
    });
  };

  const handleDeleteVersion = async () => {
    console.log(selectedVersion, versions);
    deleteModelVersionMutation([selectedVersion], {
      onSuccess: () => {
        const updatedVersions = versions.filter(
          (version) => version.version_id !== selectedVersion.value
        );

        console.log("the updated versions are", updatedVersions);

        // Update selectedVersion to next version if available, else set to null
        const nextVersion = updatedVersions.length > 0 ? updatedVersions[0] : null;
        console.log("the next version is", nextVersion);

        setSelectedVersion({
          value: nextVersion.version_id,
          label: "Version " + nextVersion.version_number,
        });

        handleCloseMenu();
      },
      onError: () => {
        console.log("Error delete version");
        handleCloseMenu;
      },
    });
  };

  const handleActiveVersion = async () => {
    // Delete the version
    handleActiveVersionMutation([selectedVersion], {
      onSuccess: () => {
        setActiveVersion(selectedVersion.value);
        handleCloseMenu();
      },
      onError: (err) => {
        console.error("Error:", err);
        handleCloseMenu();
      },
    });
  };

  const deleteProject = (model_id) => {
    //Delete the project
    deleteModelMutation([model_id], {
      onSuccess: () => {
        console.log("model_id is", model_id);

        // Navigate to the /models route
        navigate("/models");
      },
      onError: (err) => {
        console.error("Error:", err);
      },
    });
  };

  useEffect(() => {
    //console.log("The selected partition is", selectedPartition)
    if (allData.length > 0) {
      const filteredData = allData.filter((x) => {
        return x.partition_id === selectedPartition.value;
      });
      //console.log("The filtered data is", filteredData)

      if (filteredData.length > 0) {
        setProfileData(filteredData[0].profile);
        setFeatureData(filteredData[0].feature_importances);
        setFeatureDescriptionData(filteredData[0].feature_info);
        setParamData(filteredData[0].parameters);
        setCalibrationMap(filteredData[0].calibration_map);
        setProbabilityData(filteredData[0].probability_bins);
      }
    }
  }, [selectedPartition]);

  const handleCloseMenu = () => setOpenMenu(null);
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  //Routing if the activeWorkspace changes
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // it's no longer the first render
      return;
    }

    if (updatedFromLocationState.current) {
      updatedFromLocationState.current = false; // Reset the flag
      return; // Skip navigation for this specific scenario
    }

    // Navigate to the /models route if workspace changes
    navigate("/models");
  }, [activeWorkspace]);

  return (
    <ModelContext.Provider
      value={{
        model_id,
        openMenu,
        toggle,
        setToggle,
        handleToggleChange,
        handleCloseMenu,
        handleActiveVersion,
        handleDeleteVersion,
        deleteProject,
        activeVersion,
        access,
        viewState,
        modelState,
        setSelectedVersion,
        handleVersionChange,
        partitions,
        handlePartitionChange,
        handleOpenMenu,
        tabsOrientation,
        tabValue,
        setTabValue,
        handleSetTabValue,
        collapsedWidth,
        toggleCollapse,
        userData,
        profileData,
        featureData,
        featureDescriptionData,
        model_type,
        target_name,
        selectedPartition,
        trainingMetadata,
        regressionBins,
        selectedVersion,
        profileDataLoading,
        calibrationMap,
        paramData,
        trainingMetadata,
        probabilityData,
        version,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
};

ModelProvider.propTypes = {
  children: PropTypes.node,
};
