import { DEFAULT_MODEL_SETTING } from "layouts/autotrain/constants";
import { useState } from "react";

export const useSettingTab = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelSettings, setModelSettings] = useState(DEFAULT_MODEL_SETTING);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const modelProviderHandler = (data) => {
    setModelSettings((prev) => ({ ...prev, provider: data?.value }));
  };

  const modelHandler = (data) => {
    setModelSettings((prev) => ({ ...prev, model: data?.value }));
  };

  const maxTokenHandler = (e) => {
    setModelSettings((prev) => ({ ...prev, max_tokens: e.target.value }));
  };

  const temperatureHandler = (e) => {
    setModelSettings((prev) => ({ ...prev, temperature: e.target.value }));
  };

  const presencePenaltyHandler = (e) => {
    setModelSettings((prev) => ({ ...prev, presence_penalty: e.target.value }));
  };

  const frequencyPenaltyHandler = (e) => {
    setModelSettings((prev) => ({ ...prev, frequency_penalty: e.target.value }));
  };
  const topPHandler = (e) => {
    setModelSettings((prev) => ({ ...prev, top_p: e.target.value }));
  };
  const streamHandler = () => {
    setModelSettings((prev) => ({ ...prev, stream: !prev.stream }));
  };
  const stopHandler = (e) => {
    setModelSettings((prev) => ({ ...prev, stop: e.target.value }));
  };
  const nSlideHandler = (e) => {
    setModelSettings((prev) => ({ ...prev, n: e.target.value }));
  };

  return {
    isModalOpen,
    modelSettings,
    handleClose,
    modelHandler,
    modelProviderHandler,
    maxTokenHandler,
    temperatureHandler,
    presencePenaltyHandler,
    frequencyPenaltyHandler,
    topPHandler,
    streamHandler,
    stopHandler,
    nSlideHandler
  }
};
