import React, { useState, useMemo, useEffect } from "react";
import XBox from "components/XBox";
import Chart from "./components/chart";
import PropTypes from "prop-types";
import { AppBar, Tabs, Tab, Card } from "@mui/material";
import XTypography from "components/XTypography";
import colors from "assets/theme/base/colors";
import Switch from "@mui/material/Switch";
import breakpoints from "assets/theme/base/breakpoints";
import { useXplainableController } from "context";
import { XImg } from "components/XImg";

import { ReactComponent as ChangeOrderIcon } from "assets/images/icons/change-order-icon.svg";

function ExpandableInfo({ collapsed, data }) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const [chartType, setChartType] = useState("bar");
  const [highlightedFeature, setHighlightedFeature] = useState(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    if (newValue === 1) {
      setChartType("waterfall");
      setTabValue(newValue);
      return;
    }

    setChartType("bar");
    setTabValue(newValue);
  };

  const handleChangeOrderButton = (event) => {
    if (sortOrder === "asc") {
      setSortOrder("desc");
      return;
    }

    setSortOrder("asc");
  };

  function filterNonZeroValues(arr) {
    return arr.filter((item) => item.score !== 0 || item.name === "base value");
  }

  const handleMouseEnter = (feature) => {
    setHighlightedFeature(feature);
  };

  const handleMouseLeave = () => {
    setHighlightedFeature(null);
  };

  const filteredData = useMemo(() => filterNonZeroValues(data), [data]);

  const sortedData = useMemo(
    () =>
      sortOrder === "desc"
        ? [...filteredData].sort((a, b) => b.score - a.score)
        : [...filteredData].sort((a, b) => a.score - b.score),
    [filteredData, sortOrder]
  );

  return (
    <XBox width="100%" sx={{ background: darkMode ? "#1D1B1B" : "#F7F7F8" }} p={2}>
      {/* <Card>
        <XBox
          width={"100%"}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mb={1}
          px={3}
        >
          <XBox width={"50%"} display="flex">
            <XBox pr={1}>
              <Switch onChange={handleSwitchToggle} />
            </XBox>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
                <Tab
                  label="Waterfall Chart"
                  icon={
                    <i className="ni ni-fat-add" style={{ marginTop: "6px", marginRight: "8px" }} />
                  }
                />
                <Tab
                  label="Bar Chart"
                  icon={
                    <i className="ni ni-fat-add" style={{ marginTop: "6px", marginRight: "8px" }} />
                  }
                />
              </Tabs>
            </AppBar>
          </XBox>
        </XBox>
      </Card> */}
      <XBox display="flex" width="100%" flexDirection={{ sm: "column", lg: "row" }} gap={2}>
        <Card sx={{ width: "60%", p: 2 }}>
          <XBox display="flex" flexDirection="column" gap={1}>
            <XBox
              display="flex"
              sx={{ alignSelf: "flex-end", cursor: "pointer" }}
              onClick={handleChangeOrderButton}
            >
              <XImg>
                <ChangeOrderIcon />
              </XImg>
            </XBox>
            <XBox
              maxHeight={400}
              overflow="scroll"
              width="100%"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {sortedData.map((item) => (
                <XBox
                  key={item.feature}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={1}
                  py={1}
                  my={1}
                  borderBottom="1px solid #EAEAEA"
                  onMouseEnter={() => handleMouseEnter(item.feature)}
                  onMouseLeave={handleMouseLeave}
                >
                  <XBox display="flex" flexDirection="column">
                    <XTypography variant="caption">{item.feature}</XTypography>
                    <XTypography variant="button">{item.value}</XTypography>
                  </XBox>
                  <XBox
                    borderRadius={5}
                    bgColor={Number(item.score < 0) ? colors.xppink.main : colors.xpblue.main}
                    px={1}
                    py={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <XTypography variant="caption" color="white" fontWeight="medium">
                      {Number(item.score).toFixed(2)}
                    </XTypography>
                  </XBox>
                </XBox>
              ))}
            </XBox>
          </XBox>
        </Card>
        <Card sx={{ width: "100%", p: 2 }}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{
                backgroundColor: `${darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,

                transition: "all 500ms ease",
                color: "#AFAFAF",

                "& .Mui-selected": {
                  fontWeight: "600",
                  color: `${darkMode ? "white" : "black"} !important`,
                  backgroundColor: `${darkMode ? "#262525" : "white"} !important`,
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab label="Bar Chart" sx={{ minWidth: 0 }} />
              <Tab label="Waterfall Chart" sx={{ minWidth: 0 }} />
            </Tabs>
          </AppBar>
          <XBox>
            <Chart
              data={sortedData}
              chartType={chartType}
              collapsed={collapsed}
              highlightedFeature={highlightedFeature}
            />
          </XBox>
        </Card>
      </XBox>
    </XBox>
  );
}

export default ExpandableInfo;

ExpandableInfo.propTypes = {
  collapsed: PropTypes.bool,
  data: PropTypes.array,
};
