import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Shimmer from "../../ShimmerEffect";

const MockWaterfallChart = ({ tabsCount = 10 }) => {
  let accumulatedWidth = 0;

  return (
    <Grid container spacing={1} height="100%" p={1}>
      {[...Array(tabsCount)].map((_, i) => {
        let currentWidth;

        if (i === 9) {
          currentWidth = 100; // Set to 100% for the last item to represent the total
        } else {
          currentWidth = Math.floor(Math.random() * 10) + 3; // Random value between 5% and 15%
          accumulatedWidth += currentWidth; // Accumulate width for positioning next bars
        }

        return (
          <Grid key={i} item xs={12} container alignItems="center">
            <Grid item xs={2} height={"100%"} alignItems="center">
              <Shimmer height={"100%"} sx={{ borderRadius: "5px" }} />
            </Grid>
            <Grid
              item
              xs={10}
              height={"100%"}
              display="flex"
              pl={`${i === 9 ? 3 : accumulatedWidth}%`}
            >
              <Shimmer
                width={`${i === 8 ? accumulatedWidth + currentWidth : currentWidth}%`}
                height={"100%"}
                sx={{ borderRadius: "5px" }}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MockWaterfallChart;

MockWaterfallChart.propTypes = {
  tabsCount: PropTypes.number,
};
