import React, { useState, useRef, useEffect, useContext } from "react";
import { useXplainableController } from "context";
import { styled } from "@mui/system";

import ModelContext from "context/ModelContext";
import * as d3 from "d3";

import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import colors from "assets/theme/base/colors";

import XBox from "components/XBox";
import debounce from "lodash/debounce";

import "assets/css/tooltip.css";

function WaterfallChart({
  isCollapsed,
  featureData,
  margin,
  baseValue,
  sort,
  selection,
  isSorted,
  collapsedWidth: propCollapsedWidth,
  height: propHeight,
}) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  //Make the component modular to accept collapse value from parent if not passed through context
  const contextValue = useContext(ModelContext);
  const svgRef = useRef(null);
  const svgContainer = useRef(null); // The PARENT of the SVG

  const collapsedWidth = propCollapsedWidth || contextValue?.collapsedWidth;

  // State to track width and height of SVG Container
  const [width, setWidth] = useState(0);

  const height = propHeight || 600;
  const t = d3.transition().duration(500);

  useEffect(() => {
    const container = svgContainer.current;

    const handleResize = (entries) => {
      for (let entry of entries) {
        const newWidth = entry.contentRect.width;
        setWidth(newWidth - 50);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Observe the container element
    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      // Disconnect the observer when the component unmounts
      resizeObserver.disconnect();
    };
  }, [svgContainer]);

  useEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const x_scale = d3.scaleLinear().range([width, 0]);
    const y_scale = d3.scaleBand().rangeRound([0, height]).padding(0.2);

    const y_axis = d3.axisLeft(y_scale);
    const x_axis = d3.axisBottom(x_scale);

    const sortedData =
      isSorted && featureData
        ? Object.values(featureData).sort((a, b) => a.value - b.value)
        : Object.values(featureData);

    let data =
      featureData && selection[0] > 0
        ? sortedData.reverse().slice(Math.ceil(selection[1]), Math.ceil(selection[0])).reverse()
        : sortedData;

    data.unshift({
      keyName: "Base Value",
      end: baseValue,
      start: 0,
      class: "total",
    });

    let cumulative = baseValue;

    for (var i = 1; i < data.length; i++) {
      data[i].start = cumulative;
      cumulative += data[i].value;
      data[i].end = cumulative;
      data[i].class = data[i].value >= 0 ? "positive" : "negative";
    }

    // Create the keyName values for the featureData
    const dataKeyNamesSet = new Set(data.map((item) => item.keyName));

    let counter = 0;
    const sumValue = Object.values(featureData).reduce((acc, feature) => {
      if (!dataKeyNamesSet.has(feature.keyName) && feature.keyName !== "Base Value") {
        counter += 1;
        acc += feature.value;
      }
      return acc;
    }, 0);

    if (Object.values(featureData).length + 1 !== data.length) {
      data.push({
        keyName: "Other",
        start: cumulative,
        end: cumulative + sumValue,
        class: "other",
      });
    }

    data.push({
      keyName: "Total",
      start: 0,
      end: cumulative + sumValue,
      class: "total",
    });

    const features = data.map(function (d) {
      return d.keyName;
    });

    let y0;

    //Sort the bar chart depending on the sort state value
    if (sort) {
      y0 = y_scale
        .domain(
          data
            .sort(
              true
                ? function (a, b) {
                    return a.value - b.value;
                  }
                : function (a, b) {
                    return d3.ascending(a.value, b.value);
                  }
            )
            .map(function (d) {
              return d.keyName;
            })
        )
        .copy();

      let cumulative = baseValue;
      for (var i = 1; i < data.length - 1; i++) {
        data[i].start = cumulative;
        cumulative += data[i].value;
        data[i].end = cumulative;

        data[i].class = data[i].value >= 0 ? "positive" : "negative";
      }
    } else {
      y0 = y_scale.domain(features);
    }
    const max_value = d3.max(data, function (d) {
      return +d.end;
    });

    const min_value = d3.min(data, function (d) {
      return +d.start;
    });

    //Set the domain of the x_scale
    x_scale.domain([max_value, min_value - (max_value - min_value) / 5]);

    // Update the bars of the scenario analysis
    const s_bars = svg
      .selectAll(".bar")
      .data(data)
      .join("rect")
      .attr("y", function (d) {
        return y0(d.keyName);
      })
      .attr("height", y_scale.bandwidth());

    s_bars
      .transition(t)
      .attr("class", function (d) {
        return "bar " + (d.checked ? "checked" : d.start > d.end ? "negative" : "positive");
      })
      .attr("fill", function (d) {
        return d.keyName === "Total"
          ? colors.gradients.xpgreen.main
          : d.keyName === "Base Value"
          ? "#7d7c87"
          : d.checked
          ? "#7d7c87"
          : d.start < d.end
          ? colors.xpblue.main
          : colors.xppink.main;
      })
      .attr("width", function (d) {
        return `${Math.abs(x_scale(d.start) - x_scale(d.end))}px`;
      })
      .attr("x", function (d) {
        return x_scale(Math.min(d.start, d.end));
      });

    const tooltip = d3.select("#tooltip-profile");

    s_bars.on("mouseenter", onMouseEnter).on("mouseleave", onMouseLeave);

    function onMouseEnter(datum) {
      tooltip.select("#count").text((datum.value * 100).toFixed(2));
      let alphaColor;
      let textColor;

      if (
        datum.keyName === "Total" ||
        datum.keyName === "Base Value" ||
        datum.keyName === "Other"
      ) {
        return;
      }

      const barElement = event.target.getBoundingClientRect().height;

      const styleElement = document.createElement("style");

      const barY = y0(datum.keyName);
      const barX =
        datum.value <= 0
          ? x_scale(Math.min(datum.start, datum.end)) - margin.left - margin.right - 35
          : x_scale(Math.min(datum.start, datum.end)) - margin.left - margin.right - 25;

      if (datum.value <= 0) {
        alphaColor = colors.xpblue.main;
        textColor = colors.white.main;

        // Add the "right-arrow" class to the tooltip to activate the right arrow style
        tooltip.classed("right-arrow", true).classed("left-arrow", false);

        styleElement.innerHTML = `
              .tooltip.right-arrow::before {
                  border-left-color: ${alphaColor};
              }
              .tooltip.right-arrow::after {
                  border-left-color: ${alphaColor}; 
              }
          `;
      } else {
        alphaColor = colors.xppink.main;
        textColor = colors.white.main;

        // Add the "left-arrow" class to the tooltip to activate the left arrow style
        tooltip.classed("right-arrow", true).classed("left-arrow", false);

        styleElement.innerHTML = `
              .tooltip.right-arrow::before {
                  border-left-color: ${alphaColor};
              }
              .tooltip.right-arrow::after {
                  border-left-color: ${alphaColor}; 
              }
          `;
      }
      tooltip
        .style("transform", `translate(${barX}px, ${barY}px`)
        .style("opacity", 1)
        .style("background", alphaColor)
        .style("color", textColor);

      document.head.appendChild(styleElement);
    }

    function onMouseLeave() {
      tooltip.style("opacity", 0);
      tooltip.classed("left-arrow", false); // remove the class to reset the tooltip for next time
    }

    //Call x axis update
    svg.select(".x-axis").attr("transform", `translate(0 ,${height} )`).transition(t).call(x_axis);

    //Call the y axis to update it
    svg
      .select(".y-axis")
      .transition(t)
      .attr("transform", function (d) {
        return "translate(" + x_scale(0) + ", 0)";
      })
      .call(y_axis);

    svg.selectAll("text").style("fill", darkMode ? colors.white.main : colors.black.main);
    svg.selectAll(".domain").style("stroke", darkMode ? colors.white.main : colors.black.main);
  }, [featureData, sort, width, height, selection, isSorted, isCollapsed]);

  return (
    <XBox ref={svgContainer} id="test" display="flex" width="100%">
      <div id="tooltip-profile" className="tooltip">
        <div className="tooltip-value">
          <span id="count" />
        </div>
      </div>
      <svg ref={svgRef}>
        <g className="x-axis" />
        <g className="y-axis" />
      </svg>
    </XBox>
  );
}

export default WaterfallChart;

const FeatureToolTip = (props) => {
  const CustomTooltip = styled(Tooltip)({
    position: "absolute",
    visibility: props.display ? "visible" : "hidden",
    top: `${props.top}px`,
    left: `${props.left}px`,
    zIndex: "20",
  });

  const CustomTooltipContent = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1px",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: props.value < 0 ? "xppink" : "xpblue",
    backgroundColor: "white",
    color: "dark",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      transform: "translateY(-1px)",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    },
  });

  const ValueBox = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "4px",
    padding: "2px",
    borderRadius: "8px",
    backgroundColor: props.value < 0 ? "xppink" : "xpblue",
    color: "white",
    fontSize: "0.875rem",
  });

  return (
    <CustomTooltip title={props.feature}>
      <CustomTooltipContent>
        <div className="text-sm text-gray-500">{props.name}</div>
        <ValueBox>
          {props.percent === true
            ? (parseFloat(props.value).toFixed(2) * 100).toString() + "%"
            : parseFloat(props.value).toFixed(2)}
        </ValueBox>
      </CustomTooltipContent>
    </CustomTooltip>
  );
};

WaterfallChart.propTypes = {
  isCollapsed: PropTypes.bool,
  featureData: PropTypes.any,
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  selectedOption: PropTypes.string,
  plotTitle: PropTypes.string,
  collapsedWidth: PropTypes.number,
  sort: PropTypes.bool,
  percent: PropTypes.bool,
  baseValue: PropTypes.number,
  percent: PropTypes.bool,
  onWidthChange: PropTypes.bool,
  selection: PropTypes.array,
  isSorted: PropTypes.bool,
  collapsedWidth: PropTypes.bool,
  height: PropTypes.number,
};

//Proptypes for the Feature tooltip
FeatureToolTip.propTypes = {
  display: PropTypes.bool,
  top: PropTypes.number,
  left: PropTypes.number,
  value: PropTypes.string,
  feature: PropTypes.string,
  name: PropTypes.string,
  percent: PropTypes.bool,
};
