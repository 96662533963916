import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useDeployKeysMutation = () => {
  const { mutate: createDeployKeyMutation } = useMutation((params) =>
    apiHandlers.deployKeys.createDeployKey(...params)
  );

  const { mutate: deleteDeployKeyMutation } = useMutation((params) =>
    apiHandlers.deployKeys.deleteDeployKey(...params)
  );

  const { mutate: revokeAllDeployKeysMutation } = useMutation((params) =>
    apiHandlers.deployKeys.revokeAllDeployKeys(...params)
  );

  return {
    createDeployKeyMutation,
    deleteDeployKeyMutation,
    revokeAllDeployKeysMutation,
  };
};
