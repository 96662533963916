import React from 'react';
import { Box } from '@mui/material';
import Shimmer from '../../ShimmerEffect';

const MockThresholdPlot = () => {
    // Mock heights for bars in a normally distributed histogram
    const barHeights = ['10%', '20%', '40%', '70%', '90%', '90%', '70%', '40%', '20%', '10%'];

    return (
        <div style={{  height: '100%' }}>

            {/* Slider section at the top */}
            <div style={{ marginBottom: '20px' }}>
                <Shimmer width="100%" height="5%" />
            </div>

            {/* Bars and Lines */}
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', height: '90%' }}>
                {barHeights.map((height, index) => (
                    <div key={index} style={{ flex: 1, display: 'flex', flexDirection: 'column-reverse' }}>
                        <Shimmer width="90%" height={height} sx={{ alignSelf: 'center', borderRadius:"5px" }} />
                    </div>
                ))}

                {/* Line and Points */}
                <Shimmer width="2px" height="100%" position="absolute" left="0" top="0" sx={{ zIndex: 2 }} />
                <Shimmer shape="circular" width="10px" height="10px" position="absolute" left="49%" top="45%" sx={{ zIndex: 3 }} />
                <Shimmer shape="circular" width="10px" height="10px" position="absolute" left="49%" top="65%" sx={{ zIndex: 3 }} />
            </div>
        </div>
    );
};

export default MockThresholdPlot;


