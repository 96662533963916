// xplainable dashboard base styles
import colors from "assets/theme-dark/base/colors";

// xplainable dashboard helper functions
import rgba from "assets/theme-dark/functions/rgba";

const { info, white, gradients, background, text, dark } = colors;

const flatpickr = {
  ".flatpickr-calendar": {
    background: background.dark,
    boxShadow: "none",
  },

  ".flatpickr-prev-month > *, .flatpickr-month  > *, .flatpickr-next-month  > *, span.flatpickr-weekday, .flatpickr-day":
    {
      fill: text.main,
      color: text.main,
    },

  ".flatpickr-day.inRange": {
    background: `${dark.main} !important`,
    borderColor: `${dark.main} !important`,
    boxShadow: `-5px 0 0 ${dark.main}, 5px 0 0 ${dark.main}`,
  },

  ".flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay": {
    color: rgba(text.main, 0.5),
  },

  ".flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus":
    {
      background: rgba(info.main, 0.28),
      border: "none",
    },

  ".flatpickr-day.today": {
    background: info.main,
    color: white.main,
    border: "none",

    "&:hover, &:focus": {
      background: `${info.focus} !important`,
    },
  },

  ".flatpickr-day.selected, .flatpickr-day.selected:hover, .flatpickr-day.nextMonthDay.selected, .flatpickr-day.nextMonthDay.selected:hover, .flatpickr-day.nextMonthDay.selected:focus":
    {
      background: `${background.default} !important`,
      color: white.main,
      border: "none",
    },

  ".flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg":
    {
      color: `${info.main} !important`,
      fill: `${info.main} !important`,
    },
};

export default flatpickr;
