import { useAuth0 } from "@auth0/auth0-react";
import { useDatasetQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useState } from "react";

export const useDatasets = () => {
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();
  const { data: dataSets, isLoading } = useDatasetQuery(activeWorkspace, logout);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToReplace, setFileToReplace] = useState("");

  const handleOpenReplaceModal = (item) => {
    setFileToReplace(item.dataset_id);
    setIsModalOpen(true);
  };

  const handleOpenModal = () => {
    setFileToReplace("");
    setIsModalOpen(true);
  };

  return {
    dataSets,
    setIsModalOpen,
    isLoading,
    isModalOpen,
    fileToReplace,
    handleOpenReplaceModal,
    handleOpenModal,
  };
};
