import React from "react";
import { Box } from "@mui/material";

const MockLineChart = () => {
  return (
    <div
      style={{
        padding: "20px",
        background: "#fff",
        borderRadius: "8px",
        width: "100%",
        position: "relative",
        height: "100%",
      }}
    >
      {/* Mock Icon */}
      <Box
        sx={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
        }}
      />

      {/* Mock Title and Total */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            width: "150px",
            height: "20px",
            background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
          }}
        />
        <Box
          sx={{
            width: "80px",
            height: "30px",
            background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
          }}
        />
      </Box>

      {/* Mock Line Chart */}
      <Box
        sx={{
          height: "150px",
          background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
          position: "relative",
          overflow: "hidden",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "5px",
            background: "#e0e0e0",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
      </Box>

      {/* Mock Date Labels */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "60px",
            height: "15px",
            background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
          }}
        />
        <Box
          sx={{
            width: "60px",
            height: "15px",
            background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
          }}
        />
        <Box
          sx={{
            width: "60px",
            height: "15px",
            background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
          }}
        />
      </Box>

      {/* Mock Select Period */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            width: "200px",
            height: "40px",
            background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
            borderRadius: "20px",
          }}
        />
      </Box>
    </div>
  );
};

export default MockLineChart;
