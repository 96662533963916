import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useRunBatchesQuery = (activeWorkspace, optimiser_id, version_id, run_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.RUN_BATCHES, activeWorkspace, optimiser_id, version_id, run_id],
    () =>
      apiHandlers.batchOptimisation.getRunBatches(
        activeWorkspace,
        optimiser_id,
        version_id,
        run_id
      ),

    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
