// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

function PlaceholderCard({
  icon,
  title,
  hasBorder,
  outlined,
  onClick,
  height = "100%",
  width = "100%",
}) {
  return (
    <Card
      raised
      sx={({ borders: { borderWidth, borderColor } }) => ({
        height: height,
        backgroundColor: outlined && "transparent",
        boxShadow: outlined && "none",
        borderStyle: "dashed",
        border: hasBorder || outlined ? `${borderWidth[1]} solid ${borderColor}` : "none",
        width,
      })}
      onClick={onClick}
    >
      <XBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        height="100%"
        p={3}
      >
        <XBox color="secondary" mb={0.5}>
          <Icon fontSize="default" sx={{ fontWeight: "bold" }}>
            {icon}
          </Icon>
        </XBox>
        <XTypography variant={title.variant} color="secondary">
          {title.text}
        </XTypography>
      </XBox>
    </Card>
  );
}

// Setting default values for the props of PlaceholderCard
PlaceholderCard.defaultProps = {
  icon: "add",
  hasBorder: false,
  outlined: false,
};

// Typechecking props for the PlaceholderCard
PlaceholderCard.propTypes = {
  icon: PropTypes.node,
  height: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  hasBorder: PropTypes.bool,
  outlined: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PlaceholderCard;
