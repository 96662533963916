import React, { useRef, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { Tooltip, Icon, AppBar, Tabs, Tab } from "@mui/material";

import XBox from "components/XBox";
import XTypography from "components/XTypography";

import colors from "assets/theme/base/colors";
import "assets/css/tooltip.css";
import { useModel } from "hooks";
import { useXplainableController } from "context";
import { debounce } from "lodash";

/**
 * Component that renders a BrushChart2Child
 */

function ProfileBrushChart2Child({
  data,
  margin,
  selection,
  type,
  plotTitle,
  tabsOrientation,
  tabValue,
  handleSetTabValue,
}) {
  const { collapsedWidth } = useModel();

  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const [isNumeric, setIsNumeric] = useState(false);
  const [isLinePlot, setisLinePlot] = useState(false);
  const containerRef = useRef(null); // The PARENT of the SVG
  const svgRef = useRef();

  // State to track width and height of SVG Container
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(500);

  // This assumes selection is an array like [startIndex, endIndex]
  const lowerIndex =
    selection[0] - Math.floor(selection[0]) >= 0.5
      ? Math.ceil(selection[0])
      : Math.floor(selection[0]);
  const upperIndex =
    selection[1] + Math.floor(selection[1]) >= 0.5
      ? Math.ceil(selection[1])
      : Math.floor(selection[1]);

  const selectedData = selection ? data.slice(Math.max(0, lowerIndex), upperIndex) : data;

  const parseRange = (name) => {
    // Check if the name indicates a lower bound
    if (name.startsWith("< ")) {
      const value = name.replace("< ", "").replace(/,/g, "");
      return [-Infinity, +value]; // Lower bound with -Infinity as the start
    }

    // Check if the name indicates an upper bound
    else if (name.startsWith("> ")) {
      const value = name.replace("> ", "").replace(/,/g, "");
      return [+value, Infinity]; // Upper bound with Infinity as the end
    }

    // Handle normal range
    else {
      const [start, end] = name.split(" - ").map((str) => str.replace(/,/g, ""));
      return [+start, end === "Infinity" ? Infinity : +end];
    }
  };

  // This function calculates width and height of the container
  const getSvgContainerSize = () => {
    const newWidth = containerRef.current.clientWidth - margin.left - margin.right;
    setWidth(newWidth);
  };

  useEffect(() => {
    // detect 'width' and 'height' on render
    getSvgContainerSize();

    // Create a debounced version of getSvgContainerSize
    const debouncedGetSvgContainerSize = debounce(() => {
      getSvgContainerSize();
    }, 100); // Debounce for 250ms

    // listen for resize changes
    window.addEventListener("resize", debouncedGetSvgContainerSize);

    // cleanup event listener on component unmount
    return () => {
      debouncedGetSvgContainerSize.cancel(); // lodash's debounce provides a cancel method to cancel delayed invocations
      window.removeEventListener("resize", debouncedGetSvgContainerSize);
    };
  }, [collapsedWidth]);

  useEffect(() => {
    if (selectedData && selectedData[0]?.name) {
      setIsNumeric(!isNaN(parseRange(selectedData[0]?.name)[0]));
    }
  }, [selectedData]);

  //Update the charts
  useEffect(() => {
    UpdateChart(selectedData);
  }, [type]);

  useEffect(() => {
    FlushChart();
    DrawChart(selectedData);
  }, [selectedData, width, height, isLinePlot]);

  const FlushChart = () => {
    d3.select(svgRef.current).selectAll("*").remove();
  };

  const DrawChart = (data) => {
    // Reset the SVG
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + (margin.left + 20) + "," + margin.top + ")");

    // Create an xScale based on data values
    const xScale = d3.scaleLinear().range([0, width - 30]);

    let domain = d3.extent(data, function (d) {
      return d.value;
    });

    if (domain[0] < 0 && domain[1] < 0) {
      domain = [domain[0], 0];
    }
    if (domain[0] > 0 && domain[1] > 0) {
      domain = [0, domain[1]];
    }

    xScale.domain(domain);

    // Add the X Axis
    //TODO: Rotate axis bottom text
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .attr("dx", "1.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(65)")
      .attr("opacity", 0.1)
      .transition()
      .duration(1000)
      .attr("opacity", 1);

    svg
      .append("text")
      .attr("class", "x label")
      .attr("text-anchor", "middle")
      .attr("width", (width + margin.left + margin.right) / 2)
      .attr("max-width", "50px")
      .attr("font-size", "18px")
      .attr("x", (width - 30) / 2)
      .attr("y", height + 55)
      .style("fill", darkMode ? colors.white.main : colors.black.main)
      .text(function () {
        return plotTitle;
      });

    let yScale;

    //TODO: Add back in the functionality of viewing this as a line plot
    if (isNumeric && isLinePlot) {
      // Create a yScale based on data names
      yScale = d3
        .scaleLinear()
        .range([0, height])
        .domain([
          parseRange(selectedData[0]?.name)[1],
          parseRange(selectedData[selectedData.length - 1]?.name)[0],
        ]);
    } else {
      // Create a yScale based on data names
      yScale = d3
        .scaleBand()
        .range([0, height])
        .domain(
          data.map(function (d) {
            return d.name;
          })
        )
        .padding(1);
    }

    let yAxis = svg
      .append("g")
      .attr("transform", "translate(" + xScale(0) + ",0)")
      .call(d3.axisLeft(yScale));

    yAxis
      .selectAll(".tick")
      .data(data)
      .select("text")
      .attr("x", function (d, i) {
        return d.value < 0 ? 9 : -9;
      })
      .style("text-anchor", function (d, i) {
        return d.value < 0 ? "start" : "end";
      })
      .style("font-size", 10);

    if (isNumeric && isLinePlot) {
      // Prepare data for step line
      let stepData = [];
      let lastSign = 0;

      data.forEach((d, i) => {
        console.log(d);
        let range = parseRange(d.name);
        let lowerBound = range[0] === -Infinity ? range[1] - range[1] * 0.01 : range[0];
        let upperBound = range[1] === Infinity ? range[0] + range[0] * 0.01 : range[1];

        let currentDataPoint1 = { value: d.value, bound: lowerBound, colorValue: d.value };
        let currentDataPoint2 = { value: d.value, bound: upperBound, colorValue: d.value };

        if (i === 0 || Math.sign(lastSign) !== Math.sign(d.value)) {
          let zeroPointStart = { value: 0, bound: lowerBound, colorValue: lastSign };
          stepData.push([zeroPointStart, currentDataPoint1, currentDataPoint2]);
        } else {
          stepData[stepData.length - 1].push(currentDataPoint1, currentDataPoint2);
        }

        if (i < data.length - 1 && Math.sign(data[i].value) !== Math.sign(data[i + 1].value)) {
          let zeroPointEnd = { value: 0, bound: upperBound, colorValue: d.value };
          stepData[stepData.length - 1].push(zeroPointEnd);
        }

        lastSign = d.value;
      });

      // Define the line generator
      const lineGenerator = d3
        .line()
        .x((d) => xScale(d.value))
        .y((d) => yScale(d.bound));

      // Draw each line segment using the line generator and fill the area
      stepData.forEach((segment) => {
        // Draw the line segment
        let line = svg
          .append("path")
          .datum(segment)
          .attr("fill", "none")
          .attr("stroke", segment[1].colorValue < 0 ? colors.xppink.main : colors.xpblue.main)
          .attr("stroke-width", 4)
          .attr("d", lineGenerator);

        // Initialize at x = 0
        line.attr(
          "d",
          lineGenerator.x(() => xScale(0))
        );

        line
          .transition()
          .duration(500) // Change this to adjust the speed of the transition
          .attr(
            "d",
            lineGenerator.x((d) => xScale(d.value))
          );
      });
    } else {
      //Allow bar height to be dynamic
      let barHeight = 90 / data.length ** 0.8;
      barHeight = barHeight < 25 ? barHeight : 25;

      // Define gradients
      const defs = svg.append("defs");

      // const gradientPositive = defs
      //   .append("linearGradient")
      //   .attr("id", "gradientPositive")
      //   .attr("x1", "0%")
      //   .attr("y1", "100%")
      //   .attr("x2", "100%")
      //   .attr("y2", "100%");

      // gradientPositive
      //   .append("stop")
      //   .attr("offset", "0%")
      //   .attr("stop-color", colors.gradients.xpblue.state);

      // gradientPositive
      //   .append("stop")
      //   .attr("offset", "100%")
      //   .attr("stop-color", colors.gradients.xpblue.main);

      // const gradientNegative = defs
      //   .append("linearGradient")
      //   .attr("id", "gradientNegative")
      //   .attr("x1", "0%")
      //   .attr("y1", "100%")
      //   .attr("x2", "100%")
      //   .attr("y2", "100%");

      // gradientNegative
      //   .append("stop")
      //   .attr("offset", "0%")
      //   .attr("stop-color", colors.gradients.xppink.main);

      // gradientNegative
      //   .append("stop")
      //   .attr("offset", "100%")
      //   .attr("stop-color", colors.gradients.xppink.state);

      const bars = svg
        .selectAll("bar")
        .data(data)
        .enter()
        .append("path")
        .attr("class", function (d) {
          return "bar--" + (d.value < 0 ? "negative" : "positive");
        })
        .attr("fill", function (d) {
          return d.value < 0 ? colors.xppink.main : colors.xpblue.main;
        });

      // Add transition to bars
      bars
        .transition()
        .duration(500)
        .attrTween("d", function (d) {
          const interpolate = d3.interpolate(0, Math.abs(xScale(d.value) - xScale(0)));
          const yValue = yScale(d.name) - barHeight / 2;
          const radius = Math.abs(d.value) < 0.5 ? 0 : barHeight / 5;

          return function (t) {
            const barWidth = interpolate(t);

            let pathString;
            if (d.value >= 0) {
              pathString = `
                M ${xScale(0)},${yValue}
                h ${barWidth - radius}
                q ${radius},0 ${radius},${radius}
                v ${barHeight - 2 * radius}
                q 0,${radius} -${radius},${radius}
                h -${barWidth - radius}
                z
              `;
            } else {
              pathString = `
                M ${xScale(0)},${yValue + barHeight}
                h -${barWidth - radius}
                q -${radius},0 -${radius},-${radius}
                v -${barHeight - 2 * radius}
                q 0,-${radius} ${radius},-${radius}
                h ${barWidth - radius}
                z
              `;
            }
            return pathString;
          };
        });

      const tooltip = d3.select("#tooltip-profile");

      bars.on("mouseenter", onMouseEnter).on("mouseleave", onMouseLeave);

      function onMouseEnter(datum) {
        tooltip.select("#count").text((datum.value * 100).toFixed(2));
        let alphaColor;
        let barX;
        let textColor;

        const tooltipElement = tooltip.node();
        const barWidth = Math.abs(xScale(datum.value) - xScale(0));
        const tooltipElementWidth = tooltipElement.getBoundingClientRect().width;

        const barY = yScale(datum.name);

        // Set the alpha color for the ::before and ::after pseudo-element
        const styleElement = document.createElement("style");

        if (datum.value <= 0) {
          barX = xScale(datum.value) - tooltipElementWidth + 16;
          alphaColor = colors.xppink.main;
          textColor = colors.white.main;

          // Add the "right-arrow" class to the tooltip to activate the right arrow style
          tooltip.classed("right-arrow", true).classed("left-arrow", false);

          styleElement.innerHTML = `
                .tooltip.right-arrow::before {
                    border-left-color: ${alphaColor};
                }
                .tooltip.right-arrow::after {
                    border-left-color: ${alphaColor}; 
                }
            `;
        } else {
          barX = xScale(0) + barWidth + 26 + 15;
          alphaColor = colors.xpblue.main;
          textColor = colors.white.main;

          // Add the "left-arrow" class to the tooltip to activate the left arrow style
          tooltip.classed("left-arrow", true).classed("right-arrow", false);

          styleElement.innerHTML = `
                .tooltip.left-arrow::before {
                    border-right-color: ${alphaColor};
                }
                .tooltip.left-arrow::after {
                    border-right-color: ${alphaColor}; 
                }
            `;
        }
        tooltip
          .style("transform", `translate(${barX}px, ${barY}px`)
          .style("opacity", 1)
          .style("background", alphaColor)
          .style("color", textColor)
          .style("z-index", 9999);

        document.head.appendChild(styleElement);
      }

      svg.selectAll("text").style("fill", darkMode ? colors.white.main : colors.black.main);
      svg.selectAll(".domain").style("stroke", darkMode ? colors.white.main : colors.black.main);

      function onMouseLeave() {
        tooltip.style("opacity", 0);
        tooltip.classed("left-arrow", false); // remove the class to reset the tooltip for next time
      }
    }
  };

  const UpdateChart = (data) => {
    const svg = d3.select(svgRef.current);

    svg
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom)
      .append("svg:g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Add x-axis to the plot
    const xScale = d3.scaleLinear().range([0, width]);

    //Return the domain
    let domain = d3.extent(data, function (d) {
      return d.value;
    });

    //Set to 0 if both values of same sign
    if (domain[0] < 0 && domain[1] < 0) {
      domain = [domain[0], 0];
    }
    if (domain[0] > 0 && domain[1] > 0) {
      domain = [0, domain[1]];
    }

    xScale.domain(domain);
    svg
      .selectAll(".bar")
      .data(data)
      .transition()
      .duration(1000)
      .attr("x", function (d) {
        return xScale(Math.min(0, d.value));
      }) //Update this if you want to go into the negatives
      .attr("width", function (d) {
        return Math.abs(xScale(d.value) - xScale(0));
      });
  };

  return (
    <XBox width="100%">
      <XBox display="flex" alignItems="center" justifyContent="space-between">
        <XBox lineHeight={1}>
          <XTypography variant="button" fontWeight="light" textTransform="capitalize" color="text">
            Profile Feature Values
          </XTypography>
          <XTypography variant="h5" fontWeight="bold">
            {`${selectedData.length}/${data.length} feature values`}
          </XTypography>
        </XBox>
        {isNumeric && (
          <Tooltip
            title={
              isNumeric && isLinePlot
                ? "Show bars over discrete range"
                : "Show line chart over continuous range"
            }
          >
            <XBox
              width="2.6rem"
              height="2.6rem"
              display="grid"
              justifyContent="center"
              alignItems="center"
              borderRadius="lg"
              shadow="md"
              color="white"
              bgColor="xpblue"
              variant="gradient"
              onClick={() => {
                isNumeric ? setisLinePlot(!isLinePlot) : "";
              }}
            >
              <Icon fontSize={"28px"}>
                {isNumeric ? (isLinePlot ? "bar_chart" : "show_chart") : "bar_chart"}
              </Icon>
            </XBox>
          </Tooltip>
        )}
      </XBox>
      <AppBar position="static">
        <Tabs
          orientation={tabsOrientation}
          value={tabValue}
          onChange={handleSetTabValue}
          sx={{
            backgroundColor: `${darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,

            transition: "all 500ms ease",
            color: "#AFAFAF",

            "& .Mui-selected": {
              fontWeight: "600",
              color: `${darkMode ? "white" : "black"} !important`,
              backgroundColor: `${darkMode ? "#262525" : "white"} !important`,
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          <Tab label="Contribution" sx={{ minWidth: 0, fontSize: "0.8rem" }} />
          <Tab label="Mean" sx={{ minWidth: 0, fontSize: "0.8rem" }} />
          <Tab label="Frequency" sx={{ minWidth: 0, fontSize: "0.8rem" }} />
        </Tabs>
      </AppBar>
      <XBox ref={containerRef} position="relative">
        <div id="tooltip-profile" className="tooltip">
          <div className="tooltip-framework">
            <span id="framework" />
          </div>
          <div className="tooltip-value">
            <span id="count" />%
          </div>
        </div>
        <svg ml={1} ref={svgRef}>
          <defs>
            <clipPath id="myClipPath">
              <rect x="0" y="0" width="100%" height="100%" />
            </clipPath>
          </defs>
          <g className="content" clipPath="url(#myClipPath)"></g>
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </XBox>
    </XBox>
  );
}

export default ProfileBrushChart2Child;

ProfileBrushChart2Child.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  selection: PropTypes.arrayOf(PropTypes.number),
  type: PropTypes.string,
  plotTitle: PropTypes.string,
  svgContainer: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  onBarClick: PropTypes.func,
  tabsOrientation: PropTypes.string,
  tabValue: PropTypes.number,
  handleSetTabValue: PropTypes.func,
};

const barRounded = (x, y, width, height, radius) => `
  M ${x}, ${y}
  H ${x + width - radius}
  a ${radius},${radius} 0 0 1 ${radius},${radius}
  V ${y + height - radius}
  a ${radius},${radius} 0 0 1 ${-radius},${radius}
  H ${x}
  Z
`;
