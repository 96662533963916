import { useCommentsMutation } from "api/mutations";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import { useEffect, useState } from "react";
import { handleTokenExpired } from "utils";

export const useReply = ({ reply, id, fetchedComments, setFetchedComments }) => {
  const { comment_id, comment: text, like_count: like } = reply;

  const [controller] = useXplainableController();

  const { user } = useApiKey();
  const { editCommentMutation, deleteCommentMutation } = useCommentsMutation();

  const [isEditingReply, setIsEditingReply] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [editedText, setEditedText] = useState(text);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const { darkMode } = controller;

  const getInitials = (given_name, family_name) => {
    return given_name?.charAt(0).toUpperCase() + family_name?.charAt(0).toUpperCase();
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEditComment = () => {
    setIsEditingReply(true);
  };

  const escFunction = (event) => {
    if (event.key === "Escape" && isEditingReply) {
      setIsEditingReply(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const data = JSON.stringify({
        resource_id: id,
        comment_id: comment_id,
        comment: editedText,
        comment_id: comment_id,
        resource_id: id,
      });

      editCommentMutation([data], {
        onSuccess: () => {
          const updatedCommentList = fetchedComments.map((comment) => {
            if (comment.replies && comment.replies.length > 0) {
              comment.replies = comment.replies.map((reply) => {
                if (reply.comment_id === comment_id) {
                  reply.comment = editedText;
                }
                return reply;
              });
            }
            return comment;
          });
          setFetchedComments(updatedCommentList);
          setIsEditingReply(false);
        },
        onError: (error) => {
          console.log(error);
          handleTokenExpired();
        },
      });
    }
  };

  const handleDeleteComment = () => {
    deleteCommentMutation([comment_id], {
      onSuccess: () => {
        const updatedCommentList = fetchedComments.map((comment) => {
          if (comment.replies && comment.replies.length > 0) {
            comment.replies = comment.replies.filter((reply) => reply.comment_id !== comment_id);
          }
          return comment;
        });
        setFetchedComments(updatedCommentList);
      },
      onError: (error) => {
        console.log(error);
        handleTokenExpired();
      },
    });
  };

  return {
    user,
    isEditingReply,
    likeCount,
    menuAnchorEl,
    setMenuAnchorEl,
    darkMode,
    getInitials,
    editedText,
    setEditedText,
    handleMenuClose,
    handleEditComment,
    handleKeyPress,
    handleDeleteComment,
  };
};
