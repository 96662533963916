import { Card } from "@mui/material";
import { animateGradient } from "assets/theme/base/animations";
import XBox from "components/XBox";
import { useXplainableController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useSettingTab } from "hooks";
import {
  AnalystAppBar,
  ProgressBlock,
  SettingTab,
  UploadDataset,
} from "layouts/autotrain/components";
import { useAutoTrainContent } from "layouts/autotrain/components/AutoTrainContent/useAutoTrainContent";
import React, { useState } from "react";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import { AnalystChat, AnalystSavedPlots } from "./components";

export const Analyst = () => {
  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;

  const [analystTabValue, setAnalystTabValue] = useState(0);
  const [isOpenSavedPlots, setIsOpenSavedPlots] = useState(false);

  const {
    isModalOpen,
    handleClose,
    modelSettings,
    modelHandler,
    modelProviderHandler,
    maxTokenHandler,
    temperatureHandler,
    frequencyPenaltyHandler,
    presencePenaltyHandler,
  } = useSettingTab();

  const {
    uploadSuccessful,
    isLoading,
    isDataLoading,
    uploadProgress,
    fileNames,
    getRootProps,
    getInputProps,
    onDropAccepted,
    setDataSetName,
    fileMetadata,
  } = useAutoTrainContent(modelSettings);

  return (
    <DashboardLayout
      sx={{
        backgroundImage: () =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title="Analyst"
        description={
          "One-click shareable reports with intuitive drag-and-drop functionality"
        }
        icon={"ni ni-atom"}
      />

      <AnalystAppBar
        onAnalystTab={setAnalystTabValue}
        analystTabValue={analystTabValue}
        setIsOpenSavedPlots={setIsOpenSavedPlots}
        isAnalyst
      />

      {analystTabValue === 0 && !uploadSuccessful && !isLoading && (
        <Card sx={{ padding: "16px" }}>
          <UploadDataset
            fileNames={fileNames}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            onDropAccepted={onDropAccepted}
            setDataSetName={setDataSetName}
          />
        </Card>
      )}

      {analystTabValue === 0 && (
        <>
          {fileNames.length > 0 && uploadProgress ? (
            <ProgressBlock
              uploadProgress={uploadProgress}
              fileNames={fileNames}
              fileMetadata={fileMetadata}
            />
          ) : uploadSuccessful ? (
            <XBox display="flex" flexDirection="column" gap={3}>
              {isDataLoading ? (
                <XBox
                  sx={{
                    height: "500px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <LoadingSpinner animationType="pulse" size={50} />
                </XBox>
              ) : (
                <AnalystChat
                  modelSettings={modelSettings}
                  uploadSuccessful={uploadSuccessful}
                  isDataLoading={isDataLoading}
                  uploadProgress={uploadProgress}
                />
              )}
            </XBox>
          ) : (
            <div></div>
          )}
        </>
      )}

      {isOpenSavedPlots && (
        <AnalystSavedPlots setIsOpenSavedPlots={setIsOpenSavedPlots} />
      )}

      <XBox>
        {analystTabValue === 1 && (
          <SettingTab
            isModalOpen={isModalOpen}
            handleClose={handleClose}
            modelSettings={modelSettings}
            modelProviderHandler={modelProviderHandler}
            modelHandler={modelHandler}
            maxTokenHandler={maxTokenHandler}
            temperatureHandler={temperatureHandler}
            frequencyPenaltyHandler={frequencyPenaltyHandler}
            presencePenaltyHandler={presencePenaltyHandler}
          />
        )}
      </XBox>
    </DashboardLayout>
  );
};
