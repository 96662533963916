import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

import XBox from "components/XBox";
import XSelect from "components/XSelect";
import breakpoints from "assets/theme/base/breakpoints";

// import BrushChart from './components/FeatureChart/BrushChart';
import BrushChart from "./components/FeatureChart/BrushChart";
import BrushChart2Child from "./components/FeatureChart/BrushChartChild";

import ProfileBrushChart from "./components/ProfileChart/ProfileBrushChart";
import ProfileBrushChart2Child from "./components/ProfileChart/ProfileBrushChartChild";
import { useModel } from "hooks";

function getDescription(feature, array) {
  let item = array.find((item) => item.feature === feature);
  return item ? item.description : null;
}

const ModelProfile = React.memo(
  ({ profileData, firstFeature, featureData, firstRenderData, modelType }) => {
    const { selectedVersion, featureDescriptionData, selectedPartition } = useModel();

    const [selectedValue, setSelectedValue] = useState({
      value: firstFeature.toLowerCase().replace(/ /g, "_"),
      label: firstFeature,
    });

    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    const [type, setType] = useState("score");
    const [filteredData, setFilteredData] = useState(firstRenderData);
    const [profileTitle, setProfileTitle] = useState(firstFeature + " contribution");

    const sortedData = featureData.sort((a, b) => a.value - b.value);

    //Update the tab values of the plots
    useEffect(() => {
      // A function that sets the orientation state of the tabs.
      function handleTabsOrientation() {
        return window.innerWidth < breakpoints.values.sm
          ? setTabsOrientation("vertical")
          : setTabsOrientation("horizontal");
      }

      /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
      window.addEventListener("resize", handleTabsOrientation);

      // Call the handleTabsOrientation function to set the state with the initial value.
      handleTabsOrientation();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    //Update the profile data
    const tabs = {
      0: "score",
      1: "mean",
      2: "frequency",
    };

    useEffect(() => {
      updateProfileData();
      setProfileTitle(selectedValue.label + " " + tabs[tabValue]);
    }, [tabValue, selectedValue, selectedVersion, selectedPartition]);

    useEffect(() => {
      const data = sortedData.reverse()[0];
      setSelectedValue({
        value: data.name.toLowerCase().replace(/ /g, "_"),
        label: data.name,
      });
    }, []);

    const updateProfileData = () => {
      let data = dataManipulation(profileData, selectedValue.label, tabs[tabValue], modelType);

      setFilteredData(data);
    };

    const dropdownHandler = (value) => {
      setSelectedValue(value);
    };

    return (
      <XBox>
        <XBox>
          <XBox width="100%" mb={2}>
            <XSelect
              placeholder="Select Feature"
              value={selectedValue}
              options={featureData.map((feature) => ({
                value: feature.name.toLowerCase().replace(/ /g, "_"),
                label: feature.name,
              }))}
              onChange={dropdownHandler}
              sx={{ zIndex: 100 }}
            />
          </XBox>

          <XBox
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
          >
            <Grid item xs={12} lg={5.9}>
              <Card sx={{ height: "750px", padding: "16px", position: "static" }}>
                {sortedData.length > 0 && (
                  <BrushChart data={sortedData}>
                    {(selection) => (
                      <BrushChart2Child
                        data={sortedData}
                        selection={selection}
                        onBarClick={setSelectedValue}
                        selectedBar={selectedValue.label}
                        margin={{
                          top: 0,
                          right: -10,
                          bottom: 60,
                          left: 80,
                        }}
                      />
                    )}
                  </BrushChart>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} lg={5.9}>
              <Card
                sx={{ height: "750px", padding: "16px", position: "static", overflow: "unset" }}
              >
                {filteredData && (
                  <ProfileBrushChart data={[...filteredData]}>
                    {(selection) => (
                      <ProfileBrushChart2Child
                        tabsOrientation={tabsOrientation}
                        tabValue={tabValue}
                        handleSetTabValue={handleSetTabValue}
                        data={filteredData}
                        margin={{ top: 24, right: 0, bottom: 60, left: 20 }}
                        selection={selection}
                        type={type}
                        plotTitle={profileTitle}
                      />
                    )}
                  </ProfileBrushChart>
                )}
              </Card>
            </Grid>
          </XBox>
        </XBox>
      </XBox>
    );
  }
);

export default ModelProfile;

//Functon to seperate number with commas
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dataManipulation = (data, selectedGroup, type, docType) => {
  console.log("The profile data is", data);

  const groupData = data.categorical[selectedGroup] || data.numeric[selectedGroup];
  const new_data = [];
  let value;

  if (!groupData) {
    console.error("Selected group not found in the data");
    return new_data;
  }

  groupData.forEach((obj) => {
    if (type === "score") {
      value = docType === "binary" && obj.score !== null ? obj.score * 100 : obj.score;
    } else if (type === "frequency") {
      value = obj.freq !== null ? obj.freq * 100 : obj.freq;
    } else if (type === "mean") {
      value = docType === "binary" && obj.mean !== null ? obj.mean * 100 : obj.mean;
    }

    // Skip this iteration if lower and upper are both falsy and score is 0
    if (!obj.lower && !obj.upper && obj.score === 0) {
      return;
    }

    if ("category" in obj) {
      new_data.push({
        name: obj.category ? obj.category : "NaN",
        value: value,
      });
    } else {
      let range;
      if (!obj.lower && obj.upper) {
        range = "< " + numberWithCommas(Number(obj.upper).toFixed(2));
      } else if (obj.lower && !obj.upper) {
        range = "> " + numberWithCommas(Number(obj.lower).toFixed(2));
      } else if (obj.lower && obj.upper) {
        let lower = numberWithCommas(Number(obj.lower).toFixed(2));
        let upper = numberWithCommas(Number(obj.upper).toFixed(2));
        range = lower + " - " + upper;
      } else if (!obj.lower && !obj.upper) {
        range = "NaN";
      }

      new_data.push({ name: range, value: value });
    }
  });

  return new_data;
};

// Typechecking props for the DataTable
ModelProfile.propTypes = {
  model_id: PropTypes.any,
  version: PropTypes.any,
  partition: PropTypes.any,
  label: PropTypes.string,
  profileData: PropTypes.any,
  firstFeature: PropTypes.any,
  featureData: PropTypes.any,
  featureDescriptionData: PropTypes.any,
  firstRenderData: PropTypes.any,
  modelType: PropTypes.any,
};
