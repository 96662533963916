import React, { useEffect, useState } from "react";

import XBox from "components/XBox";

import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import { BatchOverview, BatchAnalysis, OptimisationMade, Batch } from "./components";

import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useDownloadBatchQuery, useRunBatchesQuery, useRunQuery } from "api/query";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

export const Run = ({ setActiveStep }) => {
  const { id } = useParams();
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const url = new URL(window.location.href);
  const run_id = url.searchParams.get("run_id");
  const version_id = url.searchParams.get("version_id");

  const [isLoading, setIsLoading] = useState(true);
  const [runData, setRunData] = useState(null);
  const [activeBatch, setActiveBatch] = useState(null);
  const [batches, setBatches] = useState(null);
  const [batchAnalysis, setBatchAnalysis] = useState({
    averageScore: null,
    totalExpectedCost: null,
    percentChange: null,
    absoluteDifference: null,
  });
  const [downloadBatchId, setDownloadBatchId] = useState(null);

  const {
    data,
    isLoading: isBatchesLoading,
    refetch,
  } = useRunQuery(activeWorkspace, id, version_id, run_id, logout);

  const { data: runBatchesData, isLoading: isRunDataLoading } = useRunBatchesQuery(
    activeWorkspace,
    id,
    version_id,
    run_id,
    logout
  );

  const { data: downloadData } = useDownloadBatchQuery(
    activeWorkspace,
    id,
    version_id,
    downloadBatchId,
    logout
  );

  useEffect(() => {
    if (!downloadData || !downloadBatchId) return;
    const url = window.URL.createObjectURL(new Blob([downloadData.data]));

    const link = document.createElement("a");
    link.href = url;
    link.download = downloadData.data.type;

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
    setDownloadBatchId(null);
  }, [downloadData, downloadBatchId]);

  useEffect(() => {
    if (!data) return;

    if (data.data.status.trim() === "Processing") {
      refetch();
    }

    setRunData(data.data);
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    if (!runBatchesData) return;

    setBatches(runBatchesData.data);
    setActiveBatch(runBatchesData.data[0]);
  }, [runBatchesData]);

  useEffect(() => {
    if (!activeBatch || !runData || isLoading) return;

    const totalExpectedCost = activeBatch.metadata.values[0].toFixed(2);
    const averageScore = activeBatch.metadata.values[1].toFixed(2);

    const percentChange = `${(
      ((runData.meta_data.locked_currents[1] +
        runData.meta_data.open_currents[1] -
        activeBatch.metadata.values[1]) /
        (runData.meta_data.locked_currents[1] + runData.meta_data.open_currents[1])) *
      100
    ).toFixed()}`;
    const absoluteDifference = (
      runData.meta_data.locked_currents[0] +
      runData.meta_data.open_currents[0] -
      activeBatch.metadata.values[0]
    ).toFixed(2);

    setBatchAnalysis({ averageScore, totalExpectedCost, percentChange, absoluteDifference });
  }, [activeBatch, runData]);

  if (isRunDataLoading || isBatchesLoading || isLoading) {
    return (
      <XBox height="600px">
        <LoadingSpinner size={40} />
      </XBox>
    );
  }

  return (
    <XBox display="flex" flexDirection="column" gap="20px">
      <BatchOverview
        setActiveStep={setActiveStep}
        runId={run_id}
        batches={batches}
        setActiveBatch={setActiveBatch}
        setDownloadBatchId={setDownloadBatchId}
      />
      <BatchAnalysis runData={runData} batchAnalysis={batchAnalysis} activeBatch={activeBatch} />
      {/* <OptimisationMade batchAnalysis={batchAnalysis} /> */}
      {/* <Batch /> */}
    </XBox>
  );
};

Run.propTypes = {
  setActiveStep: PropTypes.func,
};
