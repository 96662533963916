import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Droppable, Draggable } from "react-beautiful-dnd";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import { ReactComponent as DarkXCloseIcon } from "assets/images/dark-x-close-icon.svg";

// xplainable dashboard context
import { useXplainableController, setOpenConfigurator } from "context";

import { ColorPicker, ConfigurationBlock, ImagePicker } from "./components";
import { useLocation } from "react-router-dom";
import { useApp } from "hooks";
import { XDivider } from "components/XDivider";
import { XImg } from "components/XImg";

function Configurator() {
  const [controller, dispatch] = useXplainableController();
  const { sidebarItems } = useApp();

  const { openConfigurator } = controller;
  const [backgroundType, setBackgroundType] = useState("block");

  const location = useLocation();

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const handleBackgroundTypeChange = (event) => {
    setBackgroundType(event.target.value);
  };

  if (
    location.pathname.split("/").length === 3 &&
    location.pathname.split("/").includes("reports")
  ) {
    return (
      <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
        <XBox mb={3} display="flex" flexDirection="column" gap={1}>
          <XBox display="flex" justifyContent="space-between" alignItems="center">
            <XTypography variant="h5" fontSize="16px">
              xplainable Configurator
            </XTypography>
            <XBox component="img" src={DarkXCloseIcon} onClick={handleCloseConfigurator} />
          </XBox>

          <XTypography fontSize="12px" color="secondary">
            See our dashboard options
          </XTypography>
        </XBox>
        <XDivider />

        <XBox mt={2}>
          <XTypography variant="h5" fontSize="16px">
            Components List
          </XTypography>
          <XBox display="flex" flexDirection="column" gap="15px" mt={2}>
            {sidebarItems.map((items, index) => (
              <Accordion
                key={index}
                sx={{
                  boxShadow: "none",
                  background: "transparent",
                  margin: "0px !important",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    padding: "0px !important",
                  }}
                >
                  <Typography
                    variant="button"
                    fontSize="16px"
                    sx={{
                      textTransform: "capitalize",
                      color: controller.darkMode ? "white !important" : "black !important",
                    }}
                  >
                    {items.name.charAt(0) + items.name.slice(1).toLowerCase()}
                  </Typography>
                </AccordionSummary>
                <Droppable droppableId={items.name} isDropDisabled={true}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                      <AccordionDetails
                        sx={{
                          display: "flex",
                          position: "relative",
                          overflowY: "scroll",
                          gap: "0 15px",
                          minHeight: "100%",
                        }}
                      >
                        {items.items.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <>
                                <XBox
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  isDragging={snapshot.isDragging}
                                  style={provided.draggableProps.style}
                                >
                                  <XBox
                                    height="105px"
                                    sx={{
                                      userSelect: "none",
                                    }}
                                  >
                                    {item.sideBarContent}
                                  </XBox>
                                </XBox>
                                {snapshot.isDragging && (
                                  <XBox height="105px">{item.sideBarContent}</XBox>
                                )}
                              </>
                            )}
                          </Draggable>
                        ))}
                      </AccordionDetails>
                    </div>
                  )}
                </Droppable>
              </Accordion>
            ))}
          </XBox>
        </XBox>
      </ConfiguratorRoot>
    );
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <XBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <XBox>
          <XTypography variant="h5" style={{ fontSize: "24px" }}>
            Setting
          </XTypography>
        </XBox>

        <XBox onClick={handleCloseConfigurator}>
          <XImg>
            <DarkXCloseIcon />
          </XImg>
        </XBox>
      </XBox>

      <XBox sx={{ height: "1px", width: "100%", background: "#EAEAEA", marginBottom: "24px" }} />

      <XBox>
        <FormControl>
          <XTypography variant="caption" style={{ fontSize: "18px" }} mb={2}>
            Background
          </XTypography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="block"
            name="radio-buttons-group"
            onChange={handleBackgroundTypeChange}
          >
            <FormControlLabel value="block" control={<Radio />} label="Solid Color" />
            <FormControlLabel value="image" control={<Radio />} label="Image" />
          </RadioGroup>
        </FormControl>
        {backgroundType === "block" && (
          <XBox mt={2} mb={0.5}>
            <ColorPicker />
          </XBox>
        )}
        {backgroundType === "image" && (
          <XBox mt={2} mb={3}>
            <ImagePicker />
          </XBox>
        )}

        <ConfigurationBlock />
      </XBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;

function debounce(fn, delay) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}
