import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useSubscriptionMutation = () => {
  const { mutate: updateSubscriptionMutation } = useMutation((params) =>
    apiHandlers.subscriptions.updateSubscriptionSeats(...params)
  );

  const { mutate: createSubscriptionMutation } = useMutation((params) =>
    apiHandlers.subscriptions.createSubscription(...params)
  );

  const { mutate: cancelSubscriptionMutation } = useMutation((params) =>
    apiHandlers.subscriptions.cancelSubscription(...params)
  );

  const { mutate: resumeSubscriptionMutation } = useMutation((params) =>
    apiHandlers.subscriptions.resumeSubscription(...params)
  );

  return {
    updateSubscriptionMutation,
    createSubscriptionMutation,
    cancelSubscriptionMutation,
    resumeSubscriptionMutation
  };
};
