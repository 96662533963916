import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import { Google, LinkedIn, GitHub, QuestionMark } from "@mui/icons-material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import PropTypes from "prop-types";

import { useTheme } from "@mui/system";
import { useXplainableController } from "context";

function selectBox({ onSelection }) {
  const [selected, setSelected] = useState("");
  const theme = useTheme();
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const styles = {
    largeIcon: {
      width: 60,
      height: 60,
    },
  };

  const handleClick = (value) => {
    setSelected(value);
    onSelection(value);
  };

  const customButtonStyles = (theme) => {
    const { rgba } = theme.functions;
    const { borderWidth } = theme.borders;
    const {  secondary } = theme.palette;

    return {
      borderWidth: borderWidth[2],
      mb: 1,
      padding: "80px 130px",

      "&.MuiButton-contained, &.MuiButton-contained:hover": {
        boxShadow: "none",
        border: `${borderWidth[2]} solid #EAEAEA`,
      },

      "&:hover": {
        backgroundColor: darkMode ? theme.palette.secondary.main : theme.palette.light.main,
        border: `${borderWidth[4]} solid ${secondary.main} !important`,

        "& i": {
          color: rgba(secondary.main, 0.4),
        },
      },
    };
  };

  const buttonStyles = (theme, active) => ({
    ...customButtonStyles(theme),
    backgroundColor: darkMode ? theme.palette.light.main : theme.palette.white.main,
    border: `${theme.borders.borderWidth[2]} solid ${
      active ? theme.palette.xppink.main : theme.palette.secondary.main
    } !important`,
  });

  return (
    <XBox
      mt={2}
      display="flex"
      gap="60px"
      flexWrap="wrap"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="center"
    >
      <XBox textAlign="center">
        <XButton
          color="secondary"
          onClick={() => handleClick("Google")}
          sx={buttonStyles(theme, selected === "Google")}
        >
          <Google color={selected === "Google" ? "xppink" : "dark"} sx={styles.largeIcon} />
        </XButton>
        <XTypography variant="h6">Google</XTypography>
      </XBox>
      <XBox textAlign="center">
        <XButton
          color="secondary"
          onClick={() => handleClick("Linkedin")}
          sx={buttonStyles(theme, selected === "Linkedin")}
        >
          <LinkedIn color={selected === "Linkedin" ? "xppink" : "dark"} sx={styles.largeIcon} />
        </XButton>
        <XTypography variant="h6">Linkedin</XTypography>
      </XBox>
      <XBox textAlign="center">
        <XButton
          color="secondary"
          onClick={() => handleClick("Github")}
          sx={buttonStyles(theme, selected === "Github")}
        >
          <GitHub color={selected === "Github" ? "xppink" : "dark"} sx={styles.largeIcon} />
        </XButton>
        <XTypography variant="h6">Github</XTypography>
      </XBox>
      <XBox textAlign="center">
        <XButton
          color="secondary"
          onClick={() => handleClick("Other")}
          sx={buttonStyles(theme, selected === "Other")}
        >
          <QuestionMark color={selected === "Other" ? "xppink" : "dark"} sx={styles.largeIcon} />
        </XButton>
        <XTypography variant="h6">Other</XTypography>
      </XBox>
    </XBox>
  );
}

export default selectBox;

selectBox.propTypes = {
  onSelection: PropTypes.func,
};
