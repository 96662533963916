// xplainable dashboard base styles
import colors from "assets/theme/base/colors";

// xplainable dashboard helper functions
import rgba from "assets/theme/functions/rgba";

export const defaultMentionStyle = (darkMode) => {
  return {
    backgroundColor: darkMode ? "#344767" : rgba(colors.xppurple.main, 0.3),
    padding: "2px",
    borderRadius: "5px",
    fontWeight: "bold",
  };
};
