import { Formik, Form } from "formik";

// xplainable Dashboard components
import XBox from "components/XBox";
import UserInfo from "./components/UserInfo";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

import { Grid, Card } from "@mui/material";

//Validation schema for the forms
import createInitialValues from "layouts/first-login/schemas/initialValues";
import validations from "layouts/first-login/schemas/validations";
import form from "layouts/first-login/schemas/form";
import Notifications from "./components/Notifications";

function DashboardSettings() {
  const { formId, formField } = form;
  const { user } = useApiKey();

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} mt={3}>
          <XBox>
            <Formik
              initialValues={createInitialValues(user)}
              validationSchema={validations[0]}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <XBox>
                      <XBox>
                        <UserInfo formData={{ formField, values, errors, touched }} />
                      </XBox>
                    </XBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </XBox>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Notifications />
        </Grid>
      </Grid>
    </>
  );
}

export default DashboardSettings;
