import { styled } from "@mui/system";
import colors from "assets/theme/base/colors";

export const SavedPlotBox = styled("Box")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
  padding: "10px",
  border: "1px solid #EAEAEA",
  borderRadius: "16px",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out", // Added transition here for hover effect
  "&:hover": {
    backgroundColor: "#F7F7F8",
  },
});

export const MinimalistButton = styled("Box")({
  marginBottom: "16px",
  display: "flex",
  alignSelf: "flex-end",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
  width: "fit-content",
  border: `1px solid ${colors.xppink.main}`,
  borderRadius: "12px",
  backgroundColor: "#FFF",
  padding: "10px 12px",
  whiteSpace: "nowrap", // Prevent text from wrapping
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: `0rem 0.4375rem 0.875rem 0rem ${colors.xppink.main}, 0rem 0.1875rem 0.375rem 0rem rgba(0, 0, 0, 0.08)`,
    transform: "translateY(-1px)",
  },
});

