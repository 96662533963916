// TableCellComponent.js
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { Icon } from '@mui/material';
import { AdminPanelSettings, SupervisorAccount, PersonRemove } from '@mui/icons-material';
import Swal from "sweetalert2";

import { useInviteMutation } from "api/mutations/useInvitationsMutation";

function TableCellComponent(props) {
  const {
    handleMenuOpen,
    handleMenuClose,
    menuAnchorEl,
    openMenuIndex,
    rowIndex,
    org_admin,
    role_name,
    status,
    invite_id,
    onRemove,
    disabled,
  } = props;
  
  //Axios call to delete invitation
  const { deleteInviteMutation } = useInviteMutation();

  //handle delete invitation
  const handleDeleteInvitation = (invite_id) => {
    deleteInviteMutation(
      { invite_id },
      {
      onSuccess: () => {
        handleMenuClose();
        onRemove(rowIndex);
      }
      }
    );
  };

  const showAlert = (index, onRemove) => {
    handleMenuClose();

    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "This will remove the member from the team.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove them!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Team member removed",
            "The team member has been removed from the team.",
            "success"
          );
          //TODO: Hit the backend and delete it from the database
          onRemove(index);
        }
      });
  };

  return (
    <XBox>
      <Icon
        fontSize="default"
        color="secondary"
        sx={{ cursor: "pointer" }}
        onClick={(event) => !disabled && handleMenuOpen(event, rowIndex)}
      >
        more_vert
      </Icon>

      <Menu
        anchorEl={menuAnchorEl}
        open={openMenuIndex === rowIndex}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {status !== "pending" ? (
          [
            <MenuItem
              key="org-admin"
              onClick={(event) => {
                /* Your org admin function */
              }}
              sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
            >
              <AdminPanelSettings fontSize="small" />
              <XTypography variant="button" fontWeight="light">
                {org_admin ? "Org Admin" : "Set as Org Admin"}
              </XTypography>
            </MenuItem>,
            <MenuItem
              key="team-admin"
              onClick={(event) => {
                /* Your team admin function */
              }}
              sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
            >
              <SupervisorAccount fontSize="small" />
              <XTypography variant="button" fontWeight="light">
                {role_name === "admin" ? "Revoke Team Admin" : "Set as Team Admin"}
              </XTypography>
            </MenuItem>,
            <MenuItem
              key="remove"
              onClick={(event) => {
                showAlert(rowIndex, onRemove);
              }}
              sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
            >
              <PersonRemove fontSize="small" color="error" />
              <XTypography variant="button" fontWeight="light" color="error">
                Remove team member
              </XTypography>
            </MenuItem>
          ]
        ) : (
          <MenuItem
            onClick={(event) => {
              handleDeleteInvitation(invite_id);
            }}
            sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
          >
            <PersonRemove fontSize="small" color="error" />
            <XTypography variant="button" fontWeight="light" color="error">
              Delete invitation
            </XTypography>
          </MenuItem>
        )}
      </Menu>
    </XBox>
  );
}

export default TableCellComponent;

TableCellComponent.propTypes = {
  row: PropTypes.object,
  rowIndex: PropTypes.number,
  handleMenuOpen: PropTypes.func,
  handleMenuClose: PropTypes.func,
  menuAnchorEl: PropTypes.object,
  openMenuIndex: PropTypes.number,
  user_id: PropTypes.number,
  org_admin: PropTypes.bool,
  role_name: PropTypes.string,
  status: PropTypes.string,
  invite_id: PropTypes.number,
  showAlert: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
};
