import React from 'react';
import Card from '@mui/material/Card';
import colors from 'assets/theme/base/colors';
import PropTypes from 'prop-types'

export default function GradientCard({ children }) {
  return (
    <div style={{ 
        position: 'relative', 
        width: '100%', 
    }}>
      <Card
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {children}
      </Card>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '12px',
          border: '16px solid transparent',
          background: `linear-gradient(white, white) padding-box, linear-gradient(var(--angle), white 60%, ${colors.xppink.main} 100%) border-box`,
          filter: 'blur(2px)',
          animation: 'rotate 6s linear infinite',
          zIndex: 0,
        }}
      />
      <style>{`
        @keyframes rotate {
          to {
            --angle: 360deg;
          }
        }
      `}</style>
    </div>
  );
}

// Define prop types
GradientCard.propTypes = {
    children: PropTypes.node.isRequired,
  };
  