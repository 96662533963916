import { Card } from "@mui/material";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { ProgressBar } from "layouts/batchOptimisation/BatсhOptimisation/components/OptimiserRuns/components/Run/components";
import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as UploadedIcon } from "assets/images/icons/train/file-check-icon.svg";
import { useXplainableController } from "context";
import { DataTable } from "../DataTable";

export const ProgressBlock = ({ fileNames, uploadProgress, fileMetadata }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  return (
    <XBox>
      <XBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center", // Center the content horizontally
          width: "100%",
          gap: 1,
          height: "80px",
        }}
      >
        {fileNames.length > 0 && (
          <XBox display="flex" alignItems="center" flexDirection="row" gap={1}>
            <UploadedIcon />
            {fileNames.map((fileName, i) => (
              <XTypography key={i} fontSize="18px" fontWeight="bold">
                {fileName}
              </XTypography>
            ))}
          </XBox>
        )}

        <XBox sx={{ width: "70%", display: "flex", flexDirection: "column" }}>
          <ProgressBar bgColor="#DE4188" completed={uploadProgress} width="100%" height="8px" />
          <XBox
            sx={{ alignSelf: "flex-end", color: darkMode ? "#FFF" : "#000" }}
          >{`${uploadProgress}%`}</XBox>
        </XBox>
      </XBox>
      <XBox width="100%">
        {fileMetadata && fileMetadata.length > 0 && <DataTable fileMetadata={fileMetadata} />}
      </XBox>
    </XBox>
  );
};

ProgressBlock.propTypes = {
  fileNames: PropTypes.any,
  uploadProgress: PropTypes.number,
  fileMetadata: PropTypes.array,
};
