import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useAccountSettingMutation = () => {
  const { mutate: updateProfileImageMutation } = useMutation((params) =>
    apiHandlers.accountSettings.updateProfileImage(...params)
  );

  const { mutate: updateDetailsMutation } = useMutation((params) =>
    apiHandlers.accountSettings.updateDetails(...params)
  );

  return {
    updateProfileImageMutation,
    updateDetailsMutation,
  };
};
