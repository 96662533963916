import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { useAutoTrain } from "hooks";
import React from "react";
import PropTypes from "prop-types";
import { useXplainableController } from "context";

export const GoalItem = ({ item }) => {
  const { activeGoal, setActiveGoal } = useAutoTrain();
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  return (
    <XBox
      sx={{
        minWidth: "280px",
        background:
          activeGoal === item.question
            ? rgba(colors.xppink.main, 0.2)
            : darkMode
            ? "#262525"
            : "white",
        padding: 1.5,
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        border:
          activeGoal === item.question ? `1px dashed ${colors.xppink.main}` : "1px dashed #AFAFAF",
      }}
      onClick={(e) => {
        e.stopPropagation();
        setActiveGoal(item.question);
      }}
    >
      <XBox display="flex" gap={1}>
        <XTypography fontSize="16px">{item.question}</XTypography>
      </XBox>

      <XTypography sx={{ color: colors.xppink.main }} fontSize="14px">
        {item.visualisation}
      </XTypography>

      <XTypography color="secondary" fontSize="14px">
        {item.rationale}
      </XTypography>
    </XBox>
  );
};

GoalItem.propTypes = {
    item: PropTypes.obj
}
