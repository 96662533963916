import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import TimelineList from "./components/TimelineList";
import TimelineItem from "./components/TimelineItem";
import InfiniteScroll from "react-infinite-scroll-component";
import XTypography from "components/XTypography";
import XBox from "components/XBox";
import XDatePicker from "components/XDatePicker";
import XInput from "components/XInput";

import { Grid, Card, Icon, IconButton, Skeleton } from "@mui/material";
import { useXplainableController } from "context";

import { useXPanelQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import transformDataToTimelineItem from "./components/dataTransform";
import { useAuth0 } from "@auth0/auth0-react";
import { Select } from "./components/Select";

const getInitialDateRange = () => {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  // format the date as "YYYY-MM-DD"
  const formatDate = (date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate()
    ).padStart(2, "0")}`;

  return [formatDate(sevenDaysAgo), formatDate(today)];
};

function createSelectOptions(events) {
  const options = events.map((event) => {
    const label = event
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return { value: event, label: label };
  });

  options.unshift({ value: "all", label: "All" });
  return options;
}

function TimelineLoadingSkeleton() {
  return (
    <XBox position="relative" sx={{ pl: 5.75, pb: 2 }}>
      {/* Circle icon skeleton */}
      <Skeleton 
        variant="circular" 
        width={26} 
        height={26} 
        sx={{ position: 'absolute', left: '2px', top: '4px' }}
      />
      {/* Title and datetime */}
      <XBox>
        <Skeleton variant="text" width={120} height={20} />
        <Skeleton variant="text" width={80} height={16} sx={{ mt: 0.25 }} />
      </XBox>
      {/* Description */}
      <XBox mt={1} mb={1}>
        <Skeleton variant="text" width="90%" height={16} />
        <Skeleton variant="text" width="75%" height={16} />
      </XBox>
      {/* Badges */}
      <XBox display="flex" gap={1}>
        <Skeleton variant="rounded" width={60} height={20} />
        <Skeleton variant="rounded" width={60} height={20} />
      </XBox>
    </XBox>
  );
}

function ReuseableTimeline({ title, event, ...queryProps }) {
  const { logout } = useAuth0();
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  //Timeline state
  const [timelineItems, setTimelineItems] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [dateRange, setDateRange] = useState(getInitialDateRange());
  const [fromDate, setFromDate] = useState(getInitialDateRange()[0]);
  const [toDate, setToDate] = useState(getInitialDateRange()[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectEventFilter, setSelectEventFilter] = useState("all");
  const [inputValue, setInputValue] = useState("");

  //Dynamically make the selection list depending on the events
  const selectOptions = createSelectOptions(event);

  //Fetch more timeline items
  const fetchMoreTimelineItems = async () => {
    try {
      const fetchPage = currentPage + 1; // Increment the page directly
      console.log("Fetching page:", fetchPage);

      await refetch({
        from_date: fromDate,
        to_date: toDate,
        event: event,
        page: fetchPage,
        ...queryProps,
      });

      setCurrentPage(fetchPage);
    } catch (error) {
      console.error("Error fetching more timeline items:", error);
    }
  };

  const filteredTimelineItems = timelineItems.filter((item) => {
    if (selectEventFilter === "all") {
      return item.description.includes(inputValue);
    }

    return item.key === selectEventFilter; //&& item.description.includes(inputValue);
  });

  const { data, refetch } = useXPanelQuery(
    {
      from_date: fromDate,
      to_date: toDate,
      event: event,
      ...queryProps,
      page: currentPage,
    },
    logout
  );
  useEffect(() => {
    if (data) {
      const transformedData = transformDataToTimelineItem(data.data);

      setTimelineItems((prevItems) => [...prevItems, ...transformedData]);

      if (data.data.length < 10) {
        setHasMoreItems(false);
      }
    }
  }, [data]);

  useEffect(() => {
    setTimelineItems([]);
  }, [queryProps.organisation_id]);

  const handleDropdownItemClick = (status) => {
    setSelectEventFilter(status.value);
  };

  return (
    <Card sx={{ p: 2, position: "relative" }}>
      <Grid container>
        <Grid item xs={12}>
          <XBox display="flex" justifyContent="space-between" flexDirection="column" gap={2}>
            <XTypography variant="h5" textTransform="capitalize" fontSize="18px">
              {title}
            </XTypography>
            <XBox display="flex">
              <XDatePicker
                value={dateRange}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                }}
                onChange={(dates) => {
                  setDateRange(dates);
                  if (dates && dates.length === 2) {
                    const formatDate = (date) => {
                      const d = new Date(date);
                      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
                        2,
                        "0"
                      )}-${String(d.getDate()).padStart(2, "0")}`;
                    };
                    setFromDate(formatDate(dates[0]));
                    setToDate(formatDate(dates[1]));
                  }
                }}
              />
            </XBox>
            <XBox display="flex" position="relative" gap={2} justifyContent="space-between">
              <XInput
                sx={{ width: "100%" }}
                placeholder="Search"
                value={inputValue}
                onChange={(event) => {
                  setInputValue(event.target.value);
                }}
              />
              <Select
                selectOptions={selectOptions}
                handleDropdownItemClick={handleDropdownItemClick}
              />
            </XBox>
          </XBox>
        </Grid>
        <Grid item xs={12}>
          <TimelineList title={title} dark={darkMode}>
            {selectEventFilter !== "all" || inputValue ? (
              <XBox sx={{ height: "35vh", overflow: "scroll" }}>
                {filteredTimelineItems.map(
                  ({ color, icon, title, dateTime, description, badges, lastItem }, index) => (
                    <TimelineItem
                      key={index}
                      color={color}
                      icon={icon}
                      title={title}
                      dateTime={dateTime}
                      description={description}
                      badges={badges}
                      lastItem={lastItem}
                    />
                  )
                )}
              </XBox>
            ) : (
            <XBox
              sx={{
                height: '35vh',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '-ms-overflow-style': 'none',  /* IE and Edge */
                'scrollbar-width': 'none',     /* Firefox */
              }}
            >
              <InfiniteScroll
                dataLength={timelineItems.length}
                next={fetchMoreTimelineItems}
                hasMore={hasMoreItems}
                loader={
                  <XBox height="auto">
                    {[1, 2, 3].map((item) => (
                      <TimelineLoadingSkeleton key={item} />
                    ))}
                  </XBox>
                }
                scrollThreshold="80%"
                endMessage={
                  <XBox
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon color="secondary">check_circle</Icon>
                    <XTypography ml={1} variant="h6" color="secondary">
                      All items have been loaded.
                    </XTypography>
                  </XBox>
                }
              >
                {timelineItems.map(
                  ({ color, icon, title, dateTime, description, badges, lastItem }, index) => (
                    <TimelineItem
                      key={index}
                      color={color}
                      icon={icon}
                      title={title}
                      dateTime={dateTime}
                      description={description}
                      badges={badges}
                      lastItem={lastItem}
                    />
                  )
                )}
              </InfiniteScroll>
            </XBox>

            )}
          </TimelineList>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ReuseableTimeline;

// Typechecking props for the ReusableHeader
ReuseableTimeline.propTypes = {
  event: PropTypes.array,
  title: PropTypes.string,
};
