import React from 'react';
import Shimmer from '../../ShimmerEffect';
import PropTypes from 'prop-types';

const Quadrant = ({ color, count }) => {
    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            flexWrap: 'wrap', 
            width: '50%', 
            height: '50%', 
            position: 'relative' // Set to relative
        }}>
            {Array.from({ length: count }).map((_, index) => (
                <Shimmer 
                    key={index} 
                    shape="circular" 
                    width="10%" 
                    height="10%" 
                    sx={{
                        margin: '1%', 
                        position: 'absolute',
                        top: `${Math.random() * 90}%`,  // Randomly position from top
                        left: `${Math.random() * 90}%`, // Randomly position from left
                    }}
                />
            ))}
        </div>
    );
};

const MockConfusionChart = () => {
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '2%', width: '100%', height: '100%' }}>
            <Quadrant count={30} />
            <Quadrant count={4} />
            <Quadrant count={6} />
            <Quadrant count={25} />
        </div>
    );
};

export default MockConfusionChart;


Quadrant.propTypes = {  
    size: PropTypes.number.isRequired,  
    color: PropTypes.string.isRequired,  
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
    count: PropTypes.number.isRequired,
};