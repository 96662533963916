import React from "react";

import { Card } from "@mui/material";
import { RunCard } from "../RunCard";

import PropTypes from "prop-types";

import XBox from "components/XBox";
import XTypography from "components/XTypography";

import RegressionChart from "layouts/models/model/components/profile/components/RegressionChart";

export const BatchAnalysis = ({ runData, batchAnalysis, activeBatch }) => {
  const cardsData = [
    {
      title: "AVERAGE SCORE",
      value: batchAnalysis?.averageScore,
      descriptionValue: `${
        batchAnalysis?.percentChange && batchAnalysis?.percentChange < 0 ? "" : "+"
      }${batchAnalysis?.percentChange}%`,
      descriptionText: "from initial state",
    },
    {
      title: "TOTAL EXPECTED COST",
      value: batchAnalysis?.totalExpectedCost,
      descriptionValue: `-$${batchAnalysis?.absoluteDifference}`,
      descriptionText: "from initial state",
    },
    {
      title: "TOTAL EXPECTED BENEFIT",
      value: "$65,022",
      descriptionValue: "+133%",
      descriptionText: "from initial state",
    },
    {
      title: "TOTAL OPTIMISATIONS",
      value: "193",
      descriptionValue: "12%",
      descriptionText: "of dataset",
    },
  ];

  const data = [
    { name: "16-20", value: 15 },
    { name: "21-25", value: 20 },
    { name: "26-30", value: 11 },
    { name: "31-36", value: 60 },
    { name: "36-42", value: 20 },
    { name: "42+", value: 35 },
  ];

  const regressionBins = {
    min: 0,
    max: 1,
    bins: {
      train: {
        pred: [],
        true: activeBatch?.metadata?.score_dist ?? [],
      },
    },
  };

  return (
    <XBox display="flex" gap={2}>
      <Card
        height="100%"
        mt={10}
        pb={{ xs: 0, lg: 10 }}
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <XBox sx={{ padding: "26px 19px" }}>
          <XTypography fontSize="20px">Batch Analysis</XTypography>
          <XTypography fontSize="14px" color="secondary" fontWeight="medium">
            Compare batches at global level
          </XTypography>
          <XBox
            display="flex"
            height="300px"
            justifyContent="space-between"
            mt={5}
            sx={{ padding: "0 20px" }}
            gap="16px"
          >
            <XBox width="100%">
              <XTypography fontSize="14px" fontWeight="medium" color="secondary">
                SCORE DISTRIBUTIONS
              </XTypography>
              <XBox>
                <RegressionChart regressionBins={regressionBins} />
              </XBox>
            </XBox>
            {/* <XBox width="100%">
            <XTypography fontSize="14px" fontWeight="medium" color="secondary">
              COST COMPARISON
            </XTypography>
            <XBox>
              <CostComprasion data={data} />
            </XBox>
          </XBox> */}
          </XBox>
        </XBox>
      </Card>
      <XBox display="flex" flexDirection="column" width="100%" gap={2}>
        {cardsData.slice(0, 2).map((item, index) => (
          <RunCard
            key={index}
            title={item.title}
            value={item.value}
            descriptionText={item.descriptionText}
            descriptionValue={item.descriptionValue}
          />
        ))}
      </XBox>
    </XBox>
  );
};

BatchAnalysis.propTypes = {
  runData: PropTypes.object,
  batchAnalysis: PropTypes.array,
  activeBatch: PropTypes.object,
};
