import {  Skeleton } from "@mui/material";
import { animateGradient } from "assets/theme/base/animations";
import { useXplainableController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import { UploadDataSet, UploadModal, UploadedFile } from "./components";
import XBox from "components/XBox";
import { useDatasets } from "hooks";

export const DataSets = () => {
  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;
  const {
    dataSets,
    isLoading,
    isModalOpen,
    fileToReplace,
    handleOpenModal,
    setIsModalOpen,
    handleOpenReplaceModal,
  } = useDatasets();


  return (
    <DashboardLayout
      sx={{
        backgroundImage: () =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title="Datasets"
        description={"One-click shareable reports with intuitive drag-and-drop functionality"}
        icon={"ni ni-atom"}
      />

      {isLoading ? (
        <XBox sx={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
          {[1, 2, 3].map((skeleton) => (
            <XBox
              key={skeleton}
              sx={{
                border: "1px solid #EAEAEA",
                borderRadius: "16px",
                height: "224px",
                width: "341px",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              {/* Preview area skeleton */}
              <XBox sx={{ flex: 1, p: 2 }}>
                <Skeleton variant="rectangular" width="100%" height="100%" />
              </XBox>

              {/* Footer area skeleton */}
              <XBox sx={{ p: 2, height: "76px" }}>
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="rectangular" width="80px" height={24} sx={{ mt: 1, borderRadius: "100px" }} />
              </XBox>
            </XBox>
          ))}
        </XBox>
      ) : (
        <XBox sx={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
          <UploadDataSet openModal={handleOpenModal} />

          <UploadModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            fileToReplace={fileToReplace}
          />

          {dataSets?.data.length > 0 &&
            dataSets?.data?.map((file) => (
              <UploadedFile file={file} key={file.dataset_id} onReplace={handleOpenReplaceModal} />
            ))}
        </XBox>
      )}
    </DashboardLayout>
  );
};
