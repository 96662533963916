import React from "react";

import { useModel } from "hooks";
import { dataManipulation } from "layouts/models/model/components/profile";
import { Card } from "@mui/material";

import ModelProfile from "shared/models/ModelProfile";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import XBox from "components/XBox";

export const ReportModelProfile = React.memo(() => {
  const { profileData, featureData, featureDescriptionData, model_type, profileDataLoading } =
    useModel();

  let firstKey;
  if (profileData && (profileData.categorical || profileData.numeric)) {
    firstKey =
      profileData.categorical && Object.keys(profileData.categorical).length > 0
        ? Object.keys(profileData.categorical)[0]
        : Object.keys(profileData.numeric)[0];
  }

  if (profileDataLoading) {
    return (
      <Card
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          height: "500px",
        }}
      >
        <XBox display="flex" width="100%" alignItems="center" justifyContent="center">
          <LoadingSpinner size={50} animationType="pulse" />
        </XBox>
        <XBox display="flex" width="100%" alignItems="center" justifyContent="center">
          <LoadingSpinner size={50} animationType="pulse" />
        </XBox>
      </Card>
    );
  }

  if (profileData && Object.keys(profileData).length > 0 && firstKey) {
    return (
      <ModelProfile
        profileData={profileData}
        firstRenderData={dataManipulation(profileData, firstKey, "score", model_type)}
        firstFeature={firstKey}
        modelType={model_type}
        featureData={featureData.filter((x) => x.value != 0).sort((a, b) => b.value - a.value)}
        reportFeatureDescriptionData={featureDescriptionData}
      />
    );
  }

  return null;
});
