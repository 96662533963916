import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useUsersQuery = ({ activeWorkspace, logout }) => {
  return useQuery(
    [QUERY_CONSTANTS.ALL_USERS, activeWorkspace],
    () => apiHandlers.users.getUsers(activeWorkspace).then((res) => res),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
