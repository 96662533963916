import { Box } from "@mui/material";
import { useXplainableController } from "context";
import { XImg } from "components/XImg";
import React from "react";
import { PropTypes } from "prop-types";
import colors from "assets/theme/base/colors";
import XBox from "components/XBox";

import { ReactComponent as AddIcon } from "assets/images/icons/datasets/plus.svg";
import XTypography from "components/XTypography";

export const UploadDataSet = ({ openModal, width }) => {
  const [controller, dispatch] = useXplainableController();
  const { darkMode } = controller;

  return (
    <XBox
      sx={{
        border: "1px dashed #AFAFAF",
        borderRadius: "16px",
        height: "224px",
        width: width ? width : "341px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
      onClick={openModal}
    >
      <XBox
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <XBox
          sx={{
            height: "44px",
            width: "44px",
            backgroundColor: "#EAEAEA",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <XImg>
            <AddIcon />
          </XImg>
        </XBox>
      </XBox>
      <XBox
        sx={{
          backgroundColor: "#FFF",
          paddingY: "22px",
          textAlign: "center",
          fontWeight: 700,
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
          backgroundColor: darkMode ? colors.dark.main : colors.white.main,
        }}
      >
        <XTypography fontWeight="bold">
          Add new dataset
        </XTypography>
      </XBox>
    </XBox>
  );
};

UploadDataSet.propTypes = {
  openModal: PropTypes.func,
  width: PropTypes.string
};
