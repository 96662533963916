import { AppBar, Tab, Tabs, Skeleton, Typography } from "@mui/material";
import XBox from "components/XBox";
import { useModel } from "hooks";
import PerformanceChart from "shared/models/Metrics/Components/PerformanceChart";
import React, { useEffect, useState } from "react";
import { useBinaryPerformanceQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import EmptyState from "shared/Cards/EmptyStateCard";

export const Curves = () => {
  const { selectedPartition } = useModel();
  const { logout } = useAuth0();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [rocData, setRocData] = useState([]);
  const [precisionRecallData, setPrecisionRecallData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState({
    value: "train",
    label: "Train",
  });
  const [threshold, setThreshold] = useState(0.5);

  const { data: performanceData, isLoading, error } = useBinaryPerformanceQuery(
    selectedPartition?.value,
    logout
  );

  console.log("The performance data is", performanceData)

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  console.log(rocData, precisionRecallData, "here");

  useEffect(() => {
    const filterData = () => {
      if (!performanceData?.evaluation) {
        return null;
      }
      
      if (selectedDataset.value === "validation") {
        return performanceData.evaluation?.validation;
      } else if (selectedDataset.value === "train") {
        return performanceData.evaluation?.train;
      }
      return null;
    };

    const newData = filterData();
    setFilteredData(newData);
  }, [selectedDataset, performanceData]);

  useEffect(() => {
    if (filteredData?.roc && filteredData?.precision_recall_curve) {
      setPrecisionRecallData(filteredData.precision_recall_curve);
      setRocData(filteredData.roc);
    } else {
      setPrecisionRecallData([]);
      setRocData([]);
    }
  }, [threshold, filteredData]);

  return (
    <>
      {isLoading ? (
        <XBox>
          <AppBar position="static">
            <Skeleton variant="rectangular" height={48} animation="wave" />
          </AppBar>
          <Skeleton 
            variant="rectangular" 
            height={500}
            animation="wave"
            sx={{ mt: 2 }}
          />
        </XBox>
      ) : !performanceData?.evaluation ? (
        <EmptyState 
          icon={AnalyticsIcon}
          message="No performance data available for this partition"
          iconSize="96px"
        />
      ) : (
        <XBox>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{ backgroundColor: "transparent" }}
            >
              <Tab
                label="PR Curve"
                icon={<i className="ni ni-app" style={{ marginTop: "6px", marginRight: "8px" }} />}
              />
              <Tab
                label="ROC Curve"
                icon={
                  <i className="ni ni-email-83" style={{ marginTop: "6px", marginRight: "8px" }} />
                }
              />
            </Tabs>
          </AppBar>
          <PerformanceChart
            data={tabValue === 0 ? precisionRecallData : rocData}
            type={tabValue === 0 ? "PR" : "ROC"}
          />
        </XBox>
      )}
    </>
  );
};
