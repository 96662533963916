// useMixPanelQuery.js
import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useListModelsQuery = (logout) => {
  return useQuery(
    [QUERY_CONSTANTS.LIST_MODELS],
    () => apiHandlers.train.getModels(),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
