import React, { useRef, useState } from "react";
import { ColumnsEditableBlock } from "../columnsEditableBlock";
import { v4 as uuidv4 } from "uuid";
import { ClickAwayListener } from "@mui/material";

import PropTypes from "prop-types";
import XBox from "components/XBox";

import DeleteIcon from "assets/images/icons/collections/delete-icon.svg";
import DraggableIcon from "assets/images/icons/draggable-icon.png";
import { components } from "../../constants";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const ColumnsBlock = ({
  blocks,
  setBlocks,
  id,
  threshold,
  setThreshold,
  setMetrics,
  metrics,
}) => {
  const [activeBlockId, setActiveBlockId] = useState(null);

  const currentBlock = blocks?.find((block) => block?.id === id);

  const addBlockHandler = (currentBlock) => {
    const newBlock = {
      id: uuidv4(),
      html: "",
      tag: "p",
      type: "editor",
      isHovered: false,
      isOpenedAdditionalMenu: false,
    };

    setBlocks((prevBlocks) => {
      const currentColumns = blocks.find((block) => block.id === id);
      const index = currentColumns.columns[currentBlock.columnNumber].findIndex(
        (b) => b.id === currentBlock.id
      );

      currentColumns.columns[currentBlock.columnNumber].splice(index + 1, 0, newBlock);

      const newBlocks = prevBlocks.map((block) => (block.id === id ? currentColumns : block));

      return newBlocks;
    });

    setActiveBlockId(newBlock.id);
  };

  const deleteBlockHandler = (currentBlock) => {
    const previousBlock = currentBlock.ref.parentNode.parentNode.previousElementSibling;

    if (previousBlock) {
      setBlocks((prevBlocks) => {
        const currentColumns = blocks.find((block) => block.id === id);
        const index = currentColumns.columns[currentBlock.columnNumber].findIndex(
          (b) => b.id === currentBlock.id
        );

        currentColumns.columns[currentBlock.columnNumber].splice(index, 1);

        const newBlocks = prevBlocks.map((block) => (block.id === id ? currentColumns : block));

        return newBlocks;
      });
    }
  };

  const handleBlockHover = (blockId, index, isHovered) => {
    setBlocks((prevBlocks) => {
      const currentBlock = prevBlocks.find((prevBlock) => prevBlock.id === id);
      const updatedColumn = currentBlock.columns[index].map((block) =>
        block.id === blockId ? { ...block, isHovered } : block
      );
      currentBlock.columns[index] = updatedColumn;
      const newBlocks = prevBlocks.map((block) => (block.id === id ? currentBlock : block));

      return newBlocks;
    });
  };

  const openAdditionalMenu = (blockId, index, isOpenedAdditionalMenu) => {
    setBlocks((prevBlocks) => {
      const currentBlock = prevBlocks.find((prevBlock) => prevBlock.id === id);
      const updatedColumn = currentBlock.columns[index].map((block) =>
        block.id === blockId ? { ...block, isOpenedAdditionalMenu } : block
      );
      currentBlock.columns[index] = updatedColumn;
      const newBlocks = prevBlocks.map((block) => (block.id === id ? currentBlock : block));

      return newBlocks;
    });
  };

  const handleDelete = (e, blockId, index, isOpenedAdditionalMenu) => {
    const currentColumns = blocks.find((block) => block.id === id);
    const newBlock = {
      id: uuidv4(),
      html: "",
      tag: "p",
      type: "editor",
      isHovered: false,
      isOpenedAdditionalMenu: false,
    };

    if (currentColumns.columns[index].length === 1) {
      setBlocks((prevState) => {
        const currentColumns = prevState.find((block) => block.id === id);

        const currentIndex = currentColumns.columns[index].findIndex((b) => b.id === blockId);

        currentColumns.columns[index] = [newBlock];

        const newBlocks = prevState.map((block) => (block.id === id ? currentColumns : block));

        return newBlocks;
      });
      openAdditionalMenu(blockId, index, isOpenedAdditionalMenu);
      return;
    }

    setBlocks((prevState) => {
      const currentColumns = prevState.find((block) => block.id === id);

      const currentIndex = currentColumns.columns[index].findIndex((b) => b.id === blockId);

      currentColumns.columns[index].splice(currentIndex, 1);

      const newBlocks = prevState.map((block) => (block.id === id ? currentColumns : block));

      return newBlocks;
    });
    openAdditionalMenu(blockId, index, isOpenedAdditionalMenu);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const sourceColumnIndex = parseInt(result.source.droppableId.split("-")[1], 10);
    const destinationColumnIndex = parseInt(result.destination.droppableId.split("-")[1], 10);

    setBlocks((prevBlocks) => {
      const updatedBlocks = [...prevBlocks];

      // Retrieve the dragged item
      const draggedItem = updatedBlocks.find((block) => block.id === id).columns[sourceColumnIndex][
        sourceIndex
      ];

      // Remove the item from the source column
      updatedBlocks
        .find((block) => block.id === id)
        .columns[sourceColumnIndex].splice(sourceIndex, 1);

      // Insert the item into the destination column
      updatedBlocks
        .find((block) => block.id === id)
        .columns[destinationColumnIndex].splice(destinationIndex, 0, draggedItem);

      return updatedBlocks;
    });
  };

  return (
    <XBox display="flex" width="100%" gap={1}>
      <DragDropContext onDragEnd={handleDragEnd}>
        {Object.values(currentBlock?.columns).map((columns, index) => {
          return (
            <Droppable key={index} droppableId={`column-${index}`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={{ width: "50%" }}>
                  <XBox display="flex" width="100%" flexDirection="column" gap="4px">
                    {columns.map((column, columnIndex) => {
                      return (
                        <Draggable key={column.id} draggableId={column.id} index={columnIndex}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <XBox
                                position="relative"
                                display="flex"
                                gap="4px"
                                width="100%"
                                key={columnIndex}
                                alignItems={components[column.tag] ? "flex-end" : "center"}
                                onMouseEnter={() => handleBlockHover(column.id, index, true)}
                                onMouseLeave={() => handleBlockHover(column.id, index, false)}
                              >
                                <ColumnsEditableBlock
                                  key={columnIndex}
                                  html={column.html}
                                  tag={column.tag}
                                  blocks={blocks}
                                  setBlocks={setBlocks}
                                  parentId={id}
                                  id={column.id}
                                  columnNumber={index}
                                  addBlock={addBlockHandler}
                                  deleteBlock={deleteBlockHandler}
                                  activeBlockId={activeBlockId}
                                  threshold={threshold}
                                  setThreshold={setThreshold}
                                  setMetrics={setMetrics}
                                  metrics={metrics}
                                />
                                <ClickAwayListener
                                  onClickAway={() => openAdditionalMenu(column.id, index, false)}
                                >
                                  <XBox>
                                    <XBox
                                      position="absolute"
                                      right="-50px"
                                      bottom="0"
                                      sx={{
                                        padding: "10px",
                                        background: "#fff",
                                        borderRadius: "5px",
                                        zIndex: "9999",
                                        transition: "background-color 0.3s",
                                        opacity: column.isOpenedAdditionalMenu ? "1" : "0",
                                        transition: "all 0.3s",
                                        "&:hover": {
                                          background: "#f0f0f0",
                                        },
                                        boxShadow:
                                          "0 0 0 1px rgba(15,15,15,.05), 0 3px 6px rgba(15,15,15,.1), 0 9px 24px rgba(15,15,15,.2)",
                                      }}
                                      onClick={(e) => handleDelete(e, column.id, index, false)}
                                    >
                                      <XBox display="flex" gap={1}>
                                        <XBox component="img" src={DeleteIcon}></XBox>
                                      </XBox>
                                    </XBox>
                                    <XBox
                                      {...provided.dragHandleProps}
                                      onMouseUp={(e) => openAdditionalMenu(column.id, index, true)}
                                      sx={{
                                        opacity: column.isHovered ? "1" : "0",
                                        transition: "all 0.3s",
                                      }}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <XBox component="img" src={DraggableIcon} height="15px" />
                                    </XBox>
                                  </XBox>
                                </ClickAwayListener>
                              </XBox>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </XBox>
                  {index === 0 && <XBox width="1px" sx={{ background: "#EAEAEA" }}></XBox>}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        })}
      </DragDropContext>
    </XBox>
  );
};

ColumnsBlock.propTypes = {
  blocks: PropTypes.any,
  setBlocks: PropTypes.any,
  id: PropTypes.any,
  threshold: PropTypes.number,
  setThreshold: PropTypes.func,
  setMetrics: PropTypes.func,
  metrics: PropTypes.array,
};
