import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import colors from 'assets/theme/base/colors';
import rgba from 'assets/theme/functions/rgba';
import XBox from 'components/XBox';

const ScoreHistogram = ({ data, threshold, setScoreValue, collapsedWidth }) => {
    const [bins, setBins] = useState([]);

    const svgRef = useRef(null);
    const svgContainer = useRef(null); // The PARENT of the SVG 

    // State to track width and height of SVG Container
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(300);

    const margin = { top: 40, right: 10, bottom: 20, left: 30 },
        innerWidth = width - margin.left - margin.right,
        innerHeight = height - margin.top - margin.bottom;

    let minData = 0//Math.floor(d3.min(data) / 0.05) * 0.05;
    let maxData = 1//Math.ceil(d3.max(data) / 0.05) * 0.05;

    if (maxData - minData < 0.2) {
        const padding = (0.2 - (maxData - minData)) / 2;
        minData = minData - padding;
        maxData = maxData + padding;
    }

    const xScale = d3.scaleLinear()
        .domain([minData, maxData])
        .range([0, innerWidth]);

    const yScale = d3.scaleLinear()
        .domain([0, d3.max(bins, bin => bin.length)])
        .range([innerHeight, 0]);

    const barWidth = innerWidth / bins.length;

    const yAxisTicks = yScale.ticks(5);
    const yAxis = d3.axisLeft(yScale).ticks(5).tickSize(0);

    useEffect(() => {
        const thresholds = d3.range(minData, maxData, 0.05);
        const histogram = d3.histogram()
            .domain([minData, maxData])
            .thresholds(thresholds);

        setBins(histogram(data));
    }, [data, minData, maxData, threshold]);

        // This function calculates width and height of the container
    //TODO: pass the height from parent component ref
    const getSvgContainerSize = () => {
        const newWidth = svgContainer.current.clientWidth ;
        setWidth(newWidth);
    };

    useEffect(() => {
        // detect 'width' and 'height' on render
        getSvgContainerSize();
        // listen for resize changes, and detect dimensions again when they change
        window.addEventListener("resize", getSvgContainerSize);
        // cleanup event listener
        return () => window.removeEventListener("resize", getSvgContainerSize);
    }, [collapsedWidth]);


    return (
        <XBox height={height} ref={svgContainer}>
            <svg height={height}  width={"100%"} ref={svgRef}>
                <g transform={`translate(${margin.left}, ${margin.top})`}>

                    <g
                        className="axis axis--x"
                        transform={`translate(0, ${innerHeight})`}
                        ref={node => d3.select(node).call(d3.axisBottom(xScale).tickSize(2).tickPadding(10))}
                        style={{ color: colors.secondary.main }}
                    />
                    <g
                        className="axis axis--y"
                        ref={node => d3.select(node).call(yAxis)}
                        style={{ color: colors.secondary.main }}
                    />
    
                    {bins.map((bin, i) => (
                        <rect
                            key={`bin-${i}`}
                            x={i * barWidth}
                            y={yScale(bin.length)}
                            width={barWidth - 1}
                            height={innerHeight - yScale(bin.length)}
                            fill={rgba(colors.xpblue.main, 0.9)}
                            rx={3}  // rounding on the x-axis
                            ry={3}  // rounding on the y-axis
                            onClick={() => setScoreValue([(minData + i * 0.05) * 100, (minData + (i + 1) * 0.05) * 100])}
                        />
                    ))}
                    <line
                        className="threshold-line"
                        stroke="black"
                        strokeWidth="2.5px"
                        x1={xScale(threshold)}
                        x2={xScale(threshold)}
                        y1={0}
                        y2={innerHeight}
                    />
                </g>
            </svg>
        </XBox>
    );
};

ScoreHistogram.propTypes = {
    data: PropTypes.arrayOf(PropTypes.number).isRequired,
    threshold: PropTypes.number,
    setScoreValue: PropTypes.func,
    collapsedWidth: PropTypes.bool,
};

export default ScoreHistogram;
