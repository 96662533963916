import { Card, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useXplainableController } from "context";

import XBox from "components/XBox";
import React from "react";

import PropTypes from "prop-types";
import XTypography from "components/XTypography";

export const FeatureEngineer = ({ fileMetadata }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  return (
    <XBox>
      <Card sx={{ padding: "24px", display: "flex", flexDirection: "column", gap: 3 }}>
        <XTypography fontWeight="bold">Pipeline</XTypography>
        <TableContainer
          component={Paper}
          sx={{
            background: darkMode ? "#1D1B1B" : "white",
            boxShadow: "none",
            border: "1px solid #EAEAEA",
            borderRadius: "16px",
            height: "480px",
          }}
        >
          <Table aria-label="mui table">
            <TableBody>
              {fileMetadata[0].data.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{
                    "& > *": {
                      fontWeight: rowIndex === 0 ? "bold !important" : "normal",
                      color: rowIndex === 0 ? "#AFAFAF !important" : "black",
                    },
                  }}
                >
                  {row.map((cell, cellIndex) => {
                    if (cellIndex !== 0) {
                      return (
                        <TableCell
                          sx={{
                            color: darkMode ? "white  !important" : "black  !important",
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                            padding: "24px 8px",
                          }}
                          key={cellIndex}
                        >
                          {cell}
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={cellIndex}
                        sx={{
                          color: darkMode ? "white !important" : "black  !important",
                          lineHeight: 1,
                          whiteSpace: "nowrap",
                          padding: "24px 8px",
                        }}
                        component="th"
                        scope="row"
                      >
                        {cell}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </XBox>
  );
};

FeatureEngineer.propTypes = {
  fileMetadata: PropTypes.array,
};
