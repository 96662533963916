import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useRunQuery = (activeWorkspace, optimiser_id, version_id, run_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.RUN, activeWorkspace, optimiser_id, version_id, run_id],
    () => apiHandlers.runs.getRunData(activeWorkspace, optimiser_id, version_id, run_id),

    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
