import XBox from "components/XBox";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";

import { useXplainableController } from "context";

const NoteBlock = React.memo(({ blocks, setBlocks, id, isEditable = false }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const noteBlock = blocks?.find((block) => block.id === id);

  const [html, setHtml] = useState(noteBlock?.data ?? "");

  const handleChange = (e, index) => {
    setHtml(e.target.value);

    setBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map((block) => {
        if (block.id === id) {
          return { ...block, data: e.target.value };
        }
        return block;
      });

      return updatedBlocks;
    });
  };

  return (
    <XBox sx={{ background: "#f6f6f6" }} display="flex">
      <XBox sx={{ width: "2px", background: "black" }}></XBox>
      <XBox py={4} px={3} display="flex" gap={2}>
        <ContentEditable
          disabled={!isEditable}
          html={html}
          onChange={handleChange}
          placeholder="Type a page title..."
          style={{ color: darkMode ? "#7C7C7C" : "black", outline: "none" }}
        />
      </XBox>
    </XBox>
  );
});

export default NoteBlock;

NoteBlock.propTypes = {
  blocks: PropTypes.any,
  tableData: PropTypes.array,
  setBlocks: PropTypes.func,
  id: PropTypes.string,
  isEditable: PropTypes.bool,
};
