export const waterfallChartWidth = (windowWidth, isCollapsed) => {
  switch (true) {
    case windowWidth > 1024 && isCollapsed:
      return 570;
    case windowWidth > 1024 && !isCollapsed:
      return 370;
    case windowWidth > 768 && isCollapsed:
      return 800;
    case windowWidth > 768 && !isCollapsed:
      return 530;
    case windowWidth > 425 && isCollapsed:
      return 550;
    case windowWidth > 425 && !isCollapsed:
      return 398;
    case windowWidth > 0 && isCollapsed:
      return 209;
    case windowWidth > 0 && !isCollapsed:
      return 158;
    default:
      return 0;
  }
};
