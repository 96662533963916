import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiKey } from "../ApiKeyContext";
import { useLocation, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";
import XBox from "components/XBox";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { Paths } from "settings";
import { LOCAL_STORAGE_KEYS } from "constants";
import { useLoginQuery } from "api/query/useLoginQuery";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const { apiKey, setApiKey } = useApiKey();
  const { isLoading: isLoginLoading, data: loginData } = useLoginQuery();
  const location = useLocation();

  // Show loading state while checking auth or fetching login data
  if (isAuthLoading || isLoginLoading) {
    return (
      <XBox
        height="100vh"
        sx={{ position: "relative", zIndex: 9000, background: colors.background.default }}
      >
        <LoadingSpinner size={100} animationType="pulse" />
      </XBox>
    );
  }

  // Handle unauthenticated users
  if (!isAuthenticated) {
    return <Navigate to={Paths.login} replace />;
  }

  // If we have login data but no API key, set it
  if (loginData?.token && !apiKey) {
    setApiKey(loginData?.token);
    return (
      <XBox
        height="100vh"
        sx={{ position: "relative", zIndex: 9000, background: colors.background.default }}
      >
        <LoadingSpinner size={100} animationType="pulse" />
      </XBox>
    );
  }

  // Handle authenticated users without API key
  if (!apiKey && location.pathname !== "/new-user") {
    return <Navigate to={Paths.newUser} replace />;
  }

  // Prevent manual access to /new-user if user has API key
  if (location.pathname === "/new-user" && apiKey) {
    return <Navigate to={Paths.index} replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.any,
};

export default ProtectedRoute;
