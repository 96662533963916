// xplainable dashboard base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// xplainable dashboard helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderWidth, borderColor } = borders;
const { transparent, gradients, info } = colors;

const radio = {
  styleOverrides: {
    root: {
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      width: pxToRem(20),
      height: pxToRem(20),
      marginRight: pxToRem(6),
      padding: 0,
      color: transparent.main,
      border: `${borderWidth[1]} solid ${borderColor}`,
      borderRadius: "50%",
      transition: "all 250ms ease",

      colorPrimary: {
        backgroundColor: transparent.main,

        "&.Mui-checked": {
          borderColor: gradients.info.state,
          backgroundColor: "red",
          "& .MuiSvgIcon-root": {
            fill: transparent.main,
          },
        },

        "&:hover": {
          backgroundColor: transparent.main,
        },
      },

      colorSecondary: {
        backgroundColor: transparent.main,

        "&.Mui-checked": {
          borderColor: gradients.info.state,
          backgroundColor: "red",
          "& .MuiSvgIcon-root": {
            fill: transparent.main,
          },
        },

        "&:hover": {
          backgroundColor: transparent.main,
        },
      },
    },
  },
};

export default radio;
