

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for XInput
import XInputRoot from "components/XInput/XInputRoot";

// xplainable dashboard context
import { useXplainableController } from "context";

const XInput = forwardRef(({ size, error, success, disabled, ...rest }, ref) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  return (
    <XInputRoot {...rest} ref={ref} ownerState={{ size, error, success, disabled, darkMode }} />
  );
});

// Setting default values for the props of XInput
XInput.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the XInput
XInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default XInput;
