// usePreprocessorMutations.js
import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const usePreferencesMutations = () => {
  const { mutate: updatePreferences } = useMutation((params) =>
    apiHandlers.preferences.updatePreferences(...params)
  );

  return {
    updatePreferences,
  };
};