import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Icon } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// xplainable components
import XBox from 'components/XBox';
import XTypography from 'components/XTypography';
import XButton from 'components/XButton';
import FormField from 'shared/Forms/FormField';
import colors from 'assets/theme/base/colors';
import { useXplainableController } from 'context';

function OrganisationCreationModal({ isOpen, onClose, onSubmit, freeTrial=false}) {
  const [controller] = useXplainableController();

  const initialValues = {
    orgName: "",
    address: "",
    state: "",
    postCode: "",
    country: "",
    logo: "",
  };

  const validationSchema = Yup.object().shape({
    orgName: Yup.string().required("Organization name is required"),
    address: Yup.string().required("Address is required"),
    state: Yup.string().required("State is required"),
    postCode: Yup.string()
      .matches(/^[0-9]{4,8}$/, "Post Code must be 4-8 digits")
      .required("Post Code is required"),
    country: Yup.string().required("Country is required"),
    logo: Yup.string().url("Must be a valid URL").required("Logo is required"),
  });

  const fields = {
    orgName: {
      label: "Organisation Name",
      name: "orgName",
      placeholder: "Enter Organisation Name...",
    },
    address: {
      label: "Address",
      name: "address",
      placeholder: "Enter Address...",
    },
    state: {
      label: "State",
      name: "state",
      placeholder: "Enter State...",
    },
    postCode: {
      label: "Post Code",
      name: "postCode",
      placeholder: "Enter Post Code...",
    },
    country: {
      label: "Country",
      name: "country",
      placeholder: "Enter Country...",
    },
    logo: {
      label: "Logo",
      name: "logo",
      placeholder: "Enter Logo URL...",
    },
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="request-title"
      aria-describedby="request-description"
    >
      <XBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -60%)",
          width: 600,
          background: controller.darkMode ? colors.background.dark : colors.background.default,
          borderRadius: "16px",
          padding: 3,
        }}
      >
        <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <XTypography fontSize="16px" fontWeight="bold">
            Organisation Information
          </XTypography>
          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              color: controller.darkMode ? white.main : dark.main,
              stroke: controller.darkMode ? white.main : dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
            })}
            onClick={onClose}
          >
            close
          </Icon>
        </XBox>
        <Grid container spacing={0} sx={{ marginTop: 0 }}>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, errors, touched }) => (
                <Form id="orgForm" autoComplete="off">
                  <Grid container spacing={2} sx={{ marginTop: 0 }}>
                    {Object.keys(fields).map((key) => (
                      <Grid
                        item
                        xs={key === 'state' || key === 'postCode' ? 6 : 12}
                        key={key}
                        sx={{ paddingBottom: 0, paddingTop: 0 }}
                      >
                        <XTypography fontSize="14px" fontWeight="medium">
                          {fields[key].label}
                        </XTypography>
                        <FormField
                          as="input"
                          type="text"
                          id={fields[key].name}
                          name={fields[key].name}
                          placeholder={fields[key].placeholder}
                          error={touched[fields[key].name] && errors[fields[key].name]}
                          success={values[fields[key].name].length > 0 && !errors[fields[key].name]}
                          sx={{ marginBottom: 0, marginTop: 0 }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    <XBox display="flex" justifyContent="flex-end">
                      <XButton variant="gradient" color="button" size="medium" type="submit">
                        {freeTrial ? "Start your 7 day free trial" : "Create Organisation"}
                      </XButton>
                    </XBox>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </XBox>
    </Modal>
  );
}

OrganisationCreationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  freeTrial: PropTypes.bool,
};

export default OrganisationCreationModal;
