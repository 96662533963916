// useMixPanelQuery.js
import { apiHandlers } from "../apiHandlers";
import { useMutation } from "@tanstack/react-query";

//Add delete invite mutation
export const useInviteMutation = () => {
  const { mutate: deleteInviteMutation } = useMutation((params) =>
    apiHandlers.invitations.deleteInvitation(...params)
  );

  const { mutate: handleInvitationMutation } = useMutation((params) =>
    apiHandlers.invitations.handleInvitations(...params)
  );

  const { mutate: inviteUserMutation } = useMutation((params) =>
    apiHandlers.invitations.inviteUser(...params)
  );

  return {
    deleteInviteMutation,
    handleInvitationMutation,
    inviteUserMutation,
  };
};
