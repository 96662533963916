import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useRecentQuery = (activeWorkspace, apiKey, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.RECENT, activeWorkspace, apiKey],
    () => apiHandlers.recent.getRecentData(activeWorkspace, apiKey),
    {
      cacheTime: 60000, // Refetch data every 60,000 milliseconds (1 minute)
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
