import React, { useState, useEffect } from "react";
import { useXplainableController } from "context";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import XTypography from "components/XTypography";
import XBox from "components/XBox";
import XBadge from "components/XBadge";
import XButton from "components/XButton";

import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

import ChevronLeftIcon from "assets/images/chevron-left-icon.svg";

import { Icon } from "@mui/material";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useLocation } from "react-router-dom";
import { SidenavMenuSelect } from "./components";
import { RxAvatar } from "react-icons/rx";

// Import the OrganisationCreationModal and useOrganisationCreation hook
import OrganisationCreationModal from 'shared/Modals/OrganisationModal';
import { useOrganisationCreation } from 'hooks/useOrganisationCreation';

export default function SideNavMenu() {
  const { 
    preferences, 
    setPreferences, 
    workspaces, 
    activeWorkspace, 
    setActiveWorkspace, 
    viewState,
    requests 
  } = useApiKey();
  
  const [controller] = useXplainableController();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [pendingSelectedOrg, setPendingSelectedOrg] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [menuName, setMenuName] = React.useState(null);
  
  const location = useLocation();
  const { organisation_id, team_id } = location.state || {}; // Fetch the data from location state if it exists

  // Initialize the useOrganisationCreation hook
  const { isModalOpen, handleOpen, handleClose, handleSubmit } = useOrganisationCreation();

  // Fetch the organisation and team on first render
  useEffect(() => {
    if (preferences && workspaces) {
      const initialOrg = getOrganisationById(preferences.active_organisation_id);

      setSelectedOrg(initialOrg);
      setMenuName(initialOrg?.organisation_id);
      setSelectedTeam(getTeamById(initialOrg, preferences?.active_team_id));
    }
  }, [preferences, workspaces, requests]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => { // Renamed to avoid conflict with handleClose from hook
    setAnchorEl(null);
  };

  const getOrganisationById = (id) => workspaces?.find((org) => org.organisation_id === id);
  const getTeamById = (org, id) => org?.teams.find((team) => team.team_id === id);

  const activeOrganisation =
    preferences && workspaces && getOrganisationById(activeWorkspace?.organisation_id);

  const activeTeam =
    activeOrganisation && preferences && getTeamById(activeOrganisation, activeWorkspace?.team_id);

  const clickListener = (item) => {
    if (menuName) {
      setSelectedTeam(item);
      setSelectedOrg(pendingSelectedOrg);
      let updatedPreferences;

      // Update the local storage with the new preferences.
      if (localStorage.getItem("preferences")) {
        let storedPreferences = JSON.parse(localStorage.getItem("preferences"));
        updatedPreferences = { ...preferences, ...storedPreferences };
      } else {
        updatedPreferences = { ...preferences };
      }

      // Update the preferences with the new active organisation and team
      updatedPreferences.active_organisation_id =
        pendingSelectedOrg?.organisation_id || activeOrganisation?.organisation_id;
      updatedPreferences.active_team_id = item.team_id;

      // Stringify and store the updated preferences object in localStorage.
      localStorage.setItem("preferences", JSON.stringify(updatedPreferences));
      setPreferences(updatedPreferences);
      setActiveWorkspace({
        user_id: updatedPreferences.user_id,
        organisation_id: updatedPreferences.active_organisation_id,
        team_id: updatedPreferences.active_team_id,
      });
      handleMenuClose();
    } else {
      setPendingSelectedOrg(item);
      setMenuName(item.organisation_id);
    }
  };

  const teams = workspaces?.find((item) => item.organisation_id === menuName);

  const menuItems = menuName ? teams?.teams : workspaces;

  useEffect(() => {
    if (organisation_id && team_id) {
      let updatedPreferences = { ...preferences };

      updatedPreferences.active_organisation_id = organisation_id;
      updatedPreferences.active_team_id = team_id;

      localStorage.setItem("preferences", JSON.stringify(updatedPreferences));
      setPreferences(updatedPreferences);
      setActiveWorkspace({
        user_id: updatedPreferences.user_id,
        organisation_id: updatedPreferences.active_organisation_id,
        team_id: updatedPreferences.active_team_id,
      });
    }
  }, [organisation_id, team_id, preferences, setPreferences, setActiveWorkspace]);

  return (
    <>
      {/* Include the OrganisationCreationModal */}
      <OrganisationCreationModal 
        isOpen={isModalOpen} 
        onClose={handleClose} 
        onSubmit={handleSubmit} 
      />

      {activeOrganisation && activeTeam && (
        <SidenavMenuSelect
          image={
            activeOrganisation?.logo_link === "" ? 
            <RxAvatar style={{ color: controller?.darkMode || controller?.darkSidenav ? "white": 'black', fontSize: '30px' }}  /> 
            : 
            <img style={{ width: "40px", height: "40px" }} src={activeOrganisation?.logo_link} />     
          }
          title={[activeOrganisation.organisation_name]}
          description={activeTeam.team_name}
          onClick={handleClick}
          anchorEl={anchorEl}
        />
      )}
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{ top: "-70px" }}
      >
        {menuName && [
          <XBox
            display="flex"
            gap="10px"
            alignItems="center"
            onClick={() => setMenuName(null)}
            key="back-to-orgs"
          >
            <XBox src={ChevronLeftIcon} component="img" />
            <XTypography variant="caption" fontWeight="medium">
              Back to Organisations
            </XTypography>
          </XBox>,
          <XBox mt={2} key="select-team-title">
            <XTypography variant="button" fontWeight="light">
              Select Team
            </XTypography>
          </XBox>,
        ]}
        <XBox display="flex" flexDirection="column" overflowY="auto">
          {menuItems?.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => clickListener(item)}
              sx={{
                marginTop: "8px",
                padding: "0",
                minWidth: "250px",
              }}
            >
              <XBox
                display="flex"
                gap="8px"
                sx={{
                  border: menuName ? "1px solid #EAEAEA" : "none",
                  borderRadius: "8px",
                  width: "100%",
                  padding: "8px",
                }}
              >
                <XBox>
                  {item && item.logo_link ? (
                    <img src={item.logo_link} style={{ width: "24px", height: "24px" }} alt="Organisation Logo" />
                  ) : (
                    <XBox
                      width="1.5rem"
                      height="1.5rem"
                      display="grid"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="md"
                      shadow="md"
                      color="xppink"
                      bgColor={rgba(colors.xppink.main, 0.1)}
                      variant="gradient"
                    >
                      <Icon fontSize="default">{item.display_icon}</Icon>
                    </XBox>
                  )}
                </XBox>
                <XBox display="flex" flexDirection="column" gap="4px">
                  {menuName && (
                    <XBadge
                      badgeContent={item.role_name}
                      variant="contained"
                      color={
                        item.role_name !== "admin"
                          ? item.role_name === "creator"
                            ? "xppink"
                            : "xpblue"
                          : "success"
                      }
                    />
                  )}
                  <XTypography variant="button">
                    {menuName ? item.team_name : item.organisation_name}
                  </XTypography>
                  <XTypography variant="caption" sx={{ maxWidth: "176px", whiteSpace: "wrap" }}>
                    {menuName ? item.team_description : ""}
                  </XTypography>
                </XBox>
              </XBox>
              {!menuName && <ChevronRightIcon />}
            </MenuItem>
          ))}

          {/* Conditionally render the "Create New Organisation" button only when menuName is null (Organisations menu) */}
          {!menuName && viewState === "creator" && (
            <XBox pt={2} width="100%">
              <XButton variant="gradient" color="button" fullWidth onClick={handleOpen}>
                Create New Organisation
              </XButton>
            </XBox>
          )}
        </XBox>
      </Menu>
    </>
  );
}
