import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const usePayloadQuery = (deployment_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.PAYLOAD, deployment_id],
    () => apiHandlers.deployments.getPayload(deployment_id),
    {
      onError: (error) => {
        console.error("Error fetching deployments:", error);
        handleTokenExpired(error, logout);
      },
    }
  );
};
