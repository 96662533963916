import { useState, useEffect } from "react";
import PropTypes from "prop-types";

//Mui Components
import { Grid, TextField, Icon, Card } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XBox from "components/XBox";
import XInput from "components/XInput";

//User Defined Components
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import LinePlot from "./components/LinePlot";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
  },
});

function Optimisation({ scoreData, isLoading }) {
  const [values, setValues] = useState([0, 0, 0, 0]);
  const [calculatedSums, setCalculatedSums] = useState({ sums: [], values: [] });

  useEffect(() => {
    const newSums = calculateSums();
    setCalculatedSums(newSums);
  }, [values]);

  const handleValueChange = (index, value) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value;
      return newValues;
    });
  };

  const calculateSums = () => {
    const sums = scoreData.map((obj) => {
      const { fp, fn, tp, tn } = obj;

      //Divide by total to get the proportion
      const total = fp + fn + tp + tn;
      return (
        (fp * values[0]) / total +
        (fn * values[1]) / total +
        (tp * values[2]) / total +
        (tn * values[3]) / total
      );
    });

    if (sums.length === 0) {
      return {
        sums: Array.from({ length: 101 }, (_, i) => 0),
        values: Array.from({ length: 101 }, (_, i) => i),
      };
    } else {
      return { sums, values: Array.from({ length: 101 }, (_, i) => i) };
    }
  };

  const questions = [
    {
      key: "false positive",
      question: "What is the value of a false positive?",
    },
    {
      key: "false negative",
      question: "What is the value of a false negative?",
    },
    {
      key: "true positive",
      question: "What is the value of a true positive?",
    },
    {
      key: "true negative",
      question: "What is the value of a true negative?",
    },
  ];

  return (
    <>
      <Grid container>
        <Card sx={{ p: 2, width: "100%" }}>
          <Grid item xs={12}>
            <XTypography variant="h5" py={1} fontSize="18px">
              Threshold Optimisation
            </XTypography>
            <XTypography variant="h6" color="secondary" fontWeight="light">
              Optimise the threshold value to fit your problem specifications
            </XTypography>
          </Grid>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} lg={5.5}>
              {isLoading ? (
                <LoadingSpinner size={20} />
              ) : (
                <Card sx={{ boxShadow: "none" }}>
                  <LinePlot data={calculatedSums} />
                </Card>
              )}
            </Grid>
            <Grid item xs={12} lg={6.5}>
              {isLoading ? (
                <LoadingSpinner size={20} />
              ) : (
                <Card
                  sx={{
                    py: 2,
                    px: 2,
                    width: "100%",
                    height: "432px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "flex-start",
                    justifyContent: "start",
                    boxShadow: "none",
                  }}
                >
                  {questions.map((item, index) => (
                    <XBox width={"100%"} key={index} py={1} display="flex" alignItems="center">
                      <XBox
                        width={"90%"}
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        gap="4px"
                      >
                        <XBox display="flex">
                          <XTypography variant="body1" fontSize="14px" mr={1}>
                            {item.question}
                          </XTypography>
                          <CustomWidthTooltip title={tooltipData[item.key]} placement="top">
                            <XButton
                              variant="outlined"
                              color="secondary"
                              size="xs"
                              circular
                              iconOnly
                              ml={3}
                            >
                              <Icon>question_mark</Icon>
                            </XButton>
                          </CustomWidthTooltip>
                        </XBox>
                        <XInput
                          sx={{ width: "100%" }}
                          type="number"
                          placeholder="Number"
                          value={values[index]}
                          onChange={(e) => handleValueChange(index, e.target.value)}
                        />
                      </XBox>
                    </XBox>
                  ))}
                </Card>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

export default Optimisation;

Optimisation.propTypes = {
  scoreData: PropTypes.array,
  isLoading: PropTypes.bool,
};

const tooltipData = {
  "true positive": `The model correctly predicts that a customer will churn. Example: The 
    model predicts a customer will leave the company, so you offer them a discount or 
    improved service. As a result, the customer stays, and you retain their business. 
    This outcome represents revenue saved by retaining the customer through proactive retention strategies.`,
  "true negative": `The model correctly predicts that a customer will not churn. Example: 
    The model predicts a customer will stay with the company, and they do. This outcome maintains 
    business stability and customer trust.`,
  "false positive": `The model incorrectly predicts that a customer will churn. Example: The 
    model predicts a customer will leave, so you offer them a discount or improved service. 
    However, the customer would have stayed with the company anyway, resulting in an unnecessary 
    cost. This outcome represents unnecessary costs spent on retention strategies for customers who would have stayed.`,
  "false negative": `The model fails to predict that a customer will churn. Example: The model 
    predicts a customer will stay, but they end up leaving the company without any intervention, 
    resulting in lost revenue. This outcome represents lost revenue from the customer who churns 
    without being identified and the cost of acquiring new customers to replace them.`,
};
