import React from "react";
import { Grid } from "@mui/material";
import XBox from "components/XBox";
import FeatureRequest from "shared/Cards/FeatureRequest";
import ComingSoon from "shared/Cards/ComingSoon";

const DeploymentSettings = () => {
  return (
    <>
      <XBox display="flex" width="100%">
        <ComingSoon
          title={"Deployment Settings"}
          caption={"No settings are available for Deployments just yet."}
        />
      </XBox>
    </>
  );
};

export default DeploymentSettings;
