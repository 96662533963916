// @mui material components
import Fade from "@mui/material/Fade";

// xplainable dashboard base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

// xplainable dashboard helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { light, xpblue, dark } = colors;
const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;

const tooltip = {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(200),
      backgroundColor: dark.main,
      color: light.main,
      fontSize: size.md,
      fontWeight: fontWeightRegular,
      textAlign: "center",
      borderRadius: borderRadius.md,
      opacity: 0.7,
      padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(4)}`,
    },

    arrow: {
      color: dark.main,
    },
  },
};

export default tooltip;
