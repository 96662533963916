import { Paths } from "settings";

//TODO: fix this so that it works
export const handleTokenExpired = (err, logout) => {
  if (err?.response?.status === 401) {
    // Clear the specific items in local storage
    // localStorage.removeItem("apiKey");
    // localStorage.removeItem("workspaces");

    logout({
      logoutParams: { returnTo: `${window.location.origin}${Paths.login}` },

      onComplete: () => {
        console.log("Auth0 logout completed");
      },
    });

    window.location.replace(Paths.login);
  }
};
