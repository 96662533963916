import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useCardsMutation = () => {
  const { mutate: editCardMutation } = useMutation((params) =>
    apiHandlers.cards.editCard(...params)
  );

  return {
    editCardMutation,
  };
};
