import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useOutputMutation = () => {
  const { mutate: enableExplainMutation } = useMutation((params) =>
    apiHandlers.output.enableExplain(...params)
  );

  const { mutate: disableExplainMutation } = useMutation((params) =>
    apiHandlers.output.disableExplain(...params)
  );

  return {
    enableExplainMutation,
    disableExplainMutation,
  };
};
