import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import { Slider } from "@mui/material";

import "assets/css/tooltip.css";
import { useModel } from "hooks";
import { useXplainableController } from "context";

const RegressionChart = ({ regressionBins }) => {
  const { collapsedWidth } = useModel();
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const [numBins, setNumBins] = useState(25);

  const margin = { top: 10, right: 10, bottom: 40, left: 30 };

  // Element References
  const svgContainer = useRef(null); // The PARENT of the SVG
  const svgRef = useRef(null);

  // State to track width and height of SVG Container
  //TODO: Pass the div component height
  const [width, setWidth] = useState(450);
  const [height, setHeight] = useState(250 - margin.top - margin.bottom);

  useEffect(() => {
    const container = svgContainer.current;

    const handleResize = (entries) => {
      for (let entry of entries) {
        const newWidth = entry.contentRect.width - margin.left - margin.right;
        setWidth(newWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Observe the container element
    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      // Disconnect the observer when the component unmounts
      resizeObserver.disconnect();
    };
  }, [svgContainer]);

  const binData = (data, binCount) => {
    const binnedData = [];
    const binSize = Math.ceil(data.length / binCount);
    const dataRange = regressionBins.max - regressionBins.min;

    for (let i = 0; i < data.length; i += binSize) {
      const sum = data.slice(i, i + binSize).reduce((a, b) => a + b, 0);
      const startX = (i / data.length) * dataRange + regressionBins.min; // scaled starting value
      const endX = ((i + binSize) / data.length) * dataRange + regressionBins.min; // scaled ending value
      binnedData.push({
        x0: startX,
        x1: endX,
        length: sum,
      });
    }

    return binnedData;
  };

  useEffect(() => {
    if (
      !svgRef.current ||
      !regressionBins ||
      !regressionBins.bins ||
      !regressionBins.bins.train.true
    ) {
      return;
    }

    const svg = d3.select(svgRef.current);
    // width = 450 - margin.left - margin.right;
    // height = 250 - margin.top - margin.bottom;

    // Define the transition
    const t = d3.transition().duration(750);

    const binnedData = binData(regressionBins.bins.train.true, numBins);

    const x = d3.scaleLinear().domain([regressionBins.min, regressionBins.max]).range([0, width]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(binnedData, (d) => d.length)])
      .range([height, 0]);

    // Bars
    const bars = svg.selectAll("rect").data(binnedData);

    bars
      .enter()
      .append("rect")
      .on("mouseover", function () {
        d3.select(this).attr("fill", rgba(colors.xppink.main, 0.9));
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", rgba(colors.xppink.main, 0.8));
      })
      .merge(bars)
      .transition(t)
      .attr("x", (d) => x(d.x0) + margin.left) // Add margin.left to x position
      .attr("width", (d) => x(d.x1) - x(d.x0) - 1)
      .attr("y", (d) => y(d.length) + margin.top) // Add margin.top to y position
      .attr("height", (d) => height - y(d.length))
      .attr("fill", rgba(colors.xppink.main, 0.8))
      .attr("rx", 3)
      .attr("ry", 3);

    bars.exit().transition(t).attr("height", 0).remove();

    // Adjusting axis positioning by appending them to g elements that are translated
    let xAxisGroup = svg.select(".x-axis");
    if (xAxisGroup.empty()) {
      xAxisGroup = svg.append("g").attr("class", "x-axis");
    }
    xAxisGroup
      .attr("transform", `translate(${margin.left},${height + margin.top})`)
      .transition(t)
      .call(d3.axisBottom(x));

    let yAxisGroup = svg.select(".y-axis");
    if (yAxisGroup.empty()) {
      yAxisGroup = svg.append("g").attr("class", "y-axis");
    }
    yAxisGroup
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .transition(t)
      .call(d3.axisLeft(y));

    const tooltip = d3.select("#tooltip");

    bars
      .on("mouseenter", function (d) {
        // <-- Added mouse enter event

        tooltip.select("#count").text(d.length);

        const barX = x(d.x0) + margin.left;
        const barY = y(d.length) + margin.top - 15;

        // Assuming the alpha color you want is:
        const alphaColor = `${rgba(colors.xppink.main, 0.2)}`; // Adjust accordingly

        tooltip.classed("bottom-arrow", true);

        tooltip
          .style("transform", `translate(calc(-50% + ${barX}px), calc(-100% + ${barY}px))`)
          .style("opacity", 0.9)
          .style("background", alphaColor)
          .style("color", colors.xppink.main); // Setting the text color

        // Set the alpha color for the ::before pseudo-element
        const styleElement = document.createElement("style");
        styleElement.innerHTML = `
                .tooltip::before {
                    border-top: 11px solid ${alphaColor};
                }
            `;
      })
      .on("mouseleave", function () {
        tooltip.style("opacity", 0);
      });
    svg.selectAll("text").style("fill", darkMode ? colors.white.main : colors.black.main);
    svg.selectAll(".domain").style("stroke", darkMode ? colors.white.main : colors.black.main);
  }, [regressionBins, numBins, width]);

  return (
    <div ref={svgContainer} style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: "-10px", right: "10px", width: "150px" }}>
        <Slider
          aria-labelledby="num-bins-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={10}
          max={100}
          value={numBins}
          onChange={(event, newValue) => setNumBins(newValue)}
        />
      </div>
      <div id="tooltip" className="tooltip">
        <div className="tooltip-value">
          <span id="count" />
        </div>
      </div>
      <svg ref={svgRef} width={"100%"} height={height + margin.top + margin.bottom} />
    </div>
  );
};

// Proptypes
RegressionChart.propTypes = {
  regressionBins: PropTypes.object,
};

export default RegressionChart;
