import { useState, useEffect } from "react";
import { PropTypes } from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XAvatar from "components/XAvatar";

// xplainable dashboard example components
import DashboardNavbar from "shared/Navbars/DashboardNavbar";

// xplainable dashboard base styles
import breakpoints from "assets/theme/base/breakpoints";
import XBadge from "components/XBadge";

function ReuseableHeader({
  title,
  description,
  icon,
  user,
  color,
  rhsElement,
  tabs,
  tabValue,
  setTabValue,
  width,
  isActiveModel,
  tabColor,
}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <XBox>
      <DashboardNavbar color={color} />

      {tabs && (
        <XBox maxWidth="max-content">
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{
                "& .Mui-selected": {
                  color: `${tabColor} !important`,
                  fontWeight: "600",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: tabColor,
                },
              }}
            >
              {tabs &&
                tabs.map((tab, index) => (
                  <Tab
                    px={5}
                    key={index}
                    label={tab.label}
                    sx={{
                      padding: "0",
                      minWidth: "0",
                    }}
                  />
                ))}
            </Tabs>
          </AppBar>
        </XBox>
      )}

      {rhsElement ? (
        <Card
          sx={{
            py: 3,
            px: 3,
            mt: 5,
            // boxShadow: ({ boxShadows: { md } }) => md,
            position: "relative", // Add this line
            zIndex: 100, // Move zIndex inside the sx object
            overflow: "visible", // Change the overflow property to visible
          }}
        >
          <XBox
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {rhsElement}
          </XBox>
        </Card>
      ) : (
        <></>
        // <AppBar position="static">
        //   <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
        //     {tabs &&
        //       tabs.map((tab, index) => (
        //         <Tab
        //           px={5}
        //           key={index}
        //           label={tab.label}
        //           icon={
        //             <i
        //               className={tab.iconClassName}
        //               style={{ marginTop: "6px", marginRight: "10px" }}
        //             />
        //           }
        //         />
        //       ))}
        //   </Tabs>
        // </AppBar>
      )}
    </XBox>
  );
}

export default ReuseableHeader;

ReuseableHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  isActiveModel: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  user: PropTypes.string,
  rhsElement: PropTypes.element,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      iconClassName: PropTypes.string,
    })
  ),
  tabValue: PropTypes.number,
  setTabValue: PropTypes.func,
  width: PropTypes.string,
  tabColor: PropTypes.string,
};
