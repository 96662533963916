// xplainable dashboard base styles
import colors from "assets/theme/base/colors";

// xplainable dashboard helper functions
import rgba from "assets/theme/functions/rgba";

export const defaultStyle = (darkMode) => {
  return {
    control: {
      backgroundColor: darkMode ? colors.dark : "#fff",
      fontSize: 14,
      fontWeight: "normal",
      borderRadius: "0.5rem",
      whiteSpace: "nowrap",
    },

    "&multiLine": {
      highlighter: {
        padding: "0.625rem 2.75rem 4px 0.75rem",
        border: "1px solid transparent",
        borderRadius: "0.5rem",
      },
      input: {
        padding: "0.625rem 2.75rem 4px 0.75rem",

        border: "0.0625rem solid #d2d6da",
        borderRadius: "0.5rem",
        color: darkMode ? "#fff" : "#000",
      },
    },

    "&singleLine": {
      display: "inline-block",
      width: 180,

      highlighter: {
        padding: 1,
        border: "2px inset transparent",
        borderRadius: "0.5rem",
      },
      input: {
        padding: 1,
        border: "2px inset",
        borderRadius: "0.5rem",
      },
    },

    suggestions: {
      zIndex: "2",
      whiteSpace: "nowrap",
      position: "relative",
      marginTop: "0",
      top: "0",

      list: {
        position: "absolute",
        zIndex: "5",
        backgroundColor: darkMode ? colors.dark : "#fff",

        border: darkMode ? "1px solid black " : "1px solid rgba(0,0,0,0.15)",
        borderRadius: "0.5rem",

        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",

        "&focused": {
          backgroundColor: darkMode ? "#344767" : rgba(colors.xppurple.main, 0.1),
          color: darkMode ? "#fff" : "#000",
        },
      },
    },
  };
};
