import {
  Card,
  Collapse,
  Divider,
  Grid,
  Slider,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useModel, useToast } from "hooks";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useScenarioMutation } from "api/mutations";
import XBox from "components/XBox";
import XButton from "components/XButton";
import XDatePicker from "components/XDatePicker";
import XInput from "components/XInput";
import XProgress from "components/XProgress";
import XTypography from "components/XTypography";
import * as d3 from "d3";
import ScoreHistogram from "layouts/collections/collection/components/CollectionItems/components/ScoreHistogram";
import { debounce } from "lodash";
import CollectionModal from "../../../scenario/components/CollectionModal";
import ExpandableInfo from "./components/ExpandableInfo";

import SearchIcon from "assets/images/search-icon.svg";

import { ReactComponent as DarkDownloadIcon } from "assets/images/icons/collections/dark-download-icon.svg";
import colors from "assets/theme/base/colors";
import { XImg } from "components/XImg";
import { useXplainableController } from "context";

const getInitialDate = () => {
  const today = new Date();
  return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(
    today.getDate()
  ).padStart(2, "0")}`;
};

function PredictionResult({ returnedData }) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const { apiKey, activeWorkspace } = useApiKey();
  const { profileData, model_id, selectedVersion, selectedPartition, calibrationMap } = useModel();
  const { addScenarioMutation } = useScenarioMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const [searchInput, setSearchInput] = useState("");
  const [expandedRow, setExpandedRow] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [probValue, setProbValue] = useState([0, 100]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [date, setDate] = useState(getInitialDate()); // Use this single date state

  // Get the scores for the d3 plot
  const scores = returnedData.map((d) => d.score);
  const minPoint = Math.min(...scores).toFixed(3);
  const maxPoint = Math.max(...scores).toFixed(3);
  const midPoint = ((parseFloat(maxPoint) + parseFloat(minPoint)) / 2).toFixed(2);
  const [threshold, setThreshold] = useState(parseFloat(midPoint));

  // Values for the card
  const [averageScore, setAverageScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [minScore, setMinScore] = useState(Infinity);
  const [pctPositive, setPctPositive] = useState(0);

  // Define the range of colors
  const colorRange = ["#E14067", "#0080EA"];

  // Create the linear color scale
  const colorScale = d3
    .scaleLinear()
    .domain([minPoint * 100, maxPoint * 100])
    .range(colorRange);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handleExpandRow = (rowIndex) => {
    setExpandedRow(rowIndex === expandedRow ? -1 : rowIndex);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectCollection = (data) => {
    console.log("the data", data);
    //Set the selected collection for the endpoint
    setSelectedCollection(data);

    //Close Modal on click
    setShowCollectionModal(false);
  };

  const filterReturnedData = (data, searchInput) => {
    const searchRegex = new RegExp(searchInput, "i");

    // Add a condition to check if the index value matches the search input
    return data.filter((item) => {
      if (!item) return false;
      const withinProbaRange = item.proba * 100 >= probValue[0] && item.proba * 100 <= probValue[1];
      return (
        withinProbaRange && item["index"].toString().match(searchRegex) // Only match the index value
      );
    });
  };

  const minDistance = 5; // minimum distance between slider thumbs

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setProbValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setProbValue([clamped - minDistance, clamped]);
      }
    } else {
      setProbValue(newValue);
    }
  };

  const filteredReturnedData = filterReturnedData(returnedData, searchInput);

  // Calculate the width for each cell
  const numColumns = Object.keys(returnedData[0]).filter(
    (key) =>
      key !== "breakdown" && key !== "partition" && !(key === "id" && returnedData[0][key] === null)
  ).length;
  const cellWidth = 200 / numColumns; // +1 for the "Breakdown" column

  const columnTypes = {
    pred: {
      component: (result, key, index) => (
        <XBox display="flex" gap={0.5} alignItems="center">
          <XBox
            bgColor={result["score"] < threshold ? "xppink" : "success"}
            width="10px"
            height="10px"
            borderRadius="lg"
          />
          <XTypography variant="button">
            {result["index"]
              .toString()
              .padStart(Math.ceil(Math.log10(returnedData.length + 1)), "0")}
          </XTypography>
        </XBox>
      ),
    },
    proba: {
      component: (result, key, index) => (
        <XBox width="100%" ml={1}>
          <XProgress
            value={(result[key] * 100).toFixed(2)}
            color={colorScale((result[key] * 100).toFixed(2))}
            label={true}
          />
        </XBox>
      ),
    },
    score: {
      component: (result, key, index) =>
        typeof result[key] === "number" && result[key] !== undefined ? (
          <XTypography fontSize="14px">{result[key].toFixed(2)}</XTypography>
        ) : (
          <XTypography>{result[key]}</XTypography>
        ),
    },
    multiplier: {
      component: (result, key, index) =>
        typeof result["proba"] === "number" && result["proba"] !== undefined ? (
          <XTypography variant="button" fontWeight="regular">
            {(result["proba"] / profileData.base_value).toFixed(2) + "x"}
          </XTypography>
        ) : (
          <XTypography variant="button" fontWeight="regular">
            Not Available
          </XTypography>
        ),
    },
    support: {
      component: (result, key, index) =>
        typeof result[key] === "number" && result[key] !== undefined ? (
          <XTypography fontSize="14px">{result[key].toFixed(2)}</XTypography>
        ) : (
          <XTypography>{result[key]}</XTypography>
        ),
    },
  };

  const headerName = {
    pred: "Scenario No.",
    proba: "Probability",
    score: "Score",
    multiplier: "Multiplier",
    support: "Support",
    display: "",
  };

  const renderCell = (key, result, index) => {
    const columnType = columnTypes[key] || columnTypes.default;
    return columnType.component(result, key, index);
  };

  const [debouncedCallback] = useState(
    () =>
      debounce(() => {
        // Calculate the average score
        const totalScore = filteredReturnedData.reduce((sum, data) => sum + data.score, 0);
        setAverageScore(((totalScore / filteredReturnedData.length) * 100).toFixed(2) || 0); // prevent division by 0

        // Calculate the maximum score
        setMaxScore(
          (Math.max(...filteredReturnedData.map((data) => data.score), 0) * 100).toFixed(2)
        );

        // Calculate the minimum score
        setMinScore((Math.min(...filteredReturnedData.map((data) => data.score)) * 100).toFixed(2));

        // Calculate the percentage of scores that are greater than the first threshold slider
        const positiveScores = filteredReturnedData.filter((data) => data.score > threshold);
        setPctPositive(
          ((positiveScores.length / filteredReturnedData.length) * 100).toFixed(2) || 0
        ); // prevent division by 0
      }, 300) // Adjust the debounce delay (in milliseconds) as per your needs
  );

  useEffect(() => {
    debouncedCallback(); // Call the debounced callback

    // Cleanup the debounced callback on unmount
    return () => debouncedCallback.cancel();
  }, [filteredReturnedData, threshold, debouncedCallback]);

  useEffect(() => {
    debouncedCallback(); // Call the debounced callback

    // Cleanup the debounced callback on unmount
    return () => debouncedCallback.cancel();
  }, [filteredReturnedData, threshold, debouncedCallback]);

  function convertDataToNewFields(data) {
    console.log("the data", data);
    return data.map((item) => {
      const newFields = {};
      item.breakdown.forEach((breakdownItem) => {
        const { feature, score, value } = breakdownItem;
        newFields[feature] = {
          checked: false,
          field: value,
          value: score,
          keyName: feature,
        };
      });
      return newFields;
    });
  }

  const addScenario = async (model_id, collection_id, scenarios) => {
    // Map over scenarios to format each correctly
    const scenarioData = scenarios.map((scenario) => {
      // Calculate the total score by summing the values of each key in the scenario
      const totalScore = Object.values(scenario).reduce((sum, { value }) => sum + value, 0);
      const multiplier = totalScore / profileData.base_value;
      const probability = calibrationMap[(totalScore * 100).toFixed(0)];

      // Delete 'base_value' from the scenario if it exists
      delete scenario["base_value"];

      //Add the base value to each scenario
      scenario["Base Value"] = {
        keyName: "Base Value",
        field: null,
        value: profileData.base_value,
        checked: false,
      };

      console.log("the scenario", scenario);

      // Return the formatted scenario object
      return {
        version_id: selectedVersion.value,
        partition_id: selectedPartition.value,
        scenario: scenario,
        proba: probability,
        score: totalScore,
        multiplier: multiplier,
        support: 0,
        notes: "",
        added_date: date,
      };
    });

    //Call the mutation with the array of scenarios
    addScenarioMutation(
      [JSON.stringify(scenarioData)],
      {
        onSuccess: (data) => {
          showSuccessToast(`Scenario added successfully to ${selectedCollection.name}`);
        },
        onError: (error) => {
          console.log(error);
          showErrorToast(`Unable to add a new scenario - ${selectedCollection.name}`);
        },
      }
    );
  };

  const addToCollection = () => {
    if (selectedCollection?.collection_id) {
      //Convert the data to the new format
      const scenarios = convertDataToNewFields(filteredReturnedData);

      //Add the scenarios to the collection
      addScenario(model_id, selectedCollection.collection_id, scenarios);
    } else {
      console.log("No collection selected");
    }
  };

  return (
    <>
      {showCollectionModal && (
        <CollectionModal
          open={showCollectionModal}
          onClose={() => setShowCollectionModal(false)}
          handleSelectCollection={handleSelectCollection}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <XTypography variant="h6"> Score Distribution - All Scenarios</XTypography>
          <ScoreHistogram data={scores} threshold={threshold} />
        </Grid>
        <Grid item xs={12} lg={6} display="flex">
          <Card sx={{ p: 2, width: "100%" }}>
            <XBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <XBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <XTypography variant="button" fontWeight="light">
                  Threshold
                </XTypography>
                <XTypography variant="button" color="xpblue" fontSize="18px">
                  {threshold}
                </XTypography>
              </XBox>
              <XBox width="100%">
                <Slider
                  color={"primary"}
                  value={parseFloat(threshold)}
                  step={0.01}
                  min={parseFloat(minPoint)}
                  max={parseFloat(maxPoint)}
                  onChange={(event, sliderValue) => {
                    setThreshold(parseFloat(sliderValue));
                  }}
                  sx={{
                    "& .MuiSlider-track": {
                      background: "#0D0C0C",
                    },

                    "& .MuiSlider-thumb": {
                      borderColor: "#0D0C0C",
                    },
                  }}
                />
              </XBox>
            </XBox>
            <XBox>
              <XTypography variant="h6" fontSize="18px">
                Prediction Overview
              </XTypography>
              <XBox my={1}>
                <XBox display="flex" justifyContent="space-between" mb="8px">
                  <XTypography variant="button" fontWeight="light">
                    Average Score
                  </XTypography>
                  <XTypography variant="button" fontWeight="light" color="secondary">
                    {averageScore}%
                  </XTypography>
                </XBox>
                <XBox display="flex">
                  <XBox width="100%">
                    <XProgress
                      sx={{ height: "8px" }}
                      value={averageScore}
                      color={colorScale(averageScore)}
                      location={"right"}
                    />
                  </XBox>
                </XBox>
              </XBox>

              <XBox my={1}>
                <XBox display="flex" justifyContent="space-between" mb="8px">
                  <XTypography variant="button" fontWeight="light">
                    Max Score
                  </XTypography>
                  <XTypography variant="button" fontWeight="light" color="secondary">
                    {maxScore}%
                  </XTypography>
                </XBox>
                <XBox display="flex">
                  <XBox width="100%">
                    <XProgress
                      value={maxScore}
                      color={colorScale(maxScore)}
                      location={"right"}
                      sx={{ height: "8px" }}
                    />
                  </XBox>
                </XBox>
              </XBox>

              <XBox my={1}>
                <XBox display="flex" justifyContent="space-between" mb="8px">
                  <XTypography variant="button" fontWeight="light">
                    Min Score
                  </XTypography>
                  <XTypography variant="button" fontWeight="light" color="secondary">
                    {minScore}%
                  </XTypography>
                </XBox>
                <XBox display="flex">
                  <XBox width="100%">
                    <XProgress
                      value={minScore}
                      color={colorScale(minScore)}
                      location={"right"}
                      sx={{ height: "8px" }}
                    />
                  </XBox>
                </XBox>
              </XBox>

              <XBox my={1}>
                <XBox display="flex" justifyContent="space-between" mb="8px">
                  <XTypography variant="button" fontWeight="light">
                    Pct Positive
                  </XTypography>
                  <XTypography variant="button" fontWeight="light" color="secondary">
                    {pctPositive}%
                  </XTypography>
                </XBox>
                <XBox display="flex">
                  <XBox width="100%">
                    <XProgress
                      value={pctPositive}
                      color={colorScale(pctPositive)}
                      location={"right"}
                      sx={{ height: "8px" }}
                    />
                  </XBox>
                </XBox>
              </XBox>
            </XBox>
          </Card>
        </Grid>
      </Grid>

      <Grid container my={3}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <XBox display="flex" justifyContent="space-between" alignItems="center" width="100%" py={1}>
          <XTypography fontSize="18px" fontWeight="bold">
            Scenario
          </XTypography>
          <Grid item xs={12} lg={8}>
            <XBox display="flex" alignItems="flex-end" justifyContent="flex-end" px={2}>
              {selectedCollection?.collection_id ? (
                <XBox display="flex" flexDirection="row">
                  <XBox borderColor={colors?.secondary?.main}>
                    <XTypography variant="overline">Created Date</XTypography>
                    <XDatePicker
                      key={date} // The key prop might not be necessary unless you're explicitly forcing re-renders
                      value={date}
                      options={{
                        mode: "single",
                        enableTime: true, // Enable time selection
                        dateFormat: "Y-m-d H:i", // Adjust the format to include date and time
                        // Include any additional configurations here
                      }}
                      onChange={(selectedDateTime) => {
                        // Assuming selectedDateTime is an array with the selected date as the first element
                        if (selectedDateTime[0]) {
                          setDate(selectedDateTime[0].toISOString()); // Or format it as needed
                        }
                      }}
                    />
                  </XBox>
                  <XBox pl={2}>
                    <XTypography variant="overline">Selected Collection</XTypography>
                    <XBox display="flex" sx={{ alignItems: "center", borderRadius: "16px" }}>
                      <XBox
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="column"
                        minWidth={"120px"}
                      >
                        <XTypography
                          variant="button"
                          sx={{
                            minWidth: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {selectedCollection.name}
                        </XTypography>
                      </XBox>
                      <XBox></XBox>
                      <XBox pl={2}>
                        <Tooltip title="Add to Collection">
                          <XButton color="button" variant="gradient" onClick={addToCollection}>
                            Add
                          </XButton>
                        </Tooltip>
                      </XBox>
                    </XBox>
                  </XBox>
                </XBox>
              ) : (
                <XButton
                  color="button"
                  variant="gradient"
                  onClick={() => {
                    setShowCollectionModal(true);
                  }}
                >
                  {/* <XBox src={LightPlusIcon} component="img" /> */}
                  Add to Collection
                </XButton>
              )}
              <XBox
                ml={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Download the predictions to a csv file.">
                  <XButton
                    variant="light"
                    size="medium"
                    sx={{ borderRadius: "12px", padding: "10px", background: "#F7F7F8" }}
                    iconOnly
                  >
                    <XImg>
                      <DarkDownloadIcon />
                    </XImg>
                  </XButton>
                </Tooltip>
              </XBox>
            </XBox>
          </Grid>
        </XBox>
        <XBox display="flex" gap={3} alignItems="center" width="100%">
          <XInput
            placeholder="Scenario Number"
            startAdornment={<XBox src={SearchIcon} component="img" />}
            value={searchInput}
            onChange={handleSearchInput}
            sx={{ width: "100%" }}
          />
          <XBox display="flex" flexDirection="column" width="100%">
            <XBox display="flex" justifyContent="space-between" mb="4px">
              <XTypography variant="button" fontWeight="light">
                Probability Filter
              </XTypography>
            </XBox>
            <XBox display="flex" justifyContent="space-between">
              <XTypography variant="button" fontWeight="light">
                0
              </XTypography>
              <XTypography variant="button" fontWeight="light">
                100
              </XTypography>
            </XBox>
            <Slider
              getAriaLabel={() => "Minimum distance shift"}
              value={probValue}
              min={0}
              max={100}
              onChange={handleChange}
              valueLabelDisplay="auto"
              disableSwap
            />
          </XBox>
        </XBox>

        <Grid container>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <Table sx={{ width: "100%", tableLayout: "fixed", maxWidth: "100%" }}>
              <TableRow sx={{ width: "100%", tableLayout: "fixed", maxWidth: "100%" }}>
                {Object.keys(columnTypes)
                  .filter(
                    (key) =>
                      key !== "breakdown" &&
                      key !== "partition" &&
                      !(key === "id" && returnedData[0][key] === null)
                  )
                  .map((key) => (
                    <TableCell
                      key={key}
                      style={{
                        width: `${cellWidth}%`,
                        cursor: key !== "function" ? "pointer" : "default",
                      }}
                    >
                      <XBox
                        sx={{
                          display: "flex",
                          overflow: "hidden",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <XTypography variant="button" color="secondary" fontSize="12px">
                          {headerName[key]}
                        </XTypography>
                      </XBox>
                    </TableCell>
                  ))}
              </TableRow>
              <TableBody>
                {filteredReturnedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((result, index) => (
                    <>
                      <TableRow
                        key={`expandable-${result.customer_id}`}
                        sx={{
                          "&:hover": {
                            backgroundColor: darkMode ? "#1D1B1B" : "#f5f5f5",
                          },
                        }}
                      >
                        {Object.keys(columnTypes)
                          .filter(
                            (key) =>
                              key !== "breakdown" &&
                              key !== "partition" &&
                              !(key === "id" && result[key] === null)
                          )
                          .map((key) => (
                            <TableCell
                              key={key}
                              sx={{
                                py: "8px",
                                px: "8px",
                                borderBottom: "none",
                                cursor: key !== "function" ? "pointer" : "default",
                              }}
                              onClick={() => {
                                if (key !== "function") {
                                  handleExpandRow(index);
                                }
                              }}
                            >
                              <XBox
                                sx={{
                                  display: "flex",
                                  overflow: "hidden",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {renderCell(key, result, index)}
                              </XBox>
                            </TableCell>
                          ))}
                      </TableRow>
                      <TableRow width={"100%"}>
                        <TableCell sx={{ borderBottom: "none", padding: 0 }} colSpan={8}>
                          <Collapse in={expandedRow === index} timeout="auto">
                            {isLoading ? (
                              <XTypography>Loading...</XTypography>
                            ) : (
                              returnedData[0] && (
                                <ExpandableInfo
                                  data={returnedData[index].breakdown}
                                  collapsed={!(expandedRow === index)}
                                />
                              )
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              sx={{
                width: "100%",
                "& .MuiTablePagination-selectLabel": {
                  color: controller.darkMode ? "white !important" : "black !important",
                },
                "& .MuiTablePagination-displayedRows": {
                  color: controller.darkMode ? "white !important" : "black !important",
                },
                "& .MuiButtonBase-root": {
                  color: controller.darkMode ? "white !important" : "black !important",
                },
              }}
              component="div"
              count={filteredReturnedData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PredictionResult;

PredictionResult.propTypes = {
  showResults: PropTypes.bool,
  returnedData: PropTypes.array,
};

Completion.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
};

function Completion({ value, color }) {
  return (
    <XBox display="flex" alignItems="center">
      {/* <XTypography variant="caption" color="text">
        {value}%
      </XTypography> */}
      <XBox width="8rem" ml={1}>
        <XProgress value={value} color={color} label={true} />
      </XBox>
    </XBox>
  );
}

// function Scenario() {

//   const handleSelectCollection = (data) => {
//     //Set the selected collection for the endpoint
//     setSelectedCollection(data);

//     //Set the scenario count
//     setScenarioCount(data.scenario_count);

//     //Close Modal on click
//     setShowCollectionModal(false)
//   };
