import { AppBar, Tab, Tabs } from "@mui/material";
import XTypography from "components/XTypography";
import { MinimalistButton } from "layouts/autotrain/styled";
import colors from "assets/theme/base/colors";
import PropTypes from "prop-types";

import { ReactComponent as AnalystButtonIcon } from "assets/images/icons/train/analyst-icon.svg";
import { useAutoTrain } from "hooks";
import XButton from "components/XButton";

export const AnalystAppBar = ({
  onAnalystTab,
  analystTabValue,
  setIsOpenSavedPlots,
  isAnalyst,
}) => {
  const { tabValue, setTabValue, uploadSuccessful, isLoading, setIsAnalystOpen } = useAutoTrain();

  const handleTabValue = async (_, newValue) => {
    setTabValue(newValue);
    onAnalystTab(newValue);
  };

  return (
    <AppBar
      position="static"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Tabs
        value={analystTabValue ? analystTabValue : tabValue}
        onChange={handleTabValue}
        sx={{
          maxWidth: "700px",
          marginBottom: "24px",

          "& .Mui-selected": {
            color: `${colors.xppink.main} !important`,
            fontWeight: "600",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: colors.xppink.main,
          },
        }}
      >
        <Tab label="Overview" sx={{ minWidth: 0, maxWidth: "max-content" }} />
        <Tab label="Advanced Settings" sx={{ minWidth: 0, maxWidth: "max-content" }} />
      </Tabs>

      {uploadSuccessful &&
        !isLoading &&
        (isAnalyst ? (
          <XButton sx={{ backgroundColor: "#F7F7F8" }} onClick={() => setIsOpenSavedPlots(true)}>
            View saved plots
          </XButton>
        ) : (
          <MinimalistButton
            sx={{ mt: -10 }}
            onClick={() => setIsAnalystOpen((prevState) => !prevState)}
          >
            <AnalystButtonIcon />
            <XTypography
              sx={{
                backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "bold",
                transition: "all 0.3s",
                px: 1,
              }}
              fontSize="16px"
            >
              Analyst
            </XTypography>
          </MinimalistButton>
        ))}
    </AppBar>
  );
};

AnalystAppBar.propTypes = {
  onAnalystTab: PropTypes.func,
  analystTabValue: PropTypes.number,
  setIsOpenSavedPlots: PropTypes.func,
  isAnalyst: PropTypes.bool,
};
