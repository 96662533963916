import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// custom styles for the NotificationItem
import { menuItem, menuImage } from "shared/Items/NotificationItem/styles";
import { XDivider } from "components/XDivider";

const NotificationItem = forwardRef(
  ({ color, image, title, date, confirm, deny, windowNavigation, ...rest }, ref) => (
    <>
      <MenuItem {...rest} ref={ref} sx={{ padding: 0, margin: "16px 0" }}>
        <XBox
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          flexWrap="wrap"
        >
          <XBox display="flex" flex={1}>
            <XBox width="2.5em" height="2.5rem" mx={2} sx={(theme) => menuImage(theme, { color })}>
              {image}
            </XBox>
            <XBox flex={1} flexDirection="column" onClick={windowNavigation}>
              <XBox display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
                <XTypography
                  variant="button"
                  fontWeight="light"
                  ml={0.5}
                  fontSize="16px"
                  color="secondary"
                  flex="1 0 auto"
                  maxWidth="calc(100%)"
                  whiteSpace="normal"
                >
                  <strong>{title[0]}</strong> {title[1]}
                </XTypography>
              </XBox>
              <XTypography
                variant="button"
                color="secondary"
                fontWeight="light"
                sx={{
                  marginTop: "4px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {date}
              </XTypography>
            </XBox>
            <XBox display="flex" ml={1} mt={0.5}>
              <XBox>{confirm && confirm}</XBox>
              <XBox ml={1}>{deny && deny}</XBox>
            </XBox>
          </XBox>
        </XBox>
      </MenuItem>
    </>
  )
);
// Setting default values for the props of NotificationItem
NotificationItem.defaultProps = {
  color: "dark",
};

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
  confirm: PropTypes.object,
  deny: PropTypes.object,
  windowNavigation: PropTypes.func,
};

export default NotificationItem;
