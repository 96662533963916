import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import Members from "shared/Avatars/Members/index.jsx";

function ContributorsCard({ contributors, userData, isLoading = false }) {
  if (isLoading) {
    return (
      <Card
        sx={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <XBox display="flex" justifyContent="space-between">
          <Skeleton width={100} height={28} />
          <Skeleton width={30} height={28} />
        </XBox>
        <XBox display="flex" flexDirection="column" gap={1}>
          <Skeleton width={120} height={28} />
          <XBox display="flex" justifyContent="space-between" alignItems="center">
            <Skeleton width={30} height={28} />
            <Skeleton variant="circular" width={32} height={32} />
          </XBox>
        </XBox>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <XBox display="flex" justifyContent="space-between">
        <XTypography variant="button" fontSize="18px">
          Contributors
        </XTypography>
        <XTypography color="xpblue" variant="button" fontSize="24px">
          {contributors.length}
        </XTypography>
      </XBox>
      <XBox display="flex" flexDirection="column">
        <XTypography variant="button" fontSize="18px">
          Model Viewers
        </XTypography>
        <XBox display="flex" justifyContent="space-between">
          <XTypography color="xpblue" variant="button" fontSize="24px">
            {userData?.data?.length || 0}
          </XTypography>
          {userData?.data && (
            <XBox display="flex" justifyContent="right" alignItems="center">
              <Members members={userData?.data} size="s" numMembers={14} />
            </XBox>
          )}
        </XBox>
      </XBox>
    </Card>
  );
}

ContributorsCard.propTypes = {
  contributors: PropTypes.array.isRequired,
  userData: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default ContributorsCard;