import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useNetworkMutation = () => {
  const { mutate: createIpAddressMutation } = useMutation((params) =>
    apiHandlers.network.createIpAddress(...params)
  );

  const { mutate: handleToggleChangeMutation } = useMutation((params) =>
    apiHandlers.network.handleToggleChange(...params)
  );

  const { mutate: removeIpAddress } = useMutation((params) =>
    apiHandlers.network.removeIpAddress(...params)
  );

  return {
    createIpAddressMutation,
    handleToggleChangeMutation,
    removeIpAddress,
  };
};
