import { useMemo } from 'react';
import PropTypes from 'prop-types';

export const AxisBottom = ({ xScale, pixelsPerTick, height }) => {
  const range = xScale.range();

  const ticks = useMemo(() => {
    const width = range[1] - range[0];
    const numberOfTicksTarget = Math.floor(width / pixelsPerTick);

    // For a time scale, use `xScale.ticks()` directly without arguments or with a specific time interval
    return xScale.ticks(numberOfTicksTarget).map(value => ({
      value,
      xOffset: xScale(value),
    }));
  }, [xScale, pixelsPerTick]);

  return (
    <>
      {ticks.map(({ value, xOffset }) => (
        <g key={value} transform={`translate(${xOffset}, 0)`} shapeRendering="crispEdges">
          <line y1={0} y2={-height} stroke="#D2D7D3" strokeWidth={0.5} />
          <text dy="2em" style={{ fontSize: '10px', textAnchor: 'middle', fill: '#D2D7D3', marginTop:"10px" }}>
            {xScale.tickFormat()(value)}
          </text>
        </g>
      ))}
    </>
  );
};

AxisBottom.propTypes = {
  xScale: PropTypes.func.isRequired, // xScale is a function, typically from d3.scaleTime or d3.scaleLinear
  pixelsPerTick: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
