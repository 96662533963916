import React, { useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";

import XButton from "components/XButton";
import XBox from "components/XBox";

import LoadingSpinner from "shared/Animations/LoadingAnimation";

import { useApp, useModel } from "hooks";

import { useLocation, useNavigate } from "react-router-dom";
import { useReportsVersionQuery } from "api/query/useReportsVersionQuery";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

import PropTypes from "prop-types";
import { components } from "./constants";
import { useAuth0 } from "@auth0/auth0-react";

export const ReportPageWithData = ({ listsFromRequest, setListsFromRequest, reportVersion }) => {
  const { logout } = useAuth0();
  const { activeWorkspace, setViewState } = useApiKey();
  const { reportList, activeStep, setActiveStep, setReportId, reportId } = useApp();
  const { profileDataLoading } = useModel();

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("report_id");

  const version = reportId || id;

  const { data } = useReportsVersionQuery(version, logout, 0);
  useEffect(() => {
    if (data && activeStep === 1 && id) {
      setListsFromRequest(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (location.state?.id) {
      setReportId(location.state.id);
    }
  }, [location.state]);

  if (profileDataLoading) {
    return (
      <XBox height={"400px"}>
        <LoadingSpinner size={50} animationType="pulse" />
      </XBox>
    );
  }

  return (
    <>
      <Grid container spacing={2} mt={3}>
        {reportList
          ? reportList[reportVersion.value - 1]?.data[0]?.elements.map((element, index) => {
              const xs = 12;
              const sm = element?.numberOfColumns !== 2 ? 6 : 12;

              return (
                <Grid item xs={xs} sm={sm} key={index}>
                  <Card sx={{ boxShadow: "none" }}>
                    <CardContent sx={{ height: "100%", padding: "10px" }}>
                      {components[element.component](element.componentData)}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          : listsFromRequest
          ? listsFromRequest[reportVersion.value - 1]?.data[0]?.elements.map((element, index) => {
              const xs = 12;
              const sm = element?.numberOfColumns !== 2 ? 6 : 12;

              return (
                <Grid item xs={xs} sm={sm} key={index}>
                  <Card sx={{ boxShadow: "none" }}>
                    <CardContent sx={{ height: "100%", padding: "10px" }}>
                      {components[element.component](element.componentData)}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          : null}
      </Grid>
    </>
  );
};

ReportPageWithData.propTypes = {
  commentsValues: PropTypes.string,
  setCommentsValues: PropTypes.func,
  listsFromRequest: PropTypes.object,
  setListsFromRequest: PropTypes.func,
  reportVersion: PropTypes.object,
  reportList: PropTypes.array,
  reportInfo: PropTypes.object,
  setReportInfo: PropTypes.func,
};
