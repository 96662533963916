import React from "react";
import PropTypes from "prop-types";
import XBox from "components/XBox";
import xplainableLogo from "assets/images/xplainable-logo.svg";

const animations = {
  spin: {
    "@keyframes spin": {
      from: { transform: "rotate(0deg)" },
      to: { transform: "rotate(360deg)" },
    },
  },
  pulse: {
    "@keyframes pulse": {
      "0%": {
        transform: "scale(1)",
        opacity: 0.8,
      },
      "50%": {
        transform: "scale(1.3)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(1)",
        opacity: 0.8,
      },
    },
  },
};

const LoadingSpinner = ({ size, animationType, logo }) => {
  const animationStyles = animations[animationType];

  return (
    <XBox
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        ...animationStyles,
      }}
    >
      <img
        src={logo}
        style={{
          width: `${size}px`,
          animation: `${animationType} 1s linear infinite`,
        }}
      />
    </XBox>
  );
};

LoadingSpinner.propTypes = {
  logo: PropTypes.string,
  size: PropTypes.number,
  animationType: PropTypes.oneOf(["spin", "pulse"]),
};

LoadingSpinner.defaultProps = {
  logo: xplainableLogo,
  size: 100,
  animationType: "pulse",
};

export default LoadingSpinner;
