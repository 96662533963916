import React from "react";

import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useXplainableController } from "context";

export const TableItem = ({ item }) => {
  const [controller] = useXplainableController();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: "1",
        boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
        padding: "0.5rem",
        borderRadius: "0.375rem",
        transitionDuration: "500ms",

        "&:hover": {
          boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
          transform: "translateY(-2px)",
        },
      }}
    >
      <Box
        sx={{
          width: "40px",
          height: "40px",
          borderRadius: "0.375rem",
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgb(209 213 219)",
        }}
      >
        N
      </Box>
      <Box
        sx={{
          fontSize: "1rem",
          maxWidth: "15rem",
          color: `${controller.darkMode ? "white" : "black"} !important`,
        }}
      >
        {item.field}
      </Box>
      <Box
        sx={{
          borderRadius: "0.75rem",
          backgroundColor: item.checked ? "#9ca3af" : item.value > 0 ? "#0080EA" : "#E14067",
          padding: "0.75rem",
          color: "#fff",
          fontSize: "0.75rem",
        }}
      >
        {item.value.toFixed(2)}
      </Box>
    </Box>
  );
};

TableItem.propTypes = {
  item: PropTypes.object,
};
