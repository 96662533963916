
// react-quill components
import ReactQuill from "react-quill";

// react-quill styles
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

// Custom styles for the XEditor
import XEditorRoot from "components/XEditor/XEditorRoot";

function XEditor(props) {
  return (
    <XEditorRoot>
      <ReactQuill {...props} />
    </XEditorRoot>
  );
}

export default XEditor;
