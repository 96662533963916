import React from "react";

import { Card, Icon, Tooltip } from "@mui/material";
import { useModel } from "hooks";

import XBox from "components/XBox";
import XButton from "components/XButton";
import XTypography from "components/XTypography";
import RegressionChart from "layouts/models/model/components/profile/components/RegressionChart";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import BarChart from "shared/models/BarChart";

export const BaseValue = () => {
  const { profileData, model_type, regressionBins, profileDataLoading } = useModel();

  let firstKey;
  if (profileData && (profileData.categorical || profileData.numeric)) {
    firstKey =
      profileData.categorical && Object.keys(profileData.categorical).length > 0
        ? Object.keys(profileData.categorical)[0]
        : Object.keys(profileData.numeric)[0];
  }

  if (profileDataLoading) {
    return (
      <Card
        sx={{
          display: "flex",
          width: "100%",
          height: "200px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingSpinner size={50} animationType="pulse" />
      </Card>
    );
  }

  if (profileData && Object.keys(profileData).length > 0 && firstKey) {
    return (
      <Card sx={{ height: "320px", position: "relative" }}>
        <XBox p={2}>
          <XTypography variant="h6" fontWeight="medium" color="secondary">
            Base Value
          </XTypography>
          <XTypography variant="h4" fontWeight="medium">
            {model_type === "binary_classification"
              ? (profileData.base_value * 100).toFixed(2) + "% "
              : profileData.base_value.toFixed(2)}
          </XTypography>
          {model_type === "binary_classification" && (
            <BarChart probability={profileData.base_value} />
          )}
          {model_type === "regression" && <RegressionChart regressionBins={regressionBins} />}
        </XBox>
        <XBox sx={{ position: "absolute", top: 8, right: 12 }}>
          <Tooltip
            title="This chart shows the distribution of the target variable. The base value refers to the average of the target."
            placement="top"
          >
            <XButton variant="outlined" color="secondary" size="small" circular iconOnly>
              <Icon>question_mark</Icon>
            </XButton>
          </Tooltip>
        </XBox>
      </Card>
    );
  }

  return null;
};
