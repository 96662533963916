import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import { Slider } from "@mui/material";

import "assets/css/tooltip.css";
import { useModel } from "hooks";
import { useXplainableController } from "context";

const ResidualChart = ({ regressionBins, numBins }) => {
  const { collapsedWidth } = useModel();

  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const margin = { top: 10, right: 10, bottom: 40, left: 30 };

  // Element References
  const svgContainer = useRef(null); // The PARENT of the SVG
  const svgRef = useRef(null);

  // State to track width and height of SVG Container
  const [width, setWidth] = useState(450);
  const [height, setHeight] = useState(450 - margin.top - margin.bottom);

  // This function calculates width and height of the container
  const getSvgContainerSize = () => {
    const newWidth = svgContainer.current.clientWidth - margin.left - margin.right;
    setWidth(newWidth);
  };

  useEffect(() => {
    // detect 'width' and 'height' on render
    getSvgContainerSize();
    // listen for resize changes, and detect dimensions again when they change
    window.addEventListener("resize", getSvgContainerSize);
    // cleanup event listener
    return () => window.removeEventListener("resize", getSvgContainerSize);
  }, [collapsedWidth]);

  const binData = (data, binCount) => {
    const binnedData = [];
    const binSize = Math.ceil(data.length / binCount);
    const dataRange = regressionBins.max - regressionBins.min;

    for (let i = 0; i < data.length; i += binSize) {
      const sum = data.slice(i, i + binSize).reduce((a, b) => a + b, 0);
      const startX = (i / data.length) * dataRange + regressionBins.min; // scaled starting value
      const endX = ((i + binSize) / data.length) * dataRange + regressionBins.min; // scaled ending value
      binnedData.push({
        x0: startX,
        x1: endX,
        length: sum,
      });
    }

    return binnedData;
  };

  useEffect(() => {
    if (
      !svgRef.current ||
      !regressionBins ||
      !regressionBins.bins ||
      !regressionBins.bins.true ||
      !regressionBins.bins.pred
    ) {
      return;
    }

    const svg = d3.select(svgRef.current);

    const binnedDataTrue = binData(regressionBins.bins.true, numBins);
    const binnedDataPred = binData(regressionBins.bins.pred, numBins);

    const x = d3.scaleLinear().domain([regressionBins.min, regressionBins.max]).range([0, width]);

    const y = d3
      .scaleLinear()
      .domain([
        0,
        Math.max(
          d3.max(binnedDataTrue, (d) => d.length),
          d3.max(binnedDataPred, (d) => d.length)
        ),
      ])
      .range([height, 0]);

    const t = d3.transition().duration(750); // Define the transition here

    // Function to draw bars
    const drawBars = (data, className, fillColor, opacity, t) => {
      const bars = svg.selectAll(`rect.${className}`).data(data);
      bars
        .enter()
        .append("rect")
        .attr("class", className)
        .on("mouseover", function () {
          d3.select(this).attr("fill", rgba(fillColor, opacity + 0.2));
        })
        .on("mouseout", function () {
          d3.select(this).attr("fill", rgba(fillColor, opacity));
        })
        .merge(bars)
        .transition(t)
        .attr("x", (d) => x(d.x0) + margin.left)
        .attr("width", (d) => x(d.x1) - x(d.x0) - 1)
        .attr("y", (d) => y(d.length) + margin.top)
        .attr("height", (d) => height - y(d.length))
        .attr("fill", rgba(fillColor, opacity))
        .attr("rx", 3)
        .attr("ry", 3);

      bars.exit().transition(t).attr("height", 0).remove();

      //TODO: This logic is not a nice way of add the legend
      // Define the legend data
      const legendData = [
        { label: "true", color: colors.xppink.main },
        { label: "pred", color: colors.xpblue.main },
      ];

      // Ensure we have a group to hold the legend items
      let legendGroup = svg.select(".legend-group");
      if (legendGroup.empty()) {
        legendGroup = svg
          .append("g")
          .attr("class", "legend-group")
          .attr("transform", `translate(${width - 30}, 10)`); // Adjust 100 as necessary for positioning from the right edge
      }

      // Create the legend items
      const legendItems = legendGroup
        .selectAll(".legend-item")
        .data(legendData)
        .style("fill", (d) => d.color);

      const newLegendItems = legendItems
        .enter()
        .append("g")
        .attr("class", "legend-item")

        .attr("transform", (d, i) => `translate(0, ${i * 20})`); // 20 is the vertical spacing between items

      newLegendItems
        .append("circle")
        .attr("r", 7)
        .attr("fill", (d) => rgba(d.color, 0.8));

      newLegendItems
        .append("text")
        .attr("x", 10) // Offset from the circle
        .attr("y", 5) // Align vertically with the circle
        .text((d) => d.label)
        .style("font-size", "12px");
    };

    drawBars(binnedDataTrue, "trueBar", colors.xppink.main, 0.8, t);
    drawBars(binnedDataPred, "predBar", colors.xpblue.main, 0.5, t);

    // Adjusting axis positioning by appending them to g elements that are translated
    let xAxisGroup = svg.select(".x-axis");
    if (xAxisGroup.empty()) {
      xAxisGroup = svg.append("g").attr("class", "x-axis");
    }
    xAxisGroup
      .attr("transform", `translate(${margin.left},${height + margin.top})`)
      .selectAll("path, line, text")
      .style("stroke", darkMode ? colors.dark.main : colors.black.main)
      .transition(t)
      .call(d3.axisBottom(x));

    let yAxisGroup = svg.select(".y-axis");
    if (yAxisGroup.empty()) {
      yAxisGroup = svg.append("g").attr("class", "y-axis");
    }
    yAxisGroup
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .selectAll("path, line, text")
      .style("stroke", darkMode ? colors.dark.main : colors.black.main)
      .transition(t)
      .call(d3.axisLeft(y));

    // bars
    // .on("mouseenter", function(d) {  // <-- Added mouse enter event

    //     tooltip.select("#count").text(d.length);

    //     const barX = x(d.x0) + margin.left
    //     const barY = y(d.length) + margin.top - 15

    //     // Assuming the alpha color you want is:
    //     const alphaColor = `${rgba(colors.xppink.main, 0.2)}`;  // Adjust accordingly

    //     tooltip.classed("bottom-arrow", true);

    //     tooltip
    //         .style("transform", `translate(calc(-50% + ${barX}px), calc(-100% + ${barY}px))`)
    //         .style("opacity", 0.9)
    //         .style("background", alphaColor)
    //         .style("color", colors.xppink.main);  // Setting the text color

    //     // Set the alpha color for the ::before pseudo-element
    //     const styleElement = document.createElement("style");
    //     styleElement.innerHTML = `
    //         .tooltip::before {
    //             border-top: 11px solid ${alphaColor};
    //         }
    //     `;
    // })
    // .on("mouseleave", function() {
    //     tooltip.style("opacity", 0);
    // })
  }, [regressionBins, numBins, width]);

  return (
    <div ref={svgContainer} style={{ position: "relative" }}>
      <div id="tooltip" className="tooltip">
        <div className="tooltip-value">
          <span id="count" />
        </div>
      </div>
      <svg ref={svgRef} width={"100%"} height={height + margin.top + margin.bottom} />
    </div>
  );
};

// Proptypes
ResidualChart.propTypes = {
  regressionBins: PropTypes.object,
  numBins: PropTypes.number,
};

export default ResidualChart;
