import { Typography } from "@mui/material";
import {
  BaseValue,
  Commentary,
  Comments,
  CurrentOverview,
  Curves,
  Pipeline,
  ReportModelProfile,
  ReportRegressionChart,
  ReportResidualChart,
  ReportScenarioAnalysis,
  ThresholdOptimisation,
} from "../components";
import { REPORT_COMPONENTS_KEYS } from "constants";

import DataHealth from "layouts/models/model/components/preprocessing/components/health";
import ThresholdPlot from "../components/ThresholdPlot";
import ConfusionMatrix from "../components/ConfusionMatrix";
import MetricsTable from "../components/Metrics";

export const components = {
  [REPORT_COMPONENTS_KEYS.BINARY_OVERVIEW]: () => <ReportModelProfile />,
  [REPORT_COMPONENTS_KEYS.WATERFALL_PLOT]: () => <ReportScenarioAnalysis />,
  [REPORT_COMPONENTS_KEYS.ARTICLE]: () => <Commentary />,
  [REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT]: () => <ThresholdPlot />,
  [REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX]: () => <ConfusionMatrix />,
  [REPORT_COMPONENTS_KEYS.METRICS]: () => <MetricsTable />,
  [REPORT_COMPONENTS_KEYS.PR_CURVE_ROC_CURVE]: () => <Curves />,
  [REPORT_COMPONENTS_KEYS.COMMENTS]: (text) => <Comments text={text} />,
  [REPORT_COMPONENTS_KEYS.LINE_CHART]: () => <Typography>Line Chart</Typography>,
  [REPORT_COMPONENTS_KEYS.HEALTH]: () => <DataHealth />,
  [REPORT_COMPONENTS_KEYS.PIPELINE]: () => <Pipeline />,
  [REPORT_COMPONENTS_KEYS.CURRENT_OVERVIEW]: () => <CurrentOverview />,
  [REPORT_COMPONENTS_KEYS.BASE_VALUE]: () => <BaseValue />,
  [REPORT_COMPONENTS_KEYS.REGRESSION_CHART]: () => <ReportRegressionChart />,
  [REPORT_COMPONENTS_KEYS.RESIDUAL_CHART]: () => <ReportResidualChart />,
  [REPORT_COMPONENTS_KEYS.THRESHOLD_OPTIMISATION]: () => <ThresholdOptimisation />,
};
