import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import colors from 'assets/theme/base/colors';

const Histogram = ({ data, column }) => {
  const svgRef = useRef();
  const nanRef = useRef();

  const { bins, binWidth, binCount, min, max, nanCount } = data.summaryMetrics[column].values ? data.summaryMetrics[column].values : data[column];
  const hasNan = nanCount > 0;
  const totalWidth = 200;
  const width = hasNan ? 190 : totalWidth; // Use full width if no NaN bar

  useEffect(() => {
    // Histogram SVG
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear existing content

    const margin = { top: 10, right: 5, bottom: 50, left: 10 };
    const plotWidth = width - margin.left - margin.right;
    const height = 230 - margin.top - margin.bottom;

    const x = d3.scaleLinear()
      .domain([min, min + binWidth * binCount]) // Original domain without NaN
      .range([margin.left, plotWidth - margin.right]);

    const y = d3.scaleLinear()
      .domain([0, d3.max([...bins, nanCount])]) // Use the same y scale for both plots
      .range([height - margin.bottom, margin.top]);

    const xAxis = svg.append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).ticks(binCount))
      .attr("font-size", '10px')
      .attr("color", colors.secondary.main);

    // Rotate labels if the magnitude is greater than 3
    xAxis.selectAll("text")
      .attr("transform", (d) => {
        if (typeof d === 'number' && Math.log10(d) > 2) {
          return "rotate(-45)";
        }
        return null;
      })
      .style("text-anchor", (d) => {
        if (typeof d === 'number' && Math.log10(d) > 2) {
          return "end";
        }
        return null;
      });

    // Calculate the bar width with padding
    const barPadding = 1; // Adjust this value for more or less padding
    const barWidth = (x(binWidth) - x(0)) - barPadding;

    // Draw the bars
    svg.selectAll("rect")
      .data(bins)
      .enter().append("rect")
      .attr("x", (d, i) => x(min + i * binWidth) + barPadding / 2)
      .attr("y", d => y(d))
      .attr("width", barWidth)
      .attr("height", d => y(0) - y(d))
      .attr("fill", colors.xpblue.main);

    if (hasNan) {
      // NaN bar SVG
      const nanSvg = d3.select(nanRef.current);
      nanSvg.selectAll("*").remove(); // Clear existing content

      const nanMargin = { top: 10, right: 0, bottom: 50, left: 0 };
      const nanWidth = 25 - nanMargin.left - nanMargin.right; // Smaller width for NaN bar
      const nanHeight = 230 - nanMargin.top - nanMargin.bottom;

      const nanX = d3.scaleBand()
        .domain(["NaN"])
        .range([nanMargin.left, nanWidth - nanMargin.right])
        .padding(0.1);

      const nanY = d3.scaleLinear()
        .domain([0, d3.max([...bins, nanCount])])
        .range([nanHeight - nanMargin.bottom, nanMargin.top]);

      nanSvg.append("g")
        .attr("transform", `translate(0,${nanHeight - nanMargin.bottom})`)
        .call(d3.axisBottom(nanX))
        .attr("font-size", '10px')
        .attr("color", colors.secondary.main);

      nanSvg.append("rect")
        .attr("x", nanX("NaN"))
        .attr("y", nanY(nanCount))
        .attr("width", nanX.bandwidth())
        .attr("height", nanY(0) - nanY(nanCount))
        .attr("fill", colors.xpblue.main);
    }
  }, [bins, binWidth, binCount, min, max, nanCount, width]);

  return (
    <div style={{ display: 'flex' }}>
      <svg ref={svgRef} width={width} height={180}></svg>
      {hasNan && <svg ref={nanRef} width={25} height={180}></svg>}
    </div>
  );
};

Histogram.propTypes = {
  data: PropTypes.shape({
    summaryMetrics: PropTypes.objectOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['numeric', 'category']).isRequired,
        values: PropTypes.shape({
          bins: PropTypes.arrayOf(PropTypes.number),
          binWidth: PropTypes.number,
          binCount: PropTypes.number,
          min: PropTypes.number,
          mean: PropTypes.number,
          median: PropTypes.number,
          std: PropTypes.number,
          max: PropTypes.number,
          nanCount: PropTypes.number
        })
      })
    ).isRequired
  }).isRequired,
  column: PropTypes.string.isRequired
};

export default Histogram;
