import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

import "assets/css/tooltip.css";
import { useXplainableController } from "context";

const Chart = React.memo(({ data, chartType, collapsed, highlightedFeature, selection }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const ref = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  const updateYAxisLabels = (svg, y, data, x) => {
    const customYAxis = svg.select(".y-axis");

    const ticks = customYAxis.selectAll(".tick").data(y.domain());

    ticks
      .enter()
      .append("g")
      .attr("class", "tick")
      .merge(ticks)
      .attr("transform", (d) => `translate(${x(0)},${y(d) + y.bandwidth() / 2})`)
      .selectAll("text")
      .data((d) => [d])
      .enter()
      .append("text")
      .attr("x", (d) => {
        const barData = data.find((bar) => bar.feature === d);
        const loc = barData.score < 0 ? "start" : "end";
        return chartType === "waterfall" ? 6 : loc;
      })
      .attr("dy", ".35em")
      .attr("text-anchor", (d) => {
        const barData = data.find((bar) => bar.feature === d);
        const loc = barData.score < 0 ? "start" : "end";
        return chartType === "waterfall" ? "start" : loc;
      })
      .attr("font-size", "10px")
      .text((d) => d);

    ticks.exit().remove();
  };

  const updateBarColors = () => {
    const svg = d3.select(ref.current);
    const bars = svg.selectAll(".bar");

    bars.attr("fill", (d) => {
      if (d.feature === highlightedFeature) return "gray";
      if (d.feature === "base value") return "gray";
      if (d.feature === "Total") return "green";
      return d.score >= 0 ? colors.xpblue.main : colors.xppink.main;
    });
  };

  useEffect(() => {
    const getParentWidth = () => {
      if (ref.current && ref.current.parentElement) {
        setParentWidth(ref.current.parentElement.clientWidth);
      }
    };

    getParentWidth();
    window.addEventListener("resize", getParentWidth);

    return () => {
      window.removeEventListener("resize", getParentWidth);
    };
  }, []);

  useEffect(() => {
    if (!data || parentWidth === 0 || collapsed) return;

    const totalScore = data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.score;
    }, 0);

    if (!data.find((item) => item.feature === "Total")) {
      data.push({
        feature: "Total",
        value: null,
        score: totalScore,
        class: "total",
      });
    }

    const reversedData = [...data].reverse();
    const selectedData =
      selection[0] > 0
        ? reversedData.slice(Math.ceil(selection[1]), Math.ceil(selection[0]))
        : reversedData;
    const finalData = [...selectedData].reverse();

    const processedData = [...finalData];

    if (chartType === "waterfall") {
      processedData.forEach((d, i) => {
        if (i === 0) d.cumulative = 0;
        else d.cumulative = processedData[i - 1].cumulative + processedData[i - 1].score;
      });
    }

    const margin = { top: 20, right: 0, bottom: 10, left: 150 };
    const width = parentWidth - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    d3.select(ref.current).selectAll("*").remove();

    const svg = d3
      .select(ref.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top - 15})`);

    const x = d3
      .scaleLinear()
      .domain([
        d3.min(processedData, (d) => (chartType === "waterfall" ? d.cumulative : 0) + d.score),
        d3.max(processedData, (d) => (chartType === "waterfall" ? d.cumulative : 0) + d.score),
      ])
      .range([0, width]);

    const y = d3
      .scaleBand()
      .domain(processedData.map((d) => d.feature))
      .range([0, height])
      .padding(0.1);

    const xAxis = d3.axisBottom(x);

    // Make a copy of the data
    const bar = svg.selectAll(".bar").data(processedData).enter().append("g");

    bar
      .append("rect")
      .attr("class", "bar")
      .attr("y", (d) => y(d.feature))
      .attr("width", 0)
      .attr("height", y.bandwidth())
      .attr("fill", (d) => {
        if (d.feature === "Base value") return "gray";
        if (d.feature === "Total") return "green";
        if (d.feature === highlightedFeature) return "gray";
        return d.score >= 0 ? colors.xpblue.main : colors.xppink.main;
      })
      .attr("width", (d) => Math.abs(x(d.score) - x(0)))
      .attr("x", (d) => {
        if (chartType === "waterfall" && d.feature === "Total") {
          return x(Math.min(0, d.cumulative + d.score));
        }

        if (chartType === "waterfall") {
          return x(Math.min(d.cumulative, d.cumulative + d.score));
        }

        return x(Math.min(0, d.score));
      })
      .on("end", () => {
        updateYAxisLabels(svg, y, data, x);
      });

    bar.transition().duration(300);

    svg.append("g").call(xAxis).attr("class", "x-axis").attr("transform", `translate(0,${height})`);

    // Custom yAxis
    const customYAxis = svg.append("g").attr("class", "y-axis");

    customYAxis
      .append("line")
      .attr("x1", x(0))
      .attr("x2", x(0))
      .attr("y1", 0)
      .attr("y2", height)
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    updateYAxisLabels(svg, y, data, x);

    svg.selectAll("text").style("fill", darkMode ? colors.white.main : colors.black.main);
    svg.selectAll(".domain").style("stroke", darkMode ? colors.white.main : colors.black.main);
  }, [data, chartType, parentWidth, collapsed, highlightedFeature, selection]);

  useEffect(() => {
    // Call the updateBarColors function when highlightedFeature changes
    updateBarColors();
  }, [highlightedFeature]);

  return (
    <div>
      <svg ref={ref} />
    </div>
  );
});

export default Chart;

// Typechecking props for the SocialItem
Chart.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  chartType: PropTypes.string,
  collapsed: PropTypes.bool,
  highlightedFeature: PropTypes.string,
  selection: PropTypes.object,
};

// import React, { useEffect, useRef, useState } from "react";
// import * as d3 from "d3";
// import PropTypes  from "prop-types";
// import colors from "assets/theme/base/colors";

// const Chart = React.memo(({ data, chartType, collapsed, highlightedFeature }) => {
//   const ref = useRef(null);
//   const [parentWidth, setParentWidth] = useState(0);

//   console.log("The waterfall data is", data)

//   const updateYAxisLabels = (svg, y, data, x) => {
//     const customYAxis = svg.select(".y-axis");

//     const ticks = customYAxis.selectAll(".tick").data(y.domain());

//     ticks
//       .enter()
//       .append("g")
//       .attr("class", "tick")
//       .merge(ticks)
//       .attr("transform", (d) => `translate(${x(0)},${y(d) + y.bandwidth() / 2})`)
//       .selectAll("text")
//       .data((d) => [d])
//       .enter()
//       .append("text")
//       .attr("x", (d) => {
//         const barData = data.find((bar) => bar.feature === d);
//         return barData.score < 0 ? 6 : -6;
//       })
//       .attr("dy", ".35em")
//       .attr("text-anchor", (d) => {
//         const barData = data.find((bar) => bar.feature === d);
//         return barData.score < 0 ? "start" : "end";
//       })
//       .attr("font-size", "10px")
//       .text((d) => d);

//     ticks.exit().remove();
//   };

//   const updateBarColors = () => {
//     const svg = d3.select(ref.current);
//     const bars = svg.selectAll(".bar");

//     bars.attr("fill", (d) => {
//       if (d.feature === "base value") return "gray";
//       if (d.feature === "total") return "green";
//       if (d.feature === highlightedFeature) return "gray";
//       return d.score >= 0 ? colors.xpblue.main : colors.xppink.main;
//     });
//   };

//   useEffect(() => {
//     const getParentWidth = () => {
//       if (ref.current && ref.current.parentElement) {
//         setParentWidth(ref.current.parentElement.clientWidth);
//       }
//     };

//     getParentWidth();
//     window.addEventListener("resize", getParentWidth);

//     return () => {
//       window.removeEventListener("resize", getParentWidth);
//     };
//   }, []);

//   useEffect(() => {
//     if (!data || parentWidth === 0 || collapsed) return;

//     const margin = { top: 20, right: 0, bottom: 10, left: 150 };
//     const width = parentWidth - margin.left - margin.right;
//     const height = 400 - margin.top - margin.bottom;

//     d3.select(ref.current).selectAll("*").remove();

//     const svg = d3
//       .select(ref.current)
//       .attr("width", width + margin.left + margin.right)
//       .attr("height", height + margin.top + margin.bottom)
//       .append("g")
//       .attr("transform", `translate(${margin.left},${margin.top})`);

//       const x = d3
//       .scaleLinear()
//       .domain([
//         d3.min(data, (d) => (chartType === "waterfall" ? d.cumulative : 0) + d.score),
//         d3.max(data, (d) => (chartType === "waterfall" ? d.cumulative : 0) + d.score),
//       ])
//       .range([0, width]);

//       const y = d3
//       .scaleBand()
//       .domain(data.map((d) => d.feature))
//       .range([0, height])
//       .padding(0.1);

//     const xAxis = d3.axisTop(x);
//     // const yAxis = d3.axisLeft(y);

//     const getColor = (d) => {
//       if (d.feature === "base value") return "gray";
//       if (d.feature === "total") return "green";
//       if (d.feature === highlightedFeature) return "orange";
//       return d.score >= 0 ? colors.xpblue.main : colors.xppink.main;
//     };

//     // Make a copy of the data
//     const processedData = [...data];

//     if (chartType === "waterfall") {
//       processedData.forEach((d, i) => {
//         if (i === 0) d.cumulative = 0;
//         else d.cumulative = processedData[i - 1].cumulative + processedData[i - 1].value;
//       });
//     }

//     const bar = svg.selectAll(".bar").data(data).enter().append("g");

//     bar
//       .append("rect")
//       .attr("class", "bar")
//       .attr("x", (d) =>
//         chartType === "waterfall"
//           ? x(Math.min(d.cumulative, d.cumulative + d.score))
//           : x(Math.min(0, d.score))
//       )
//       .attr("y", (d) => y(d.feature))
//       .attr("width", 0)
//       .attr("height", y.bandwidth())
//       .attr("fill", (d) => {
//         if (d.feature === "base value") return "gray";
//         if (d.feature === "total") return "green";
//         if (d.feature === highlightedFeature) return "gray";
//         return d.score >= 0 ? colors.xpblue.main : colors.xppink.main;
//       })
//       .transition()
//       .duration(300)
//       .attr("width", (d) => Math.abs(x(d.score) - x(0)))
//       .attr("x", (d) =>
//         chartType === "waterfall"
//           ? x(Math.min(d.cumulative, d.cumulative + d.score))
//           : x(Math.min(0, d.score))
//       );

//       svg.append("g").call(xAxis).attr("class", "x-axis").attr("transform", `translate(0,${height})`);
//       // svg.append("g").call(yAxis).attr("class", "y-axis").attr("transform", `translate(${x(0)},0)`);

//       // Custom yAxis
//       const customYAxis = svg.append("g").attr("class", "y-axis");
//       customYAxis.append("line").attr("x1", x(0)).attr("x2", x(0)).attr("y1", 0).attr("y2", height).attr("stroke", "black").attr("stroke-width", 1);

//       updateYAxisLabels(svg, y, data, x);

//     }, [data, chartType, parentWidth, collapsed]);

//     useEffect(() => {
//       // Call the updateBarColors function when highlightedFeature changes
//       updateBarColors();
//     }, [highlightedFeature]);

//     return (
//       <div>
//         <svg ref={ref} />
//       </div>
//     );
//   });

//   export default Chart;

// // Typechecking props for the SocialItem
// Chart.propTypes = {
//     data: PropTypes.array,
//     width: PropTypes.number,
//     height: PropTypes.number,
//     chartType: PropTypes.string,
//     collapsed: PropTypes.bool,
//     highlightedFeature: PropTypes.string,
//   };

// import React, { useEffect, useRef, useState } from "react";
// import * as d3 from "d3";
// import PropTypes  from "prop-types";
// import colors from "assets/theme/base/colors";

// const Chart = React.memo(({ data, collapsed, highlightedFeature, renderChart }) => {
//   const ref = useRef(null);
//   const [parentWidth, setParentWidth] = useState(0);

// // console.log("the waterfall data is: ", data)

//   const updateYAxisLabels = (svg, y, data, x) => {
//     const customYAxis = svg.select(".y-axis");

//     const ticks = customYAxis.selectAll(".tick").data(y.domain());

//     ticks
//       .enter()
//       .append("g")
//       .attr("class", "tick")
//       .merge(ticks)
//       .attr("transform", (d) => `translate(${x(0)},${y(d) + y.bandwidth() / 2})`)
//       .selectAll("text")
//       .data((d) => [d])
//       .enter()
//       .append("text")
//       .attr("x", (d) => {
//         const barData = data.find((bar) => bar.feature === d);
//         return barData && barData.score < 0 ? 6 : -6;
//       })
//       .attr("text-anchor", (d) => {
//         const barData = data.find((bar) => bar.feature === d);
//         return barData && barData.score < 0 ? "start" : "end";
//       })
//       .attr("dy", ".35em")
//       .attr("font-size", "10px")
//       .text((d) => d);

//     ticks.exit().remove();
//   };

//   const updateBarColors = () => {
//     const svg = d3.select(ref.current);
//     const bars = svg.selectAll(".bar");

//     bars.attr("fill", (d) => {
//       if (d.feature === "Base value") return "gray";
//       if (d.feature === "total") return "green";
//       if (d.feature === highlightedFeature) return "gray";
//       return d.score >= 0 ? colors.xpblue.main : colors.xppink.main;
//     });
//   };

//   useEffect(() => {
//     const getParentWidth = () => {
//       if (ref.current && ref.current.parentElement) {
//         setParentWidth(ref.current.parentElement.clientWidth);
//       }
//     };

//     getParentWidth();
//     window.addEventListener("resize", getParentWidth);

//     return () => {
//       window.removeEventListener("resize", getParentWidth);
//     };
//   }, []);

//   useEffect(() => {
//     if (!data || parentWidth === 0 || collapsed) return;

//     const margin = { top: 20, right: 0, bottom: 10, left: 150 };
//     const width = parentWidth - margin.left - margin.right;
//     const height = 400 - margin.top - margin.bottom;

//     d3.select(ref.current).selectAll("*").remove();

//     const svg = d3
//       .select(ref.current)
//       .attr("width", width + margin.left + margin.right)
//       .attr("height", height + margin.top + margin.bottom)
//       .append("g")
//       .attr("transform", `translate(${margin.left},${margin.top})`);

//     const x = d3
//       .scaleLinear()
//       .domain([
//         d3.min(data, (d) => d.cumulative + d.score),
//         d3.max(data, (d) => d.cumulative + d.score),
//       ])
//       .range([0, width]);

//     // Sort the data so that "Base Value" is first and add a "Total" bar at the end.
//     const baseValue = data.find((d) => d.feature === "Base value");
//     const filteredWithoutBaseValue = data.filter((d) => d.feature !== "Base value");
//     const sortedData = [baseValue, ...filteredWithoutBaseValue.sort((a, b) => a.score - b.score)];

//     // Push total onto both sortedData and data arrays.
//     const total = { feature: "total", score: sortedData[sortedData.length - 1].cumulative };
//     sortedData.push(total);
//     data.push(total);

//     const y = d3
//       .scaleBand()
//       .domain(sortedData.map((d) => d.feature))
//       .range([0, height])
//       .padding(0.1);

//     const xAxis = d3.axisTop(x);

//     // Process the sorted data to calculate the cumulative scores
//     sortedData.forEach((d, i) => {
//       if (i === 0) {
//         d.cumulative = d.score;
//       } else {
//         d.cumulative = sortedData[i - 1].cumulative + d.score;
//       }
//     });

//       const bar = svg.selectAll(".bar").data(data).enter().append("g");

//       bar
//         .append("rect")
//         .attr("class", "bar")
//         .attr("x", (d) => x(Math.min(d.cumulative - d.score, d.cumulative)))
//         .attr("y", (d) => y(d.feature))
//         .attr("width", 0)
//         .attr("height", y.bandwidth())
//         .attr("fill", (d) => {
//           if (d.feature === "Base value") return "gray";
//           if (d.feature === "total") return "green";
//           if (d.feature === highlightedFeature) return "gray";
//           return d.score >= 0 ? colors.xpblue.main : colors.xppink.main;
//         })
//         .transition()
//         .duration(300)
//         .attr("width", (d) => Math.abs(x(d.score) - x(0)))
//         .attr("x", (d) => x(Math.min(d.cumulative - d.score, d.cumulative)));

//       svg.append("g").call(xAxis).attr("class", "x-axis").attr("transform", `translate(0,${height})`);

//       // Replace updateYAxisLabels with D3's built-in axis function.
//       const yAxis = d3.axisLeft(y);
//       svg.append("g").call(yAxis).attr("class", "y-axis");

//     // Custom yAxis
//     // const customYAxis = svg.append("g").attr("class", "y-axis");
//     // customYAxis.append("line").attr("x1", x(0)).attr("x2", x(0)).attr("y1", 0).attr("y2", height).attr("stroke", "black").attr("stroke-width", 1);

//     // updateYAxisLabels(svg, y, data, x);

//   }, [data,  parentWidth, collapsed, renderChart]);

//   useEffect(() => {
//     // Call the updateBarColors function when highlightedFeature changes
//     updateBarColors();
//   }, [highlightedFeature]);

//   return (
//     <div>
//       <svg ref={ref} />
//     </div>
//   );
// });

// export default Chart;

// // Typechecking props for the SocialItem
// Chart.propTypes = {
//   data: PropTypes.array,
//   chartType: PropTypes.string,
//   collapsed: PropTypes.bool,
//   highlightedFeature: PropTypes.string,
//   renderChart: PropTypes.func,
// };
