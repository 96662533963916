import axios from "axios";
import { Paths } from "settings";

// Set the API version
const APIVersion = "v1";

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_HOST_URL}/${APIVersion}`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add the access token to the axiosInstance
export const setAccessToken = (token) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

// Add the API key to the axiosInstance
export const setApiKeyConfig = (key) => {
  //   console.log("given api key: ", key);
  axiosInstance.defaults.headers.common["api_key"] = key;
};

// Add the axiosInstance interceptor to automatically log the user out if the API returns a 401
export const addAuthInterceptor = (logout) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        logout({
          logoutParams: { returnTo: `${window.location.origin}${Paths.login}` },

          onComplete: () => {
            console.log("Auth0 logout completed");
          },
        });
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
