import { useState, useEffect } from "react";

import { useModel } from "hooks";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { Card, Grid } from "@mui/material";

// xplainable dashboard example components
import DataHealth from "./components/health";
import ScrollReveal from "scrollreveal";
import PreprocessingPipeline from "shared/models/Pipeline";
// import LinkPreprocessor from "shared/models/LinkPreprocessor";
import LinkPreprocessor from "layouts/deployments/single-deployment/components/overview/components/PreprocessorCard";
import PreprocessorInfoCard from "shared/Cards/PreprocessorCard";

function Preprocessing() {
  const [preprocessorData, setPreprocessorData] = useState(null);
  const { model_id, selectedVersion, modelState, selectedPartition } = useModel();

  //Unpacking the Model State Values
  const { user, created, model_name, model_description } = modelState;

  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, []);

  useEffect(() => {
    if (preprocessorData) {
      ScrollReveal().reveal(".scrollreveal2", {
        delay: 0,
        distance: "100px",
        duration: 700,
        easing: "cubic-bezier(0.5, 0, 0, 1)",
        origin: "bottom",
        interval: 100,
      });
    }
  }, [preprocessorData]);

  return (
    <>
        <XBox className="scrollreveal">
          <Card sx={{ p: 4, mt: 2 }} className="scrollreveal">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <XTypography variant="h5" py={1} fontSize="18px">
                  Linked Preprocessor
                </XTypography>
                <XTypography variant="h6" color="secondary" fontWeight="light">
                Link a preprocessor to the model. This preprocessor will be used for all predict processes for models and deployments.
                </XTypography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <LinkPreprocessor modelVersion={selectedVersion.value} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <XBox>
                  <PreprocessorInfoCard
                    title={model_name}
                    version={selectedVersion.value}
                    author={user?.given_name + " " + user?.family_name}
                    imgUrl={user?.image}
                    description={model_description}
                    role={user?.position}
                    date={created}
                    showLink={false}
                  />
                </XBox>
              </Grid>
            </Grid>
          </Card>
        </XBox>
        {preprocessorData && (
          <XBox className="scrollreveal2" mt={2}>
            <Card sx={{ p: 2, position: "relative", zIndex: 0, overflow: "visible" }}>
              <PreprocessingPipeline preprocessorData={preprocessorData} />
            </Card>
          </XBox>
        )}
        <XBox className="scrollreveal" mt={2}>
          <Card sx={{ p: 2, position: "relative", zIndex: 1, overflow: "visible" }}>
            <DataHealth />
          </Card>
        </XBox>
    </>
  );
}

export default Preprocessing;
