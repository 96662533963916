import React from 'react'
import "./style.css";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import { Icon, Tooltip } from '@mui/material';
import SuccessChecklistIcon from  "assets/images/icons/deployments/success-checklist-icon.svg";

const PricingTable = () => {
  return (
    <table
    className="custom-table"
    style={{ border: "1px solid #EAEAEA", borderRadius: "16px", padding: "16px" }}
  >
    <thead>
      <tr>
        <th></th>
        <th>
          <XBox
            display="flex"
            flexDirection="column"
            gap="24px"
            textAlign="start"
            height="100%"
          >
            <XBox display="flex" flexDirection="column">
              <XTypography variant="button" color="secondary" fontSize="32px">
                Free
              </XTypography>
              <XTypography variant="button" color="secondary" fontSize="12px">
                Best for personal use
              </XTypography>
            </XBox>
            <XBox display="flex" alignItems="flex-end" height="100px">
              <XTypography variant="button" color="secondary" sx={{ fontSize: "60px" }}>$0</XTypography>
              <XTypography variant="button" fontWeight="light" color="secondary" sx={{ marginBottom: '0.1em' }}>
                / user/ month
              </XTypography>
            </XBox>
            <XBox display="flex" flexDirection="column" alignItems="center" marginTop="auto">
              <XTypography color="secondary" frontWeight="bold" sx={{ fontSize: "16px" }}>
                Default
              </XTypography>
            </XBox>
          </XBox>
        </th>
        <th>
          <XBox
            display="flex"
            flexDirection="column"
            gap="24px"
            textAlign="start"
            height="100%"
          >
            <XBox display="flex" flexDirection="column">
              <XTypography variant="button" sx={{ color: "#DE4188" }} fontSize="32px">
                Premium
              </XTypography>
              <XTypography variant="button" color="secondary" fontSize="12px">
                Best for growing teams. 
              </XTypography>
            </XBox>
            <XBox display="flex" alignItems="flex-end" height="100px">
              <XTypography variant="button" sx={{ color: "#DE4188", fontSize: "60px" }}>$79</XTypography>
              <XTypography variant="button" fontWeight="light" color="secondary" sx={{ marginBottom: '0.1em' }}>
                / user/ month
              </XTypography>
            </XBox>
            <XBox display="flex" flexDirection="column" marginTop="auto">
              {/* <XButton variant="gradient" color="button">
                Contact us
              </XButton> */}
              <XBox height={"40px"} />
            </XBox>
          </XBox>
        </th>
        <th>
          <XBox  
            display="flex"
            flexDirection="column"
            gap="24px"
            textAlign="start"
            height="100%"
          >
            <XBox display="flex" flexDirection="column">
              <XTypography
                variant="button"
                sx={{
                  backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                fontSize="32px"
              >
                Enterprise
              </XTypography>
              <XTypography variant="button" color="secondary" fontSize="12px">
                Best for Business.
              </XTypography>
            </XBox>
            <XBox display="flex" flexDirection="column" height="100px">
              <XTypography variant="button" color="secondary" fontWeight="light">
              Our Enterprise package provides extensive support for customised deployments, on-premises hosting 
              alternatives, dedicated infrastructure, and specialised services.
              </XTypography>
            </XBox>
            <XBox display="flex" flexDirection="column" marginTop="auto">
            <XButton
                variant="gradient"
                color="button"
                onClick={() => window.open('https://www.xplainable.io/contact-us', '_blank')}
                >
                Contact us
                </XButton>
            </XBox>
          </XBox>
        </th>
      </tr>
    </thead>
    <tbody>
    <tr>
        <td>
        <XBox display="flex" justifyContent="flex-end" >
            <XTypography variant="h5">Model Collaboration</XTypography>
            <XBox ml={1}>
              <Tooltip title={"Understand and explain your models' decisions easily, ensuring clarity and trust in model creation."} placement="top">
                <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                  <Icon>question_mark</Icon>
                </XButton>
              </Tooltip>
            </XBox>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XBox component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XBox component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XBox component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
      </tr>
      <tr>
        <td>
        <XBox display="flex" justifyContent="right">
          <XTypography variant="h5">Model Creation</XTypography>
          <XBox ml={1}>
            <Tooltip title={"Use the API to create machine learning models and save them to Xplainable Cloud"} placement="top">
              <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                <Icon>question_mark</Icon>
              </XButton>
            </Tooltip>
          </XBox>
        </XBox>
        </td>
        <td>
          <XBox display="flex" alignItems="flex-end" justifyContent="center">
            <XTypography  sx={{ fontSize: "24px" }} fontWeight="bold">1{" "}</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">10</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XBox component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
      </tr>
      <tr>
        <td>
        <XBox display="flex" justifyContent="right">
          <XTypography variant="h5">AI Report Creation</XTypography>
          <XBox ml={1}>
            <Tooltip title={"Automate model commentary using Artificial Intelligence"} placement="top">
              <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                <Icon>question_mark</Icon>
              </XButton>
            </Tooltip>
          </XBox>
        </XBox>
        </td>
        <td>
          <XBox display="flex" alignItems="flex-end" justifyContent="center">
            <XTypography  sx={{ fontSize: "24px" }} fontWeight="bold">1{" "}</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">30</XTypography>
            <XTypography sx={{ fontSize: "14px" }} color="secondary">/month</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XBox component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
      </tr>
      <tr>
        <td>
        <XBox display="flex" justifyContent="right">
          <XTypography variant="h5">Collections</XTypography>
          <XBox ml={1}>
            <Tooltip title={"Upload datasets to create batches and save them for later viewing"} placement="top">
              <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                <Icon>question_mark</Icon>
              </XButton>
            </Tooltip>
          </XBox>
        </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">1</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">5</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XBox component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
      </tr>
      <tr>
        <td>
        <XBox display="flex" justifyContent="right">
          <XBox display="flex" alignItems="flex-end">
            <XTypography variant="h5">Deployments</XTypography>
            <XTypography sx={{ fontSize: "14px" }} color="secondary">(shared)</XTypography>
          </XBox>
          <XBox ml={1}>
            <Tooltip title={"Easily embed machine learning into external applications either through API or the web interface"} placement="top">
              <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                <Icon>question_mark</Icon>
              </XButton>
            </Tooltip>
          </XBox>
        </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">1{" "}</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">2{" "}</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="center">
            <XBox display="flex" justifyContent="center" alignItems="center" component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
      </tr>
      
      <tr>
        <td>
          <XBox display="flex" justifyContent="right">
            <XTypography variant="h5">Shareable Reports</XTypography>
            <XBox ml={1}>
              <Tooltip title={"Create Drag & Drop Reports to share with business stakeholders."} placement="top">
                <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                  <Icon>question_mark</Icon>
                </XButton>
              </Tooltip>
            </XBox>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">-</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">3</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XBox component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
      </tr>
      <tr>
        <td>
        <XBox display="flex" justifyContent="right">
          <XTypography variant="h5">Batch Optimisation</XTypography>
          <XBox ml={1}>
            <Tooltip title={"Enhance efficiency by choosing the best set of actions, using a blend of prediction and performance scoring"} placement="top">
              <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                <Icon>question_mark</Icon>
              </XButton>
            </Tooltip>
          </XBox>
        </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">-</XTypography>
          </XBox>
        </td>
        <td>
        <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">-</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XBox component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
      </tr>
      <tr>
        <td>
        <XBox display="flex" justifyContent="flex-end">
          <XTypography variant="h5">Champion/Challenger Framework</XTypography>
          <XBox ml={1}>
            <Tooltip title={"Test multiple ML options simultaneously, learning and adapting based on real-time feedback."} placement="top">
              <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                <Icon>question_mark</Icon>
              </XButton>
            </Tooltip>
          </XBox>
        </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">-</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XTypography sx={{ fontSize: "24px" }} fontWeight="bold">-</XTypography>
          </XBox>
        </td>
        <td>
          <XBox display="flex" justifyContent="center" alignItems="flex-end">
            <XBox component="img" src={SuccessChecklistIcon} />
          </XBox>
        </td>
      </tr>
     
    </tbody>
  </table>
  )
}

export default PricingTable