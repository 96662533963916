import React, { useRef, useEffect, useState, useContext } from "react";
import { select, scaleLinear, max, min, brushY, axisLeft } from "d3";
import PropTypes from "prop-types";
import XTypography from "components/XTypography";
import XBox from "components/XBox";

import { event as d3event } from "d3-selection";
import { usePrevious } from "../../shared";
import { useModel } from "hooks";

import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

/**
 * Component that renders a BrushChart
 */

function BrushChart({ data, children }) {
  const { collapsedWidth } = useModel;
  const svgRef = useRef();
  const svgContainer = useRef(null); // The PARENT of the SVG
  const [selection, setSelection] = useState(
    data.length > 30 ? [data.length, data.length - 30] : [data.length, 0]
  );

  const previousSelection = usePrevious(selection);

  // State to track width and height of SVG Container
  const [containerWidth, setContainerWidth] = useState(0);
  const [height, setHeight] = useState(490);
  const [width, setWidth] = useState(40);

  const padding = 0.5; // adjust this to increase or decrease the padding

  // This function calculates width and height of the container
  //TODO: pass the height from parent component ref
  const getSvgContainerSize = () => {
    const newWidth = svgContainer.current.clientWidth;
    setContainerWidth(newWidth);
    setWidth(width);
  };

  useEffect(() => {
    // detect 'width' and 'height' on render
    getSvgContainerSize();
    // listen for resize changes, and detect dimensions again when they change
    window.addEventListener("resize", getSvgContainerSize);
    // cleanup event listener
    return () => window.removeEventListener("resize", getSvgContainerSize);
  }, []);

  // will be called initially and on every data change
  useEffect(() => {
    const svg = select(svgRef.current);

    // scales
    const yScale = scaleLinear()
      .domain([0, data.length - 1])
      .range([height, 30]);

    const xScale = scaleLinear()
      .domain([min(data.map((d) => d.value)), max(data.map((d) => d.value))])
      .range([20, width]);

    // render the bars
    svg
      .selectAll(".brush-bar")
      .data(data)
      .join("rect")
      .attr("class", "brush-bar")
      .attr("fill", (d, index) =>
        selection && index >= selection[1].toFixed(0) && index <= selection[0].toFixed(0)
          ? rgba(colors.xpblue.main, 0.4)
          : rgba(colors.secondary.main, 0.4)
      )
      .attr("y", (d, index) => yScale(index) - 15 || 0)
      .attr("x", 5)
      .attr("height", height / data.length - 5 * padding) // subtract twice the padding from the height
      .attr("width", (d) => xScale(d.value) - 10 || 0)
      .attr("rx", 5) // Add this line for x-axis corner radius
      .attr("ry", 5); // Add this line for y-axis corner radius

    const yAxis = axisLeft(yScale).tickSize(0);

    svg.select(".y-axis").call(yAxis).select(".domain").attr("stroke", "none"); // hides the domain line;

    const brush = brushY()
      .extent([
        [0, 0],
        [width, height],
      ])
      .on("start brush end", function () {
        const event = d3event;
        if (event.selection) {
          const indexSelection = event.selection.map(yScale.invert);
          setSelection(indexSelection);
        } else {
          setSelection(null);
        }
      });

    // initial position + retaining position on resize
    // Code to apply styles to the brush
    svg
      .select(".brush-3")
      .call(brush)
      .selectAll(".selection")
      .style("stroke", rgba(colors.secondary.main, 0.4)) // Change the stroke color of the selection
      .style("fill", colors.light.main) // Change the fill color of the selection
      .style("fill-opacity", 0.2); // Change the fill opacity of the selection

    svg
      .select(".brush-3")
      .call(brush)
      .selectAll(".handle")
      .style("fill", rgba(colors.secondary.main, 0.4)) // Change the fill color of the handles
      .style("stroke", rgba(colors.secondary.main, 0.4)) // Change the stroke color of the handles
      .style("stroke-width", "1.5px"); // Change the stroke width of the handles

    // initial position + retaining position on resize
    if (previousSelection === selection) {
      if (selection) {
        svg.select(".brush-3").call(brush).call(brush.move, selection.map(yScale));
      }
    }
  }, [data, previousSelection, selection, width, height]);

  return (
    <>
      <XBox width={containerWidth} display="flex" alignItems="center" mb={8}>
        <XBox ml={2} lineHeight={1}>
          <XTypography variant="button" fontWeight="light" textTransform="capitalize" color="text">
            {"Model Feature Importances"}
          </XTypography>
          <XTypography variant="h5" fontWeight="bold" whiteSpace="nowrap">
            {`${(selection?.[0] - selection?.[1]).toFixed(0)}/${data?.length}  features selected`}
          </XTypography>
        </XBox>
      </XBox>

      <XBox display="flex" ref={svgContainer} mt={3}>
        <XBox width={width} flexGrow={1}>
          {children(selection, svgContainer)}
        </XBox>
        <XBox ml={1} width={width}>
          <svg ref={svgRef} height={height}>
            <g className="brush-3" />
            <g className="x-axis" />
            <g className="y-axis" />
          </svg>
        </XBox>
      </XBox>
    </>
  );
}

export default BrushChart;

BrushChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  children: PropTypes.func.isRequired,
};
