import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useUserMutation = () => {
  const { mutate: patchUserNotificationMutation } = useMutation((params) =>
    apiHandlers.userManagement.patchUserNotification(...params)
  );
  const { mutate: createUserMutation } = useMutation((params) =>
    apiHandlers.userManagement.createUser(...params)
  );

  return {
    patchUserNotificationMutation,
    createUserMutation,
  };
};
