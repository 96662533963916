// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import colors from "assets/theme/base/colors";

// Images
import upgradeImage from "assets/images/upgrade-card.png";
import GradientLinkTypography from "components/GradientTypography";

function UpgradeCard() {
  return (
    <Card
      sx={{
        background: `url(${upgradeImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "16px",
        padding: "24px",
        height:"350px",
        color: "#333",
      }}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
            <XBox display="flex" alignItems="center">
            <XTypography variant="h6" fontSize="16px" fontWeight="bold" sx={{ mr: 1 }}>
                Upgrade
            </XTypography>
            <XTypography variant="h5" fontWeight="bold" color="xppink" sx={{ mr: 1 }}>
                Software + Data Scientist
            </XTypography>
            <span>🎉</span>
            </XBox>
            <XTypography color="secondary" fontSize="16px" fontWeight="light">
            to enjoy more features
            </XTypography>
        </Grid>
        <Grid item>
          <XBox>
            <Grid container spacing={1} direction="column">
              {[
                "Dedicated Data Scientist Seat",
                "40 hours/month Data Science Support",
                "Single Request at a Time",
                "Research Reports",
                "Python Code Files",
                "Analytics Support (Power BI, Alteryx)",
              ].map((feature, index) => (
                <Grid item key={index}>
                  <Grid container alignItems="center">
                    <CheckCircleIcon sx={{ color: colors.xppink.main, mr: 1 }} />
                    <XTypography fontSize="14px" fontWeight="light">{feature}</XTypography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </XBox>
        </Grid>
        <Grid item>
          <XBox >
          <GradientLinkTypography href="https://www.xplainable.io/contact-us">
                Learn more
          </GradientLinkTypography>
          </XBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default UpgradeCard;
