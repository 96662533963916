// src/layouts/autotrain/components/ChatAutoTrain/components/PreprocessorInfoCard.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Tooltip, Icon } from "@mui/material";
import XInput from "components/XInput";
import { MdContentCopy, MdCheck } from "react-icons/md";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XAvatar from "components/XAvatar";
import { XImg } from "components/XImg";
import { ReactComponent as DarkXCloseIcon } from "assets/images/dark-x-close-icon.svg";
import WarningIcon from "assets/images/warning-icon.svg";
import colors from "assets/theme/base/colors";
import { useToast } from "hooks"; // Assuming you have a useToast hook for notifications
import ConfirmationModal from "shared/Modals/ConfirmationModal";

// Utility function to calculate time difference
import { getTimeDifference } from "shared/Functions/Date";

function PreprocessorInfoCard({
  title,
  version,
  author,
  date,
  imgUrl,
  role,
  description,
  setAddingPreprocessor,
  onUnlinkPreprocessor, 
  showLink=true
}) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onUnlinkPreprocessor}
        title="Are you sure you want to unlink the preprocessor associated with this deployment? This could cause unexpected issues in production systems."
        textToConfirm="unlink-preprocessor"
        confirmButtonText="Unlink Preprocessor"
        warningIcon={WarningIcon}
        darkMode={false} // Adjust based on your theme context
        colors={colors}
      />

      {/* Preprocessor Info Card */}
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid #EAEAEA",
          p: 2,
          height: "280px",
          position: "relative",
        }}
      >
        {/* X Button to Open Delete Modal */}
        {showLink && 
         <XBox
          sx={{
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
          }}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <XImg>
            <DarkXCloseIcon />
          </XImg>
        </XBox>
        }
       
        {/* Card Content */}
        <Grid container>
          <Grid item>
            <XBox mb={1} display="flex" flexDirection="column" gap={1}>
              <Tooltip title={title.length > 30 ? title : ""}>
                <XTypography variant="button" fontSize="18px" fontWeight="medium">
                  {title.length > 30 ? `${title.slice(0, 30)}...` : title}
                </XTypography>
              </Tooltip>
              <XTypography variant="h5" fontWeight="bold" fontSize="14px" color="xpblue">
                {`Version ${version}`}
              </XTypography>
            </XBox>
          </Grid>
        </Grid>

        <Grid>
          <XBox
            display="flex"
            sx={{
              overflow: "hidden",
              overflowY: "scroll",
              minHeight: "80px",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
            }}
          >
            <XTypography variant="button" fontWeight="light" color="secondary">
              {description}
            </XTypography>
          </XBox>
        </Grid>

        <Grid>
          <XBox display="flex" alignItems="center">
            <XBox display="flex" textAlign="left" gap={1} alignItems="center">
              <XAvatar variant="rounded" size="xs" src={imgUrl} alt={`${author}'s avatar`} />
              <XBox display="flex" flexDirection="column">
                <XTypography variant="button" fontWeight="light">
                  {author}
                </XTypography>
                <XTypography variant="caption" sx={{ color: "#AFAFAF" }} fontWeight="medium">
                  {role}
                </XTypography>
              </XBox>
            </XBox>
          </XBox>
        </Grid>

        <Grid display="flex" justifyContent="end">
          <XTypography variant="caption" color="secondary" lineHeight={1.4}>
            {date ? getTimeDifference(date, true) : ""}
          </XTypography>
        </Grid>

        <Grid item spacing={1}>
          {showLink &&
          <XBox display="flex" justifyContent="end" gap={2} mt={2}>
            <XButton
              color="button"
              variant="gradient"
              onClick={() => setAddingPreprocessor(true)}
            >
              Relink
            </XButton>
          </XBox>
          }
        </Grid>
      </Card>
    </>
  );
}

// Setting default values for the props of PreprocessorInfoCard
PreprocessorInfoCard.defaultProps = {
  title: "Unknown Preprocessor",
  version: "1.0",
  author: "Unknown Author",
  date: "",
  description: "No description provided.",
  imgUrl: "",
  role: "User",
  setAddingPreprocessor: () => {},
  onUnlinkPreprocessor: () => {},
};

// Typechecking props for the PreprocessorInfoCard
PreprocessorInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  author: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  imgUrl: PropTypes.string,
  role: PropTypes.string,
  setAddingPreprocessor: PropTypes.func,
  onUnlinkPreprocessor: PropTypes.func, 
  showLink: PropTypes.bool
};

export default PreprocessorInfoCard;
