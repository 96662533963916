import React, { useState, useRef, useEffect, useContext } from "react";
import ModelContext from "context/ModelContext";
import * as d3 from "d3";

import PropTypes from "prop-types";
import { waterfallChartWidth } from "utils/countWaterfallChartWidth";
import XBox from "components/XBox";
import { debounce } from "lodash";
import colors from "assets/theme/base/colors";
import { useXplainableController } from "context";

function RelativeChart({
  featureData,
  margin,
  selectedOption,
  isCollapsed,
  percent,
  sort,
  isSorted,
  selection,
  collapsedWidth: propCollapsedWidth,
}) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const contextValue = useContext(ModelContext);
  const collapsedWidth = propCollapsedWidth || contextValue?.collapsedWidth;
  // Element References
  const svgRef = useRef(null);
  const svgContainer = useRef(null); // The PARENT of the SVG

  // State to track width and height of SVG Container
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(600);

  //Set the state of the tooltip
  const [tooltip, setTooltip] = useState({
    top: 0,
    left: 0,
    name: "",
    value: "",
    display: false,
    feature: selectedOption,
  });
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const t = d3.transition().duration(0);

  // const getSvgContainerSize = () => {
  //   const newWidth = svgContainer.current.clientWidth - margin.left - margin.right;
  //   setWidth(newWidth);
  // };

  // useEffect(() => {
  //   // detect 'width' and 'height' on render
  //   getSvgContainerSize();

  //   // Create a debounced version of getSvgContainerSize
  //   const debouncedGetSvgContainerSize = debounce(() => {
  //     getSvgContainerSize();
  //   }, 100); // Debounce for 250ms

  //   // listen for resize changes
  //   window.addEventListener("resize", debouncedGetSvgContainerSize);

  //   // cleanup event listener on component unmount
  //   return () => {
  //     debouncedGetSvgContainerSize.cancel(); // lodash's debounce provides a cancel method to cancel delayed invocations
  //     window.removeEventListener("resize", debouncedGetSvgContainerSize);
  //   };
  // }, [collapsedWidth, isCollapsed]);

  useEffect(() => {
    const container = svgContainer.current;

    const handleResize = (entries) => {
      for (let entry of entries) {
        const newWidth = entry.contentRect.width;
        setWidth(newWidth - 50);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Observe the container element
    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      // Disconnect the observer when the component unmounts
      resizeObserver.disconnect();
    };
  }, [svgContainer]);

  const handleMouseMove = (event) => {
    setCoords({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };

  useEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)

      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const defs = svg.append("defs");

    const gradientPositive = defs
      .append("linearGradient")
      .attr("id", "gradientPositive")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "100%")
      .attr("y2", "100%");

    gradientPositive
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", colors.gradients.xpblue.state);

    gradientPositive
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", colors.gradients.xpblue.main);

    const gradientNegative = defs
      .append("linearGradient")
      .attr("id", "gradientNegative")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "100%")
      .attr("y2", "100%");

    gradientNegative
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", colors.gradients.xppink.main);

    gradientNegative
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", colors.gradients.xppink.state);

    const gradientSelect = defs
      .append("linearGradient")
      .attr("id", "gradientSelect")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "100%")
      .attr("y2", "100%");

    gradientSelect.append("stop").attr("offset", "0%").attr("stop-color", "#9b9aab");

    gradientSelect.append("stop").attr("offset", "100%").attr("stop-color", "#7d7c87");

    const gradientTotal = defs
      .append("linearGradient")
      .attr("id", "gradientTotal")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "100%")
      .attr("y2", "100%");

    gradientTotal
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", colors.gradients.xpgreen.main);

    gradientTotal
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", colors.gradients.xpgreen.state);

    const x_scale = d3.scaleLinear().range([width, 0]);

    const y_scale = d3.scaleBand().rangeRound([0, height]).padding(0.1);

    const y_axis = d3.axisLeft(y_scale);
    const x_axis = d3.axisBottom(x_scale);

    const sortedData =
      isSorted && featureData
        ? Object.values(featureData).sort((a, b) => a.value - b.value)
        : Object.values(featureData);

    const data =
      featureData && selection[0] > 0
        ? sortedData.reverse().slice(Math.ceil(selection[1]), Math.ceil(selection[0])).reverse()
        : sortedData;

    const features = data.map((item) => item.keyName);

    let y0;

    //Sort the bar chart depending on the sort state value
    if (sort) {
      y0 = y_scale
        .domain(
          data
            .sort(
              true
                ? function (a, b) {
                    return b.value - a.value;
                  }
                : function (a, b) {
                    return d3.ascending(a.value, b.value);
                  }
            )
            .map(function (d) {
              return d.name;
            })
        )
        .copy();
    } else {
      y0 = y_scale.domain(features);
    }

    var max_value = d3.max(data, function (d) {
      return +d.value;
    });

    var min_value = d3.min(data, function (d) {
      return +d.value;
    });

    //TODO: update this logic to be dynamic with the naming convention
    x_scale.domain([
      max_value + (max_value - min_value) / 4,
      min_value - (max_value - min_value) / 6,
    ]);

    var s_bars = svg
      .selectAll(".bar")
      .data(data)
      .join("rect")
      .attr("y", function (d) {
        return y0(d.keyName);
      })
      .attr("height", y_scale.bandwidth())
      .transition(t)
      .attr("class", function (d) {
        return "bar " + (d.checked ? "checked" : d.value < 0 ? "negative" : "positive");
      })
      .attr("fill", function (d) {
        return d.checked ? "#9ca3af" : d.value > 0 ? "#0080EA" : "#E14067";
      })
      .attr("width", function (d) {
        return Math.abs(x_scale(d.value) - x_scale(0));
      })
      .attr("x", function (d) {
        return x_scale(Math.min(0, d.value));
      })
      .attr("opacity", 0.6);

    //Call x axis update
    svg.select(".x-axis").transition(t).call(x_axis);

    svg
      .select(".y-axis")
      .transition(t)
      .attr("transform", function (d) {
        return "translate(" + x_scale(0) + ", 0)";
      })
      .call(y_axis);

    svg.selectAll("text").style("fill", darkMode ? colors.white.main : colors.black.main);
    svg.selectAll(".domain").style("stroke", darkMode ? colors.white.main : colors.black.main);
  }, [featureData, sort, width, height, isSorted, selection, isCollapsed]);

  return (
    <>
      <XBox ref={svgContainer} onMouseMove={handleMouseMove} display="flex" width="100%">
        <svg ref={svgRef}>
          <g className="x-axis" transform={`translate(0, ${height})`} />
          <g className="y-axis" />
        </svg>
        <FeatureToolTip
          //    feature = { feature }
          left={tooltip.left}
          top={tooltip.top}
          name={tooltip.name}
          value={tooltip.value}
          display={tooltip.display}
          percent={percent}
        />
      </XBox>
    </>
  );
}

export default RelativeChart;

const FeatureToolTip = (props) => (
  <div
    style={{
      position: "absolute",
      visibility: props.display ? "visible" : "hidden",
      top: `${props.top}px`,
      left: `${props.left}px`,
      zIndex: `20`,
    }}
    className="tooltip flex  mb-2 justify-between"
  >
    <div
      className={
        (props.value < 0 ? "border-xppink" : "border-xpblue") +
        " justify-between  border-2 cursor-pointer bg-white dark:bg-gray-500 rounded-md flex flex-1 items-center md:p-2 p-1 md:text-base text-sm hover:-translate-y-1 hover:shadow-lg"
      }
    >
      <div className="flex flex-col">
        <div className="text-sm text-gray-500">{props.feature}</div>
        <div>{props.name}</div>
      </div>
      <div
        className={
          (props.value < 0 ? "bg-xppink " : "bg-xpblue ") +
          "ml-4 p-2 md:p-3 rounded-xl text-white text-sm"
        }
      >
        {props.percent === true
          ? (parseFloat(props.value).toFixed(2) * 100).toString() + "%"
          : parseFloat(props.value).toFixed(2)}
      </div>
    </div>
  </div>
);

RelativeChart.propTypes = {
  featureData: PropTypes.any,
  margin: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
  }),
  selectedOption: PropTypes.string,
  plotTitle: PropTypes.string,
  sort: PropTypes.bool,
  percent: PropTypes.bool,
  baseValue: PropTypes.number,
  percent: PropTypes.bool,
  onWidthChange: PropTypes.bool,
  isSorted: PropTypes.bool,
  selection: PropTypes.array,
  isCollapsed: PropTypes.bool,
  collapsedWidth: PropTypes.bool,
};

//Proptypes for the Feature tooltip
FeatureToolTip.propTypes = {
  display: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  feature: PropTypes.string,
  name: PropTypes.string.isRequired,
  percent: PropTypes.bool,
};
