import React from 'react';
import { CodeBlock, dracula } from 'react-code-blocks';
import PropTypes from 'prop-types';
import XBox from 'components/XBox';
import { useXplainableController } from 'context';

//Update the style of the code block
import "./styles.css";

function SimulatedNotebook({ cells }) {
    const [controller] = useXplainableController();
    const { darkMode } = controller;
    
  // Function to render code cells based on the JSON object
  const renderCells = () => {
    return Object.entries(cells).map(([cellId, lines], index) => {
      const codeString = lines.join('\n'); // Join lines to form the code string
      return (
        <XBox key={cellId} width={"100%"} style={{ marginBottom: '20px', fontSize: '14px' }}>
          <CodeBlock
            text={codeString}
            language={"python"}
            showLineNumbers={true}
            theme={darkMode ? dracula :"light"}
            wrapLines={false}
            codeBlock
          />
        </XBox>
      );
    });
  };

  return (
    <div style={{ }}>
      {renderCells()}
    </div>
  );
}

export default SimulatedNotebook;

SimulatedNotebook.propTypes = {
    cells: PropTypes.object.isRequired,
};