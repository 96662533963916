import { toast } from "react-hot-toast";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

import SuccessChecklistIcon from "assets/images/icons/deployments/success-checklist-icon.svg";
import ErrorChecklistIcon from "assets/images/icons/deployments/error-checklist-icon.svg";

export const useToast = () => {
  const showSuccessToast = (message, duration = 5000) => {
    return toast.custom(
      (t) => (
        <>
          <style>
            {`
            @keyframes scaleIn {
              from {
                transform: scale(0.8);
                opacity: 0;
              }
              to {
                transform: scale(1);
                opacity: 1;
              }
            }

            .scale-in-animation {
              animation: scaleIn 0.3s ease-out forwards;
            }
          `}
          </style>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#cffaec",
              color: colors.success.main,
              border: `1px solid ${colors.success.main}`,
              fontSize: "14px",
              padding: "10px",
              borderRadius: "8px",
              transform: "scale(0.8)",
              opacity: 0, 
            }}
            className={`${t.visible ? "scale-in-animation" : ""}`}
          >
            <img src={SuccessChecklistIcon} alt="Success" style={{ height:"24px", width:"24px", marginRight: "8px" }} />
            {message}
          </div>
        </>
      ),
      {
        duration,
      }
    );
  };

  const showErrorToast = (message, duration = 5000) => {
    return toast.custom(
      (t) => (
        <>
          <style>
            {`
            @keyframes scaleIn {
              from {
                transform: scale(0.8);
                opacity: 0;
              }
              to {
                transform: scale(1);
                opacity: 1;
              }
            }

            .scale-in-animation {
              animation: scaleIn 0.3s ease-out forwards;
            }
          `}
          </style>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fcd7de",
              color: colors.error.main,
              border: `1px solid ${colors.error.main}`,
              fontSize: "14px",
              padding: "10px",
              maxWidth: "50%",
              borderRadius: "8px",
              transform: "scale(0.8)", 
              opacity: 0,
            }}
            className={`${t.visible ? "scale-in-animation" : ""}`}
          >
            <img src={ErrorChecklistIcon} alt="Error" style={{ height:"24px", width:"24px", marginRight: "8px" }} />
            {message}
          </div>
        </>
      ),
      {
        duration,
      }
    );
  };

  return {
    showSuccessToast,
    showErrorToast,
  };
};
