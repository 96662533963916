import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

//Get the state values
import { useApiKey } from "components/Authorisation/ApiKeyContext";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import { Icon, Menu, Modal } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// xplainable dashboard example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavFooter from "examples/Sidenav/SidenavFooter";
import SideNavMenu from "examples/Sidenav/SidenavMenu";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";

// xplainable dashboard context
import { useXplainableController, setMiniSidenav } from "context";

import colors from "assets/theme/base/colors";
import XInput from "components/XInput";
import XButton from "components/XButton";
import { useRequestMutation } from "api/mutations";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { user, viewState } = useApiKey();
  const { sendRequestMutation } = useRequestMutation();

  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputs, setInputs] = useState({
    message: "",
  });

  const [controller, dispatch] = useXplainableController();
  const { miniSidenav, darkSidenav, layout } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];

  const filteredRoutes = routes.filter((route) => {
    if (viewState === "viewer") {
      return route.creator !== true;
    }

    return route;
  });

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link key={key} href={href} target="_blank" rel="noreferrer">
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link href={href} key={key} target="_blank" rel="noreferrer">
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOnChange = (event) => {
    event.persist();
    setInputs((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      ...inputs,
      user: user.given_name + " " + user.family_name,
      username: user.username,
      email: user.email,
      page: window.location.href,
    };

    sendRequestMutation([JSON.stringify(payload)], {
      onSuccess: () => {
        console.log("Success");
        setInputs({
          message: "",
        });
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = filteredRoutes.map(
    ({
      type,
      name,
      activeIcon,
      unActiveIcon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      isBeta = false,
    }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link href={href} key={key} target="_blank" rel="noreferrer">
              <SidenavCollapse
                name={name}
                active={key === collapseName}
                noCollapse={noCollapse}
                activeIcon={activeIcon}
                unActiveIcon={unActiveIcon}
              />{" "}
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                activeIcon={activeIcon}
                unActiveIcon={unActiveIcon}
                noCollapse={noCollapse}
                active={key === collapseName || (!collapseName && key === "home")}
                isBeta={isBeta}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              activeIcon={activeIcon}
              unActiveIcon={unActiveIcon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <XTypography
            key={key}
            color={darkSidenav ? "white" : "#0D0C0C"}
            display="block"
            fontSize="16px"
            variant="caption"
            fontWeight="bold"
            mt="24px"
            mb={1}
            ml={miniSidenav ? 0 : 2}
            textAlign={miniSidenav ? "center" : "start"}
          >
            {title}
          </XTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} light={darkSidenav} />;
      } else if (type === "space") {
        returnValue = <XBox h="16px" key={key} />;
      } else if (type === "request") {
        return (
          <SidenavCollapse
            name={name}
            activeIcon={activeIcon}
            unActiveIcon={unActiveIcon}
            noCollapse={noCollapse}
            active={key === collapseName || (!collapseName && key === "home")}
            onClick={() => setIsModalOpen(true)}
            key={key}
          >
            {collapse ? renderCollapse(collapse) : null}
          </SidenavCollapse>
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ darkSidenav, miniSidenav, layout }}>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          onSubmit={handleOnSubmit}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: darkSidenav ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" flexDirection="column">
            <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <XTypography fontSize="16px" fontWeight="bold">
                Looking for something? Submit a feature request.
              </XTypography>
              <Icon
                sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                  fontSize: `${size.md} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  color: darkSidenav ? white.main : dark.main,
                  stroke: darkSidenav ? white.main : dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                })}
                onClick={handleClose}
              >
                close
              </Icon>
            </XBox>
            <XTypography style={{ color: "#AFAFAF" }} fontSize="14px" mb={2}>
              Requests will be sent to our development team for assessment. Thank you for your
              input!
            </XTypography>
            <XBox mb={3}>
              <XInput
                placeholder="Enter your request..."
                multiline
                rows={5}
                id="message"
                onChange={handleOnChange}
                value={inputs.message}
                sx={{ width: "100%" }}
              />
            </XBox>
            <XButton
              variant="gradient"
              color="button"
              sx={{ alignSelf: "end" }}
              type="submit"
              onClick={handleOnSubmit}
              disabled={!inputs.message}
            >
              Submit
            </XButton>
          </XBox>
        </XBox>
      </Modal>
      <XBox p={2} textAlign="center" mb={2}>
        <XBox
          component={NavLink}
          to="/"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="8px"
        >
          {brand && (
            <XBox component="img" src={brand} alt="Xplainable Logo" width="44px" mr={0.25} />
          )}
          {!miniSidenav && (
            <XTypography
              component="h6"
              variant="h5"
              fontWeight="bold"
              fontSize="25px"
              color={darkSidenav ? "white" : "black"}
            >
              {brandName}
            </XTypography>
          )}
        </XBox>
      </XBox>
      <XBox px={2}>
        <SideNavMenu />
      </XBox>
      <XBox
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none" /* IE and Edge */,
          scrollbarWidth: "none" /* Firefox */,
        }}
      >
        <List>{renderRoutes}</List>
        <Divider />
        <XBox pt={1} mb={2} mx={2}>
          {!miniSidenav && <SidenavFooter />}
        </XBox>
      </XBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  // color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  // color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  workspaces: PropTypes.array,
  color: PropTypes.string,
  brand: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;

const SubMenu = ({ workspace }) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleOpenSubMenu = (event) => {
    setOpenSubMenu(event.currentTarget);
  };

  const handleCloseSubMenu = () => {
    setOpenSubMenu(null);
  };

  return (
    <>
      <MenuItem onClick={handleOpenSubMenu}>{workspace.organisation_name}</MenuItem>
      <Menu
        open={Boolean(openSubMenu)}
        anchorEl={openSubMenu}
        onClose={handleCloseSubMenu}
        sx={{ mt: 2 }}
      >
        {workspace.teams.map((team, index) => (
          <MenuItem key={index}>{team.team_name}</MenuItem>
        ))}
      </Menu>
    </>
  );
};

// Typechecking props for the Sidenav
SubMenu.propTypes = {
  workspace: PropTypes.array,
};
