import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import SelectBox from "./components/selectbox";

function Survey({ formData, setFieldValue }) {

  const onSelection = (value) => {
    // Use Formik's setFieldValue to update the form value
    setFieldValue("survey", value);
  };

  return (
    <XBox>
      <XBox 
        width={"100%"} 
        lineHeight={0} 
        display="flex" 
        height={"100%"} 
        flexDirection='column' 
        p={2} 
        justifyContent='center' 
        alignItems='center' 
        textAlign="center">
        <XTypography variant="h5" fontWeight="bold">
            How did you hear about us?
        </XTypography>
        <XTypography variant="button" fontWeight="regular" color="text" px={6}>
          {"We’d love to know how you came across us. It helps us better understand our user base so we can develop better products for everyone."}
        </XTypography>
      </XBox>
      <SelectBox onSelection={onSelection}/>
    </XBox>
  );
}

Survey.propTypes = {
  formData: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Survey;
