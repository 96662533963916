import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useScenarioMutation = () => {
  const { mutate: addScenarioMutation } = useMutation((params) =>
    apiHandlers.scenario.addScenario(...params)
  );

  const { mutate: deleteScenarioMutation } = useMutation((params) =>
    apiHandlers.scenario.deleteScenario(...params)
  );

  return {
    addScenarioMutation,
    deleteScenarioMutation,
  };
};
