import { useState } from "react";
import { useXplainableController } from "context";

// xplainable dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import CollectionsOverview from "./components/overview";
import CollectionSettings from "./components/settings";
import colors from "assets/theme/base/colors";

import { animateGradient } from "assets/theme/base/animations";

function AllCollections() {
  const [tabValue, setTabValue] = useState(0);
  const [controller, dispatch] = useXplainableController();
  const { globalImage, globalColor } = controller;

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={"Collections"}
        description={"Collections of scenarios for optimisation and tracking."}
        icon={"ni ni-collection"}
        color={colors.xppurple.main}
        tabColor={colors.xppurple.main}
        tabs={[
          {
            label: "Overview",
            iconClassName: "ni ni-app",
          },
          {
            label: "Settings",
            iconClassName: "ni ni-settings-gear-65",
          },
        ]}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      {tabValue === 0 && <CollectionsOverview />}
      {tabValue === 1 && <CollectionSettings />}
    </DashboardLayout>
  );
}

export default AllCollections;
