import React from "react";

import XBox from "components/XBox";
import XTypography from "components/XTypography";

const ModelLogicRules = () => {
  return (
    <XBox>
      <XBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "300px" }}
      >
        <XTypography variant="h6" fontWeight="light">
          Logic rules are applied to optimisers and the scenario analysis tool
        </XTypography>
      </XBox>
    </XBox>
  );
};

export default ModelLogicRules;
