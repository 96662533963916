import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useDownloadBatchQuery = (
  activeWorkspace,
  optimiser_id,
  version_id,
  batch_id,
  logout
) => {
  return useQuery(
    [QUERY_CONSTANTS.DOWNLOAD_BATCH, activeWorkspace, optimiser_id, version_id, batch_id],
    () =>
      apiHandlers.batchOptimisation.downloadBatch(
        activeWorkspace,
        optimiser_id,
        version_id,
        batch_id
      ),
    {
      enabled: !!batch_id,
    },
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
