import { useState, useContext, useRef, useEffect, useCallback } from "react";
import { useXplainableController } from "context";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "hooks";
import DeploymentContext from "../deployment-context";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { AppBar, Tabs, Tab, Select, MenuItem, Slider, Switch } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XInput from "components/XInput";
import { Comments } from "shared/Comments";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import DefaultItem from "shared/Items/DefaultItem";
import rgba from "assets/theme/functions/rgba";
import colors from "assets/theme/base/colors";

//Import BrushChart and Waterfall chart from scenario analysis
import LinkPreprocessor from "./components/PreprocessorCard";
import { BrushChart } from "shared/models/ScenarioAnalysis/components/BrushChart";
import { usePayloadQuery } from "api/query/usePayloadQuery";

import XBadge from "components/XBadge";
import WaterfallChart from "shared/models/ScenarioAnalysis/components/WaterfallChart";
import EventIcon from "shared/Icons/EventIcons";
import SuccessChecklistIcon from "assets/images/icons/deployments/success-checklist-icon.svg";
import ErrorChecklistIcon from "assets/images/icons/deployments/error-checklist-icon.svg";
import DarkCopyIcon from "assets/images/icons/deployments/dark-copy-icon.svg";
import LightCopyIcon from "assets/images/icons/deployments/light-copy-icon.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Skeleton from '@mui/material/Skeleton';

function SingleDeploymentOverview({ setTabValue, tabValue }) {
  const { showSuccessToast, showErrorToast } = useToast();
  const { logout } = useAuth0();

  const { 
    model_id, 
    toggle, 
    deployment_id, 
    collapsedWidth, 
    active_version 
      } =
    useContext(DeploymentContext);
  const [controller, dispatch] = useXplainableController();
  const { darkMode } = controller;
  const selectionWaterfallRef = useRef([0, 0]);

  const [payload, setPayload] = useState("");
  const [deployKey, setDeployKey] = useState(false);
  const [copied, setCopied] = useState(false);
  const [payloadCopy, setPayloadCopy] = useState(false);
  const [responseCopy, setResponseCopy] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [deployKeyData, setDeployKeyData] = useState(null);
  const [fieldValues, setFieldValues] = useState({});
  const [base_value, setBaseValue] = useState(0);
  const [response, setResponse] = useState({});
  const [appbarValue, setAppbarValue] = useState(0);
  const [responseTime, setResponseTime] = useState(null);
  const [payloadView, setPayloadView] = useState(0);
  const [exampleData, setExampleData] = useState(null);
  const [originalPayload, setOriginalPayload] = useState('');
  const [selectedPayloadIndex, setSelectedPayloadIndex] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [threshold, setThreshold] = useState(0.5);
  const [showBreakdown, setShowBreakdown] = useState(true);

  useEffect(() => {
    selectionWaterfallRef.current = [Object.values(fieldValues)?.length, 0];
  }, [fieldValues]);

  // Add useEffect to handle screen size changes for tabs orientation
  useEffect(() => {
    // A function that sets the orientation state of the tabs
    function handleTabsOrientation() {
      return window.innerWidth < 1024 ? setTabsOrientation("horizontal") : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    // Call the function to set the orientation when the component mounts
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const {
    data: payloadData,
    isLoading: isPayloadLoading,
    refetch: refetchPayload,
  } = usePayloadQuery(deployment_id, logout);

  // Handle initial payload data
  useEffect(() => {
    if (payloadData?.data) {
      const payloadString = JSON.stringify(payloadData.data[0], null, 2);
      const formattedPayload = `[${payloadString}]`;
      setOriginalPayload(formattedPayload);
      setPayload(formattedPayload);
    }
  }, [payloadData]);

  //Set the Appbar selection
  const handleSetTabValue = (event, newValue) => {
    setAppbarValue(newValue);
  };

  // This function handles copying the URL to the clipboard
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `${process.env.REACT_APP_INFERENCE_URL}/v1/predict?threshold=${threshold}&show_breakdown=${showBreakdown}`
      );
      setCopied(true);

      // Reset the 'copied' state after 2 seconds
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy URL to clipboard", err);
    }
  };

  function handleChange(e) {
    // Get the value from the event target (the input)
    let inputData = e.target.value;

    // Update the deployKeyData state
    setDeployKeyData(inputData);
  }

  const handleCopyPayload = async () => {
    try {
      if (payload) {
        await navigator.clipboard.writeText(payload);
        setPayloadCopy(true);

        // Reset the 'copied' state after 2 seconds
        setTimeout(() => setPayloadCopy(false), 2000);
      }
    } catch (err) {
      console.error("Failed to copy payload to clipboard", err);
    }
  };

  const handleCopyResponse = async () => {
    try {
      if (response) {
        await navigator.clipboard.writeText("[" + JSON.stringify(response, null, 2) + "]");
        setResponseCopy(true);

        // Reset the 'copied' state after 2 seconds
        setTimeout(() => setResponseCopy(false), 2000);
      }
    } catch (err) {
      console.error("Failed to copy payload to clipboard", err);
    }
  };

  const handlePayloadChange = (event) => {
    setPayload(event.target.value);
    if (payloadView === 0) {
      payload(payload);
    }
  };
  
  //TODO: Turn this into a GET request in react query
  const handlePrediction = async () => {
    const startTime = Date.now();

    try {
      let predictionPayload;
      if (payloadView === 0) {
        predictionPayload = payload.trim().startsWith('[') ? payload : `[${payload}]`;
      } else if (exampleData) {
        predictionPayload = JSON.stringify([exampleData]);
      }

      const response = await fetch(
        `${process.env.REACT_APP_INFERENCE_URL}/v1/predict?threshold=${threshold}&show_breakdown=${showBreakdown}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            api_key: deployKeyData,
          },
          body: predictionPayload,
        }
      );

      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      setResponseTime(elapsedTime + "ms");

      if (!response.ok) {
        const errorText = await response.text();
        const errorJson = JSON.parse(errorText);
        console.log("The error text is", errorJson.detail);
        showErrorToast(errorJson.detail);

        throw new Error("Failed to fetch prediction response");
      }

      // Get the response body
      const fetchedPrediction = await response.json();
      
      // Check if the response is an array of arrays and get the first array
      const predictions = Array.isArray(fetchedPrediction[0]) ? fetchedPrediction[0] : fetchedPrediction;
      
      // Set response with the full prediction data
      setResponse(predictions);

      // If there's only one prediction, set the base value from its breakdown
      if (predictions.length >= 1) {
        const baseValueObj = predictions[selectedPayloadIndex].breakdown.find(item => item.feature === "base_value");
        setBaseValue(baseValueObj?.score || 0);

        // Reformat the data for waterfall chart
        const reformattedData = reformatData(
          predictions[selectedPayloadIndex]
        );

        // Update the field values
        setFieldValues(reformattedData);
      }
    } catch (error) {
      console.error("Error fetching prediction:", error);
    }
  };

    // This will receive both raw and processed data when a preprocessor is linked
    const handleExampleDataUpdate = useCallback((data) => {
      console.log('Example data updated:', data);
      if (JSON.stringify(data) !== JSON.stringify(exampleData)) {
        setExampleData(data);
      }
    }, [exampleData]);

  // Function to get current payload based on preprocessor data availability
  const getCurrentPayload = useCallback(() => {
    if (payloadView === 0) {
      return payload; // Show the regular payload
    }
    return exampleData?.raw ? JSON.stringify([exampleData.raw], null, 2) : payload;
  }, [payloadView, payload, exampleData]);

  // Update the payload display
  useEffect(() => {
    if (payloadView === 1 && exampleData) {
      setPayload(JSON.stringify([exampleData], null, 2));
    } else if (payloadView === 0) {
      setPayload(originalPayload);
    }
  }, [payloadView, exampleData, originalPayload]);

  // Add this useEffect to handle payload selection changes
  useEffect(() => {
    if (response && response.length > 0) {
      // Reformat the data for waterfall chart using the selected payload
      const reformattedData = reformatData(response[selectedPayloadIndex]);
      setFieldValues(reformattedData);
    }
  }, [selectedPayloadIndex, response]); // Dependencies array includes both selectedPayloadIndex and response

  // Add this useEffect to reset selectedPayloadIndex when response length changes
  useEffect(() => {
    if (response && Array.isArray(response)) {
      // Reset to 0 if current index is out of bounds
      if (selectedPayloadIndex >= response.length) {
        setSelectedPayloadIndex(0);
      }
    }
  }, [response?.length]); // Only trigger when response length changes

  // Update the existing useEffect to include a safety check
  useEffect(() => {
    if (response && 
        Array.isArray(response) && 
        response.length > 0 && 
        selectedPayloadIndex < response.length &&
        response[selectedPayloadIndex]) {
      // Reformat the data for waterfall chart using the selected payload
      const reformattedData = reformatData(response[selectedPayloadIndex]);
      setFieldValues(reformattedData);
    }
  }, [selectedPayloadIndex, response]);

  return (
    <Grid container zIndex={1}>
      <Grid item xs={12} zIndex={1} xl={collapsedWidth ? 12 : 8} pr={{ xs: 0, lg: 1 }}>
        <XBox
          mr={1}
          sx={{
            width: collapsedWidth ? "100%" : "100%",
            transition: "width 500ms",
            zIndex: 1,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} mt={3}>
              <Card sx={{ p: 2 }}>
                <XBox display="flex" flexDirection="column" gap={2}>
                  <XTypography variant="h5" textTransform="capitalize" fontSize="18px">
                    Deployment URL
                  </XTypography>
                  <XBox>
                    <XBox display="flex" gap={2}>
                      <XBox display="flex" position="relative" width="100%">
                        <XBox
                          width="100%"
                          bgColor={darkMode ? rgba(colors.light.main, 0.05) : colors.light.main}
                          borderRadius="md"
                          display="flex"
                          alignItems="center"
                          px={2}
                        >
                          <XTypography variant="overline" fontSize="16px">
                            {`${process.env.REACT_APP_INFERENCE_URL}/v1/predict?threshold=${threshold}&show_breakdown=${showBreakdown}`}
                          </XTypography>
                        </XBox>
                        <XBox position="absolute" right="0" top="8px">
                          <EventIcon
                            onClick={handleCopy}
                            tooltipText="Copy URL to clipboard"
                            icon={
                              <XBox component="img" src={darkMode ? LightCopyIcon : DarkCopyIcon} />
                            }
                            copied={copied}
                          />
                        </XBox>
                      </XBox>
                      <XButton variant="gradient" color="button">
                        POST
                      </XButton>
                    </XBox>
                  </XBox>
                </XBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              { !toggle && deployKey ?
              <Card sx={{ p: 2, mb:3 }}>
              <XBox lineHeight={1} mb={2}>
                <XTypography variant="h6" fontSize="18px">
                  Deployment Checklist
                </XTypography>
              </XBox>
              <XBox display="flex" flexDirection="column" gap={3}>
                <XBox display="flex" alignItems="center" gap={1}>
                  <XBox component="img" src={SuccessChecklistIcon} />
                  <XTypography variant="button" fontWeight="light">
                    Model Is Deployed
                  </XTypography>
                  <Tooltip
                    title="The model is active_deployment to the inference server"
                    placement="top"
                  >
                    <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                      <Icon>question_mark</Icon>
                    </XButton>
                  </Tooltip>
                </XBox>
                <XBox display="flex" alignItems="center" gap={1}>
                  <XBox
                    component="img"
                    src={toggle ? SuccessChecklistIcon : ErrorChecklistIcon}
                  />
                  <XTypography variant="button" fontWeight="light">
                    {toggle ? "Deployment is active" : "Deployment is inactive"}
                  </XTypography>
                  <Tooltip title="The deployment has been activated" placement="top">
                    <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                      <Icon>question_mark</Icon>
                    </XButton>
                  </Tooltip>
                </XBox>
                <XBox display="flex" alignItems="center" gap={1}>
                  <XBox
                    component="img"
                    src={deployKey ? SuccessChecklistIcon : ErrorChecklistIcon}
                  />
                  <XTypography variant="button" fontWeight="light">
                    {deployKey ? "Deploy key created" : "Create a deploy key"}
                  </XTypography>
                  <Tooltip
                    title="Create a deployment key and pass as a token in the request"
                    placement="top"
                  >
                    <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                      <Icon>question_mark</Icon>
                    </XButton>
                  </Tooltip>
                </XBox>
              </XBox>
            </Card>
            :
            <></>
            }
              <LinkPreprocessor
                modelVersion={active_version}
                setExampleData={handleExampleDataUpdate}
              />
              <Card sx={{ p: 2, mt: 3 }}>
                <XBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <XTypography variant="h6" textTransform="capitalize" fontSize="18px">
                    Example Payload
                  </XTypography>
                  <AppBar position="static" sx={{ boxShadow: 'none', width: 'auto', backgroundColor: 'transparent' }}>
                    <Tabs
                      value={payloadView}
                      onChange={(e, newValue) => setPayloadView(newValue)}
                      sx={{
                        padding: 0,
                        '& .MuiTabs-flexContainer': {
                          gap: 0,
                        },
                        '& .MuiTab-root': {
                          minHeight: '36px',
                          minWidth: '36px',
                          width: '36px',
                          height: '36px',
                          padding: 0,
                          margin: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                        '& .Mui-selected': {
                          backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                          borderRadius: '6px',
                          '& .MuiIcon-root': {
                            color: 'white !important',
                            fontSize: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }
                        },
                        '& .MuiTabs-indicator': {
                          display: 'none',
                        },
                        '& .MuiIcon-root': {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%',
                          margin: 0,
                        }
                      }}
                    >
                      <Tab 
                        icon={
                          <Tooltip title="Payload data" placement="top">
                            <Icon sx={{ display: 'flex !important', alignItems: 'center !important', justifyContent: 'center !important' }}>
                              transform
                            </Icon>
                          </Tooltip>
                        }
                        sx={{ minWidth: 0 }}
                        aria-label="Preprocessed data view"
                      />
                      <Tab 
                        icon={
                          <Tooltip title={!exampleData ? "Link a preprocessor to view raw data" : "Raw data"} placement="top">
                            <Icon sx={{
                              display: 'flex !important',
                              alignItems: 'center !important',
                              justifyContent: 'center !important',
                              width: '100%',
                              height: '100%',
                              opacity: !exampleData ? 0.5 : 1,
                            }}>
                              data_object
                            </Icon>
                          </Tooltip>
                        }
                        disabled={!exampleData} // Simpler check
                        sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      />
                    </Tabs>
                  </AppBar>
                </XBox>
                <XBox position="relative" width="100%">
                  <XBox
                    display="flex"
                    position="absolute"
                    top={0}
                    right={10}
                    alignItems="center"
                    zIndex={1}
                    p={1}
                  >
                    <EventIcon
                      onClick={handleCopyPayload}
                      tooltipText="Copy Payload to Clipboard"
                      icon={<XBox component="img" src={darkMode ? LightCopyIcon : DarkCopyIcon} />}
                      copied={payloadCopy}
                    />
                  </XBox>
                  {isPayloadLoading ? (
                    <XBox sx={{ p: 1, height: '320px', overflow: 'hidden' }}>
                      <Skeleton width="20%" height={24} sx={{ mb: 1 }} />
                      <XBox sx={{ pl: 2 }}>
                        <Skeleton width="60%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="40%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="70%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="50%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="45%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="65%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="55%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="40%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="75%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="50%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="60%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="45%" height={24} sx={{ mb: 1 }} />
                        <Skeleton width="35%" height={24} sx={{ mb: 1 }} />
                      </XBox>
                      <Skeleton width="20%" height={24} />
                    </XBox>
                  ) : (
                    <XInput
                      value={getCurrentPayload()}
                      onChange={handlePayloadChange}
                      multiline
                      rows={15}
                      sx={{
                        "&::-webkit-scrollbar": {
                          width: 0,
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "none",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "none",
                        },
                        msOverflowStyle: "none",
                        scrollbarWidth: "none",
                      }}
                    />
                  )}
                </XBox>
                {/* Add controls below the Example Payload */}
                <XBox mt={3} display="flex" justifyContent="space-between" alignItems="center">
                  <XBox display="flex" alignItems="center" gap={2} width="60%">
                    <XTypography variant="caption" fontWeight="light">
                      Threshold
                    </XTypography>
                    <Slider
                      value={threshold}
                      onChange={(_, newValue) => setThreshold(newValue)}
                      min={0}
                      max={1}
                      step={0.01}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => value.toFixed(2)}
                      sx={{
                        '& .MuiSlider-thumb': {
                          backgroundColor: colors.xpblue.main,
                        },
                        '& .MuiSlider-track': {
                          backgroundColor: colors.xpblue.main,
                        },
                        '& .MuiSlider-rail': {
                          backgroundColor: darkMode ? rgba(colors.light.main, 0.1) : rgba(colors.dark.main, 0.1),
                        },
                      }}
                    />
                  </XBox>
                  <XBox display="flex" alignItems="center" gap={2}>
                    <XTypography variant="caption" fontWeight="light">
                      Show Breakdown
                    </XTypography>
                    <Switch
                      checked={showBreakdown}
                      onChange={(e) => setShowBreakdown(e.target.checked)}
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: colors.xpblue.main,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: colors.xpblue.main,
                        },
                      }}
                    />
                  </XBox>
                </XBox>
                <XBox>
                  <XBox lineHeight={1} mt={3}>
                    <XTypography variant="h6" fontSize="18px">
                      Deployment Key
                    </XTypography>
                  </XBox>
                  <XBox display="flex" mt={1} position="relative">
                    <XBox width="100%">
                      <XInput
                        type={showKey ? "text" : "password"}
                        placeholder={"Paste a deployment key here..."}
                        value={deployKeyData}
                        onChange={handleChange}
                        sx={{ pr: 4 }}
                      />
                    </XBox>
                    <XBox position="absolute" right="0">
                      <Tooltip title={showKey ? "Hide Deployment Key" : "Show Deployment Key"}>
                        <IconButton
                          size="medium"
                          color={"dark"}
                          onClick={() => setShowKey(!showKey)}
                        >
                          {showKey ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </Tooltip>
                    </XBox>
                  </XBox>
                  <XBox display="flex" mt={2}>
                    <XBox width="100%">
                      <Tooltip title="Generate a new example payload">
                        <XButton
                          sx={{
                            backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            boxShadow: "none",
                            width: "100%",
                            opacity: payloadView === 1 ? 0.5 : 1, // Dim when viewing raw data
                            pointerEvents: payloadView === 1 ? "none" : "auto", // Disable clicks when viewing raw data
                          }}
                          onClick={() => refetchPayload()}
                        >
                          Regenerate
                        </XButton>
                      </Tooltip>
                    </XBox>
                    <XBox width="100%">
                      <XButton
                        color="button"
                        variant="gradient"
                        onClick={handlePrediction}
                        sx={{ width: "100%" }}
                      >
                        Predict
                      </XButton>
                    </XBox>
                  </XBox>
                </XBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card sx={{ p: 2 }}>
                <XBox lineHeight={1}>
                  <XTypography variant="h6" fontSize="18px">
                    Prediction Response
                  </XTypography>
                  
                  {/* Add payload selector if multiple payloads exist */}
                  {response.length > 1 && (
                    <XBox mt={2}>
                      <Select
                        value={selectedPayloadIndex}
                        onChange={(e) => setSelectedPayloadIndex(e.target.value)}
                        sx={{ minWidth: 200 }}
                      >
                        {response.map((_, index) => (
                          <MenuItem key={index} value={index}>
                            Prediction {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </XBox>
                  )}

                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={6}>
                        <DefaultItem
                          color="xpblue"
                          bgColor={colors.light.main}
                          title="Prediction"
                          description={response[selectedPayloadIndex]?.pred || 0}
                          tooltipText="The prediction of the model."
                          p={1}
                          borderRadius="lg"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DefaultItem
                          color="xpblue"
                          bgColor={colors.light.main}
                          title="Score"
                          description={response[selectedPayloadIndex]?.score?.toFixed(2) || 0}
                          tooltipText="The score associated to the prediction value."
                          p={1}
                          borderRadius="lg"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DefaultItem
                          color="xpblue"
                          bgColor={colors.light.main}
                          title="Partition"
                          description={response?.partition || "__dataset__"}
                          tooltipText="The partition passed in the example payload."
                          p={1}
                          borderRadius="lg"
                        />
                      </Grid>
                    </Grid>
                </XBox>
                <XBox mt={3}>
                  <AppBar position="static">
                    <Tabs
                      orientation={tabsOrientation}
                      value={appbarValue}
                      onChange={handleSetTabValue}
                      sx={{
                        backgroundColor: `${darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,
                        transition: "all 500ms ease",
                        color: "#AFAFAF",
                        "& .Mui-selected": {
                          fontWeight: "600",
                          color: `${darkMode ? "white" : "black"} !important`,
                          backgroundColor: `${darkMode ? "#262525" : "white"} !important`,
                        },
                        "& .MuiTabs-indicator": {
                          display: "none",
                        },
                      }}
                    >
                      <Tab 
                        label="Response" 
                        sx={{ minWidth: 0 }} 
                        disabled={!Object.keys(response).length} 
                      />
                      <Tab 
                        label="Plot" 
                        sx={{ minWidth: 0 }} 
                        disabled={!Object.keys(response).length || !Object.keys(fieldValues).length}
                      />
                    </Tabs>
                  </AppBar>

                  {appbarValue === 0 && (
                    <XBox mt={1} position="relative" width="100%">
                      {Object.keys(response).length > 0 && (
                        <XBox
                          display="flex"
                          position="absolute"
                          top={0}
                          right={10}
                          alignItems="center"
                          zIndex={1}
                          p={1}
                        >
                          <EventIcon
                            onClick={handleCopyResponse}
                            tooltipText="Copy Response to Clipboard"
                            icon={
                              <XBox component="img" src={darkMode ? LightCopyIcon : DarkCopyIcon} />
                            }
                            copied={responseCopy}
                          />
                        </XBox>
                      )}
                      <XInput
                        value={
                          Object.keys(response).length === 0
                            ? ""
                            : JSON.stringify(response, null, 2)
                        }
                        multiline
                        rows={22}
                      />
                      <XBox
                        position="absolute"
                        bottom={0}
                        right={10}
                        alignItems="center"
                        zIndex={1}
                        p={1}
                      >
                        {responseTime && (
                          <Tooltip
                            title="The displayed response time includes both the network latency and processing overhead within the browser."
                            placement="top"
                          >
                            <XBadge
                              badgeContent={responseTime}
                              variant="contained"
                              color="success"
                              size="md"
                            />
                          </Tooltip>
                        )}
                      </XBox>
                    </XBox>
                  )}
                  {appbarValue === 1 && (
                    <XBox>
                      <BrushChart
                        data={Object.values(fieldValues)}
                        selectionRef={selectionWaterfallRef}
                        isSorted={true}
                        collapsedWidth={collapsedWidth}
                        height={400}
                      >
                        {(selection) => (
                          <WaterfallChart
                            isCollapsed={collapsedWidth}
                            id={"deployment-waterfall"}
                            featureData={fieldValues}
                            margin={{
                              top: 10,
                              right: 20,
                              bottom: 20,
                              left: 20,
                            }}
                            height={415}
                            baseValue={response[selectedPayloadIndex]?.breakdown?.find(item => item.feature === "base_value")?.score || 0}
                            sort={true}
                            selection={selection}
                            isSorted={true}
                          />
                        )}
                      </BrushChart>
                    </XBox>
                  )}
                </XBox>
              </Card>
            </Grid>
          </Grid>
        </XBox>
      </Grid>
      <Grid item xs={collapsedWidth ? 0 : 12} xl={collapsedWidth ? 0 : 4} mt={3}>
        <Slide direction="left" in={!collapsedWidth} timeout={500}>
          <XBox
            sx={{
              width: collapsedWidth ? 0 : "100%",
              overflow: "hidden",
              transition: "width 500ms",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <Grid container alignContent="flex-start" spacing={3}>
              <Grid item xs={12}>
                <Comments
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  modelId={model_id}
                  versionNumber={null}
                  deploymentId={deployment_id}
                  type={"deployments"}
                />
              </Grid>
            </Grid>
          </XBox>
        </Slide>
      </Grid>
    </Grid>
  );
}

SingleDeploymentOverview.propTypes = {
  setTabValue: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired
};

export default SingleDeploymentOverview;

function reformatData(payload) {
  // Add safety checks
  if (!payload || !payload.breakdown) {
    return {};
  }

  const breakdown = payload.breakdown;
  
  // Initialize the output object
  let output = {};
  
  // Process all features except base_value
  breakdown.forEach(item => {
    if (item.feature !== "base_value") {
      output[item.feature] = {
        keyName: item.feature,
        field: String(item.value || ""),
        value: item.score,
        checked: false
      };
    }
  });

  return output;
}