import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { Paths } from "settings";
import { useApiKey } from "../ApiKeyContext";

import PropTypes from "prop-types";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import XBox from "components/XBox";

export const AuthRouteWrapper = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const { apiKeyChecked } = useApiKey();

  if (!apiKeyChecked) {
    return (
      <XBox height="100vh">
        <LoadingSpinner size={100} animationType="pulse" />
      </XBox>
    );
  }

  if (isAuthenticated) {
    return <Navigate to={Paths.index} replace />;
  }

  return <>{children}</>;
};

AuthRouteWrapper.propTypes = {
  children: PropTypes.element,
};
