// api/query.js
import { useQuery } from "@tanstack/react-query";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";
import { useAuth0 } from "@auth0/auth0-react";
import { makeHttpRequest } from "api/httpClient";
import { apiPaths } from "api/apiPath";
import { apiHandlers } from "../apiHandlers";

export const useLoginQuery = () => {
  const { isAuthenticated, getAccessTokenSilently, isLoading: isAuthLoading } = useAuth0();

  return useQuery({
    queryKey: [QUERY_CONSTANTS.LOGIN, isAuthenticated],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const response = await makeHttpRequest({
        method: 'GET',
        url: apiPaths.login.login(),
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    },
    retry: false,
    // staleTime: Infinity,
    // cacheTime: 1000 * 60 * 30,
  });
};

export const useLimitsQuery = (activeWorkspace, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.LIMITS, activeWorkspace?.organisation_id],
    () => apiHandlers.login.limits(activeWorkspace),
    {
      cacheTime: 60000, // Refetch data every 60,000 milliseconds (1 minute)
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};