import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import colors from "assets/theme/base/colors";

// Images
import upgradeImage from "assets/images/upgrade-card.png";
import trialEndedImage from "assets/images/subscription-overdue.png"
import warningIcon from "assets/images/icons/warning-icon.svg"
import XButton from "components/XButton";
import OrganisationCreationModal from "shared/Modals/OrganisationModal";
import { useOrganisationCreation } from "hooks/useOrganisationCreation";

function TrialCard({ trialStarted, activeSubscription }) {
  const { 
    isModalOpen, 
    handleOpen, 
    handleClose, 
    handleSubmit 
  } = useOrganisationCreation(trialStarted);

  return (
    <>
      <OrganisationCreationModal isOpen={isModalOpen} onClose={handleClose} onSubmit={handleSubmit} freeTrial={true}/>
      <Card
      sx={{
        background: `url(${activeSubscription ? upgradeImage : trialEndedImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "16px",
        padding: "24px",
        color: "#333",
      }}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <XBox display="flex">
            {!activeSubscription && <XBox src={warningIcon} component="img" mr={1}/>}
            <XTypography 
              color={activeSubscription ? "secondary" : "error"} 
              fontSize="16px" 
              fontWeight={activeSubscription ? "light" : "bold"}
              >
              {activeSubscription ? trialStarted ? "You have 7 days remaining on your free trial" : "Start your 7-day free trial now" : "Your free trial has ended"}
            </XTypography>
          </XBox>
          <XBox display="flex" alignItems="center">
            <XTypography variant="h6" fontSize="16px" fontWeight="bold" sx={{ mr: 1 }}>
              {trialStarted ? "Upgrade to our" : "Get started with our"}
            </XTypography>
            <XTypography variant="h5" fontWeight="bold" color="xppink" sx={{ mr: 1 }}>
              Software Plan
            </XTypography>
            <span>🎉</span>
          </XBox>
          <XTypography color="secondary" fontSize="16px" fontWeight="light">
            {trialStarted ? "to receive Full access for teams!" : "and enjoy Full access for teams!"}
          </XTypography>
        </Grid>
        <Grid item>
          <XBox>
            <Grid container spacing={1} direction="column">
              {[
                "5 Creator Seats",
                "20 Viewer Seats",
                "Access to all 15 Features",
                "3 Dedicated Deployments",
                "REST APIs",
              ].map((feature, index) => (
                <Grid item key={index}>
                  <Grid container alignItems="center">
                    <CheckCircleIcon sx={{ color: colors.xppink.main, mr: 1 }} />
                    <XTypography fontSize="14px" fontWeight="light">{feature}</XTypography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </XBox>
        </Grid>
        <Grid item>
          <XBox>
          <XButton fullWidth variant="gradient" color="button" onClick={handleOpen}>
            {trialStarted ? "Sign Up Now" : "Start Your Free Trial"}
          </XButton>
          </XBox>
        </Grid>
      </Grid>
    </Card>
  </>
  );
}


TrialCard.propTypes = {
  trialStarted: PropTypes.bool,
  activeSubscription: PropTypes.bool
};

export default TrialCard;
