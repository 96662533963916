import React, { useEffect, useState } from "react";

import { Card, Divider, Icon } from "@mui/material";

import XBox from "components/XBox";
import XButton from "components/XButton";
import XTypography from "components/XTypography";
import DataTable from "shared/Tables/DataTable";
import TableCellComponent from "layouts/batchOptimisation/AllBatchOptimisations/components/TableCellComponent";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

import PropTypes from "prop-types";
import { useDatasetQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { getTimeDifference } from "shared/Functions/Date";
import { useLocation, useNavigate } from "react-router-dom";
import { useDatasetMutation } from "api/mutations";
import { useAuth0 } from "@auth0/auth0-react";

import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";
import ChevronLeftIcon from "assets/images/icons/batch/chevron-left-icon.svg";

export const DatasetBlock = ({ dataset, setDataset, setActiveStep, handleCreateDatasetButton }) => {
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();
  const { data, isLoading } = useDatasetQuery(activeWorkspace, logout);
  const { deleteDatasetMutation } = useDatasetMutation();

  const url = new URL(window.location.href);
  const versionId = url.searchParams.get("version_id");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!data) return;

    setDataset(data.data);
  }, [data]);

  const handleDelete = (e, props) => {
    deleteDatasetMutation([activeWorkspace, props.row.original.dataset_id], {
      onSuccess: () => {
        const filteredDataset = dataset.filter(
          (item) => item.dataset_id !== props.row.original.dataset_id
        );
        setDataset(filteredDataset);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "dataset_name",
      id: "dataset_name",
      Cell: (props) => {
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            <XTypography fontSize="16px" fontWeight="regular">
              {props.row.original.dataset_name}
            </XTypography>
          </XBox>
        );
      },
    },
    {
      Header: "Description",
      accessor: "dataset_description",
      id: "dataset_description",
      Cell: (props) => {
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            <XTypography fontSize="12px" fontWeight="medium">
              {props.row.original.dataset_description}
            </XTypography>
          </XBox>
        );
      },
    },
    {
      Header: "Last updated",
      accessor: "last_updated",
      Cell: (props) => {
        return (
          <XTypography variant="button" fontWeight="regular" fontSize="12px" color="secondary">
            {props.row.original.last_updated
              ? getTimeDifference(props.row.original.last_updated, true)
              : null}
          </XTypography>
        );
      },
    },
    {
      Header: "Runs",
      accessor: "runs",
      Cell: (props) => {
        return (
          <XTypography variant="button" fontWeight="regular" fontSize="12px" color="secondary">
            1
          </XTypography>
        );
      },
    },
    {
      Header: "",
      width: "5%",
      id: "function",
      Cell: (props) => <TableCellComponent {...props} handleDelete={handleDelete} />,
    },
  ];

  const handleRowClick = (item) => {
    if (versionId) {
      navigate(
        `${location.pathname}?dataset_id=${item.original.dataset_id}&version_id=${versionId}`,
        {
          state: { datasetId: item.original.dataset_id, dataset: dataset },
        }
      );
      setActiveStep(3);
      return;
    }

    navigate(`${location.pathname}?dataset_id=${item.original.dataset_id}`);
    setActiveStep(3);
    return;
  };

  const handleBackButton = () => {
    setActiveStep(0);
  };

  if (isLoading) {
    return (
      <Card
        sx={{ minHeight: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoadingSpinner size={50} animationType="pulse" />
      </Card>
    );
  }

  if (!dataset || dataset?.length === 0) {
    return (
      <Card height={"100%"} mt={10} pb={{ xs: 0, lg: 10 }}>
        <XBox sx={{ padding: "10px 20px" }}>
          <XButton
            variant="outlined"
            sx={{ padding: 0, minHeight: "20px", background: "transparent", border: "none" }}
            onClick={handleBackButton}
          >
            <XBox display="flex" gap="4px" alignItems="center">
              <XBox src={ChevronLeftIcon} component="img" />
              <XTypography color="xpblue" variant="button">
                Back
              </XTypography>
            </XBox>
          </XButton>
          <XTypography fontSize="18px">Data Uploads</XTypography>
          <XTypography fontSize="16px" color="secondary" fontWeight="light">
            Select a dataset to optimise
          </XTypography>
          <XBox
            sx={{
              minHeight: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Divider />
            <XBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <XTypography fontSize="14px" color="secondary" fontWeight="light">
                No datasets. Add one to get started.
              </XTypography>
              <XButton
                size="small"
                color="button"
                variant="gradient"
                onClick={handleCreateDatasetButton}
                sx={{ maxWidth: "121px" }}
              >
                <XBox component="img" src={LightPlusIcon} />

                <XTypography
                  variant="button"
                  color="white"
                  ml={0.1}
                  sx={{ whiteSpace: "nowrap", fontSize: "12px" }}
                >
                  Add Dataset
                </XTypography>
              </XButton>
            </XBox>
            <Divider />
          </XBox>
        </XBox>
      </Card>
    );
  }

  return (
    <Card height={"100%"} mt={10} pb={{ xs: 0, lg: 10 }}>
      <XBox sx={{ padding: "10px 20px" }}>
        <XButton
          variant="outlined"
          sx={{ padding: 0, minHeight: "20px", background: "transparent", border: "none" }}
          onClick={handleBackButton}
        >
          <XBox display="flex" gap="4px" alignItems="center">
            <XBox src={ChevronLeftIcon} component="img" />
            <XTypography color="xpblue" variant="button">
              Back
            </XTypography>
          </XBox>
        </XButton>
        <XBox display="flex" justifyContent="space-between" alignItems="center">
          <XBox>
            <XTypography fontSize="18px">Datasets</XTypography>
            <XTypography fontSize="18px" color="secondary" fontWeight="light">
              Select a dataset to optimise
            </XTypography>
          </XBox>

          <XButton
            size="small"
            color="button"
            variant="gradient"
            onClick={handleCreateDatasetButton}
            sx={{ maxWidth: "121px" }}
          >
            <XBox component="img" src={LightPlusIcon} />

            <XTypography
              variant="button"
              color="white"
              ml={0.1}
              sx={{ whiteSpace: "nowrap" }}
              fontSize="12px"
            >
              Add Dataset
            </XTypography>
          </XButton>
        </XBox>

        <XBox
          sx={{
            minHeight: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <DataTable
            table={{ rows: dataset, columns: columns }}
            entriesPerPage={{
              defaultValue: 5,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            handleRowClick={handleRowClick}
            // eventComponent={<ReportModal />}
          />
        </XBox>
      </XBox>
    </Card>
  );
};

DatasetBlock.propTypes = {
  activeStep: PropTypes.number,
  setDataset: PropTypes.func,
  setActiveStep: PropTypes.func,
  handleCreateDatasetButton: PropTypes.func,
  dataset: PropTypes.array,
  value: PropTypes.number,
  row: PropTypes.number,
};
