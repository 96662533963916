import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import XBox from "components/XBox";

import "assets/css/tooltip.css";

export const OptimisationMadeChart = ({ data }) => {
  const ref = useRef();
  const svgContainer = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(220);

  useEffect(() => {
    const container = svgContainer.current;

    const handleResize = (entries) => {
      for (let entry of entries) {
        const newWidth = entry.contentRect.width;
        setWidth(newWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Observe the container element
    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      // Disconnect the observer when the component unmounts
      resizeObserver.disconnect();
    };
  }, [svgContainer]);

  useEffect(() => {
    FlushChart();
    DrawChart();
  }, [data, width, height]);

  const FlushChart = () => {
    d3.select(ref.current).selectAll("*").remove();
  };

  const DrawChart = () => {
    const margin = { top: 20, right: 40, bottom: 60, left: 30 };
    const plotWidth = width - margin.left - margin.right;
    const plotHeight = height - margin.top - margin.bottom;

    const svg = d3
      .select(ref.current)
      .attr("width", plotWidth + margin.left + margin.right)
      .attr("height", plotHeight + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const x = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([0, plotWidth]);

    const y = d3
      .scaleBand()
      .range([0, height - margin.bottom])
      .domain(data.map((d) => d.name))
      .padding(0.5);

    // create horizontal grid lines
    svg
      .selectAll("line.horizontal-grid")
      .data(y.domain())
      .enter()
      .append("line")
      .attr("class", "horizontal-grid")
      .attr("x1", 0)
      .attr("x2", plotWidth)
      .attr("y1", (d) => y(d) + y.bandwidth() / 2)
      .attr("y2", (d) => y(d) + y.bandwidth() / 2)
      .attr("stroke", colors.light.main)
      .attr("stroke-width", "1px")
      .attr("stroke-dasharray", "5,5")
      .lower();

    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar probability-bar")
      .attr("y", (d) => y(d.name))
      .attr("height", y.bandwidth())
      .attr("x", 0)
      .attr("width", (d) => x(d.value))
      .attr("fill", "#172B4D")
      .attr("rx", 5)
      .attr("ry", 5);

    svg
      .append("g")
      .attr("transform", "translate(0," + (height - margin.bottom) + ")")
      .call(d3.axisBottom(x).tickSize(0));

    svg.append("g").call(d3.axisLeft(y).tickSize(0));

    svg.selectAll(".domain").style("stroke", "none");
  };
  return (
    <XBox sx={{ position: "relative" }} ref={svgContainer}>
      <div id="tooltip" className="tooltip">
        <div className="tooltip-framework">
          <span id="framework" />
        </div>
        <div className="tooltip-value">
          <span id="count" />%
        </div>
      </div>
      <svg ref={ref}></svg>
    </XBox>
  );
};

OptimisationMadeChart.propTypes = {
  data: PropTypes.array,
};
