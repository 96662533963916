// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// xplainable Dashboard components
import XBox from "components/XBox";

import TableSortIcon from "assets/images/icons/table-sort-icon.svg";
import { useXplainableController } from "context";

function DataTableHeadCell({ width, children, sorted, align, ...rest }) {
  const [controller] = useXplainableController();

  return (
    <XBox
      component="th"
      width={width}
      py={1.5}
      px={3}
      sx={({ borders: { borderWidth, borderColor } }) => ({
        borderBottom: `${borderWidth[1]} solid ${borderColor}`,
      })}
    >
      <XBox
        {...rest}
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        textAlign={align}
        sx={({ typography: { size, fontWeightBold } }) => ({
          color: "#AFAFAF",
          fontSize: size.sm,
          fontWeight: fontWeightBold,
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
        })}
      >
        {children}
        {sorted && (
          <XBox
            display="flex"
            flexDirection="column"
            bgColor={controller.darkMode ? "#1D1B1B" : "#F7F7F8"}
            sx={{ borderRadius: "4px", padding: "5px" }}
          >
            <XBox src={TableSortIcon} component="img" />
          </XBox>
        )}
      </XBox>
    </XBox>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;
