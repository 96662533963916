import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const usePreviewQuery = (dataset_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.PREVIEW, dataset_id],
    () => apiHandlers.dataset.getPreview(dataset_id),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
