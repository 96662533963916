import { useQuery } from "@tanstack/react-query";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";
import { apiHandlers } from "../apiHandlers";

export const useDatasetQuery = (activeWorkspace, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.DATASET, activeWorkspace],
    () => apiHandlers.dataset.getData(activeWorkspace),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
      enabled: !!activeWorkspace?.team_id,
    }
  );
};

export const useDatasetItemQuery = (logout, dataset_id) => {
  return useQuery(
    [QUERY_CONSTANTS.DATASET, dataset_id],
    () => apiHandlers.dataset.getItemData(dataset_id),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
