import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Switch } from "@mui/material";
import XSelect from "components/XSelect";
import XTypography from "components/XTypography";
import XBox from "components/XBox";

const DropdownContainer = ({ feature, featureProfile, handleChange, globalToggle, currentValue }) => {
  // Extract categories and filter out null categories
  const categories = featureProfile.map(profile => profile.category).filter(category => category != null);

  // Set initial state for selectedCategory
  const initialCategory = categories.length > 0
                          ? { value: categories[0], label: categories[0] }
                          : { value: '', label: '' };

  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [isEnabled, setIsEnabled] = useState(false);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);

    const selectedProfile = featureProfile.find(profile => profile.category === selectedOption.value);
    const score = selectedProfile ? selectedProfile.score : null;

    handleChange(feature, isEnabled, selectedOption.value, score);
  };

  const handleSwitchChange = (event) => {
    setIsEnabled(event.target.checked);
    const selectedProfile = featureProfile.find(profile => profile.category === selectedCategory.value);

    const score = selectedProfile ? selectedProfile.score : null;

    handleChange(feature, event.target.checked, selectedCategory.value, score);
  };

  useEffect(() => {
    setIsEnabled(globalToggle);
  }, [globalToggle]);

  useEffect(() => {
    if (currentValue != null && categories.includes(currentValue)) {
      // Only update if the current value has actually changed
      if (selectedCategory.value !== currentValue) {
        setSelectedCategory({ value: currentValue, label: currentValue });
      }
    }
  }, [currentValue, categories, selectedCategory.value]);

  return (
    <XBox
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 1,
        borderRadius: "10px",
        borderColor: "light",
        borderWidth: 1,
        my: 0.5,
      }}
    >
      <XTypography         
        color={!isEnabled ? "text" : "light"}
        fontSize="14px" 
        variant="h6" 
        gutterBottom sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {feature}
      </XTypography>
      <XBox sx={{ display: "flex", alignItems: "center", width: '100%' }}>
  <XSelect
    value={selectedCategory}
    onChange={handleCategoryChange}
    sx={{ width: '90%' }}  // 80% of the container width
    isDisabled={isEnabled}
    placeholder="Select Category"
    options={categories.map(category => ({
      value: category,
      label: category,
    }))}
  />
  <FormControlLabel
    control={<Switch checked={isEnabled} onChange={handleSwitchChange} color="primary" />}
    sx={{ width: '10%', marginLeft: 1 }}  // 20% of the container width
  />
</XBox>
    </XBox>
  );
};


DropdownContainer.propTypes = {
  feature: PropTypes.string.isRequired,
  featureProfile: PropTypes.any,
  firstLabel: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  globalToggle: PropTypes.bool.isRequired,
  currentValue: PropTypes.string,
};

export default DropdownContainer;
