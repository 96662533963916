import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import DeploymentContext from "./components/deployment-context";

// @mui material components
import { Switch, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// xplainable dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// xplainable dashboard base styles
import breakpoints from "assets/theme/base/breakpoints";

// Teams page components
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import SingleDeploymentOverview from "./components/overview";
import DeployKeys from "./components/DeployKeys";
import DeploymentConfig from "./components/configuration";

import { animateGradient } from "assets/theme/base/animations";
import { useModelsQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";
import { useToggleMutation } from "api/mutations";
import { Monitoring } from "./components/monitoring";

import ActiveDeploymentIcon from "assets/images/icons/deployments/active-deployment-icon.svg";
import UnActiveDeploymentIcon from "assets/images/icons/deployments/un-active-deployment-icon.svg";
import CollapsedWidthRightIcon from "assets/images/icons/collapsed-width-icon-right.svg";
import CollapsedWidthLeftIcon from "assets/images/icons/collapsed-width-icon-left.svg";
import colors from "assets/theme/base/colors";
import { useApp } from "hooks";

function Deployment() {
  const { setActiveTitle } = useApp();
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();
  const isFirstRender = useRef(true);
  const navigate = useNavigate();
  const [access, setAccess] = useState("creator");
  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;
  const location = useLocation();

  //Unpack the model_id using the url if route from location other than the card
  const { id: deployment_id } = useParams();
  const [modelState, setModelState] = useState({
    model_id: null,
    model_name: null,
    model_description: null,
    model_type: null,
    target_name: null,
    created: null,
    archived: false,
    active: false,
    user: null,
    active_version: null,
    deployed: false,
    active_deployment: null,
    contributors: [],
    versions: [],
    number_of_versions: 0,
  });

  const [toggle, setToggle] = useState(modelState?.active_deployment);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [collapsedWidth, setCollapsedWidth] = useState(false);

  const { data } = useModelsQuery(activeWorkspace, logout);
  const { deploymentToggleMutation } = useToggleMutation();

  //Check if the user has navigated to the page from the card or from the url
  useEffect(() => {
    if (location.state) {
      setModelState(location.state);
      return;
    }

    if (!data?.data) return;

    const filteredData = data?.data.filter(
      (model) => model?.deployment_id && model?.deployment_id.includes(deployment_id)
    )[0];

    setModelState(filteredData);
  }, [location.state, activeWorkspace?.organisation_id, activeWorkspace?.team_id, data]); // this effect hook runs whenever location.state changes

  useEffect(() => {
    setToggle(modelState?.active_deployment);
  }, [modelState]);
  // Unpack the mode values variables from modelState
  const {
    model_id,
    model_name,
    model_description,
    model_type,
    target_name,
    created,
    archived,
    active,
    user,
    active_version,
    deployed,
    active_deployment,
    contributors,
    versions,
    number_of_versions,
  } = modelState;

  useEffect(() => {
    if (!model_name) return;

    setActiveTitle(model_name);
  }, [model_name]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleToggleChange = async () => {
    const action = toggle ? "deactivate" : "activate";

    deploymentToggleMutation([deployment_id, action], {
      onSuccess: () => {
        console.log("Toggle Success");
        setToggle(!toggle);
      },
      onError: () => {
        console.log("Toggle Error");
      },
    });
  };

  const toggleCollapse = () => {
    setCollapsedWidth((prev) => !prev);
  };

  //Routing if the activeWorkspace changes
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // it's no longer the first render
      return;
    }
    navigate("/deployments");
  }, [activeWorkspace]);

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={model_name}
        description={model_description}
        icon={"ni ni-sound-wave"}
        width={"40%"}
        color={colors["xpblue"].main}
        rhsElement={
          access === "creator" ? (
            <XBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "12px",
                width: "100%",
              }}
            >
              <XBox display="flex" gap={1} alignItems="center">
                <XBox
                  display="flex"
                  alignItems="center"
                  gap={1}
                  px="12px"
                  py={1}
                  bgColor={
                    active_version
                      ? controller.darkMode
                        ? "xpblue"
                        : "xpblue"
                      : controller.darkMode
                      ? "#1D1B1B"
                      : "#F7F7F8"
                  }
                  sx={{ borderRadius: "24px" }}
                >
                  <XBox
                    component="img"
                    src={active_version ? ActiveDeploymentIcon : UnActiveDeploymentIcon}
                  />
                  <XTypography
                    sx={{ color: active_version ? "#ffffff" : "#AFAFAF" }}
                    fontSize="12px"
                  >
                    {active_version ? "Active" : "Inactive"}
                  </XTypography>
                </XBox>
                <XBox display="flex" flexDirection="column">
                  <XBox display="flex" alignItems="center" gap={1}>
                    <XTypography variant="h5" fontWeight="medium" color="dark" fontSize="24px">
                      {model_name}
                    </XTypography>
                  </XBox>
                  <XTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                      color: "#7C7C7C",
                    }}
                  >
                    {model_description}
                  </XTypography>
                </XBox>
              </XBox>
              <XBox
                width={"10%"}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                ml={2}
              >
                <XTypography variant="overline">Active</XTypography>
                <Switch
                  checked={toggle}
                  onChange={() => {
                    setToggle(!active);
                    handleToggleChange();
                  }}
                />
              </XBox>
            </XBox>
          ) : (
            <></>
          )
        }
      />

      <DeploymentContext.Provider
        value={{
          model_id,
          model_name,
          model_description,
          model_type,
          target_name,
          created,
          archived,
          active,
          user,
          active_version,
          deployed,
          deployment_id,
          active_deployment,
          contributors,
          versions,
          number_of_versions,
          toggle,
          collapsedWidth,
          setCollapsedWidth,
        }}
      >
        <XBox position="relative" mt={3}>
          <XBox display="flex" justifyContent="center" alignItems="center">
            <XBox width="100%">
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  sx={{
                    maxWidth: "500px",

                    "& .Mui-selected": {
                      color: `${colors["xpblue"].main} !important`,
                      fontWeight: "600",
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: `${colors["xpblue"].main} `,
                    },
                  }}
                >
                  <Tab label="Overview" sx={{ minWidth: 0 }} />
                  <Tab label="Deploy Keys" sx={{ minWidth: 0 }} />
                  <Tab label="Monitoring" sx={{ minWidth: 0 }} />
                  <Tab label="Configuration" sx={{ minWidth: 0 }} />
                </Tabs>
              </AppBar>
            </XBox>

            {tabValue === 0 && (
              <XBox>
                <XBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgColor={collapsedWidth ? "white" : "xpblue"}
                  variant="contained"
                  p="10px"
                  borderRadius="12px"
                  onClick={toggleCollapse}
                  sx={{
                    transition: "transform 300ms",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <XBox
                    component="img"
                    src={collapsedWidth ? CollapsedWidthLeftIcon : CollapsedWidthRightIcon}
                  />
                </XBox>
              </XBox>
            )}
          </XBox>
          {tabValue === 0 && (
            <SingleDeploymentOverview setTabValue={setTabValue} tabValue={tabValue} />
          )}
          {tabValue === 1 && <DeployKeys deploymentId={deployment_id} />}
          {tabValue === 2 && <Monitoring />}
          {tabValue === 3 && <DeploymentConfig />}
        </XBox>
      </DeploymentContext.Provider>
    </DashboardLayout>
  );
}

export default Deployment;
