import React, { useEffect, useRef, useState } from "react";

import XEditor from "components/XEditor";
import XBox from "components/XBox";

import { debounce } from "lodash";
import { useApp } from "hooks";

import PropTypes from "prop-types";
import XTypography from "components/XTypography";

export const Comments = ({ id, text }) => {
  const { activeStep, setCommentsValues, commentsValues } = useApp();

  const [editorValue, setEditorValue] = useState(text || "<div>Add your comments here…</div>");

  useEffect(() => {
    if (!text) return;
    setEditorValue(text);
  }, [text]);

  const debouncedSetEditorValue = useRef(
    debounce((value) => {
      setEditorValue(value);
    }, 300)
  ).current;

  useEffect(() => {
    setCommentsValues({ ...commentsValues, [id]: editorValue });
  }, [editorValue]);

  return (
    <XBox>
      <XTypography variant="button" fontSize="18px" px="15px">
        Comments
      </XTypography>
      <XEditor
        readOnly={activeStep !== 0 ? true : false}
        theme={"bubble"}
        onChange={(content) => debouncedSetEditorValue(content)}
        value={editorValue}
      />
    </XBox>
  );
};

Comments.propTypes = {
  commentsValues: PropTypes.string,
  setCommentsValues: PropTypes.func,
  id: PropTypes.string,
  text: PropTypes.string,
};
