import { Divider } from "@mui/material";
import { REPORT_COMPONENTS_KEYS } from "constants";
import { ColumnsBlock } from "../components";
import {
  BaseValue,
  BulletList,
  Commentary,
  CurrentOverview,
  Curves,
  Pipeline,
  ReportModelProfile,
  ReportRegressionChart,
  ReportResidualChart,
  ReportScenarioAnalysis,
  TextEditorTable,
  ThresholdOptimisation,
} from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components";
import { Monitoring } from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components/Monitoring";

import DataHealth from "layouts/models/model/components/preprocessing/components/health";
import ThresholdPlot from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components/ThresholdPlot";
import ConfusionMatrix from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components/ConfusionMatrix";
import NoteBlock from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components/NoteBlock";
import MetricsTable from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components/Metrics";
import Predict from "layouts/models/model/components/predict";

export const components = {
  [REPORT_COMPONENTS_KEYS.BINARY_OVERVIEW]: () => <ReportModelProfile />,
  [REPORT_COMPONENTS_KEYS.BATCH_PREDICT]: () => <Predict />,
  [REPORT_COMPONENTS_KEYS.WATERFALL_PLOT]: () => <ReportScenarioAnalysis />,
  [REPORT_COMPONENTS_KEYS.ARTICLE]: () => <Commentary />,
  [REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT]: (threshold, setThreshold) => (
    <ThresholdPlot threshold={threshold} setThreshold={setThreshold} />
  ),
  [REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX]: (threshold, setThreshold, setMetrics, metrics) => (
    <ConfusionMatrix
      threshold={threshold}
      setThreshold={setThreshold}
      setMetrics={setMetrics}
      metrics={metrics}
    />
  ),
  [REPORT_COMPONENTS_KEYS.METRICS]: (threshold, setThreshold, setMetrics, metrics) => (
    <MetricsTable
      threshold={threshold}
      setThreshold={setThreshold}
      setMetrics={setMetrics}
      metrics={metrics}
    />
  ),
  [REPORT_COMPONENTS_KEYS.PR_CURVE_ROC_CURVE]: () => <Curves />,
  //   [REPORT_COMPONENTS_KEYS.COMMENTS]: (text) => <Comments text={text} />,
  //   [REPORT_COMPONENTS_KEYS.LINE_CHART]: () => <Typography>Line Chart</Typography>,
  [REPORT_COMPONENTS_KEYS.HEALTH]: () => <DataHealth />,
  [REPORT_COMPONENTS_KEYS.PIPELINE]: () => <Pipeline />,
  [REPORT_COMPONENTS_KEYS.CURRENT_OVERVIEW]: () => <CurrentOverview />,
  [REPORT_COMPONENTS_KEYS.BASE_VALUE]: () => <BaseValue />,
  [REPORT_COMPONENTS_KEYS.REGRESSION_CHART]: () => <ReportRegressionChart />,
  [REPORT_COMPONENTS_KEYS.RESIDUAL_CHART]: () => <ReportResidualChart />,
  [REPORT_COMPONENTS_KEYS.THRESHOLD_OPTIMISATION]: () => <ThresholdOptimisation />,
  [REPORT_COMPONENTS_KEYS.THRESHOLD_OPTIMISATION]: () => <ThresholdOptimisation />,
  [REPORT_COMPONENTS_KEYS.DIVIDER]: () => <Divider />,
  [REPORT_COMPONENTS_KEYS.COLUMNS]: (
    blocks,
    setBlocks,
    id,
    threshold,
    setThreshold,
    setMetrics,
    metrics
  ) => (
    <ColumnsBlock
      blocks={blocks}
      setBlocks={setBlocks}
      id={id}
      threshold={threshold}
      setThreshold={setThreshold}
      setMetrics={setMetrics}
      metrics={metrics}
    />
  ),
  //   [REPORT_COMPONENTS_KEYS.TABLE]: (blocks, setBlocks, id, tableData, isEditable) => (
  //     <TextEditorTable
  //       blocks={blocks}
  //       setBlocks={setBlocks}
  //       id={id}
  //       threshold={threshold}
  //       setThreshold={setThreshold}
  //     />
  //   ),
  [REPORT_COMPONENTS_KEYS.TABLE]: (blocks, setBlocks, id, tableData, isEditable) => (
    <TextEditorTable blocks={blocks} id={id} setBlocks={setBlocks} isEditable={isEditable} />
  ),
  [REPORT_COMPONENTS_KEYS.BULLET_LIST]: (blocks, setBlocks, id, tableData, isEditable) => (
    <BulletList blocks={blocks} id={id} setBlocks={setBlocks} isEditable={isEditable} />
  ),
  [REPORT_COMPONENTS_KEYS.NOTE]: (blocks, setBlocks, id, tableData, isEditable) => (
    <NoteBlock blocks={blocks} id={id} setBlocks={setBlocks} isEditable={isEditable} />
    //   [REPORT_COMPONENTS_KEYS.BULLET_LIST]: (blocks, setBlocks, id, tableData, isEditable) => (
    //     <BulletList
    //       blocks={blocks}
    //       id={id}
    //       setBlocks={setBlocks}
    //       tableData={tableData}
    //       isEditable={isEditable}
    //     />
  ),
  [REPORT_COMPONENTS_KEYS.MONITORING]: (blocks, setBlocks, id, tableData, isEditable) => (
    <Monitoring />
  ),
};
