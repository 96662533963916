import { useApiKey } from "components/Authorisation/ApiKeyContext";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

// @mui material components
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Pagination,
  PaginationItem,
  Switch,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

// xplainable Dashboard components
import XBox from "components/XBox";
import XButton from "components/XButton";
import XInput from "components/XInput";
import XSelect from "components/XSelect";
import XTypography from "components/XTypography";

// xplainable dashboard example components
import TableCellComponent from "layouts/models/all-models/components/overview/components/TableCellComponent";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import Members from "shared/Avatars/Members";
import ReuseableCard from "shared/Cards/ReuseableCard";
import ProgressLineChart from "shared/Charts/ProgressLineChart";
import PricingModal from "shared/Modals/PricingModal";
import DataTable from "shared/Tables/DataTable";

import { useAuth0 } from "@auth0/auth0-react";
import { useCardsMutation, useDeploymentsMutation } from "api/mutations";
import { 
  useDeploymentsQuery, 
  useModelsQuery, 
  useActiveDeploymentKeysQuery } from "api/query";
import { XImg } from "components/XImg";
import { XViewToggle } from "components/XViewToggle";
import { useXplainableController } from "context";
import { useNavigate } from "react-router-dom";
import { getTimeDifference } from "shared/Functions/Date";
import { Select } from "shared/Timeline/ReuseableTimeline/components/Select";

import BlueArrowIcon from "assets/images/icons/blue-arrow-icon.svg";
import SearchIcon from "assets/images/search-icon.svg";
import colors from "assets/theme/base/colors";
import MiniStatisticsCard from "shared/Cards/MiniStatisticsCard";

import { ReactComponent as DarkDeploymentIcon } from "assets/images/icons/deployments/dark-deployment-icon.svg";
import { ReactComponent as DarkArrowLeft } from "assets/images/icons/pagination/dark-arrow-left.svg";
import { ReactComponent as DarkArrowRight } from "assets/images/icons/pagination/dark-arrow-right.svg";
import { PAGINATION } from "constants";
function DeploymentsOverview() {
  const [controller] = useXplainableController();

  const { viewState, activeWorkspace } = useApiKey();
  const { editCardMutation } = useCardsMutation();
  const { logout } = useAuth0();

  const [addingDeployment, setAddingDeployment] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [dropdowns, setDropdowns] = useState([]);
  const [projects, setProjects] = useState([]);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [activeCount, setActiveCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dropdownMenu = useDropdownMenu();
  const navigate = useNavigate();

  const [tableRows, setTableRows] = useState([]);
  const [switchValue, setSwitchValue] = useState([]);
  const [isCardViewEnable, setIsCardViewEnable] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedPartition, setSelectedPartition] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredDeployments, setFilteredDeployments] = useState(null);

  const { data: modelsData } = useModelsQuery(activeWorkspace, logout);
  const { data, isLoading: isLoadingDeployments } = useDeploymentsQuery(activeWorkspace, dropdowns, isCardViewEnable, logout);
  const { data: activeKeysData } = useActiveDeploymentKeysQuery(activeWorkspace);
  const {
    deleteDeploymentsMutation,
    handleArchiveDeploymentsMutation,
    handleDeployDeploymentMutation,
  } = useDeploymentsMutation();

  const handleSwitchChange = (foundSwitch) => {
    const action = foundSwitch?.active_deployment ? "deactivate" : "activate";
    const endpointType = "deployments";
    const id = foundSwitch?.deployment_id;

    editCardMutation([endpointType, id, action], {
      onSuccess: () => {
        const updatedArray = switchValue?.map((item) => {
          if (item?.model_id === foundSwitch?.model_id) {
            return { ...item, active_deployment: !foundSwitch?.active_deployment };
          }
          return item;
        });
        setSwitchValue(updatedArray);
      },

      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    });
  };

  useEffect(() => {
    setTableRows(projects);
  }, [projects]);

  useEffect(() => {
    if (!isCardViewEnable) {
      setAddingDeployment(true);
    } else {
      setAddingDeployment(false);
    }
  }, [isCardViewEnable]);

  useEffect(() => {
    if (!data) return;

    const toggleArray = data?.data?.map((item, index) => ({
      model_id: item?.model_id,
      active_deployment: item?.active_deployment,
      deployment_id: item?.deployment_id,
    }));

    // Toggle the array values
    setSwitchValue(toggleArray);

    // Return the available deployments
    const currentDeployments = modelsData?.data?.filter((x) => x?.deployment_id !== null);

    setProjects(
      currentDeployments?.map((project) => {
        return { ...project, dropdown: dropdownMenu };
      })
    );
  }, [data, modelsData]);

  useMemo(() => {
    viewState === "creator" ? setSelectedStatus("all") : setSelectedStatus(true);
  }, [viewState]);

  useEffect(() => {
    if (!projects) return;

    const filteredTableRows = projects?.filter((item) => {
      const statusCondition = selectedStatus === "all" || item?.active_deployment === selectedStatus;
      const searchQueryLower = searchQuery?.toLowerCase();
      const textConditions = item?.model_name?.toLowerCase()?.includes(searchQueryLower);

      return statusCondition && textConditions;
    });

    setFilteredDeployments(filteredTableRows);
  }, [searchQuery, selectedStatus, projects]);

  const handleDeleteProject = async (deployment_id) => {
    // Logic to toggle the loading state
    setAddingDeployment(false);

    deleteDeploymentsMutation([deployment_id], {
      onSuccess: () => {
        const filteredProjects = projects?.filter(
          (project) => project?.deployment_id !== deployment_id
        );
        setProjects(filteredProjects);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleArchiveProject = async (model_id) => {
    handleArchiveDeploymentsMutation([model_id], {
      onSuccess: () => {
        console.error(`The model id ${model_id} was archived..`);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleCreateDeployment = (newDeployment) => {
    handleDeployDeploymentMutation(
      [newDeployment?.selected_version],
      {
        onSuccess: (data) => {
          console.log("The deployment response is is ", data)
          const newProject = {
            model_id: newDeployment?.model_id,
            model_name: newDeployment?.model_name,
            model_description: newDeployment?.model_description,
            model_type: newDeployment?.model_type,
            target_name: newDeployment?.target_name,
            created: newDeployment?.created,
            created_by: newDeployment?.created_by,
            archived: newDeployment?.archived,
            active: newDeployment?.active,
            active_version: newDeployment?.selected_version,
            contributors: newDeployment?.contributors,
            versions: newDeployment?.versions,
            selected_version: newDeployment?.selected_version,
            selected_partition: newDeployment?.selected_partition,
            deployment_id: data?.data?.deployment_id,
          };

          // Update the projects state with the new project added
          setProjects([...projects, newProject]);
          setAddingDeployment(!addingDeployment);
          handleClose();
        },
        onError: () => {
          console.log("Error test");

          setAddingDeployment(false);
          // setShowPricingModal(true);
        },
      }
    );
  };

  // Handle Filter status
  const handleStatusFilterChange = (status) => {
    setSelectedStatus(status?.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleRowClick = (e) => {
    if (!e?.row?.original) {
      return;
    }

    const {
      model_id,
      model_name,
      model_description,
      model_type,
      target_name,
      created,
      archived,
      active,
      user,
      active_version,
      deployed,
      deployment_id,
      active_deployment,
      contributors,
      versions,
      number_of_versions,
    } = e?.row?.original;

    const params = {
      model_id,
      model_name,
      model_description,
      model_type,
      target_name,
      created,
      archived,
      active,
      user,
      active_version,
      deployed,
      deployment_id,
      active_deployment,
      contributors,
      versions,
      number_of_versions,
    };
    navigate(`/deployments/${deployment_id}`, {
      state: params,
    });
  };

  const renderProjects = () => {
    const type = "deployments";

    if (isLoadingDeployments) {
      return (
        <XBox 
          display="flex" 
          flexWrap="wrap" 
          sx={{ justifyContent: { xs: "center", lg: "flex-start" } }}
        >
          {[...Array(2)].map((_, index) => (
            <XBox
              key={index}
              m={1}
              sx={{
                width: { xs: "100%", sm: "540px" },
                opacity: 0,
                transform: "translateY(20px)",
                animation: "slide-up 0.5s forwards",
                animationDelay: `${index * 0.03}s`,
                "@keyframes slide-up": {
                  "100%": {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                },
              }}
            >
              <ReuseableCard
                type="deployments"
                isLoading={true}
              />
            </XBox>
          ))}
        </XBox>
      );
    }

    const columns = [
      {
        Header: "",
        width: "5%",
        id: "function",
        Cell: (props) => (
          <TableCellComponent
            {...props}
            handleDeleteProject={handleDeleteProject}
            model_id={props?.row?.original?.model_id}
            handleArchiveProject={handleArchiveProject}
            deployed={props?.row?.original?.deployed}
          />
        ),
      },
      {
        Header: "Model name",
        accessor: "model_name",
        id: "model_name",

        Cell: (props) => {
          if (!props?.row?.original?.model_name) {
            return null;
          }

          return (
            <XBox display="flex" gap="0 10px" alignItems="center">
              <XImg>
                <DarkDeploymentIcon />
              </XImg>
              <XTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
                sx={{
                  maxHeight: "30px",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                }}
              >
                {props?.row?.original?.model_name}
              </XTypography>
            </XBox>
          );
        },
      },
      {
        Header: "Role",
        accessor: "number_of_versions",
        Cell: (props) => {
          const foundSwitch = switchValue?.find(
            (item) => item?.model_id === props?.row?.original?.model_id
          );

          if (!props?.row?.original?.number_of_versions) {
            return null;
          }

          return (
            <>
              <XTypography variant="button" fontWeight="light">
                {"Version " + props?.row?.original?.number_of_versions}
              </XTypography>
            </>
          );
        },
      },
      {
        Header: "Contributors",
        accessor: "contributors",
        Cell: ({ value }) => (value ? <Members members={value} /> : null),
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: (props) => {
          const foundSwitch = switchValue?.find(
            (item) => item?.model_id === props?.row?.original?.model_id
          );

          return (
            <XTypography
              variant="button"
              fontWeight="light"
            >
              {props?.row?.original?.created
                ? getTimeDifference(props?.row?.original?.created, true)
                : null}
            </XTypography>
          );
        },
      },
      {
        Header: "Activate",
        width: "5%",
        accessor: "model_id",
        id: "model_id",
        Cell: ({ value }) => {
          const foundSwitch = switchValue?.find((item) => item?.model_id === value);

          return (
            <Switch
              checked={foundSwitch?.active_deployment}
              onChange={() => handleSwitchChange(foundSwitch)}
            />
          );
        },
      },
      {
        Header: "",
        width: "5%",
        accessor: "",
        id: "model_transition",
        Cell: (props) => {
          const foundSwitch = switchValue?.find(
            (item) => item?.model_id === props?.row?.original?.model_id
          );

          return (
            <XBox
              sx={{ cursor: "pointer" }}
              onClick={() => handleRowClick(props)}
              display="flex"
              gap={1}
            >
              <XTypography
                variant="button"
                textTransform="capitalize"
                fontWeight="medium"
                color="xpblue"
              >
                Deployment Insights
              </XTypography>

              <XBox component="img" src={BlueArrowIcon} />
            </XBox>
          );
        },
      },
    ];

    const filteredTableRows = tableRows?.filter((item) => {
      const statusCondition = selectedStatus === "all" || item?.active_deployment === selectedStatus;
      const searchQueryLower = searchQuery?.toLowerCase();
      const textConditions = item?.model_name?.toLowerCase()?.includes(searchQueryLower);

      return statusCondition && textConditions;
    });

    const startIndex = (currentPage - 1) * PAGINATION.DEPLOYMENTS_ITEMS_PER_PAGE;
    const endIndex = startIndex + PAGINATION.DEPLOYMENTS_ITEMS_PER_PAGE;
    const currentDeployments = filteredTableRows?.slice(startIndex, endIndex);

    return (
      <>
        {isCardViewEnable &&
          currentDeployments?.map((project, index) => (
            <ProjectCard
              key={index}
              index={index}
              project={project}
              handleDeleteProject={handleDeleteProject}
              handleArchiveProject={handleArchiveProject}
              selectedStatus={selectedStatus}
              updateActiveCount={updateActiveCount}
              searchQuery={searchQuery}
            />
          ))}

        {!isCardViewEnable && (
          <Card
            sx={{
              width: "100%",
              position: "relative",
              zIndex: 1,
              overflow: "visible",
            }}
          >
            <DataTable
              table={{ rows: filteredTableRows, columns: columns }}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              switchValue={switchValue}
              type={type}
            />
          </Card>
        )}
      </>
    );
  };

  // Function to pass to ProjectCard to update active count
  const updateActiveCount = (id, active) => {
    const updatedProjects = projects?.map((project) => {
      if (project?.deployment_id === id) {
        return { ...project, active_deployment: active };
      }
      return project;
    });
    setProjects(updatedProjects);
  };

  useEffect(() => {
    const activeProjects = projects?.filter((p) => p?.active_deployment)?.length;
    setActiveCount(activeProjects);
  }, [projects]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModelChange = (event) => {
    const modelId = event?.value;
    setSelectedPartition(null);
    setSelectedModel(event);
    const foundModel = models?.find((model) => model?.model_id === modelId);
    setVersions(foundModel ? foundModel?.versions : []);
  };

  const handleVersionChange = (event) => {
    setSelectedVersion(event);
  };

  const handleCreateNewDeploymentButton = async () => {
    const foundModel = models?.find((model) => model?.model_id === selectedModel?.value);
    if (foundModel && selectedVersion) {
      const newDeployment = {
        model_id: foundModel?.model_id,
        model_name: foundModel?.model_name,
        model_description: foundModel?.model_description,
        model_type: foundModel?.model_type,
        target_name: foundModel?.target_name,
        created: foundModel?.created,
        created_by: foundModel?.created_by,
        archived: foundModel?.archived,
        active: foundModel?.active,
        active_version: foundModel?.active_version,
        contributors: foundModel?.contributors,
        versions: foundModel?.versions,
        selected_version: selectedVersion?.value,
      };
      handleCreateDeployment(newDeployment);
    }
  };

  useEffect(() => {
    if (!modelsData) return;

    const availableDeployments = modelsData?.data?.filter((x) => x?.deployed !== true);

    // Set the available deployments
    setModels(availableDeployments);
  }, [modelsData]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: controller?.darkMode ? colors?.background?.dark : colors?.background?.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <XTypography fontSize="16px" fontWeight="bold">
              New Deployment
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size?.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller?.darkMode ? white?.main : dark?.main,
                stroke: controller?.darkMode ? white?.main : dark?.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>
          <XBox display="flex" flexDirection="column" gap={2}>
            <XBox display="flex" flexDirection="column" gap={0.5}>
              <XTypography variant="overline">Models</XTypography>
              <XSelect
                value={selectedModel}
                onChange={handleModelChange}
                fullWidth
                placeholder="Select Model"
                options={models?.map((model) => ({
                  value: model?.model_id,
                  label: model?.model_name,
                }))}
              />
            </XBox>
            <XBox display="flex" flexDirection="column" gap={0.5}>
              <XTypography variant="overline">Version</XTypography>
              <XSelect
                value={selectedVersion}
                onChange={handleVersionChange}
                fullWidth
                placeholder="Select Version"
                options={versions?.map((version) => ({
                  value: version?.version_id,
                  label: version?.version_number,
                }))}
              />
            </XBox>
            <XBox display="flex" flexDirection="column" gap={0.5}>
              <XTypography variant="overline">Location</XTypography>
              <XSelect
                value={selectedPartition}
                fullWidth
                isDisabled={true}
                placeholder="Sydney"
              />
            </XBox>
            <XButton
              variant="gradient"
              color="button"
              sx={{ alignSelf: "end" }}
              onClick={handleCreateNewDeploymentButton}
            >
              Create
            </XButton>
          </XBox>
        </XBox>
      </Modal>
      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} lg={9}>
          <XBox>
            <ProgressLineChart
              icon="access_time"
              title="Total API Calls"
              color={"xpblue"}
              height={"160px"}
              event="inference_server_predict"
              team_id={activeWorkspace?.team_id}
            />
          </XBox>
        </Grid>
        <Grid item xs={12} lg={3} display="flex">
          <XBox display="flex" flexDirection="column" gap={4} width="100%">
            <MiniStatisticsCard
              title={{ text: "Active Deployments", fontWeight: "medium" }}
              count={`${activeCount}/${projects?.length}`}
              color="xpblue"
            />

            <MiniStatisticsCard
              title={{ text: "Active Keys", fontWeight: "medium" }}
              count={activeKeysData?.data}
              color="xpblue"
            />
          </XBox>
        </Grid>
      </Grid>

      <XBox pt={3} height={"100%"} pb={{ xs: 0, lg: 24 }}>
        <Grid container sx={{ width: "100%" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <XBox mb={2}>
              <XTypography variant="h5" fontSize="18px">
                All Deployments
              </XTypography>
            </XBox>
          </Grid>

          <XBox display="flex" justifyContent="space-between" width="100%">
            <XBox display="flex" gap={2} justifyContent="space-between" width="550px">
              <XInput
                sx={{ width: "100%" }}
                placeholder="Search Deployments..."
                startAdornment={<XBox src={SearchIcon} component="img" />}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e?.target?.value)}
              />

              {viewState === "creator" && (
                <XBox position="relative">
                  <Select
                    color="ffffff"
                    darkColor="262525"
                    selectOptions={[
                      { value: "all", label: "All" },
                      { value: true, label: "Active Deployment" },
                      { value: false, label: "Inactive Deployment" },
                    ]}
                    handleDropdownItemClick={handleStatusFilterChange}
                  />
                </XBox>
              )}
            </XBox>

            <XBox display="flex" gap={2}>
              {viewState === "creator" && (
                <XButton variant="gradient" color="button" onClick={() => setIsModalOpen(true)}>
                  New Deployment
                </XButton>
              )}
              <XViewToggle
                color="xpblue"
                isCardViewEnable={isCardViewEnable}
                setIsCardViewEnable={setIsCardViewEnable}
              />
            </XBox>
          </XBox>
        </Grid>

        <XBox mt={{ xs: 1, lg: 3 }} mb={1}>
          <XBox
            display="flex"
            flexWrap="wrap"
            gap="15px"
            sx={{ justifyContent: { xs: "center", lg: "flex-start" } }}
          >
            {renderProjects()}

            {showPricingModal && (
              <PricingModal open={showPricingModal} onClose={() => setShowPricingModal(false)} />
            )}
          </XBox>
          {filteredDeployments && filteredDeployments?.length > 0 && (
            <Grid item xs={12} mt={3}>
              <Pagination
                count={Math.ceil(
                  filteredDeployments?.length / PAGINATION.DEPLOYMENTS_ITEMS_PER_PAGE
                )}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                sx={{
                  alignSelf: "end",
                  "& .Mui-selected": {
                    background: "linear-gradient(to right, #E14086, #0080EA)",
                    color: "white !important",
                  },
                }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    components={{
                      next: () => (
                        <XBox display="flex" sx={{ borderRadius: "4px" }} p="4px" bgColor="#F7F7F8">
                          <XImg lightColor="black">
                            <DarkArrowRight />
                          </XImg>
                        </XBox>
                      ),
                      previous: () => (
                        <XBox display="flex" sx={{ borderRadius: "4px" }} p="4px" bgColor="#F7F7F8">
                          <XImg lightColor="black">
                            <DarkArrowLeft />
                          </XImg>
                        </XBox>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          )}
        </XBox>
      </XBox>
    </>
  );
}

export default DeploymentsOverview;

export function ProjectCard({
  project,
  handleDeleteProject,
  handleArchiveProject,
  selectedStatus,
  index,
  updateActiveCount,
  searchQuery,
}) {
  const menuProps = useDropdownMenu(true);
  const theme = useTheme();
  const [controller] = useXplainableController();

  // Render the menu and match it to the current theme state
  const renderMenu = (state, close, model_id, active) => {
    const menuBackground = active ? theme?.palette?.dark?.main : theme?.palette?.white;
    const menuTextColor = active ? theme?.palette?.white : theme?.palette?.text?.primary;

    return (
      <Menu
        anchorEl={state}
        open={Boolean(state)}
        onClose={close}
        keepMounted
        PaperProps={{
          style: { backgroundColor: menuBackground },
        }}
      >
        <MenuItem
          onClick={() => {
            handleArchiveProject(project?.model_id);
            close();
          }}
          style={{
            color: controller?.darkMode ? "white" : "dark",
          }}
        >
          Archive
        </MenuItem>
        <MenuItem
          onClick={close}
          style={{
            color: controller?.darkMode ? "white" : "dark",
          }}
        >
          Unarchive
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteProject(project?.deployment_id);
            close();
          }}
          style={{
            color: "#EA2C4C",
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const searchQueryLower = searchQuery?.toLowerCase();
  const textConditions = project?.model_name?.toLowerCase()?.includes(searchQueryLower);

  if (
    (selectedStatus === "all" || project?.active_deployment === selectedStatus) &&
    textConditions
  ) {
    return (
      <XBox
        sx={{
          width: { xs: "100%", sm: "530px" },
          opacity: 0,
          transform: "translateY(20px)",
          animation: "slide-up 0.5s forwards",
          animationDelay: `${index * 0.03}s`,
          "@keyframes slide-up": {
            "100%": {
              opacity: 1,
              transform: "translateY(0)",
            },
          },
        }}
      >
        <ReuseableCard
          type={"deployment"}
          color="xpblue"
          dropdown={{
            action: () => menuProps?.openMenu(),
            menu: renderMenu(
              menuProps?.menu,
              () => menuProps?.closeMenu(),
              project?.model_id,
              project?.active
            ),
          }}
          onClick={menuProps?.openOnClick ? menuProps?.openMenu : undefined}
          project={project}
          updateActiveCount={updateActiveCount}
        />
      </XBox>
    );
  }
  return null;
}

ProjectCard.propTypes = {
  project: PropTypes.object,
  index: PropTypes.number,
  selectedStatus: PropTypes.string,
  handleDeleteProject: PropTypes.func,
  handleArchiveProject: PropTypes.func,
  updateActiveCount: PropTypes.func,
  searchQuery: PropTypes.string,
};

export const useDropdownMenu = (openOnClick = false) => {
  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  return { menu, openMenu, closeMenu, openOnClick };
};

export const NewDeploymentForm = ({ onCreateDeployment, setAddingDeployment }) => {
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedPartition, setSelectedPartition] = useState(null);

  const { data } = useModelsQuery(activeWorkspace, logout);

  const handleModelChange = (event) => {
    const modelId = event.value;
    setSelectedPartition(null);
    setSelectedModel(event);
    const foundModel = models.find((model) => model.model_id === modelId);
    setVersions(foundModel ? foundModel.versions : []);
  };

  const handleVersionChange = (event) => {
    setSelectedVersion(event);
  };

  useEffect(() => {
    if (!data) return;
    const availableDeployments = data.data.filter((x) => x.deployed !== true);

    //Set the available deployements
    setModels(availableDeployments);
  }, [data]);

  return (
    <Card
      sx={{
        position: "relative",
        zIndex: 1,
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100%",
        py: 2,
        px: 5,
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
        onClick={() => {
          setAddingDeployment(false);
        }}
      >
        <CloseIcon />
      </IconButton>
      <XTypography variant="h6" align="center">
        New Deployment
      </XTypography>
      <XTypography variant="overline">Model</XTypography>
      <XSelect
        value={selectedModel}
        onChange={handleModelChange}
        fullWidth
        placeholder="Select Model"
        options={models.map((model) => ({
          value: model.model_id,
          label: model.model_name,
        }))}
      />
      <XTypography variant="overline">Version</XTypography>
      <XSelect
        value={selectedVersion}
        onChange={handleVersionChange}
        fullWidth
        placeholder="Select Version"
        options={versions.map((version) => ({
          value: version.version_id,
          label: version.version_number,
        }))}
      />
      <XTypography variant="overline">Location</XTypography>
      <XSelect
        value={selectedPartition}
        // onChange={handlePartitionChange}
        fullWidth
        isDisabled={true}
        placeholder="Sydney"
        // options={partitions.map((partition) => ({
        // value: partition.partition_id,
        // label: partition.partition_value,
        // }))}
      />
      <XButton
        variant="contained"
        color="primary"
        onClick={async () => {
          const foundModel = models.find((model) => model.model_id === selectedModel.value);
          console.log("The found model is ", foundModel);
          if (foundModel && selectedVersion) {
            const newDeployment = {
              model_id: foundModel.model_id,
              model_name: foundModel.model_name,
              model_description: foundModel.model_description,
              model_type: foundModel.model_type,
              target_name: foundModel.target_name,
              created: foundModel.created,
              created_by: foundModel.created_by,
              archived: foundModel.archived,
              active: foundModel.active,
              active_version: foundModel.active_version,
              contributors: foundModel.contributors,
              versions: foundModel.versions,
              selected_version: selectedVersion.value,
            };
            onCreateDeployment(newDeployment);
          }
        }}
      >
        Create
      </XButton>
    </Card>
  );
};

// Typechecking props for the Feature Chart
NewDeploymentForm.propTypes = {
  onCreateDeployment: PropTypes.func,
  setAddingDeployment: PropTypes.func,
  projects: PropTypes.object,
  isCardViewEnable: PropTypes.bool,
  loading: PropTypes.bool,
};

DeploymentsOverview.propTypes = {
  value: PropTypes.number,
  row: PropTypes.number,
};
