import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useTeamsMutation = () => {
  const { mutate: createTeamMutation } = useMutation((params) =>
    apiHandlers.teams.createTeam(...params)
  );

  const { mutate: deleteTeamMutation } = useMutation((params) =>
    apiHandlers.teams.deleteTeam(...params)
  );

  return {
    createTeamMutation,
    deleteTeamMutation,
  };
};
