

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";

function DefaultPricingCard({ title, price, specifications, action }) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <XBox key={label} display="flex" alignItems="center" p={1}>
      <XBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="50%"
        shadow="md"
        bgColor={includes ? "success" : "secondary"}
        variant="gradient"
        mr={2}
      >
        <XTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
          <Icon sx={{ fontWeight: "bold" }}>{includes ? "done" : "remove"}</Icon>
        </XTypography>
      </XBox>
      <XTypography variant="body2" color="text">
        {label}
      </XTypography>
    </XBox>
  ));

  return (
    <Card>
      <XBox pt={3} pb={2} px={2} textAlign="center">
        <XTypography variant="body2" color="dark" textTransform="uppercase" fontWeight="medium">
          {title}
        </XTypography>
        <XBox my={1}>
          <XTypography variant="h1">
            <XTypography display="inline" component="small" variant="h2">
              {price.currency}
            </XTypography>
            {price.value}
          </XTypography>
        </XBox>
      </XBox>
      <XBox pb={3} px={3}>
        {renderSpecifications}
        {action.type === "internal" ? (
          <XBox mt={3}>
            <XButton component={Link} to={action.route} color={action.color} fullWidth>
              {action.label}&nbsp;
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </XButton>
          </XBox>
        ) : (
          <XBox mt={3}>
            <XButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              color={action.color}
              fullWidth
            >
              {action.label}&nbsp;
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </XButton>
          </XBox>
        )}
      </XBox>
    </Card>
  );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
