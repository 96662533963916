import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useDeploymentKeysQuery = (deploymentId) => {
  return useQuery(
    [QUERY_CONSTANTS.DEPLOYMENT_KEYS, deploymentId],
    () => apiHandlers.deployKeys.getDeployKeys(deploymentId),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};

export const useActiveDeploymentKeysQuery = (activeWorkspace) => {
  return useQuery(
    [QUERY_CONSTANTS.DEPLOYMENT_KEYS, activeWorkspace],
    () => apiHandlers.deployKeys.getActiveDeployKeys(activeWorkspace),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
