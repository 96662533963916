import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useDatasetDownloadQuery = (dataset_id, logout) => {
    return useQuery(
      [QUERY_CONSTANTS.DATASET, dataset_id],
      () => apiHandlers.dataset.downloadDataset(dataset_id),
      {
        enabled: !!dataset_id,
      },
      {
        onError: (err) => {
          handleTokenExpired(err, logout);
        },
      }
    );
  };