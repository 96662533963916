// usePreprocessorMutations.js
import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const usePreprocessorMutations = () => {
  const { mutate: checkSignatureMutation } = useMutation((params) =>
    apiHandlers.preprocessors.checkSignature(...params)
  );

  const { mutate: linkPreprocessorMutation } = useMutation((params) =>
   apiHandlers.preprocessors.linkPreprocessor(...params)
  );

  const { mutate: unlinkPreprocessorMutation } = useMutation((params) =>
    apiHandlers.preprocessors.unlinkPreprocessor(...params)
   );

  return {
    checkSignatureMutation,
    linkPreprocessorMutation,
    unlinkPreprocessorMutation
  };
};