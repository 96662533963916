import PropTypes from "prop-types";
import React from "react";

import { Box } from "@mui/material";
import { TableItem } from "../TableItem";

export const TableChart = ({ featureData, isSorted }) => {
  const data =
    isSorted && featureData
      ? Object.values(featureData).sort((a, b) => a.value - b.value)
      : Object.values(featureData);

  return (
    <Box sx={{ maxHeight: "600px", overflow: "scroll", padding: "1rem", marginTop: "1rem" }}>
      {data && data.map((item, index) => <TableItem key={index} item={item} />)}
    </Box>
  );
};

TableChart.propTypes = {
  featureData: PropTypes.object,
  isSorted: PropTypes.bool,
};
