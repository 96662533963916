import { useEffect, useState } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";

// xplainable dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Pages layout components
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import Dashboard from "./components/dashboard";
import DashboardSettings from "./components/settings";

import { animateGradient } from "assets/theme/base/animations";
import { useLocation } from "react-router-dom";

function Home() {
  const [tabValue, setTabValue] = useState(0);
  const { user } = useApiKey();
  const [controller, dispatch] = useXplainableController();
  const { globalImage, globalColor } = controller;

  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      setTabValue(location?.state.tabValue);
    }
  }, [location]);

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={user ? user.given_name + " " + user.family_name : "No name set"}
        description={user ? user.position : "No position set"}
        width={"50%"}
        user={user ? user.image : ""}
        tabColor="#DE4188"
        tabs={[
          {
            label: "Dashboard",
            iconClassName: "ni ni-app",
          },
          {
            label: "Account Settings",
            iconClassName: "ni ni-settings-gear-65",
          },
        ]}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />

      {tabValue === 0 && <Dashboard setTabValue={setTabValue} />}
      {tabValue === 1 && <DashboardSettings />}
    </DashboardLayout>
  );
}

export default Home;
