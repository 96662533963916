import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import { Saturation, Hue, EditableInput } from "react-color/lib/components/common";

import XTypography from "components/XTypography";
import XBox from "components/XBox";

import tinycolor2 from "tinycolor2";

import "../../style.css";
import { setGlobalColor, useXplainableController } from "context";

const inputStyles = {
  input: {
    border: "1px solid #EAEAEA",
    borderRadius: "8px",
    maxWidth: "100px",
    padding: "8px 12px",
    fontSize: "16px",
    color: "#000",
  },
};

const inlineStyles = {
  pointer: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    border: "2px solid white",
    backgroundColor: "transparent",
    transform: "translate(-50%, -50%)",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)",
  },
  slider: {
    width: "16px",
    borderRadius: "50%",
    height: "16px",
    background: "transparent",
    border: "2px solid white",
    transform: "translate(0, -2px)",
  },
};

export const ColorPicker = () => {
  const [controller, dispatch] = useXplainableController();

  const [colorState, setColorState] = useState({
    hsl: controller?.globalColor
      ? tinycolor2(controller?.globalColor).toHsl()
      : { h: 0, s: 0, l: 0 },
    hsv: controller?.globalColor
      ? tinycolor2(controller?.globalColor).toHsv()
      : { h: 0, s: 0, v: 0 },
    hex: controller?.globalColor || "aaaaaa",
  });

  const handleHueChange = (hue) => {
    setColorState((prev) => ({ ...prev, hsl: hue }));
  };

  const handleInputChange = (hex) => {
    const hsl = tinycolor2(hex).toHsl();
    const hsv = tinycolor2(hex).toHsl();

    setColorState((prev) => ({ ...prev, hex, hsl, hsv }));
  };

  const handleSaturationChange = (hsv) => {
    const color = tinycolor2(hsv);

    setColorState((prev) => ({ ...prev, hsl: color.toHsl(), hex: color.toHex() }));
  };

  useEffect(() => {
    setGlobalColor(dispatch, colorState.hex);
  }, [colorState.hex]);

  return (
    <Card sx={{ padding: "8px 8px 16px" }}>
      <XBox
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <XBox
          sx={{
            width: "100%",
            height: "122px",
            position: "relative",
            overflow: "hidden",
            borderRadius: "8px",
          }}
          onMouseDown={(event) => {
            const pointer = document.querySelector(".custom-pointer");
            const pointerContainer = pointer?.parentElement;

            if (pointerContainer) {
              pointerContainer.style.position = "fixed";
              pointerContainer.style.top = event.clientY + "px";
              pointerContainer.style.left = event.clientX + "px";
            }
          }}
        >
          <Saturation
            hsl={colorState.hsl}
            hsv={colorState.hsv}
            pointer={CustomPointer}
            onChange={handleSaturationChange}
          />
        </XBox>

        <XBox
          sx={{
            height: "12px",
            borderRadius: "24px",
            position: "relative",
            margin: "10px 0px",
            width: "100%",
          }}
        >
          <Hue
            hsl={colorState.hsl}
            pointer={CustomSlider}
            onChange={handleHueChange}
            direction={"horizontal"}
          />
        </XBox>

        <XBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <XTypography variant="caption" fontSize="14px">
            Color code
          </XTypography>
          <EditableInput
            style={{
              input: {
                border: "1px solid #7C7C7C",
                borderRadius: "8px",
                maxWidth: "100px",
                padding: "8px 12px",
                fontSize: "16px",
                color: controller.darkMode ? "white" : "black",
                background: controller.darkMode ? "#262525" : "white",
              },
            }}
            value={colorState.hex}
            onChange={handleInputChange}
          />
        </XBox>
      </XBox>
    </Card>
  );
};

const CustomSlider = () => {
  return <div style={inlineStyles.slider} />;
};

const CustomPointer = () => {
  return <div className="custom-pointer" style={inlineStyles.pointer} />;
};
