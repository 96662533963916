import { useState } from "react";
import { useXplainableController } from "context";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

function RoleSelect({ onSelection }) {
  const [selected, setSelected] = useState("Viewer");
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const handleClick = (value) => {
    setSelected(value);
    onSelection(value);
  };

  const getCellStyle = (role) => {
    // Define style object based on whether the role is selected
    return {
      border: selected === role ? "2px solid #0080EA" : "",
      borderRadius: "16px",
      padding: "16px",
      cursor: "pointer",
      background: selected === role 
      ? (darkMode ? colors.background.dark : colors.light.main) 
      : (darkMode ? colors.background.dark : "")
    
    };
  };

  return (
    <XBox>
      <table
        className="custom-table"
        style={{ border: "1px solid #EAEAEA", borderRadius: "16px", padding: "16px" }}
      >
        <thead>
          <tr>
            <th onClick={() => handleClick("Viewer")} style={getCellStyle("Viewer")}
            >
              <XBox
                display="flex"
                flexDirection="column"
                gap="24px"
                textAlign="start"
                height="100%"
              >
                <XBox display="flex" flexDirection="column">
                  <XTypography variant="button" color="secondary" fontSize="32px">
                    Viewer
                  </XTypography>
                  <XTypography variant="button" color="secondary" fontSize="12px">
                    The Business User.
                  </XTypography>
                </XBox>
                <XBox display="flex" flexDirection="column" height="120px">
                  <XTypography variant="button" color="secondary" fontWeight="light">
                    View model insights, add commentary, and explore scenarios. Utilise batch
                    prediction and share results with other users through our reporting
                    functionality.
                  </XTypography>
                </XBox>
              </XBox>
            </th>
            <th onClick={() => handleClick("Creator")} style={getCellStyle("Creator")}>
              <XBox
                display="flex"
                flexDirection="column"
                gap="24px"
                textAlign="start"
                height="100%"
              >
                <XBox display="flex" flexDirection="column">
                  <XTypography variant="button" sx={{ color: "#DE4188" }} fontSize="32px">
                    Creator
                  </XTypography>
                  <XTypography variant="button" color="secondary" fontSize="12px">
                    The Technical Expert.
                  </XTypography>
                </XBox>
                <XBox display="flex" flexDirection="column" height="120px">
                  <XTypography variant="button" color="secondary" fontWeight="light">
                    Explore our API to create preprocessors, models, and deployments. Then, persist
                    all the metadata to the Xplainable cloud.
                  </XTypography>
                </XBox>
              </XBox>
            </th>
          </tr>
        </thead>
      </table>
    </XBox>
  );
}

export default RoleSelect;

RoleSelect.propTypes = {
  onSelection: PropTypes.func,
};
