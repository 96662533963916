import React, { useState } from "react";
import { Card, Divider, Icon, Tooltip } from "@mui/material";

import XBox from "components/XBox";
import XButton from "components/XButton";
import XInput from "components/XInput";
import XTypography from "components/XTypography";

import PropTypes from "prop-types";
import { useOptimiserMutation, useRunsMutation } from "api/mutations";
import { useParams } from "react-router-dom";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

import DeleteIcon from "assets/images/icons/collections/delete-icon.svg";
import ChevronLeftIcon from "assets/images/icons/batch/chevron-left-icon.svg";

export const Batches = ({ setActiveStep, versionId, dataset, additionalHeaders }) => {
  const { activeWorkspace } = useApiKey();

  const url = new URL(window.location.href);
  const runId = url.searchParams.get("run_id");

  const params = useParams();

  const batchesList = [
    {
      name: "Batches 1",
      value: {},
    },
  ];

  const { createBatchesMutation } = useOptimiserMutation();

  const [batchesInformation, setBatchesInformation] = useState({ name: "", description: "" });
  const [batches, setBatches] = useState(batchesList);
  const [newBatchValue, setNewBatchValue] = useState({});

  const handleAddNewBatch = (e) => {
    if (e.target.tagName !== "INPUT") {
      setBatches([...batches, { name: `Batches ${batches.length + 1}`, value: newBatchValue }]);
      setNewBatchValue("");
    }
  };

  const handleBackButton = () => {
    setActiveStep(3);
  };

  const handleStartRunButton = () => {
    const filteredBatches = batches
      .filter((item) => item.value)
      ?.map((item) => ({ ...item.value }));
    const data = {
      run_id: runId,
      batches: filteredBatches,
    };

    createBatchesMutation([activeWorkspace, params.id, versionId, data], {
      onSuccess: (data) => {
        setActiveStep(5);
      },
    });
  };

  return (
    <Card height={"100%"} mt={10} pb={{ xs: 0, lg: 10 }}>
      <XBox sx={{ padding: "10px 20px" }} width="100%">
        <XBox display="flex" flexDirection="column" gap="10px">
          <XButton
            variant="outlined"
            sx={{ padding: 0, alignSelf: "start", background: "transparent", border: "none" }}
            onClick={handleBackButton}
          >
            <XBox display="flex" gap="4px" alignItems="center">
              <XBox src={ChevronLeftIcon} component="img" />
              <XTypography color="xpblue" variant="button">
                Back
              </XTypography>
            </XBox>
          </XButton>
          {/* <XBox display="flex" gap="20px" width="100%">
            <XBox width="100%">
              <XBox mb={2}>
                <XInput
                  placeholder="eg. March 2023"
                  value={batchesInformation.name}
                  onChange={(event) => {
                    setBatchesInformation({
                      ...batchesInformation,
                      name: event.target.value,
                    });
                  }}
                />
              </XBox>
            </XBox>
            <XBox width="100%">
              <XBox mb={2}>
                <XInput
                  placeholder="eg. March 2023"
                  value={batchesInformation.description}
                  onChange={(event) => {
                    setBatchesInformation({
                      ...batchesInformation,
                      description: event.target.value,
                    });
                  }}
                />
              </XBox>
            </XBox>
          </XBox> */}
          <XBox sx={{ overflow: "auto" }} mb={2}>
            <XBox display="flex" justifyContent="space-between" gap="150px">
              <XBox position="relative">
                <XBox display="flex" alignItems="center" gap="10px">
                  <XTypography fontWeight="medium" fontSize="14px" color="secondary">
                    Batches
                  </XTypography>
                  <XBox sx={{ position: "relative" }}>
                    <Tooltip
                      title="Tooltip"
                      placement="top"
                      sx={{
                        minHeight: "17px",
                        maxHeight: "17px",
                        minWidth: "17px",
                        maxWidth: "17px",
                        display: "flex",
                      }}
                    >
                      <XButton variant="outlined" color="secondary" size="small" circular iconOnly>
                        <Icon>question_mark</Icon>
                      </XButton>
                    </Tooltip>
                  </XBox>
                </XBox>
              </XBox>
              <XBox display="flex" alignItems="center" justifyContent="end" gap="20px" width="100%">
                {additionalHeaders.map((item, index) => (
                  <XBox key={index} sx={{ minWidth: "70px" }}>
                    <XTypography width="100%" fontSize="14px" color="secondary" fontWeight="medium">
                      {item}
                    </XTypography>
                  </XBox>
                ))}
                <XBox position="relative">
                  <XBox display="flex" alignItems="center" gap="10px">
                    <XBox sx={{ position: "relative" }}>
                      <Tooltip
                        title="Tooltip"
                        placement="top"
                        sx={{
                          minHeight: "17px",
                          maxHeight: "17px",
                          minWidth: "17px",
                          maxWidth: "17px",
                          display: "flex",
                        }}
                      >
                        <XButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>question_mark</Icon>
                        </XButton>
                      </Tooltip>
                    </XBox>
                  </XBox>
                </XBox>
              </XBox>
            </XBox>
            <Divider />
            <XBox display="flex" flexDirection="column" gap={1}>
              {batches.map((batch, batchIndex) => (
                <>
                  <XBox
                    key={batch.name}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ padding: "0 10px" }}
                    gap="130px"
                  >
                    <XBox display="flex" gap="10px" alignItems="center">
                      <XButton
                        size="small"
                        circular
                        iconOnly
                        sx={{ boxShadow: "none", background: "transparent" }}
                        onClick={() => {
                          const filteredBatches = batches.filter(
                            (item, index) => index !== batchIndex
                          );

                          setBatches(filteredBatches);
                        }}
                      >
                        <XBox src={DeleteIcon} component="img" />
                      </XButton>
                      <XTypography fontSize="12px" color="secondary" sx={{ whiteSpace: "nowrap" }}>
                        {batch.name}
                      </XTypography>
                    </XBox>
                    <XBox display="flex" gap="20px" sx={{ paddingRight: "20px" }}>
                      {additionalHeaders.map((header, headerIndex) => (
                        <XBox key={headerIndex}>
                          <XInput
                            sx={{
                              maxWidth: "86px",
                              padding: "5px",
                              maxHeight: "26px",
                              minWidth: "70px",
                            }}
                            value={batch.value?.[header] || ""}
                            onChange={(e) => {
                              const result = e.target.value.replace(/\D/g, "");

                              setBatches((prevBatches) =>
                                prevBatches.map((batch, index) => {
                                  if (index === batchIndex) {
                                    return {
                                      ...batch,
                                      value: {
                                        ...batch.value,
                                        [additionalHeaders[headerIndex]]: result,
                                      },
                                    };
                                  }
                                  return batch;
                                })
                              );
                            }}
                          />
                        </XBox>
                      ))}
                    </XBox>
                  </XBox>
                </>
              ))}
            </XBox>
            <XBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleAddNewBatch}
              sx={{ padding: "0 50px 0 20px" }}
              gap="100px"
              mt={2}
            >
              <XTypography variant="button" color="xpblue" sx={{ whiteSpace: "nowrap" }}>
                + Add New Batch
              </XTypography>
              <XBox display="flex" gap="15px">
                {additionalHeaders.map((header, index) => (
                  <XBox key={index}>
                    <XInput
                      sx={{
                        maxWidth: "96px",
                        padding: "5px",
                        maxHeight: "26px",
                        minWidth: "70px",
                      }}
                      value={newBatchValue[header] || ""}
                      onChange={(e) => {
                        const result = e.target.value.replace(/\D/g, "");

                        setNewBatchValue((prevValue) => ({
                          ...prevValue,
                          [header]: result,
                        }));
                      }}
                    />
                  </XBox>
                ))}
              </XBox>
            </XBox>
          </XBox>
          <XButton
            color="button"
            variant="gradient"
            sx={{ alignSelf: "end", height: "36px" }}
            onClick={handleStartRunButton}
            disabled={!versionId || !dataset}
          >
            Start Run
          </XButton>
        </XBox>
      </XBox>
    </Card>
  );
};

Batches.propTypes = {
  setActiveStep: PropTypes.func,
  versionId: PropTypes.string,
  dataset: PropTypes.string,
  additionalHeaders: PropTypes.array,
};
