import { getLinksFromPaths } from "utils";

export const Paths = {
  index: "/",
  login: "/login",
  newUser: "/new-user",
  models: {
    index: "/models",
    id: "/models/:id",
  },
  deployments: {
    index: "/deployments",
    id: "/deployments/:id",
  },
  collections: {
    index: "/collections",
    id: "/collections/:id/:id",
  },

  reports: {
    index: "/reports",
    id: "/reports/:id",
  },
  batchOptimisation: {
    index: "/batch-optimisation",
    id: "/batch-optimisation/:id",
  },
  apiKeys: "/api-keys",
  train: {
    index: "/train",
  },
  analyst: {
    index: "/analyst",
  },
  autoTrain: {
    index: "/auto-train",
  },
  datasets: {
    index: "/datasets",
  },
  admin: {
    index: "admin",
    organisationId: "/admin/:organisationId",
    teamId: "/admin/:organisationId/:teamId",
  },
  subscriptions: "/subscriptions",
};

export const Links = getLinksFromPaths(Paths);
