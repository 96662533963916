// xplainable dashboard base styles
import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

// xplainable dashboard helper functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

const { light, black, info, primary, white, xpblue } = colors;
const { borderRadius } = borders;

const slider = {
  styleOverrides: {
    root: {
      width: "100%",

      "& .MuiSlider-active, & .Mui-focusVisible": {
        boxShadow: "none !important",
      },

      "& .MuiSlider-valueLabel": {
        color: white.main,
        backgroundColor: xpblue.main,
        borderRadius: "4px",
      },
      "& .MuiSlider-markLabel": {
        color: white.main,
      },
    },

    rail: {
      height: pxToRem(8),
      backgroundColor: light.main,
      borderRadius: borderRadius.sm,
    },

    track: {
      backgroundColor: primary.main,
      height: pxToRem(6),
      position: "relative",
      top: pxToRem(2),
      border: "none",
      borderRadius: borderRadius.lg,
      zIndex: 1,
    },

    thumb: {
      width: pxToRem(20),
      height: pxToRem(20),
      backgroundColor: white.main,
      border: `4px solid ${xpblue.main}`,
      zIndex: 10,
      boxShadow: "none",

      "&:hover": {
        boxShadow: "none",
      },
    },
  },
};

export default slider;
