import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "hooks";
import UserTable from "../admin/components/UserTable";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import {
  Autocomplete,
  Grid,
  Icon,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { debounce } from "lodash";
import { useXplainableController } from "context";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { MdCheck } from "react-icons/md";

import colors from "assets/theme/base/colors";
import RoleSelect from "./components/RoleSelect";

import { useSearchUserQuery, useSubscriptionSeatsQuery } from "api/query";
import { useInviteMutation } from "api/mutations/useInvitationsMutation";

export const Users = ({ teamUsersData, team, organisation }) => {
  const { logout } = useAuth0();
  const { activeWorkspace } = useApiKey();
  const [controller] = useXplainableController();

  const { data } = useSubscriptionSeatsQuery(activeWorkspace, logout);

  const { showSuccessToast } = useToast();
  const { inviteUserMutation } = useInviteMutation();

  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [role, setRole] = useState("Viewer");
  const [tableRows, setTableRows] = useState(null);
  const [buttonText, setButtonText] = useState("Invite");
  const [searchInput, setSearchInput] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const roleTabs = ["Team Admin", "Creator", "Viewer"];
  const [users, setUsers] = useState(null);

  const { data: searchedUser, refetch: searchUserRefetch } = useSearchUserQuery(
    activeWorkspace?.organisation_id,
    searchInput
  );

  const handleRoleSelect = (selected) => {
    setRole(selected);
  };

  const toggleSnackbar = () => setShow(!show);

  const filter = createFilterOptions();

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  // Function to toggle checkbox state
  const handleToggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const debouncedSearch = useCallback(
    debounce((input) => {
      searchUserRefetch({ organisationId: organisation?.organisation_id, input })
        .then((response) => {
          console.log('Search response:', response);
        })
        .catch((error) => {
          console.error('Error during search:', error);
        });
    }, 200),
    [searchUserRefetch, organisation?.organisation_id]
  );
  
  useEffect(() => {
    if (searchInput) {
      debouncedSearch(searchInput);
    } else {
      setSearchResults([]);
    }
  }, [searchInput, debouncedSearch]);

  useEffect(() => {
    if (searchedUser) {
      setSearchResults(searchedUser?.data || []);
    }
  }, [searchedUser]);

  useEffect(() => {
    setButtonText(searchResults?.length === 0 ? "Invite" : "Add");
  }, [searchResults]);



  useEffect(() => {
    if (!teamUsersData) {
      setUsers([]);
      return;
    }

    // Optionally, add team_name to each user
    const updatedUsers = teamUsersData.map((user) => ({
      ...user,
      team_name: team?.name ? [team.name] : [],
    }));

    setUsers(updatedUsers);
  }, [teamUsersData, team]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onAdd = useCallback(() => {
    let emailToSend;
    if (selectedUser) {
      emailToSend = selectedUser?.email;
    } else if (searchInput) {
      emailToSend = searchInput;
    }

    if (selectedUser && team?.id && organisation) {
      const payload = {
        email: emailToSend,
        team_id: team?.id,
        organisation_id: organisation?.organisation_id,
        role: roleTabs?.indexOf(role) + 1,
      };

      inviteUserMutation([JSON.stringify(payload)], {
        onSuccess: (data) => {
          console.log("Invite API response:", data?.data);
          setSelectedUser(null); // Reset the selected user

          showSuccessToast(`Successfully invited ${emailToSend} to join the team.`);
          handleClose();
        },
        onError: (error) => {
          handleClose();
          console.log(error);
        },
      });
    } else {
      // Handle case when no user is selected
      handleClose();
    }
  }, [
    selectedUser,
    searchInput,
    team,
    organisation,
    role,
    inviteUserMutation,
    roleTabs,
    showSuccessToast,
  ]);

  useEffect(() => {
    setTableRows(users || []);
  }, [users]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 800,
            background: controller?.darkMode
              ? colors?.background?.dark
              : colors?.background?.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <XTypography fontSize="16px" fontWeight="bold">
              Add New User
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size?.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller?.darkMode ? white?.main : dark?.main,
                stroke: controller?.darkMode ? white?.main : dark?.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>

          <Grid item xs={12}>
            <XBox>
              <Grid container>
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    value={selectedUser}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setSelectedUser({ email: newValue });
                      } else if (newValue?.inputValue) {
                        setSelectedUser({ email: newValue.inputValue });
                      } else {
                        setSelectedUser(newValue);
                      }
                    }}
                    placeholder="Enter user's email"
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options?.some(
                        (option) => inputValue === option?.email
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered?.push({
                          inputValue,
                          email: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="user-autocomplete"
                    options={searchResults || []}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") return option;
                      if (option?.inputValue) return option.inputValue;
                      return option?.email;
                    }}
                    renderOption={(props, option) => <li {...props}>{option?.email}</li>}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" onChange={handleSearchChange} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <XBox display="flex" flexDirection="column">
                    <XTypography fontSize="16px" fontWeight="bold" pb={2}>
                      User Subscription
                    </XTypography>
                    <RoleSelect onSelection={handleRoleSelect} />
                  </XBox>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <XTypography fontSize="16px" fontWeight="bold" pb={2}>
                    Team Admin
                  </XTypography>
                  <XBox
                    sx={{
                      backgroundColor: `rgba(${colors?.secondary?.main}, 0.1)`,
                      padding: 0.5,
                      borderColor: isChecked ? colors?.xpblue?.main : "grey.300",
                      border: 1,
                      borderStyle: "solid",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <XBox px={2}>
                      <XTypography fontSize="16px" fontWeight="light" color="secondary">
                        {`Set this user as the Team Administrator, allowing them to update user roles.`}
                      </XTypography>
                    </XBox>
                    <Tooltip title="Set as Team Admin" placement="top">
                      <XBox
                        sx={{
                          p: "8px",
                          borderColor: isChecked ? colors?.xpblue?.main : "grey.300",
                          border: 1,
                          height: "48px",
                          width: "48px",
                          borderStyle: "solid",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleToggleCheckbox}
                      >
                        {isChecked && <MdCheck size="24" color="#0080EA" />}
                      </XBox>
                    </Tooltip>
                  </XBox>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <XBox display="flex" justifyContent="flex-end">
                    <Tooltip
                      title={team ? "" : "Select a team to add a user"}
                      placement="top"
                    >
                      <span>
                        <XButton
                          type="submit"
                          variant="gradient"
                          color="button"
                          disabled={!team}
                          size="medium"
                          onClick={onAdd}
                        >
                          {buttonText}
                        </XButton>
                      </span>
                    </Tooltip>
                  </XBox>
                </Grid>
              </Grid>
            </XBox>
          </Grid>
        </XBox>
      </Modal>
      <XBox my={3}>
        <Grid container>
          <Grid item xs={12}>
            <XBox display="flex" justifyContent="space-between" alignItems="center">
              <XTypography textTransform="capitalize" variant="h5">
                Users
              </XTypography>
              <XBox display="flex" gap={2} alignItems="center">
                <XButton
                  variant="gradient"
                  color="button"
                  onClick={() => setIsModalOpen(true)}
                >
                  Add New User
                </XButton>
              </XBox>
            </XBox>
          </Grid>
          <Grid item xs={12}>
            <XBox pt={3}>
                <UserTable
                  data={users || []}
                  teamData={team || {}}
                  isAdmin={isAdmin}
                  setIsAdmin={setIsAdmin}
                  tableRows={tableRows || []}
                  setTableRows={setTableRows}
                  messageContent={messageContent}
                  toggleSnackbar={toggleSnackbar}
                  show={show}
                  isLoading={!users}
                />
            </XBox>
          </Grid>
        </Grid>
      </XBox>
    </>
  );
};

Users.propTypes = {
  teamUsersData: PropTypes.array,
  team: PropTypes.object,
  organisation: PropTypes.object,
};
