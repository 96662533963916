// TableCellComponent.js
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import XBox from "components/XBox";
import { Icon } from "@mui/material";

import Swal from "sweetalert2";
import XTypography from "components/XTypography";
import DeleteIcon from "assets/images/icons/collections/delete-icon.svg";

function TableCellComponent(props) {
  const {
    isModel,
    handleMenuOpen,
    handleMenuClose,
    menuAnchorEl,
    openMenuIndex,
    rowIndex,
    handleArchiveProject,
    handleDeleteProject,
    collection_id,
    model_id,
    deployed,
    setIsModalOpen,
    setSelectedModel,
    project,
  } = props;

  const showAlert = (index, onRemove) => {
    handleMenuClose();

    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "This will remove the team from the organisation.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove the organisation!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Sent a confirmation email!",
            "An email has been sent to confirm the removal of the Organisation",
            "success"
          );
          //TODO: Hit the backend and delete it from the database
          onRemove(index);
        }
      });
  };

  return (
    <XBox>
      <Icon
        fontSize="default"
        color="secondary"
        sx={{ cursor: "pointer" }}
        onClick={(event) => handleMenuOpen(event, rowIndex)}
      >
        more_vert
      </Icon>
      <Menu
        anchorEl={menuAnchorEl}
        open={openMenuIndex === rowIndex}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {!collection_id && !isModel && (
          <>
            <MenuItem
              onClick={() => {
                handleArchiveProject(model_id);
              }}
            >
              <XTypography fontSize="14px">Archive</XTypography>
            </MenuItem>
            <MenuItem onClick={close}>
              <XTypography fontSize="14px">Unarchive</XTypography>
            </MenuItem>
          </>
        )}

        {isModel && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setSelectedModel(project?.model_id);
              setIsModalOpen(true);
              close();
            }}
          >
            <XTypography fontSize="14px">Move Model</XTypography>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            if (deployed) {
              showAlert();
            } else {
              handleDeleteProject(collection_id ? (model_id, collection_id) : model_id);
            }
          }}
          style={{
            display: "flex",
            gap: 8,
            padding: 8,
          }}
        >
          <XBox src={DeleteIcon} component="img" />
          <XTypography fontSize="14px" sx={{ color: "#EA2C4C" }}>
            Delete
          </XTypography>
        </MenuItem>
      </Menu>
    </XBox>
  );
}

export default TableCellComponent;

TableCellComponent.propTypes = {
  row: PropTypes.object,
  rowIndex: PropTypes.number,
  handleMenuOpen: PropTypes.func,
  handleMenuClose: PropTypes.func,
  menuAnchorEl: PropTypes.object,
  openMenuIndex: PropTypes.number,
  showAlert: PropTypes.func,
  onRemove: PropTypes.func,
  handleArchiveProject: PropTypes.func,
  handleDeleteProject: PropTypes.func,
  model_id: PropTypes.string,
  collection_id: PropTypes.number,
  deployed: PropTypes.bool,
  isModel: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setSelectedModel: PropTypes.func,
  project: PropTypes.object,
};
