import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from "@mui/material";
import XBox from "components/XBox";

const EmptyState = ({ 
  icon: Icon,  // Expect a component, not an instance
  message, 
  height = "500px",
  iconSize = "50px",
}) => {
  return (
    <XBox 
      height={height} 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <Icon 
        sx={{ 
          width: `${iconSize} !important`,
          height: `${iconSize} !important`,
          fontSize: `${iconSize} !important`,
          color: 'text.secondary',
          opacity: 0.5
        }} 
      />
      <Typography 
        variant="h6" 
        color="text.secondary"
        sx={{ 
          opacity: 0.8,
          textAlign: 'center',
          maxWidth: '80%'
        }}
      >
        {message}
      </Typography>
    </XBox>
  );
};

EmptyState.propTypes = {
  icon: PropTypes.elementType.isRequired,
  message: PropTypes.string.isRequired,
  height: PropTypes.string,
  iconSize: PropTypes.string,
};

export default EmptyState; 