import ProbabilityChart from "shared/models/BinaryPerformance/components/ProbabilityChart";
import PropTypes from "prop-types";

import React from "react";

const ThresholdPlot = React.memo(({ threshold, setThreshold }) => {
  const selectedDataset = {
    label: "Train",
    value: "train",
  };

  return (
    <ProbabilityChart
      id={"probability--plot"}
      dataset={selectedDataset.value}
      margin={{
        top: 20,
        right: 40,
        bottom: 20,
        left: 40,
      }}
      setThreshold={setThreshold}
    />
  );
});

export default ThresholdPlot;

ThresholdPlot.propTypes = {
  threshold: PropTypes.number,
  setThreshold: PropTypes.func,
};
