import React from "react";

import { Card } from "@mui/material";

import XBox from "components/XBox";
import XTypography from "components/XTypography";

import PropTypes from "prop-types";

export const RunCard = ({ title, value, descriptionValue, descriptionText }) => {
  return (
    <Card sx={{ width: "100%" }}>
      <XBox sx={{ padding: "16px" }} display="flex" gap="8px" flexDirection="column">
        <XTypography fontSize="12px" fontWeight="medium" color="secondary">
          {title}
        </XTypography>
        <XTypography fontWeight="bold" fontSize="24px" sx={{ color: "#0080EA" }}>
          {value}
        </XTypography>
        <XBox display="flex" gap="10px">
          <XTypography sx={{ color: "#2cce88" }} fontWeight="medium" fontSize="12px">
            {descriptionValue}
          </XTypography>
          <XTypography color="secondary" fontSize="12px">
            {descriptionText}
          </XTypography>
        </XBox>
      </XBox>
    </Card>
  );
};

RunCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  descriptionValue: PropTypes.string,
  descriptionText: PropTypes.string,
};
