import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import XBox from "components/XBox";

import "assets/css/tooltip.css";
import { useModel } from "hooks";
import { useXplainableController } from "context";

const BarChart = ({ probability }) => {
  const [controller] = useXplainableController();
  const { collapsedWidth } = useModel();
  const ref = useRef();
  const svgContainer = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(250);

  // This function calculates width and height of the container
  const getSvgContainerSize = () => {
    const newWidth = svgContainer.current.clientWidth;
    setWidth(newWidth);
  };

  useEffect(() => {
    // detect 'width' and 'height' on render
    getSvgContainerSize();
    // listen for resize changes, and detect dimensions again when they change
    window.addEventListener("resize", getSvgContainerSize);
    // cleanup event listener
    return () => window.removeEventListener("resize", getSvgContainerSize);
  }, [collapsedWidth]);

  useEffect(() => {
    FlushChart();
    DrawChart();
  }, [probability, width, height, controller.darkMode]);

  const FlushChart = () => {
    d3.select(ref.current).selectAll("*").remove();
  };

  const DrawChart = () => {
    const data = [
      { name: "True", value: probability },
      { name: "False", value: 1 - probability },
    ];

    const margin = { top: 20, right: 40, bottom: 60, left: 20 };
    const plotWidth = width - margin.left - margin.right;
    const plotHeight = height - margin.top - margin.bottom;

    const svg = d3
      .select(ref.current)
      .attr("width", plotWidth + margin.left + margin.right)
      .attr("height", plotHeight + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(data.map((d) => d.name))
      .padding(0.5);

    const y = d3
      .scaleLinear()
      .domain([0, 1])
      .range([height - margin.bottom, 0]);

    // create horizontal grid lines
    // svg
    //   .selectAll("line.horizontal-grid")
    //   .data(y.ticks())
    //   .enter()
    //   .append("line")
    //   .attr("class", "horizontal-grid")
    //   .attr("x1", 0)
    //   .attr("x2", width)
    //   .attr("y1", (d) => y(d))
    //   .attr("y2", (d) => y(d))
    //   .attr("stroke", colors.light.main)
    //   .attr("stroke-width", "1px")
    //   .attr("stroke-dasharray", "5,5")
    //   .lower();

    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar probability-bar")
      .attr("x", (d) => x(d.name))
      .attr("width", x.bandwidth())
      .attr("y", (d) => y(d.value))
      .attr("height", (d) => height - y(d.value) - margin.bottom)
      .attr("fill", colors.xpblue.main)
      .attr("rx", 5)
      .attr("ry", 5);

    svg
      .append("g")
      .attr("transform", "translate(0," + (height - margin.bottom) + ")")
      .call(d3.axisBottom(x).tickSize(0));

    svg.append("g").call(d3.axisLeft(y).tickSize(0));

    svg.selectAll(".domain").style("stroke", "none");

    const tooltip = d3.select("#tooltip");

    d3.selectAll(".probability-bar").on("mouseenter", onMouseEnter).on("mouseleave", onMouseLeave);

    svg.selectAll("text").style("color", controller.darkMode ? "white" : "black");

    function onMouseEnter(datum) {
      tooltip.select("#count").text((datum.value * 100).toFixed(2));

      const offsetAdjustment = 8; // Change this value based on observation
      const barX = x(datum.name) + x.bandwidth() / 2 + offsetAdjustment;
      const barY = y(datum.value) - 5;

      // Assuming the alpha color you want is:
      const alphaColor = "black"; // Adjust accordingly

      tooltip.classed("bottom-arrow", true);

      tooltip
        .style("transform", `translate(calc(-50% + ${barX}px), calc(-100% + ${barY}px))`)
        .style("opacity", 0.9)
        .style("background", alphaColor)
        .style("color", colors.white.main); // Setting the text color

      // Set the alpha color for the ::before pseudo-element
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
        .tooltip::before {
            border-top: 11px solid ${alphaColor};
        }
      `;

      document.head.appendChild(styleElement);
    }

    function onMouseLeave() {
      tooltip.style("opacity", 0);
    }
  };

  return (
    <XBox sx={{ position: "relative" }} ref={svgContainer}>
      <div id="tooltip" className="tooltip">
        <div className="tooltip-framework">
          <span id="framework" />
        </div>
        <div className="tooltip-value">
          <span id="count" />%
        </div>
      </div>
      <svg ref={ref}></svg>
    </XBox>
  );
};

BarChart.propTypes = {
  probability: PropTypes.number.isRequired,
  trueColor: PropTypes.string,
  falseColor: PropTypes.string,
};

export default BarChart;
