import { useState, useEffect } from "react";
import { useXplainableController } from "context";

import { Grid, Card, Skeleton } from "@mui/material";
import { XViewToggle } from "components/XViewToggle";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { ReportCard } from "./components";

import { animateGradient } from "assets/theme/base/animations";
import { useReportMutation } from "api/mutations/useReportMutation";
import { useNavigate } from "react-router-dom";
import { getTimeDifference } from "shared/Functions/Date";
import { useModelsQuery, useReportsQuery } from "api/query";
import { useXPanelQuery } from "api/query";
import { useApp, useDropdownMenu } from "hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Paths } from "settings";
import { XImg } from "components/XImg";
import { ReactComponent as DarkReportIcon } from "assets/images/icons/reports/dark-report-icon.svg";

import XBox from "components/XBox";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import XButton from "components/XButton";

import DataTable from "shared/Tables/DataTable";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import MiniStatisticsCard from "shared/Cards/MiniStatisticsCard";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ProgressLineChart from "shared/Charts/ProgressLineChart";
import SearchIcon from "assets/images/search-icon.svg";
import ReportArrowIcon from "assets/images/icons/reports/report-arrow-icon.svg";

import TableCellComponent from "layouts/batchOptimisation/AllBatchOptimisations/components/TableCellComponent";
import PropTypes from "prop-types";
import CreateReportModal from "./components/ReportModal";

import "./styles.css";

export function getData(prop) {
  return prop?.data?.current ?? {};
}

export function createSpacer({ id }) {
  return {
    id,
    type: "spacer",
    title: "spacer",
  };
}

export const AllReports = () => {
  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;

  const { viewState, activeWorkspace } = useApiKey();
  const {
    setActiveStep,
    setActiveTitle,
    reportInfo,
    setReportInfo,
    selectedModel,
    setSelectedModel,
  } = useApp();
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const [isCardViewEnable, setIsCardViewEnable] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [models, setModels] = useState([]);
  const [reports, setReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalTitle, setModalTitle] = useState("New report");
  const [actionContext, setActionContext] = useState('create'); // Default to 'create'
  const [originalReportId, setOriginalReportId] = useState(null);

  const { data: modelData } = useModelsQuery(activeWorkspace, logout);
  const { data, isLoading } = useReportsQuery(activeWorkspace, logout);

  const { 
    createReportMutation, 
    deleteReportMutation,
    copyReportMutation,
  } = useReportMutation();

  //TODO work out the xpanel logic to show all the viewers of the reporting
  const query = useXPanelQuery(
    {
      // model_id: model_id,
      report_id: data?.data?.report_id,
      event: ["viewed_report"],
      users: "True",
    },
    logout
  );

  const { data: userData, isLoading: reportsLoading, isError } = query;

  const menuProps = useDropdownMenu(true);

  useEffect(() => {
    if (!modelData) return;

    setModels(modelData.data);
  }, [modelData]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setReports(data.data);
  }, [data]);

  const handleRowClick = (e) => {
    if (!e?.row?.original) {
      return;
    }

    const { report_id, model_id } = e.row.original;

    setActiveTitle(reportInfo.name);
    setActiveStep(1);
    navigate(`/reports/${model_id}?report_id=${report_id}`, {
      state: {
        id: report_id,
        reportName: reportInfo.name,
        reportDescription: reportInfo.description,
      },
    });
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setModalTitle("New report");
  };

  const handleModelChange = (event) => {
    setSelectedModel(event);
  };

  const openCreateModal = () => {
    setActionContext('create');
    setIsModalOpen(true);
    // Reset or set default values for reportInfo as needed
    setReportInfo(defaultReportInfo); // Assume defaultReportInfo is defined
  };
  
  const openCopyModal = (reportId) => {
    setActionContext('copy');
    setOriginalReportId(reportId);
    setIsModalOpen(true);
    // Optionally, preload reportInfo with values from the report being copied
  };

  const handleCreateReport = () => {
    if (!selectedModel.value) return;
  
    const reportData = {
      team_id: activeWorkspace?.team_id,
      model_id: selectedModel.value,
      report_name: reportInfo.name,
      report_description: reportInfo.description,
      is_public: false,
    };
  
    const onSuccess = (data) => {
      setIsModalOpen(false);
      setActiveTitle(reportInfo.name);

      console.log('Report created successfully:', data.data)
      
      // Check if the action was 'copy' and navigate accordingly
      if (actionContext === 'copy') {
        // For copy, navigate with model ID in the path and report ID as a query parameter
        navigate(`${Paths.reports.index}/${selectedModel.value}?report_id=${data.data.report_id}`,{
          state: {
            id: data.data.report_id,
            reportName: reportInfo.name,
            reportDescription: reportInfo.description,
          },
        }
        );
      } else {
        // For creating a new report, keep the existing navigation logic
        navigate(`${Paths.reports.index}/${selectedModel.value}`, {
          state: {
            id: data.data.report_id,
            reportName: reportInfo.name,
            reportDescription: reportInfo.description,
          },
        });
      }
    };
  
    const onError = (err) => {
      console.error(err);
      setIsModalOpen(false);
    };
  
    if (actionContext === 'create') {
      createReportMutation([JSON.stringify(reportData)], { onSuccess, onError });
    } else if (actionContext === 'copy') {
      copyReportMutation([originalReportId, JSON.stringify(reportData)], { onSuccess, onError });
    }
  };
  
  const cardActionStyles = ({ palette: { white }, typography: { size } }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: `${white.dark} !important`,

    "& .material-icons-round": {
      ml: 0.5,
      fontSize: size.md,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round": {
      transform: "translateX(5px)",
    },
  });

  const handleDeleteReport = (e, props) => {
    deleteReportMutation([props.row.original.report_id], {
      onSuccess: (data) => {
        const filteredReports = reports?.filter(
          (item) => item.report_id !== props.row.original.report_id
        );

        setReports(filteredReports);
        menuProps.closeMenu();
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const renderProjects = () => {
    const type = "Reports";

    if (isLoading && isCardViewEnable) {
      return (
        <XBox display="flex" flexWrap="wrap" gap={3}>
          {[1, 2, 3].map((index) => (
            <Card key={index} sx={{ width: { xs: "100%", sm: "340px" }, height: "230px", p: 2 }}>
              <XBox display="flex" flexDirection="column" gap={2}>
                {/* Report Icon and Name */}
                <XBox display="flex" alignItems="center" gap={1}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="text" width={120} height={24} />
                </XBox>
                
                {/* Description */}
                <Skeleton variant="text" width="90%" />
                <Skeleton variant="text" width="70%" />
                
                {/* Bottom section */}
                <XBox display="flex" justifyContent="space-between" alignItems="center" mt="auto">
                  <Skeleton variant="text" width={80} />
                  <Skeleton variant="rectangular" width={100} height={32} sx={{ borderRadius: 1 }} />
                </XBox>
              </XBox>
            </Card>
          ))}
        </XBox>
      );
    }

    if (isLoading && !isCardViewEnable) {
      return (
        <Card sx={{ width: "100%", p: 2 }}>
          {/* Table Header */}
          <XBox display="flex" gap={2} mb={2}>
            <Skeleton variant="rectangular" width={30} height={20} />
            <Skeleton variant="text" width={200} height={24} />
            <Skeleton variant="text" width={250} height={24} />
            <Skeleton variant="text" width={100} height={24} />
            <Skeleton variant="text" width={100} height={24} />
          </XBox>
          
          {/* Table Rows */}
          {[1, 2, 3, 4].map((index) => (
            <XBox key={index} display="flex" gap={2} mb={2}>
              <Skeleton variant="rectangular" width={30} height={20} />
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={250} />
              <Skeleton variant="text" width={100} />
              <Skeleton variant="rectangular" width={100} height={32} sx={{ borderRadius: 1 }} />
            </XBox>
          ))}
        </Card>
      );
    }

    const columns = [
      {
        Header: "",
        width: "5%",
        id: "function",
        Cell: (props) => <TableCellComponent {...props} handleDelete={handleDeleteReport} />,
      },
      {
        Header: "Report Name",
        accessor: "report_name",
        id: "report_name",
        Cell: (props) => {
          return (
            <XBox display="flex" gap="0 10px" alignItems="center">
              <XImg>
                <DarkReportIcon />
              </XImg>

              <XTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
                sx={{
                  maxHeight: "30px",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                }}
              >
                {props.row.original.report_name}
              </XTypography>
            </XBox>
          );
        },
      },
      {
        Header: "Report Description",
        accessor: "report_description",
        id: "report_description",
        Cell: (props) => {
          return (
            <XBox display="flex" gap="0 10px" alignItems="center">
              <XTypography
                variant="button"
                fontWeight="light"
                textTransform="capitalize"
                sx={{
                  maxHeight: "30px",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                }}
              >
                {props.row.original.report_description}
              </XTypography>
            </XBox>
          );
        },
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: (props) => {
          return (
            <XTypography
              variant="button"
              fontWeight="light"
              // color={foundSwitch.active_Report ? "light" : "dark"}
            >
              {props.row.original.created
                ? getTimeDifference(props.row.original.created, true)
                : null}
            </XTypography>
          );
        },
      },
      {
        Header: "",
        width: "5%",
        accessor: "",
        id: "model_transition",
        Cell: (props) => {
          return (
            <XBox sx={{ cursor: "pointer" }} onClick={() => handleRowClick(props)}>
              <XTypography
                variant="button"
                textTransform="capitalize"
                fontWeight="medium"
                color="xpblue"
                sx={cardActionStyles}
              >
                View report
                <XBox src={ReportArrowIcon} component="img" />
              </XTypography>
            </XBox>
          );
        },
      },
    ];

    const filteredReports = reports.filter((item) => {
      const searchQueryLower = searchQuery?.toLowerCase();

      const textConditions =
        item.report_name?.toLowerCase()?.includes(searchQueryLower) ||
        item.report_description?.toLowerCase()?.includes(searchQueryLower);

      return textConditions;
    });

    return (
      <>
        {isCardViewEnable &&
          filteredReports.map((report, index) => (
          <ReportCard
            report={report}
            reports={reports}
            setReports={setReports}
            setIsModalOpen={setIsModalOpen} 
            index={index}
            key={index}
            setModalTitle={setModalTitle}
            openCopyModal={() => openCopyModal(report.report_id)}
          />
          ))}

        {!isCardViewEnable && (
          <Card
            sx={{
              width: "100%",
              position: "relative",
              zIndex: 1,
              overflow: "visible",
            }}
          >
            <DataTable
              table={{ rows: filteredReports, columns: columns }}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              type={type}
            />
          </Card>
        )}
      </>
    );
  };

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={"Reports"}
        description={"One-click shareable reports with intuitive drag-and-drop functionality"}
        icon={"ni ni-atom"}
      />
      <CreateReportModal
        isModalOpen={isModalOpen}
        handleClose={handleClose}
        handleModelChange={handleModelChange}
        handleCreateReport={handleCreateReport}
        selectedModel={selectedModel}
        models={models}
        reportInfo={reportInfo}
        setReportInfo={setReportInfo}
        controller={controller}
        modalTitle={modalTitle}
      />
      <XBox  height={"100%"} pb={{ xs: 0, lg: 10 }}>

      <Grid container spacing={3} mt={1} mb={3}>
        <Grid item xs={12} lg={9}>
          <XBox>
            <ProgressLineChart
              color={"xpblue"}
              icon="groups"
              title="Total Report Views"
              height={"160px"}
              event="viewed_report"
              team_id={activeWorkspace?.team_id}
            />
          </XBox>
        </Grid>
        <Grid item xs={12} lg={3} display="flex">
          <XBox display="flex" flexDirection="column" gap={4} width="100%">
            <MiniStatisticsCard
              title={{ text: "Total Reports", fontWeight: "medium" }}
              count={`${reports?.length}`}
              color="xpblue"
            />

            <MiniStatisticsCard
              title={{ text: "All Viewers", fontWeight: "medium" }}
              count={userData?.data?.length || 0}
              color="xpblue"
              users={userData?.data}
            />
          </XBox>
        </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <XBox mb={1}>
              <XTypography variant="h5" fontSize="18px">
                All Reports
              </XTypography>
            </XBox>
          </Grid>



          <XBox display="flex" justifyContent="space-between" width="100%" ml={3}>
            <XBox display="flex" gap={2} justifyContent="space-between" width="550px">
              <XInput
                sx={{ width: "100%" }}
                placeholder="Search"
                startAdornment={<XBox src={SearchIcon} component="img" />}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </XBox>

            <XBox display="flex" gap={2}>
              {viewState === "creator" && (
                <XButton variant="gradient" color="button" onClick={openCreateModal}>
                  New Report
                </XButton>
              )}
              <XViewToggle
                color="xpblue"
                isCardViewEnable={isCardViewEnable}
                setIsCardViewEnable={setIsCardViewEnable}
              />
            </XBox>
          </XBox>
        </Grid>

        <XBox mt={1} mb={1}>
          <XBox
            display="flex"
            flexWrap="wrap"
            sx={{ justifyContent: { xs: "center", lg: "flex-start" } }}
          >
            {renderProjects()}
            <XBox
              sx={{
                zIndex: "0",
                width: { xs: "100%", sm: "340px" },
                justifyContent: { xs: "center", lg: "flex-start" },
                height: "390px",
                mx: isCardViewEnable ? 1 : 0,
                my: 1,
              }}
            ></XBox>
          </XBox>
        </XBox>
      </XBox>
    </DashboardLayout>
  );
};

AllReports.propTypes = {
  value: PropTypes.number,
  row: PropTypes.number,
};
