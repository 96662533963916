import { useRef } from "react";
import { useNavigate } from "react-router-dom";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/swiper-bundle.min.css";

// @mui material components
import Card from "@mui/material/Card";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XAvatar from "components/XAvatar";

const data = [
  // {
  //   title: "Making Predictions using a Zapier Integration",
  //   description:
  //     "Seamlessly Integrate Machine Learning into Your Workflow with Xplainable and Zapier.",
  //   author: {
  //     img: "https://strapi-website-cms-byiou.ondigitalocean.app/uploads/jason_profile_photo_896497a14a.jpeg",
  //     name: "Jason Zhong",
  //   },
  //   bgImage:
  //     "https://strapi-website-cms-byiou.ondigitalocean.app/uploads/Zapier_Deployment_7ae2d2e8b0.png",
  //   date: "Mar 17th, 2023 · 10 min read",
  //   href: "https://www.xplainable.io/resources/blog/xplainable-zapier-integration",
  // },
  {
    title: "Loan Risk Prediction and Prognosis",
    description: "Assessing better risk management in banking with explainable machine learning.",
    author: {
      img: "https://xplainablepublic.blob.core.windows.net/asset-repository/profile_images/P6ZagWAjtgLbAPA0.jpg",
      name: "Tim Huntley",
    },
    bgImage:
      "https://images.unsplash.com/photo-1523839699072-5ec088b61a21?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4140&q=80",
    date: "Oct 4th, 2022 · 12 min read",
    href: "https://www.xplainable.io/resources/walkthroughs/loan-risk-analytics",
  },
  {
    title: "Explainable AI as a Catalyst for Innovation and Creativity",
    description: "Exploring the Intersection of Explainable AI and Innovative Thinking",
    author: {
      img: "https://xplainablepublic.blob.core.windows.net/asset-repository/profile_images/ranay.jpg",
      name: "Ranay Padarath",
    },
    bgImage:
      "https://strapi-website-cms-byiou.ondigitalocean.app/uploads/explainable_ai_human_creativity_b7f3a30abc_f635b01064.png",
    date: "Jul 3rd, 2023 · 10 min read",
    href: "https://www.xplainable.io/resources/blog/explainable-machine-learning-creativity-catalyst",
  },
  {
    title:
      "How to Leverage Xplainable and ChatGPT to Automate Business Intelligence & Optimisation",
    description:
      "Explainable ML is more than just a window into a model's decision-making process — it's a blueprint for optimisation and business insights",
    author: {
      img: "https://strapi-website-cms-byiou.ondigitalocean.app/uploads/Tim_dbd7280046_cff90a6238.jpeg",
      name: "Tim Huntley",
    },
    bgImage:
      "https://strapi-website-cms-byiou.ondigitalocean.app/uploads/Rectangle_407_ee69cdce71_aacb7c805d.svg",
    date: "Jul 18th, 2023 · 15 min read",
    href: "https://www.xplainable.io/resources/blog/leveraging-chat-gpt-automated-intelligence",
  },
];

function Slider() {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);
  const navigate = useNavigate();

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <Card
      sx={{
        position: "relative",
        display: "block",
        height: "458px",
        overflow: "hidden",
        p: 0,
        borderRadius: "16px",
      }}
    >
      <Swiper
        onInit={({ params, navigation }) => {
          const { navigation: nav } = params;

          nav.prevEl = navigationPrevRef.current;
          nav.nextEl = navigationNextRef.current;
          navigation.init();
          navigation.update();
        }}
        autoplay={{ delay: 6000 }}
        speed={2000}
        spaceBetween={0}
        slidesPerView={1}
        loop
        style={{ height: "100%" }}
      >
        <XBox
          display="flex"
          alignItems="center"
          position="absolute"
          bottom={16}
          right={16}
          gap={1}
          zIndex={5}
        >
          <XBox
            width="2rem"
            height="2rem"
            p={1}
            display="flex"
            color="black"
            sx={{ cursor: "pointer", background: "rgba(246, 246, 246, 0.6)", borderRadius: "4px" }}
            ref={navigationPrevRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
            </svg>
          </XBox>
          <XBox
            width="2rem"
            height="2rem"
            p={1}
            display="flex"
            color="black"
            sx={{ cursor: "pointer", background: "rgba(246, 246, 246, 0.6)", borderRadius: "4px" }}
            ref={navigationNextRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
            </svg>
          </XBox>
        </XBox>

        {/* Dynamic SwiperSlides */}
        {data.map((item, index) => (
          <SwiperSlide
            key={index}
            onClick={() => {
              // Check if URL is internal or external
              if (item.href.startsWith("http") || item.href.startsWith("https")) {
                window.open(item.href, "_blank").focus();
              } else {
                navigate(item.href);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <XBox
              sx={{
                backgroundSize: "cover",
                height: "50%",
                backgroundImage: `url(${item.bgImage})`,
                backgroundPositionY: "80%",
              }}
            ></XBox>
            <XBox
              sx={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
              }}
            >
              <XBox
                p={2}
                pb={0}
                mb={2}
                height="100px"
                textAlign="left"
                sx={{
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "transparent", // Hide the scrollbar thumb
                  },
                }}
              >
                <XTypography variant="h5" fontWeight="bold" mb={0.5}>
                  {item.title}
                </XTypography>
                <XTypography variant="body2" style={{ color: "#7C7C7C" }} fontSize={"14px"}>
                  {item.description}
                </XTypography>
              </XBox>
              <XBox display="flex" px={2}>
                <XAvatar
                  sx={{ borderRadius: "50%" }}
                  src={item.author.img}
                  alt={item.author.name}
                  size="sm"
                  shadow="sm"
                />
                <XBox
                  ml={2}
                  gap="4px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <XTypography variant="caption" fontWeight="medium" fontSize="14px">
                    {item.author.name}
                  </XTypography>
                  <XTypography variant="caption">{item.date}</XTypography>
                </XBox>
              </XBox>
            </XBox>
          </SwiperSlide>
        ))}
      </Swiper>
    </Card>
  );
}

export default Slider;
