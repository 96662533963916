import { useRef, useState } from "react";
import PropTypes from "prop-types";
import XTypography from "components/XTypography";

export const BulletList = ({ blocks, setBlocks, id, isEditable = false }) => {
  const bulletBlock = blocks?.find((block) => block.id === id);

  const [items, setItems] = useState(bulletBlock?.data ?? ["Item 1"]);
  const inputRefs = useRef([]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      const newItems = [...items];
      newItems.splice(index + 1, 0, "");
      setItems(newItems);

      setBlocks((prevBlocks) => {
        const updatedBlocks = prevBlocks.map((block) => {
          if (block.id === id) {
            return { ...block, data: newItems };
          }
          return block;
        });

        return updatedBlocks;
      });

      setTimeout(() => {
        if (inputRefs.current[index + 1]) {
          inputRefs.current[index + 1].focus();
        }
      }, 0);
      return;
    }

    if (e.key === "Backspace" && items[index] === "") {
      const newItems = [...items];
      newItems.splice(index, 1);
      setItems(newItems);

      setBlocks((prevBlocks) => {
        const updatedBlocks = prevBlocks.map((block) => {
          if (block.id === id) {
            return { ...block, data: newItems };
          }
          return block;
        });

        return updatedBlocks;
      });

      setTimeout(() => {
        if (inputRefs.current[index - 1]) {
          inputRefs.current[index - 1].focus();
        }
      }, 0);
    }
  };

  const handleChange = (e, index) => {
    const newItems = [...items];
    newItems[index] = e.target.value;
    setItems(newItems);

    setBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map((block) => {
        if (block.id === id) {
          return { ...block, data: newItems };
        }
        return block;
      });

      return updatedBlocks;
    });
  };

  return (
    <ul style={{ marginLeft: "20px" }}>
      {items.map((item, index) => (
        <li key={index}>
          {isEditable ? (
            <input
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              value={item}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              style={{ width: "100%", border: "none", padding: "1px", fontSize: "20px" }}
            />
          ) : (
            <XTypography fontSize="20px">{item}</XTypography>
          )}
        </li>
      ))}
    </ul>
  );
};

BulletList.propTypes = {
  blocks: PropTypes.any,
  tableData: PropTypes.array,
  setBlocks: PropTypes.func,
  id: PropTypes.string,
  isEditable: PropTypes.bool,
};
