import React, { useState } from "react";
import NotificationItem from "shared/Items/NotificationItem";
import XBox from "components/XBox";
import PropTypes from "prop-types";
import { getTimeDifference } from "shared/Functions/Date";
import XButton from "components/XButton";
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import { useNavigate } from "react-router-dom";
import { Collapse } from "@mui/material";

import DenyIcon from "assets/images/icons/deny-icon.svg";

const CommentNotification = ({ notification, handleCloseMenu, handleMarkAsRead }) => {
  const [isNotificationVisible, setIsNotificationVisible] = useState(true);

  const navigate = useNavigate();

  const handleNavigation = () => {
    handleCloseMenu();
    if (notification.data.url.startsWith("http")) {
      window.location.href = notification.data.url;
    } else {
      navigate(notification.data.url, {
        state: {
          organisation_id: notification.data.organisation_id,
          team_id: notification.data.team_id,
        },
      });
    }
  };

  return (
    <Collapse in={isNotificationVisible}>
      <NotificationItem
        key={notification._id}
        image={
          <XBox sx={{ position: "relative" }}>
            <img src={notification.data.profile_image_url} sx={{ width: "100%", height: "auto" }} />
            <XBox sx={{ position: "absolute", bottom: "0px", right: "-10px" }}>
              <XBox
                bgColor={rgba(colors.xpblue.main, 0.8)}
                color="white"
                p={0.5}
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon>{"comments"}</Icon>
              </XBox>
            </XBox>
          </XBox>
        }
        title={[`${notification.data.commenter_name}`, `${notification.description}`]}
        date={getTimeDifference(notification.insert_timestamp, true)}
        deny={
          <XBox mx={0.5} display="flex" justifyContent="center" alignItems="center">
            <XBox
              src={DenyIcon}
              component="img"
              onClick={() => {
                handleMarkAsRead(notification._id);
                setIsNotificationVisible(false);
              }}
            />
          </XBox>
        }
        windowNavigation={handleNavigation}
      />
    </Collapse>
  );
};

export default CommentNotification;

CommentNotification.propTypes = {
  notification: PropTypes.object.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  handleMarkAsRead: PropTypes.func.isRequired,
};
