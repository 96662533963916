// xplainable dashboard base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// xplainable dashboard helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { white } = colors;
const { borderRadius } = borders;

const sidenav = {
  styleOverrides: {
    root: {
      width: pxToRem(272),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      width: pxToRem(256),
      backgroundColor: white.main,
      margin: `${pxToRem(32)} 0 ${pxToRem(16)} ${pxToRem(32)}`,
      borderRadius: borderRadius.xl,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;
