import { useDropzone } from "react-dropzone";
import { useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, Card } from "@mui/material";

import XTypography from "components/XTypography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import Papa from "papaparse";
import XBox from "components/XBox";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import XBadge from "components/XBadge";
import DefaultItem from "shared/Items/DefaultItem";
import ImagePickerIcon from "assets/images/image-picker-button.svg";
import FileIcon from "assets/images/icons/file-icon.svg";
import ChangeIcon from "assets/images/icons/change-icon.svg";
import DownloadIcon from "assets/images/icons/batch/download-icon.svg";

import PropTypes from "prop-types";
import { XDivider } from "components/XDivider";
import { useXplainableController } from "context";

const XDropzone = ({ onDrop, options, activeStep, isImage = true }) => {
  const [controller] = useXplainableController();

  const [fileNames, setFileNames] = useState([]);
  const [fileMetadata, setFileMetadata] = useState([]);

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setFileNames(acceptedFiles.map((file) => file.name));
      acceptedFiles.forEach((file) => {
        Papa.parse(file, {
          complete: function (results) {
            // Extract file metadata
            const metadata = {
              name: file.name,
              size: file.size,
              type: file.type,
              lastModified: file.lastModified,
              rowCount: results.data.length,
              headerColumns: results.data[0],
            };
            setFileMetadata((prevState) => [...prevState, metadata]);
          },
          error: function (err) {
            console.error(`Papa Parse error: ${err}`);
          },
        });
      });
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    onDrop: onDropAccepted,
    accept: {
      "text/csv": [".csv"],
      "text/xlsx": [".xlsx"],
    },
  });

  return (
    <>
      {activeStep === 1 && (
        <Grid container>
          <Grid item xs={12} lg={6} mt={4}>
            <XBox>
              <DefaultItem
                color="xpblue"
                icon="storage"
                title="Size"
                description={`${
                  fileMetadata.length > 0 ? (fileMetadata[0].size / 1000000).toFixed(2) : 0
                } Mb`}
                tooltipText="Size of the uploaded file"
                borderRadius="lg"
                small={true}
              />
              <XBox mt={1}>
                <DefaultItem
                  color="xppurple"
                  icon="view_list"
                  title="Count of Rows"
                  description={fileMetadata.length > 0 ? fileMetadata[0].rowCount : 0}
                  tooltipText="Number of rows in the uploaded CSV"
                  borderRadius="lg"
                  small={true}
                />
              </XBox>
              <XBox mt={1}>
                <DefaultItem
                  color="xppink"
                  icon="view_module"
                  title="Count of Columns"
                  description={fileMetadata.length > 0 ? fileMetadata[0].headerColumns.length : 0}
                  tooltipText="Number of columns in the uploaded CSV"
                  borderRadius="lg"
                  small={true}
                />
              </XBox>
              <XBox mt={1}>
                <DefaultItem
                  color="xpblue"
                  icon="settings"
                  title="Preprocessor"
                  description="-"
                  tooltipText="Information regarding the preprocessor"
                  borderRadius="lg"
                  small={true}
                />
              </XBox>
              <XBox mt={1}>
                <DefaultItem
                  color="xpgreen"
                  icon="label"
                  title="Type"
                  description={fileMetadata.length > 0 ? fileMetadata[0].type : "N/A"}
                  tooltipText="Type of the uploaded file"
                  borderRadius="lg"
                  small={true}
                />
              </XBox>
            </XBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <XBox>
              <DefaultItem
                color="xppink"
                icon="view_module"
                title="Columns"
                description=""
                tooltipText="All columns in uploaded CSV"
                borderRadius="lg"
                small={true}
              />
              <XBox
                p={1}
                mt={1}
                sx={{
                  backgroundColor: controller.darkMode ? "#1D1B1B" : rgba(colors.light.main, 0.4),
                  overflowY: "scroll",
                  overflow: "hidden",
                  borderRadius: "16px",
                  padding: "16px",
                }}
              >
                {fileMetadata.length > 0 ? (
                  fileMetadata[0].headerColumns.map((header, i) => (
                    <XBadge color="xpblue" variant="contained" key={i} badgeContent={header} />
                  ))
                ) : (
                  <></>
                )}
              </XBox>
            </XBox>
          </Grid>
        </Grid>
      )}

      {activeStep === 0 && (
        <StyledBox
          {...getRootProps()}
          sx={{
            height: "300px",
            border: "1px dashed grey",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <input {...getInputProps()} />
          {fileNames.length === 0 ? (
            <XBox p={2}>
              <XTypography variant="caption" style={{ fontSize: "14px" }}>
                Drag and drop image
              </XTypography>
              <XBox display="flex" gap={2} alignItems="center" width="100%">
                <XDivider />
                <XTypography style={{ color: "#EAEAEA", fontSize: "14px" }} variant="caption">
                  or
                </XTypography>
                <XDivider />
              </XBox>
              {isImage ? (
                <XBox src={ImagePickerIcon} component="img" />
              ) : (
                <XBox display="flex" gap={1} alignItems="center" justifyContent="center">
                  <XTypography
                    variant="button"
                    sx={{
                      backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Upload CSV
                  </XTypography>
                  <XBox src={DownloadIcon} component="img" />
                </XBox>
              )}
            </XBox>
          ) : (
            fileNames.map((fileName, i) => (
              <XBox
                display="flex"
                flexDirection="column"
                key={fileName}
                gap={1}
                alignItems="center"
              >
                <XBox src={FileIcon} component="img" width="24px" />
                <XTypography fontWeight="medium">{fileName}</XTypography>
                <XBox src={ChangeIcon} component="img" height="20px" />
              </XBox>
            ))
          )}
        </StyledBox>
      )}
    </>
  );
};

XDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  activeStep: PropTypes.number,
  isImage: PropTypes.bool,
};

const StyledBox = styled(Box)(({ theme }) => {
  const { palette, typography, borders, functions } = theme;

  const { text, white, dark, inputColors, transparent } = palette;
  const { size } = typography;
  const { borderRadius, borderWidth } = borders;
  const { rgba } = functions;

  return {
    height: "300px",
    margin: 2,
    border: `1px dashed grey`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: borderRadius.md,
    backgroundColor: transparent.main,

    "& .dz-default": {
      margin: "0 auto !important",
    },

    "& .dz-default .dz-button": {
      color: `${text.main} !important`,
      fontSize: `${size.sm} !important`,
    },

    "& .dz-preview.dz-image-preview": {
      background: transparent.main,
    },

    "& .dz-preview .dz-details": {
      color: `${dark.main} !important`,
      opacity: "1 !important",

      "& .dz-size span, & .dz-filename span": {
        backgroundColor: `${rgba(white.main, 0.7)} !important`,
      },
    },

    "& .dz-error-message": {
      display: "none !important",
    },

    "& .dz-remove": {
      color: `${dark.main} !important`,
      textDecoration: "none",

      "&:hover, &:focus": {
        textDecoration: "none !important",
      },
    },
  };
});

export default XDropzone;
