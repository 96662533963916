import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useDeploymentsMutation = () => {
  const { mutate: deleteDeploymentsMutation } = useMutation((params) =>
    apiHandlers.deployments.deleteDeployments(...params)
  );

  const { mutate: handleArchiveDeploymentsMutation } = useMutation((params) =>
    apiHandlers.deployments.handleArchiveDeployment(...params)
  );

  const { mutate: handleDeployDeploymentMutation } = useMutation((params) =>
    apiHandlers.deployments.handleDeployDeployment(...params)
  );

  return {
    deleteDeploymentsMutation,
    handleArchiveDeploymentsMutation,
    handleDeployDeploymentMutation,
  };
};
