import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { Batches, DataConnectionsBlock, DatasetBlock, Preview, RunsList } from "./components";
import { Run } from "./components/Run";

export const OptimiserRuns = ({
  versionId,
  additionalHeaders,
  setIsOptimisationConfigurationVisible,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [dataset, setDataset] = useState(null);

  const handleCreateRunButton = () => {
    setActiveStep(activeStep + 1);
  };

  const handleCreateDatasetButton = () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (activeStep > 3) {
      setIsOptimisationConfigurationVisible(false);
      return;
    }

    setIsOptimisationConfigurationVisible(true);
  }, [activeStep]);

  return (
    <>
      {activeStep == 0 && (
        <RunsList
          handleCreateRunButton={handleCreateRunButton}
          versionId={versionId}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 1 && (
        <DatasetBlock
          dataset={dataset}
          setDataset={setDataset}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleCreateDatasetButton={handleCreateDatasetButton}
        />
      )}
      {activeStep === 2 && (
        <DataConnectionsBlock activeStep={activeStep} setActiveStep={setActiveStep} />
      )}
      {activeStep === 3 && (
        <Preview setActiveStep={setActiveStep} dataset={dataset} versionId={versionId} />
      )}
      {activeStep === 4 && (
        <Batches
          setActiveStep={setActiveStep}
          versionId={versionId}
          dataset={dataset}
          additionalHeaders={additionalHeaders}
        />
      )}
      {activeStep === 5 && <Run setActiveStep={setActiveStep} />}
    </>
  );
};

OptimiserRuns.propTypes = {
  versionId: PropTypes.string,
  additionalHeaders: PropTypes.array,
  setIsOptimisationConfigurationVisible: PropTypes.func,
};
