import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// xplainable Dashboard components
import XBox from "components/XBox";
import XButton from "components/XButton";
import XTypography from "components/XTypography";

import { useApiKey } from "components/Authorisation/ApiKeyContext";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

import ModelsIcon from "assets/images/icons/recent/models.svg";
import DeploymentsIcon from "assets/images/icons/recent/deployments.svg";
import CollectionsIcon from "assets/images/icons/recent/collections.svg";
import ChevronRightIcon from "assets/images/icons/recent/chevron-right.svg";
import { useXplainableController } from "context";
import { useRecentQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";

function RecentAssets() {
  const { apiKey, activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const { data: recentData, isLoading } = useRecentQuery(activeWorkspace, apiKey, logout);

  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleButton = (asset_type, model_id, deployment_id, collection_id, name, description) => {
    if (asset_type === "model") {
      navigate(`/models/${model_id}`);
      return;
    }

    if (asset_type === "deployment") {
      navigate(`/deployments/${deployment_id}`);
      return;
    }

    if (asset_type === "collection") {
      navigate(`/collections/${model_id}/${collection_id}`, {
        state: {
          model_id,
          collection_id,
          title: name,
          description,
          contributors: [],
        },
      });
      return;
    }
  };

  useEffect(() => {
    if (!recentData) return;

    setData(recentData.data);
  }, [recentData]);

  if (isLoading) {
    return (
      <Card sx={{ height: "300px", overflow: "hidden" }}>
        <LoadingSpinner size={40} />
      </Card>
    );
  }

  return (
    <Card sx={{ padding: "18px", overflow: "hidden" }}>
      <XBox px={2} mb={2}>
        <XTypography fontSize="18px" fontWeight="medium" textTransform="capitalize">
          Recent
        </XTypography>
      </XBox>
      <XBox>
        <XBox
          component="ul"
          display="flex"
          flexDirection="column"
          m={0}
          p={0}
          sx={{ listStyle: "none" }}
        >
          {Array.isArray(data) && data.length !== 0 ? (
            data.map(
              ({ asset_type, model_id, deployment_id, collection_id, name, description }, key) => (
                <Fragment key={key}>
                  <XBox component="li" mb={3}>
                    <XBox alignItems="center" display="flex" justifyContent="space-between">
                      <XBox display="flex" gap="12px">
                        <XBox alignItems="center">
                          <BadgeIcon type={asset_type} />
                        </XBox>
                        <XBox display="flex" flexDirection="column" gpa="4px" maxWidth="200px">
                          <XTypography variant="subtitle2" fontSize="14px">
                            {name}
                          </XTypography>
                          <XTypography color="secondary" variant="caption" fontSize="14px">
                            {description}
                          </XTypography>
                        </XBox>
                      </XBox>
                      <XBox
                        src={ChevronRightIcon}
                        component="img"
                        onClick={() =>
                          handleButton(
                            asset_type,
                            model_id,
                            deployment_id,
                            collection_id,
                            name,
                            description
                          )
                        }
                      />
                    </XBox>
                  </XBox>
                </Fragment>
              )
            )
          ) : (
            <XBox
              display="flex"
              height={"100%"}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <XTypography style={{ color: "#7C7C7C" }} fontSize="14px" mb={4}>
                {"You haven't created any assets yet"}
              </XTypography>
              <XButton
                color="button"
                variant="gradient"
                sx={{ maxWidth: "120px", whiteSpace: "nowrap" }}
                onClick={() => {
                  window.open(
                    "https://docs.xplainable.io/docs/category/getting-started",
                    "_blank"
                  );
                }}
              >
                Show me how
              </XButton>
            </XBox>
          )}
        </XBox>
      </XBox>
    </Card>
  );
}

export default RecentAssets;

function BadgeIcon({ type }) {
  const [controller] = useXplainableController();

  const icon = (() => {
    switch (type) {
      case "model":
        return ModelsIcon;
      case "deployment":
        return DeploymentsIcon;
      case "api-key":
        return "ni ni-key-25";
      case "collection":
        return CollectionsIcon;
      default:
        return "";
    }
  })();

  return (
    <XBox
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgColor={controller.darkMode ? "#1D1B1B" : "#F7F7F8"}
      p={1}
    >
      <XBox src={icon} component="img"></XBox>
    </XBox>
  );
}

BadgeIcon.propTypes = {
  type: PropTypes.string,
};
