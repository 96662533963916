import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import Apis from "./components/Apis";
import * as Yup from "yup";
import PropTypes from "prop-types";

const validationSchema = Yup.object({
  orgName: Yup.string().required("Organisation name is required"),
  expiryDays: Yup.number().required("Expiry days is required"),
});

function ApiTable({ handleRowClick, deploymentId }) {
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    // this is replacement for a network call that would load the data from a server
    setTimeout(() => {
      setFormData([]);
    }, 1000);
  });

  return (
    <div className="app">
      <Formik initialValues={formData} validationSchema={validationSchema} enableReinitialize>
        <Form>
          <FieldArray name="friends">
            {(arrayHelpers) => (
              <Apis
                name="api-keys"
                handleAdd={arrayHelpers.push}
                handleRemove={arrayHelpers.remove}
                deploymentId={deploymentId}
              />
            )}
          </FieldArray>
        </Form>
      </Formik>
    </div>
  );
}

export default ApiTable;

// Typechecking props for the ProductCell
ApiTable.propTypes = {
  handleRowClick: PropTypes.func,
  deploymentId: PropTypes.number,
};
