import { keyframes } from '@mui/system';

export const animateGradient = keyframes`
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 20%;
  }
  100% {
    background-position: 0% 20%;
  }
`;