import { useAutoTrain } from "hooks";
import { Base64Image } from "../ChatAutoTrain";

import { ReactComponent as DeleteIcon } from "assets/images/icons/train/delete-button.svg";
import { ReactComponent as ChatImageErrorIcon } from "assets/images/icons/train/chat-image-error-icon.svg";

import { Box, Typography } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import { SavedPlotBox } from "layouts/autotrain/styled";
import { useXplainableController } from "context";

export const SavedPlots = () => {
  const { savedPlots, setSavedPlots } = useAutoTrain();
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const [isHovered, setIsHovered] = useState("");

  const handleDeletePlot = (item) => {
    const filteredPlots = savedPlots.filter((el) => el.raster !== item.raster);
    setSavedPlots(filteredPlots);
    setIsHovered("");
  };

  return (
    <Box sx={{ overflow: "scroll" }}>
      {savedPlots.length > 0 &&
        savedPlots.map((item) => (
          <SavedPlotBox
            key={item.id}
            onMouseEnter={() => setIsHovered(item.raster)}
            onMouseLeave={() => setIsHovered("")}
          >
            {item?.raster ? (
              <Box sx={{
                display: "flex", flexDirection: "column", gap: "16px"
              }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 4,
                    height: "60px",
                    color: isHovered ? "#000" : (darkMode ? "#FFF" : "#000")
                  }}
                >
                  <Typography sx={{
                    fontSize: "16px", fontWeight: "bold"
                  }}>
                    {item.title}
                  </Typography>
                  {isHovered === item.raster && (
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeletePlot(item)}
                    />
                  )}
                </Box>

                <Base64Image base64={item?.raster} />
              </Box>
            ) : (
              <ChatImageErrorIcon
                style={{ alignSelf: "center", width: "600px", height: "600px" }}
              />
            )}
          </SavedPlotBox>
        ))}
    </Box>
  );
};

