import {
  AppBar,
  Card,
  Collapse,
  Grid,
  Icon,
  Menu,
  MenuItem,
  Slider,
  Tab,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import XAvatar from "components/XAvatar";
import XBadge from "components/XBadge";
import XBox from "components/XBox";
import XButton from "components/XButton";
import XInput from "components/XInput";
import XProgress from "components/XProgress";
import XTypography from "components/XTypography";
import * as d3 from "d3";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import Papa from "papaparse";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ExpandableInfo from "shared/CollapsibleElements/ExpandableInfo";
import ScoreHistogram from "./components/ScoreHistogram";

import PlusIcon from "assets/images/icons/plus-icon.svg";
import SearchIcon from "assets/images/search-icon.svg";

//Helper Functions
import { ReactComponent as DarkDownloadIcon } from "assets/images/icons/collections/dark-download-icon.svg";
import { ReactComponent as ViolinIcon } from "assets/images/icons/violin-icon.svg";

import { useScenarioMutation } from "api/mutations";
import colors from "assets/theme/base/colors";
import { XImg } from "components/XImg";
import { useXplainableController } from "context";
import { getTimeDifference } from "shared/Functions/Date";
import Scatterplot from "./CollectionsScatter/components/ScatterPlot";

function CollectionItems({ returnedData, collectionId, modelId, collapsedWidth }) {
  const { activeWorkspace } = useApiKey();
  const { deleteScenarioMutation } = useScenarioMutation();
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const [searchInput, setSearchInput] = useState("");
  const [expandedRow, setExpandedRow] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scoreValue, setScoreValue] = useState([0, 100]);
  const [isLoading, setIsLoading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedScenarioId, setSelectedScenarioId] = useState(null);
  const [scores, setScores] = useState(returnedData?.map((d) => d.score));

  // Get the scores for the d3 plot
  // const scores = returnedData.map((d) => d.score);
  const minPoint = Math.min(...scores).toFixed(3);
  const maxPoint = Math.max(...scores).toFixed(3);
  const midPoint = ((parseFloat(maxPoint) + parseFloat(minPoint)) / 2).toFixed(2);
  const [threshold, setThreshold] = useState(parseFloat(midPoint));

  // Values for the card
  const [averageScore, setAverageScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [minScore, setMinScore] = useState(Infinity);
  const [pctPositive, setPctPositive] = useState(0);
  const [filteredReturnedData, setFilteredReturnedData] = useState([]);
  const [isViolinVisible, setIsViolinVisible] = useState(true);

  const [tabValue, setTabValue] = useState(0);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  // Define the range of colors
  const colorRange = ["#E14067", "#0080EA"];

  // Create the linear color scale
  const colorScale = d3.scaleLinear().domain([0, 100]).range(colorRange);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handleExpandRow = (rowIndex) => {
    setExpandedRow(rowIndex === expandedRow ? -1 : rowIndex);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Add the handleMenuClose function to your component
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const filterReturnedData = (data, searchInput) => {
    const searchRegex = new RegExp(searchInput, "i");

    // Add a condition to check if the index value matches the search input
    return data.filter((item) => {
      if (!item) return false;
      const withinProbaRange =
        item.score * 100 >= scoreValue[0] && item.score * 100 <= scoreValue[1];
      return (
        withinProbaRange && item["scenario_id"].toString().match(searchRegex) // Only match the index value
      );
    });
  };

  const minDistance = 5; // minimum distance between slider thumbs

  const handleChange = (event, newValue, activeThumb) => {
    //Close the expanded row
    setExpandedRow(-1);

    //TODO: manage the state of the component so it doesnt rerender the plot in the expanded info row

    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setScoreValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setScoreValue([clamped - minDistance, clamped]);
      }
    } else {
      setScoreValue(newValue);
    }
  };

  useEffect(() => {
    setFilteredReturnedData(filterReturnedData(returnedData, searchInput));
  }, [returnedData, searchInput, scoreValue]);

  const columnTypes = {
    user: {
      component: (result, key, index) => (
        <XBox my={1}>
          <Tooltip title={result[key].given_name + " " + result[key].family_name} key={index}>
            <XAvatar
              key={index}
              src={typeof result[key].image === "string" ? result[key].image : ""}
              alt="contributor profile"
              size="s"
              variant="rounded"
            />
          </Tooltip>
        </XBox>
      ),
    },
    scenario_number: {
      component: (result, key, index) => (
        <>
          <XBox
            bgColor={result["score"] < threshold ? "xppink" : "success"}
            width="10px"
            height="10px"
            borderRadius="lg"
          />
          {/* <XTypography variant="h5" fontSize="18px">{(result[key]).toString().padStart(Math.ceil(Math.log10(returnedData.length + 1)), '0')}</XTypography> */}
        </>
      ),
    },

    proba: {
      component: (result, key, index) => (
        <XBox width="100%" ml={1} display="flex" flexDirection="column" gap="4px">
          <XTypography variant="button" fontWeight="light" fontSize="12px">
            {(result[key] * 100).toFixed(2)}%
          </XTypography>
          <XProgress
            value={(result[key] * 100).toFixed(2)}
            color={colorScale((result[key] * 100).toFixed(2))}
            // label={true}
          />
        </XBox>
      ),
    },
    score: {
      component: (result, key, index) =>
        typeof result[key] === "number" && result[key] !== undefined ? (
          <XTypography fontSize="14px">{(result[key] * 100).toFixed(2)}</XTypography>
        ) : (
          <XTypography color="secondary">{result[key] * 100}</XTypography>
        ),
    },
    multiplier: {
      component: (result, key, index) =>
        typeof result[key] === "number" && result[key] !== undefined ? (
          <XTypography fontSize="14px">{result[key].toFixed(2)}x</XTypography>
        ) : (
          <XTypography color="secondary">{result[key]}x</XTypography>
        ),
    },
    // status: {
    //   component: (result, key, index) => review,
    // },
    added_date: {
      component: (result, key, index) => (
        <XBox>
          <XTypography fontSize="14px" textAlign="center">
            {getTimeDifference(result[key], true)}
          </XTypography>
        </XBox>
      ),
    },
    created: {
      component: (result, key, index) => (
        <XBox>
          <XTypography fontSize="14px" textAlign="center">
            {getTimeDifference(result[key], true)}
          </XTypography>
        </XBox>
      ),
    },
    function: {
      component: (result, key, index) => (
        <>
          <Icon
            fontSize="default"
            color="secondary"
            sx={{ cursor: "pointer" }}
            onClick={(event) => {
              event.persist();
              setMenuAnchorEl(event.currentTarget);
              setSelectedScenarioId(result["scenario_id"]); // Save the scenario_id here
            }}
          >
            more_vert
          </Icon>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem
              onClick={() => {
                deleteScenario(selectedScenarioId); // Use it here
                handleMenuClose();
              }}
            >
              <Icon>delete</Icon>
              <XTypography variant="caption" ml={1}>
                Delete
              </XTypography>
            </MenuItem>
          </Menu>
        </>
      ),
    },
  };

  const headerName = {
    user: "Creator",
    created: "Uploaded",
    added_date: "Created",
    scenario_number: "Scenario No.",
    proba: "Probability",
    score: "Score",
    multiplier: "Multiplier",
    support: "Support",
    status: "Status",
    display: "",
    function: "",
  };

  const review = (
    <XTypography variant="button" fontWeight="regular">
      For Review
    </XTypography>
  );

  // Badges
  const approved = (
    <XBadge variant="contained" color="success" size="xs" badgeContent="Approved" container />
  );

  const renderCell = (key, result, index) => {
    const columnType = columnTypes[key] || columnTypes.default;
    return columnType.component(result, key, index);
  };

  const debouncedCallback = useCallback(
    debounce(() => {
      // Calculate the average score
      const totalScore = filteredReturnedData.reduce((sum, data) => sum + data.score, 0);
      setAverageScore(((totalScore / filteredReturnedData.length) * 100).toFixed(2) || 0); // prevent division by 0

      // Calculate the maximum score
      setMaxScore(
        (Math.max(...filteredReturnedData.map((data) => data.score), 0) * 100).toFixed(2)
      );

      // Calculate the minimum score
      setMinScore((Math.min(...filteredReturnedData.map((data) => data.score)) * 100).toFixed(2));

      // Calculate the percentage of scores that are greater than the first threshold slider
      console.log("filteredReturnedData:", filteredReturnedData); // Add this
      const positiveScores = filteredReturnedData.filter((data) => data.score > threshold);
      setPctPositive(((positiveScores.length / filteredReturnedData.length) * 100).toFixed(2) || 0); // prevent division by 0
    }, 300),
    [filteredReturnedData, threshold]
  ); // Adjust the debounce delay (in milliseconds) as per your needs

  useEffect(() => {
    debouncedCallback(); // Call the debounced callback

    // Cleanup the debounced callback on unmount
    return () => debouncedCallback.cancel();
  }, [filteredReturnedData, threshold, debouncedCallback]);

  const deleteScenario = async (scenarioId) => {
    deleteScenarioMutation([activeWorkspace, modelId, collectionId, scenarioId], {
      onSuccess: () => {
        setFilteredReturnedData(
          filteredReturnedData.filter((scenario) => scenario.scenario_id !== scenarioId)
        );
        setScores(
          filteredReturnedData
            .filter((scenario) => scenario.scenario_id !== scenarioId)
            .map((d) => d.score)
        );
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Card sx={{ p: 2 }}>
            <XBox display="flex" gap={2} alignItems="center">
              <XTypography variant="Button" fontWeight="medium" fontSize="18px">
                Collection Score Distibution
              </XTypography>
              <XBox>
                <Tooltip
                  title="This chart shows the distribution of the scenarios selected in the collection."
                  placement="top"
                >
                  <XButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>question_mark</Icon>
                  </XButton>
                </Tooltip>
              </XBox>
            </XBox>
            <ScoreHistogram
              data={scores}
              threshold={threshold}
              setScoreValue={setScoreValue}
              collapsedWidth={collapsedWidth}
            />
          </Card>
        </Grid>
        <Grid item xs={12} lg={6} display="flex">
          <Card sx={{ p: 2, width: "100%" }}>
            <XBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <XBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <XTypography variant="button" fontWeight="light">
                  Threshold
                </XTypography>
                <XTypography variant="button" color="xpblue" fontSize="18px">
                  {threshold}
                </XTypography>
              </XBox>
              <XBox width="100%">
                <Slider
                  color={"dark"}
                  value={parseFloat(threshold)}
                  step={0.01}
                  min={0}
                  max={1}
                  onChange={(event, sliderValue) => {
                    setThreshold(parseFloat(sliderValue));
                    setExpandedRow(-1);
                  }}
                  sx={{
                    "& .MuiSlider-track": {
                      background: "#0D0C0C",
                    },

                    "& .MuiSlider-thumb": {
                      borderColor: "#0D0C0C",
                    },
                  }}
                />
              </XBox>
            </XBox>
            <XBox>
              <XTypography variant="h6" fontSize="18px">
                Prediction Overview
              </XTypography>
              <XBox my={1}>
                <XBox display="flex" justifyContent="space-between" mb="8px">
                  <XTypography variant="button" fontWeight="light">
                    Average Score
                  </XTypography>
                  <XTypography variant="button" fontWeight="light" color="secondary">
                    {averageScore}%
                  </XTypography>
                </XBox>
                <XBox display="flex">
                  <XBox width="100%">
                    <XProgress
                      sx={{ height: "8px" }}
                      value={averageScore}
                      color={colorScale(averageScore)}
                      location={"right"}
                    />
                  </XBox>
                </XBox>
              </XBox>

              <XBox my={1}>
                <XBox display="flex" justifyContent="space-between" mb="8px">
                  <XTypography variant="button" fontWeight="light">
                    Max Score
                  </XTypography>
                  <XTypography variant="button" fontWeight="light" color="secondary">
                    {maxScore}%
                  </XTypography>
                </XBox>
                <XBox display="flex">
                  <XBox width="100%">
                    <XProgress
                      value={maxScore}
                      color={colorScale(maxScore)}
                      location={"right"}
                      sx={{ height: "8px" }}
                    />
                  </XBox>
                </XBox>
              </XBox>

              <XBox my={1}>
                <XBox display="flex" justifyContent="space-between" mb="8px">
                  <XTypography variant="button" fontWeight="light">
                    Min Score
                  </XTypography>
                  <XTypography variant="button" fontWeight="light" color="secondary">
                    {minScore}%
                  </XTypography>
                </XBox>
                <XBox display="flex">
                  <XBox width="100%">
                    <XProgress
                      value={minScore}
                      color={colorScale(minScore)}
                      location={"right"}
                      sx={{ height: "8px" }}
                    />
                  </XBox>
                </XBox>
              </XBox>

              <XBox my={1}>
                <XBox display="flex" justifyContent="space-between" mb="8px">
                  <XTypography variant="button" fontWeight="light">
                    Pct Positive
                  </XTypography>
                  <XTypography variant="button" fontWeight="light" color="secondary">
                    {pctPositive}%
                  </XTypography>
                </XBox>
                <XBox display="flex">
                  <XBox width="100%">
                    <XProgress
                      value={pctPositive}
                      color={colorScale(pctPositive)}
                      location={"right"}
                      sx={{ height: "8px" }}
                    />
                  </XBox>
                </XBox>
              </XBox>
            </XBox>
          </Card>
        </Grid>
      </Grid>
      <XBox p={2}>
        <AppBar position="static">
          <Tabs
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{
              maxWidth: "700px",

              "& .Mui-selected": {
                color: `${colors["xpblue"].main} !important`,
                fontWeight: "600",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: colors["xpblue"].main,
              },
            }}
          >
            <Tab label="Local" sx={{ minWidth: 0, maxWidth: "max-content" }} />
            <Tab label="Global" sx={{ minWidth: 0, maxWidth: "max-content" }} />
          </Tabs>
        </AppBar>
      </XBox>
      {tabValue === 0 && (
        <Card sx={{ p: 2, mt: 2 }}>
          <XBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            mb={3}
          >
            <XTypography variant="button" fontSize="18px">
              Scenario
            </XTypography>

            <XBox display="flex" gap={1} alignItems="center">
              <Link to={`/models/${modelId}`}>
                <XButton sx={{ background: "transparent", boxShadow: "none" }}>
                  <XBox src={PlusIcon} component="img" />
                  <XTypography
                    fontSize="18px"
                    sx={{
                      backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    Add scenario
                  </XTypography>
                </XButton>
              </Link>
              <Tooltip title="Download the predictions to a csv file.">
                <XBox
                  onClick={() => {
                    handleDownload(filteredReturnedData);
                  }}
                  sx={{
                    borderRadius: "12px",
                    background: controller.darkMode ? "#1D1B1B" : "#F7F7F8",
                    p: "10px 12px",
                  }}
                >
                  <XImg>
                    <DarkDownloadIcon />
                  </XImg>
                </XBox>
              </Tooltip>
            </XBox>
          </XBox>
          <XBox display="flex" gap={3} alignItems="center" width="100%">
            <XInput
              placeholder="Scenario Number"
              startAdornment={<XBox src={SearchIcon} component="img" />}
              value={searchInput}
              onChange={handleSearchInput}
              sx={{ width: "100%" }}
            />
            <XBox display="flex" flexDirection="column" width="100%">
              <XBox display="flex" justifyContent="space-between" mb="4px">
                <XTypography variant="button" fontWeight="light">
                  Score Filter
                </XTypography>
              </XBox>
              <XBox display="flex" justifyContent="space-between">
                <XTypography variant="button" fontWeight="light">
                  0
                </XTypography>
                <XTypography variant="button" fontWeight="light">
                  100
                </XTypography>
              </XBox>
              <Slider
                getAriaLabel={() => "Minimum distance shift"}
                value={scoreValue}
                min={0}
                max={100}
                onChange={handleChange}
                valueLabelDisplay="auto"
                disableSwap
              />
            </XBox>
          </XBox>

          <Grid container>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <Table sx={{ width: "100%", tableLayout: "fixed", maxWidth: "100%" }}>
                <TableRow sx={{ width: "100%", tableLayout: "fixed", maxWidth: "100%" }}>
                  {Object.keys(columnTypes).map((key) => (
                    <TableCell key={key}>
                      <XBox
                        sx={{
                          display: "flex",
                          overflow: "hidden",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <XTypography variant="button" color="secondary" fontSize="12px">
                          {headerName[key]}
                        </XTypography>
                      </XBox>
                    </TableCell>
                  ))}
                </TableRow>
                <TableBody>
                  {filteredReturnedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result, index) => (
                      <>
                        <TableRow
                          key={`expandable-${index}`}
                          sx={{
                            "&:hover": {
                              backgroundColor: darkMode ? "#1D1B1B" : "#f5f5f5",
                            },
                          }}
                        >
                          {Object.keys(columnTypes).map((key) => (
                            <TableCell
                              onClick={() => {
                                if (key !== "function") {
                                  handleExpandRow(index);
                                }
                              }}
                              key={key}
                              sx={{
                                py: 0,
                                px: 0,
                                borderBottom: "none",
                                cursor: key !== "function" ? "pointer" : "default",
                              }}
                            >
                              <XBox
                                sx={{
                                  display: "flex",
                                  overflow: "hidden",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {renderCell(key, result, index)}
                              </XBox>
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow width={"100%"}>
                          <TableCell sx={{ borderBottom: "none", padding: 0 }} colSpan={8}>
                            <Collapse in={expandedRow === index} timeout="auto">
                              {isLoading ? (
                                <XTypography>Loading...</XTypography>
                              ) : (
                                returnedData.length > 0 && (
                                  <ExpandableInfo
                                    data={convertFormat(returnedData[index].scenario)}
                                    notes={returnedData[index]?.notes}
                                    collapsed={!(expandedRow === index)}
                                    activeWorkspace={activeWorkspace}
                                    modelId={modelId}
                                    collectionId={collectionId}
                                    scenarioId={returnedData[index].scenario_id}
                                  />
                                )
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                sx={{
                  width: "100%",
                  "& .MuiTablePagination-selectLabel": {
                    color: controller.darkMode ? "white !important" : "black !important",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    color: controller.darkMode ? "white !important" : "black !important",
                  },
                  "& .MuiButtonBase-root": {
                    color: controller.darkMode ? "white !important" : "black !important",
                  },
                }}
                component="div"
                count={filteredReturnedData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Card>
      )}
      {tabValue === 1 && (
        <Card sx={{ p: 2, mt: 2 }}>
          <XBox display="flex" justifyContent="space-between">
            <XTypography variant="button" fontSize="18px">
              Scatter Plot
            </XTypography>
            <XBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ paddingRight: "2rem" }}
            >
              {" "}
              {/* Adjust paddingRight as needed */}
              <Tooltip title={isViolinVisible ? "Hide distribution" : "Show distribution"}>
                <XBox
                  width="2.2rem"
                  height="2.2rem"
                  display="grid"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="md"
                  shadow="md"
                  bgColor={isViolinVisible ? "xpblue" : "white"}
                  variant="gradient"
                  sx={{
                    cursor: "pointer",
                    ":hover": {
                      opacity: 1,
                    },
                    "& svg": {
                      fill: isViolinVisible ? "white" : "xpblue",
                    },
                  }}
                  onClick={() => setIsViolinVisible(!isViolinVisible)}
                >
                  <ViolinIcon />
                </XBox>
              </Tooltip>
            </XBox>
          </XBox>
          <Scatterplot
            data={returnedData}
            width={800}
            height={400}
            isViolinVisible={isViolinVisible}
          />
        </Card>
      )}
    </>
  );
}

export default CollectionItems;

CollectionItems.propTypes = {
  collectionId: PropTypes.string,
  modelId: PropTypes.string,
  returnedData: PropTypes.array,
  collapsedWidth: PropTypes.bool,
};

Completion.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
};

function Completion({ value, color }) {
  return (
    <XBox display="flex" alignItems="center">
      <XBox width="8rem" ml={1}>
        <XProgress value={value} color={color} label={true} />
      </XBox>
    </XBox>
  );
}

function convertFormat(inputObject) {
  const outputArray = [];

  for (const key in inputObject) {
    const obj = {
      feature: key,
      value: inputObject[key].field === "-Infinity" ? null : inputObject[key].field,
      score: inputObject[key].value,
    };
    outputArray.push(obj);
  }

  return outputArray;
}

function handleDownload(data) {
  console.log("Download data: ", data);

  const processedData = data.map((item) => {
    const newItem = { ...item };

    // Process scenario object
    for (const key in newItem.scenario) {
      newItem[`${key}_field`] = newItem.scenario[key].field;
      newItem[`${key}_value`] = newItem.scenario[key].value;
      newItem[`${key}_checked`] = newItem.scenario[key].checked;
      // newItem[`scenario_${key}_end`] = newItem.scenario[key].end;
      // newItem[`scenario_${key}_class`] = newItem.scenario[key].class;
      // newItem[`scenario_${key}_start`] = newItem.scenario[key].start;
      // newItem[`scenario_${key}_keyName`] = newItem.scenario[key].keyName;
    }

    // Remove the original scenario object
    delete newItem.scenario;

    // Process user object
    newItem["username"] = newItem.user.username;
    newItem["position"] = newItem.user.position;
    // newItem['user_id'] = newItem.user.user_id;
    // newItem['given_name'] = newItem.user.given_name;
    // newItem['family_name'] = newItem.user.family_name;
    // newItem['image'] = newItem.user.image;

    // Remove the original user object
    delete newItem.user;

    return newItem;
  });

  const csv = Papa.unparse(processedData);

  // Create a Blob instance representing the data as a CSV
  var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

  // Use FileSaver to download the file
  saveAs(blob, "data.csv");
}
