import React from "react";
import { Card, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { Paths } from "settings";
import { useApp, useDropdownMenu } from "hooks";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useReportMutation } from "api/mutations/useReportMutation";
import { useTheme } from "@mui/styles";
import { useXplainableController } from "context";

import XBox from "components/XBox";
import XTypography from "components/XTypography";

import rgba from "assets/theme/functions/rgba";
import colors from "assets/theme/base/colors";

import DeleteIcon from "assets/images/icons/collections/delete-icon.svg";
import ReportIcon from "assets/images/icons/reports/report-icon.svg";
import ReportArrowIcon from "assets/images/icons/reports/report-arrow-icon.svg";
import DarkDotsVerticalIcon from "assets/images/icons/dark-dots-vertical-icon.svg";
import LightDotsVerticalIcon from "assets/images/icons/dark-dots-vertical-icon.svg";
import EventIcon from "shared/Icons/EventIcons";
import DarkCopyIcon from "assets/images/icons/deployments/dark-copy-icon.svg";
import LightCopyIcon from "assets/images/icons/deployments/light-copy-icon.svg";

import PropTypes from "prop-types";

export const ReportCard = ({ report, reports, setReports, index, openCopyModal }) => {
  const { viewState, activeSubscription } = useApiKey();
  const { setSelectedModel, setActiveTitle, setActiveStep } = useApp();
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const { deleteReportMutation } = useReportMutation();

  const theme = useTheme();
  const menuProps = useDropdownMenu(true);

  const handleOpenModal = () => {
    if (activeSubscription) {
      openCopyModal(report.report_id);
    }
  };

  const renderMenu = () => {
    const menuBackground = theme.palette.dark.main;

    return (
      <Menu
        anchorEl={menuProps.menu}
        open={Boolean(menuProps.menu)}
        onClose={menuProps.closeMenu}
        keepMounted
        PaperProps={{
          style: { backgroundColor: menuBackground },
        }}
      >
        <MenuItem
          onClick={() => {
            if (activeSubscription) {
              deleteReportMutation([report.report_id], {
                onSuccess: (data) => {
                  const filteredReports = reports?.filter(
                    (item) => item.report_id !== report.report_id
                  );

                  setReports(filteredReports);
                  menuProps.closeMenu();
                },
                onError: (err) => {
                  console.log(err);
                },
              });
            }
          }}
          disabled={!activeSubscription}
          style={{
            color: activeSubscription ? "#EA2C4C" : "grey",
            display: "flex",
            gap: 8,
            padding: 8,
          }}
        >
          <XBox src={DeleteIcon} component="img" />
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const cardActionStyles = ({ palette: { white }, typography: { size } }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",

    "& .material-icons-round": {
      ml: 0.5,
      fontSize: size.md,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round": {
      transform: "translateX(5px)",
    },
  });

  const renderViewReport = (
    <XTypography
      variant="button"
      textTransform="capitalize"
      fontWeight="medium"
      color={activeSubscription ? "xpblue" : "secondary"}
      sx={cardActionStyles}
    >
      View report
      <XBox src={ReportArrowIcon} component="img" />
    </XTypography>
  );

  return (
    <XBox
      key={index}
      m={1}
      sx={{
        opacity: 0,
        width: { xs: "100%", sm: "330px" },
        transform: "translateY(20px)",
        animation: "slide-up 0.5s forwards",
        animationDelay: `${index * 0.03}s`,
        "@keyframes slide-up": {
          "100%": {
            opacity: 1,
            transform: "translateY(0)",
          },
        },
      }}
    >
      <Tooltip title={activeSubscription ? "" : "Renew your subscription to view"}>
        <Card
          sx={{
            overflow: "none",
            zIndex: 0,
            width: { xs: "100%", sm: "330px" },
            justifyContent: { xs: "center", lg: "flex-start" },
            backgroundColor: theme.palette.dark,
            transition: "background-color 300ms, transform 300ms, box-shadow 300ms",
            boxShadow: activeSubscription ? "0px 0px 10px rgba(0, 0, 0, 0.25)" : "none",
            "&:hover": activeSubscription
              ? {
                  transform: "translateY(-2px)",
                  boxShadow: `0px 0px 20px ${rgba(colors.xpblue.main, 3)}`,
                }
              : undefined,
          }}
          m={3}
        >
          <XBox p={2} overflow="visible">
            <XBox display="flex" alignItems="end" justifyContent="space-between">
              <XBox
                display="flex"
                alignItems="center"
                gap={1}
                px="12px"
                py={1}
                bgColor={controller.darkMode ? "#1D1B1B" : "#F7F7F8"}
                sx={{ borderRadius: "24px" }}
              >
                <XBox component="img" src={ReportIcon} />
                <XTypography color="xpblue" fontSize="12px">
                  Reports
                </XTypography>
              </XBox>
              <XBox
                display="flex"
                position="absolute"
                top={0}
                right={10}
                alignItems="center"
                zIndex={1}
                p={1}
                bgColor="light"
                borderRadius="10px"
                mr={1}
                mt={1}
              >
                <EventIcon
                  onClick={activeSubscription ? handleOpenModal : undefined}
                  tooltipText={
                    activeSubscription
                      ? "Create new version from this report"
                      : "Renew your subscription to use this feature"
                  }
                  successToast={false}
                  icon={
                    <XBox
                      component="img"
                      fontColor={"xpblue"}
                      src={darkMode ? LightCopyIcon : DarkCopyIcon}
                    />
                  }
                />
              </XBox>
            </XBox>
            <XBox mt={2} lineHeight={1}>
              <XBox
                sx={{
                  maxHeight: "30px",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                }}
              >
                <XTypography
                  fontSize="18px"
                  variant="button"
                  color={activeSubscription ? "dark" : "secondary"}
                >
                  {report.report_name}
                </XTypography>
              </XBox>
              <Stack position="relative" mt={1} mb={3}>
                <XBox
                  sx={{
                    maxHeight: "40px",
                    minHeight: "40px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  <XTypography
                    variant="button"
                    fontWeight="regular"
                    color={activeSubscription ? "text" : "light"}
                  >
                    {report.report_description}
                  </XTypography>
                </XBox>
              </Stack>
            </XBox>
            <XBox
              display="flex"
              lineHeight={0}
              justifyContent="space-between"
              alignItems="center"
            >
              {viewState === "creator" && (
                <>
                  <XBox
                    src={
                      controller.darkMode
                        ? LightDotsVerticalIcon
                        : DarkDotsVerticalIcon
                    }
                    component="img"
                    onClick={
                      activeSubscription && menuProps.openOnClick
                        ? menuProps.openMenu
                        : undefined
                    }
                  />
                  {renderMenu()}
                </>
              )}
              {activeSubscription ? (
                <Link
                  to={{
                    pathname: `${Paths.reports.index}/${report.model_id}`,
                    search: `report_id=${report.report_id}`,
                  }}
                  onClick={() => {
                    setSelectedModel(report.model_id);
                    setActiveTitle(report.report_name);
                    setActiveStep(1);
                  }}
                  state={{
                    id: report.report_id,
                    reportName: report.report_name,
                    reportDescription: report.report_description,
                  }}
                >
                  {renderViewReport}
                </Link>
              ) : (
                renderViewReport
              )}
            </XBox>
          </XBox>
        </Card>
      </Tooltip>
    </XBox>
  );
};

ReportCard.propTypes = {
  report: PropTypes.object,
  setIsModalOpen: PropTypes.bool,
  openCopyModal: PropTypes.func,
  setReports: PropTypes.func,
  index: PropTypes.number,
  reports: PropTypes.array,
  setModalTitle: PropTypes.func,
};
