import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useCalendarQuery = (logout) => {
  return useQuery(
    [QUERY_CONSTANTS.CALENDAR],
    () => apiHandlers.calendar.getCalendarData(),
    {
      cacheTime: Infinity,  // The data will remain in the cache indefinitely unless manually invalidated
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
