import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// @mui material components
import { Card, Icon, MenuItem } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XButton from "components/XButton";
import XTypography from "components/XTypography";
import { useInviteMutation } from "api/mutations/useInvitationsMutation";
import { useInvitationsQuery, useRequestsQuery } from "api/query";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import { useToast } from "hooks";
import { getTimeDifference } from "shared/Functions/Date";
import RequestItem from "shared/Items/RequestItem";

function Requests() {
  const { requests, setRequests, invites, setInvites } = useApiKey();
  const { logout } = useAuth0();
  const { showSuccessToast, showErrorToast } = useToast();

  const { handleInvitationMutation } = useInviteMutation();
  const { data: inviteData } = useInvitationsQuery(logout);
  const { data: requestData } = useRequestsQuery(logout);

  useEffect(() => {
    if (!inviteData) return;

    setInvites(inviteData?.data);
  }, [inviteData]);

  useEffect(() => {
    if (!requestData) return;

    setRequests(requestData?.data);
  }, [requestData]);

  //Accept/Decline the request
  const handleRequest = async(requestId, type, action, team_name) => {
    handleInvitationMutation([type, requestId, action], {
      onSuccess: () => {
        setRequests((prevRequests) =>
          prevRequests.filter((request) => request?.request_id !== requestId)
        );
        if (action === "accept") {
          showSuccessToast(`You've been added to ${team_name}, wait while we refresh the page`);
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
          return;
        }
        showErrorToast("Request has been declined");
      },
      onError: () => {
        console.error("Error:", error);
      },
    });
  };

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <XBox px={3} mt={2}>
        <XTypography fontSize="18px" fontWeight="medium" textTransform="capitalize">
          Requests
        </XTypography>
      </XBox>
      <XBox mb={4} px={3}>
        <XBox
          component="ul"
          display="flex"
          flexDirection="column"
          m={0}
          p={0}
          sx={{ listStyle: "none" }}
        >
          {Array.isArray(requests) && requests?.length > 0 ? (
            requests.map((request) => (
              <RequestItem
                key={request.request_id}
                image={
                  <XBox sx={{ position: "relative" }}>
                    <img
                      src={request.organisation.logo_link}
                      sx={{ width: "100%", height: "auto" }}
                    />
                    <XBox sx={{ position: "absolute", bottom: "0px", right: "-10px" }}>
                      <XBox
                        bgColor={rgba(colors.xpblue.main, 0.8)}
                        color="white"
                        p={0.5}
                        borderRadius="8px"
                        sx={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon>{request?.team?.display_icon}</Icon>
                      </XBox>
                    </XBox>
                  </XBox>
                }
                title={[
                  `${request?.requested_by?.given_name} ${request?.requested_by?.family_name}`,
                  `requested to join ${request?.team?.name}`,
                ]}
                date={getTimeDifference(request?.created, true)}
                confirm={
                  <XBox
                    ml={1.5}
                    mr={0.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <XButton
                      onClick={() => handleRequest(request?.request_id, "requests", "approve")}
                      variant="gradient"
                      color="success"
                      size="small"
                      circular
                      iconOnly
                      sx={{
                        transition: "box-shadow 0.1s ease-in-out",
                        ":hover": {
                          boxShadow: "0px 0px 10px rgba(0, 230, 64, 0.5)",
                        },
                      }}
                    >
                      <Icon>check</Icon>
                    </XButton>
                  </XBox>
                }
                deny={
                  <XBox mx={0.5} display="flex" justifyContent="center" alignItems="center">
                    <XButton
                      onClick={() => handleRequest(request?.request_id, "requests", "reject")}
                      variant="gradient"
                      color="error"
                      size="small"
                      circular
                      iconOnly
                      sx={{
                        transition: "box-shadow 0.1s ease-in-out",
                        ":hover": {
                          boxShadow: "0px 0px 10px rgba(255, 0, 0, 0.5)",
                        },
                      }}
                    >
                      <Icon>clear</Icon>
                    </XButton>
                  </XBox>
                }
              />
            ))
          ) : (
            <MenuItem sx={{ padding: 0, color: "#7C7C7C" }}>
              You currently have no active requests
            </MenuItem>
          )}
        </XBox>
      </XBox>

      <XBox px={3}>
        <XTypography fontSize="18px" fontWeight="medium" textTransform="capitalize">
          Invitations
        </XTypography>
      </XBox>
      <XBox pb={3} px={3}>
        <XBox
          component="ul"
          display="flex"
          flexDirection="column"
          m={0}
          p={0}
          sx={{ listStyle: "none" }}
        >
          {Array.isArray(invites) && invites.length > 0 ? (
            invites.map((invite) => (
              <RequestItem
                key={invite.request_id}
                image={
                  <XBox sx={{ position: "relative" }}>
                    <img
                      src={invite.organisation.logo_link}
                      sx={{ width: "100%", height: "auto" }}
                    />
                    <XBox sx={{ position: "absolute", bottom: "0px", right: "-10px" }}>
                      <XBox
                        bgColor={rgba(colors.xpblue.main, 0.8)}
                        color="white"
                        p={0.5}
                        borderRadius="8px"
                        sx={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon>{invite?.team?.display_icon}</Icon>
                      </XBox>
                    </XBox>
                  </XBox>
                }
                title={[
                  `${invite?.inviter?.given_name} ${invite?.inviter?.family_name}`,
                  `invited you to join ${invite?.team?.name}`,
                ]}
                date={getTimeDifference(invite?.created, true)}
                confirm={
                  <XBox
                    ml={1.5}
                    mr={0.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <XButton
                      onClick={() =>
                        handleRequest(
                          invite?.team?.id,
                          "invitations",
                          "accept",
                          invite?.team?.team_name
                        )
                      }
                      variant="gradient"
                      color="success"
                      size="small"
                      circular
                      iconOnly
                      sx={{
                        transition: "box-shadow 0.1s ease-in-out",
                        ":hover": {
                          boxShadow: "0px 0px 10px rgba(0, 230, 64, 0.5)",
                        },
                      }}
                    >
                      <Icon>check</Icon>
                    </XButton>
                  </XBox>
                }
                deny={
                  <XBox mx={0.5} display="flex" justifyContent="center" alignItems="center">
                    <XButton
                      onClick={() => 
                        handleRequest(
                          invite?.team?.id, 
                          "invitations", 
                          "decline"
                        )}
                      variant="gradient"
                      color="error"
                      size="small"
                      circular
                      iconOnly
                      sx={{
                        transition: "box-shadow 0.1s ease-in-out",
                        ":hover": {
                          boxShadow: "0px 0px 10px rgba(255, 0, 0, 0.5)",
                        },
                      }}
                    >
                      <Icon>clear</Icon>
                    </XButton>
                  </XBox>
                }
              />
            ))
          ) : (
            <MenuItem sx={{ padding: 0, color: "#7C7C7C" }}>
              You currently have no active invitations
            </MenuItem>
          )}
        </XBox>
      </XBox>
    </Card>
  );
}

export default Requests;
