/** 
  All of the routes for the xplainable dashboard are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// xplainable dashboard layouts
import Login from "components/Authorisation/Login";
import Home from "layouts/home";
import Model from "layouts/models/model";
import Deployment from "layouts/deployments/single-deployment";
import Collection from "layouts/collections/collection";
import AllModels from "layouts/models/all-models";
import AllDeployments from "layouts/deployments/all-deployments";
import ApiKeys from "layouts/api-keys";
import NewUser from "layouts/first-login";
import AllCollections from "layouts/collections/all-collections";
import AdminPanel from "layouts/admin";
import SubscriptionPanel from "layouts/subscriptions";

// import Reports from "layouts/pages/users/reports";
// import NewUser from "layouts/pages/users/new-user";
// import Settings from "layouts/pages/account/settings";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
// import Security from "layouts/pages/account/security";
// import General from "layouts/pages/projects/general";
// import Timeline from "layouts/pages/projects/timeline";
// import NewProject from "layouts/pages/projects/new-project";
// import Widgets from "layouts/pages/widgets";
// import Charts from "layouts/pages/charts";
// import SweetAlerts from "layouts/pages/sweet-alerts";
// import Notifications from "layouts/pages/notifications";
// import RTL from "layouts/pages/rtl";
// import Kanban from "layouts/applications/kanban";
// import Wizard from "layouts/applications/wizard";
// import DataTables from "layouts/applications/data-tables";
// import Calendar from "layouts/applications/calendar";
// import Analytics from "layouts/applications/analytics";
// import Overview from "layouts/ecommerce/overview";
// import NewProduct from "layouts/ecommerce/products/new-product";
// import EditProduct from "layouts/ecommerce/products/edit-product";
// import ProductPage from "layouts/ecommerce/products/product-page";
// import ProductsList from "layouts/ecommerce/products/products-list";
// import OrderList from "layouts/ecommerce/orders/order-list";
// import OrderDetails from "layouts/ecommerce/orders/order-details";
// import Referral from "layouts/ecommerce/referral";
// import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpBasic from "layouts/authentication/sign-up/basic";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import SignUpIllustration from "layouts/authentication/sign-up/illustration";
// import ResetBasic from "layouts/authentication/reset-password/basic";
// import ResetCover from "layouts/authentication/reset-password/cover";
// import ResetIllustration from "layouts/authentication/reset-password/illustration";
// import LockBasic from "layouts/authentication/lock/basic";
// import LockCover from "layouts/authentication/lock/cover";
// import LockIllustration from "layouts/authentication/lock/illustration";
// import VerificationBasic from "layouts/authentication/2-step-verification/basic";
// import VerificationCover from "layouts/authentication/2-step-verification/cover";
// import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
// import Error404 from "layouts/authentication/error/404";
// import Error500 from "layouts/authentication/error/500";

// xplainable Dashboard components

import { Paths } from "settings";
import { CreateReport } from "layouts/reports/ReportBuilder/components/CreateReport";
import { ModelProvider } from "context/useModelContext";
import { BatchOptimisation } from "layouts/batchOptimisation/BatсhOptimisation";
import { AllBatchOptimisation } from "layouts/batchOptimisation/AllBatchOptimisations";
import { AdminProvider } from "context/useAdminContext";
import { Teams, Users } from "layouts/admin/components";
import { TextEditor } from "layouts/textEditor/report";

import LightHomeIcon from "assets/images/icons/sidebar/light-home-icon.svg";
import LightModelsIcon from "assets/images/icons/sidebar/light-models-icon.svg";
import LightDeploymentsIcon from "assets/images/icons/sidebar/light-deployments-icon.svg";
import LightCollectionsIcon from "assets/images/icons/sidebar/light-collections-icon.svg";
import LightReportsIcon from "assets/images/icons/sidebar/light-reports-icon.svg";
import LightBatchesIcon from "assets/images/icons/sidebar/light-batches-icon.svg";
import LightAdminIcon from "assets/images/icons/sidebar/light-admin-icon.svg";
import LightApiKeyIcon from "assets/images/icons/sidebar/light-api-key-icon.svg";
import LightExperimentsIcon from "assets/images/icons/sidebar/light-experiments-icon.svg";
import LightSubscriptionIcon from "assets/images/icons/sidebar/light-subscription-icon.svg";
import LightBlogIcon from "assets/images/icons/sidebar/light-blog-icon.svg";
import LightSupportIcon from "assets/images/icons/sidebar/light-support-icon.svg";
import LightRequestIcon from "assets/images/icons/sidebar/light-request-icon.svg";
import LightAutoTrainIcon from "assets/images/icons/sidebar/light-auto-train-icon.svg";
import LightDataSetsIcon from "assets/images/icons/datasets/white-datasets-icon.svg";
import LightAnalystIcon from "assets/images/icons/sidebar/light-analyst-icon.svg";

import DarkHomeIcon from "assets/images/icons/sidebar/dark-home-icon.svg";
import DarkModelsIcon from "assets/images/icons/sidebar/dark-models-icon.svg";
import DarkDeploymentIcon from "assets/images/icons/sidebar/dark-deployments-icon.svg";
import DarkCollectionIcon from "assets/images/icons/sidebar/dark-collections-icon.svg";
import DarkReportIcon from "assets/images/icons/sidebar/dark-reports-icon.svg";
import DarkApiKeyIcon from "assets/images/icons/sidebar/dark-api-key-icon.svg";
import DarkBatchesIcon from "assets/images/icons/sidebar/dark-batches-icon.svg";
import DarkAdminIcon from "assets/images/icons/sidebar/dark-admin-icon.svg";
import DarkExperimentsIcon from "assets/images/icons/sidebar/dark-experiments-icon.svg";
import DarkSubscriptionIcon from "assets/images/icons/sidebar/dark-subscription-icon.svg";
import DarkSupportIcon from "assets/images/icons/sidebar/dark-support-icon.svg";
import DarkBlogIcon from "assets/images/icons/sidebar/dark-blog-icon.svg";
import DarkRequestIcon from "assets/images/icons/sidebar/dark-request-icon.svg";
import DarkAutoTrainIcon from "assets/images/icons/sidebar/dark-auto-train-icon.svg";
import DarkDataSetsIcon from "assets/images/icons/datasets/dark-datasets-icon.svg";
import DarkAnalystIcon from "assets/images/icons/sidebar/dark-analyst-icon.svg";

import { AllReports } from "layouts/textEditor/all-reports";
import { AutoTrain } from "layouts/autotrain";
import { AutoTrainProvider } from "context/AutoTrainContext";
import { DataSets } from "layouts/datasets";
import { Analyst } from "layouts/analyst";

const isDevelopment = process.env.REACT_APP_NODE_ENV === "DEV";

const routes = [
  {
    key: "login",
    route: Paths.login,
    component: <Login />,
    protected: false,
    creator: false
  },

  {
    key: "new-user",
    route: Paths.newUser,
    component: <NewUser />,
    protected: true,
    creator: false
  },
  {
    key: "models",
    route: Paths.models.id,
    component: (
      <ModelProvider>
        <Model />
      </ModelProvider>
    ),
    protected: true,
    creator: false
  },
  {
    key: "deployments",
    route: Paths.deployments.id,
    component: <Deployment />,
    protected: true,
    creator: true
  },
  {
    key: "collection",
    route: Paths.collections.id,
    component: <Collection />,
    protected: true,
    creator: false
  },
  {
    key: "report",
    route: Paths.reports.id,
    component: (
      <ModelProvider>
        <TextEditor />
      </ModelProvider>
    ),
    protected: true,
    creator: false
  },
  {
    key: "batch",
    route: Paths.batchOptimisation.id,
    component: (
      <ModelProvider>
        <BatchOptimisation />
      </ModelProvider>
    ),
    protected: true,
    creator: true
  },
  {
    key: "admin",
    route: Paths.admin.organisationId,
    component: (
      <AdminProvider>
        <Teams />
      </AdminProvider>
    ),
    protected: true,
    creator: true
  },
  {
    key: "admin",
    route: Paths.admin.teamId,
    component: (
      <AdminProvider>
        <Users />
      </AdminProvider>
    ),
    protected: true,
    creator: true
  },
  {
    type: "collapse",
    name: "Home",
    key: "home",
    unActiveIcon: DarkHomeIcon,
    activeIcon: LightHomeIcon,
    route: Paths.index,
    component: <Home />,
    noCollapse: true,
    protected: true,
    creator: false
  },
  { type: "title", title: "Assets", key: "title-assets" },
  {
    type: "collapse",
    name: "Datasets",
    key: "datasets",
    unActiveIcon: DarkDataSetsIcon,
    activeIcon: LightDataSetsIcon,
    route: Paths.datasets.index,
    component: <DataSets />,
    noCollapse: true,
    protected: true,
    creator: true
  },
  {
    type: "collapse",
    name: "Analyst",
    key: "analyst",
    unActiveIcon: DarkAnalystIcon,
    activeIcon: LightAnalystIcon,
    route: Paths.analyst.index,
    component: 
      <AutoTrainProvider>
        <Analyst />
      </AutoTrainProvider>,
    noCollapse: true,
    protected: true,
    creator: false
  },
  {
    type: "collapse",
    name: "AutoTrain",
    isBeta: true,
    key: "auto-train",
    unActiveIcon: DarkAutoTrainIcon,
    activeIcon: LightAutoTrainIcon,
    route: Paths.autoTrain.index,
    component: 
      <AutoTrainProvider>
        <AutoTrain />
      </AutoTrainProvider>,
    noCollapse: true,
    protected: true,
    creator: true
  },
  {
    type: "collapse",
    name: "Models",
    key: "models",
    route: Paths.models.index,
    unActiveIcon: DarkModelsIcon,
    activeIcon: LightModelsIcon,
    component: <AllModels />,
    noCollapse: true,
    protected: true,
    creator: false
  },
  {
    type: "collapse",
    name: "Deployments",
    key: "deployments",
    route: Paths.deployments.index,
    unActiveIcon: DarkDeploymentIcon,
    activeIcon: LightDeploymentsIcon,
    component: <AllDeployments />,
    noCollapse: true,
    protected: true,
    creator: false
  },
  {
    type: "collapse",
    name: "Collections",
    key: "collections",
    route: Paths.collections.index,
    unActiveIcon: DarkCollectionIcon,
    activeIcon: LightCollectionsIcon,
    component: <AllCollections />,
    noCollapse: true,
    protected: true,
    creator: false

  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    unActiveIcon: DarkReportIcon,
    activeIcon: LightReportsIcon,
    route: Paths.reports.index,
    component: <AllReports />,
    noCollapse: true,
    protected: true,
    creator: false
  },
  {
    type: "collapse",
    name: "Batch Optimisation",
    key: "batch-optimisation",
    unActiveIcon: DarkBatchesIcon,
    activeIcon: LightBatchesIcon,
    route: isDevelopment ? Paths.batchOptimisation.index : Paths.index,
    component: isDevelopment ? <AllBatchOptimisation /> : <></>,
    noCollapse: true,
    protected: true,
    isBeta: true,
    creator: true
  },
  {
    type: "collapse",
    name: "API Keys",
    key: "api-keys",
    unActiveIcon: DarkApiKeyIcon,
    activeIcon: LightApiKeyIcon,
    route: Paths.apiKeys,
    component: <ApiKeys />,
    noCollapse: true,
    protected: true,
    creator: true
  },
  { type: "title", 
    title: "Settings", 
    key: "settings",
    creator: true
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    unActiveIcon: DarkAdminIcon,
    activeIcon: LightAdminIcon,
    route: Paths.admin.index,
    component: (
      <AdminProvider>
        <AdminPanel />
      </AdminProvider>
    ),
    noCollapse: true,
    protected: true,
    creator: true
  },
  {
    type: "collapse",
    name: "Subscription",
    key: "subscriptions",
    unActiveIcon: DarkSubscriptionIcon,
    activeIcon: LightSubscriptionIcon,
    route: Paths.subscriptions,
    component: <SubscriptionPanel />,
    noCollapse: true,
    protected: true,
    creator: true
  },
  { type: "title", title: "Resources", key: "resource-docs" },
  {
    type: "collapse",
    name: "Blog",
    key: "blog",
    href: "https://www.xplainable.io/resources",
    unActiveIcon: DarkBlogIcon,
    activeIcon: LightBlogIcon,
    noCollapse: true,
    creator: false
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    href: "https://www.xplainable.io/contact-us",
    unActiveIcon: DarkSupportIcon,
    activeIcon: LightSupportIcon,
    noCollapse: true,
    creator: false
  },
  {
    type: "request",
    name: "Send Request",
    key: "Send Request",
    unActiveIcon: DarkRequestIcon,
    activeIcon: LightRequestIcon,
    noCollapse: true,
    creator: false
  },
  // {
  //   type: "collapse",
  //   name: "Pricing Page",
  //   key: "pricing-page",
  //   route: "/pages/pricing-page",
  //   component: <PricingPage />,
  //   noCollapse: true,
  //   protected: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <XBox component="i" color="warning" fontSize="14px" className="ni ni-ungroup" />,
  //   protected: true,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "Teams",
  //           key: "teams",
  //           route: "/pages/profile/teams",
  //           component: <Teams />,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "Reports",
  //           key: "reports",
  //           route: "/pages/users/reports",
  //           component: <Reports />,
  //         },
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings />,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing />,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice />,
  //         },
  //         {
  //           name: "Security",
  //           key: "security",
  //           route: "/pages/account/security",
  //           component: <Security />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "General",
  //           key: "general",
  //           route: "/pages/projects/general",
  //           component: <General />,
  //         },
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline />,
  //         },
  //         {
  //           name: "New Project",
  //           key: "new-project",
  //           route: "/pages/projects/new-project",
  //           component: <NewProject />,
  //         },
  //       ],
  //     },

  //     { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
  //     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
  //     {
  //       name: "Sweet Alerts",
  //       key: "sweet-alerts",
  //       route: "/pages/sweet-alerts",
  //       component: <SweetAlerts />,
  //     },
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <XBox component="i" color="info" fontSize="14px" className="ni ni-ui-04" />,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban />,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/applications/analytics",
  //       component: <Analytics />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <XBox component="i" color="success" fontSize="14px" className="ni ni-archive-2" />,
  //   collapse: [
  //     {
  //       name: "Overview",
  //       key: "overview",
  //       route: "/ecommerce/overview",
  //       component: <Overview />,
  //     },
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //         {
  //           name: "Products List",
  //           key: "products-list",
  //           route: "/ecommerce/products/products-list",
  //           component: <ProductsList />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Referral",
  //       key: "referral",
  //       route: "/ecommerce/referral",
  //       component: <Referral />,
  //     },
  //   ],
  // },

  // {
  //   type: "collapse",
  //   name: "Authentication",
  //   key: "authentication",
  //   icon: <XBox component="i" color="error" fontSize="14px" className="ni ni-single-copy-04" />,
  //   collapse: [
  //     {
  //       name: "Sign In",
  //       key: "sign-in",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/sign-in/basic",
  //           component: <SignInBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-in/cover",
  //           component: <SignInCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/sign-in/illustration",
  //           component: <SignInIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Sign Up",
  //       key: "sign-up",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/sign-up/basic",
  //           component: <SignUpBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-up/cover",
  //           component: <SignUpCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/sign-up/illustration",
  //           component: <SignUpIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Reset Password",
  //       key: "reset-password",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/reset-password/basic",
  //           component: <ResetBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/reset-password/cover",
  //           component: <ResetCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/reset-password/illustration",
  //           component: <ResetIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Lock",
  //       key: "lock",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/lock/basic",
  //           component: <LockBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/lock/cover",
  //           component: <LockCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/lock/illustration",
  //           component: <LockIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "2-Step Verification",
  //       key: "2-step-verification",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/verification/basic",
  //           component: <VerificationBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/verification/cover",
  //           component: <VerificationCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/verification/illustration",
  //           component: <VerificationIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Error",
  //       key: "error",
  //       collapse: [
  //         {
  //           name: "Error 404",
  //           key: "error-404",
  //           route: "/authentication/error/404",
  //           component: <Error404 />,
  //         },
  //         {
  //           name: "Error 500",
  //           key: "error-500",
  //           route: "/authentication/error/500",
  //           component: <Error500 />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  //     {
  //       name: "Foundation",
  //       key: "foundation",
  //       collapse: [
  //         {
  //           name: "Colors",
  //           key: "colors",
  //           href: "https://www.xplainable.io/learning-lab/react/colors/xplainable-dashboard/",
  //         },
  //         {
  //           name: "Grid",
  //           key: "grid",
  //           href: "https://www.xplainable.io/learning-lab/react/grid/xplainable-dashboard/",
  //         },
  //         {
  //           name: "Typography",
  //           key: "base-typography",
  //           href: "https://www.xplainable.io/learning-lab/react/base-typography/xplainable-dashboard/",
  //         },
  //         {
  //           name: "Borders",
  //           key: "borders",
  //           href: "https://www.xplainable.io/learning-lab/react/borders/xplainable-dashboard/",
  //         },
  //         {
  //           name: "Box Shadows",
  //           key: "box-shadows",
  //           href: "https://www.xplainable.io/learning-lab/react/box-shadows/xplainable-dashboard/",
  //         },
  //         {
  //           name: "Functions",
  //           key: "functions",
  //           href: "https://www.xplainable.io/learning-lab/react/functions/xplainable-dashboard/",
  //         },
  //         {
  //           name: "Routing System",
  //           key: "routing-system",
  //           href: "https://www.xplainable.io/learning-lab/react/routing-system/xplainable-dashboard/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <XBox component="i" color="inherit" fontSize="14px" className="ni ni-app" />,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://www.xplainable.io/learning-lab/react/alerts/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://www.xplainable.io/learning-lab/react/avatar/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://www.xplainable.io/learning-lab/react/badge/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://www.xplainable.io/learning-lab/react/badge-dot/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://www.xplainable.io/learning-lab/react/box/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://www.xplainable.io/learning-lab/react/buttons/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://www.xplainable.io/learning-lab/react/datepicker/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://www.xplainable.io/learning-lab/react/dropzone/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://www.xplainable.io/learning-lab/react/quill/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://www.xplainable.io/learning-lab/react/input/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://www.xplainable.io/learning-lab/react/pagination/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://www.xplainable.io/learning-lab/react/progress/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Select",
  //       key: "select",
  //       href: "https://www.xplainable.io/learning-lab/react/select/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://www.xplainable.io/learning-lab/react/snackbar/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://www.xplainable.io/learning-lab/react/social-buttons/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Tag Input",
  //       key: "tag-input",
  //       href: "https://www.xplainable.io/learning-lab/react/tag-input/xplainable-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://www.xplainable.io/learning-lab/react/typography/xplainable-dashboard/",
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "",
  //   icon: <XBox component="i" color="inherit" fontSize="14px" className="ni ni-align-left-2" />,
  //   noCollapse: true,
  // },
];

export default routes;
