import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XInput from "components/XInput";
import XSelect from "components/XSelect";

function FormField({ label, name, as, ...rest }) {
  return (
    <XBox mb={1.5}>
      {label && (
        <XBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <XTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {label}
          </XTypography>
        </XBox>
      )}

      <Field {...rest} name={name} as={as === "input" ? XInput : XSelect} />
      <XBox mt={0.75}>
        <XTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </XTypography>
      </XBox>
    </XBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  as: PropTypes.string,
};

export default FormField;
