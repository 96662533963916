import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import Subscriptions from "./components/subscriptions";
import Grid from "@mui/material/Grid";
import { animateGradient } from "assets/theme/base/animations";

function SubscriptionPanel() {
  const { user } = useApiKey();
  const [controller, dispatch] = useXplainableController();
  const { globalImage, globalColor } = controller;

  return (
    <DashboardLayout
        sx={{
          backgroundImage: ({ palette: { gradients } }) =>
            globalImage 
              ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})` 
              : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
          backgroundSize: "100% 100%, cover",
          backgroundPosition: "0% 50%, 80% 50%",
          animation: `${animateGradient} 15s ease infinite`,
        }}
      > 
      <ReuseableHeader 
        title={user ? (user.given_name + " " + user.family_name) : "No name set"} 
        description={user ? user.position : "No position set"}
        width={"50%"}
        user={user ? user.image : ""}
      />
        <Grid container spacing={3} >
            <Grid item xs={12} >
              <Subscriptions />
            </Grid>
        </Grid>
    </DashboardLayout>
  );
}

export default SubscriptionPanel;
