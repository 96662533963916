

const appBar = {
  defaultProps: {
    color: "transparent",
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
    },
  },
};


// const appBar = {
//   defaultProps: {
//     color: "transparent",
//   },

//   styleOverrides: {
//     root: {
//       boxShadow: "none",
//       backgroundColor: "transparent", // Keeps the AppBar background transparent
//       '& .MuiTabs-root': {
//         backgroundColor: "transparent", // Keeps the AppBar background transparent
//         borderRadius: "0", // This will make the indicator rounded
//       },
//       '& .MuiTabs-indicator': {
//         borderBottom: `3px solid #DE4188`, // Pink underline for the active tab
//         borderRadius: "0", // This will make the indicator rounded
//         color:`#DE4188`,
//         boxShadow: "none", // Ensures that there is no shadow,
//         paddingTop: "10px"
//       },
//       '& .MuiTab-root': {
//         color: "#000", // Default text color
//         '&.Mui-selected': {
//           color: "pink", // Pink text color for the active tab
//         }
//       },
//       // If you want to remove the buttons or hide them, add styles here
//     },
//   },
// };

export default appBar;
