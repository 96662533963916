import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useAllCollectionQuery = (activeWorkspace, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.ALL_COLLECTIONS, activeWorkspace],
    () => apiHandlers.collections.getAllCollections(activeWorkspace),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
      enabled: !!activeWorkspace?.team_id,
    }
  );
};
