import { REPORT_COMPONENTS_KEYS } from "constants";

export const countNumberOfColumns = (type) => {
  const oneColumn = [
    REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT,
    REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX,
    REPORT_COMPONENTS_KEYS.METRICS,
    REPORT_COMPONENTS_KEYS.PR_CURVE_ROC_CURVE,
  ];

  if (oneColumn.includes(type)) {
    return 1;
  }

  return 2;
};
