import { Card, IconButton } from "@mui/material";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import XTypography from "components/XTypography";
import XSelect from "components/XSelect";
import XInput from "components/XInput";
import XButton from "components/XButton";

import { useModelsQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useApp } from "hooks";
import { useOptimiserMutation } from "api/mutations";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export const CreateOptimisationsForm = ({ setAddingOptimisation }) => {
  const { activeWorkspace } = useApiKey();
  const { selectedModel, setSelectedModel, optimisationInfo, setOptimisationInfo } = useApp();
  const { logout } = useAuth0();

  const navigate = useNavigate();

  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);

  const { data } = useModelsQuery(activeWorkspace, logout);
  const { createOptimiserMutation } = useOptimiserMutation();

  useEffect(() => {
    if (data) {
      setModels(data.data);
    }
  }, [data]);

  const handleModelChange = (event) => {
    const modelId = event.value;
    setSelectedModel(event);
    const foundModel = models.find((model) => model.model_id === modelId);
    setVersions(foundModel ? foundModel.versions : []);
  };

  const handleVersionChange = (event) => {
    setSelectedVersion(event);
  };

  const handleCreateOptimisation = () => {
    if (!selectedModel.value) return;

    const data = {
      optimiser_name: optimisationInfo.name,
      optimiser_description: optimisationInfo.description,
      model_id: selectedModel.value,
      model_version_id: selectedVersion.value,
    };

    createOptimiserMutation([activeWorkspace, data], {
      onSuccess: (data) => {
        navigate(`/batch-optimisation/${data.data.optimiser_id}`);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <Card
      sx={{
        position: "relative",
        zIndex: 1,
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100%",
        py: 2,
        px: 5,
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
        onClick={() => {
          setAddingOptimisation(false);
        }}
      >
        <CloseIcon />
      </IconButton>
      <XTypography variant="h6" align="center">
        New Optimisation
      </XTypography>
      <XTypography variant="overline">Model</XTypography>
      <XSelect
        value={selectedModel}
        onChange={handleModelChange}
        fullWidth
        placeholder="Select Model"
        options={models.map((model) => ({
          value: model.model_id,
          label: model.model_name,
        }))}
      />
      <XTypography variant="overline">Version</XTypography>
      <XSelect
        value={selectedVersion}
        onChange={handleVersionChange}
        fullWidth
        placeholder="Select Version"
        options={versions.map((version) => ({
          value: version.version_id,
          label: version.version_number,
        }))}
      />
      <XTypography variant="overline">Name</XTypography>
      <XInput
        value={optimisationInfo.name}
        onChange={(e) => setOptimisationInfo({ ...optimisationInfo, name: e.target.value })}
      />

      <XTypography variant="overline">Description</XTypography>
      <XInput
        value={optimisationInfo.description}
        onChange={(e) => setOptimisationInfo({ ...optimisationInfo, description: e.target.value })}
      />

      <XButton
        variant="contained"
        color="primary"
        onClick={handleCreateOptimisation}
        disabled={!selectedModel}
      >
        Create
      </XButton>
    </Card>
  );
};

CreateOptimisationsForm.propTypes = {
  setAddingOptimisation: PropTypes.func,
};
