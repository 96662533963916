import React from "react";
import { Grid, Card } from "@mui/material";
import XBox from "components/XBox";
import SubscriptionCard from "./components/SubscriptionCard";
import ComingSoon from "shared/Cards/ComingSoon";
import { useSubscriptionQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import UpgradeCard from "./components/UpgradeCard";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { useOrganisationCreation } from "hooks/useOrganisationCreation";
import OrganisationCreationModal from "shared/Modals/OrganisationModal";

function Subscriptions() {
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();
  
  const { data: subscriptionData, isLoading: isSubscriptionDataLoading } = useSubscriptionQuery(activeWorkspace, logout);
  const { isModalOpen, handleOpen, handleClose, handleSubmit } = useOrganisationCreation(subscriptionData?.data?.xp_sub?.type === "trial");

  const isTrialExpired = new Date() > new Date(subscriptionData?.data?.current_period?.end);

  const handleAction = (type) => {
    console.log("Action triggered for type:", type); // Debugging line
    switch (type) {
      case "free":
        handleOpen();
        break;
      case "trial":
        handleSubmit();
        break;
      case "software":
        if (subscriptionData.data?.cancel_at_period_end) {
          // Resume subscription logic
        } else {
          // Cancel subscription logic
        }
        break;
      default:
        break;
    }
  };

  if (isSubscriptionDataLoading) {
    return (
      <Grid item xs={12} mt={3}>
        <Card>
          <XBox p={2} height="420px">
            <LoadingSpinner size={50} />
          </XBox>
        </Card>
      </Grid>
    );
  }

  return (
    <>
      <OrganisationCreationModal isOpen={isModalOpen} onClose={handleClose} onSubmit={handleSubmit} freeTrial={false} />
      <Grid container spacing={3}>
        <Grid item xs={subscriptionData?.data?.xp_sub?.type === "enterprise" ? 12 : 7} mt={3}>
          <SubscriptionCard
            type={subscriptionData?.data?.xp_sub?.type}
            expires={subscriptionData?.data?.current_period?.end ? subscriptionData?.data?.current_period?.end : "N/A"}
            isTrialExpired={isTrialExpired}
            onAction={handleAction}
          />
        </Grid>
        {subscriptionData?.data?.xp_sub?.type !== "enterprise" && (
          <Grid item xs={5} mt={3}>
            <UpgradeCard />
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ComingSoon title={"Billing"} caption="In-platform Invoice coming soon." height={{ xs: "200px", lg: "300px" }} shadow={false} icon={false} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Subscriptions;

function formatDateToDayMonthYear(inputDate) {
  const date = new Date(inputDate);
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
}
