import { Card, Divider, Grid } from "@mui/material";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useModel } from "hooks";
import React, { useEffect, useState } from "react";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import Metrics from "shared/models/Metrics";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

import PropTypes from "prop-types";

const MetricsTable = React.memo(({ metrics }) => {
  if (!metrics) {
    return <LoadingSpinner />;
  }

  const numRows = Math.ceil(metrics.length / 2);

  // Create an array of arrays to group ImageCard components into rows
  const rows = Array.from({ length: numRows }, (_, i) => metrics.slice(i * 2, i * 2 + 2));
  return (
    <>
      <XBox>
        Metrics
        <Grid container>
          {rows.map((row, i) => (
            <React.Fragment key={i}>
              <Grid item xs={6}>
                <XBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <XTypography variant="subtitle1">{row[0].title}</XTypography>
                  <XTypography variant="h3">{Number(row[0].value)}</XTypography>
                </XBox>
              </Grid>
              {row[1] && (
                <Grid item xs={6}>
                  <XBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <XTypography variant="subtitle1">{row[1].title}</XTypography>
                    <XTypography variant="h3">{Number(row[1].value)}</XTypography>
                  </XBox>
                </Grid>
              )}
              {i < rows.length - 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </XBox>
    </>
  );
});

export default MetricsTable;

MetricsTable.propTypes = {
  metrics: PropTypes.array,
};
