import React from "react";
import PropTypes from "prop-types";

//mui material components
import Icon from "@mui/material/Icon";
import { Menu, MenuItem } from "@mui/material";
import colors from "assets/theme-dark/base/colors";

//xplainable Dashboard components
import XBox from "components/XBox";
import XAvatar from "components/XAvatar";
import XTypography from "components/XTypography";
import XInput from "components/XInput";
import XBadge from "components/XBadge";
import Card from "@mui/material/Card";

//Helper Functions
import { getTimeDifference } from "shared/Functions/Date";
import { Replies } from "../Replies";
import { useComment } from "shared/Comments/hooks";
import { wrapMentionsInSpan } from "utils";

import UnLikedIcon from "assets/images/icons/comment/un-liked-icon.svg";
import LikedIcon from "assets/images/icons/comment/liked-icon.svg";

export const Comment = ({
  comment,
  commentKey = null,
  isReply = false,
  currentUser,
  type,
  activeWorkspace,
  id,
  fetchedComments,
  setFetchedComments,
  tabMap,
  tabValue,
  setTabValue,
  setSelectedVersion,
}) => {
  const {
    comment_id,
    parent_id,
    user,
    comment: text,
    like_count: like,
    created,
    last_edited,
    replies,
    version_id,
    version_number,
    user_has_liked,
    component,
  } = comment;

  const {
    inputRef,
    menuAnchorEl,
    setMenuAnchorEl,
    isEditing,
    editedText,
    setEditedText,
    likeCount,
    darkMode,
    handleMenuClose,
    getInitials,
    getKeyByValue,
    handleDeleteComment,
    handleEditComment,
    handleKeyPress,
    handleLike,
    toggleReplies,
    isReplyOpen,
    editedCommentTime,
  } = useComment({
    type,
    id,
    text,
    fetchedComments,
    setFetchedComments,
    comment_id,
  });

  const { image, username: name, family_name, given_name, position } = user;

  return (
    <React.Fragment
    // key={(parent_id === null ? null : parent_id.toString()) + "-" + comment_id.toString()}
    >
      <XBox
        width={"100%"}
        key={name}
        display="flex"
        mt={commentKey === 0 ? 0 : isReply ? 1 : 2.5}
        p={isReply ? 0.25 : 0}
        flexDirection={currentUser === name ? "row-reverse" : "row"}
      >
        <XBox>
          {user?.image ? (
            <XAvatar src={image} alt={user} size="xs" />
          ) : (
            <XAvatar
              bgColor="secondary"
              variant="normal"
              color="white"
              alt={user}
              size="xs"
            >
              <XBox>
                <XTypography color="white" fontWeight="bold" variant={isReply ? "caption" : "h6"}>
                  {getInitials(given_name, family_name)}
                </XTypography>
              </XBox>
            </XAvatar>
          )}
        </XBox>
        <XBox
          flexGrow={1}
          ml={currentUser === name ? 0 : isReply ? 1 : 1.5}
          mr={currentUser === name ? 1.5 : 0}
        >
          <Card
            sx={{
              borderRadius: currentUser === name ? "0px 12px 12px 12px" : "0px 12px 12px 12px",
              position: "relative",
              zIndex: 1,
              overflow: "visible",
            }}
          >
            <XBox p={1}>
              <XBox display="flex">
                <XBox display="flex" marginRight="auto">
                  <XBox display="flex" flexDirection="column">
                    <XBox display="flex" gap="4px" alignItems="center">
                      <XTypography variant="button" textTransform="capitalize">
                        {given_name + " " + family_name}
                      </XTypography>
                      {isReply || type !== "models" ? (
                        <></>
                      ) : (
                        <>
                          <XBadge
                            sx={{ cursor: "pointer" }}
                            variant="contained"
                            color="xppink"
                            badgeContent={"version " + version_number}
                            onClick={() =>
                              setSelectedVersion({
                                value: version_id,
                                label: "Version " + version_number,
                              })
                            }
                          />
                          <XBadge
                            sx={{ cursor: "pointer" }}
                            variant="contained"
                            color="xpblue"
                            badgeContent={component}
                            onClick={() => setTabValue(getKeyByValue(tabMap, component))}
                          />
                        </>
                      )}
                    </XBox>
                    <XTypography
                      variant="caption"
                      textTransform="capitalize"
                      fontSize="14px"
                      sx={{ color: "#7C7C7C" }}
                    >
                      {position}
                    </XTypography>
                  </XBox>
                </XBox>
                {currentUser === name && (
                  <>
                    <Icon
                      fontSize="default"
                      sx={{ cursor: "pointer" }}
                      onClick={(event) => {
                        event.persist();
                        setMenuAnchorEl(event.currentTarget);
                      }}
                    >
                      more_vert
                    </Icon>

                    <Menu
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl)}
                      onClose={handleMenuClose}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleEditComment();
                          handleMenuClose();
                        }}
                      >
                        <Icon>edit</Icon>
                        <XTypography variant="caption" ml={1}>
                          Edit
                        </XTypography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDeleteComment();
                          handleMenuClose();
                        }}
                      >
                        <Icon>delete</Icon>
                        <XTypography variant="caption" ml={1}>
                          Delete
                        </XTypography>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </XBox>
              <XBox mt={1} mb={1} lineHeight={isReply ? 0.5 : 0.75} pr={2}>
                {isEditing ? (
                  <XInput
                    ref={inputRef}
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    autoFocus
                  />
                ) : (
                  <XTypography
                    variant="button"
                    fontWeight="light"
                    dangerouslySetInnerHTML={{ __html: wrapMentionsInSpan(text) }}
                  />
                )}
              </XBox>
            </XBox>
            <XBox position="absolute" bottom={"0px"} right={"12px"}>
              <XTypography variant="caption" sx={{ color: "#AFAFAF" }}>
                {(editedCommentTime || last_edited !== created ? "Edited " : "") +
                  getTimeDifference(editedCommentTime || last_edited, true)}
              </XTypography>
            </XBox>
          </Card>

          <XBox mt={1} display="flex" flexWrap="wrap" alignItems="center">
            <XBox
              component="img"
              onClick={() => {
                user_has_liked ? handleLike(comment_id, "unlike") : handleLike(comment_id, "like");
              }}
              src={user_has_liked ? LikedIcon : UnLikedIcon}
            />

            <XTypography variant="button" mx={1} fontWeight="regular" sx={{ color: "#AFAFAF" }}>
              {+(likeCount[commentKey] || like) + (like === 1 ? " Like" : " Likes")}
            </XTypography>
            <XTypography
              sx={{ cursor: "pointer", color: "#AFAFAF" }}
              variant="button"
              fontWeight="regular"
              onClick={toggleReplies}
            >
              {replies.length === 0
                ? isReply
                  ? ""
                  : "Reply"
                : `${replies.length} ${replies.length === 1 ? "reply" : "replies"}`}
            </XTypography>
          </XBox>
        </XBox>
      </XBox>

      <XBox ml={4}>
        <Replies
          activeWorkspace={activeWorkspace}
          type={type}
          id={id}
          isReplyOpen={isReplyOpen}
          fetchedComments={fetchedComments}
          setFetchedComments={setFetchedComments}
          comment={comment}
          tabMap={tabMap}
          tabValue={tabValue}
          currentUser={currentUser}
          getInitials={getInitials}
          handleLike={handleLike}
        />
      </XBox>
    </React.Fragment>
  );
};

Comment.propTypes = {
  comment: PropTypes.object,
  commentKey: PropTypes.number,
  isReply: PropTypes.bool,
  currentUser: PropTypes.string,
  type: PropTypes.string,
  activeWorkspace: PropTypes.object,
  id: PropTypes.string,
  setFetchedComments: PropTypes.func,
  fetchedComments: PropTypes.array,
  tabValue: PropTypes.number,
  tabMap: PropTypes.object,
  setTabValue: PropTypes.func,
  setSelectedVersion: PropTypes.func,
};
