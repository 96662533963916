import { Modal } from "@mui/material";
import XBox from "components/XBox";
import { XImg } from "components/XImg";
import XTypography from "components/XTypography";
import React, { useState } from "react";
import { PropTypes } from "prop-types";

import colors from "assets/theme/base/colors";

import { ReactComponent as CloseIcon } from "assets/images/icons/train/close-icon.svg";
import { ReactComponent as UploadIconExcel } from "assets/images/icons/train/upload-icon-excel.svg";
import { ReactComponent as UploadIconGoogle } from "assets/images/icons/datasets/google-sheet.svg";
import { ReactComponent as UploadIconHubspot } from "assets/images/icons/datasets/hubspot-icon.svg";
import { ReactComponent as UploadIconPostgress } from "assets/images/icons/datasets/postgress-icon.svg";

import { useXplainableController } from "context";
import { UploadItem } from "../UploadItem";
import { useDropzone } from "react-dropzone";
import { useDatasetMutation } from "api/mutations";
import { useToast } from "hooks";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { queryClient } from "api/queryClient";
import { QUERY_CONSTANTS } from "constants";
import { active } from "d3";

export const UploadModal = ({ isModalOpen, setIsModalOpen, fileToReplace }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const { createDatasetMutation, updateDatasetMutation } = useDatasetMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const { activeWorkspace } = useApiKey();

  const [isLoading, setIsLoading] = useState(false);

  const onDropAccepted = (acceptedFiles) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    formData.append("dataset_name", acceptedFiles[0]?.name);
    formData.append("dataset_description", "Test");
    formData.append("team_id", activeWorkspace?.team_id)

    if (!fileToReplace) {
      createDatasetMutation([formData], {
        onSuccess: () => {
          setIsLoading(false);
          setIsModalOpen(false);
          queryClient.invalidateQueries(QUERY_CONSTANTS.DATASET);
          showSuccessToast("Dataset successfully uploaded");
        },
        onError: () => {
          setIsLoading(false);
          showErrorToast("Error uploading file");
        },
      });

      return;
    }

    //formData.append("dataset_id", fileToReplace);
    formData.append("team_id", activeWorkspace?.team_id)

    updateDatasetMutation([fileToReplace, formData], {
      onSuccess: () => {
        setIsLoading(false);
        setIsModalOpen(false);
        queryClient.invalidateQueries(QUERY_CONSTANTS.DATASET);
        showSuccessToast("Dataset successfully replaced");
      },
      onError: () => {
        setIsLoading(false);
        showErrorToast("Error replacing file");
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    addRemoveLinks: true,
    maxFiles: 1,
    onDrop: onDropAccepted,
    accept: {
      "text/csv": [".csv"],
    },
  });

  const { onClick, ...rest } = getRootProps();

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="request-title"
      aria-describedby="request-description"
      {...rest}
    >
      <XBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          maxWidth: "1000px",
          width: "100%",
          transform: "translate(-50%, -60%)",
          background: darkMode ? colors.background.dark : colors.background.default,
          borderRadius: "16px",
          padding: 3,
          outline: 0,
        }}
      >
        {isLoading ? (
          <XBox
            sx={{
              height: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner animationType="pulse" size={50} />
          </XBox>
        ) : (
          <>
            <XBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom="24px"
            >
              <XTypography fontWeight="bold" fontSize="16px">
                Pick a data source
              </XTypography>
              <XImg sx={{ cursor: "pointer" }} onClick={() => setIsModalOpen(false)}>
                <CloseIcon />
              </XImg>
            </XBox>

            <XBox display="flex" gap="24px" flexWrap="wrap">
              <UploadItem
                icon={<UploadIconExcel />}
                text="Upload CSV"
                isReady
                getInputProps={getInputProps}
                onClick={onClick}
              />
              <UploadItem icon={<UploadIconGoogle />} text="Google Sheets coming soon" />
              <UploadItem icon={<UploadIconHubspot />} text="Hubspot coming soon" />
              <UploadItem icon={<UploadIconPostgress />} text="Postgres coming soon" />
            </XBox>
          </>
        )}
      </XBox>
    </Modal>
  );
};

UploadModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  fileToReplace: PropTypes.any,
};
