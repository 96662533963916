import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableRow, Box } from "@mui/material";
import XBox from "components/XBox";

const CollapsibleInfo = ({ params }) => {
  const renderNestedTable = (nestedObj) => {
    return (
      <Table size="small">
        <TableBody>
          {Object.entries(nestedObj).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell sx={{ py: 0.5, fontSize: "0.875rem" }}>{key}</TableCell>
              <TableCell sx={{ py: 0.5, fontSize: "0.875rem" }}>
                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                    <svg
                      width="100%"
                      height="10"
                      viewBox="0 0 100 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0,5H98L93,10M98,5L93,0" stroke="currentColor" strokeWidth="1.5" />
                    </svg>
                  </Box>
                  {value ? value.toString() : null}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Table size="small">
      <TableBody>
        {Object.entries(params).map(([key, value]) => (
          <TableRow key={key}>
            <TableCell sx={{ py: 1 }}>
              <Box sx={{ display: "flex" }}>
                <XBox
                  component="i"
                  color="success"
                  fontSize="22px"
                  className="ni ni-vector"
                  mr={2}
                />
                {key}
              </Box>
            </TableCell>
            <TableCell sx={{ py: 1 }}>
              {typeof value === "object" && value !== null
                ? renderNestedTable(value)
                : value.toString()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CollapsibleInfo;

CollapsibleInfo.propTypes = {
  params: PropTypes.object,
};
