import { useState, useEffect } from "react";
import { useXplainableController } from "context";

// @mui material components
import Grid from "@mui/material/Grid";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// xplainable dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

//User defined components
import ApiTable from "./components/ApiTable";

// Teams page components
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import ComingSoon from "shared/Cards/ComingSoon";
import FeatureRequest from "shared/Cards/FeatureRequest";

import { animateGradient } from "assets/theme/base/animations";

function ApiKeys() {
  const [tabValue, setTabValue] = useState(0);
  const [access, setAccess] = useState("creator");
  const [controller, dispatch] = useXplainableController();
  const { globalImage, globalColor } = controller;

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={"API Keys"}
        description={"Allows remote access to the xplainable API"}
        icon={"ni ni-key-25"}
        tabs={[
          {
            label: "Manage Keys",
            iconClassName: "ni ni-key-25",
          },
          {
            label: "Settings",
            iconClassName: "ni ni-settings-gear-65",
          },
        ]}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      {tabValue === 0 && (
        <>
          <XBox mt={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <XBox minHeight={600}>
                  <ApiTable />
                </XBox>
              </Grid>
            </Grid>
          </XBox>
        </>
      )}
      {tabValue === 1 && (
        <>
          <XBox display="flex" width="100%">
            <ComingSoon
              title={"Api Keys"}
              caption={"No settings are available for API keys just yet."}
            />
          </XBox>
        </>
      )}
    </DashboardLayout>
  );
}

export default ApiKeys;
