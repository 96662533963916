// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

function Breadcrumbs({ icon, title, route, light, color }) {
  const routes = route.slice(0, -1);

  return (
    <XBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: "#AFAFAF",
          },
        }}
      >
        <Link to="/">
          <XTypography
            component="span"
            variant="body2"
            style={{ color: "#AFAFAF" }}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            Home
          </XTypography>
        </Link>
        {routes[0] !== "reports" &&
          routes.map((el) => (
            <Link to={`/${el}`} key={el}>
              <XTypography
                component="span"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color={light ? "dark" : "white"}
                opacity={light ? 0.8 : 0.5}
                sx={{ lineHeight: 0 }}
              >
                {el}
              </XTypography>
            </Link>
          ))}
        <XTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "dark" : "white"}
          sx={{ lineHeight: 0 }}
        >
          {routes[0] !== "reports" && title.replace("-", " ").length === 0
            ? "Home"
            : title.replace("-", " ")}
        </XTypography>
      </MuiBreadcrumbs>
      <XTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        style={{ fontSize: "40px" }}
        sx={{
          color: color ? color : light ? "dark" : "white",
          maxWidth: "450px",
        }}
        noWrap
      >
        {routes[0] !== "reports" && title.replace("-", " ").length === 0
          ? "Home"
          : title.replace("-", " ")}
      </XTypography>
    </XBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
