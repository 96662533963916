import React, { useRef, useEffect, useState, useContext } from "react";
import ModelContext from "context/ModelContext";
import PropTypes from "prop-types";
import * as d3 from "d3";
import XBox from "components/XBox";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import "assets/css/tooltip.css";
import { useModel } from "hooks";
import { useXplainableController } from "context";
import { debounce } from "lodash";

/**
 * Component that renders a BrushChart2Child
 */

function BrushChart2Child({ data, margin, selection, selectedBar, onBarClick }) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const { collapsedWidth } = useModel();

  const containerRef = useRef(null); // The PARENT of the SVG
  const svgRef = useRef();

  // State to track width and height of SVG Container
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(500);
  const [resizing, setResizing] = useState(true);

  // This assumes selection is an array like [startIndex, endIndex]
  const selectedData = selection
    ? data.slice(selection[1].toFixed(0), selection[0].toFixed(0))
    : data;
  const padding = 0.5;

  // This function calculates width and height of the container
  const getSvgContainerSize = () => {
    const newWidth = containerRef.current.clientWidth - 20;
    setWidth(newWidth);
  };

  useEffect(() => {
    // detect 'width' and 'height' on render
    getSvgContainerSize();

    // Create a debounced version of getSvgContainerSize
    const debouncedGetSvgContainerSize = debounce(() => {
      getSvgContainerSize();
    }, 100); // Debounce for 250ms

    // listen for resize changes
    window.addEventListener("resize", debouncedGetSvgContainerSize);

    // cleanup event listener on component unmount
    return () => {
      debouncedGetSvgContainerSize.cancel(); // lodash's debounce provides a cancel method to cancel delayed invocations
      window.removeEventListener("resize", debouncedGetSvgContainerSize);
    };
  }, [collapsedWidth]);

  useEffect(() => {
    FlushChart();
    DrawChart(selectedData, margin);
  }, [selectedData, width, height]);

  const FlushChart = () => {
    d3.select(svgRef.current).selectAll("*").remove();
  };

  const DrawChart = (selectedData, margin) => {
    //Create svg elements
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right - 50)
      .attr("height", height + margin.top + margin.bottom)
      .append("g");

    const yScale = d3
      .scaleBand()
      .domain(selectedData.map((d) => d.name)) // use the names from the selectedData
      .range([height - 10, 10])
      .padding(0.1);

    const yTextElements = svg
      .append("g")
      .style("fill", darkMode ? colors.white.main : colors.black.main)
      .call(d3.axisLeft(yScale).tickSize(0))
      .selectAll("text");

    const longestWidth = d3.max(yTextElements.nodes(), (element) => element.getBBox().width);

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(selectedData, (d) => d.value)])
      .range([0, width + margin.left + margin.right - 50 - longestWidth]);

    // render the bars
    const bars = svg
      .selectAll(".myBar")
      .data(selectedData)
      .join("path") // change "rect" to "path"
      .attr("class", "myBar")
      .attr("fill", (d) => (d.name === selectedBar ? "#bfcedd" : colors.xpblue.main))
      .attr("d", (d) =>
        barRounded(0, yScale(d.name), xScale(d.value), yScale.bandwidth() - 2 * padding, 3)
      );

    bars.on("click", (d) => {
      onBarClick({ value: d.name.toLowerCase().replace(/ /g, "_"), label: d.name });
    });

    //TODO: Rotate axis bottom text

    svg
      .append("g")
      .attr("transform", "translate(0," + (height - 8) + ")")
      .call(d3.axisBottom(xScale)) // Add .tickSize(0) to make x-axis ticks invisible
      .selectAll("text")
      .attr("dx", "1.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(65)")
      .attr("opacity", 0.1)
      .transition()
      .duration(1000)
      .attr("opacity", 1);

    svg.attr("transform", `translate( ${longestWidth - 10}, 0 )`);

    //Add x label
    svg
      .append("text")
      .attr("class", "x label")
      .attr("text-anchor", "middle")
      .attr("font-size", "18px")
      .attr("x", width / 2 + 30 - longestWidth)
      .attr("y", height + 55)
      .style("fill", darkMode ? colors.white.main : colors.black.main)
      .text("Feature Importances (%)");

    const tooltip = d3.select("#tooltip-feature");

    bars.on("mouseenter", onMouseEnter).on("mouseleave", onMouseLeave);

    function onMouseEnter(datum) {
      if (datum !== selectedBar) {
        // Check if the hovered bar is not the selectedBar
        bars.attr("fill", (d) =>
          d.name === datum
            ? "#7f9dbb"
            : d.name === selectedBar || !selectedBar
            ? "#bfcedd"
            : colors.xpblue.main
        );
      }

      const tooltipElement = tooltip.node();
      const barElement = document.querySelector(".myBar");
      const barElementHeight = barElement.getBoundingClientRect().height;

      tooltip.select("#count").text((datum.value * 100).toFixed(2));
      const tooltipElementHeight = tooltipElement.getBoundingClientRect().height;

      const barX = xScale(datum.value) + longestWidth + 16 + 5;
      const barY = yScale(datum.name) - tooltipElementHeight / 2 + barElementHeight / 2; // Adjust by half the bandwidth

      // Assuming the alpha color you want is:
      const alphaColor = colors.xpblue.main; // Adjust accordingly

      // Add the "left-arrow" class to the tooltip to activate the left arrow style
      tooltip.classed("left-arrow", true);

      tooltip
        .style("transform", `translate(${barX}px, ${barY}px)`)
        .style("opacity", 1)
        .style("background", alphaColor)
        .style("color", colors.white.main)
        .style("z-index", 9999);

      // Set the alpha color for the ::before and ::after pseudo-element
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
            .tooltip.left-arrow::before {
                border-right-color: ${alphaColor};
            }
            .tooltip.left-arrow::after {
                border-right-color: ${alphaColor}; 
            }
          `;

      document.head.appendChild(styleElement);
    }

    svg.selectAll("text").style("fill", darkMode ? colors.white.main : colors.black.main);
    svg.selectAll(".domain").style("stroke", darkMode ? colors.white.main : colors.black.main);

    function onMouseLeave() {
      bars.attr("fill", (d) =>
        d.name === selectedBar || !selectedBar ? "#bfcedd" : colors.xpblue.main
      );

      tooltip.style("opacity", 0);
      tooltip.classed("left-arrow", false); // remove the class to reset the tooltip for next time
    }
  };

  return (
    <XBox ref={containerRef}>
      <div id="tooltip-feature" className="tooltip">
        <div className="tooltip-framework">
          <span id="framework" />
        </div>
        <div className="tooltip-value">
          <span id="count" />%
        </div>
      </div>
      <svg ref={svgRef} id="feature-chart">
        <defs>
          <clipPath id="myClipPath">
            <rect x="0" y="0" width="100%" height="100%" />
          </clipPath>
        </defs>
        <g className="content" clipPath="url(#myClipPath)"></g>
        <g className="x-axis" />
        <g className="y-axis" />
      </svg>
    </XBox>
  );
}

export default BrushChart2Child;

BrushChart2Child.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  selection: PropTypes.arrayOf(PropTypes.number),
  svgContainer: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  selectedBar: PropTypes.string,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  onBarClick: PropTypes.func.isRequired,
};

const barRounded = (x, y, width, height, radius) => `
  M ${x}, ${y}
  H ${x + width - radius}
  a ${radius},${radius} 0 0 1 ${radius},${radius}
  V ${y + height - radius}
  a ${radius},${radius} 0 0 1 ${-radius},${radius}
  H ${x}
  Z
`;
