import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useOrganisationMutation = () => {
  const { mutate: createOrganisationMutation } = useMutation((params) =>
    apiHandlers.organisations.createOrganisation(...params)
  );

  const { mutate: deleteOrganisationMutation } = useMutation((params) =>
    apiHandlers.organisations.deleteOrganisation(...params)
  );

  const { mutate: createSubscriptionMutation } = useMutation((params) =>
    apiHandlers.organisations.createSubscription(...params)
  );

  return {
    createOrganisationMutation,
    deleteOrganisationMutation,
    createSubscriptionMutation
  };
};
