import React from "react";

import XBox from "components/XBox";
import XTypography from "components/XTypography";

import PricingTable from "./components/PricingTable";

export const Subscription = () => {
  return (
    <XBox display="flex" flexDirection="column" justifyContent="center" gap={2} textAlign="end">
      <XTypography variant="h5" fontWeight="bold" textAlign="center" fontSize="24px">
        Subscription
      </XTypography>
      <XTypography variant="button" fontWeight="regular" color="text" textAlign="center">
        Unlock Full Potential with Our Tailored Subscription Plans
      </XTypography>
      <PricingTable />
    </XBox>
  );
};
