import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import XButton from "components/XButton";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import XBox from "components/XBox";
import XBadge from "components/XBadge";
import Icon from "@mui/material/Icon";
import { AppBar, Tabs, Tab } from "@mui/material";
import { Slider, Card, Modal } from "@mui/material";
import { useXplainableController } from "context";
import colors from "assets/theme/base/colors";
import breakpoints from "assets/theme/base/breakpoints";
import { ReactComponent as LightReportsIcon } from 'assets/images/icons/sidebar/light-reports-icon.svg';

const HoverMenu = ({ autoGenerateReport }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const [targetDescription, setTargetDescription] = useState("");
  const [projectObjective, setProjectObjective] = useState("");
  const [sliderValue, setSliderValue] = useState(0.7);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const handleOpen = (e) => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    handleClose();
    const reportType = tabValue === 0 ? "report" : "insights"; // Determine report type based on tab
    autoGenerateReport(targetDescription, projectObjective, sliderValue, reportType);
  };

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <Card
          sx={{
            position: "absolute",
            width: "500px",
            p: 3,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            borderRadius: "10px",
            boxShadow: 3,
            zIndex: 100,
          }}
        >
          <AppBar position="static">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
            TabIndicatorProps={{
              style: {
                backgroundColor: colors.xpblue.main, // This changes the tab indicator to xpblue
              },
            }}
          >
            <Tab
              label={
                <XTypography color={colors.xpblue.main} variant="button">
                  Report
                </XTypography>
              }
              icon={
                <XBox mr={1} sx={{ minWidth: 0, display: 'flex', alignItems: 'center' }}>
                  <LightReportsIcon style={{ width: '24px', height: '24px', fill: colors.xpblue.main }} />
                </XBox>
              }
              sx={{
                color: colors.xpblue.main, // Ensure the label text color is xpblue when selected
                '&.Mui-selected': {
                  color: colors.xpblue.main,
                },
              }}
            />
            <Tab
              label={
                <XTypography color={colors.xpblue.main} variant="button">
                  Insights
                </XTypography>
              }
              icon={
                <XBox mr={1} sx={{ minWidth: 0, display: 'flex', alignItems: 'center' }} >
                  <Icon fontSize="lg" sx={{ color: colors.xpblue.main }}>auto_fix_high</Icon>  
                </XBox>
              }
              sx={{
                color: colors.xpblue.main, // Ensure the label text color is xpblue when selected
                '&.Mui-selected': {
                  color: colors.xpblue.main,
                },
              }}
            />
          </Tabs>

          </AppBar>
          <XBox my={3} width={"100%"}>
            <XBox display="flex" justifyContent="space-between">
              <XTypography variant="h6" fontSize="12px">
                Temperature
              </XTypography>
              <XBadge color="xpblue" variant="contained" badgeContent={sliderValue} />
            </XBox>
            <Slider
              color={"primary"}
              step={0.01}
              min={0}
              max={1}
              value={sliderValue}
              onChange={(event, newValue) => {
                setSliderValue(newValue);
              }}
            />
          </XBox>
          <XBox>
              <XBox>
                <XTypography variant="h6" fontSize="12px">
                  Target Description
                </XTypography>
                <XInput
                  multiline
                  rows={4}
                  size="medium"
                  placeholder={"Describe the target in detail..."}
                  value={targetDescription}
                  onChange={(e) => setTargetDescription(e.target.value)}
                />
              </XBox>
              <XBox my={2}>
                <XTypography variant="h6" fontSize="12px">
                  Project Objective
                </XTypography>
                <XInput
                  multiline
                  rows={4}
                  size="medium"
                  placeholder={"Describe what the intent of the project is..."}
                  value={projectObjective}
                  onChange={(e) => setProjectObjective(e.target.value)}
                />
              </XBox>
            </XBox>
          <XBox sx={{ display: "flex", justifyContent: "flex-end" }}>
            <XButton onClick={handleSubmit} variant="gradient" color="button">
              Create {tabValue === 0 ? "Report" : "Insights"}
            </XButton>
          </XBox>
        </Card>
      </Modal>

      <div style={{ position: "relative", display: "inline-block" }} onClick={handleOpen}>
        <XButton
          color={darkMode ? "dark" : "white"}
          size="medium"
          id="menubutton1"
          style={{ zIndex: 1 }}
        >
          <Icon color={darkMode ? "white" : "secondary"}>auto_fix_high</Icon>
          <XTypography variant="button" color={darkMode ? "white" : "secondary"} ml={0.5}>
            XReport
          </XTypography>
        </XButton>
      </div>
    </>
  );
};

HoverMenu.propTypes = {
  autoGenerateReport: PropTypes.func.isRequired, // Make it required
};

export default HoverMenu;
