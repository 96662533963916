import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { SavedPlots } from "layouts/autotrain/components";
import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as CloseIcon } from "assets/images/icons/train/close-icon.svg";
import { useXplainableController } from "context";
import { XImg } from "components/XImg";

export const AnalystSavedPlots = ({ setIsOpenSavedPlots }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  return (
    <XBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        padding: "30px 24px",
        transition: transitions.create(["width"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        background: darkMode ? "#262525" : "white",
        display: "flex",
        flexDirection: "column",
        width: "640px",
        zIndex: "100",
      })}
    >
      <XBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #EAEAEA",
          paddingBottom: "24px",
        }}
      >
        <XTypography sx={{ fontWeight: 700 }}>Saved plots</XTypography>
        <XBox sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <XImg sx={{ cursor: "pointer", stroke: darkMode ? "white" : "black" }} onClick={() => setIsOpenSavedPlots(false)}>
            <CloseIcon />
          </XImg>
        </XBox>
      </XBox>

      <SavedPlots />
    </XBox>
  );
};

AnalystSavedPlots.propTypes = {
  setIsOpenSavedPlots: PropTypes.func,
};
