// useMixPanelQuery.js
import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useScenarioQuery = (collection_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.SCENARIO, collection_id],
    () => apiHandlers.scenario.getScenario(collection_id),
    // {
    //   enabled: !!collection_id,
    // },
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
