import { createContext, useState } from "react";

import PropTypes from "prop-types";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [organisation, setOrganisation] = useState(null);
  const [access, setAccess] = useState("globaladmin");
  const [teamData, setTeamData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [users, setUsers] = useState([]);
  const [team, setTeam] = useState(null);

  return (
    <AdminContext.Provider
      value={{
        organisation,
        setOrganisation,
        access,
        team,
        setTeam,
        teamData,
        setTeamData,
        userData,
        setUserData,
        users,
        setUsers,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

AdminProvider.propTypes = {
  children: PropTypes.node,
};
