import React from "react";
import { Grid } from "@mui/material";
import Shimmer from "../../ShimmerEffect";
import PropTypes from "prop-types";

const MockBinaryOverview = ({ tabsCount = 3 }) => {
  return (
    <Grid container spacing={2} height="100%">
      {[...Array(tabsCount)].map((i) => (
        <Grid key={i} item xs={12} container alignItems="center">
          <Grid item xs={3} height="100%">
            <Shimmer shape="circular" height={"100%"} />
          </Grid>
          <Grid item xs={9} height="100%" px={1}>
            <Shimmer height={"30%"} sx={{ my: 1, borderRadius: "10px" }} />
            <Shimmer height={"50%"} width={"60%"} sx={{ borderRadius: "5px" }} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default MockBinaryOverview;

MockBinaryOverview.propTypes = {
  tabsCount: PropTypes.number,
};
