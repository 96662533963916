// @mui material components
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export default styled(Box)(({ theme, ownerState }) => {
  const { palette, functions, borders, boxShadows } = theme;
  const { variant, bgColor, color, opacity, borderRadius, shadow } = ownerState;

  const { gradients, grey, white } = palette;
  const { linearGradient } = functions;
  const { borderRadius: radius } = borders;

  const validColors = [
    "transparent",
    "white",
    "black",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "xppink",
    "xpblue",
    "xppurple",
    "xpgreen",
    "grey-100",
    "grey-200",
    "grey-300",
    "grey-400",
    "grey-500",
    "grey-600",
    "grey-700",
    "grey-800",
    "grey-900",
  ];

  // Function to check if the provided color is a valid HTML color
  const isValidHtmlColor = (color) => {
    const htmlColorRegex =
      /^#([0-9a-f]{3,8})$|^rgba?\(([0-9]{1,3}), ?([0-9]{1,3}), ?([0-9]{1,3})(, ?[0-9.]+)?\)$/i;
    return htmlColorRegex.test(color);
  };

  // background value
  let backgroundValue = bgColor;

  if (variant === "gradient" && gradients[bgColor]) {
    backgroundValue = linearGradient(gradients[bgColor].main, gradients[bgColor].state);
  } else if (validColors.includes(bgColor)) {
    backgroundValue = palette[bgColor] ? palette[bgColor].main : grey[bgColor];
  } else if (!isValidHtmlColor(bgColor)) {
    backgroundValue = white.main;
  }

  // color value
  let colorValue = validColors.includes(color)
    ? palette[color]
      ? palette[color].main
      : grey[color]
    : color;

  // borderRadius value
  let borderRadiusValue = radius[borderRadius] || borderRadius;

  // boxShadow value
  let boxShadowValue = boxShadows[shadow] || shadow;

  return {
    opacity,
    background: backgroundValue,
    color: colorValue,
    borderRadius: borderRadiusValue,
    boxShadow: boxShadowValue,
  };
});
