import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

// @mui material components
import { Grid } from "@mui/material";

import colors from "assets/theme/base/colors";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { useModel } from "hooks";
import { useXplainableController } from "context";

const LinePlot = ({ data }) => {
  const { collapsedWidth } = useModel();
  const [controller] = useXplainableController();

  const svgRef = useRef();
  const svgContainer = useRef(null); // The PARENT of the SVG
  const [maxValue, setMaxValue] = useState(0);
  const [bestThredhold, setBestThredhold] = useState(0);
  const [width, setWidth] = useState(550);
  const [height, setHeight] = useState(320);
  const margin = { top: 20, right: 20, bottom: 60, left: 40 };

  // This function calculates width and height of the container
  const getSvgContainerSize = () => {
    const newWidth = svgContainer.current.clientWidth;
    setWidth(newWidth);
  };

  useEffect(() => {
    // detect 'width' and 'height' on render
    getSvgContainerSize();
    // listen for resize changes, and detect dimensions again when they change
    window.addEventListener("resize", getSvgContainerSize);
    // cleanup event listener
    return () => window.removeEventListener("resize", getSvgContainerSize);
  }, [collapsedWidth]);

  useEffect(() => {
    if (!data || data.sums.length === 0 || data.values.length === 0) return;

    const svg = d3.select(svgRef.current);

    svg.attr("width", width).attr("height", height);

    // Scales
    const xScale = d3
      .scaleLinear()
      .domain([0, data.values.length - 1])
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data.sums), d3.max(data.sums)])
      .range([height - margin.bottom, margin.top]);

    // Axes
    const xAxis = d3.axisBottom(xScale).ticks(11);
    const yAxis = d3.axisLeft(yScale);

    const xAxisGroup = svg.select(".x-axis");
    if (!xAxisGroup.empty()) {
      xAxisGroup
        .attr("transform", `translate(0, ${height - margin.bottom})`)
        .attr("font-size", "14px")
        .call(xAxis)
        .selectAll(".tick text")
        .attr("class", "x-tick");
    }

    const yAxisGroup = svg.select(".y-axis");
    if (!yAxisGroup.empty()) {
      yAxisGroup
        .attr("transform", `translate(${margin.left}, 0)`)
        .attr("font-size", "14px")
        .call(yAxis);
    }

    // Line generator
    const lineGenerator = d3
      .line()
      .x((_, i) => xScale(data.values[i]))
      .y((d) => yScale(d));

    // Update line with a transition
    svg.select(".line-path").datum(data.sums).transition().duration(500).attr("d", lineGenerator);

    // Find the greatest sum (y) value and its corresponding index
    const maxIndex = data.sums.indexOf(d3.max(data.sums));
    setMaxValue(data.sums[maxIndex]);
    setBestThredhold(data.values[maxIndex]);

    // Transition the circle to the greatest sum (y) value
    svg
      .select(".max-point")
      .transition()
      .duration(1000)
      .attr("cx", xScale(data.values[maxIndex]))
      .attr("cy", yScale(data.sums[maxIndex]));

    // Transition the vertical line from the max point to the x-axis
    svg
      .select(".max-line")
      .transition()
      .duration(1000)
      .attr("x1", xScale(data.values[maxIndex]))
      .attr("y1", yScale(data.sums[maxIndex]))
      .attr("x2", xScale(data.values[maxIndex]))
      .attr("y2", height - margin.bottom);

    // Update the x label
    svg
      .select(".x-label")
      .attr("x", width / 2)
      .attr("y", height - 10)
      .text("Threshold")
      .style("fill", controller.darkMode ? "white" : "#262525");

    // Update the bestThreshold label with a transition
    svg
      .select(".best-threshold")
      .transition()
      .duration(1000)
      .attr("x", xScale(data.values[maxIndex]))
      .attr("y", height - margin.bottom + 20)
      .text(`${bestThredhold}`);

    // Update the bestThreshold label background with a transition
    const bestThresholdLabel = svg.select(".best-threshold");
    const bbox = bestThresholdLabel.node().getBBox();

    svg
      .select(".best-threshold-bg")
      .transition()
      .duration(1000)
      .attr("x", xScale(data.values[maxIndex]) - bbox.width / 2 - 2)
      .attr("y", height - margin.bottom + bbox.y - 2)
      .attr("width", bbox.width + 4)
      .attr("height", bbox.height + 4)
      .attr("fill", "white");

    svg.selectAll(".tick text").attr("fill", controller.darkMode ? "#FFFFFF" : "#0D0C0C");
    svg.selectAll(".domain").attr("stroke", controller.darkMode ? "#FFFFFF" : "#0D0C0C");

    // Check for overlap and adjust opacity of x-axis ticks
    svg.selectAll(".x-tick").each(function () {
      const tickLabel = d3.select(this);
      const tickLabelBBox = this.getBBox();
      const overlap =
        bbox.x < tickLabelBBox.x + tickLabelBBox.width && bbox.x + bbox.width > tickLabelBBox.x;

      tickLabel
        .transition()
        .duration(1000)
        .style("opacity", overlap ? 0 : 1);
    });
  }, [data, width, height, margin, controller]);

  return (
    <XBox height={"400px"}>
      <XBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <XBox
              display="flex"
              p={2}
              flexDirection="column"
              bgColor={controller.darkMode ? "#1D1B1B" : " #F7F7F8"}
              sx={{ borderRadius: "16px" }}
            >
              <XTypography variant="button" color="secondary" fontWeight="light">
                Net Value
              </XTypography>
              <XTypography variant="button">{maxValue.toFixed(2)}</XTypography>
            </XBox>
          </Grid>
          {/* <Grid item xs={6}>
            <XBox
              display="flex"
              flexDirection="column"
              p={2}
              bgColor={controller.darkMode ? "#1D1B1B" : " #F7F7F8"}
              sx={{ borderRadius: "16px" }}
            >
              <XTypography variant="button" color="secondary" fontWeight="light">
                Error
              </XTypography>
              <XTypography variant="button">{"+/- " + maxValue.toFixed(2)}</XTypography>
            </XBox>
          </Grid> */}
        </Grid>
        <XBox></XBox>
      </XBox>
      <XBox ref={svgContainer}>
        <svg ref={svgRef}>
          <g className="x-axis" />
          <text className="x-label" textAnchor="middle" style={{ font: "18px sans-serif" }} />
          <rect className="best-threshold-bg" />
          <text
            className="best-threshold"
            textAnchor="middle"
            fill={controller.darkMode ? "white" : colors.dark.main}
            style={{ font: "14px sans-serif" }}
          />
          <g className="y-axis" />
          <path className="line-path" fill="none" stroke={colors.xpblue.main} strokeWidth="2" />
          <circle className="max-point" r="5" fill={colors.white.main} stroke={colors.dark.main} />
          <line
            className="max-line"
            stroke={controller.darkMode ? "white" : colors.dark.main}
            strokeWidth="1"
            strokeDasharray="5,5"
          />
        </svg>
      </XBox>
    </XBox>
  );
};

export default LinePlot;

LinePlot.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
};
