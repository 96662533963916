import React, { useRef, useEffect, useState, useContext } from "react";
import { select, scaleLinear, max, min, brushY, axisLeft } from "d3";
import { usePrevious } from "../../shared";
import { useModel } from "hooks";

import { event as d3event } from "d3-selection";
import XBox from "components/XBox";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import PropTypes from "prop-types";

function ProfileBrushChart({ data, children, height: propHeight }) {
  const { collapsedWidth } = useModel();

  const svgRef = useRef();
  const svgContainer = useRef(null);

  const [selection, setSelection] = useState([0, data.length]);
  const [containerWidth, setContainerWidth] = useState(0);
  const [height, setHeight] = useState(480);
  const [width, setWidth] = useState(40);

  const previousSelection = usePrevious(selection);

  const getSvgContainerSize = () => {
    const newWidth = svgContainer.current.clientWidth;
    setContainerWidth(newWidth - 50);
  };

  useEffect(() => {
    setSelection([-0.5, data.length - 0.5]);
  }, [data]);

  useEffect(() => {
    getSvgContainerSize();
    window.addEventListener("resize", getSvgContainerSize);
    return () => window.removeEventListener("resize", getSvgContainerSize);
  }, []);

  useEffect(() => {
    renderChart();
  }, [previousSelection, selection, width, height, data]);

  const calculateBarHeight = () => {
    let barHeight = 90 / data.length ** 0.7;
    return barHeight < 25 ? barHeight : 25;
  };

  const createYScale = () => {
    return scaleLinear()
      .domain([data.length - 0.5, -0.5])
      .range([height, 0]);
  };

  const createXScale = () => {
    return scaleLinear()
      .domain([min(data.map((d) => d.value)), max(data.map((d) => d.value))])
      .range([10, width - 5]);
  };

  const createBars = (svg, xScale, yScale, barHeight) => {
    svg
      .selectAll(".myBar")
      .data(data)
      .join("rect")
      .attr("class", "myBar")
      .attr("fill", (d, index) => {
        const isInRange =
          selection && index >= Math.floor(selection[0]) && index < Math.ceil(selection[1]);
        return d.value < 0
          ? isInRange
            ? rgba(colors.xppink.main, 0.4)
            : rgba(colors.light.main, 0.4)
          : isInRange
          ? rgba(colors.xpblue.main, 0.4)
          : rgba(colors.light.main, 0.4);
      })
      .attr("y", (d, index) => yScale(index) - barHeight / 2 || 0)
      .attr("x", (d) => (d.value < 0 ? xScale(d.value) : xScale(0)))
      .attr("height", barHeight)
      .attr("width", (d) => Math.abs(xScale(d.value) - xScale(0)) || 0)
      .attr("rx", 3) // Add this line for x-axis corner radius
      .attr("ry", 3); // Add this line for y-axis corner radius
  };

  const createYAxis = (svg, yScale, xScale) => {
    const yAxis = axisLeft(yScale).tickSize(0).tickFormat("");
    svg
      .select(".y-axis")
      .attr("transform", `translate(${xScale(0)},0)`)
      .call(yAxis);
  };

  const createBrush = (svg, xScale, yScale) => {
    const brush = brushY()
      .extent([
        [0, 0],
        [width, height],
      ])
      .on("start brush end", function () {
        const event = d3event;
        const indexSelection = event.selection.map(yScale.invert);

        if (indexSelection[0] === selection[0] && indexSelection[1] === selection[1]) {
          setSelection([-0.5, data.length - 0.5]);
          return;
        }

        if (event.selection) {
          console.log("The index selection is", indexSelection);
          setSelection(indexSelection);
        }
      });

    // Code to apply styles to the brush
    svg
      .select(".brush")
      .call(brush)
      .selectAll(".selection")
      .style("stroke", rgba(colors.secondary.main, 0.4)) // Change the stroke color of the selection
      .style("fill", colors.light.main) // Change the fill color of the selection
      .style("fill-opacity", 0.2); // Change the fill opacity of the selection

    svg
      .select(".brush")
      .call(brush)
      .selectAll(".handle")
      .style("fill", rgba(colors.secondary.main, 0.4)) // Change the fill color of the handles
      .style("stroke", rgba(colors.secondary.main, 0.4)) // Change the stroke color of the handles
      .style("stroke-width", "1.5px"); // Change the stroke width of the handles

    const isEqual = previousSelection ? selection[1] === previousSelection[1] : false;

    if (!isEqual && selection) {
      svg.select(".brush").call(brush).call(brush.move, selection.map(yScale));
    }
  };

  const renderChart = () => {
    const svg = select(svgRef.current);
    const barHeight = calculateBarHeight();
    const yScale = createYScale();
    const xScale = createXScale();

    createBars(svg, xScale, yScale, barHeight);
    createYAxis(svg, yScale, xScale);
    createBrush(svg, xScale, yScale);

    svg.selectAll(".domain").style("stroke", "none");
  };

  return (
    <>
      <XBox display="flex" ref={svgContainer}>
        <XBox width={containerWidth} flexGrow={1}>
          {children(selection, svgContainer)}
        </XBox>
        <XBox
          width={width}
          mr={1}
          mt={18}
          // sx={{ transform: `translateX(${collapsedWidth ? "0" : "-30px"})` }}
        >
          <svg ref={svgRef} height={"610px"}>
            <g className="brush" />
            <g className="x-axis" />
            <g className="y-axis" />
          </svg>
        </XBox>
      </XBox>
    </>
  );
}

ProfileBrushChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  children: PropTypes.func.isRequired,
  height: PropTypes.number,
};

export default ProfileBrushChart;
