import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import colors from 'assets/theme/base/colors';

// GradientTypography Component
export const GradientText = styled('span')({
  background: `linear-gradient(90deg, ${colors.xppink.main} 0%, ${colors.xpblue.main} 100%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold',
  fontSize: '18px',
});

const Container = styled(Typography)({
  display: 'inline-flex', // Ensures the text stays on a single line and allows for aligning the arrow
  alignItems: 'center',
  cursor: 'pointer', // Changes cursor to pointer to indicate clickability
  textDecoration: 'none', // Removes underline from text
  '&:hover .arrow': {
    transform: 'translateX(5px)', // Moves the arrow 5px to the right on hover
  },
});

// Arrow Component
const Arrow = styled('span')({
  display: 'inline-block',
  transition: 'transform 0.3s ease-in-out', // Arrow transition settings
  marginLeft: '4px', // Adds a small space between the text and the arrow
  color: `${colors.xpblue.main}`, // Manually set the color for the arrow
});

// GradientLinkTypography Component
function GradientLinkTypography({ children, href }) {
  const handleClick = () => {
    window.open(href, '_blank');
  };

  return (
    <Container onClick={handleClick}>
      <GradientText>{children}</GradientText>
      <Arrow className="arrow">→</Arrow>
    </Container>
  );
}

// Define PropTypes for the component
GradientLinkTypography.propTypes = {
  children: PropTypes.node.isRequired, // `children` should be React node(s) and is required
  href: PropTypes.string.isRequired,    // `href` should be a string and is required
};

export default GradientLinkTypography;
