import { apiPaths } from "./apiPath";
import { deleteData, getData, patchData, postData, putData } from "./crudHandlers";

export const apiHandlers = {
  comments: {
    getCommentsModels: (activeWorkspace, modelId, pageNumber, itemsPerPage) => {
      return getData(
        apiPaths.comments.getComments.models(activeWorkspace, modelId, pageNumber, itemsPerPage)
      );
    },

    getCommentsDeployments: (activeWorkspace, modelId, pageNumber, itemsPerPage) => {
      return getData(
        apiPaths.comments.getComments.deployments(
          activeWorkspace,
          modelId,
          pageNumber,
          itemsPerPage
        )
      );
    },

    getCommentsCollections: (activeWorkspace, modelId, pageNumber, itemsPerPage) => {
      return getData(
        apiPaths.comments.getComments.collections(
          activeWorkspace,
          modelId,
          pageNumber,
          itemsPerPage
        )
      );
    },

    getComments: (resourceId, pageNumber, itemsPerPage) => {
      return getData(apiPaths.comments.getComments.comments(resourceId, pageNumber, itemsPerPage));
    },

    getUsers: (activeWorkspace) => {
      return getData(apiPaths.comments.getUsers(activeWorkspace));
    },

    getFilteredUsers: (activeWorkspace, input) => {
      return getData(apiPaths.comments.getFilteredUsers(activeWorkspace, input));
    },

    addComment: (data) => { //Updated endpoint
      return putData(apiPaths.comments.addComment(), {
        data,
      });
    },

    deleteComment: (comment_id) => {
      return deleteData(apiPaths.comments.deleteComment(comment_id));
    },

    editComment: (data) => {
      return patchData(apiPaths.comments.editComment(), {
        data,
      });
    },

    addLike: (comment_id) => {
      return putData(
        apiPaths.comments.addLike(comment_id)
      );
    },

    removeLike: (comment_id) => {
      return putData(
        apiPaths.comments.removeLike(comment_id),
      );
    },
  },
  cards: {
    editCard: (endpointType, id, action) => {
      return patchData(apiPaths.cards.toggleCard(endpointType, id, action));
    },
  },
  xPanel: {
    getXPanel: (queryParams) => {
      const queryStringParts = [];

      for (const key in queryParams) {
        if (Array.isArray(queryParams[key])) {
          // Join array values with commas for the events parameter
          if (key === 'event') {
            queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key].join(','))}`);
          } else {
            // Keep existing handling for other array parameters
            queryParams[key].forEach((val) => {
              queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
            });
          }
        } else {
          // For non-array values, add the key-value pair as usual
          queryStringParts.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
          );
        }
      }

      const queryString = queryStringParts.join("&");
      return getData(`${apiPaths.xPanel.getXPanel()}?${queryString}`);
    },
  },
  userManagement: {
    getUser: (api_key) => {
      return getData(apiPaths.user.getUser(), {
        api_key: api_key,
      });
    },
    createUser: (data, jwt) => {
      return putData(apiPaths.user.createUser(), {
        data: data,
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Access-Control-Allow-Origin": "*",
        },
      });
    },
    deleteUser: (api_key) => {
      return deleteData(apiPaths.user.deleteUser(), {
        api_key: api_key,
      });
    },
    getUserNotification: () => {
      return getData(apiPaths.user.userNotification());
    },
    patchUserNotification: (data) => {
      return patchData(apiPaths.user.userNotification(), {
        data,
      });
    },
  },
  models: {
    getModels: (activeWorkspace) => {
      return getData(apiPaths.models.getModels(activeWorkspace));
    },
    getModel: (model_id) => {
      return getData(apiPaths.models.getModel(model_id));
    },

    deleteModel: (model_id) => {
      return deleteData(apiPaths.models.deleteModel(model_id));
    },
    archiveModel: (model_id) => {
      return patchData(apiPaths.models.archiveModel(model_id));
    },
    moveModel: (activeWorkspace, model_id, new_team_id) => {
      return patchData(apiPaths.models.moveModel(), {
        data: {
          new_team_id: new_team_id,
          model_id: model_id,
        },
      });
    },
    deleteModelVersion: (selectedVersion) => {
      return deleteData(
        apiPaths.models.deleteModelVersion(selectedVersion)
      );
    },
    handleActiveVersion: (selectedVersion) => {
      return patchData(
        apiPaths.models.handleActiveVersion(),
        {
          version_id: selectedVersion.version,
        }
      );
    },
  },
  deployments: {
    getDeployments: (activeWorkspace) => {
      return getData(apiPaths.deployments.getDeployments(activeWorkspace));
    },
    getDeployment: (activeWorkspace, model_id, api_key) => {
      if (api_key) {
        return getData(apiPaths.models.getModel(activeWorkspace, model_id), {
          api_key: api_key,
        });
      }
    },
    handleArchiveDeployment: (model_id) => {
      return patchData(apiPaths.deployments.handleArchiveDeployment(model_id));
    },

    handleDeployDeployment: (version_id) => {
      return getData(
        apiPaths.deployments.handleDeployDeployment(version_id)
      );
    },
    deleteDeployments: (deployment_id) => {
      return deleteData(apiPaths.deployments.deleteDeployments(deployment_id));
    },
    getKey: (deployment_id) => {
      return getData(apiPaths.deployments.getKey(deployment_id));
    },

    getPayload: (deployment_id) => {
      return getData(apiPaths.deployments.getPayload(deployment_id));
    },
    getPrediction: (deployment_key, payload) => {
      return postData(apiPaths.deployments.getPrediction(), {
        body: payload,
        headers: {
          "Content-Type": "application/json",
          api_key: deployment_key,
        },
      });
    },
  },
  toggle: {
    toggleDeployment: (deployment_id, action) => {
      return patchData(apiPaths.toggle.toggleDeployment(deployment_id, action));
    },
    toggleModel: (model_id, action) => {
      return patchData(apiPaths.toggle.toggleModel(model_id, action));
    },
  },
  invitations: {
    inviteUser: (data) => {
      return putData(apiPaths.invitations.inviteUser(), {
        data,
      });
    },
    getInvitations: () => {
      return getData(apiPaths.invitations.getInvitations());
    },
    getRequests: () => {
      return getData(apiPaths.invitations.getRequests());
    },
    deleteInvitation: (invitationId) => {
      return deleteData(apiPaths.invitations.deleteInvitation(invitationId));
    },
    handleInvitations: (type, requestId, action) => {
      return patchData(apiPaths.invitations.handleInvitations(type, requestId, action));
    },
  },
  notifications: {
    getNotifications: () => {
      return getData(apiPaths.notifications.getNotifications());
    },
    markAsRead: (notificationId) => {
      return deleteData(apiPaths.notifications.markAsRead(notificationId));
    },
    markAllAsRead: () => {
      return deleteData(apiPaths.notifications.markAllAsRead());
    },
  },
  predictions: {
    getPrediction: (deployment_key) => {
      return getData(apiPaths.predictions.getPrediction(), {
        api_key: deployment_key,
      });
    },
    getPayload: (activeWorkspace, deployment_id, api_key) => {
      return getData(apiPaths.predictions.getPayload(activeWorkspace, deployment_id), {
        api_key: api_key,
      });
    },
  },
  preprocessors: {
    getAllPreprocessors: (activeWorkspace) => {
      return getData(apiPaths.preprocessors.getAllPreprocessors(activeWorkspace));
    },
    getPreprocessorVersion: (activeWorkspace, preprocessor_id) => {
      return getData(
        apiPaths.preprocessors.getPreprocessorVersion(activeWorkspace, preprocessor_id)
      );
    },
    getPreprocessors: (version_id) => {
      return getData(apiPaths.preprocessors.getPreprocessors(version_id));
    },
    getRelevantPreprocessors: (model_version_id) => {
      return getData(
        apiPaths.preprocessors.getRelevantPreprocessors(model_version_id),
      );
    },
    getPreprocessorSample: (activeWorkspace, preprocessor_id, version_id) => {
      return getData(
        apiPaths.preprocessors.getPreprocessorSample(activeWorkspace, preprocessor_id, version_id)
      );
    },
    checkSignature: (
      activeWorkspace,
      model_id,
      version_id,
      preprocessor_id,
      preprocessor_version,
      api_key
    ) => {
      return postData(
        apiPaths.preprocessors.checkSignature(
          activeWorkspace,
          preprocessor_id,
          preprocessor_version
        ),
        {
          api_key: api_key,
          data: {
            model_id: model_id,
            version_id: version_id,
          },
        }
      );
    },
    linkPreprocessor: (data) => {
      return putData(
        apiPaths.preprocessors.linkPreprocessor(),{
          data,
        });
    },
    unlinkPreprocessor: (data) => {
      return patchData(
        apiPaths.preprocessors.unlinkPreprocessor(), {
          data,
        });
    },
  },
  profileData: {
    getProfileData: (version, api_key) => {
      return getData(apiPaths.profileData.getProfileData(version), {
        api_key: api_key,
      });
    },
  },
  scenario: {
    addScenario: (data) => {
      return putData(apiPaths.scenario.addScenario(), {
        data: data,
      });
    },
    getScenario: (collection_id) => {
      return getData(apiPaths.scenario.getScenario(collection_id));
    },
    deleteScenario: (activeWorkspace, modelId, collectionId, scenarioId) => {
      return deleteData(
        apiPaths.scenario.deleteScenario(activeWorkspace, modelId, collectionId, scenarioId)
      );
    },
  },
  collections: {
    getAllCollections: (activeWorkspace) => {
      return getData(apiPaths.collections.getAllCollections(activeWorkspace));
    },
    getCollections: (model_id) => {
      return getData(apiPaths.collections.getCollections(model_id));
    },
    getCollection: (activeWorkspace, model_id, collection_id) => {
      return getData(apiPaths.collections.getCollection(activeWorkspace, model_id, collection_id));
    },
    createCollection: (activeWorkspace, modelId, data) => {
      return putData(apiPaths.collections.createCollection(activeWorkspace, modelId), {
        data,
      });
    },
    deleteCollection: (collection_id) => {
      return deleteData(apiPaths.collections.deleteCollection(collection_id));
    },
    updateTitle: (activeWorkspace, model_id, collection_id, data) => {
      return patchData(apiPaths.collections.updateTitle(activeWorkspace, model_id, collection_id), {
        data,
      });
    },
    updateDescription: (activeWorkspace, model_id, collection_id, data) => {
      return patchData(
        apiPaths.collections.updateDescription(activeWorkspace, model_id, collection_id),
        {
          data,
        }
      );
    },
    deleteModelCollections: (activeWorkspace, model_id) => {
      return deleteData(apiPaths.collections.deleteModelCollections(activeWorkspace, model_id));
    },
    updateScenarioNotes: (activeWorkspace, model_id, collection_id, scenario_id, data) => {
      return patchData(
        apiPaths.collections.updateScenarioNotes(
          activeWorkspace,
          model_id,
          collection_id,
          scenario_id
        ),
        {
          data,
        }
      );
    },
  },
  reports: {
    getReports: (activeWorkspace) => {
      return getData(apiPaths.reports.getReports(activeWorkspace));
    },
    getReportsVersion: (reportId) => {
      return getData(apiPaths.reports.getReportsVersion(reportId));
    },
    createReport: (data) => {
      return putData(apiPaths.reports.createReport(), {
        data,
      });
    },
    createReportVersion: (reportId, data) => {
      return putData(apiPaths.reports.createReportVersion(reportId), {
        data,
      });
    },
    deleteReport: (reportId) => {
      return deleteData(apiPaths.reports.deleteReport(reportId));
    },
    copyReport: (reportId, data) => {
      return postData(apiPaths.reports.copyReport(reportId), {
        data,
      });
    },
  },
  health: {
    getHealthData: (selectedVersion) => {
      return getData(apiPaths.health.getHealthData(selectedVersion));
    },
  },
  predict: {
    postPredict: (apiKey, data) => {
      return postData(apiPaths.predict.postPredict(), {
        apiKey,
        data,
        header: `multipart/form-data`,
      });
    },
  },
  subscriptions: {
    getSubscription: (activeWorkspace) => {
      return getData(apiPaths.subscription.getSubscription(activeWorkspace));
    },
    getSubscriptionSeats: (activeWorkspace) => {
      return getData(apiPaths.subscription.getSubscriptionSeats(activeWorkspace));
    },
    updateSubscriptionSeats: (activeWorkspace, data) => {
      return putData(apiPaths.subscription.updateSubscriptionSeats(activeWorkspace), {
        data,
      });
    },
    createSubscription: (activeWorkspace) => {
      return postData(apiPaths.subscription.createSubscription(), {
        organisation_id: activeWorkspace?.organisation_id,
      });
    },
    cancelSubscription: (activeWorkspace) => {
      return postData(apiPaths.subscription.cancelSubscription(), {
        organisation_id: activeWorkspace?.organisation_id,
      });
    },
    resumeSubscription: (activeWorkspace) => {
      return postData(apiPaths.subscription.resumeSubscription(), {
        organisation_id: activeWorkspace?.organisation_id,
      });
    },
  },
  commentary: {
    getCommentary: (version_id) => {
      return getData(apiPaths.commentary.getCommentary(version_id));
    },

    autoGenerateReport: (activeWorkspace, id, version, data) => {
      return putData(apiPaths.commentary.autoGenerateReport(activeWorkspace, id, version), {
        data,
      });
    },

    changeCommentary: (activeWorkspace, id, version, data) => {
      return patchData(apiPaths.commentary.changeCommentary(activeWorkspace, id, version), {
        data,
      });
    },

    handlePublishCommentary: (action, data) => {
      return patchData(
        apiPaths.commentary.handlePublishCommentary(action), {
          data,
        }
      );
    },
  },
  users: {
    getUsers: (activeWorkspace) => {
      return getData(apiPaths.users.getUsers(activeWorkspace));
    },
    getTeamUsers: (organisationId) => {
      return getData(apiPaths.users.getTeamUsers(organisationId));
    },
    searchUser: (organisationId, input) => {
      return getData(apiPaths.users.searchUser(organisationId, input));
    },
  },
  binaryPerformance: {
    getData: (partition_id) => {
      return getData(
        apiPaths.binaryPerformance.getData(partition_id)
      );
    },
  },
  pipeLine: {
    getData: (preprocessor_version) => {
      return getData(
        apiPaths.pipeLine.getData(preprocessor_version)
      );
    },
  },
  regressionPerformance: {
    getData: (partition_id) => {
      return getData(
        apiPaths.regressionPerformance.getData(partition_id)
      );
    },
  },
  organisations: {
    getData: () => {
      return getData(apiPaths.organisation.getData());
    },
    createOrganisation: (data) => {
      return postData(apiPaths.organisation.createOrganisation(), {
        data,
      });
    },

    deleteOrganisation: (organisation_id) => {
      return deleteData(apiPaths.organisation.deleteOrganisation(organisation_id));
    },
    getOrganisationMetadata: () => {
      return getData(apiPaths.organisation.getOrganisationMetadata());
    },
    manageOrganisation: () => {
      return getData(apiPaths.organisation.manageOrganisation());
    },
  },
  teams: {
    getTeams: (organisationId) => {
      return getData(apiPaths.teams.getTeams(organisationId));
    },
    createTeam: (organisationData, data) => {
      return putData(apiPaths.teams.createTeam(organisationData), {
        data,
      });
    },
    deleteTeam: (organisationData, team_id) => {
      return deleteData(apiPaths.teams.deleteTeam(organisationData, team_id));
    },
  },
  apiKeys: {
    getData: (activeWorkspace) => {
      return getData(apiPaths.apiKeys.getData(activeWorkspace));
    },
    createKey: (activeWorkspace, data) => {
      return putData(apiPaths.apiKeys.createKey(activeWorkspace), {
        data,
      });
    },
    revokeAllKeys: () => {
      return postData(apiPaths.apiKeys.revokeAllKeys());
    },
  },
  batchOptimisation: {
    getData: (activeWorkspace) => {
      return getData(apiPaths.batchOptimisation.getData(activeWorkspace));
    },
    getOptimisersVersions: (activeWorkspace, optimiser_id) => {
      return getData(
        apiPaths.batchOptimisation.getOptimisersVersions(activeWorkspace, optimiser_id)
      );
    },
    getOptimiserVersion: (activeWorkspace, optimiser_id, version_id) => {
      return getData(
        apiPaths.batchOptimisation.getOptimiserVersion(activeWorkspace, optimiser_id, version_id)
      );
    },
    createOptimiserVersion: (activeWorkspace, optimiser_id, data) => {
      console.log(optimiser_id);
      return putData(
        apiPaths.batchOptimisation.createOptimiserVersion(activeWorkspace, optimiser_id),
        {
          data,
        }
      );
    },
    deleteOptimiser: (activeWorkspace, optimiser_id) => {
      return deleteData(apiPaths.batchOptimisation.deleteOptimiser(activeWorkspace, optimiser_id));
    },
    createOptimiser: (activeWorkspace, data) => {
      return putData(apiPaths.batchOptimisation.createOptimiser(activeWorkspace), {
        data,
      });
    },
    deleteOptimiserVersion: (activeWorkspace, optimiser_id, version_id) => {
      return deleteData(
        apiPaths.batchOptimisation.deleteOptimiserVersion(activeWorkspace, optimiser_id, version_id)
      );
    },
    resetOptimiserVersion: (activeWorkspace, optimiser_id, version_id) => {
      return patchData(
        apiPaths.batchOptimisation.resetOptimiserVersion(activeWorkspace, optimiser_id, version_id)
      );
    },
    updateOptimiserVersion: (activeWorkspace, optimiser_id, version_id, data) => {
      return patchData(
        apiPaths.batchOptimisation.updateOptimiserVersion(
          activeWorkspace,
          optimiser_id,
          version_id
        ),
        {
          data,
        }
      );
    },
    createBatches: (activeWorkspace, optimiser_id, version_id, data) => {
      return putData(
        apiPaths.batchOptimisation.createBatches(activeWorkspace, optimiser_id, version_id),
        {
          data,
        }
      );
    },
    getBatch: (activeWorkspace, optimiser_id, version_id, batch_id) => {
      return getData(
        apiPaths.batchOptimisation.getBatch(activeWorkspace, optimiser_id, version_id, batch_id)
      );
    },
    downloadBatch: (activeWorkspace, optimiser_id, version_id, batch_id) => {
      return getData(
        apiPaths.batchOptimisation.downloadBatch(
          activeWorkspace,
          optimiser_id,
          version_id,
          batch_id
        ),
        {
          responseType: "blob",
        }
      );
    },
    getRunBatches: (activeWorkspace, optimiser_id, version_id, run_id) => {
      return getData(
        apiPaths.batchOptimisation.getRunBatches(activeWorkspace, optimiser_id, version_id, run_id)
      );
    },
  },
  dataset: {
    getData: (activeWorkspace) => {
      return getData(apiPaths.dataset.getData(activeWorkspace));
    },
    getItemData: (dataset_id) => {
      return getData(apiPaths.dataset.getItemData(dataset_id));
    },
    getPreview: (dataset_id) => {
      return getData(apiPaths.dataset.getPreview(dataset_id));
    },
    createDataset: (data) => {
      return putData(apiPaths.dataset.createDataset(), {
        data,
        header: `multipart/form-data`,
      });
    },
    deleteDataset: (dataset_id) => {
      return deleteData(apiPaths.dataset.deleteDataset(dataset_id));
    },
    updateDataSet: (dataset_id, data) => {
      return patchData(apiPaths.dataset.updateDataSet(dataset_id), {
        data,
        header: `multipart/form-data`,
      });
    },
    downloadDataset: (dataset_id) => {
      return getData(apiPaths.dataset.downloadDataset(dataset_id));
    },
  },
  runs: {
    createRun: (activeWorkspace, optimiser_id, version_id, data) => {
      return putData(apiPaths.runs.createRun(activeWorkspace, optimiser_id, version_id), {
        data,
      });
    },
    getData: (activeWorkspace, optimiser_id, version_id) => {
      return getData(apiPaths.runs.getData(activeWorkspace, optimiser_id, version_id));
    },
    getRunData: (activeWorkspace, optimiser_id, version_id, run_id) => {
      return getData(apiPaths.runs.getRunData(activeWorkspace, optimiser_id, version_id, run_id));
    },
  },
  output: {
    enableExplain: (deployment_id) => {
      return patchData(apiPaths.output.enableExplain(deployment_id));
    },
    disableExplain: (deployment_id) => {
      return patchData(apiPaths.output.disableExplain(deployment_id));
    },
  },
  partitions: {
    getPartitions: (version_id) => {
      return getData(apiPaths.partitions.getPartitions(version_id));
    },
  },
  preferences: {
    updatePreferences: (data) => {
      return patchData(apiPaths.preferences.updatePreferences(), {
        data,
      });
    },
  },
  deployKeys: {
    getDeployKeys: (deploymentId) => {
      return getData(apiPaths.deployKeys.getDeployKeys(deploymentId));
    },
    getActiveDeployKeys: (activeWorkspace) => {
      return getData(apiPaths.deployKeys.getActiveDeployKeys(activeWorkspace));
    },
    createDeployKey: (data) => {
      return putData(apiPaths.deployKeys.createDeployKey(), {
        data,
      });
    },
    deleteDeployKey: (deploymentId) => {
      return deleteData(apiPaths.deployKeys.deleteDeployKey(deploymentId));
    },
    revokeAllDeployKeys: (deploymentId) => {
      return patchData(apiPaths.deployKeys.revokeAllDeployKeys(deploymentId));
    }
  },
  network: {
    createIpAddress: (deploymentId, data) => {
      return putData(apiPaths.network.createIpAddress(deploymentId), {
        data,
      });
    },
    handleToggleChange: (deployment_id, action) => {
      return patchData(apiPaths.network.handleToggleChange(deployment_id, action));
    },
    getFirewalls: (deploymentId) => {
      return getData(apiPaths.network.getFirewalls(deploymentId));
    },
    removeIpAddress: (deploymentId, sourceToRemove) => {
      return deleteData(
        apiPaths.network.removeIpAddress(deploymentId, sourceToRemove)
      );
    },
  },
  calendar: {
    getCalendarData: () => {
      return getData(apiPaths.calendar.getCalendarData());
    },
  },
  recent: {
    getRecentData: (activeWorkspace) => {
      console.log(activeWorkspace.team_id, "active");
      return getData(apiPaths.recent.getRecentData(activeWorkspace));
    },
  },
  accountSettings: {
    updateProfileImage: (data) => {
      return patchData(apiPaths.accountSettings.updateProfileImage(), {
        data,

      });
    },
    updateDetails: (data) => {
      return patchData(apiPaths.accountSettings.updateDetails(), {
        data
      });
    },
  },
  configuration: {
    saveConfiguration: (data) => {
      return patchData(apiPaths.configuration.saveConfiguration(),
        {
          data,
        }
      );
    },
  },
  login: {
    login: (token) => {
      return getData(apiPaths.login.login(), {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
    },
    limits: (activeWorkspace) => {
      return getData(apiPaths.login.limits(activeWorkspace));
    },
  },
  train: {
    dataPrep: (data) => {
      return postData(apiPaths.train.dataPrep(), {
        data,
      });
    },
    summarize: (data) => {
      return postData(apiPaths.train.summarize(), {
        data,
        header: `multipart/form-data`,
      });
    },
    summarizeUrl: (activeWorkspace, data) => {
      return postData(apiPaths.train.summarizeUrl(activeWorkspace), {
        data,
      });
    },
    goal: (data) => {
      return postData(apiPaths.train.goal(), {
        data,
      });
    },
    autoTrain: (data) => {
      return postData(apiPaths.train.autoTrain(), {
        data,
      });
    },
    applyStep: (data) => {
      return postData(apiPaths.train.applyStep(), {
        data,
      });
    },
    downloadDataprep: (data) => {
      return postData(apiPaths.train.downloadDataprep(), {
        data,
      });
    },
    dropDataprep: (data) => {
      return postData(apiPaths.train.dropDataprep(), {
        data,
      });
    },
    train: (data) => {
      return postData(apiPaths.train.train(), {
        data,
      });
    },
    featureEngineering: (data) => {
      return postData(apiPaths.train.featureEngineering(), {
        data,
      });
    },
    insights: (data) => {
      return postData(apiPaths.train.insights(), {
        data,
      });
    },
    visualize: (data) => {
      return postData(apiPaths.train.visualize(), {
        data,
      });
    },
    visualizeEdit: (data) => {
      return postData(apiPaths.train.visualizeEdit(), {
        data,
      });
    },
    visualizeRepair: (data) => {
      return postData(apiPaths.train.visualizeRepair(), {
        data,
      });
    },
    visualizeExplain: (data) => {
      return postData(apiPaths.train.visualizeExplain(), {
        data,
      });
    },
    visualizeEvaluate: (data) => {
      return postData(apiPaths.train.visualizeEvaluate(), {
        data,
      });
    },
    visualizeRecommend: (data) => {
      return postData(apiPaths.train.visualizeRecommend(), {
        data,
      });
    },
    textGenerate: (data) => {
      return postData(apiPaths.train.textGenerate(), {
        data,
      });
    },
    infographer: (data) => {
      return postData(apiPaths.train.infographer(), {
        data,
      });
    },
    getModels: () => {
      return getData(apiPaths.train.getModels());
    },
    getApiKey: (modelProvider) => {
      return getData(apiPaths.train.getApiKey(modelProvider));
    },
    setApiKey: (data) => {
      return patchData(apiPaths.train.setApiKey(), {
        data,
      });
    },
  },
};
