import { debounce } from "lodash";
import { handleTokenExpired } from "./handleTokenExpired";

export const debouncedSearch = (activeWorkspace, input, apiKey, callback) => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const debouncedFunction = debounce(() => {
    // Make the API call here

    //TODO: REWRITE FUNCTION
    fetch(
      `${hostUrl}/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/search-user?search_string=${input}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          api_key: apiKey,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        console.log(error);
        handleTokenExpired();
      });
  }, 200);

  debouncedFunction();
};
