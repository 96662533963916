import { useMemo } from 'react';
import PropTypes from 'prop-types';

const TICK_LENGTH = 10;

export const AxisLeft = ({ yScale, pixelsPerTick, width }) => {
  const range = yScale.range();

  const ticks = useMemo(() => {
    const height = range[0] - range[1];
    const numberOfTicksTarget = Math.floor(height / pixelsPerTick);

    return yScale.ticks(numberOfTicksTarget).map(value => ({
      value,
      yOffset: yScale(value),
    }));
  }, [yScale, pixelsPerTick]);

  return (
    <>
      {ticks.map(({ value, yOffset }) => (
        <g key={value} transform={`translate(0, ${yOffset})`} shapeRendering="crispEdges">
          <line x1={-TICK_LENGTH} x2={width + TICK_LENGTH} stroke="#D2D7D3" strokeWidth={0.5} />
          <text style={{ fontSize: '10px', textAnchor: 'end', transform: `translateX(-10px)`, fill: '#D2D7D3', alignmentBaseline: 'middle' }}>
            {value}
          </text>
        </g>
      ))}
    </>
  );
};

AxisLeft.propTypes = {
  yScale: PropTypes.func.isRequired, // yScale is a function, typically from d3.scaleLinear
  pixelsPerTick: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
