import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { userName, firstName, lastName, bio, email, position, survey, city, country, company, companyPhone, companyCity, companyCountry, address1, address2, zip, twitter },
} = checkout;

const validations = [
  Yup.object().shape({
    [userName.name]: Yup.string().required(userName.errorMsg),
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [city.name]: Yup.string().required(city.errorMsg),
    [country.name]: Yup.string().required(country.errorMsg),
    [position.name]: Yup.string().required(position.errorMsg),
    [bio.name]: Yup.string()
  }),
  // Yup.object().shape({
  //   [company.name]: Yup.string().required(company.errorMsg),
  //   [companyPhone.name]: Yup.string().required(companyPhone.errorMsg),
  //   [companyCity.name]: Yup.string().required(companyCity.errorMsg),
  //   [companyCountry.name]: Yup.string().required(companyCountry.errorMsg),
  //   [address1.name]: Yup.string().required(address1.errorMsg),
  //   [address2.name]: Yup.string().required(address1.errorMsg),
  //   [zip.name]: Yup.string().required(zip.errorMsg).min(4, zip.invalidMsg),
  // }),
  Yup.object().shape({
    [survey.name]: Yup.string().required(survey.errorMsg),
  }),
];

export default validations;
