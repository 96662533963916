import { useModel } from "hooks";
import ScenarioAnalysis from "shared/models/ScenarioAnalysis";
import React, { useState } from "react";

export const ReportScenarioAnalysis = React.memo(() => {
  const { profileData } = useModel();

  const [fieldValues, setFieldValues] = useState({});
  const [probability, setProbability] = useState(0);
  const [score, setScore] = useState(profileData.base_value);
  const [multiplier, setMultiplier] = useState(0);

  let firstKey;
  if (profileData && (profileData.categorical || profileData.numeric)) {
    firstKey =
      profileData.categorical && Object.keys(profileData.categorical).length > 0
        ? Object.keys(profileData.categorical)[0]
        : Object.keys(profileData.numeric)[0];
  }

  if (profileData && Object.keys(profileData).length > 0 && firstKey) {
    return (
      <ScenarioAnalysis
        fieldValues={fieldValues}
        setFieldValues={setFieldValues}
        setProbability={setProbability}
        setScore={setScore}
        setMultiplier={setMultiplier}
        probability={probability}
        score={score}
        multiplier={multiplier}
      />
    );
  }

  return null;
});
