import { createContext, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import DummyComponent from "layouts/reports/ReportBuilder/MockComponents";
import DataHealth from "layouts/models/model/components/preprocessing/components/health";

import PropTypes from "prop-types";

import { v4 as uuidv4 } from "uuid";
import { MockLineChart } from "layouts/reports/ReportBuilder/MockComponents/types";
import { MdComment } from "react-icons/md";

import { REPORT_COMPONENTS_KEYS } from "constants";
import { useLocation } from "react-router-dom";
import { Pipeline } from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components/Pipeline";
import {
  BaseValue,
  Commentary,
  Comments,
  CurrentOverview,
  Curves,
  ReportModelProfile,
  ReportRegressionChart,
  ReportResidualChart,
  ReportScenarioAnalysis,
  ThresholdOptimisation,
} from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components";

import ThresholdPlot from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components/ThresholdPlot";

import CommentaryIcon from "assets/images/icons/reports/commentary-icon.svg";
import OverviewIcon from "assets/images/icons/reports/overview-icon.svg";
import ScenarioIcon from "assets/images/icons/reports/scenario-icon.svg";
import ResidualIcon from "assets/images/icons/reports/residual-icon.svg";
import RegressionIcon from "assets/images/icons/reports/regression-icon.svg";
import HealthIcon from "assets/images/icons/reports/health-icon.svg";
import PipelineIcon from "assets/images/icons/reports/pipeline-icon.svg";
import CommentsIcon from "assets/images/icons/reports/comments-icon.svg";
import ConfusionMatrix from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components/ConfusionMatrix";
import MetricsTable from "layouts/reports/ReportBuilder/components/CreateReport/components/ReportPageWithData/components/Metrics";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];
  destClone.splice(droppableDestination.index, 0, { ...item, id: uuidv4() });
  return destClone;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

const PROFILE = [
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.ARTICLE,
    numberOfColumns: 2,
    title: "Commentary",
    sideBarContent: (
      <DummyComponent
        title="Commentary"
        caption="Insightful commentary and analysis related to the report."
        icon={CommentaryIcon}
      />
    ),
    content: () => <Commentary />,
  },

  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.CURRENT_OVERVIEW,
    numberOfColumns: 1,
    title: "Current Overview",
    sideBarContent: (
      <DummyComponent
        title="Current Overview"
        caption="A brief overview of the current report status."
        icon={OverviewIcon}
      />
    ),
    content: () => <CurrentOverview />,
  },
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.BASE_VALUE,
    numberOfColumns: 1,
    title: "Base Value",
    sideBarContent: (
      <DummyComponent
        title="Base Value"
        caption="Fundamental metrics and values for baseline comparison."
        icon={CommentaryIcon}
      />
    ),
    content: () => <BaseValue />,
  },
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.BINARY_OVERVIEW,
    numberOfColumns: 2,
    title: "Overview",
    sideBarContent: (
      <DummyComponent
        title="Overview"
        caption="A comprehensive overview of the binary model's performance."
        icon={OverviewIcon}
      />
    ),
    content: () => <ReportModelProfile />,
  },
];

const SCENARIO = [
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.WATERFALL_PLOT,
    title: "Waterfall Plot",
    numberOfColumns: 2,
    sideBarContent: (
      <DummyComponent
        title="Scenario Plot"
        caption="Run scenarios in the browser to see change in outcomes."
        icon={ScenarioIcon}
      />
    ),
    content: () => <ReportScenarioAnalysis />,
  },
];

const PERFORMANCE = [
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.RESIDUAL_CHART,
    title: "Residual chart",
    numberOfColumns: 1,
    sideBarContent: (
      <DummyComponent
        title="Residual Chart"
        caption="Plot the residuals of the Classification outcome."
        icon={ResidualIcon}
      />
    ),
    content: () => <ReportResidualChart />,
  },
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.REGRESSION_CHART,
    title: "Regression chart",
    numberOfColumns: 1,
    sideBarContent: (
      <DummyComponent
        title="Regression Chart"
        caption="Plot the residuals of the Regression outcome."
        icon={RegressionIcon}
      />
    ),
    content: () => <ReportRegressionChart />,
  },
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT,
    title: "Threshold Plot",
    numberOfColumns: 1,
    sideBarContent: (
      <DummyComponent
        title="Threshold Plot"
        caption="Visualize the effectiveness of different threshold values."
        icon={RegressionIcon}
      />
    ),
    content: () => <ThresholdPlot />,
  },
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX,
    title: "Confusion Matrix",
    numberOfColumns: 1,
    sideBarContent: (
      <DummyComponent
        title="Confusion Matrix"
        caption="Display the performance of your classification model."
        icon={RegressionIcon}
      />
    ),
    content: () => <ConfusionMatrix />,
  },
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.METRICS,
    title: "Metrics",
    numberOfColumns: 1,
    sideBarContent: (
      <DummyComponent
        title="Metrics"
        caption="Review key performance metrics of your model."
        icon={RegressionIcon}
      />
    ),
    content: () => <MetricsTable />,
  },
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.PR_CURVE_ROC_CURVE,
    title: "PR Curve/ROC Curve",
    numberOfColumns: 1,
    sideBarContent: (
      <DummyComponent
        title="PR Curve/ROC Curve"
        caption="Compare Precision-Recall and Receiver Operating Characteristic curves."
        icon={RegressionIcon}
      />
    ),
    content: () => <Curves />,
  },
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.THRESHOLD_OPTIMISATION,
    title: "Threshold optimisation",
    numberOfColumns: 2,
    sideBarContent: (
      <DummyComponent
        title="Threshold"
        caption="Adjust cost values to optimise for the best threshold."
        icon={RegressionIcon}
      />
    ),
    content: () => <ThresholdOptimisation />,
  },
];

const PLOTTING = [
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.LINE_CHART,
    title: "Model Plot",
    numberOfColumns: 1,
    sideBarContent: <MockLineChart />,
    content: () => <MockLineChart />,
  },

  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.LINE_CHART,
    title: "Deployment Plot",
    numberOfColumns: 1,
    sideBarContent: <MockLineChart />,
    content: () => <MockLineChart />,
  },
];

const PREPROCESSING = [
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.HEALTH,
    title: "Health",
    numberOfColumns: 2,
    sideBarContent: (
      <DummyComponent
        title="Health"
        caption="Assess the overall health and quality of your data."
        shadow={true}
        icon={HealthIcon}
      />
    ),
    content: () => <DataHealth />,
  },
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.PIPELINE,
    title: "Pipeline",
    numberOfColumns: 2,
    sideBarContent: (
      <DummyComponent
        title="Pipeline"
        caption="Visualize and manage your data processing pipeline."
        icon={PipelineIcon}
      />
    ),
    content: () => <Pipeline />,
  },
];

const USERDEFINED = [
  {
    id: uuidv4(),
    type: REPORT_COMPONENTS_KEYS.COMMENTS,
    numberOfColumns: 1,
    title: "Comments",
    sideBarContent: (
      <DummyComponent
        title="Comments"
        caption="View and manage user comments on the report."
        icon={CommentsIcon}
      />
    ),
    content: (id) => <Comments id={id} />,
  },
];

const initialAppContext = {};

export const AppContext = createContext(initialAppContext);

export const AppProvider = ({ children }) => {
  const [lists, setLists] = useState({ [uuidv4()]: [], [uuidv4()]: [] });
  const [commentsValues, setCommentsValues] = useState({});
  const [reportId, setReportId] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [reportList, setReportList] = useState(null);
  const [reportInfo, setReportInfo] = useState({ name: "", description: "" });
  const [optimisationInfo, setOptimisationInfo] = useState({ name: "", description: "" });

  const [activeTitle, setActiveTitle] = useState("");

  const [selectedModel, setSelectedModel] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const splittedPathName = location.pathname.split("/");
    const isTabChanged = !splittedPathName.includes("reports");

    if (isTabChanged) {
      setActiveStep(0);
      setReportList(null);
      setLists({ [uuidv4()]: [], [uuidv4()]: [] });
      setReportInfo({ name: "", description: "" });
    }
  }, [location.pathname]);

  const handleDroppable = (source, destination, type) => {
    const modifiedObject = {};
    const newTypeItem = {
      ...lists,
      [destination.droppableId]: copy(type, lists[destination.droppableId], source, destination),
    };

    const resultKey = destination.droppableId;
    let keyBefore = null;
    let prevKey = null;

    for (const key in newTypeItem) {
      if (key === resultKey && newTypeItem[resultKey][0].numberOfColumns === 2) {
        keyBefore = prevKey;
        break;
      }
      prevKey = key;
    }

    for (const key in newTypeItem) {
      if (resultKey && key === resultKey && keyBefore && newTypeItem[keyBefore].length === 0) {
        modifiedObject[uuidv4()] = [];
      }
      modifiedObject[key] = newTypeItem[key];
    }

    const fullFieldContainers = Object.values(modifiedObject).filter((item) => item.length > 0);

    if (fullFieldContainers.length === Object.values(lists).length) {
      for (let i = 0; i < 2; i++) {
        modifiedObject[uuidv4()] = [];
      }
      setLists(modifiedObject);

      return;
    }

    setLists(modifiedObject);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (
      !destination &&
      (source?.droppableId !== "PROFILE" ||
        source?.droppableId === "SCENARIO" ||
        source?.droppableId === "PERFORMANCE" ||
        source?.droppableId === "PLOTTING" ||
        source?.droppableId === "PREPROCESSING" ||
        source?.droppableId === "USERDEFINED")
    ) {
      setLists({
        ...lists,
        [source.droppableId]: [],
      });
      return;
    }

    if (lists[destination.droppableId].length > 0) return;

    switch (source.droppableId) {
      case destination.droppableId:
        setLists({
          ...lists,
          [destination.droppableId]: reorder(
            lists[source.droppableId],
            source.index,
            destination.index
          ),
        });
        break;
      case "PROFILE":
        handleDroppable(source, destination, PROFILE);
        break;
      case "SCENARIO":
        handleDroppable(source, destination, SCENARIO);
        break;
      case "PERFORMANCE":
        handleDroppable(source, destination, PERFORMANCE);
        break;
      case "PLOTTING":
        handleDroppable(source, destination, PLOTTING);
        break;
      case "PREPROCESSING":
        handleDroppable(source, destination, PREPROCESSING);
        break;
      case "USER DEFINED":
        handleDroppable(source, destination, USERDEFINED);
        break;
      default:
        setLists({
          ...lists,
          ...move(lists[source.droppableId], lists[destination.droppableId], source, destination),
        });
        break;
    }
  };

  const sidebarItems = [
    { name: "PROFILE", items: PROFILE },
    { name: "SCENARIO", items: SCENARIO },
    { name: "PERFORMANCE", items: PERFORMANCE },
    // { name: "PLOTTING", items: PLOTTING },
    { name: "PREPROCESSING", items: PREPROCESSING },
    { name: "USER DEFINED", items: USERDEFINED },
  ];

  const addList = () => {
    setLists({ ...lists, [uuidv4()]: [], [uuidv4()]: [] });
  };

  const removeList = (itemId) => {
    const newData = { ...lists };

    if (newData[itemId]) {
      delete newData[itemId];
    }

    if (Object.values(newData).length === 0) {
      setLists({ [uuidv4()]: [], [uuidv4()]: [] });
      return;
    }

    setLists(newData);
  };

  return (
    <AppContext.Provider
      value={{
        sidebarItems,
        lists,
        addList,
        removeList,
        activeStep,
        setActiveStep,
        setLists,
        reportId,
        setReportId,
        commentsValues,
        setCommentsValues,
        reportList,
        setReportList,
        reportInfo,
        setReportInfo,
        selectedModel,
        setSelectedModel,
        optimisationInfo,
        setOptimisationInfo,
        activeTitle,
        setActiveTitle,
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
