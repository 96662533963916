import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useNotificationsMutation = () => {
  //TODO this should be a useQuery - not a mutation
  const { mutate: getNotificationsMutation } = useMutation((params) =>
    apiHandlers.notifications.getNotifications(...params)
  );

  const { mutate: markAsReadMutation } = useMutation((params) =>
    apiHandlers.notifications.markAsRead(...params)
  );

  const { mutate: markAllAsReadMutation } = useMutation((params) =>
    apiHandlers.notifications.markAllAsRead(...params)
  );

  return {
    getNotificationsMutation,
    markAsReadMutation,
    markAllAsReadMutation,
  };
};
