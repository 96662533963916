import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XAvatar from "components/XAvatar";
import XInput from "components/XInput";
import { Icon } from "@mui/material";
import FormField from "shared/Forms/FormField";
import { useAccountSettingMutation } from "api/mutations/useAccountSettingMutation";

function UserInfo({ formData }) {
  const { apiKey, user, setUser } = useApiKey();
  const { updateProfileImageMutation, updateDetailsMutation } = useAccountSettingMutation();

  const [avatar, setAvatar] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [allFieldsEditing, setAllFieldsEditing] = useState(false);
  const { formField, values, errors, touched } = formData;

  // Extract user data from new structure
  const { username, email, profile_image_url, personal_details } = user || {};
  const { given_name, family_name, bio, city, country } = personal_details || {};

  const {
    userName: userNameV,
    firstName: firstNameV,
    lastName: lastNameV,
    city: cityV,
    country: countryV,
    position: positionV,
    email: emailV,
    bio: bioV,
  } = values;

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2097152) {
        alert("The selected file is too large. Please select a file less than 2MB.");
        return;
      }

      const formData = new FormData();
      formData.append("image", file);

      updateProfileImageMutation([formData], {
        onSuccess: (data) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setAvatar(reader.result);
          };
          reader.readAsDataURL(file);
        },
        onError: (error) => {
          alert(`Failed to update profile image: ${error.message}`);
        },
      });
    }
  };

  const fileInputRef = useRef(null);

  const handleEditClick = (fieldName) => {
    if (fieldName === "profilePicture") {
      fileInputRef.current.click();
      return;
    }

    setEditingField(editingField === fieldName ? null : fieldName);
  };

  const handleEditFieldsClick = () => {
    if (allFieldsEditing) {
      handleSubmit();
    }
    setAllFieldsEditing(!allFieldsEditing);
  };

  const handleSubmit = () => {
    const updatedFields = {};
    for (let key in values) {
      if (values[key] !== formField[key].value) {
        updatedFields[key] = values[key];
      }
    }

    handleFieldUpdate(updatedFields);
  };

  const handleFieldUpdate = async (updatedFields) => {
    const fieldMapping = {
      userName: "username",
      firstName: "given_name",
      lastName: "family_name",
      position: "position",
      city: "city",
      country: "country",
      bio: "bio",
    };

    const mappedFields = {};
    const updatedUser = { ...user };
    for (let key in updatedFields) {
      if (fieldMapping[key]) {
        const mappedKey = fieldMapping[key];
        mappedFields[mappedKey] = updatedFields[key];
        if (personal_details && mappedKey in personal_details) {
          updatedUser.personal_details[mappedKey] = updatedFields[key];
        } else {
          updatedUser[mappedKey] = updatedFields[key];
        }
      }
    }

    updateDetailsMutation([JSON.stringify(mappedFields)], {
      onSuccess: () => {
        setEditingField(null);
        setAllFieldsEditing(false);
        setUser(updatedUser);
      },
      onError: (error) => {
        alert(`Failed to update user details: ${error.message}`);
      },
    });
  };

  return (
    <XBox p={5}>
      <XBox display="flex" height={"100%"} flexDirection="column" p={2} alignItems="start">
        <XTypography variant="button" fontSize="18px">
          About me
        </XTypography>
        <XTypography variant="button" fontWeight="regular" color="text">
          Update your user information
        </XTypography>
      </XBox>
      <XBox display="flex" gap={4}>
        <XBox display="flex" height="100%" flexDirection="column" alignItems="center">
          <XBox position="relative" mx="auto">
            <XAvatar src={avatar || profile_image_url} alt="profile picture" size="xxl" variant="rounded" />
            <XBox position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
              <label htmlFor="avatarInput">
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    component="span"
                    sx={{ animation: "shake 2s linear infinite" }}
                    onClick={() => handleEditClick("profilePicture")}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </label>
            </XBox>
          </XBox>
        </XBox>
        <XBox display="flex" flexDirection="column" width="100%">
          <XBox display="flex" width="100%" gap="40px">
            <XBox position="relative" width="100%">
              <FormField
                as="input"
                label="Username"
                name="username"
                value={username || ""}
                disabled={editingField !== "username"}
              />
              <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    onClick={() => handleEditClick("username")}
                    sx={{ animation: "shake 2s linear infinite" }}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </XBox>
            </XBox>
            <XBox position="relative" width="100%">
              <FormField
                as="input"
                label="Full Name"
                name="given_name"
                value={`${given_name || ""} ${family_name || ""}`}
                disabled={editingField !== "given_name"}
              />
              <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    onClick={() => handleEditClick("given_name")}
                    sx={{ animation: "shake 2s linear infinite" }}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </XBox>
            </XBox>
          </XBox>
          <XBox display="flex" width="100%" gap="40px">
            <XBox position="relative" width="100%">
              <FormField
                as="input"
                label="City"
                name="city"
                value={city || ""}
                disabled={editingField !== "city"}
              />
              <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    onClick={() => handleEditClick("city")}
                    sx={{ animation: "shake 2s linear infinite" }}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </XBox>
            </XBox>
            <XBox position="relative" width="100%">
              <FormField
                as="input"
                label="Country"
                name="country"
                value={country || ""}
                disabled={editingField !== "country"}
              />
              <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    onClick={() => handleEditClick("country")}
                    sx={{ animation: "shake 2s linear infinite" }}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </XBox>
            </XBox>
          </XBox>
          <XBox display="flex" width="100%" gap="40px">
            <XBox width="100%">
              <XBox position="relative" height="max-content" width="100%">
                <FormField
                  as="input"
                  label="Position"
                  name="position"
                  value={positionV || ""}
                  disabled={editingField !== "position"}
                />
                <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                  {allFieldsEditing && (
                    <XButton
                      variant="gradient"
                      color="light"
                      size="small"
                      iconOnly
                      onClick={() => handleEditClick("position")}
                      sx={{ animation: "shake 2s linear infinite" }}
                    >
                      <Icon>edit</Icon>
                    </XButton>
                  )}
                </XBox>
              </XBox>
            </XBox>
            <XBox width="100%">
              <XBox width="100%">
                <XBox  lineHeight={0} display="inline-block" width="100%">
                  <XTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    Email
                  </XTypography>
                </XBox>
                <XInput value={email || ""} disabled={true} width="100%" />
              </XBox>
            </XBox>
          </XBox>
          <XBox width="100%">
            <XBox width="100%">
              <XBox mb={1.5} width="100%">
                <XBox mb={1} ml={0.5} lineHeight={0} display="inline-block" width="100%">
                  <XTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    Bio
                  </XTypography>
                </XBox>
                <XBox position="relative" height="max-content" mx="auto">
                  <XInput
                    placeholder="Please enter a Bio..."
                    multiline
                    rows={5}
                    value={bio || ""}
                    disabled={editingField !== "bio"}
                  />
                  <XBox position="absolute" right={0} top={0} mr={-1} mt={-2}>
                    {allFieldsEditing && (
                      <XButton
                        variant="gradient"
                        color="light"
                        size="small"
                        iconOnly
                        component="span"
                        onClick={() => handleEditClick("bio")}
                        sx={{ animation: "shake 2s linear infinite" }}
                      >
                        <Icon>edit</Icon>
                      </XButton>
                    )}
                  </XBox>
                </XBox>
              </XBox>
            </XBox>
          </XBox>
        </XBox>
      </XBox>
      <XBox sx={{ mt: 1, display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <XButton color="button" variant="gradient" px={4} onClick={handleEditFieldsClick}>
          {allFieldsEditing ? "Update All" : "Edit Fields"}
        </XButton>
      </XBox>
    </XBox>
  );
}

UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
