import { forwardRef } from "react";
import PropTypes from "prop-types";
import {  Icon } from "@mui/material";
import XBox from "components/XBox";
import XTypography from "components/XTypography";


function menuItem(theme) {
  const { palette, borders, transitions } = theme;

  const { secondary, light } = palette;
  const { borderRadius } = borders;

  return {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: secondary.main,
    py: 1,
    px: 1,
    borderRadius: borderRadius.md,
    transition: transitions.create("background-color", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    "&:not(:last-child)": {
      mb: 1.25,
    },

    "&:hover": {
      backgroundColor: light.main,
    },
  };
}

function menuImage(theme, ownerState) {
  const { functions, palette, borders } = theme;
  const { color } = ownerState;

  const { linearGradient } = functions;
  const { gradients } = palette;
  const { borderRadius } = borders;

  return {
    display: "grid",
    placeItems: "center",
    backgroundImage: gradients[color]
      ? linearGradient(gradients[color].main, gradients[color].state)
      : linearGradient(gradients.dark.main, gradients.dark.state),

    "& img": {
      width: "100%",
      borderRadius: borderRadius.lg,
    },
  };
}

const RequestItem = forwardRef(({ color, image, title, date, confirm, deny, ...rest }, ref) => (
  <XBox {...rest} ref={ref} sx={(theme) => menuItem(theme)} display="flex" justifyContent="space-between">
      <XBox display="flex" justifyContent="space-between">
        <XBox
        width="2.25rem"
        height="2.25rem"
        mt={0.25}
        mr={2}
        mb={0.25}
        borderRadius="lg"
        sx={(theme) => menuImage(theme, { color })}
        >
        {image}
        </XBox>
        <XBox>
            <XBox display="flex" flexDirection="column">
                <XTypography variant="button" textTransform="capitalize" fontWeight="regular">
                    <strong>{title[0]}</strong> 
                </XTypography>
                <XTypography variant="button" fontWeight="regular">
                    {title[1]}
                </XTypography>
        </XBox>
        <XTypography
            variant="caption"
            color="secondary"
            sx={{
            display: "flex",
            alignItems: "center",
            mt: 0.5,
            }}
        >
            <XTypography variant="button" color="secondary">
            <Icon
                sx={{
                lineHeight: 1.2,
                mr: 0.5,
                }}
            >
                watch_later
            </Icon>
            </XTypography>
            {date}
        </XTypography>
        </XBox>
    </XBox>
    <XBox display="flex">
        <XBox>
        {confirm && confirm}
        </XBox>
        <XBox>
        {deny && deny}
        </XBox>
    </XBox>
  </XBox>
));

RequestItem.defaultProps = {
  color: "dark",
};

RequestItem.propTypes = {
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    image: PropTypes.node.isRequired,
    title: PropTypes.arrayOf(PropTypes.string).isRequired,
    date: PropTypes.string.isRequired,
    confirm: PropTypes.object,
    deny: PropTypes.object,
  };
  
  export default RequestItem;
