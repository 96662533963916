import React, { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import DataTable from "shared/Tables/DataTable";
import Members from "shared/Avatars/Members";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XInput from "components/XInput";
import XButton from "components/XButton";
import XSelect from "components/XSelect";
import TableCellComponent from "./components/TableCellComponent";

import PropTypes from "prop-types";

import { useXplainableController } from "context";
import { animateGradient } from "assets/theme/base/animations";
import { Card, Grid, Icon, Modal } from "@mui/material";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useBatchOptimisationQuery, useModelsQuery } from "api/query";
import { BatchOptimisationCard } from "./components";
import { getTimeDifference } from "shared/Functions/Date";
import { useNavigate } from "react-router-dom";
import { useOptimiserMutation } from "api/mutations";
import { useAuth0 } from "@auth0/auth0-react";
import { XViewToggle } from "components/XViewToggle";
import { useApp, useDropdownMenu } from "hooks";
import { ReactComponent as DarkBOIcon } from "assets/images/icons/batch/dark-bo-icon.svg";

import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";
import SearchIcon from "assets/images/search-icon.svg";
import ReportArrowIcon from "assets/images/icons/reports/report-arrow-icon.svg";

import colors from "assets/theme/base/colors";
import { XImg } from "components/XImg";

export const AllBatchOptimisation = () => {
  const { logout } = useAuth0();
  const { viewState, activeWorkspace } = useApiKey();
  const { selectedModel, setSelectedModel, optimisationInfo, setOptimisationInfo } = useApp();

  const { data, isLoading } = useBatchOptimisationQuery(activeWorkspace, logout);
  const { data: modelData } = useModelsQuery(activeWorkspace, logout);

  const { deleteOptimiserMutation, createOptimiserMutation } = useOptimiserMutation();

  const navigate = useNavigate();

  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;

  const [addingOptimisation, setAddingOptimisation] = useState(false);
  const [batchOptimisations, setBatchOptimisations] = useState();
  const [isCardViewEnable, setIsCardViewEnable] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);

  const handleModelChange = (event) => {
    const modelId = event.value;
    setSelectedModel(event);
    const foundModel = models.find((model) => model.model_id === modelId);
    setVersions(foundModel ? foundModel.versions : []);
  };

  const handleVersionChange = (event) => {
    setSelectedVersion(event);
  };

  useEffect(() => {
    if (modelData) {
      setModels(modelData.data);
    }
  }, [modelData]);

  const menuProps = useDropdownMenu(true);

  useEffect(() => {
    if (!data) return;

    setBatchOptimisations(data.data);
  }, [data]);

  const cardActionStyles = ({ palette: { white }, typography: { size } }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",

    "& .material-icons-round": {
      ml: 0.5,
      fontSize: size.md,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round": {
      transform: "translateX(5px)",
    },
  });

  const handleRowClick = (props) => {
    navigate(`/batch-optimisation/${props.row.original.optimiser_id}`, {
      state: { batchInfo: props.row.original },
    });
  };

  const handleDeleteOptimiser = (e, props) => {
    deleteOptimiserMutation([activeWorkspace, props.row.original.optimiser_id], {
      onSuccess: () => {
        const filteredBatchOptimisations = batchOptimisations?.filter(
          (item) => item.optimiser_id !== props.row.original.optimiser_id
        );
        setBatchOptimisations(filteredBatchOptimisations);
        menuProps.closeMenu();
      },
    });
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const renderProjects = () => {
    if (isLoading && isCardViewEnable) {
      return (
        <Card sx={{ width: { xs: "100%", sm: "340px" }, height: "230px" }}>
          <LoadingSpinner size={40} animationType="pulse" />
        </Card>
      );
    }

    if (isLoading && !isCardViewEnable) {
      return (
        <Card sx={{ width: "100%", height: "270px" }}>
          <isLoadingSpinner size={40} animationType="pulse" />
        </Card>
      );
    }

    const columns = [
      {
        Header: "",
        width: "5%",
        id: "function",
        Cell: (props) => <TableCellComponent {...props} handleDelete={handleDeleteOptimiser} />,
      },
      {
        Header: "Optimiser Name",
        accessor: "optimiser_name",
        id: "optimiser_name",
        Cell: (props) => {
          return (
            <XBox display="flex" gap="0 10px" alignItems="center">
              <XImg>
                <DarkBOIcon />
              </XImg>
              <XTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
                fontSize="14px"
                sx={{
                  maxHeight: "30px",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                }}
              >
                {props.row.original.optimiser_name}
              </XTypography>
            </XBox>
          );
        },
      },
      {
        Header: "Version",
        accessor: "version",
        id: "version",
        Cell: (props) => {
          return (
            <XTypography variant="button" fontWeight="light">
              {"Version " + props.row.original.number_of_versions}
            </XTypography>
          );
        },
      },
      {
        Header: "Contributors",
        accessor: "contributors",
        id: "contributors",
        Cell: (props) => {
          if (props.row.original.contributors) {
            return <Members members={props.row.original.contributors} />;
          }
          return null;
        },
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: (props) => {
          return (
            <XTypography variant="button" fontWeight="light">
              {props.row.original.created
                ? getTimeDifference(props.row.original.created, true)
                : null}
            </XTypography>
          );
        },
      },
      {
        Header: "",
        width: "5%",
        accessor: "",
        id: "model_transition",
        Cell: (props) => {
          return (
            <XBox sx={{ cursor: "pointer" }} onClick={() => handleRowClick(props)}>
              <XTypography
                variant="button"
                textTransform="capitalize"
                fontWeight="medium"
                color="xpblue"
                sx={cardActionStyles}
              >
                View Batch
                <XBox src={ReportArrowIcon} component="img" />
              </XTypography>
            </XBox>
          );
        },
      },
    ];

    const filteredOptimisations = batchOptimisations?.filter((item) => {
      const searchQueryLower = searchQuery?.toLowerCase();

      const textConditions =
        item.optimiser_name.toLowerCase().includes(searchQueryLower) ||
        item.optimiser_description.toLowerCase().includes(searchQueryLower);

      return textConditions;
    });

    return (
      <>
        {isCardViewEnable &&
          filteredOptimisations?.map((batchOptimisation, index) => (
            <BatchOptimisationCard
              key={index}
              index={index}
              batchOptimisation={batchOptimisation}
              batchOptimisations={batchOptimisations}
              setBatchOptimisations={setBatchOptimisations}
            />
          ))}

        {!isCardViewEnable && (
          <Card
            sx={{
              width: "100%",
              position: "relative",
              zIndex: 1,
              overflow: "visible",
            }}
          >
            <DataTable
              table={{ rows: filteredOptimisations, columns: columns }}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              // eventComponent={<ReportModal />}
            />
          </Card>
        )}
      </>
    );
  };

  const handleCreateOptimisation = () => {
    if (!selectedModel.value) return;

    const optimiserData = {
      optimiser_name: optimisationInfo.name,
      optimiser_description: optimisationInfo.description,
      model_id: selectedModel.value,
      model_version_id: selectedVersion.value,
    };

    createOptimiserMutation([activeWorkspace, optimiserData], {
      onSuccess: (data) => {
        navigate(`/batch-optimisation/${data.data.optimiser_id}`, {
          state: { batchInfo: optimiserData },
        });
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={"Batch Optimisation"}
        description={"Prescriptive recommendations for optimising tasks in collective batches."}
        icon={"ni ni-atom"}
      />
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: controller.darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <XTypography fontSize="16px" fontWeight="bold">
              New report
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller.darkMode ? white.main : dark.main,
                stroke: controller.darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>
          <XBox display="flex" flexDirection="column" gap={1} mt={2}>
            <XTypography variant="button" fontWeight="light">
              Model
            </XTypography>
            <XSelect
              value={selectedModel}
              onChange={handleModelChange}
              fullWidth
              placeholder="Select Model"
              options={models.map((model) => ({
                value: model.model_id,
                label: model.model_name,
              }))}
            />
            <XTypography variant="overline">Version</XTypography>
            <XSelect
              value={selectedVersion}
              onChange={handleVersionChange}
              fullWidth
              placeholder="Select Version"
              options={versions.map((version) => ({
                value: version.version_id,
                label: version.version_number,
              }))}
            />

            <XTypography variant="overline">Name</XTypography>
            <XInput
              value={optimisationInfo.name}
              onChange={(e) => setOptimisationInfo({ ...optimisationInfo, name: e.target.value })}
            />

            <XTypography variant="overline">Description</XTypography>
            <XInput
              value={optimisationInfo.description}
              onChange={(e) =>
                setOptimisationInfo({ ...optimisationInfo, description: e.target.value })
              }
            />

            <XButton
              variant="contained"
              color="primary"
              onClick={handleCreateOptimisation}
              disabled={!selectedModel}
            >
              Create
            </XButton>
          </XBox>
        </XBox>
      </Modal>
      <XBox pt={3} height={"100%"} pb={{ xs: 0, lg: 10 }}>
        <Grid container sx={{ width: "100%" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <XBox mb={1}>
              <XTypography variant="h5" fontSize="18px">
                All Batches
              </XTypography>
            </XBox>
          </Grid>

          <XBox display="flex" justifyContent="space-between" width="100%">
            <XBox display="flex" gap={2} justifyContent="space-between" width="550px">
              <XInput
                sx={{ width: "100%" }}
                placeholder="Search"
                startAdornment={<XBox src={SearchIcon} component="img" />}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </XBox>

            <XBox display="flex" gap={2}>
              {viewState === "creator" && (
                <XButton variant="gradient" color="button" onClick={() => setIsModalOpen(true)}>
                  {/* <XBox component="img" src={LightPlusIcon} /> */}
                  Create optimisation
                </XButton>
              )}
              <XViewToggle
                color="xpblue"
                isCardViewEnable={isCardViewEnable}
                setIsCardViewEnable={setIsCardViewEnable}
              />
            </XBox>
          </XBox>
        </Grid>

        <XBox mt={1} mb={1}>
          <XBox
            display="flex"
            flexWrap="wrap"
            gap="20px"
            sx={{ justifyContent: { xs: "center", lg: "flex-start" } }}
          >
            {renderProjects()}
          </XBox>
        </XBox>
      </XBox>
    </DashboardLayout>
  );
};

AllBatchOptimisation.propTypes = {
  value: PropTypes.number,
  row: PropTypes.number,
};
