import { forwardRef, createContext, useContext, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// xplainable Dashboard components
import XBox from "components/XBox";

// Custom styles for XPagination
import XPaginationItemRoot from "components/XPagination/XPaginationItemRoot";

// The Pagination main context
const Context = createContext();

const XPagination = forwardRef(({ item, variant, color, size, active, children, ...rest }, ref) => {
  const context = useContext(Context);
  const paginationSize = context ? context.size : null;
  const value = useMemo(() => ({ variant, color, size }), [variant, color, size]);

  return (
    <Context.Provider value={value}>
      {item ? (
        <XPaginationItemRoot
          {...rest}
          ref={ref}
          variant={active ? context.variant : "outlined"}
          color={active ? context.color : "secondary"}
          iconOnly
          ownerState={{ variant, active, paginationSize }}
        >
          {children}
        </XPaginationItemRoot>
      ) : (
        <XBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ listStyle: "none" }}
        >
          {children}
        </XBox>
      )}
    </Context.Provider>
  );
});

// Setting default values for the props of XPagination
XPagination.defaultProps = {
  item: false,
  variant: "gradient",
  color: "button",
  size: "medium",
  active: false,
};

// Typechecking props for the XPagination
XPagination.propTypes = {
  item: PropTypes.bool,
  variant: PropTypes.oneOf(["gradient", "contained"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "button",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default XPagination;
