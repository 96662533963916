import React, { useEffect, useState } from "react";

import { Card, Grid, Icon, MenuItem, Modal } from "@mui/material";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import XBox from "components/XBox";
import XButton from "components/XButton";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import UserTable from "layouts/admin/components/admin/components/UserTable";

import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";
import DarkXCloseIcon from "assets/images/dark-x-close-icon.svg";
import XSelect from "components/XSelect";

import { useToast } from "hooks";
import { useXplainableController } from "context";

import InviteOnlyIcon from "assets/images/icons/reports/invite-only-icon.svg";
import AnyoneIcon from "assets/images/icons/reports/anyone-icon.svg";
import PublicIcon from "assets/images/icons/reports/public-icon.svg";
import CopyLinkIcon from "assets/images/icons/reports/copy-link-icon.svg";

export const ShareReport = ({ isModalOpen, users, setIsModalOpen }) => {
  const { activeWorkspace } = useApiKey();
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const { showSuccessToast } = useToast();

  const [email, setEmail] = useState("");

  const [messageContent, setMessageContent] = useState("");
  const [show, setShow] = useState(false);
  const [tableRows, setTableRows] = useState(users);
  const [isAdmin, setIsAdmin] = useState(false);

  const toggleSnackbar = () => setShow(!show);

  const [selectedOption, setSelectedOption] = useState({
    value: "Invite only",
    label: "Invite only",
  });

  const menuItems = [
    {
      invite_title: "Invite only",
      invite_description: "Only you and people invited can access",
      invite_icon: InviteOnlyIcon,
    },
    {
      invite_title: "Anyone with link at Xplainable",
      invite_description: "People invited and workspace members with link can access",
      invite_icon: AnyoneIcon,
    },
    {
      invite_title: "Everyone at Xplainable",
      invite_description: "All workspace members can search and access",
      invite_icon: AnyoneIcon,
    },
    {
      invite_title: "Public",
      invite_description: "Anyone with an xplainable account and link can access",
      invite_icon: PublicIcon,
    },
  ];

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleCopyToClipboard = () => {
    const urlToCopy = window.location.href; // Replace this with the URL you want to copy
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        showSuccessToast("URL copied ");
      })
      .catch((error) => {
        console.error("Copy to clipboard failed: ", error);
      });
  };
  useEffect(() => {
    setTableRows(users);
  }, [users]);

  console.log(tableRows, "tableRow");

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <XBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -60%)",
          width: 600,
          background: darkMode ? colors.background.dark : colors.background.default,
          borderRadius: "16px",
          padding: 3,
        }}
      >
        <XBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <XTypography variant="button" fontSize="16px">
            Share Report
          </XTypography>
          <XBox component="img" src={DarkXCloseIcon} onClick={() => setIsModalOpen(false)} />
        </XBox>

        <XBox mb={3}>
          <XBox>
            <XBox mb={1} lineHeight={0} display="inline-block">
              <XTypography variant="button" fontWeight="light">
                Emails
              </XTypography>
            </XBox>
            <XInput
              value={email}
              onChange={handleEmailChange}
              placeholder="Add people, groups, or emails"
            />
          </XBox>
          <XBox mt={2}>
            <XBox mb={1} lineHeight={0} display="inline-block">
              <XTypography variant="button" fontWeight="light">
                People with Access
              </XTypography>
              <Grid spacing={3} container mt={1} sx={{ overflow: "hidden" }}>
                <Grid item xs={12} lg={6.5}>
                  {tableRows && (
                    <UserTable
                      data={users}
                      teamData={null}
                      organisationId={activeWorkspace?.organisation_id}
                      entriesPerPage={2}
                      messageContent={messageContent}
                      toggleSnackbar={toggleSnackbar}
                      show={show}
                      tableRows={tableRows}
                      isAdmin={isAdmin}
                      setIsAdmin={setIsAdmin}
                    />
                  )}
                </Grid>
              </Grid>
            </XBox>
          </XBox>
        </XBox>

        <XBox display="flex" justifyContent="space-between" alignItems="center">
          <XSelect
            value={selectedOption}
            // onChange={handleVersionChange}
            fullWidth
            options={menuItems.map((menuItem) => ({
              value: menuItem.invite_title,
              label: (
                <XBox display="flex" gap={1} alignItems="center">
                  <XBox src={menuItem.invite_icon} component="img" />
                  <XTypography variant="body" fontWeight="light">
                    {menuItem.invite_title}
                  </XTypography>
                </XBox>
              ),
            }))}
          />

          <XButton onClick={handleCopyToClipboard} variant="gradient" color="button">
            <XBox display="flex" gap="5px" alignItems="center">
              <XBox src={CopyLinkIcon} component="img" />
              <XTypography fontSize="14px" color="light">
                Copy link
              </XTypography>
            </XBox>
          </XButton>
        </XBox>
      </XBox>
    </Modal>
  );
};

ShareReport.propTypes = {
  isModalOpen: PropTypes.bool,
  users: PropTypes.array,
  setIsModalOpen: PropTypes.func,
};
