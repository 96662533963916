import React, { useState } from "react";
import { ProgressBar } from "../BatchOverview";
import { RunCard } from "../RunCard";
import { Card, Divider, Icon, Menu, MenuItem, Switch, Tooltip } from "@mui/material";

import XBox from "components/XBox";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XSelect from "components/XSelect";

export const Batch = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isAdditionalMenuOpen, setIsAdditionalMenuOpen] = useState(false);

  const [searchInputValue, setSearchInputValue] = useState("");
  const [isCostMenuOpen, setIsCostMenuOpen] = useState();
  const [costPosition, setCostPosition] = useState(null);

  const test = ["ID", "Probability", "Multiplayer", "Score", "Cost"];

  const data = [
    {
      id: "001",
      probability: "27",
      multiplayer: "1.2x",
      score: "22.3",
      cost: "$2.85",
    },
    {
      id: "001",
      probability: "27",
      multiplayer: "1.2x",
      score: "22.3",
      cost: "$2.85",
    },
    {
      id: "001",
      probability: "27",
      multiplayer: "1.2x",
      score: "22.3",
      cost: "$2.85",
    },
  ];

  const costData = [
    {
      title: "Senior Citizen",
      options: [
        { name: "No", value: "No" },
        { name: "Yes", value: "Yes" },
      ],
      isToggled: false,
    },
    { title: "Senior Citizen", options: ["Yes", "No"], isToggled: false },
    { title: "Senior Citizen", options: ["Yes", "No"], isToggled: false },
    { title: "Senior Citizen", options: ["No phone services", "Yes"], isToggled: false },
  ];

  const handleCostMenuOpen = (event, index) => {
    setCostPosition(event.target);
    setIsCostMenuOpen(true);
  };

  const handleCostMenuClose = () => {
    setIsCostMenuOpen(false);
  };

  const handleMenuOpen = (event, index) => {
    setMenuPosition(event.target);
    setIsMenuOpen(true);
    setSelectedIndex(index);
  };

  const handleAdditionalMenuToggle = (e, index) => {
    if (e.target.tagName !== "SPAN" && e.target.tagName !== "P" && !isCostMenuOpen && !isMenuOpen) {
      setIsAdditionalMenuOpen((prev) => (prev === index ? null : index));
    }
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    handleMenuClose();
    setMenuPosition(null);
  };

  return (
    <Card>
      <XBox sx={{ padding: "20px 30px" }} display="flex" flexDirection="column" gap="10px">
        <XTypography fontSize="14px" color="secondary" fontWeight="medium">
          BATCH 1
        </XTypography>
        <XBox display="flex" justifyContent="space-between">
          <XInput
            placeholder="Search Features..."
            sx={{ padding: "5px" }}
            startAdornment={
              <XBox mr={3} display="flex" justifyContent="center" alignItems="center">
                <Icon fontSize="small">search</Icon>
              </XBox>
            }
          />
          <Icon>cloud_download</Icon>
        </XBox>
        <XBox display="flex" flexDirection="column">
          <XBox display="flex" justifyContent="space-between" sx={{ paddingRight: "40px" }}>
            {test.map((item, key) => (
              <XTypography
                key={key}
                fontSize="12px"
                color="secondary"
                sx={{ width: item === "Probability" ? "150px" : " 70px" }}
              >
                {item}
              </XTypography>
            ))}
          </XBox>
          <Divider sx={{ margin: "5px " }} />
          {data.map((item, index) => (
            <XBox
              key={index}
              display="flex"
              flexDirection="column"
              gap="10px"
              onClick={(e) => handleAdditionalMenuToggle(e, index)}
            >
              <XBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
                <XTypography fontSize="16px" fontWeight="regular">
                  {item.id}
                </XTypography>
                <XBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  minWidth="140px"
                >
                  <XTypography fontSize="12px" color="secondary" fontWeight="medium">
                    {item.probability}%
                  </XTypography>
                  <ProgressBar bgColor="#6f42c1" completed={+item.probability} width="100px" />
                </XBox>
                <XTypography fontSize="12px" fontWeight="medium" sx={{ color: "#2cce88" }}>
                  {item.multiplayer}
                </XTypography>
                <XTypography fontSize="12px" color="secondary" fontWeight="medium">
                  {item.score}
                </XTypography>

                <XTypography
                  fontSize="12px"
                  sx={{ color: "#0080e9" }}
                  fontWeight="medium"
                  onClick={(event) => handleCostMenuOpen(event, index)}
                >
                  {item.cost}
                </XTypography>
                <Menu anchorEl={costPosition} open={isCostMenuOpen} onClose={handleCostMenuClose}>
                  <XBox display="flex" maxWidth="300px" sx={{ boxShadow: "none" }}>
                    <XBox display="flex">
                      <XBox>
                        <XTypography fontSize="10px" fontWeight="medium" color="secondary">
                          Search & Toggle Features
                        </XTypography>
                        <XBox
                          mb={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="5px"
                        >
                          <XInput
                            placeholder="Search"
                            value={searchInputValue}
                            onChange={(event) => {
                              setSearchInputValue(event.target.value);
                            }}
                            sx={{
                              minHeight: "30px",
                              maxHeight: "30px",
                            }}
                          />
                          <Switch />
                        </XBox>

                        <XBox display="flex" flexDirection="column" gap="10px">
                          {costData.map((item, index) => (
                            <Card
                              key={index}
                              sx={{ height: "100px", overflow: "visible", zIndex: "10" }}
                            >
                              <XBox p={1}>
                                <XBox
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <XTypography fontSize="10px" color="secondary">
                                    {item.title}
                                  </XTypography>

                                  <XBox sx={{ position: "relative" }}>
                                    <Tooltip title="Tooltip" placement="top">
                                      <XButton
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        circular
                                        iconOnly
                                        sx={{
                                          minWidth: "15px",
                                          maxWidth: "15px",
                                          minHeight: "15px",
                                          maxHeight: "15px",
                                        }}
                                      >
                                        <Icon>question_mark</Icon>
                                      </XButton>
                                    </Tooltip>
                                  </XBox>
                                </XBox>
                                <XBox>
                                  <XBox
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <XBox width="50%">
                                      <XSelect
                                        placeholder="Select Feature"
                                        defaultValue={{ value: "No", label: "No" }}
                                        options={item.options.map((feature) => {
                                          return {
                                            value: feature.name,
                                            label: feature.name,
                                          };
                                        })}
                                        // onChange={dropdownHandler}
                                      />
                                    </XBox>
                                    <Switch />
                                  </XBox>
                                </XBox>
                              </XBox>
                            </Card>
                          ))}
                        </XBox>
                      </XBox>
                    </XBox>
                  </XBox>
                </Menu>

                <XBox id="cost">
                  <Icon
                    fontSize="default"
                    color="secondary"
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => handleMenuOpen(event, index)}
                  >
                    more_vert
                  </Icon>
                  <Menu
                    anchorEl={menuPosition}
                    open={isMenuOpen && selectedIndex === index}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMenuItemClick}>Delete</MenuItem>
                  </Menu>
                </XBox>
              </XBox>
              <XBox
                sx={{
                  maxHeight: isAdditionalMenuOpen === index ? "500px" : "0",
                  transition:
                    isAdditionalMenuOpen === index ? "max-height 0.5s ease, opacity 0.5s ease" : "",
                  opacity: isAdditionalMenuOpen === index ? 1 : 0,
                }}
              >
                {isAdditionalMenuOpen === index && (
                  <XBox>
                    <XTypography color="secondary" fontSize="14px" fontWeight="medium">
                      BREAKDOWN
                    </XTypography>
                    <XBox p={2}>
                      <XBox display="flex" flexDirection="column">
                        <XTypography fontSize="12px" color="secondary">
                          ACTIONS
                        </XTypography>
                        <XBox display="flex" alignItems="center" justifyContent="space-between">
                          <XBox display="flex" flexDirection="column" alignItems="center">
                            <XBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              gap="15px"
                            >
                              <XTypography fontSize="14px">{"Contact --> Email"}</XTypography>
                              <XBox display="flex" gap="5px">
                                <XTypography fontSize="12px">{"+$2,63"}</XTypography>
                                <XTypography fontSize="12px">{"+$0,02%"}</XTypography>
                              </XBox>
                            </XBox>
                            <XBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              gap="15px"
                            >
                              <XTypography fontSize="14px">{"Contact --> Email"}</XTypography>
                              <XBox display="flex" gap="5px">
                                <XTypography fontSize="12px">{"+$2,63"}</XTypography>
                                <XTypography fontSize="12px">{"+$0,02%"}</XTypography>
                              </XBox>
                            </XBox>
                          </XBox>

                          <XBox display="flex" gap="10px">
                            <RunCard
                              title="SCORE"
                              value="0.46"
                              descriptionValue="+3%"
                              descriptionText="from initial state"
                            />
                            <RunCard
                              title="COST OF ACTIONS"
                              value="$2,63"
                              descriptionValue="-$1.22"
                              descriptionText="from initial state"
                            />
                          </XBox>
                        </XBox>
                      </XBox>
                    </XBox>
                  </XBox>
                )}
              </XBox>
            </XBox>
          ))}
        </XBox>
      </XBox>
    </Card>
  );
};
