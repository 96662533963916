import React from "react";

import { Draggable, Droppable } from "react-beautiful-dnd";
import { Grid, Icon, Typography } from "@mui/material";
import { useApp } from "hooks";

import XBox from "components/XBox";

import PropTypes from "prop-types";

import TrashIcon from "assets/images/icons/reports/trash-icon.svg";
import PlusIcon from "assets/images/icons/reports/plus-icon.svg";

export const DroppableContainer = ({ lists, list }) => {
  const { removeList } = useApp();

  const xs = lists[list][0]?.numberOfColumns !== 2 ? 5.8 : 12;
  const sm = lists[list][0]?.numberOfColumns !== 2 ? 5.8 : 12;

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      position="relative"
      sx={{
        position: "relative",
        "&:hover .delete-icon": {
          // Опційні стилі для іконки Delete при наведенні курсора
          opacity: 1,
        },
      }}
    >
      <Droppable droppableId={list}>
        {(provided, snapshot) => {
          return (
            <XBox
              sx={{
                border: !snapshot.isDraggingOver ? "2px dashed #AFAFAF" : "2px solid #0080EA",
                // boxShadow: snapshot.isDraggingOver ? "0px 4px 20px rgba(0, 0, 0, 0.2)" : "none",
                background: !snapshot.isDraggingOver ? "transparent" : "#F0F8FF",
                borderColor: lists[list].length > 0 && "#0080EA",
                transition: "all 0.5s",
                borderRadius: "16px",
                minHeight: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                position: "relative",
                padding: "10px",
              }}
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
            >
              <XBox
                className="delete-icon"
                component="img"
                src={TrashIcon}
                sx={{
                  p: 1,
                  background: "#FFD0D8",
                  borderRadius: "8px",
                  position: "absolute",
                  height: "40px",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                  opacity: 0,
                }}
                onClick={() => removeList(list)}
              />
              {lists[list].length ? (
                lists[list].map((item, index) => {
                  return (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <Grid
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            isDragging={snapshot.isDragging}
                            style={provided.draggableProps.style}
                            item
                            key={index}
                            {...provided.dragHandleProps}
                            sm={11}
                          >
                            {item.content(item.id)}
                          </Grid>
                        );
                      }}
                    </Draggable>
                  );
                })
              ) : (
                <>
                  <XBox
                    className="delete-icon"
                    component="img"
                    src={TrashIcon}
                    sx={{
                      position: "absolute",
                      p: 1,
                      background: "#FFD0D8",
                      borderRadius: "8px",
                      height: "40px",
                      top: 10,
                      right: 10,
                      cursor: "pointer",
                      opacity: 0,
                    }}
                    onClick={() => removeList(list)}
                  />

                  {!snapshot.isDraggingOver && (
                    <XBox position="absolute">
                      <XBox display="flex" flexDirection="column" alignItems="center">
                        <XBox component="img" src={PlusIcon} />
                        <Typography sx={{ fontSize: "18px", color: "#7C7C7C" }}>
                          Add component here
                        </Typography>
                      </XBox>
                    </XBox>
                  )}
                </>
              )}
              {provided.placeholder}
            </XBox>
          );
        }}
      </Droppable>
    </Grid>
  );
};

DroppableContainer.propTypes = {
  lists: PropTypes.object,
  list: PropTypes.string,
};
