import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { animateGradient } from "assets/theme/base/animations";
import { useXplainableController } from "context";
import { OptimisationConfiguration, OptimiserRuns } from "./components";
import { useLocation } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import XBox from "components/XBox";

import BOIcon from "assets/images/icons/batch/bo-icon.svg";
import XTypography from "components/XTypography";
import { useApp } from "hooks";

export const BatchOptimisation = () => {
  const { state } = useLocation();
  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;
  const { setActiveTitle } = useApp();

  const [versionId, setVersionId] = useState(null);
  const [batchInfo, setBatchInfo] = useState(null);
  const [isOptimisationConfigurationVisible, setIsOptimisationConfigurationVisible] =
    useState(true);

  const [additionalHeaders, setAdditionalHeaders] = useState(["Cost"]);

  useEffect(() => {
    if (state?.batchInfo) {
      setBatchInfo({
        title: state.batchInfo.optimiser_name,
        description: state.batchInfo.optimiser_description,
      });

      setActiveTitle(state.batchInfo.optimiser_name);
    }
  }, [state]);

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={"Predictive Maintenance"}
        description={"Predicting machinery failures in region X"}
        icon={"ni ni-atom"}
        rhsElement={
          <XBox display="flex" alignItems="center" gap={1}>
            <XBox display="flex" alignItems="end" justifyContent="space-between">
              <XBox
                display="flex"
                alignItems="center"
                gap={1}
                px="12px"
                py={1}
                bgColor={controller.darkMode ? "#1D1B1B" : "#F7F7F8"}
                sx={{ borderRadius: "24px" }}
              >
                <XBox component="img" src={BOIcon} />
              </XBox>
            </XBox>
            <XBox display="flex" flexDirection="column" gap="4px">
              <XTypography variant="button" fontSize="24px">
                {batchInfo?.title}
              </XTypography>
              <XTypography>{batchInfo?.description}</XTypography>
            </XBox>
          </XBox>
        }
      />
      <XBox pt={3} height={"100%"} pb={{ xs: 0, lg: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={isOptimisationConfigurationVisible ? 8 : 12}>
            <OptimiserRuns
              versionId={versionId}
              additionalHeaders={additionalHeaders}
              setIsOptimisationConfigurationVisible={setIsOptimisationConfigurationVisible}
            />
          </Grid>
          {isOptimisationConfigurationVisible && (
            <Grid item xs={4}>
              <OptimisationConfiguration
                setVersionId={setVersionId}
                additionalHeaders={additionalHeaders}
                setAdditionalHeaders={setAdditionalHeaders}
              />
            </Grid>
          )}
        </Grid>
      </XBox>
    </DashboardLayout>
  );
};
