import { AppBar, Card, Divider, Icon, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";

import PropTypes from "prop-types";

import BarChartIcon from "@mui/icons-material/BarChart";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";

import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XBox from "components/XBox";
import XInput from "components/XInput";

import DescriptionIcon from "@mui/icons-material/Description";
import Papa from "papaparse";

import { useDropzone } from "react-dropzone";
import { StyledBox } from "./styled";
import { useDatasetMutation } from "api/mutations";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import { XDivider } from "components/XDivider";

import DownloadIcon from "assets/images/icons/batch/download-icon.svg";
import ChevronLeftIcon from "assets/images/icons/batch/chevron-left-icon.svg";

export const DataConnectionsBlock = ({ activeStep, setActiveStep }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const { createDatasetMutation } = useDatasetMutation();
  const { activeWorkspace } = useApiKey();

  const [fileNames, setFileNames] = useState("");
  const [fileMetadata, setFileMetadata] = useState([]);
  const [acceptedFiles, setAcceptedFiles] = useState(null);
  const [datasetInformation, setDatasetInformation] = useState({ name: "", description: "" });

  const onDropAccepted = (acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name));
    acceptedFiles.forEach((file) => {
      Papa.parse(file, {
        complete: function (results) {
          const metadata = {
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            rowCount: results.data.length,
            headerColumns: results.data[0],
          };
          setFileMetadata((prevState) => [...prevState, metadata]);
        },
        error: function (err) {
          console.error(`Papa Parse error: ${err}`);
        },
      });
      setAcceptedFiles(acceptedFiles);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    addRemoveLinks: true,
    maxFiles: 1,
    onDrop: onDropAccepted,
    accept: {
      "text/csv": [".csv"],
      "text/xlsx": [".xlsx"],
    },
  });

  const [tabValue, setTabValue] = useState(0);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const stepBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleUploadButton = async () => {
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    formData.append("dataset_name", datasetInformation.name);
    formData.append("dataset_description", datasetInformation.description);

    createDatasetMutation([activeWorkspace, formData], {
      onSuccess: () => {
        stepBack();
      },
      onError: (err) => {
        console.log(err);
        stepBack();
      },
    });
  };

  const handleBackButton = () => {
    setActiveStep(1);
  };

  return (
    <Card height={"100%"} mt={10} pb={{ xs: 0, lg: 10 }}>
      <XBox sx={{ padding: "10px 20px" }}>
        <XBox display="flex" flexDirection="column">
          <XButton
            variant="outlined"
            sx={{
              padding: 0,
              alignSelf: "start",
              minHeight: "20px",
              background: "transparent",
              border: "none",
            }}
            onClick={handleBackButton}
          >
            <XBox display="flex" gap="4px" alignItems="center">
              <XBox src={ChevronLeftIcon} component="img" />
              <XTypography color="xpblue" variant="button">
                Back
              </XTypography>
            </XBox>
          </XButton>
          <XTypography color="secondary" fontWeight="light" fontSize="16px">
            Select your data source
          </XTypography>
          <XBox display="flex" justifyContent="center" alignItems="center" mt={1}>
            <AppBar
              position="static"
              sx={{
                maxWidth: "350px",
                backgroundColor: `${darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,

                transition: "all 500ms ease",
                color: "#AFAFAF",

                "& .Mui-selected": {
                  fontWeight: "600",
                  color: `${darkMode ? "white" : "black"} !important`,
                  backgroundColor: `${darkMode ? "#262525" : "white"} !important`,
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tabs value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Connection"
                  icon={<BarChartIcon size="md" />}
                  sx={{ minWidth: 0, minHeight: "36px" }}
                />
                <Tab
                  label="File Upload"
                  icon={<MoveToInboxIcon />}
                  sx={{ minWidth: 0, minHeight: "36px" }}
                />
              </Tabs>
            </AppBar>
          </XBox>

          {tabValue === 0 && (
            <>
              <XBox
                sx={{
                  minHeight: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <XBox display="flex" flexDirection="column">
                  <Divider />
                </XBox>
                <XBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <XTypography fontSize="14px" color="secondary" fontWeight="light">
                    Data connections coming soon
                  </XTypography>
                </XBox>
                <Divider />
              </XBox>
              <XButton size="small" color="xpblue" disabled sx={{ alignSelf: "end" }}>
                <XTypography variant="button" color="white" ml={0.5}>
                  Connect
                </XTypography>
              </XButton>
            </>
          )}
          {tabValue === 1 && (
            <XBox display="flex" flexDirection="column" alignItems="center" mt={2}>
              <XBox display="flex" gap="20px" width="100%">
                <XBox width="100%">
                  <XBox mb={2}>
                    <XInput
                      placeholder="eg. Soft"
                      value={datasetInformation.name}
                      onChange={(event) => {
                        setDatasetInformation({
                          ...datasetInformation,
                          name: event.target.value,
                        });
                      }}
                    />
                  </XBox>
                </XBox>
                <XBox width="100%">
                  <XBox mb={2}>
                    <XInput
                      placeholder="eg. Tokyo"
                      value={datasetInformation.description}
                      onChange={(event) => {
                        setDatasetInformation({
                          ...datasetInformation,
                          description: event.target.value,
                        });
                      }}
                    />
                  </XBox>
                </XBox>
              </XBox>
              <XTypography fontSize="16px" fontWeight="light" sx={{ alignSelf: "center" }}>
                Upload csv or txt file
              </XTypography>
              <XBox display="flex" width="100%" gap="10px">
                <XBox display="flex" flexDirection="column" gap="10px" width="100%">
                  <StyledBox
                    {...getRootProps()}
                    sx={{
                      height: "300px",
                      border: "1px dashed grey",
                      display: "flex",
                      padding: "24px",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input {...getInputProps()} />
                    {fileNames.length === 0 ? (
                      <>
                        <XTypography variant="caption" style={{ fontSize: "14px" }}>
                          Drag and drop image
                        </XTypography>
                        <XBox display="flex" gap={2} alignItems="center" width="100%">
                          <XDivider />
                          <XTypography
                            style={{ color: "#EAEAEA", fontSize: "14px" }}
                            variant="caption"
                          >
                            or
                          </XTypography>
                          <XDivider />
                        </XBox>
                        <XBox display="flex" alignItems="center" gap={1}>
                          <XTypography
                            variant="button"
                            sx={{
                              backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Upload file
                          </XTypography>
                          <XBox component="img" src={DownloadIcon} />
                        </XBox>
                      </>
                    ) : (
                      fileNames.map((fileName, i) => (
                        <>
                          <DescriptionIcon /> {/* Adjust icon as needed */}
                          <XTypography key={fileName}>{fileName}</XTypography>
                        </>
                      ))
                    )}
                  </StyledBox>
                  <XButton
                    size="small"
                    variant="gradient"
                    color="button"
                    sx={{ alignSelf: "end" }}
                    onClick={handleUploadButton}
                    disabled={fileMetadata.length === 0}
                  >
                    Upload
                  </XButton>
                </XBox>
              </XBox>
            </XBox>
          )}
        </XBox>
      </XBox>
    </Card>
  );
};

DataConnectionsBlock.propTypes = {
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
};
