// @mui material components
import Link from "@mui/material/Link";

// xplainable Dashboard components
import XButton from "components/XButton";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { XDivider } from "components/XDivider";

// xplainable dashboard context
import { useXplainableController } from "context";

// Images
import LightLinkIcon from "assets/images/light-link-icon.svg";
import DarkLinkIcon from "assets/images/dark-link-icon.svg";

function SidenavFooter() {
  const [controller] = useXplainableController();
  const { miniSidenav, darkSidenav } = controller;

  return (
    <XBox opacity={miniSidenav ? 0 : 1} sx={{ transition: "opacity 200ms linear" }}>
      <XBox textAlign="center" display="flex" justifyContent="space-between" alignItems="center">
        <XBox display="flex" flexDirection="column" alignItems="start">
          <XTypography
            variant="caption"
            fontWeight="medium"
            fontSize="14px"
            style={{ color: darkSidenav ? "white" : "black" }}
          >
            Need Help?
          </XTypography>
          <XTypography
            variant="overline"
            lineHeight={1}
            fontSize="14px"
            mb={1}
            style={{ color: darkSidenav ? "white" : "black" }}
          >
            Check our docs!
          </XTypography>
        </XBox>
        <XBox
          src={!darkSidenav ? DarkLinkIcon : LightLinkIcon}
          component="img"
          onClick={() => {
            window.open("https://docs.xplainable.io/", "_blank");
          }}
        />
      </XBox>
      <XBox display="flex" gap={2} alignItems="center" width="100%">
        <XDivider />
        <XTypography
          style={{
            color: "#EAEAEA",
            fontSize: "14px",
          }}
          variant="caption"
        >
          or
        </XTypography>
        <XDivider />
      </XBox>
      <XBox width="100%" mt={1}>
        <XButton
          color="button"
          variant="gradient"
          onClick={() => {
            window.open("https://www.xplainable.io/contact-us", "_blank");
          }}
          sx={{ width: "100%" }}
        >
          Chat with a Data Scientist
        </XButton>
      </XBox>
    </XBox>
  );
}

export default SidenavFooter;
