import { useState } from 'react';
import { useOrganisationMutation } from 'api/mutations';
import { useSubscriptionMutation } from 'api/mutations/useSubscriptionMutation';
import { useToast } from './useToast';
import { useApiKey } from 'components/Authorisation/ApiKeyContext';

export function useOrganisationCreation(trialStarted = false) { // Set default value to false
  const { 
    createOrganisationMutation, 
  } = useOrganisationMutation();
  const { 
    createSubscriptionMutation 
  } = useSubscriptionMutation();
  const { activeWorkspace } = useApiKey();

  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    if (!trialStarted) {  // Only open the modal if trialStarted is false
      setIsModalOpen(true);
    } else {
      // If trialStarted is true, proceed with subscription creation directly
      handleSubmit();
    }
  };

  const handleSuccess = (message, shouldRefresh = false) => {
    showSuccessToast(message);
    if (shouldRefresh) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    handleClose();
  };

  const handleError = (err) => {
    console.log(err);
    showErrorToast("Something went wrong. Please try again.");
    handleClose();
  };

  const handleSubmit = (values, actions) => {
    if (!trialStarted) {
      const item = {
        organisation_name: values.orgName,
        country: values.country,
        state: values.state,
        logo_link: values.logo,
        postcode: values.postCode,
        address: values.address,
      };
    
      createOrganisationMutation([JSON.stringify(item)], {
        onSuccess: () => handleSuccess("The Organisation was created successfully, please wait while we refresh the page.", true),
        onError: handleError,
      });
    } else {
      createSubscriptionMutation([activeWorkspace], {
        onSuccess: (data) => {
          const paymentUrl = data.data;
          if (paymentUrl) {
            window.location.href = paymentUrl;
          }
          actions?.resetForm();
          handleClose();
        },
        onError: handleError,
      });
    }
  };

  return {
    isModalOpen,
    handleOpen,
    handleClose,
    handleSubmit,
  };
}
