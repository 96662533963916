import React from "react";

import PropTypes from "prop-types";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XAvatar from "components/XAvatar";

import { Card, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDropdownMenu } from "hooks";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useTheme } from "@mui/styles";
import { useOptimiserMutation } from "api/mutations";
import { useXplainableController } from "context";

import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

import BOIcon from "assets/images/icons/batch/bo-icon.svg";
import DarkDotsVerticalIcon from "assets/images/icons/dark-dots-vertical-icon.svg";
import ReportArrowIcon from "assets/images/icons/reports/report-arrow-icon.svg";
import LightDotsVerticalIcon from "assets/images/icons/light-dots-vertical-icon.svg";
import DeleteIcon from "assets/images/icons/collections/delete-icon.svg";

export const BatchOptimisationCard = ({
  index,
  batchOptimisation,
  batchOptimisations,
  setBatchOptimisations,
}) => {
  const navigate = useNavigate();

  const [controller] = useXplainableController();
  const { viewState, activeWorkspace } = useApiKey();
  const { deleteOptimiserMutation } = useOptimiserMutation();

  const theme = useTheme();
  const menuProps = useDropdownMenu(true);

  const renderMenu = () => {
    const menuBackground = theme.palette.dark.main;

    return (
      <Menu
        anchorEl={menuProps.menu}
        open={Boolean(menuProps.menu)}
        onClose={menuProps.closeMenu}
        keepMounted
        PaperProps={{
          style: { backgroundColor: menuBackground },
        }}
      >
        <MenuItem
          onClick={() => {
            deleteOptimiserMutation([activeWorkspace, batchOptimisation.optimiser_id], {
              onSuccess: () => {
                const filteredBatchOptimisations = batchOptimisations?.filter(
                  (item) => item.optimiser_id !== batchOptimisation.optimiser_id
                );
                setBatchOptimisations(filteredBatchOptimisations);
                menuProps.closeMenu();
              },
            });
          }}
          style={{
            color: "#EA2C4C",
            display: "flex",
            gap: 8,
            padding: 8,
          }}
        >
          <XBox src={DeleteIcon} component="img" />
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const cardActionStyles = ({ palette: { white }, typography: { size } }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",

    "& .material-icons-round": {
      ml: 0.5,
      fontSize: size.md,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round": {
      transform: "translateX(5px)",
    },
  });

  const renderModelDetails = () => {
    return (
      <XTypography
        variant="button"
        textTransform="capitalize"
        fontWeight="medium"
        color="xpblue"
        sx={cardActionStyles}
        onClick={() => {
          navigate(`/batch-optimisation/${batchOptimisation.optimiser_id}`, {
            state: { batchInfo: batchOptimisation },
          });
        }}
      >
        View Batch
        <XBox src={ReportArrowIcon} component="img" />
      </XTypography>
    );
  };

  const showContributors = 5;
  const renderContributors = batchOptimisation.contributors
    .slice(0, showContributors)
    .map((contributor, key) => {
      const contributorKey = `contributor-${key}`;

      return (
        <Tooltip
          title={contributor.given_name + " " + contributor.family_name}
          key={contributorKey}
        >
          <XAvatar
            key={contributorKey}
            src={typeof contributor.image === "string" ? contributor.image : ""}
            alt="contributor profile"
            size="xs"
            sx={({ borders: { borderWidth }, palette: { white } }) => ({
              border: `${borderWidth[2]} solid ${white.main}`,
              cursor: "pointer",
              position: "relative",

              "&:not(:first-of-type)": {
                ml: -1.25,
              },

              "&:hover, &:focus": {
                zIndex: "10",
              },
            })}
          />
        </Tooltip>
      );
    });

  return (
    <XBox
      key={index}
      m={1}
      sx={{
        opacity: 0,
        width: { xs: "100%", sm: "330px" },
        transform: "translateY(20px)",
        animation: "slide-up 0.5s forwards",
        animationDelay: `${index * 0.03}s`,
        "@keyframes slide-up": {
          "100%": {
            opacity: 1,
            transform: "translateY(0)",
          },
        },
      }}
    >
      <Card
        sx={{
          overflow: "none",
          zIndex: 0,
          width: { xs: "100%", sm: "340px" },
          justifyContent: { xs: "center", lg: "flex-start" },
          backgroundColor: theme.palette.white, // Update this line to set the card background color based on the current theme
          transition: "background-color 300ms, transform 300ms, box-shadow 300ms", // Add transition to the card background color, transform, and box-shadow properties
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: `0px 0px 20px ${rgba(colors.xpblue.main, 3)}`,
          },
        }}
        m={3}
      >
        <XBox p={2} overflow="visible">
          <XBox display="flex" alignItems="end" justifyContent="space-between">
            <XBox
              display="flex"
              alignItems="center"
              gap={1}
              px="12px"
              py={1}
              bgColor={controller.darkMode ? "#1D1B1B" : "#F7F7F8"}
              sx={{ borderRadius: "24px" }}
            >
              <XBox component="img" src={BOIcon} />
              <XTypography color="xpblue" fontSize="12px">
                Batch Optimisation
              </XTypography>
            </XBox>
          </XBox>

          <XBox my={2} lineHeight={1}>
            <XBox
              sx={{
                maxHeight: "30px",
                overflowY: "auto",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
              }}
            >
              <XTypography fontSize="18px" variant="button" color="dark">
                {batchOptimisation.optimiser_name}
              </XTypography>
            </XBox>
            <Stack position="relative">
              <XBox
                sx={{
                  maxHeight: "40px",
                  minHeight: "40px",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
              >
                <XTypography variant="button" fontWeight="regular" color="text">
                  {batchOptimisation.optimiser_description}
                </XTypography>
              </XBox>
            </Stack>
          </XBox>

          <XBox display="flex" justifyContent="space-between" alignItems="center">
            <XBox display="flex" flexDirection="row" lineHeight={0} overflow="visible">
              {viewState === "creator" && (
                <>
                  <XBox
                    src={controller.darkMode ? LightDotsVerticalIcon : DarkDotsVerticalIcon}
                    component="img"
                    onClick={menuProps.openOnClick ? menuProps.openMenu : undefined}
                  />
                  {renderMenu()}
                </>
              )}
            </XBox>
            {renderModelDetails()}
          </XBox>
        </XBox>
      </Card>
    </XBox>
  );
};

BatchOptimisationCard.propTypes = {
  index: PropTypes.number,
  batchOptimisation: PropTypes.object,
  batchOptimisations: PropTypes.array,
  setBatchOptimisations: PropTypes.func,
};
