import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";

import { useAuth0 } from "@auth0/auth0-react";
import { useUserNotificationQuery } from "api/query";
import { useUserMutation } from "api/mutations";

function Notifications() {
  const { logout } = useAuth0();

  const { data } = useUserNotificationQuery(logout);
  const { patchUserNotificationMutation } = useUserMutation();

  const initialPreferences = {
    email_requests: false,
    push_requests: false,
    email_invitations: false,
    push_invitations: false,
    email_comment_tags: false,
    push_comment_tags: false,
    email_comments: false,
    push_comments: false,
    email_new_models: false,
    push_new_models: false,
    email_new_versions: false,
    push_new_versions: false,
  };

  const [preferences, setPreferences] = useState(initialPreferences);

  useEffect(() => {
    if (!data) return;

    setPreferences(data.data);
  }, [data]);

  const handleToggle = async (preference) => {
    const updatedValue = !preferences[preference];
    setPreferences((prev) => ({ ...prev, [preference]: updatedValue }));

    patchUserNotificationMutation(
      [
        {
          [preference]: updatedValue,
        },
      ],
      {
        onError: () => {
          console.error(`Error updating ${preference} preference:`, error);
        },
      }
    );
  };

  return (
    <Card id="notifications">
      <XBox pb={3} px={3}>
        <XBox minWidth="auto">
          <Table sx={{ minWidth: "36rem" }}>
            <XBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]} noBorder>
                  <XTypography variant="button" fontSize="18px">
                    Activity
                  </XTypography>
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]} noBorder>
                  Email
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]} noBorder>
                  Push
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]} noBorder>
                  SMS
                </TableCell>
              </TableRow>
            </XBox>
            <TableBody>
              {notificationConfig.map((notif) => (
                <TableRow key={notif.label}>
                  <TableCell padding={[1, 1, 1, 0.5]} noBorder>
                    <XBox lineHeight={1.4}>
                      <XTypography display="block" variant="button" fontWeight="regular">
                        {notif.label}
                      </XTypography>
                      <XTypography variant="caption" color="text" fontWeight="regular">
                        {notif.description}
                      </XTypography>
                    </XBox>
                  </TableCell>
                  {notif.toggles.map((toggle) => (
                    <TableCell
                      align="center"
                      padding={[1, 1, 1, 0.5]}
                      key={toggle.preference || toggle.type}
                      noBorder
                    >
                      <Switch
                        checked={preferences[toggle.preference]}
                        onChange={() => handleToggle(toggle.preference)}
                        disabled={toggle.isDisabled}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </XBox>
      </XBox>
    </Card>
  );
}

export default Notifications;

const notificationConfig = [
  {
    label: "Requests",
    description: "Notify when another user requests to join a team where I'm an admin.",
    toggles: [
      { preference: "email_requests", type: "Email" },
      { preference: "push_requests", type: "Push" },
      { type: "SMS", isDisabled: true },
    ],
  },
  {
    label: "Invitations",
    description: "Notify when another user invites me to join a team within an organisation.",
    toggles: [
      { preference: "email_invitations", type: "Email" },
      { preference: "push_invitations", type: "Push" },
      { type: "SMS", isDisabled: true },
    ],
  },
  {
    label: "Mentions",
    description: "Notify when another user mentions you in a comment.",
    toggles: [
      { preference: "email_comment_tags", type: "Email" },
      { preference: "push_comment_tags", type: "Push" },
      { type: "SMS", isDisabled: true },
    ],
  },
  {
    label: "Comments",
    description: "Notify when another user comments on an item you've contributed to.",
    toggles: [
      { preference: "email_comments", type: "Email" },
      { preference: "push_comments", type: "Push" },
      { type: "SMS", isDisabled: true },
    ],
  },
  {
    label: "Models",
    description: "Notify when a user in your team creates a new model.",
    toggles: [
      { preference: "email_new_models", type: "Email" },
      { preference: "push_new_models", type: "Push" },
      { type: "SMS", isDisabled: true },
    ],
  },
  {
    label: "Versions",
    description: "Notify when a user in your team creates a new version of a model.",
    toggles: [
      { preference: "email_new_versions", type: "Email" },
      { preference: "push_new_versions", type: "Push" },
      { type: "SMS", isDisabled: true },
    ],
  },
];
