import React, { useEffect, useRef, useState } from "react";
import ContentEditable from "react-contenteditable";
import XBox from "components/XBox";
import PropTypes from "prop-types";
import classNames from "classnames";

import { SelectMenu } from "../selectMenu";
import { ClickAwayListener, Grid, Paper } from "@mui/material";
import { EditTextButton } from "../editTextButton";
import { components } from "../../constants";
import { v4 as uuidv4 } from "uuid";
import { REPORT_COMPONENTS_KEYS } from "constants";

import _ from "lodash";

import "./style.css";
import { useXplainableController } from "context";
export const EditableBlock = ({
  id,
  activeBlockId,
  html,
  tagName,
  addBlock,
  deleteBlock,
  onChangeBlocks,
  blocks,
  threshold,
  setThreshold,
  setMetrics,
  metrics,
}) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const contentEditable = useRef();
  const containerRef = useRef();
  const htmlRef = useRef(html);
  const isPopoverOpenRef = useRef(false);

  // const [html, setHtml] = useState("");
  // const [tag, setTag] = useState("p");
  const [htmlBackup, setHtmlBackup] = useState(null);
  const [previousKey, setPreviousKey] = useState("");
  const [selectMenuIsOpen, setSelectMenuIsOpen] = useState(false);
  const [selectMenuPosition, setSelectMenuPosition] = useState({ x: null, y: null });

  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (activeBlockId === id && contentEditable.current) {
      contentEditable.current.focus();
    }
  }, [activeBlockId, id]);

  const onKeyDownHandler = (e) => {
    if (isPopoverOpenRef.current) {
      setIsPopoverOpen(false);
      isPopoverOpenRef.current = false;
    }

    if (e.key === "/") {
      setHtmlBackup(htmlRef.current);
    }

    if (e.key === "Enter" && !e.shiftKey && !selectMenuIsOpen) {
      e.preventDefault();
      addBlock({
        id,
        ref: contentEditable.current,
      });
    }

    if (e.key === "Backspace" && !htmlRef.current) {
      e.preventDefault();
      deleteBlock({
        id,
        ref: containerRef.current,
      });
    }

    setPreviousKey(e.key);
  };

  const onKeyUpHandler = (e) => {
    if (e.key === "/") {
      openSelectMenuHandler();
    }
  };

  const openSelectMenuHandler = () => {
    const rect = contentEditable.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    const absoluteTop = rect.top + scrollTop;
    const absoluteLeft = rect.left + scrollLeft;

    setSelectMenuIsOpen(true);
    setSelectMenuPosition({ x: absoluteLeft, y: absoluteTop });

    document.addEventListener("click", closeSelectMenuHandler);
  };

  const closeSelectMenuHandler = () => {
    setHtmlBackup(null);
    setSelectMenuIsOpen(false);
    setSelectMenuPosition({ x: null, y: null });

    document.removeEventListener("click", closeSelectMenuHandler);
  };

  const tagSelectionHandler = (tag) => {
    const newBlock = {
      id: uuidv4(),
      html: "",
      tag,
      type: "editor",
      isHovered: false,
      isOpenedAdditionalMenu: false,
    };
    const newColumnsBlock = {
      id: uuidv4(),
      html: "",
      tag,
      columns: {
        0: [
          {
            id: uuidv4(),
            html: "",
            tag: "column",
            type: "editor",
            isHovered: false,
            isOpenedAdditionalMenu: false,
          },
        ],
        1: [
          {
            id: uuidv4(),
            html: "",
            tag: "column",
            type: "editor",
            isHovered: false,
            isOpenedAdditionalMenu: false,
          },
        ],
      },
      type: "editor",
      isHovered: false,
      isOpenedAdditionalMenu: false,
    };

    onChangeBlocks((prevBlocks) => {
      const index = prevBlocks.findIndex((b) => b.id === id);
      const updatedBlocks = [...prevBlocks];
      updatedBlocks.splice(index + 1, 0, tag === "columns" ? newColumnsBlock : newBlock);

      return updatedBlocks;
    });
    closeSelectMenuHandler();
  };

  const onMouseUpHandler = () => {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    const text = range?.toString().trim();

    if (selection.rangeCount > 0 && text) {
      const rect = range.getBoundingClientRect();

      setPopoverPosition({ top: rect.top - 50, left: rect.left + rect.width / 2 });
      setIsPopoverOpen(true);
      isPopoverOpenRef.current = true;
    }
  };

  const handleChange = (e) => {
    onChangeBlocks((prevBlocks) =>
      prevBlocks.map((block) => (block.id === id ? { ...block, html: e.target.value } : block))
    );

    htmlRef.current = e.target.value;
  };

  const blockClassName = classNames("block", selectMenuIsOpen ? "blockSelected" : null);

  const handleClickAway = (e) => {
    if (isPopoverOpen) {
      setIsPopoverOpen(false);
      isPopoverOpenRef.current = false;
    }
  };

  return (
    <XBox ref={containerRef} display="flex" width="100%" position="relative">
      {selectMenuIsOpen && (
        <SelectMenu
          position={selectMenuPosition}
          handleSelection={tagSelectionHandler}
          closeMenu={closeSelectMenuHandler}
        />
      )}

      {components[tagName] && tagName === REPORT_COMPONENTS_KEYS.COLUMNS ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <XBox>
              {components[tagName](
                blocks,
                onChangeBlocks,
                id,
                threshold,
                setThreshold,
                setMetrics,
                metrics
              )}
            </XBox>
          </Grid>
        </Grid>
      ) : components[tagName] &&
        (tagName === REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT ||
          tagName === REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX ||
          tagName === REPORT_COMPONENTS_KEYS.METRICS) ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <XBox>{components[tagName](threshold, setThreshold, setMetrics, metrics)}</XBox>
          </Grid>
        </Grid>
      ) : components[tagName] ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <XBox>{components[tagName](blocks, onChangeBlocks, id, null, true)}</XBox>
          </Grid>
        </Grid>
      ) : (
        <ClickAwayListener onClickAway={handleClickAway}>
          <XBox width="100%" display="flex">
            <ContentEditable
              className={blockClassName}
              innerRef={contentEditable}
              html={html}
              tagName={tagName}
              onChange={handleChange}
              onKeyDown={onKeyDownHandler}
              onKeyUp={onKeyUpHandler}
              onMouseUp={onMouseUpHandler}
              placeholder="Type a page title..."
              style={{ color: darkMode ? "#7C7C7C" : "black" }}
            />
            <Paper
              style={{
                padding: "3px",
                background: "#0D0C0C",
                borderRadius: "5px",
                display: isPopoverOpen ? "flex" : "none",
                position: "absolute",
                top: popoverPosition.top,
                left: popoverPosition.left,
                zIndex: "1000",
              }}
            >
              <EditTextButton style="italic" text="i" />
              <EditTextButton style="bold" text="b" />
              <EditTextButton style="underline" text="u" />
            </Paper>
          </XBox>
        </ClickAwayListener>
      )}
    </XBox>
  );
};

EditableBlock.propTypes = {
  onChangeBlocks: PropTypes.func,
  activeBlockId: PropTypes.number,
  id: PropTypes.string,
  html: PropTypes.any,
  tagName: PropTypes.any,
  addBlock: PropTypes.any,
  deleteBlock: PropTypes.any,
  blocks: PropTypes.array,
  threshold: PropTypes.number,
  setThreshold: PropTypes.func,
  setMetrics: PropTypes.func,
  metrics: PropTypes.array,
};
