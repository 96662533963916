import React, { useEffect, useRef, useState } from "react";
import { matchSorter } from "match-sorter";
import { REPORT_COMPONENTS_KEYS } from "constants";
import { useModel } from "hooks";
import { XImg } from "components/XImg";

import { ReactComponent as H1Icon } from "assets/images/icons/text-editor/h1.svg";
import { ReactComponent as H2Icon } from "assets/images/icons/text-editor/h2.svg";
import { ReactComponent as H3Icon } from "assets/images/icons/text-editor/h3.svg";
import { ReactComponent as H4Icon } from "assets/images/icons/text-editor/h4.svg";
import { ReactComponent as BulletListIcon } from "assets/images/icons/text-editor/bullet-list.svg";
import { ReactComponent as ArticleIcon } from "assets/images/icons/text-editor/article.svg";
import { ReactComponent as ColumnsIcon } from "assets/images/icons/text-editor/columns.svg";
import { ReactComponent as DividerIcon } from "assets/images/icons/text-editor/divider.svg";
import { ReactComponent as ParagraphIcon } from "assets/images/icons/text-editor/paragraph.svg";
import { ReactComponent as TableIcon } from "assets/images/icons/text-editor/table.svg";
import { ReactComponent as ScenarioIcon } from "assets/images/icons/text-editor/scenario.svg";
import { ReactComponent as HealthIcon } from "assets/images/icons/text-editor/health.svg";
import { ReactComponent as PipelineIcon } from "assets/images/icons/text-editor/pipeline.svg";
import { ReactComponent as OverviewIcon } from "assets/images/icons/text-editor/overview.svg";
import { ReactComponent as ResidualChartIcon } from "assets/images/icons/text-editor/residual-chart.svg";
import { ReactComponent as RegressionChartIcon } from "assets/images/icons/text-editor/regression-chart.svg";
import { ReactComponent as ThresholdIcon } from "assets/images/icons/text-editor/threshold.svg";
import { ReactComponent as CurveIcon } from "assets/images/icons/text-editor/curve.svg";
import { ReactComponent as MetricsIcon } from "assets/images/icons/text-editor/metrics.svg";
import { ReactComponent as NoteIcon } from "assets/images/icons/text-editor/note.svg";

import PropTypes from "prop-types";
import XBox from "components/XBox";

import XTypography from "components/XTypography";

const MENU_HEIGHT = 205;
const allowedTags = [
  {
    id: "heading1",
    tag: "h1",
    label: "Heading 1",
    modelType: "both",
    icon: <H1Icon />,
    description: "Heading 1 style",
  },
  {
    id: "heading2",
    tag: "h2",
    label: "Heading 2",
    modelType: "both",
    icon: <H2Icon />,
    description: "Heading 2 style",
  },
  {
    id: "heading3",
    tag: "h3",
    label: "Heading 3",
    modelType: "both",
    icon: <H3Icon />,
    description: "Heading 3 style",
  },
  {
    id: "heading4",
    tag: "h4",
    label: "Heading 4",
    modelType: "both",
    icon: <H4Icon />,
    description: "Heading 4 style",
  },
  {
    id: "paragraph",
    tag: "p",
    label: "Paragraph",
    modelType: "both",
    icon: <ParagraphIcon />,
    description: "Paragraph style",
  },
  {
    id: REPORT_COMPONENTS_KEYS.BATCH_PREDICT,
    tag: REPORT_COMPONENTS_KEYS.BATCH_PREDICT,
    label: "Predict",
    modelType: "both",
    icon: <NoteIcon />,
    description: "Predict block",
  },
  {
    id: REPORT_COMPONENTS_KEYS.BULLET_LIST,
    tag: REPORT_COMPONENTS_KEYS.BULLET_LIST,
    label: "Bullet List",
    modelType: "both",
    icon: <BulletListIcon />,
    description: "Bullet list style",
  },
  {
    id: REPORT_COMPONENTS_KEYS.DIVIDER,
    tag: REPORT_COMPONENTS_KEYS.DIVIDER,
    label: "Divider",
    modelType: "both",
    icon: <DividerIcon />,
    description: "Divider style",
  },
  {
    id: REPORT_COMPONENTS_KEYS.BINARY_OVERVIEW,
    tag: REPORT_COMPONENTS_KEYS.BINARY_OVERVIEW,
    label: "Bar Chart",
    modelType: "both",
    icon: <ScenarioIcon />,
    description: "Bar chart",
  },
  {
    id: REPORT_COMPONENTS_KEYS.WATERFALL_PLOT,
    tag: REPORT_COMPONENTS_KEYS.WATERFALL_PLOT,
    label: "Waterfall Plot",
    modelType: "both",
    icon: <ScenarioIcon />,
    description: "Waterfall plot",
  },
  {
    id: REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT,
    tag: REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT,
    label: "Threshold Plot",
    modelType: "binary_classification",
    icon: <ThresholdIcon />,
    description: "Threshold plot",
  },
  {
    id: REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX,
    tag: REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX,
    label: "Confusion Matrix",
    modelType: "binary_classification",
    icon: <MetricsIcon />,
    description: "Confusion Matrix",
  },
  {
    id: REPORT_COMPONENTS_KEYS.METRICS,
    tag: REPORT_COMPONENTS_KEYS.METRICS,
    label: "Metrics",
    modelType: "regression",
    icon: <MetricsIcon />,
    description: "Metrics",
  },
  {
    id: REPORT_COMPONENTS_KEYS.PR_CURVE_ROC_CURVE,
    tag: REPORT_COMPONENTS_KEYS.PR_CURVE_ROC_CURVE,
    label: "Pr curve roc curve",
    modelType: "binary_classification",
    icon: <CurveIcon />,
    description: "Pr curve roc curve",
  },
  {
    id: REPORT_COMPONENTS_KEYS.ARTICLE,
    tag: REPORT_COMPONENTS_KEYS.ARTICLE,
    label: "Article",
    modelType: "both",
    icon: <ArticleIcon />,
    description: "Article",
  },
  {
    id: REPORT_COMPONENTS_KEYS.HEALTH,
    tag: REPORT_COMPONENTS_KEYS.HEALTH,
    label: "Health",
    modelType: "both",
    icon: <HealthIcon />,
    description: "Health",
  },
  {
    id: REPORT_COMPONENTS_KEYS.PIPELINE,
    tag: REPORT_COMPONENTS_KEYS.PIPELINE,
    label: "Pipeline",
    modelType: "both",
    icon: <PipelineIcon />,
    description: "Pipeline",
  },
  {
    id: REPORT_COMPONENTS_KEYS.CURRENT_OVERVIEW,
    tag: REPORT_COMPONENTS_KEYS.CURRENT_OVERVIEW,
    label: "Current Overview",
    modelType: "both",
    icon: <OverviewIcon />,
    description: "Current Overview",
  },
  {
    id: REPORT_COMPONENTS_KEYS.BASE_VALUE,
    tag: REPORT_COMPONENTS_KEYS.BASE_VALUE,
    label: "Base value",
    modelType: "both",
    icon: <ScenarioIcon />,
    description: "Base value",
  },
  {
    id: REPORT_COMPONENTS_KEYS.REGRESSION_CHART,
    tag: REPORT_COMPONENTS_KEYS.REGRESSION_CHART,
    label: "Regression chart",
    modelType: "regression",
    icon: <RegressionChartIcon />,
    description: "Regression chart",
  },
  {
    id: REPORT_COMPONENTS_KEYS.RESIDUAL_CHART,
    tag: REPORT_COMPONENTS_KEYS.RESIDUAL_CHART,
    label: "Residual Chart",
    modelType: "regression",
    icon: <ResidualChartIcon />,
    description: "Residual chart",
  },
  {
    id: REPORT_COMPONENTS_KEYS.THRESHOLD_OPTIMISATION,
    tag: REPORT_COMPONENTS_KEYS.THRESHOLD_OPTIMISATION,
    label: "Threshold Optimisation",
    modelType: "both",
    icon: <ThresholdIcon />,
    description: "Threshold Optimisation",
  },
  {
    id: REPORT_COMPONENTS_KEYS.COLUMNS,
    tag: REPORT_COMPONENTS_KEYS.COLUMNS,
    label: "Columns",
    modelType: "both",
    icon: <ColumnsIcon />,
    description: "Columns",
  },
  {
    id: REPORT_COMPONENTS_KEYS.TABLE,
    tag: REPORT_COMPONENTS_KEYS.TABLE,
    label: "Table",
    modelType: "both",
    icon: <TableIcon />,
    description: "Table",
  },
  {
    id: REPORT_COMPONENTS_KEYS.NOTE,
    tag: REPORT_COMPONENTS_KEYS.NOTE,
    label: "Note",
    modelType: "both",
    icon: <NoteIcon />,
    description: "Note block",
  },
  {
    id: REPORT_COMPONENTS_KEYS.MONITORING,
    tag: REPORT_COMPONENTS_KEYS.MONITORING,
    label: "Monitoring",
    modelType: "both",
    icon: <NoteIcon />,
    description: "Monitoring block",
  },
  {
    id: REPORT_COMPONENTS_KEYS.METRICS,
    tag: REPORT_COMPONENTS_KEYS.METRICS,
    label: "Metrics",
    modelType: "both",
    icon: <NoteIcon />,
    description: "Metrics block",
  },
];

export const SelectMenu = ({ position, closeMenu, handleSelection, isColumns }) => {
  const { model_type } = useModel();

  const filteredTags = allowedTags
    .filter((allowedTag) => {
      if (isColumns) {
        return allowedTag.id !== REPORT_COMPONENTS_KEYS.COLUMNS;
      }

      return allowedTag;
    })
    .filter((allowedTag) => {
      if (allowedTag.modelType === "both") {
        return allowedTag;
      }

      return allowedTag.modelType === model_type;
    });

  const [tagList, setTagList] = useState(filteredTags);
  const [selectedTag, setSelectedTag] = useState(0);
  const [command, setCommand] = useState("");

  const menuRef = useRef(null);

  // If the tag selector menu is display outside the top viewport,
  // we display it below the block
  const isMenuOutsideOfTopViewport = position.y - MENU_HEIGHT < 0;

  const isElementInView = (element, container) => {
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    return (
      elementRect.top >= containerRect.top &&
      elementRect.bottom <= containerRect.bottom &&
      elementRect.left >= containerRect.left &&
      elementRect.right <= containerRect.right
    );
  };

  // Filter tagList based on given command
  useEffect(() => {
    setTagList(matchSorter(filteredTags, command, { keys: ["id"] }));
  }, [command]);

  // Attach listener to allow tag selection via keyboard
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSelection(tagList[selectedTag].tag);
        return;
      }

      if (e.key === "Tab" || e.key === "ArrowDown") {
        e.preventDefault();
        const newSelectedTag = selectedTag === tagList.length - 1 ? 0 : selectedTag + 1;
        setSelectedTag(newSelectedTag);
        if (
          menuRef.current &&
          !isElementInView(menuRef.current.children[newSelectedTag], menuRef.current)
        ) {
          menuRef.current.children[newSelectedTag].scrollIntoView();
        }
        return;
      }

      if (e.key === "ArrowUp") {
        e.preventDefault();
        const newSelectedTag = selectedTag === 0 ? tagList.length - 1 : selectedTag - 1;
        setSelectedTag(newSelectedTag);
        if (
          menuRef.current &&
          !isElementInView(menuRef.current.children[newSelectedTag], menuRef.current)
        ) {
          menuRef.current.children[newSelectedTag].scrollIntoView();
        }
        return;
      }

      if (e.key === "Backspace") {
        if (command) {
          setCommand(command.slice(0, -1));
        } else {
          closeMenu();
        }

        return;
      }

      setCommand(command + e.key);
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [tagList, selectedTag]);

  return (
    <div
      style={{
        top: -200,
        left: 0,
        justifyContent: !isMenuOutsideOfTopViewport ? "flex-end" : "flex-start",
        position: "absolute",
        padding: "5px",
        borderRadius: "5px",
        zIndex: "9999",
        display: "flex",
        flexDirection: "column",
        height: "12rem",
        background: "#fff",
      }}
    >
      <XBox
        sx={{
          borderRadius: "5px",
          height: "12rem",
          overflowY: "scroll",
          boxShadow:
            "0 0 0 1px rgba(15,15,15,.05), 0 3px 6px rgba(15,15,15,.1), 0 9px 24px rgba(15,15,15,.2)",
        }}
        ref={menuRef}
      >
        {tagList.map((tag, key) => {
          return (
            <div
              key={key}
              data-tag={tag.tag}
              style={{
                borderTopLeftRadius: key === 0 ? "7px" : "0",
                borderTopRightRadius: key === 0 ? "7px" : "0",
                borderBottomRightRadius: key === tagList.length - 1 ? "7px" : "0",
                borderBottomLeftRadius: key === tagList.length - 1 ? "7px" : "0",
                borderBottom: key !== tagList.length - 1 ? "1px solid #ededed" : "none",

                background: tagList.indexOf(tag) === selectedTag ? "#f5f6fb" : "#fff",
                padding: "2px 10px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
                "&:hover": {
                  background: "#f5f6fb",
                },
              }}
              role="button"
              tabIndex="0"
              onClick={() => handleSelection(tag.tag)}
            >
              <XImg>{tag?.icon}</XImg>
              <XBox display="flex" flexDirection="column">
                <p>{tag.label}</p>
                <XTypography fontSize="12px" sx={{ color: "#7c7c7c" }}>
                  {tag?.description}
                </XTypography>
              </XBox>
            </div>
          );
        })}
      </XBox>
    </div>
  );
};

SelectMenu.propTypes = {
  closeMenu: PropTypes.any,
  position: PropTypes.any,
  handleSelection: PropTypes.any,
  isColumns: PropTypes.bool,
};
