import React from "react";

import XBox from "components/XBox";
import XButton from "components/XButton";
import XTypography from "components/XTypography";

import { Link } from "react-router-dom";
import { XDivider } from "components/XDivider";
import { Icon, Switch, Tooltip } from "@mui/material";
import { setDarkMode, setDarkSidenav, setMiniSidenav, useXplainableController } from "context";

import DarkLinkedInIcon from "assets/images/dark-linkedin-icon.svg";
import LightLinkedInIcon from "assets/images/light-linkedin-icon.svg";

import DarkInstagramIcon from "assets/images/dark-instagram-icon.svg";
import LightInstagramIcon from "assets/images/light-instagram-icon.svg";

import DarkTwitterIcon from "assets/images/dark-twitter-icon.svg";
import LightTwitterIcon from "assets/images/light-twitter-icon.svg";

import DarkGitHubIcon from "assets/images/dark-github-icon.svg";
import LightGitHubIcon from "assets/images/light-github-icon.svg";

export const ConfigurationBlock = () => {
  const [controller, dispatch] = useXplainableController();

  const { darkSidenav, miniSidenav, darkMode } = controller;

  const handleSidenav = () => setDarkSidenav(dispatch, !darkSidenav);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleDarkMode = () => {
    setDarkSidenav(dispatch, !darkMode);
    setDarkMode(dispatch, !darkMode);
  };
  return (
    <XBox display="flex" flexDirection="column" gap={3}>
      <XDivider />

      <XBox display="flex" justifyContent="space-between" mt={3} lineHeight={1}>
        <XTypography variant="caption" style={{ fontSize: "18px" }} fontWeight="regular">
          Toggle Sidenav Theme
        </XTypography>

        <Switch checked={darkSidenav} onChange={handleSidenav} />
      </XBox>

      <XDivider />

      <XBox display="flex" justifyContent="space-between" lineHeight={1}>
        <XTypography variant="caption" style={{ fontSize: "18px" }} fontWeight="regular">
          Web app dark theme
        </XTypography>

        <Switch checked={darkMode} onChange={handleDarkMode} />
      </XBox>

      <XDivider />

      <XBox display="flex" justifyContent="space-between" lineHeight={1}>
        <XBox display="flex" gap={1} alignItems="center">
          <XTypography variant="caption" style={{ fontSize: "18px" }} fontWeight="regular">
            Sidenav Mini
          </XTypography>
          <XBox>
            <Tooltip
              title="Collapse and expand
            sidenav by hover"
              placement="top"
            >
              <XButton variant="outlined" color="secondary" size="small" circular iconOnly>
                <Icon>priority_high</Icon>
              </XButton>
            </Tooltip>
          </XBox>
        </XBox>

        <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
      </XBox>

      <XDivider />

      <XBox display="flex" justifyContent="center" gap={2}>
        <Link to="">
          <XBox src={darkMode ? LightLinkedInIcon : DarkLinkedInIcon} component="img" />
        </Link>
        <Link to="">
          <XBox src={darkMode ? LightInstagramIcon : DarkInstagramIcon} component="img" />
        </Link>
        <Link to="">
          <XBox src={darkMode ? LightTwitterIcon : DarkTwitterIcon} component="img" />
        </Link>
        <Link to="">
          <XBox src={darkMode ? LightGitHubIcon : DarkGitHubIcon} component="img" />
        </Link>
      </XBox>
    </XBox>
  );
};
