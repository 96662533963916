import React, { useEffect, useRef, useState } from "react";
import { SelectMenu } from "../selectMenu";
import { v4 as uuidv4 } from "uuid";
import { components } from "../../constants";
import { Grid } from "@mui/material";

import ContentEditable from "react-contenteditable";
import PropTypes from "prop-types";
import classNames from "classnames";
import XBox from "components/XBox";
import { REPORT_COMPONENTS_KEYS } from "constants";

export const ColumnsEditableBlock = ({
  html,
  tag,
  setBlocks,
  parentId,
  id,
  columnNumber,
  addBlock,
  deleteBlock,
  activeBlockId,
  threshold,
  blocks,
  setThreshold,
  setMetrics,
  metrics,
}) => {
  const contentEditable = useRef();
  const containerRef = useRef();
  const htmlRef = useRef(html);

  const [selectMenuIsOpen, setSelectMenuIsOpen] = useState(false);
  const [selectMenuPosition, setSelectMenuPosition] = useState({ x: null, y: null });

  useEffect(() => {
    if (activeBlockId === id && contentEditable.current) {
      contentEditable.current.focus();
    }
  }, [activeBlockId, id]);

  const onKeyDownHandler = (e) => {
    // if (isPopoverOpenRef.current) {
    //   setIsPopoverOpen(false);
    //   isPopoverOpenRef.current = false;
    // }

    if (e.key === "Enter" && !e.shiftKey && !selectMenuIsOpen) {
      e.preventDefault();
      addBlock({
        id,
        columnNumber,
        ref: contentEditable.current,
      });
    }

    if (e.key === "Backspace" && !htmlRef.current) {
      e.preventDefault();
      deleteBlock({
        id,
        columnNumber,
        ref: containerRef.current,
      });
    }

    // setPreviousKey(e.key);
  };

  const handleChange = (e) => {
    setBlocks((prevBlocks) => {
      const currentBlock = prevBlocks.find((prevBlock) => prevBlock.id === parentId);
      const updatedColumn = currentBlock.columns[columnNumber].map((block) =>
        block.id === id ? { ...block, html: e.target.value } : block
      );
      currentBlock.columns[columnNumber] = updatedColumn;
      const newBlocks = prevBlocks.map((block) => (block.id === parentId ? currentBlock : block));

      return newBlocks;
    });

    htmlRef.current = e.target.value;
  };

  const tagSelectionHandler = (tag) => {
    const newBlock = {
      id: uuidv4(),
      html: "",
      tag,
      type: "editor",
      isHovered: false,
      isOpenedAdditionalMenu: false,
    };

    setBlocks((prevBlocks) => {
      const currentColumns = prevBlocks.find((block) => block.id === parentId);
      const index = currentColumns.columns[columnNumber].findIndex((b) => b.id === id);

      currentColumns.columns[columnNumber].splice(index + 1, 0, newBlock);

      const newBlocks = prevBlocks.map((block) => (block.id === parentId ? currentColumns : block));

      return newBlocks;
    });

    closeSelectMenuHandler();
  };

  const onKeyUpHandler = (e) => {
    if (e.key === "/") {
      openSelectMenuHandler();
    }
  };

  const openSelectMenuHandler = () => {
    setSelectMenuIsOpen(true);

    document.addEventListener("click", closeSelectMenuHandler);
  };

  const closeSelectMenuHandler = () => {
    setSelectMenuIsOpen(false);

    document.removeEventListener("click", closeSelectMenuHandler);
  };

  const blockClassName = classNames("block", false ? "blockSelected" : null);

  return (
    <XBox width="100%" display="flex" ref={containerRef}>
      {selectMenuIsOpen && (
        <SelectMenu
          position={selectMenuPosition}
          handleSelection={tagSelectionHandler}
          closeMenu={closeSelectMenuHandler}
          isColumns
        />
      )}
      {components[tag] &&
      (tag === REPORT_COMPONENTS_KEYS.THRESHOLD_PLOT ||
        tag === REPORT_COMPONENTS_KEYS.CONFUSION_MATRIX ||
        tag === REPORT_COMPONENTS_KEYS.METRICS) ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <XBox>{components[tag](threshold, setThreshold, setMetrics, metrics)}</XBox>
          </Grid>
        </Grid>
      ) : components[tag] ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <XBox>{components[tag](blocks, setBlocks, id)}</XBox>
          </Grid>
        </Grid>
      ) : (
        <ContentEditable
          innerRef={contentEditable}
          className={blockClassName}
          placeholder="Type a page title..."
          html={html}
          tagName={tag}
          onChange={handleChange}
          onKeyDown={onKeyDownHandler}
          onKeyUp={onKeyUpHandler}
        />
      )}
    </XBox>
  );
};

ColumnsEditableBlock.propTypes = {
  html: PropTypes.any,
  tag: PropTypes.any,
  parentId: PropTypes.any,
  id: PropTypes.any,
  setBlocks: PropTypes.any,
  columnNumber: PropTypes.number,
  addBlock: PropTypes.func,
  activeBlockId: PropTypes.any,
  deleteBlock: PropTypes.func,
  threshold: PropTypes.number,
  setThreshold: PropTypes.func,
  blocks: PropTypes.array,
  setMetrics: PropTypes.func,
  metrics: PropTypes.array,
};
