import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ApiKeyProvider } from "components/Authorisation/ApiKeyContext";

//Auth0 application
import { Auth0Provider } from "@auth0/auth0-react";

// Soft UI Context Provider
import { XplainableControllerProvider } from "context";

// react-perfect-scrollbar component
import PerfectScrollbar from "react-perfect-scrollbar";

// react-perfect-scrollbar styles
import "react-perfect-scrollbar/dist/css/styles.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "api/queryClient";

import { PusherProvider } from "context/Pusher";
import { AppProvider } from "context/useAppContext";
import { Toaster } from "react-hot-toast";

const container = document.getElementById("root");

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: `https://${domain}/api/v2/`,
      scope: "offline_access openid profile email",
    }}
  >
    <QueryClientProvider client={queryClient}>
      <ApiKeyProvider>
        <PusherProvider>
          <BrowserRouter>
            <XplainableControllerProvider>
              <PerfectScrollbar>
                <AppProvider>
                  <>
                    <App />
                    <Toaster
                      position="top-center"
                      toastOptions={{
                        success: {
                          style: {
                            background: "green",
                          },
                        },
                        error: {
                          style: {
                            width: "100%",
                            background: "#EA2C4C",
                            maxWidth: "600px",
                            color: "white",
                            whiteSpace: "normal",
                          },
                        },
                      }}
                    />
                  </>
                </AppProvider>
              </PerfectScrollbar>
            </XplainableControllerProvider>
          </BrowserRouter>
        </PusherProvider>
      </ApiKeyProvider>
    </QueryClientProvider>
  </Auth0Provider>,
  container
);
