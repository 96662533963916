import checkout from "./form";

const {
  formField: {
    userName,
    firstName,
    lastName,
    city,
    country,
    email,
    position,
    bio,
    survey,
  },
} = checkout;

const createInitialValues = (user) => ({
  [userName.name]: user.username || "",
  [firstName.name]: user.given_name || "",
  [lastName.name]: user.family_name || "",
  [email.name]: user.email || "",
  [position.name]: user.position || "",
  [city.name]: user.city || "",
  [country.name]: user.country || "",
  [bio.name]: user.bio || "",
  [survey.name]: "",
});
export default createInitialValues;
