import React from "react";
import PropTypes from "prop-types";

import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Paths } from "settings";

import XBox from "components/XBox";
import XButton from "components/XButton";
import XProgress from "components/XProgress";
import XTypography from "components/XTypography";

import ModelsIcon from "assets/images/icons/limits/modelsIcon.svg";
import DeploymentsIcon from "assets/images/icons/limits/deploymentsIcon.svg";
import PreprocessorsIcon from "assets/images/icons/limits/preprocessorsIcon.svg";
import CollectionsIcon from "assets/images/icons/limits/collectionsIcon.svg";
import ReportsIcon from "assets/images/icons/limits/reportsIcon.svg";
import OpenAiIcon from "assets/images/logos/openaigym.svg";

const Limits = ({ limits }) => {
  const navigate = useNavigate();

  const renderLimit = (title, data, icon, isCurrency = false) => {
    const count = data?.count ?? 0;  
    const limit = data?.limit ?? 0; 
  
    return (
      <XBox py={1}>
        <XBox display="flex" justifyContent="space-between" py={1}>
          <XBox display="flex" justifyContent="space-between" alignItems="center">
            <XBox component="img" src={icon} mr={1} width="18px" height="18px" />
  
            <XTypography fontSize="14px" textTransform="capitalize" fontWeight="light">
              {title}
            </XTypography>
          </XBox>
          <XTypography
            textTransform="capitalize"
            style={{ color: "#7C7C7C" }}
            fontWeight="light"
            fontSize="14px"
          >
            {isCurrency ? "$" : ""}
            {`${Number.isInteger(count) ? count : count.toFixed(2)}/${Number.isInteger(limit) ? limit : limit.toFixed(2)}`}
          </XTypography>
        </XBox>
        <XProgress
          style={{ height: "8px" }}
          value={count === 0 || limit === 0 ? 0 : ((count / limit) * 100).toFixed(2)}
          color="primary"
        />
      </XBox>
    );
  };
  

  return (
    <Card>
      <XBox p={3}>
        <XBox display="flex" width="100%" justifyContent="space-between">
          <XTypography variant="h5" fontSize="18px" textTransform="capitalize">
            Limits
          </XTypography>
          <XButton variant="gradient" color="button" onClick={() => navigate(Paths.subscriptions)}>
            Manage Subscriptions
          </XButton>
        </XBox>
        {renderLimit("Models", limits.models || Infinity, ModelsIcon)}
        {renderLimit("Preprocessors", limits.preprocessors || Infinity, PreprocessorsIcon)}
        {renderLimit("Deployments", limits.deployments || Infinity, DeploymentsIcon)}
        {renderLimit("Collections", limits.collections || Infinity, CollectionsIcon)}
        {renderLimit("Shareable Reports", limits.reports || Infinity, ReportsIcon)}
        {renderLimit("AI Credit", limits.ai_credits || Infinity, OpenAiIcon, true)}
      </XBox>
    </Card>
  );
};

export default Limits;

Limits.propTypes = {
  limits: PropTypes.shape({
    models: PropTypes.shape({
      count: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
    }),
    preprocessors: PropTypes.shape({
      count: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
    }),
    deployments: PropTypes.shape({
      count: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
    }),
    collections: PropTypes.shape({
      count: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
    }),
    reports: PropTypes.shape({
      count: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
    }),
    ai_credits: PropTypes.shape({
      count: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
    }),
  }).isRequired,
  setTabValue: PropTypes.func.isRequired,
};
