import React, { useState, useEffect, useRef } from "react";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Box, Card, Icon, Slider } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";

// xplainable Dashboard components
import XBox from "components/XBox";
import XButton from "components/XButton";
import XTypography from "components/XTypography";
import XInput from "components/XInput";
import breakpoints from "assets/theme/base/breakpoints";

//User Defined components
import DropdownContainer from "./components/DropdownContainer";
import SliderContainer from "./components/SliderContainer";
import WaterfallChart from "./components/WaterfallChart";
import RelativeChart from "./components/RelativeChart";
import { debounce } from "lodash";
import { BrushChart } from "./components/BrushChart";
import { TableChart } from "./components/TableChart";
import { useModel } from "hooks";
import { useToast } from "hooks";
import { Target } from "./components/Targeting";
import { useXplainableController } from "context";

import DarkCollapsedIcon from "assets/images/icons/scenario/dark-collapsed-icon.svg";
import LightCollapsedIcon from "assets/images/icons/scenario/light-collapsed-icon.svg";
import LightFilterIcon from "assets/images/icons/scenario/light-filter-icon.svg";
import DarkFilterIcon from "assets/images/icons/scenario/dark-filter-icon.svg";

const ScenarioAnalysis = ({
  fieldValues,
  setFieldValues,
  score,
  setScore,
  probability,
  setProbability,
  multiplier,
  setMultiplier,
}) => {
  const { model_type, profileData, selectedVersion, selectedPartition, calibrationMap } =
    useModel();
  const { showErrorToast } = useToast();
  const [controller, dispatch] = useXplainableController();
  const { darkMode } = controller;

  const selectionWaterfallRef = useRef([0, 0]);
  const selectionRelativeRef = useRef([0, 0]);

  const [plotType, setPlotType] = useState("waterfall");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sort, setSort] = useState(false);
  const [isSorted, setIsSorted] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [globalToggle, setGlobalToggle] = useState(false);
  const [target, setTarget] = useState(0);
  const [actionType, setActionType] = useState(null);

  const handleGlobalToggle = () => {
    setGlobalToggle(!globalToggle);
  };

  // Add the following function to handle input changes
  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSortChange = () => {
    setIsSorted(!isSorted);
  };

  useEffect(() => {
    let newState = {};

    // Parse the categorical values
    Object.keys(profileData.categorical).forEach((val) => {
      const categoryData = profileData.categorical[val].find((cat) => cat.category);
      if (categoryData.length < 3) {
        return;
      }

      if (categoryData) {
        newState[val] = {
          keyName: val,
          field: categoryData.category,
          value: categoryData.score,
          checked: false,
        };
      }
    });

    // Parse the numeric values
    Object.keys(profileData.numeric).forEach((val) => {
      const numericData = profileData.numeric[val];

      // Skip if empty
      if (numericData.length < 3) {
        return;
      }

      // Find first and last valid entries
      const firstValidEntry = numericData.find((d) => d.lower) || {};
      const lastValidEntry =
        numericData
          .slice()
          .reverse()
          .find((d) => d.upper) || {};

      let lower = firstValidEntry.lower ? firstValidEntry.lower : -Infinity;
      let upper = lastValidEntry.upper ? lastValidEntry.upper : Infinity;

      // Calculate mid-point for field
      const field =
        lower === -Infinity || upper === Infinity
          ? "Variable Range"
          : ((lower + upper) / 2).toFixed(2);

      // Find the closest entry to mid-point
      const closestEntry = numericData.reduce((prev, curr) => {
        let currMid = (Number(curr.lower || -Infinity) + Number(curr.upper || Infinity)) / 2;
        let prevMid = (Number(prev.lower || -Infinity) + Number(prev.upper || Infinity)) / 2;
        return Math.abs(currMid - field) < Math.abs(prevMid - field) ? curr : prev;
      });

      newState[val] = {
        keyName: val,
        field: field,
        value: closestEntry.score,
        checked: false,
      };
    });

    console.log("the new state is", newState);

    setFieldValues(newState);
  }, [profileData, selectedVersion, selectedPartition]);

  // Potentially split this out into slider changer and Dropdown changer
  const handleChange = (feature, enabled, field, value) => {
    setFieldValues((prevState) => {
      let newFields = { ...prevState };

      if (!newFields[feature]) {
        return newFields;
      }

      newFields[feature]["checked"] = enabled;
      newFields[feature]["field"] = field.label ? field.label : field;
      newFields[feature]["value"] = value;

      return newFields;
    });
  };

  useEffect(() => {
    if (!Object.values(fieldValues).length) return;

    const newObject = {};
    for (const key in fieldValues) {
      if (Object.hasOwnProperty.call(fieldValues, key)) {
        const oldObj = fieldValues[key];
        newObject[key] = { ...oldObj, checked: globalToggle };
      }
    }
    setFieldValues(newObject);
  }, [globalToggle]);

  //Debounce the handle change function for efficiency
  const debouncedHandleChange = debounce(handleChange, 100); // Adjust the debounce delay (300ms in this example)

  //Update the tab values of the plots
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const targetValue = (actionType) => {
    let numericTarget;

    if (actionType === "minimise") {
      numericTarget = 0;
    } else if (actionType === "maximise") {
      if (model_type === "binary_classification") {
        numericTarget = 1;
      } else if (model_type === "regression") {
        numericTarget = Number.MAX_SAFE_INTEGER;
      }
    } else if (actionType === "target") {
      numericTarget = Number(target);
      if (isNaN(numericTarget)) {
        // Show error toast if target is not a number
        showErrorToast("Target value must be a number.");
        return; // Exit the function
      }
    }

    // Proceed with the target logic
    let scenarioTarget = new Target(profileData, fieldValues);
    let response = scenarioTarget.run(numericTarget, 200000); // Use numericTarget here

    setFieldValues(response);
  };

  //Update the profile data
  const tabs = { 0: "waterfall", 1: "bar", 2: "table" };

  useEffect(() => {
    setPlotType(tabs[tabValue]);
  }, [tabValue]);

  useEffect(() => {
    const newScore = Object.values(fieldValues).reduce((acc, cur) => {
      return acc + cur.value;
    }, profileData.base_value);

    //Calculate multiplier and probability based on calibration curve
    const newMultiplier = probability / profileData["base_value"];
    setMultiplier(newMultiplier);

    if (model_type === "binary_classification") {
      const newProbability = calibrationMap?.[Math.round(newScore * 100)];
      setProbability(newProbability);
    }

    setScore(newScore);
  }, [fieldValues, profileData, probability]);

  return (
    <XBox>
      <XBox display="flex" gap={2} py={1} mb={2}>
        <XButton
          fullWidth
          sx={{
            backgroundColor: `${darkMode ? "#262525" : "white"} `,
            color: `${darkMode ? "white" : "black"} `,
            display: "flex",
            gap: "8px",
          }}
          onClick={() => {
            targetValue("maximise");
            setActionType("maximise");
          }}
        >
          <Icon outlined>arrow_upward</Icon>
          MAXIMISE&nbsp;
        </XButton>
        <XButton
          fullWidth
          sx={{
            backgroundColor: `${darkMode ? "#262525" : "white"} `,
            color: `${darkMode ? "white" : "black"} `,
            display: "flex",
            gap: "8px",
          }}
          onClick={() => {
            targetValue("minimise");
            setActionType("minimise");
          }}
        >
          <Icon>arrow_downward</Icon>
          MINIMISE&nbsp;
        </XButton>
        <XButton
          fullWidth
          sx={{
            backgroundColor: `${darkMode ? "#262525" : "white"} `,
            color: `${darkMode ? "white" : "black"} `,
            display: "flex",
            gap: "8px",
          }}
          onClick={() => {
            targetValue("target");
            setActionType("target");
          }}
        >
          <Icon>bolt</Icon>
          TARGET&nbsp;
        </XButton>
        <XInput
          placeholder="Enter target value..."
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          sx={{ input: { textAlign: "right" }, width: "100%" }}
        />
      </XBox>
      <XBox width="100%" display="flex" flexDirection="column">
        <Card sx={{ padding: "16px", borderRadius: "16px" }}>
          <XBox display="flex" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={8}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  sx={{
                    backgroundColor: `${darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,

                    transition: "all 500ms ease",
                    color: "#AFAFAF",

                    "& .Mui-selected": {
                      fontWeight: "600",
                      color: `${darkMode ? "white" : "black"} !important`,
                      backgroundColor: `${darkMode ? "#262525" : "white"} !important`,
                    },
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  <Tab label="Waterfall Chart" sx={{ minWidth: 0, minHeight: "36px" }} />
                  <Tab label="Bar Chart" sx={{ minWidth: 0, minHeight: "36px" }} />
                  <Tab label="Table" sx={{ minWidth: 0, minHeight: "36px" }} />
                </Tabs>
              </AppBar>
            </Grid>

            <XBox display="flex" gap={1}>
              <XBox
                component="img"
                src={!isCollapsed || darkMode ? LightCollapsedIcon : DarkCollapsedIcon}
                p="10px"
                bgColor={!isCollapsed ? "xpblue" : darkMode ? "#1D1B1B" : "#F7F7F8"}
                sx={{ borderRadius: "12px", cursor: "pointer", transition: "all 500ms" }}
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}
              />

              <XBox
                component="img"
                src={isSorted || darkMode ? LightFilterIcon : DarkFilterIcon}
                p="10px"
                bgColor={isSorted ? "xpblue" : darkMode ? "#1D1B1B" : "#F7F7F8"}
                sx={{ borderRadius: "12px", cursor: "pointer", transition: "all 500ms" }}
                onClick={handleSortChange}
              />
            </XBox>
          </XBox>
          <XBox display="flex">
            <XBox
              width={isCollapsed ? "100%" : "70%"}
              style={{ transition: "width 0.5s ease" }}
              ml={1}
            >
              <Grid container>
                <Grid item xs={12}>
                  <XBox>
                    {plotType === "waterfall" && (
                      <BrushChart
                        data={Object.values(fieldValues)}
                        selectionRef={selectionWaterfallRef}
                        isSorted={isSorted}
                        height={600}
                        isCollapsed={isCollapsed}
                        plotType={plotType}
                      >
                        {(selection) => (
                          <WaterfallChart
                            isCollapsed={isCollapsed}
                            id={"profile--plot"}
                            featureData={fieldValues}
                            margin={{
                              top: 10,
                              right: 0,
                              bottom: 100,
                              left: 20,
                            }}
                            baseValue={profileData.base_value}
                            sort={sort}
                            selection={selection}
                            isSorted={isSorted}
                          />
                        )}
                      </BrushChart>
                    )}
                    {plotType === "bar" && (
                      <BrushChart
                        data={Object.values(fieldValues)}
                        selectionRef={selectionRelativeRef}
                        isSorted={isSorted}
                        isBarChart
                        plotType={plotType}
                      >
                        {(selection) => (
                          <RelativeChart
                            isCollapsed={isCollapsed}
                            id={"bar--plot"}
                            featureData={fieldValues}
                            margin={{
                              top: 10,
                              right: 0,
                              bottom: 60,
                              left: 20,
                            }}
                            baseValue={profileData.base_value}
                            selection={selection}
                            sort={sort}
                            isSorted={isSorted}
                          />
                        )}
                      </BrushChart>
                    )}
                    {plotType === "table" && (
                      <TableChart featureData={fieldValues} isSorted={isSorted} />
                    )}
                  </XBox>
                </Grid>
              </Grid>
            </XBox>

            <XBox
              width={isCollapsed ? "0%" : "30%"}
              sx={{
                transition: "width 0.5s ease",
                overflowX: "hidden",
                display: isCollapsed ? "none" : "block",
                height: "600px",
              }}
            >
              <XBox my={2}>
                <XTypography variant="overline">Search and Toggle Features</XTypography>
                <XBox display="flex">
                  <XBox mr={2}>
                    <XInput
                      width={"100%"}
                      placeholder="Search the input features..."
                      value={searchInput}
                      onChange={handleSearchInput}
                    />
                  </XBox>
                  <Switch
                    checked={globalToggle}
                    onChange={handleGlobalToggle}
                    name="globalToggle"
                    color="primary"
                  />
                </XBox>
              </XBox>
              <XBox
                sx={{
                  overflowY: "auto",
                  maxHeight: "700px",
                  display: "flex",
                  flexGrow: 1,
                  py: 1,
                  flexDirection: "column",
                  transition: "all 0.3s ease",
                  "&::-webkit-scrollbar": {
                    width: 0,
                  },
                }}
              >
                <XBox px={0.5}>
                  {Object.keys(profileData.categorical)
                    .filter(
                      (feature) =>
                        profileData.categorical[feature] &&
                        profileData.categorical[feature].length > 0
                    )
                    .filter(
                      (feature) =>
                        feature.toLowerCase().includes(searchInput.toLowerCase()) ||
                        profileData.categorical[feature].some(
                          (item) =>
                            item.category &&
                            item.category.toLowerCase().includes(searchInput.toLowerCase())
                        )
                    )
                    .map((feature, idx) => (
                      <DropdownContainer
                        key={idx}
                        feature={feature}
                        featureProfile={profileData.categorical[feature]}
                        handleChange={debouncedHandleChange}
                        globalToggle={globalToggle}
                        currentValue={fieldValues[feature]?.field}
                      />
                    ))}
                </XBox>
                <XBox px={0.5}>
                  {Object.keys(profileData.numeric)
                    .filter(
                      (feature) =>
                        profileData.numeric[feature] && profileData.numeric[feature].length > 2
                    )
                    .filter(
                      (feature) =>
                        feature.toLowerCase().includes(searchInput.toLowerCase()) ||
                        profileData.numeric[feature].some(
                          (item) =>
                            String(item.upper).toLowerCase().includes(searchInput.toLowerCase()) ||
                            String(item.lower).toLowerCase().includes(searchInput.toLowerCase())
                        )
                    )
                    .map((feature, idx) => {
                      return (
                        <SliderContainer
                          key={idx}
                          feature={feature}
                          featureProfile={profileData.numeric[feature]}
                          handleChange={debouncedHandleChange}
                          globalToggle={globalToggle}
                          currentValue={fieldValues[feature]?.field}
                        />
                      );
                    })}
                </XBox>
              </XBox>
            </XBox>
          </XBox>
        </Card>
      </XBox>
    </XBox>
  );
};

ScenarioAnalysis.propTypes = {
  fieldValues: PropTypes.object,
  setFieldValues: PropTypes.func,
  score: PropTypes.number,
  setScore: PropTypes.func,
  probability: PropTypes.number,
  setProbability: PropTypes.func,
  multiplier: PropTypes.number,
  setMultiplier: PropTypes.func,
};

export default ScenarioAnalysis;
