import React from "react";
import { Card, Step, StepLabel, Stepper } from "@mui/material";
import XBox from "components/XBox";
import colors from "assets/theme/base/colors";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { UploadDataset } from "../UploadDataset";
import { STEPS } from "layouts/autotrain/constants";
import { DataTable } from "../DataTable";
import { FooterBlock } from "../FooterBlock";
import { Train } from "../Train";
import { useAutoTrainContent } from "./useAutoTrainContent";
import { ProgressBlock } from "../ProgressBlock";
import PropTypes from 'prop-types';

export const AutoTrainContent = ({ modelSettings }) => {
  const {
    tabValue,
    uploadSuccessful,
    isLoading,
    uploadProgress,
    fileNames,
    isDataLoading,
    fileMetadata,
    activeStep,
    getRootProps,
    getInputProps,
    onDropAccepted,
    setDataSetName,
  } = useAutoTrainContent(modelSettings);

  return (
    <XBox>
      {tabValue === 0 && (
        <>
          {uploadSuccessful && !isLoading && (
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                marginTop: 1,
                marginBottom: 2,
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {STEPS.map((label) => (
                <Step
                  key={label}
                  sx={{
                    "& .Mui-active": {
                      color: `${colors.xppink.main} !important`,
                    },
                    "& .Mui-completed": {
                      fill: `${colors.xppink.main} !important`,
                      stroke: `${colors.xppink.main} !important`,
                      borderColor: `${colors.xppink.main} !important`,
                      color: `${colors.xppink.main} !important`,
                    },
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}

          {!uploadSuccessful && !isLoading && (
            <Card sx={{ padding: "16px", height: "100vh%" }}>
              <UploadDataset
                fileNames={fileNames}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                onDropAccepted={onDropAccepted}
                setDataSetName={setDataSetName}
              />
            </Card>
          )}

          {uploadProgress && fileNames.length > 0 ? (
            <ProgressBlock
              fileNames={fileNames}
              uploadProgress={uploadProgress}
              fileMetadata={fileMetadata}
            />
          ) : uploadSuccessful ? (
            <XBox display="flex" flexDirection="column" gap={3}>
              {isDataLoading && activeStep === 0 ? (
                <XBox
                  sx={{
                    height: "500px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <LoadingSpinner animationType="pulse" size={50} />
                </XBox>
              ) : (
                <>
                  {activeStep === 0 && <DataTable fileMetadata={fileMetadata} />}
                  {activeStep === 1 && <DataTable fileMetadata={fileMetadata} />}
                  {activeStep === 2 && <Train fileMetadata={fileMetadata} />}
                  <FooterBlock />
                </>
              )}
            </XBox>
          ) : (
            <div></div>
          )}
        </>
      )}
    </XBox>
  );
};

AutoTrainContent.propTypes = {
  modelSettings: PropTypes.object.isRequired,
};
