import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const usePredictMutation = () => {
  const { mutate: postPredictMutation } = useMutation((params) =>
    apiHandlers.predict.postPredict(...params)
  );

  return {
    postPredictMutation,
  };
};
