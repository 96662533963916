import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Collapse,
  TablePagination,
} from "@mui/material";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XInput from "components/XInput";

import colors from "assets/theme/base/colors";
import SearchIcon from "assets/images/search-icon.svg";

import ChevronDown from "assets/images/icons/preprocressing-chevron-down.svg";

import CollapsibleInfo from "./components/ExpandableInfo";

const PipelineDetails = ({ stages, step, setStep }) => {
  const [searchInput, setSearchInput] = useState("");
  const [expandedRow, setExpandedRow] = useState(step - 1);
  const [chevronRotation, setChevronRotation] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setExpandedRow(step - 1);
    const newPage = step === 0 ? 0 : Math.floor((step - 1) / rowsPerPage);
    setPage(newPage);
  }, [step, rowsPerPage]);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handleExpandRow = (rowIndex) => {
    const globalRowIndex = page * rowsPerPage + rowIndex;
    const newRow = globalRowIndex === expandedRow ? -1 : globalRowIndex;
    setExpandedRow(newRow);
    setChevronRotation(globalRowIndex === expandedRow ? -180 : -180);
    setStep(newRow === -1 ? 0 : newRow + 1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const filterStages = (stages, searchInput) => {
    const searchRegex = new RegExp(searchInput, "i");
    return stages.filter(
      (stage) => stage.name.match(searchRegex) || stage.feature.match(searchRegex)
    );
  };

  const filteredStages = filterStages(stages, searchInput);

  return (
    <>
      <XBox py={2}>
        <Grid container spacing={3} py={4}>
          <Grid item xs={12}>
            <XBox
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <XTypography variant="h4" fontSize="18px">
                Pipeline Details
              </XTypography>
              <XTypography variant="button" color="secondary" fontWeight="light">
                The preprocessing stages applies to the dataset before training
              </XTypography>
            </XBox>
          </Grid>
          <Grid item xs={12}>
            <XInput
              placeholder="Search Pipeline Steps..."
              startAdornment={<XBox src={SearchIcon} component="img" />}
              value={searchInput}
              onChange={handleSearchInput}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <Table sx={{ width: "100%", tableLayout: "fixed" }}>
              <TableBody>
                {filteredStages
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((stage, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell
                          sx={{
                            alignItems: "flex-end",
                            borderBottom: "none",
                            pr: 0,
                          }}
                        >
                          <XBox display="flex" gap={2}>
                            <XBox
                              onClick={() => handleExpandRow(index)}
                              component="img"
                              src={ChevronDown}
                              sx={{
                                transform: `rotate(${
                                  expandedRow === index ? chevronRotation : 0
                                }deg)`,
                                transition: "transform 500ms",
                              }}
                            />
                            <XBox display="flex" flexDirection="column">
                              <XTypography fontSize="16px">{stage.name}</XTypography>
                              <XTypography
                                fontSize="16px"
                                fontWeight="medium"
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                {stage.feature}
                              </XTypography>
                            </XBox>
                          </XBox>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ borderBottom: "none" }} colSpan={6}>
                          <Collapse in={expandedRow === page * rowsPerPage + index} timeout="auto">
                            <XBox
                              width="100%"
                              p={2}
                              border="1px solid #EAEAEA"
                              sx={{ borderRadius: "16px" }}
                            >
                              <XTypography variant="h6" color="secondary">
                                Parameters
                              </XTypography>
                              <CollapsibleInfo params={stage.params} />
                            </XBox>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              sx={{ width: "100%" }}
              component="div"
              count={filteredStages.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </XBox>
    </>
  );
};

export default PipelineDetails;

PipelineDetails.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      params: PropTypes.object,
      feature: PropTypes.string,
    })
  ).isRequired,
};
