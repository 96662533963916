import React from "react";
import XBox from "components/XBox";
import ComingSoon from "shared/Cards/ComingSoon";

const CollectionSettings = () => {
  return (
    <>
      <XBox display="flex" width="100%">
        <ComingSoon
          title={"Collection Settings"}
          caption={"No settings are available for Collections just yet."}
        />
      </XBox>
    </>
  );
};

export default CollectionSettings;
