// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XInput from "components/XInput";
import XSelect from "components/XSelect";

function FormField({ label, name, as, textColor, ...rest }) {
  return (
    <XBox sx={{ marginBottom: 0, padding: 0 }}>
      <XTypography
        component="label"
        variant="caption"
        textTransform="capitalize"
        color={textColor ? textColor : "dark"}
        sx={{ marginBottom: 0, display: "block" }}
      >
        {label}
      </XTypography>
      <Field
        {...rest}
        name={name}
        as={as === "input" ? XInput : XSelect}
        sx={{ marginTop: 0, marginBottom: 0, display: "block" }}
      />
      <XBox sx={{ marginTop: 0.5 }}>
        <XTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </XTypography>
      </XBox>
    </XBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  as: PropTypes.string,
  textColor: PropTypes.string,
};

export default FormField;
