import { useState } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import { animateGradient } from "assets/theme/base/animations";

// xplainable dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Pages layout components
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import Admin from "./components/admin";

function AdminPanel() {
  const { user } = useApiKey();
  const [controller, dispatch] = useXplainableController();
  const { globalImage, globalColor } = controller;

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={user ? user.given_name + " " + user.family_name : "No name set"}
        description={user ? user.position : "No position set"}
        width={"50%"}
        user={user ? user.image : ""}
      />
      <Admin />
    </DashboardLayout>
  );
}

export default AdminPanel;
