import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useCommentsMutation = () => {
  const { mutate: addCommentMutation } = useMutation((params) =>
    apiHandlers.comments.addComment(...params)
  );

  const { mutate: deleteCommentMutation } = useMutation((params) =>
    apiHandlers.comments.deleteComment(...params)
  );

  const { mutate: editCommentMutation } = useMutation((params) =>
    apiHandlers.comments.editComment(...params)
  );

  const { mutate: addLikeMutation } = useMutation((params) =>
    apiHandlers.comments.addLike(...params)
  );

  const { mutate: removeLikeMutation } = useMutation((params) =>
    apiHandlers.comments.removeLike(...params)
  );

  return {
    addCommentMutation,
    deleteCommentMutation,
    editCommentMutation,
    addLikeMutation,
    removeLikeMutation,
  };
};
