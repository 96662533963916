

// @mui material components
import Divider from "@mui/material/Divider";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

function Separator() {
  return (
    <XBox position="relative" py={0.25}>
      <Divider />
      <XBox
        bgColor="transparent"
        position="absolute"
        top="50%"
        left="50%"
        px={1.5}
        lineHeight={1}
        sx={{ transform: "translate(-50%, -60%)" }}
      >
        <XTypography variant="button" fontWeight="medium" color="secondary">
          or
        </XTypography>
      </XBox>
    </XBox>
  );
}

export default Separator;
