import { QueryCache, QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },

  queryCache: new QueryCache({}),
});
