import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useCommentaryMutation = () => {
  const { mutate: autoGenerateReportMutation } = useMutation((params) =>
    apiHandlers.commentary.autoGenerateReport(...params)
  );

  const { mutate: changeCommentaryMutation } = useMutation((params) =>
    apiHandlers.commentary.changeCommentary(...params)
  );

  const { mutate: handlePublishCommentaryMutation } = useMutation((params) =>
    apiHandlers.commentary.handlePublishCommentary(...params)
  );

  return {
    autoGenerateReportMutation,
    changeCommentaryMutation,
    handlePublishCommentaryMutation,
  };
};
