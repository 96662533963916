import XBox from "components/XBox";
import { useModel } from "hooks";
import React from "react";
import DefaultItem from "shared/Items/DefaultItem";

export const CurrentOverview = () => {
  const { target_name, selectedPartition, trainingMetadata } = useModel();

  return (
    <XBox p={2}>
      <DefaultItem
        color="xpblue"
        icon="adjust" // or "NearMe" for Target
        title="Target"
        description={target_name}
        tooltipText="Target refers to the variable this model is trained to predict"
        // bgColor={rgba(colors.primary.main, 0.05)}
        p={1}
        borderRadius="lg"
      />
      <XBox mt={2}>
        <DefaultItem
          color="xppurple"
          icon="pie_chart" // for Partition
          title="Partition"
          description={selectedPartition?.label}
          tooltipText="A subgroup of the {{partition_on}} column. '__dataset__' refers to the entire training data"
          // bgColor={rgba(colors.xppurple.main, 0.05)}
          p={1}
          borderRadius="lg"
        />
      </XBox>
      <XBox mt={2}>
        <DefaultItem
          color="xppink"
          icon="visibility" // or "Insights" for Observations
          title="Observations"
          description={trainingMetadata?.observations}
          tooltipText="The number of rows of data this model was trained on"
          // bgColor={rgba(colors.xppink.main, 0.05)}
          p={1}
          borderRadius="lg"
        />
      </XBox>
    </XBox>
  );
};
