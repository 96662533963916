import { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useXplainableController } from "context/index.js";
import { useApiKey } from "components/Authorisation/ApiKeyContext/index.js";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import { Grid, Tooltip, Menu, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slide from "@mui/material/Slide";
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XSelect from "components/XSelect/index.js";
import { MinimalistButton } from "layouts/autotrain/styled.js";
import { ReactComponent as DeploymentButtonIcon } from "assets/images/icons/deployment-icon.svg";

// xplainable dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Profile from "./components/profile/index.js";
import Preprocessing from "./components/preprocessing/index.js";
import Predict from "./components/predict/index.js";
import Scenario from "./components/scenario/index.js";
import Performance from "./components/performance/index.js";
import ReuseableHeader from "shared/Headers/ReuseableHeader/index.js";
import ModelConfiguration from "./components/configuration/index.js";

import colors from "assets/theme/base/colors.js";
import XBadge from "components/XBadge/index.js";

import { animateGradient } from "assets/theme/base/animations.js";
import { Comments } from "shared/Comments/index.js";
import { useModel } from "hooks/useModel.js";
import { useApp } from "hooks/useApp.js";

import DraftModelsIcon from "assets/images/icons/draft-models-icon.svg";
import PublishedModelsIcon from "assets/images/icons/published-models-icon.svg";
import CollapsedWidthRightIcon from "assets/images/icons/collapsed-width-icon-right.svg";
import CollapsedWidthLeftIcon from "assets/images/icons/collapsed-width-icon-left.svg";
import DarkDotsVerticalIcon from "assets/images/icons/dark-dots-vertical-icon.svg";
import LightDotsVerticalIcon from "assets/images/icons/light-dots-vertical-icon.svg";
import Members from "shared/Avatars/Members/index.jsx";
import ContributorsCard from "shared/Cards/ContributorsCard";

function Model() {
  const { setActiveTitle } = useApp();
  const {
    model_id,
    openMenu,
    toggle,
    setToggle,
    handleToggleChange,
    handleCloseMenu,
    handleActiveVersion,
    handleDeleteVersion,
    deleteProject,
    activeVersion,
    access,
    viewState,
    modelState,
    selectedVersion,
    setSelectedVersion,
    handleVersionChange,
    partitions,
    selectedPartition,
    handlePartitionChange,
    handleOpenMenu,
    tabsOrientation,
    tabValue,
    setTabValue,
    handleSetTabValue,
    collapsedWidth,
    toggleCollapse,
    userData,
    version,
  } = useModel();
  const { activeSubscription } = useApiKey()
  const navigate = useNavigate();

  const [controller, dispatch] = useXplainableController();
  const { globalImage, globalColor, darkMode } = controller;

  const {
    model_name,
    model_description,
    model_type,
    target_name,
    created,
    archived,
    active,
    user,
    active_version,
    deployed,
    deployment_id,
    active_deployment,
    contributors,
    versions,
    number_of_versions,
  } = modelState;

  useEffect(() => {
    if (!model_name) return;

    setActiveTitle(model_name);
  }, [model_name]);

  useEffect(() => {
    if (!activeSubscription) {
      navigate("/models"); // Redirect to /models if subscription is not active
    }
  }, [activeSubscription, navigate]);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      <MenuItem
        onClick={() => {
          setToggle(!active);
          handleToggleChange();
          handleCloseMenu();
        }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Icon fontSize="medium">{active ? "unpublished" : "published_with_changes"}</Icon>
        <XTypography ml={1} variant="inherit">
          {active ? "Unpublish" : "Publish"}
        </XTypography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleActiveVersion();
        }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Icon fontSize="medium">check</Icon>
        <XTypography ml={1} variant="inherit">
          Set Active Version
        </XTypography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDeleteVersion();
        }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Icon fontSize="medium">delete</Icon>
        <XTypography ml={1} variant="inherit">
          Delete Version
        </XTypography>
      </MenuItem>
      <XBox component="div" bgColor="secondary" opacity={0.3} width="100%" height="1px" my={1} />
      <MenuItem
        onClick={() => {
          deleteProject(model_id);
          navigate("/models");
        }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Icon fontSize="medium" color="error">
          delete
        </Icon>
        <XTypography variant="inherit" color="error">
          <XTypography ml={1} variant="inherit" color="error">
            Delete Model
          </XTypography>
        </XTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title={model_name}
        description={model_description}
        icon={"ni ni-atom"}
        width={"50%"}
        color={colors["xppink"].main}
        type={"model"}
        active={toggle}
        isActiveModel={selectedVersion.value === activeVersion}
        rhsElement={
          access === "creator" && viewState === "creator" ? (
            <XBox display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2}>
              <XBox sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <XBox
                  display="flex"
                  alignItems="center"
                  gap={1}
                  px="12px"
                  py={1}
                  bgColor={
                    active
                      ? controller.darkMode
                        ? "#C3085D"
                        : colors["xppink"].main
                      : controller.darkMode
                      ? "#1D1B1B"
                      : "#F7F7F8"
                  }
                  sx={{ borderRadius: "24px" }}
                >
                  <XBox component="img" src={active ? PublishedModelsIcon : DraftModelsIcon} />
                  <XTypography sx={{ color: active ? "#ffffff" : "#AFAFAF" }} fontSize="12px">
                    {active ? "Published" : "Draft"}
                  </XTypography>
                </XBox>
                <XBox display="flex" flexDirection="column">
                  <XBox display="flex" alignItems="center" gap={1}>
                    <XTypography variant="h5" fontWeight="medium" color="dark" fontSize="24px">
                      {model_name}
                    </XTypography>
                    {selectedVersion.value === activeVersion && (
                      <XBox>
                        <XBadge badgeContent="active" color="success" variant="contained" />
                      </XBox>
                    )}
                  </XBox>
                  <XTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                      color: "#7C7C7C",
                    }}
                  >
                    {model_description}
                  </XTypography>
                </XBox>
              </XBox>
              <XBox display="flex" gap={3} ml="auto">
                <Grid item xs={12} lg={3}>
                  <XBox display="flex" flexDirection="column" mx={1}>
                    <XBox display="flex" justifyContent="space-between">
                      <XTypography variant="overline">Version</XTypography>
                    </XBox>
                    <XBox width={"100%"}>
                      <XSelect
                        value={selectedVersion}
                        onChange={handleVersionChange}
                        fullWidth
                        placeholder="Select Version"
                        options={versions.map((version) => ({
                          value: version.version_id,
                          label: `Version ${version.version_number}`,
                        }))}
                      />
                    </XBox>
                  </XBox>
                </Grid>
                <Grid item xs={12} lg={3}>
                    {partitions.length > 1 ? (
                      <XBox display="flex" flexDirection="column" mx={1}>
                        <XBox display="flex" justifyContent="space-between">
                          <XTypography variant="overline">Partitions</XTypography>
                        </XBox>
                        <XBox width={"100%"}>
                          <XSelect
                            value={selectedPartition}
                            onChange={handlePartitionChange}
                            fullWidth
                            placeholder="Select Partition"
                            options={partitions.map((partition) => ({
                              value: partition.partition_id,
                              label: partition.partition_value,
                            }))}
                          />
                        </XBox>
                      </XBox>
                    ) : (
                      // This is a transparent box that maintains the layout but shows nothing
                      <XBox display="flex" flexDirection="column" mx={1} style={{visibility: 'hidden'}}>
                        <XBox display="flex" justifyContent="space-between">
                          <XTypography variant="overline">Invisible</XTypography>
                        </XBox>
                        <XBox width={"100%"}>
                          <XSelect
                            value=""
                            fullWidth
                            placeholder="Invisible"
                            options={[]}
                          />
                        </XBox>
                      </XBox>
                    )}
                  </Grid>
                <Grid item xs={2} lg={2} alignItems="end" display="flex">
                <XBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="end"
                  >
                    <XBox>
                      <Tooltip title={deployed ? "Show Deployment" : "No active Deployment"}>
                        {deployed ? (
                          <Link
                            to={`/deployments/${deployment_id}`}
                            state={{
                              model_id,
                              model_name,
                              model_description,
                              model_type,
                              target_name,
                              created,
                              archived,
                              active,
                              user,
                              active_version,
                              deployed,
                              deployment_id,
                              active_deployment,
                              contributors,
                              versions,
                              number_of_versions,
                            }}
                          >
                            <XBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                  
                              >
                              <MinimalistButton 
                                sx={{ 
                                  gap: "8px", 
                                  display: "flex", 
                                  bgcolor: darkMode ? colors.dark.main: "white" 
                                }}>
                                <DeploymentButtonIcon style={{ fill: colors.xppink.main }} />
                                <XTypography
                                  sx={{
                                    backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    fontWeight: "bold",
                                    transition: "all 0.3s",
                                    px:2
                                  }}
                                  fontSize="16px"
                                >
                                  Deployment
                                </XTypography>
                            </MinimalistButton>
                            </XBox>
                          </Link>
                        ) : (
                         
                          <XBox
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
              
                          >
                          <MinimalistButton 
                            sx={{ 
                              gap: "8px", 
                              display: "flex", 
                              bgcolor: darkMode ? colors.dark.main : "white" 
                            }}>
                            <DeploymentButtonIcon style={{ fill: colors.xppink.main }} />
                            <XTypography
                              sx={{
                                backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                fontWeight: "bold",
                                transition: "all 0.3s",
                                px:2
                              }}
                              fontSize="16px"
                            >
                              Deployment
                            </XTypography>
                        </MinimalistButton>
                        </XBox>
                        )}
                      </Tooltip>
                    </XBox>
                  </XBox>
                </Grid>
                <Grid item xs={1}>
                  <XBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    bgColor={controller.darkMode ? "#1D1B1B" : "#F7F7F8"}
                    sx={{ borderRadius: "50%" }}
                    mt={1.5}
                    p={1}
                  >
                    <XBox
                      component="img"
                      src={controller.darkMode ? LightDotsVerticalIcon : DarkDotsVerticalIcon}
                      onClick={handleOpenMenu}
                    />
                    {renderMenu()}
                  </XBox>
                </Grid>
              </XBox>
            </XBox>
          ) : (
            <>
              <XBox display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2}>
                <XBox sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                  <XBox
                    display="flex"
                    alignItems="center"
                    gap={1}
                    px="12px"
                    py={1}
                    bgColor={
                      active
                        ? controller.darkMode
                          ? "#C3085D"
                          : colors["xppink"].main
                        : controller.darkMode
                        ? "#1D1B1B"
                        : "#F7F7F8"
                    }
                    sx={{ borderRadius: "24px" }}
                  >
                    <XBox component="img" src={active ? PublishedModelsIcon : DraftModelsIcon} />
                    <XTypography sx={{ color: active ? "#ffffff" : "#AFAFAF" }} fontSize="12px">
                      {active ? "Published" : "Draft"}
                    </XTypography>
                  </XBox>
                  <XBox display="flex" flexDirection="column">
                    <XBox display="flex" alignItems="center" gap={1}>
                      <XTypography variant="h5" fontWeight="medium" color="dark" fontSize="24px">
                        {model_name}
                      </XTypography>
                      {selectedVersion.value === activeVersion && (
                        <XBox>
                          <XBadge badgeContent="active" color="success" variant="contained" />
                        </XBox>
                      )}
                    </XBox>
                    <XTypography
                      variant="button"
                      fontWeight="regular"
                      sx={{
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        msOverflowStyle: "none",
                        scrollbarWidth: "none",
                        color: "#7C7C7C",
                      }}
                    >
                      {model_description}
                    </XTypography>
                  </XBox>
                </XBox>
              </XBox>
            </>
          )
        }
      />
      <XBox py={3} position="relative">
        <Grid container zIndex={1}>
          <Grid item xs={12} zIndex={1} xl={collapsedWidth ? 12 : 8}>
            <XBox
              mr={1}
              sx={{
                width: collapsedWidth ? "100%" : "100",
                transition: "width 500ms",
                zIndex: 1,
              }}
            >
              <XBox p={2}>
                <AppBar position="static">
                  <Tabs
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                    sx={{
                      maxWidth: "700px",

                      "& .Mui-selected": {
                        color: `${colors["xppink"].main} !important`,
                        fontWeight: "600",
                      },
                      "& .MuiTabs-indicator": {
                        backgroundColor: colors["xppink"].main,
                      },
                    }}
                  >
                    <Tab label="Profile" sx={{ minWidth: 0 }} />
                    <Tab label="Scenarios" sx={{ minWidth: 0 }} />
                    <Tab label="Performance" sx={{ minWidth: 0 }} />
                    <Tab label="Preprocessing" sx={{ minWidth: 0 }} />
                    <Tab label="Predict" sx={{ minWidth: 0 }} />
                    {access === "creator" && viewState === "creator" && (
                      <Tab label="Configuration" sx={{ minWidth: 0 }} />
                    )}
                  </Tabs>
                </AppBar>
              </XBox>
              <Tooltip
                title={collapsedWidth ? "Show the comments section" : "Hide the comments section"}
                placement="top"
              >
                <XBox
                  sx={{
                    position: "absolute",
                    top: 40,
                    right: 0,
                    zIndex: 100,
                  }}
                >
                  <XBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgColor={collapsedWidth ? "white" : "xpblue"}
                    variant="contained"
                    p="10px"
                    borderRadius="12px"
                    onClick={toggleCollapse}
                    sx={{
                      transition: "transform 300ms",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <XBox
                      component="img"
                      src={collapsedWidth ? CollapsedWidthLeftIcon : CollapsedWidthRightIcon}
                    />
                  </XBox>
                </XBox>
              </Tooltip>
              <XBox>
                {tabValue === 0 && <Profile />}
                {tabValue === 1 && <Scenario />}
                {tabValue === 2 && <Performance />}
                {tabValue === 3 && <Preprocessing />}
                {tabValue === 4 && <Predict />}
                {tabValue === 5 && <ModelConfiguration />}
              </XBox>
            </XBox>
          </Grid>
          <Grid
            item
            xs={collapsedWidth ? 0 : 12}
            xl={collapsedWidth ? 0 : 3.8}
            mt={{ xs: 3, xl: 13 }}
            ml={1.5}
          >
            <Slide direction="left" in={!collapsedWidth} timeout={500}>
              <XBox
                sx={{
                  width: collapsedWidth ? 0 : "100%",
                  transition: "width 500ms",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  alignContent="flex-start"
                  spacing={2}
                  sx={{ position: "sticky", top: "0" }}
                >
                  <Grid item xs={12}>
                    <ContributorsCard 
                      contributors={contributors} 
                      userData={userData} 
                      isLoading={!modelState || !userData}
                      />
                  </Grid>

                  <Grid item xs={12}>
                    <Comments
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      modelId={model_id}
                      versionId={selectedVersion.value}
                      versionNumber={selectedVersion.label}
                      setSelectedVersion={setSelectedVersion}
                      type={"models"}
                      version={version}
                    />
                  </Grid>
                </Grid>
              </XBox>
            </Slide>
          </Grid>
        </Grid>
      </XBox>
    </DashboardLayout>
  );
}

export default Model;

// Typechecking props for the ProfileInfoCard
Model.propTypes = {
  versions: PropTypes.array,
};
