import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { useModel } from "hooks/useModel.js";

// @mui material components
import Grid from "@mui/material/Grid";
import { useXplainableController } from "context/index.js";
import { Card, Tooltip, Icon } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton/index.js";

import LoadingSpinner from "shared/Animations/LoadingAnimation";
import Commentary from "shared/Commentary";

import ScrollReveal from "scrollreveal";
import DefaultItem from "shared/Items/DefaultItem/";
import RegressionChart from "./components/RegressionChart/";
import ModelProfile from "shared/models/ModelProfile/";
import BarChart from "shared/models/BarChart";

function Profile() {
  const {
    model_id,
    profileData,
    featureData,
    featureDescriptionData,
    model_type,
    target_name,
    selectedPartition,
    trainingMetadata,
    regressionBins,
    selectedVersion,
  } = useModel();

  const [controller, dispatch] = useXplainableController();

  let firstKey;
  if (profileData && (profileData.categorical || profileData.numeric)) {
    firstKey =
      profileData.categorical && Object.keys(profileData.categorical).length > 0
        ? Object.keys(profileData.categorical)[0]
        : Object.keys(profileData.numeric)[0];
  }

  useEffect(() => {
    if (profileData && Object.keys(profileData).length > 0 && firstKey) {
      const sr = ScrollReveal();
      sr.reveal(".scrollreveal", {
        delay: 0,
        distance: "100px",
        duration: 700,
        easing: "cubic-bezier(0.5, 0, 0, 1)",
        origin: "bottom",
        interval: 100,
      });
    }
  }, [profileData, firstKey]);

  const LoadingSkeleton = () => (
    <XBox>
      <Grid container spacing={2} my={{ xs: 4, lg: 0 }}>
        <Grid item xs={12} lg={6}>
          <Card sx={{ height: "100%" }}>
            <XBox pt={2} px={2} lineHeight={1}>
              <Skeleton width={150} height={30} />
            </XBox>
            <XBox px={2}>
              {[1, 2, 3].map((item) => (
                <XBox key={item} mt={item === 1 ? 0 : 2}>
                  <Skeleton height={60} />
                </XBox>
              ))}
            </XBox>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card sx={{ height: "320px" }}>
            <XBox p={2}>
              <Skeleton width={150} height={30} />
              <Skeleton width={100} height={40} sx={{ my: 1 }} />
              <Skeleton height={200} />
            </XBox>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ mt: 3 }}>
            <XBox p={2}>
              <Skeleton width={200} height={30} sx={{ mb: 1 }} />
              <Skeleton width={300} height={20} sx={{ mb: 3 }} />
              <Skeleton height={400} />
            </XBox>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} pb={4} mt={2}>
        <Grid item xs={12}>
          <Card>
            <XBox p={2}>
              <Skeleton height={200} />
            </XBox>
          </Card>
        </Grid>
      </Grid>
    </XBox>
  );

  return (
    <>
      {profileData && Object.keys(profileData).length > 0 && firstKey ? (
        <>
          <XBox>
            <Grid container spacing={2} my={{ xs: 4, lg: 0 }} className="scrollreveal">
              <Grid item xs={12} lg={6}>
                <Card sx={{ height: "100%" }}>
                  <XBox pt={2} px={2} lineHeight={1}>
                    <XTypography fontWeight="medium" fontSize="18px">
                      Model Metadata
                    </XTypography>
                  </XBox>
                  <XBox px={2}>
                    <DefaultItem
                      color="xpblue"
                      title="Target"
                      description={target_name}
                      tooltipText="Target refers to the variable this model is trained to predict"
                      // bgColor={rgba(colors.primary.main, 0.05)}
                      borderRadius="lg"
                      py={1}
                    />
                    <XBox mt={2}>
                      <DefaultItem
                        color="xppurple"
                        title="Partition"
                        description={selectedPartition.label}
                        tooltipText={`A subgroup of the ${selectedPartition.label} column. '__dataset__' refers to the entire training data`}
                        // bgColor={rgba(colors.xppurple.main, 0.05)}
                        borderRadius="lg"
                        py={1}
                      />
                    </XBox>
                    <XBox mt={2}>
                      <DefaultItem
                        color="xppink"
                        title="Observations"
                        description={trainingMetadata.observations}
                        tooltipText="The number of rows of data this model was trained on"
                        // bgColor={rgba(colors.xppink.main, 0.05)}
                        py={1}
                        borderRadius="lg"
                      />
                    </XBox>
                  </XBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card sx={{ height: "320px", position: "relative" }}>
                  <XBox p={2}>
                    <XBox display="flex" justifyContent="space-between">
                      <XTypography fontWeight="medium" fontSize="18px">
                        Base Value
                      </XTypography>
                      <XBox>
                        <Tooltip
                          title="This chart shows the distribution of the target variable. The base value refers to the average of the target."
                          placement="top"
                        >
                          <XButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            circular
                            iconOnly
                          >
                            <Icon>question_mark</Icon>
                          </XButton>
                        </Tooltip>
                      </XBox>
                    </XBox>
                    <XTypography variant="h4" fontWeight="medium" color="xpblue">
                      {model_type === "binary_classification"
                        ? (profileData.base_value * 100).toFixed(2) + "% "
                        : profileData.base_value.toFixed(2)}
                    </XTypography>
                    {model_type === "binary_classification" && (
                      <BarChart probability={profileData.base_value} />
                    )}
                    {model_type === "regression" && (
                      <RegressionChart regressionBins={regressionBins} />
                    )}
                  </XBox>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="scrollreveal">
              <Grid item xs={12}>
                <Card sx={{ mt: 3 }}>
                  <XBox p={2}>
                    <XTypography variant="h5" fontSize="18px" mb={1}>
                      Model Profile
                    </XTypography>
                    <XTypography variant="button" fontWeight="light">
                      How does the model make its decisions?
                    </XTypography>
                    <ModelProfile
                      label={target_name}
                      profileData={profileData}
                      firstRenderData={dataManipulation(profileData, firstKey, "score", model_type)}
                      firstFeature={firstKey}
                      featureData={featureData
                        .filter((x) => x.value != 0)
                        .sort((a, b) => b.value - a.value)}
                      featureDescriptionData={featureDescriptionData}
                      modelType={model_type}
                    />
                  </XBox>
                </Card>
              </Grid>
            </Grid>

            {/* Model Commentary */}
            <Grid container spacing={2} className="scrollreveal" pb={4}>
              <Grid item xs={12}>
                <Commentary id={model_id} version={selectedVersion} />
              </Grid>
            </Grid>
          </XBox>
        </>
      ) : (
        <LoadingSkeleton />
      )}
    </>
  );
}

export default Profile;

export const dataManipulation = (data, selectedGroup, type, docType) => {
  const groupData = data.categorical[selectedGroup] || data.numeric[selectedGroup];
  const new_data = [];
  let value;

  if (!groupData) {
    console.error("Selected group not found in the data");
    return new_data;
  }

  groupData.forEach((obj) => {
    if (type === "score") {
      value = docType === "binary" && obj.score !== null ? obj.score * 100 : obj.score;
    } else if (type === "frequency") {
      value = obj.freq !== null ? obj.freq * 100 : obj.freq;
    } else if (type === "mean") {
      value = docType === "binary" && obj.mean !== null ? obj.mean * 100 : obj.mean;
    }

    // Skip this iteration if lower and upper are both falsy and score is 0
    if (!obj.lower && !obj.upper && obj.score === 0) {
      return;
    }

    if ("category" in obj) {
      new_data.push({
        name: obj.category ? obj.category : "NaN",
        value: value,
      });
    } else {
      let range;
      if (!obj.lower && obj.upper) {
        range = "< " + numberWithCommas(Number(obj.upper).toFixed(2));
      } else if (obj.lower && !obj.upper) {
        range = "> " + numberWithCommas(Number(obj.lower).toFixed(2));
      } else if (obj.lower && obj.upper) {
        let lower = numberWithCommas(Number(obj.lower).toFixed(2));
        let upper = numberWithCommas(Number(obj.upper).toFixed(2));
        range = lower + " - " + upper;
      }

      new_data.push({ name: range, value: value });
    }
  });

  return new_data;
};

//Functon to seperate number with commas
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

Profile.propTypes = {
  modelId: PropTypes.any,
  version: PropTypes.any,
  partition: PropTypes.any,
  profileData: PropTypes.any,
  featureData: PropTypes.any,
  featureDescriptionData: PropTypes.any,
  selectedPartition: PropTypes.any,
};
