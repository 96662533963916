import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useXplainableController } from "context";
import { usePreferencesMutations } from "api/mutations/usePreferencesMutation";

import colors from "assets/theme/base/colors";
import PropTypes from "prop-types";
import XBox from "components/XBox";

import UnActiveTableIcon from "assets/images/icons/un-active-table-icon.svg";
import ActiveTableIcon from "assets/images/icons/active-table-icon.svg";
import ActiveCardIcon from "assets/images/icons/active-card-icon.svg";
import UnActiveCardIcon from "assets/images/icons/un-active-card-icon.svg";

export const XViewToggle = ({
  color,
  isCardViewEnable,
  setIsCardViewEnable,
  updatePreferenceKey,
}) => {
  const [controller] = useXplainableController();
  const [value, setValue] = useState(0);
  const { updatePreferences } = usePreferencesMutations();

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    const newViewState = !isCardViewEnable;
    setIsCardViewEnable(newViewState);

    // Assuming `updatePreferences` is defined in the parent component or imported
    const data = JSON.stringify({
      [updatePreferenceKey]: !newViewState,
    });

    await updatePreferences([data], {
      onSuccess: (data) => {
        console.log("The view state was updated...");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
      onChange={handleChange}
      value={value}
      aria-label="Tabs where selection follows focus"
      selectionFollowsFocus
      sx={{
        background: controller.darkMode ? "#262525" : "white",
        "& .Mui-selected": { background: colors[color].main },
        "& .MuiTabs-flexContainer": {
          gap: 0,
        },
      }}
    >
      <Tab
        icon={<XBox component="img" src={isCardViewEnable ? ActiveCardIcon : UnActiveCardIcon} />}
        sx={{ minWidth: "48px" }}
      />
      <Tab
        icon={<XBox component="img" src={isCardViewEnable ? UnActiveTableIcon : ActiveTableIcon} />}
        sx={{ minWidth: "48px" }}
      />
    </Tabs>
  );
};

XViewToggle.propTypes = {
  color: PropTypes.string,
  isCardViewEnable: PropTypes.bool,
  setIsCardViewEnable: PropTypes.func,
  updatePreferenceKey: PropTypes.string,
};
