import React from "react";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";

import DataTable from "shared/Tables/DataTable";
import PropTypes from "prop-types";
import icon from "assets/images/atlassian-jira-icon.svg";
import TableCellComponent from "layouts/batchOptimisation/AllBatchOptimisations/components/TableCellComponent";
import ChevronLeftIcon from "assets/images/icons/batch/chevron-left-icon.svg";

import { Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { XImg } from "components/XImg";

import { ReactComponent as DownloadBatchIcon } from "assets/images/icons/batch/download-batch-icon.svg";

export const BatchOverview = ({
  setActiveStep,
  runId,
  batches,
  setActiveBatch,
  setDownloadBatchId,
}) => {
  const location = useLocation();

  const handleBackButton = () => {
    setActiveStep(0);
  };

  const batch = batches?.map((batch, index) => {
    return {
      batch_id: batch.batch_id,
      batchTitle: index,
      config: batch.config,
      status: batch.status.trim(),
    };
  });

  const handleRowClick = (e, event) => {
    if (event.target.tagName === "SVG") return;
    const activeBatch = batches.find((batch) => batch.batch_id === e.original.batch_id);

    setActiveBatch(activeBatch);
  };

  const columns = [
    {
      Header: "Batch",
      accessor: "batch",
      id: "batch",
      Cell: (props) => {
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            <XBox component="img" src={icon} alt="Xplainable Logo" width="2rem" mr={0.25} />

            <XTypography fontWeight="regular" fontSize="12px">
              {props.row.original.batchTitle}
            </XTypography>
          </XBox>
        );
      },
    },
    {
      Header: "Config",
      accessor: "config",
      id: "config",
      Cell: (props) => {
        const data = Object.entries(props.row.original.config);
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            {data.map((value, index) => (
              <XTypography key={index} fontWeight="medium" fontSize="12px">
                {value[0]}: {value[1]}
              </XTypography>
            ))}
          </XBox>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      id: "status",
      Cell: (props) => {
        const color = () => {
          if (props.row.original.status === "running") {
            return "#14cdef";
          }

          if (props.row.original.status === "done") {
            return "#2cce88";
          }

          return "#f4375d";
        };
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            <XBox sx={{ backgroundColor: color, borderRadius: "50%" }} width="7px" height="7px" />
            <XTypography color="secondary" fontSize="12px">
              {props.row.original.status}
            </XTypography>
          </XBox>
        );
      },
    },
    // {
    //   Header: "Average Score",
    //   accessor: "average_score",
    //   id: "average_score",
    //   Cell: (props) => {
    //     const color = () => {
    //       const averageScore = +props.row.original.averageScore;
    //       if (averageScore <= 30) {
    //         return "#f4375d";
    //       }

    //       if (averageScore <= 80) {
    //         return "#14cdef";
    //       }

    //       return "#2cce88";
    //     };
    //     return (
    //       <XBox display="flex" alignItems="center" justifyContent="space-between" minWidth="170px">
    //         <XTypography color="secondary" fontSize="12px">
    //           {props.row.original.averageScore}%
    //         </XTypography>
    //         <ProgressBar bgColor={color()} completed={+props.row.original.averageScore} />
    //       </XBox>
    //     );
    //   },
    // },
    {
      Header: "",
      width: "5%",
      id: "function",
      Cell: (props) => <TableCellComponent {...props} handleDelete={() => console.log("delete")} />,
    },
    {
      Header: "",
      width: "5%",
      id: "download",
      Cell: (props) => (
        <XBox
          sx={{ cursor: "pointer" }}
          onClick={(e) => {
            const activeBatch = batches.find(
              (batch) => batch.batch_id === props.row.original.batch_id
            );

            setDownloadBatchId(activeBatch.batch_id);
          }}
        >
          <DownloadBatchIcon />
        </XBox>
      ),
    },
  ];

  return (
    <Card
      height="100%"
      mt={10}
      pb={{ xs: 0, lg: 10 }}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <XBox sx={{ padding: "16px" }}>
        <XButton
          variant="outlined"
          sx={{ padding: 0, alignSelf: "start", background: "transparent", border: "none" }}
          onClick={handleBackButton}
        >
          <XBox display="flex" gap="4px" alignItems="center">
            <XBox src={ChevronLeftIcon} component="img" />
            <XTypography color="xpblue" variant="button">
              Back
            </XTypography>
          </XBox>
        </XButton>
        <XBox display="flex" flexDirection="column" gap={1} mb={3}>
          <XTypography fontSize="18px" fontWeight="bold">
            {location?.state?.run?.run_name}
          </XTypography>

          <XTypography fontSize="14px" color="secondary" fontWeight="light">
            Run ID: {runId}
          </XTypography>
        </XBox>

        <XBox display="flex" flexDirection="column" gap={1}>
          <XTypography fontSize="18px" fontWeight="bold">
            Batch Overview
          </XTypography>

          <XTypography fontSize="14px" color="secondary" fontWeight="light">
            Compare batch metadata at a global level
          </XTypography>
        </XBox>
        <DataTable
          table={{ rows: batch, columns: columns }}
          entriesPerPage={{
            defaultValue: 5,
            entries: [5, 7, 10, 15, 20, 25],
          }}
          handleRowClick={handleRowClick}
          // eventComponent={<ReportModal />}
        />
      </XBox>
    </Card>
  );
};

BatchOverview.propTypes = {
  value: PropTypes.number,
  row: PropTypes.number,
  setActiveStep: PropTypes.func,
  runId: PropTypes.string,
  batches: PropTypes.array,
  setActiveBatch: PropTypes.func,
  setDownloadBatchId: PropTypes.func,
};

export const ProgressBar = ({ bgColor, completed, width = "130px", height = "3px" }) => {
  const containerStyles = {
    height,
    width: "100%",
    maxWidth: width,
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgColor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};

ProgressBar.propTypes = {
  bgColor: PropTypes.number,
  completed: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
};
