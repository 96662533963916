import React, { useState } from 'react'

import { Card } from '@mui/material'

import XBox from 'components/XBox'
import XInput from 'components/XInput'
import XTypography from 'components/XTypography'
import XButton from 'components/XButton'
import { useApiKey } from 'components/Authorisation/ApiKeyContext'

const FeatureRequest = () => {
  const { user } = useApiKey()
  // Input Change Handling
  const [inputs, setInputs] = useState({
    message: "",
  });

  const handleOnChange = (event) => {
    event.persist();
    setInputs((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  // Server State Handling
  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      ...inputs,
      user: user.given_name + " " + user.family_name,
      username: user.username,
      email: user.email,
      page: window.location.href,
    };


    try {
      const response = await fetch('https://formbold.com/s/3Gq10', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response;

      if (response.ok) {
        console.log('Success');
        setInputs("")
      } else {
        console.log('Error');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Card>
      <XBox
        onSubmit={handleOnSubmit}
        width={"100%"}
        lineHeight={0}
        p={3}
        display="flex"
        height={"100%"}
        flexDirection='column'
        justifyContent='center'
        alignItems='center'>
        <XTypography variant="h6" textAlign="center">Looking for something? Submit a feature request.</XTypography>
        <XBox component="i" color="dark" variant="gradient" fontSize="60px" className="ni ni-send" my={3} />
        <XTypography variant="caption" mb={3} textAlign="center">{"Requests will be sent to our development team for assessment. Thank you for your input!"}</XTypography>
        <XInput id="message" sx={{width:"100%"}} onChange={handleOnChange} value={inputs.message} placeholder="Please tell us about your feature request..." multiline rows={10} />
        <XBox display="flex" justifyContent='flex-end' alignItems='center' mt={2}>
          <XButton color="dark" type="submit" onClick={handleOnSubmit}>
            Submit
          </XButton>
        </XBox>
      </XBox>
    </Card>
  )
}

export default FeatureRequest
