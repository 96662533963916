import { useRef, useEffect, useState } from "react";
import { useModel } from "hooks";
import * as d3 from "d3";
import PropTypes from "prop-types";
import XBox from "components/XBox";
import colors from "assets/theme-dark/base/colors";

const BoxPlot = ({ data }) => {
  const { collapsedWidth } = useModel();
  const ref = useRef();
  const svgContainer = useRef(null); // The PARENT of the SVG
  const [width, setWidth] = useState(550);
  const [height, setHeight] = useState(100);
  const margin = { top: 0, right: 50, bottom: 90, left: 20 };

  const strokeWidth = 2;

  // This function calculates width and height of the container
  const getSvgContainerSize = () => {
    const newWidth = svgContainer.current.clientWidth;
    setWidth(newWidth);
  };

  useEffect(() => {
    // detect 'width' and 'height' on render
    getSvgContainerSize();
    // listen for resize changes, and detect dimensions again when they change
    window.addEventListener("resize", getSvgContainerSize);
    // cleanup event listener
    return () => window.removeEventListener("resize", getSvgContainerSize);
  }, [collapsedWidth]);

  useEffect(() => {
    const svg = d3.select(ref.current);

    svg.selectAll("*").remove(); // clear previous render

    svg
      .attr("width", width - margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleLinear()
      .rangeRound([0, width - margin.left - margin.right])
      .domain([data.min, data.max]);

    const center = height / 2;

    // Defining gradient for the bar
    const gradient = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%")
      .attr("spreadMethod", "pad");

    gradient
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", colors.xppink.main)
      .attr("stop-opacity", 1);

    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", colors.xpblue.main)
      .attr("stop-opacity", 1);

    // Adding the bar with gradient
    const box = g
      .append("rect")
      .attr("x", x(data["25%"]))
      .attr("y", center - 20)
      .attr("width", x(data["75%"]) - x(data["25%"]))
      .attr("height", 40)
      .attr("fill", "url(#gradient)")
      .style("stroke", colors.secondary.main)
      .style("stroke-width", strokeWidth);

    box.transition().duration(1000).attr("x1", x(data["25%"])).attr("x2", x(data["75%"]));

    // Median (Q2/50%)
    const median = g
      .append("line") // Median (Q2/50%)
      .attr("x1", x(data.median))
      .attr("x2", x(data.median))
      .attr("y1", center - 20)
      .attr("y2", center + 20)
      .attr("stroke", colors.secondary.main)
      .attr("stroke-width", strokeWidth);

    median
      .transition()
      .duration(1000)
      .attr("x", x(data.median) - 5);

    // Line (Min to Q1)
    const minLine = g
      .append("line")
      .attr("x1", x(data.min))
      .attr("x2", x(data["25%"]))
      .attr("y1", center)
      .attr("y2", center)
      .attr("stroke", colors.secondary.main)
      .attr("stroke-width", strokeWidth);

    minLine.transition().duration(1000).attr("x1", x(data.min)).attr("x2", x(data["25%"]));

    // Line (Q3 to Max)
    const maxLine = g
      .append("line")
      .attr("x1", x(data["75%"]))
      .attr("x2", x(data.max))
      .attr("y1", center)
      .attr("y2", center)
      .attr("stroke", colors.secondary.main)
      .attr("stroke-width", strokeWidth);

    maxLine.transition().duration(1000).attr("x1", x(data["75%"])).attr("x2", x(data.max));

    // Circle at the end of Min Line
    const minCircle = g
      .append("circle")
      .attr("cx", x(data.min))
      .attr("cy", center)
      .attr("r", 5)
      .attr("fill", "white")
      .attr("stroke", colors.secondary.main)
      .attr("stroke-width", strokeWidth);

    minCircle.transition().duration(1000).attr("cx", x(data.min));

    // Circle at the end of Max Line
    const maxCircle = g
      .append("circle")
      .attr("cx", x(data.max))
      .attr("cy", center)
      .attr("r", 5)
      .attr("fill", "white")
      .attr("stroke", colors.secondary.main)
      .attr("stroke-width", strokeWidth);

    maxCircle.transition().duration(1000).attr("cx", x(data.max));

    // Add the x Axis
    const xAxis = g
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    xAxis.transition().duration(1000).call(d3.axisBottom(x));
  }, [data, width, height]);

  return (
    <XBox ref={svgContainer}>
      <svg ref={ref} />
    </XBox>
  );
};

export default BoxPlot;

BoxPlot.propTypes = {
  data: PropTypes.shape({
    "25%": PropTypes.number.isRequired,
    "75%": PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    std: PropTypes.number.isRequired,
    mean: PropTypes.number.isRequired,
    median: PropTypes.number.isRequired,
    kurtosis: PropTypes.number.isRequired,
    skewness: PropTypes.number.isRequired,
    variance: PropTypes.number.isRequired,
    missing_pct: PropTypes.number.isRequired,
  }).isRequired,
};
