import React, { useState } from 'react';
import { useXplainableController } from 'context';

import XBox from 'components/XBox';
import XTypography from 'components/XTypography';

import { CopyBlock, dracula, googlecode } from "react-code-blocks";
import { ReactComponent as CodeIcon } from "assets/images/icons/train/code-icon.svg";
import { XImg } from "components/XImg";
import xplainableLogo from 'assets/images/xplainable-logo.svg';
import PropTypes from 'prop-types';

export const TextMessage = ({ item }) => {
    const [controller] = useXplainableController();
    const { darkMode } = controller;
    const [isCodeOpen, setIsCodeOpen] = useState(false);

    return (
        <XBox key={item.id} sx={{ padding: "24px", display: "flex", flexDirection: "column", gap: "12px" }}>
            <XBox>
                <XBox display="flex" gap="12px" alignItems="center" justifyContent="space-between">
                    <XBox display="flex" gap="12px" alignItems="center">
                        <img src={xplainableLogo} width={32} />
                        <XTypography fontSize="16px" fontWeight="bold">
                            Xplainable
                        </XTypography>
                    </XBox>
                    <XBox
                      onClick={() => {
                        setIsCodeOpen((prevState) => !prevState);
                      }}
                      sx={{
                        display: "flex",
                        padding: "8px 12px",
                        backgroundColor: darkMode ? "#1D1B1B" : "#F7F7F8",
                        borderRadius: "12px",
                        alignItems: "center",
                        transition: ".3s all",
                        cursor: "pointer",
                        boxShadow: isCodeOpen ? "0px 0px 0px 1px black" : "none",
                        "&:hover": {
                          transform: "scale(105%)",
                        },
                      }}
                    >
                      <XImg>
                        <CodeIcon />
                      </XImg>
                    </XBox>
                </XBox>
            </XBox>
            <XBox
                display="flex"
                flexDirection="row"
                sx={{
                    border: "1px solid #EAEAEA",
                    borderRadius: "16px",
                    padding: "24px",
                    marginLeft: "44px",
                    position: "relative",
                    gap: "28px",
                    alignItems: "center", // Center items vertically
                    justifyContent: "space-between" // Justify items between the parent component
                }}
                >
                <XTypography 
                    fontSize="14px" 
                    sx={{ 
                    whiteSpace: 'pre-line',
                    lineHeight: '1.5', // Adjust line height as needed
                    margin: 0, // Remove any default margins
                    padding: 0, // Remove any default padding
                    }}
                >
                    {item.text}
                </XTypography>
                {isCodeOpen && 
                <XBox
                    sx={{
                    overflow: "auto",
                    overflowY: "scroll",
                    borderRadius: "5px",
                    border: "1px solid grey",
                    fontSize: "14px",
                    minHeight: "40px",
                    }}
                      width={"40%"}
                    >
                    <CopyBlock
                        text={item?.code && item.code.length > 0 ? item.code[item.code.length - 1]?.query : ""}
                        language="python"
                        theme={darkMode ? dracula : googlecode}
                        showLineNumbers={true}
                        />
                </XBox>
                }
            </XBox>
        </XBox>
    );
}

TextMessage.propTypes = {
    item: PropTypes.object,
};
