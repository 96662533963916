export const STEPS = ["Data Prep", " Feature Engineer", "Train"];

export const DATA = [
  {
    fileName: "Product.csv",
    data: [
      {
        title: "Name",
        type: "string | car_model",
        description: "Name of the car model",
        uniqueValues: 311,
        samples: ["amc concord dl", "amc ambassador dpl", "plymouth cricket"],
      },
      {
        title: "Cylinders",
        type: "number | cylinder_count",
        description: "Number of cylinders in the engine",
        uniqueValues: 5,
        min: 3,
        max: 8,
        std: "1.000",
        samples: ["4", "5", "6"],
      },
      {
        title: "Horsepower",
        type: "number | horsepower",
        description: "Horsepower of the car",
        uniqueValues: 93,
        min: 46,
        max: 230,
        std: "38.769",
        samples: ["155", "200", "83"],
      },
      {
        title: "Name",
        type: "string | car_model",
        description: "Name of the car model",
        uniqueValues: 311,
        samples: ["amc concord dl", "amc ambassador dpl", "plymouth cricket"],
      },
      {
        title: "Name",
        type: "string | car_model",
        description: "Name of the car model",
        uniqueValues: 311,
        samples: ["amc concord dl", "amc ambassador dpl", "plymouth cricket"],
      },
      {
        title: "Name",
        type: "string | car_model",
        description: "Name of the car model",
        uniqueValues: 311,
        samples: ["amc concord dl", "amc ambassador dpl", "plymouth cricket"],
      },
    ],
  },
  {
    fileName: "Test.json",
    data: [
      {
        title: "Acceleration",
        type: "number | acceleration",
        description: "Acceleration rate of the car",
        uniqueValues: 96,
        min: 8,
        max: 24.8,
        std: "2.803",
        samples: ["17.9", "12.9", "14.3"],
      },
    ],
  },
];

export const GOAL_DATA = [
  {
    id: "test_id_1",
    title: "What is the overall trend of stock prices for the companies in the dataset?",
    link: "line chart of price over time, grouped by symbol",
    description:
      "This visualisation will show the trend of stock prices over time for each company in the dataset, allowing us to identify which companies have experienced growth or decline in their stock prices over the period covered by the dataset.",
  },
  {
    id: "test_id_2",
    title:
      "Which company had the highest average stock price over the period covered by the dataset?",
    link: "bar chart of average price, grouped by symbol",
    description:
      "This visualisation will allow us to compare the average stock prices of each company in the dataset, and identify which company had the highest average stock price over the period covered by the dataset.",
  },
  {
    id: "test_id_3",
    title: "What is the distribution of stock prices for each company in the dataset?",
    link: "box plot of price, grouped by symbol",
    description:
      "This visualisation will allow us to compare the distribution of stock prices for each company in the dataset, and identify which companies have a wider or narrower range of stock prices.",
  },
  {
    id: "test_id_4",
    title: "What is the correlation between stock prices of different companies in the dataset?",
    link: "scatter plot of price, with symbols colored by company",
    description:
      "This visualisation will allow us to identify any patterns or relationships between the stock prices of different companies in the dataset, and determine if there is any correlation between the stock prices of different companies.",
  },
  {
    id: "test_id_5",
    title: "What is the overall trend of stock prices over time for each company in the dataset?",
    link: "area chart of price over time, grouped by symbol",
    description:
      "This visualisation will show the trend of stock prices over time for each company in the dataset, allowing us to identify which companies have experienced growth or decline in their stock prices over the period covered by the dataset.",
  },
];

export const EVALUATE_DATA = [
  {
    id: "test_id_1",
    title: "bugs",
    rate: 4.5,
    description:
      "The code is well-written and there are no syntax errors, typos or logic errors. However, the function does not have any error handling mechanism in case the input data is invalid.",
  },
  {
    id: "test_id_2",
    title: "transformation",
    rate: 4.5,
    description:
      "The data is transformed appropriately for the visualisation type. The date fields are converted to date types and rows with NaT values are dropped. The date is also converted to the right time format for plotting. However, there could be additional transformations such as filtering or grouping that could be done to better highlight the trend of stock prices.",
  },
  {
    id: "test_id_3",
    title: "compliance",
    rate: 5,
    description:
      "The code meets the specified visualisation goal of showing the overall trend of stock prices for the companies in the dataset. The title of the chart clearly states the goal and the chart itself shows the trend of stock prices over time.",
  },
  {
    id: "test_id_4",
    title: "type",
    rate: 4.5,
    description:
      "The line chart is an appropriate visualisation type for showing the trend of stock prices over time. However, there could be other visualisation types such as a bar chart or a heatmap that could be more effective in highlighting the trend of stock prices.",
  },
  {
    id: "test_id_5",
    title: "encoding",
    rate: 3,
    description:
      "The data is encoded appropriately for the visualisation type. The x-axis shows the year-month and the y-axis shows the price. The hue encoding is used to show the different symbols and the color palette is chosen appropriately. The median price is also encoded using a horizontal line.",
  },
  {
    id: "test_id_6",
    title: "aesthetics",
    rate: 2,
    description:
      "The aesthetics of the visualisation are appropriate for the visualisation type and the data. The chart is easy to read and understand. The font size and color are appropriate. The legend is clear and the median price is highlighted using a different color and linestyle.",
  },
];

export const DEFAULT_MODEL_SETTING = {
  model: "gpt-4o-mini",
  n: "1",
  provider: "openai",
  max_tokens: 4000,
  temperature: 0,
  presence_penalty: 0,
  frequency_penalty: 0,
  top_p: 0,
  stream: false,
  stop: '',
  n: 1
};

export const MODEL_PROVIDER_OPTIONS = [
  { value: "openai", label: "openai" },
  { value: "palm", label: "palm" },
  { value: "cohere", label: "cohere" },
  { value: "huggingface", label: "huggingface" },
];

export const MODEL_OPTIONS = [
  { value: "gpt-4", label: "gpt-4" },
  { value: "gpt-4-32k", label: "gpt-4-32k" },
  { value: "gpt-3.5-turbo", label: "gpt-3.5-turbo" },
  { value: "gpt-3.5-0301", label: "gpt-3.5-0301" },
  { value: "gpt-3.5-16k", label: "gpt-3.5-16k" },
  { value: "gpt-3.5-0316", label: "gpt-3.5-0316" },
];

export const MAX_TOKEN_OPTIONS = {
  "gpt-4": 8192,
  "gpt-4-32k": 32768,
  "gpt-3.5-turbo": 4096,
  "gpt-3.5-0301": 4096,
  "gpt-3.5-16k": 16384,
  "gpt-3.5-0316": 4096,
};
