import React, { useState, useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthRouteWrapper } from "components/Authorisation/authRouteWrapper";
import { ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import XBox from "components/XBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { setApiKeyConfig } from "api/AxiosInstance";
import { setQueryAccessToken} from "api/httpClient";
import { useLoginQuery } from "api/query/useLoginQuery";
import { Paths } from "settings";
import { useApp } from "hooks";
import { LOCAL_STORAGE_KEYS } from "constants";
import { ReactComponent as ChevronRight } from "assets/images/dark-chevron-left.svg";
import { ReactComponent as ChevronLeft } from "assets/images/dark-chevron-right.svg";
import ProtectedRoute from "components/Authorisation/ProtectedRoute";
import { useXplainableController, setMiniSidenav } from "context";
import brand from "assets/images/xplainable-logo.svg";
import brandDark from "assets/images/xplainable-logo.svg";
import routes from "routes";

// CSS imports
import "assets/css/animations.css";
import "assets/css/nucleo-icons.css";
import "assets/css/nucleo-svg.css";
import "assets/css/tt-lakes-neue.css";
import "assets/css/plus-jakarta.css";
import "assets/css/style.css";

// Extracted Components
const SidenavToggle = React.memo(({ miniSidenav, darkSidenav, handleMiniSidenav }) => {
  return (
    <XBox
      sx={(theme) => ({
        position: "fixed",
        top: "350px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        left: miniSidenav ? "110px" : "260px",
        transform: "translate(20px)",
        boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.08)",
        borderRadius: "50%",
        zIndex: "1300",
        background: darkSidenav ? theme.palette.background.dark : "white",
        transition: "all cubic-bezier(0.4, 0, 0.6, 1) 225ms",
        cursor: "pointer",
      })}
      onClick={handleMiniSidenav}
    >
      {miniSidenav ? (
        <ChevronLeft stroke={darkSidenav ? "white" : "#0D0C0C"} />
      ) : (
        <ChevronRight stroke={darkSidenav ? "white" : "#0D0C0C"} />
      )}
    </XBox>
  );
});

// Custom Hooks
const useLoginHandler = (loginData, error, handlers) => {
  const {
    setApiKey,
    setApiKeyChecked,
    setUser,
    setWorkspaces,
    setActiveWorkspace,
    setPreferences,
    setCheckEmail,
    logout,
  } = handlers;

  useEffect(() => {
    if (!loginData) return;

    let isMounted = true;

    const handleLogin = async () => {
      try {
        if (isMounted) {
          setQueryAccessToken(loginData.token);
          setApiKeyConfig(loginData.token);
          setApiKey(loginData.token);

          const updatedPreferences = {
            miniSidenav: loginData.user.user_preferences.sidenav.mini,
            darkSidenav: loginData.user.user_preferences.sidenav.dark_mode,
            sidenavColor: loginData.user.user_preferences.sidenav.color,
            transparentNavbar: loginData.user.user_preferences.nav.transparent,
            fixedNavbar: loginData.user.user_preferences.nav.fixed,
            darkMode: loginData.user.user_preferences.globe.dark_mode,
            globalColor: loginData.user.user_preferences.globe.color,
            globalImage: loginData.user.user_preferences.globe.image,
            user_id: loginData.user.id,
            active_organisation_id: loginData.user.active_organisation_id,
            active_team_id: loginData.user.active_team_id,
            models_view_table: loginData.user.user_preferences.table_view.models,
            deployments_view_table: loginData.user.user_preferences.table_view.deployments,
            collections_view_table: loginData.user.user_preferences.table_view.collections,
            reports_view_table: loginData.user.user_preferences.table_view.reports,
            batches_view_table: loginData.user.user_preferences.table_view.batches,
          };

          const getPreferenceOrDefault = (key, defaultValue) => {
            const storedPreferences = JSON.parse(localStorage.getItem("preferences") || "{}");
            return storedPreferences[key] !== undefined ? storedPreferences[key] : defaultValue;
          };

          const userId = getPreferenceOrDefault("user_id", loginData.user.id);
          const organisationId = getPreferenceOrDefault(
            "active_organisation_id",
            loginData.user.active_organisation_id
          );
          const teamId = getPreferenceOrDefault("active_team_id", loginData.user.active_team_id);

          setApiKeyChecked(true);
          setUser(loginData.user);
          setWorkspaces(loginData.workspaces);
          setActiveWorkspace({
            user_id: userId,
            organisation_id: organisationId,
            team_id: teamId,
          });
          setPreferences(updatedPreferences);
        }
      } catch (error) {
        console.error("Login handler error:", error);
        if (error.message === "Please verify your email before logging in.") {
          setCheckEmail(true);
        }
        setApiKey(null);
        setApiKeyChecked(true);
      }
    };

    handleLogin();
    return () => {
      isMounted = false;
    };
  }, [loginData]);

  useEffect(() => {
    if (error) {
      console.error("Login error:", error);
      if (error.message === "Please verify your email before logging in.") {
        setCheckEmail(true);
      }
      setApiKey(null);
      setApiKeyChecked(true);
    }
  }, [error]);
};

const useRouteHandler = (isAuthenticated, route) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (route && isAuthenticated) {
      navigate(route);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ROUTE);
    }
  }, [route, isAuthenticated, navigate]);
};

export default function App() {
  const { isAuthenticated, logout } = useAuth0();
  const apiKeyContext = useApiKey();
  const { activeStep } = useApp();
  const [controller, dispatch] = useXplainableController();
  const { miniSidenav, sidenavColor, darkSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const route = localStorage.getItem(LOCAL_STORAGE_KEYS.ROUTE);
  useRouteHandler(isAuthenticated, route);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const { data: loginData, error: loginError } = useLoginQuery();
  useLoginHandler(loginData, loginError, { ...apiKeyContext, logout });

  // Sidenav handlers
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // API key configuration
  useEffect(() => {
    if (apiKeyContext.apiKey !== null) {
      setApiKeyConfig(apiKeyContext.apiKey);
    }
  }, [apiKeyContext.apiKey]);

  // Scroll to top on route change
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Memoized route generation
  const routeElements = useMemo(
    () =>
      routes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
        if (route.route) {
          return (
            <Route
              exact
              path={route.route}
              element={
                route.protected ? (
                  <ProtectedRoute>{route.component}</ProtectedRoute>
                ) : (
                  <AuthRouteWrapper>{route.component}</AuthRouteWrapper>
                )
              }
              key={route.key}
            />
          );
        }
        return null;
      }),
    [routes]
  );

  const currentTheme = useMemo(() => {
    return darkMode ? themeDark : theme;
  }, [darkMode]);

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      {isAuthenticated && pathname !== Paths.newUser && activeStep !== 1 && (
        <>
          <SidenavToggle
            miniSidenav={miniSidenav}
            darkSidenav={darkSidenav}
            handleMiniSidenav={handleMiniSidenav}
          />
          <Sidenav
            color={sidenavColor}
            brand={darkSidenav || darkMode ? brand : brandDark}
            brandName="xplainable"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      <Routes>
        {routeElements}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}

SidenavToggle.propTypes = {
  miniSidenav: PropTypes.bool.isRequired,
  darkSidenav: PropTypes.bool.isRequired,
  handleMiniSidenav: PropTypes.func.isRequired,
};