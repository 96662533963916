import React, { 
  useCallback,
  useMemo } from "react";

import { 
  Grid, 
  Card, 
  Icon
} from "@mui/material";

import PropTypes from "prop-types";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XProgress from "components/XProgress";
import Members from "shared/Avatars/Members";
import DataTable from "shared/Tables/DataTable";
import TableCellComponent from "./components/TableCellComponent";
import { getTimeDifference } from "shared/Functions/Date";

function Teams({
  handleRowClick,
  organisationData,
  isAdmin,
  tableRows,
  setTableRows,
}) {
  const { deleteTeamMutation } = useTeamsMutation();


  const onRemove = useCallback(
    (index) => {
      //Get the team id from the index
      const team_id = tableRows[index].team_id;

      deleteTeamMutation([organisationData, team_id], {
        onSuccess: () => {
          const newState = [...tableRows];
          newState.splice(index, 1);
          setTableRows(newState);
        },
        onError: (err) => {
          console.log(err);
        },
      });
    },
    [tableRows]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        width: "5%",
        id: "function",
        Cell: (props) => {
          if (isAdmin) {
            return (
              <TableCellComponent
                {...props}
                rowIndex={props.row.index}
                onRemove={onRemove}
              />
            );
          }
          return null;
        },
      },
      {
        Header: "Team Name",
        accessor: "name",
        id: "team_name",
        accessor: (data) => ({
          display_icon: data.display_icon,
          name: data.name,
        }),
        Cell: ({ value }) =>
          value ? <TeamCell icon={value.display_icon} name={value.name} /> : null,
      },
      {
        Header: "Team Description",
        accessor: "description",
        Cell: ({ value }) => (value ? value : null),
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: ({ value }) => (value ? getTimeDifference(value, true) : null),
      },
      {
        Header: "Members",
        accessor: "members",
        Cell: ({ value }) => (value ? <Members members={value} /> : null),
      },
    ],
    [name, onRemove]
  );

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Card>
          <DataTable
            handleRowClick={handleRowClick}
            table={{ rows: tableRows, columns: columns }}
            entriesPerPage={{
              defaultValue: 5,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            canSearch
          />
        </Card>
      </Grid>
    </Grid>
  );
}

function Completion({ value }) {
  let color;
  if (value <= 30) {
    color = "error";
  } else if (value > 30 && value <= 70) {
    color = "info";
  } else {
    color = "success";
  }

  return (
    <XBox display="flex" alignItems="center">
      <XTypography variant="caption" color="text">
        {value}%
      </XTypography>
      <XBox width="8rem" ml={1}>
        <XProgress value={value} color={color} label={false} />
      </XBox>
    </XBox>
  );
}

import rgba from "assets/theme/functions/rgba";
import colors from "assets/theme/base/colors";
import { useTeamsMutation } from "api/mutations";
function TeamCell({ icon, name }) {
  return (
    <XBox>
      <XBox display="flex" alignItems="center" pr={2}>
        <XBox
          width="2.2rem"
          mr={1}
          height="2.2rem"
          display="grid"
          justifyContent="center"
          alignItems="center"
          borderRadius="md"
          shadow="md"
          variant="gradient"
          color="xppink"
          bgColor={rgba(colors.xppink.main, 0.1)}
        >
          <Icon fontSize="default" color="light">
            {icon}
          </Icon>
        </XBox>
        <XBox display="flex" flexDirection="column">
          <XTypography variant="button" fontWeight="medium">
            {name}
          </XTypography>
        </XBox>
      </XBox>
    </XBox>
  );
}

Completion.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

Teams.propTypes = {
  data: PropTypes.object,
  handleRowClick: PropTypes.func,
  organisationData: PropTypes.object,
  value: PropTypes.number,
  row: PropTypes.number,
  name: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  setIsAdmin: PropTypes.func,
  tableRows: PropTypes.array,
  setTableRows: PropTypes.func,
};

// Typechecking props for the TeamCell
TeamCell.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const TeamTable = React.memo(Teams);
export { TeamCell };
