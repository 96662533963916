// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Members from "shared/Avatars/Members";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// xplainable dashboard contexts
import { useXplainableController } from "context";

function MiniStatisticsCard({
  bgColor,
  title,
  count,
  percentage,
  direction,
  users,
  textVariant = "h5",
  color = "white",
  numMembers = 5
}) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;


  return (
    <Card sx={{ height: "100%", width: "100%" }}>
      <XBox
        bgColor={bgColor === "white" && darkMode ? "transparent" : bgColor}
        display="flex"
        height="100%"
        width="100%"
        p={2}
      >
        <XBox
          width="100%"
          ml={direction === "left" ? 2 : 0}
          lineHeight={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <XTypography
            variant="button"
            opacity={bgColor === "white" ? 1 : 0.7}
            fontWeight={title.fontWeight}
            fontSize="18px"
          >
            {title.text}
          </XTypography>
          <XBox
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <XBox sx={{ display: "flex" }}>
              <XTypography
                variant={textVariant}
                fontWeight="bold"
                color={color}
                sx={{ fontSize: 24 }}
              >
                {count}{" "}
                <XTypography variant="button" color={percentage.color} fontWeight="bold">
                  {percentage.text}
                </XTypography>
              </XTypography>
            </XBox>
            {users && Array.isArray(users) && users.length > 0 && (
              <XBox sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Members members={users} size="sm" numMembers={numMembers}/>
              </XBox>
            )}
          </XBox>
        </XBox>
      </XBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "xppink",
    ]),
    component: PropTypes.node,
  }),
  direction: PropTypes.oneOf(["right", "left"]),
  users: PropTypes.array,
  textVariant: PropTypes.string,
  color: PropTypes.string,
  numMembers: PropTypes.int
};

export default MiniStatisticsCard;
