// TableCellComponent.js
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import XBox from 'components/XBox';
import XTypography from 'components/XTypography';
import { Icon } from '@mui/material';

import Swal from "sweetalert2";

function TableCellComponent(props) {
    const { 
        handleMenuOpen, 
        handleMenuClose, 
        menuAnchorEl, 
        openMenuIndex, 
        rowIndex,  
        onRemove
    } = props;

    const showAlert = (index, onRemove) => {
        handleMenuClose()

        const newSwal = Swal.mixin({
        customClass: {
            confirmButton: "button button-success",
            cancelButton: "button button-error",
        },
        buttonsStyling: false,
        });

        newSwal
        .fire({
            title: "Are you sure?",
            text: "This will remove the team from the organisation.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, remove the team!",
        })
        .then((result) => {
            if (result.isConfirmed) {
            Swal.fire("Team removed from Organisation", "The team has been removed from the organisation.", "success");
            //TODO: Hit the backend and delete it from the database
            onRemove(index)
            }
        });
      };
    
    return (
   
        <XBox >
            <Icon
            fontSize="default"
            color="secondary"
            sx={{ cursor: "pointer" }}
            onClick={(event) => handleMenuOpen(event, rowIndex)}
            >
            more_vert
            </Icon>
            <Menu
            anchorEl={menuAnchorEl}
            open={openMenuIndex === rowIndex}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            >

                <MenuItem onClick={(event) => { showAlert(rowIndex, onRemove) }}>
                    <Icon>delete</Icon>
                    <XTypography variant="caption" ml={1}>
                        Remove Team
                    </XTypography>
                </MenuItem>
            </Menu>
       </XBox>
    );
}

export default TableCellComponent;

TableCellComponent.propTypes = {
    row: PropTypes.object,
    rowIndex: PropTypes.number,
    handleMenuOpen: PropTypes.func,
    handleMenuClose: PropTypes.func,
    menuAnchorEl: PropTypes.object,
    openMenuIndex: PropTypes.number,
    user_id: PropTypes.number,
    org_admin: PropTypes.bool,
    role_name: PropTypes.string,
    showAlert: PropTypes.func,
    onRemove: PropTypes.func

};





