import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Slider, Switch, Tooltip } from "@mui/material";
import Icon from "@mui/material/Icon";
import XTypography from "components/XTypography";
import XBox from "components/XBox";

const SliderContainer = ({ feature, featureProfile, handleChange, globalToggle, currentValue }) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isNaNToggle, setIsNaNToggle] = useState(false); // State for NaN toggle

  // Calculate min and max considering null values
  let min = featureProfile.reduce((acc, profile) => {
    return profile.lower != null && profile.lower < acc ? profile.lower : acc;
  }, Infinity);
  let max = featureProfile.reduce((acc, profile) => {
    return profile.upper != null && profile.upper > acc ? profile.upper : acc;
  }, -Infinity);

  // Adjusting min and max based on magnitude if they are equal or if min or max is Infinity
  if (min === Infinity || max === -Infinity || min === max) {
    const magnitude =
      min !== Infinity && max !== -Infinity
        ? Math.pow(10, Math.floor(Math.log10(Math.abs(max))))
        : 1;
    min = min === Infinity ? 0 : min - magnitude;
    max = max === -Infinity ? magnitude : max + magnitude;
  }

  const step = (max - min) / 100;
  const initialValue = currentValue != null ? parseFloat(currentValue) : (min + max) / 2;

  const [sliderValue, setSliderValue] = useState(initialValue);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setIsNaNToggle(false); // Reset NaN toggle when slider changes

    const selectedProfile = featureProfile.find(
      (profile) => parseFloat(profile.lower) <= newValue && newValue < parseFloat(profile.upper)
    );
    const score = selectedProfile ? selectedProfile.score : null;

    handleChange(feature, isEnabled, newValue, score);
  };

  // Check if NaN split exists
  const hasNaNSplit = featureProfile.some(
    (profile) => !profile.lower && !profile.upper && profile.score
  );

  const handleNaNToggle = () => {
    const newNaNToggleState = !isNaNToggle;
    setIsNaNToggle(newNaNToggleState);

    if (newNaNToggleState) {
      // Find the profile where both lower and upper are null
      const selectedProfile = featureProfile.find(
        (profile) => profile.lower === null && profile.upper === null
      );
      const score = selectedProfile ? selectedProfile.score : null;
      handleChange(feature, isEnabled, "NaN", score);
    } else {
      // If NaN is toggled off, calculate the score based on the current slider value
      const selectedProfile = featureProfile.find(
        (profile) =>
          parseFloat(profile.lower) <= sliderValue && sliderValue < parseFloat(profile.upper)
      );
      const score = selectedProfile ? selectedProfile.score : null;
      handleChange(feature, isEnabled, sliderValue, score);
    }
  };

  const handleSwitchChange = (event, checked) => {
    setIsEnabled(checked);

    if (isNaNToggle) {
      // Find the profile where both lower and upper are null
      const selectedProfile = featureProfile.find(
        (profile) => profile.lower === null && profile.upper === null
      );
      const score = selectedProfile ? selectedProfile.score : null;
      handleChange(feature, checked, "NaN", score);
    } else {
      // If NaN is toggled off, calculate the score based on the current slider value
      const selectedProfile = featureProfile.find(
        (profile) =>
          parseFloat(profile.lower) <= sliderValue && sliderValue < parseFloat(profile.upper)
      );

      console.log("The selected profile is", selectedProfile);
      console.log("The slider value checked", checked);
      const score = selectedProfile ? selectedProfile.score : null;

      handleChange(feature, checked, sliderValue, score);
    }
  };

  useEffect(() => {
    setIsEnabled(globalToggle);
    // if (globalToggle) {
    //   setIsNaNToggle(false);
    // }
  }, [globalToggle]);

  useEffect(() => {
    if (currentValue != null && currentValue !== "NaN") {
      setSliderValue(parseFloat(currentValue));
      setIsNaNToggle(false);
    } else if (currentValue === "NaN") {
      setIsNaNToggle(true);
    }
  }, [currentValue]);

  return (
    <XBox
      sx={{
        display: "flex",
        flexDirection: "column",
        py: 1,
        px: 1,
        borderRadius: 1,
        borderColor: "#fcba03",
        borderWidth: 10,
        my: 0.5,
        position: "relative",
      }}
    >
      <XBox display="flex" justifyContent="space-between" sx={{ flexWrap: "wrap" }}>
        <XBox display="flex" alignItems="center" mr={1}>
          <XTypography
            color={isEnabled ? "light" : "text"}
            fontSize="14px"
            variant="h6"
            gutterBottom
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {feature}
          </XTypography>
        </XBox>
        <XBox>
          <XBox>
            {hasNaNSplit && (
              <Tooltip
                title={isEnabled ? "Unlock feature to select NaN" : "NaN Split"}
                placement="top"
              >
                <Icon
                  sx={{ position: "absolute", top: 7, right: 60 }}
                  onClick={() => {
                    isEnabled ? null : handleNaNToggle();
                  }}
                  color={isNaNToggle ? "primary" : "secondary"}
                  size="small"
                >
                  rule
                </Icon>
              </Tooltip>
            )}
            <XBox>
              <XTypography variant="body1" fontSize="14px">
                {isNaNToggle
                  ? "NaN"
                  : typeof sliderValue === "number"
                  ? sliderValue.toFixed(2)
                  : "0.00"}
              </XTypography>
            </XBox>
          </XBox>
        </XBox>
      </XBox>
      <XBox display="flex" mb={-1} sx={{ alignItems: "center" }}>
        <XBox width="80%" pr={1}>
          <XBox
            mt={1}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <XTypography
              color={isEnabled || isNaNToggle ? "light" : "text"}
              variant="overline"
              lineHeight={0.9}
            >
              {min.toFixed(2)}
            </XTypography>
            <XTypography
              color={isEnabled || isNaNToggle ? "light" : "text"}
              variant="overline"
              lineHeight={0.9}
            >
              {max.toFixed(2)}
            </XTypography>
          </XBox>
          <Slider
            value={isNaNToggle ? min - 1 : sliderValue}
            onChange={handleSliderChange}
            min={min}
            max={max}
            step={0.1}
            disabled={isEnabled || isNaNToggle}
          />
        </XBox>
        <XBox width="20%" sx={{ display: "flex", alignItems: "center" }}>
          <Switch checked={isEnabled} onChange={handleSwitchChange} color="primary" />
        </XBox>
      </XBox>
    </XBox>
  );
};

SliderContainer.propTypes = {
  feature: PropTypes.string.isRequired,
  globalToggle: PropTypes.bool.isRequired,
  featureProfile: PropTypes.arrayOf(
    PropTypes.shape({
      lower: PropTypes.number,
      upper: PropTypes.number,
      score: PropTypes.number,
      mean: PropTypes.number,
      freq: PropTypes.number,
    })
  ),
  handleChange: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
};

export default React.memo(SliderContainer);

// import React, { useState, useEffect } from 'react'

// import PropTypes from "prop-types";

// //Mui Elements
// import { Card, Switch, Grid,  Tooltip, Icon, Slider} from '@mui/material';

// //Xplainable Styling
// import XBox from 'components/XBox';
// import XTypography from 'components/XTypography';
// import XSelect from 'components/XSelect';
// import XButton from 'components/XButton';

// const boxSX = {
//     zIndex: 0,
//     my: 0.5,
//     borderRadius:"14px",
//     transition: "all 0.3s ease-in-out",
//     "&:hover": {
//         boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
//     },
//   };

// const SliderContainer = ({feature, featureProfile, handleChange}) => {
//     const [enabled, setEnabled] = useState(false)
//     const lower = Object.keys(featureProfile)[0]
//     const upper = Object.keys(featureProfile).at(-1)
//     const lowerBound = Number(featureProfile[lower].upper.toFixed(1))
//     const upperBound = Number(featureProfile[upper].lower.toFixed(1))
//     const [sliderField, setSliderField] = useState([Number(((upperBound + lowerBound) / 2).toFixed(1))])
//     const [value, setValue] = useState(0)

//     //Add a use state to set the slider field
//       //Use effect to calculate the score contribution
//     useEffect(() => {
//         let field = sliderField
//         let loc = Object.values(featureProfile).map(function(x) {return x.upper}).findIndex(function(x) { return x > Number(field) ? x > Number(field): 0})
//         let add = Number(featureProfile[Object.keys(featureProfile).at(loc)].score)
//         //Add logic in here to check whether percentage
//         return setValue(add)
//         }, [sliderField]);

//   return (
//     <Card rounded={3}  sx={boxSX} >
//         <Grid container display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between'}} px={2} py={1} >
//             <Grid item xs={9}>
//                 <XBox display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between'}}>
//                     <XTypography variant="overline" opacity={enabled ? 0.6 : 1}>{feature}</XTypography>
//                     <XTypography sx={{ background:"primary.main", textColor:"white",  alignItems: 'center'}} variant="overline" opacity={enabled ? 0.6 : 1}>{sliderField}</XTypography>
//                 </XBox>
//                 <Grid container display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between'}}>
//                     <Grid item xs={2}>
//                         <XTypography variant="overline" opacity={enabled ? 0.6 : 1}>{lowerBound}</XTypography>
//                     </Grid>
//                     <Grid item xs={8}>
//                         <Slider
//                             color={"primary"}
//                             disabled={enabled}
//                             step={0.1}
//                             min={(lowerBound - 0.01 * lowerBound)}
//                             max={(upperBound + 0.01 * upperBound)}
//                             values={sliderField}
//                             defaultValue={(upperBound + lowerBound) / 2}
//                             aria-label="Default"
//                             valueLabelDisplay="auto"
//                             onChange={(event, newValue) => {
//                                 setSliderField(newValue);
//                                 let loc = Object.values(featureProfile).map(function(x) { return x.upper }).findIndex(function(x) { return x > Number(newValue) ? x > Number(newValue) : 0 });
//                                 let updatedValue = Number(featureProfile[Object.keys(featureProfile).at(loc)].score);
//                                 handleChange(feature, enabled, newValue, updatedValue);
//                             }}
//                         />
//                     </Grid>
//                     <Grid item xs={2}>
//                         <XTypography variant="overline" opacity={enabled ? 0.6 : 1}>{upperBound}</XTypography>
//                     </Grid>
//                 </Grid>
//             </Grid>
//             <Grid item xs={2}>
//                 <XBox ml={2}>
//                 <Switch
//                     id={feature+"--toggle"}
//                     checked={enabled}
//                     onChange={() => {
//                         setEnabled(!enabled);
//                         handleChange(feature, !enabled, sliderField[0], value);
//                     }}
//                     />
//                 </XBox>
//             </Grid>
//             <Grid item xs={1}>
//                 <XBox ml={1}>
//                     <Tooltip title={"This some tooltip text"} placement="top">
//                         <XButton variant="outlined" color="secondary" size="small" circular iconOnly>
//                             <Icon>question_mark</Icon>
//                         </XButton>
//                     </Tooltip>
//                 </XBox>
//             </Grid>
//         </Grid>
//       </Card>

//   )
// }

// const SliderContainer = ({feature, featureProfile, handleChange}) => {
//     const [enabled, setEnabled] = useState(false)
//     const lower = Object.keys(featureProfile)[0]
//     const upper = Object.keys(featureProfile).at(-1)
//     const lowerBound = Number(featureProfile[lower].upper.toFixed(1))
//     const upperBound = Number(featureProfile[upper].lower.toFixed(1))
//     const [sliderField, setSliderField] = useState([Number(((upperBound + lowerBound) / 2).toFixed(1))])
//     const [value, setValue] = useState(0)

//     //Add a use state to set the slider field
//       //Use effect to calculate the score contribution
//     useEffect(() => {
//         let field = sliderField
//         let loc = Object.values(featureProfile).map(function(x) {return x.upper}).findIndex(function(x) { return x > Number(field) ? x > Number(field): 0})
//         let add = Number(featureProfile[Object.keys(featureProfile).at(loc)].score)
//         //Add logic in here to check whether percentage
//         return setValue(add)
//         }, [sliderField]);

//   return (
//     <Card rounded={3}  sx={boxSX} >
//         <Grid container display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between'}} px={2} py={1} >
//             <Grid item xs={9}>
//                 <XBox display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between'}}>
//                     <XTypography variant="overline" opacity={enabled ? 0.6 : 1}>{feature}</XTypography>
//                     <XTypography sx={{ background:"primary.main", textColor:"white",  alignItems: 'center'}} variant="overline" opacity={enabled ? 0.6 : 1}>{sliderField}</XTypography>
//                 </XBox>
//                 <Grid container display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between'}}>
//                     <Grid item xs={2}>
//                         <XTypography variant="overline" opacity={enabled ? 0.6 : 1}>{lowerBound}</XTypography>
//                     </Grid>
//                     <Grid item xs={8}>
//                         <Slider
//                             color={"primary"}
//                             disabled={enabled}
//                             step={0.1}
//                             min={(lowerBound - 0.01 * lowerBound)}
//                             max={(upperBound + 0.01 * upperBound)}
//                             values={sliderField}
//                             defaultValue={(upperBound + lowerBound) / 2}
//                             aria-label="Default"
//                             valueLabelDisplay="auto"
//                             onChange={(event, newValue) => {
//                                 setSliderField(newValue);
//                                 let loc = Object.values(featureProfile).map(function(x) { return x.upper }).findIndex(function(x) { return x > Number(newValue) ? x > Number(newValue) : 0 });
//                                 let updatedValue = Number(featureProfile[Object.keys(featureProfile).at(loc)].score);
//                                 handleChange(feature, enabled, newValue, updatedValue);
//                             }}
//                         />
//                     </Grid>
//                     <Grid item xs={2}>
//                         <XTypography variant="overline" opacity={enabled ? 0.6 : 1}>{upperBound}</XTypography>
//                     </Grid>
//                 </Grid>
//             </Grid>
//             <Grid item xs={2}>
//                 <XBox ml={2}>
//                 <Switch
//                     id={feature+"--toggle"}
//                     checked={enabled}
//                     onChange={() => {
//                         setEnabled(!enabled);
//                         handleChange(feature, !enabled, sliderField[0], value);
//                     }}
//                     />
//                 </XBox>
//             </Grid>
//             <Grid item xs={1}>
//                 <XBox ml={1}>
//                     <Tooltip title={"This some tooltip text"} placement="top">
//                         <XButton variant="outlined" color="secondary" size="small" circular iconOnly>
//                             <Icon>question_mark</Icon>
//                         </XButton>
//                     </Tooltip>
//                 </XBox>
//             </Grid>
//         </Grid>
//       </Card>

//   )
// }
