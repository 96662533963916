import React, { useEffect, useState } from "react";

import XBox from "components/XBox";
import XButton from "components/XButton";
import XTypography from "components/XTypography";

import DataTable from "shared/Tables/DataTable";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import TableCellComponent from "layouts/batchOptimisation/AllBatchOptimisations/components/TableCellComponent";

import PropTypes from "prop-types";

import { Card, Divider, Icon } from "@mui/material";
import { useRunsQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getTimeDifference } from "shared/Functions/Date";
import { useAuth0 } from "@auth0/auth0-react";

import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";

export const RunsList = ({ handleCreateRunButton, versionId, setActiveStep }) => {
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [runs, setRuns] = useState(null);

  const { data, isLoading } = useRunsQuery(activeWorkspace, params.id, versionId, logout);

  useEffect(() => {
    setRuns(data?.data);
  }, [data]);

  const handleDelete = () => {
    console.log("delete");
  };

  const handleRowClick = (params) => {
    navigate(`${location.pathname}?run_id=${params.original.run_id}&version_id=${versionId}`, {
      state: { run_id: params.original.run_id, versionId, run: params.original },
    });
    setActiveStep(5);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "dataset_name",
      id: "dataset_name",
      Cell: (props) => {
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            <XTypography
              fontWeight="regular"
              fontSize="16px"
              sx={{
                color: "#172B4D",
              }}
            >
              {props.row.original.run_name}
            </XTypography>
          </XBox>
        );
      },
    },
    {
      Header: "Description",
      accessor: "dataset_description",
      id: "dataset_description",
      Cell: (props) => {
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            <XTypography
              fontSize="12px"
              fontWeight="medium"
              sx={{
                color: "#172B4D",
              }}
            >
              {props.row.original.run_description}
            </XTypography>
          </XBox>
        );
      },
    },
    {
      Header: "Last updated",
      accessor: "last_updated",
      Cell: (props) => {
        return (
          <XTypography variant="button" fontWeight="light" fontSize="12px" color="secondary">
            {props.row.original.created
              ? getTimeDifference(props.row.original.created, true)
              : null}
          </XTypography>
        );
      },
    },
    {
      Header: "Batches",
      accessor: "batches",
      Cell: (props) => {
        return (
          <XTypography variant="button" fontWeight="light" fontSize="12px" color="secondary">
            {props.row.original.batches ? props.row.original.batches.length : 0}
          </XTypography>
        );
      },
    },
    {
      Header: "",
      width: "5%",
      id: "function",
      Cell: (props) => <TableCellComponent {...props} handleDelete={handleDelete} />,
    },
  ];

  if (isLoading && versionId) {
    return (
      <Card
        sx={{ minHeight: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoadingSpinner size={50} animationType="pulse" />
      </Card>
    );
  }

  console.log(runs);

  if (!runs || runs.length == 0) {
    return (
      <Card pt={3} height={"100%"} mt={10} pb={{ xs: 0, lg: 10 }}>
        <XBox p={4} display="flex" flexDirection="column" width="100%">
          <XTypography fontSize="18px" variant="button">
            Optimiser Runs
          </XTypography>
          <XTypography fontSize="16px" color="secondary" fontWeight="light" variant="button">
            Select a run to config
          </XTypography>
          <XBox
            sx={{
              minHeight: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Divider />
            <XBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <XTypography
                fontSize="14px"
                color="secondary"
                fontWeight="light"
                sx={{ marginBottom: "10px" }}
              >
                No runs. Add one to get started
              </XTypography>
              <XButton
                size="small"
                color="button"
                variant="gradient"
                onClick={handleCreateRunButton}
                sx={{ maxWidth: "130px" }}
              >
                {/* <XBox component="img" src={LightPlusIcon} /> */}

                <XTypography
                  variant="button"
                  color="white"
                  ml={0.1}
                  fontSize="12px"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Create Run
                </XTypography>
              </XButton>
            </XBox>
            <Divider />
          </XBox>
        </XBox>
      </Card>
    );
  }

  return (
    <Card pt={3} height={"100%"} mt={10} pb={{ xs: 0, lg: 10 }}>
      <XBox p={4} maxWidth="650px">
        <XBox display="flex" justifyContent="space-between" alignItems="center">
          <XBox display="flex" flexDirection="column">
            <XTypography fontSize="20px" sx={{ color: "#172B4D" }}>
              Optimiser Runs
            </XTypography>
            <XTypography fontSize="14px" color="secondary" fontWeight="medium">
              Select a run to config
            </XTypography>
          </XBox>
          <XButton
            size="small"
            color="button"
            variant="gradient"
            onClick={handleCreateRunButton}
            sx={{ maxWidth: "120px" }}
          >
            {/* <XBox component="img" src={LightPlusIcon} /> */}

            <XTypography
              variant="button"
              color="white"
              ml={0.1}
              sx={{ whiteSpace: "nowrap" }}
              fontSize="12px"
            >
              Create Run
            </XTypography>
          </XButton>
        </XBox>
        <XBox
          sx={{
            minHeight: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <DataTable
            table={{ rows: runs, columns: columns }}
            entriesPerPage={{
              defaultValue: 5,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            handleRowClick={handleRowClick}
            // eventComponent={<ReportModal />}
          />
        </XBox>
      </XBox>
    </Card>
  );
};

RunsList.propTypes = {
  handleCreateRunButton: PropTypes.func,
  versionId: PropTypes.string,
  value: PropTypes.number,
  row: PropTypes.number,
  setActiveStep: PropTypes.func,
};
