import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useLimitsQuery } from "api/query";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";

// Dashboard layout components
import Slider from "shared/Slider";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import RecentAssets from "./components/RecentAssets";
import Limits from "./components/Limits";
import ReuseableTimeline from "shared/Timeline/ReuseableTimeline";
import LoginCalendar from "./components/LoginCalendar";
import Requests from "./components/Requests";

//Import Styles
import GradientCard from "components/GradientCard";
import './styles.css'; // Import your CSS file
import TrialCard from "./components/TrialCard";

function Dashboard({ setTabValue }) {
  const { viewState, 
    workspaces, 
    activeWorkspace, 
    user, 
    activeSubscription 
  } = useApiKey();
  const { logout } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const { data: limitsData, isLoading } = useLimitsQuery(activeWorkspace, logout);

  useEffect(() => {
    if (!activeWorkspace) {
      return;
    }
    setUserMetadataFromWorkspaces();
  }, [activeWorkspace]);

  const setUserMetadataFromWorkspaces = () => {
    if (!activeWorkspace) return;

    let filteredOrganisations = workspaces.filter(
      (org) => org.organisation_id === activeWorkspace?.organisation_id
    );

    if (filteredOrganisations.length > 0) {
      setUserMetadata({
        is_admin: filteredOrganisations[0]?.is_admin,
        isPersonal: filteredOrganisations[0]?.is_personal,
        start_date: filteredOrganisations[0]?.subscription?.start_date,
      });
    }
  };

  return (
    <XBox py={3}>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} lg={8}>
          <Card>
            <LoginCalendar />
          </Card>
          <Grid container spacing={3} mt={0}>
            <Grid item xs={12} lg={12}>
              {isLoading || !limitsData ? (
                <Card>
                  <XBox height={"416px"}>
                    <LoadingSpinner size={50} />
                  </XBox>
                </Card>
              ) : (
                <Limits limits={limitsData.data} setTabValue={setTabValue} />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0}>
            <Grid item xs={12} lg={6}>
              <RecentAssets />
            </Grid>
            {viewState === "creator" && (
              <Grid item xs={12} lg={6}>
                <XBox mb={3}>
                  <Requests />
                </XBox>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} >
          {!userMetadata?.isPersonal && !user?.trial_available &&
            <XBox mb={3}>
              <GradientCard>
                <TrialCard 
                  trialStarted={!user?.trial_available}
                  activeSubscription={activeSubscription}
                />
              </GradientCard>
            </XBox>
          }
          <XBox mb={3}>
            <Slider />
          </XBox>
          <XBox>
            <ReuseableTimeline
              title={"Event Timeline"}
              team_id={activeWorkspace?.team_id}
              organisation_id={activeWorkspace?.organisation_id}
              event={[
                "set_active_version",
                "new_login",
                "deployed_model",
                "deleted_deployment",
                "created_model",
                "deleted_model",
                "activated_model",
                "deactivated_model",
                "generated_gpt_report",
              ]}
            />
          </XBox>
        </Grid>
      </Grid>
    </XBox>
  );
}

export default Dashboard;

Dashboard.propTypes = {
  setTabValue: PropTypes.func.isRequired,
};
