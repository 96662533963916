import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import XBox from "components/XBox";
import ApiTable from "./ApiTable";

const DeployKeys = ({ deploymentId }) => {
  return (
    <>
      <XBox mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <XBox minHeight={600}>
              <ApiTable deploymentId={deploymentId} />
            </XBox>
          </Grid>
        </Grid>
      </XBox>
    </>
  );
};

export default DeployKeys;

DeployKeys.propTypes = {
  deploymentId: PropTypes.string,
};
