
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// xplainable Dashboard components
import XBox from "components/XBox";

// Timeline context
import { TimelineProvider } from "../context";

function TimelineList({ title, dark, children, containerStyles }) {
  return (
    <TimelineProvider value={dark}>
        <XBox style={containerStyles}>
          <XBox my={2}>
            <XBox>{children}</XBox>
          </XBox>
        </XBox>
    </TimelineProvider>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
  containerStyles: {},
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
  containerStyles: PropTypes.object,
};

export default TimelineList;