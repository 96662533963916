import AvaIcon from "assets/images/ava.png";

export const EXPLAIN_DATA = [
    {
      isPinned: true,
      comment_id: "Wrla0oHasdlk123bEd27JWHo",
      model_id: "IujZXfFF4swdTf8i",
      version_id: "7XRmjJXL6fBjDqxg",
      version_number: 1,
      parent_id: null,
      created: "2024-03-27T11:00:10.481Z",
      last_edited: "2024-03-27T11:00:10.481Z",
      comment: "What is a confusion matrix?",
      component: "Profile",
      like_count: 0,
      user: {
        user_id: "5CW6kQbDWyVI8JEq",
        username: "Vlad Malinin",
        given_name: "Vlad",
        family_name: "Malinin",
        position: "Frontend",
        image:
          "https://xplainablepublic.blob.core.windows.net/asset-repository/profile_images/5CW6kQbDWyVI8JEq.jpg",
      },
      replies: [],
    },
    {
      isPinned: false,
      comment_id: "Wrla0gsdfgoHbEd27JWHo",
      model_id: "IujZXfFF4swdTf8i",
      version_id: "7XRmjJXL6fBjDqxg",
      version_number: 1,
      parent_id: null,
      created: "2024-03-27T11:00:10.481Z",
      last_edited: "2024-03-27T11:00:10.481Z",
      comment:
        "A confusion matrix is a table used in machine learning to evaluate the performance of a classification model. It summarizes the actual and predicted classifications, showing counts of true positives, true negatives, false positives, and false negatives.",
      component: "Profile",
      like_count: 0,
      user: {
        user_id: "",
        given_name: "Chat",
        family_name: "Bot",
        username: "Chatbot",
        image: AvaIcon,
      },
      replies: [],
    },
    {
      isPinned: false,
      comment_id: "Wrla0oasdasdasdHbEd27JWHo",
      model_id: "IujZXfFF4swdTf8i",
      version_id: "7XRmjJXL6fBjDqxg",
      version_number: 1,
      parent_id: null,
      created: "2024-03-27T11:00:10.481Z",
      last_edited: "2024-03-27T11:00:10.481Z",
      comment: "Why is the confusion matrix important?",
      component: "Profile",
      like_count: 0,
      user: {
        user_id: "5CW6kQbDWyVI8JEq",
        username: "Vlad Malinin",
        given_name: "Vlad",
        family_name: "Malinin",
        position: "Frontend",
        image:
          "https://xplainablepublic.blob.core.windows.net/asset-repository/profile_images/5CW6kQbDWyVI8JEq.jpg",
      },
      replies: [],
    },
    {
      isPinned: true,
      comment_id: "Wasd3123a0oHbEd27JWHo",
      model_id: "IujZXfFF4swdTf8i",
      version_id: "7XRmjJXL6fBjDqxg",
      version_number: 1,
      parent_id: null,
      created: "2024-03-27T11:00:10.481Z",
      last_edited: "2024-03-27T11:00:10.481Z",
      comment:
        "A confusion matrix is a table used in machine learning to evaluate the performance of a classification model. It summarizes the actual and predicted classifications, showing counts of true positives, true negatives, false positives, and false negatives.",
      component: "Profile",
      like_count: 0,
      user: {
        user_id: "",
        given_name: "Chat",
        family_name: "Bot",
        username: "Chatbot",
        image: AvaIcon,
      },
      replies: [],
    },
    {
      isPinned: false,
      comment_id: "Wrla0oHbEdasdasd27JWHo",
      model_id: "IujZXfFF4swdTf8i",
      version_id: "7XRmjJXL6fBjDqxg",
      version_number: 1,
      parent_id: null,
      created: "2024-03-27T11:00:10.481Z",
      last_edited: "2024-03-27T11:00:10.481Z",
      comment: "Why is the confusion matrix important?",
      component: "Profile",
      like_count: 0,
      user: {
        user_id: "5CW6kQbDWyVI8JEq",
        username: "Vlad Malinin",
        given_name: "Vlad",
        family_name: "Malinin",
        position: "Frontend",
        image:
          "https://xplainablepublic.blob.core.windows.net/asset-repository/profile_images/5CW6kQbDWyVI8JEq.jpg",
      },
      replies: [],
    },
    {
      isPinned: false,
      comment_id: "Wrla0oHbEdfsdafsdaf27JWHo",
      model_id: "IujZXfFF4swdTf8i",
      version_id: "7XRmjJXL6fBjDqxg",
      version_number: 1,
      parent_id: null,
      created: "2024-03-27T11:00:10.481Z",
      last_edited: "2024-03-27T11:00:10.481Z",
      comment:
        "A confusion matrix is a table used in machine learning to evaluate the performance of a classification model. It summarizes the actual and predicted classifications, showing counts of true positives, true negatives, false positives, and false negatives.",
      component: "Profile",
      like_count: 0,
      user: {
        user_id: "",
        given_name: "Chat",
        family_name: "Bot",
        username: "Chatbot",
        image: AvaIcon,
      },
      replies: [],
    },
  ];
