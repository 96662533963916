import React from 'react'
import LoadingSpinner from 'shared/Animations/LoadingAnimation'
import XBox from 'components/XBox'
import XTypography from 'components/XTypography'
import { Divider } from '@mui/material'

const Verification = () => {
  return (
    <XBox display="flex" height="100%" flexDirection='column' justifyContent='center' alignItems='center' py={12}>
        <XBox>
            <Divider />
            <XBox my={4}
                 sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <LoadingSpinner size={80} animationType="pulse" /> 
                <XBox display="flex" flexDirection="column" textAlign="center" pt={6}>
                    <XTypography variant="caption" fontSize="36px" fontWeight="bold">
                        {"Verifying Identity..."}
                    </XTypography>
                    <XTypography variant="caption" fontSize="16px" pt={1} color="secondary">
                        {"This should only take a few seconds"}
                    </XTypography>
                </XBox>
            </XBox>
            <Divider />
        </XBox>
    </XBox>

  )
}

export default Verification