

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// xplainable Dashboard components
import XBox from "components/XBox";

// xplainable dashboard example component

import DefaultPricingCard from "shared/Cards/DefaultPricingCard";

function PricingCards({ prices }) {
  const [starter, premium, enterprise] = prices;
  return (
    <XBox position="relative" zIndex={10} >
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            title="Free Forever"
            price={{ currency: "$", value: starter }}
            specifications={[
              { label: "1 Creator", includes: true },
              { label: "1 Model", includes: true },
              { label: "1 Deployments", includes: true },
              { label: "Viewers", includes: false },
              { label: "Xplainer Integration", includes: false },
              { label: "Optimisation Tools", includes: false },
              { label: "Experiments", includes: false },
              { label: "Integration help", includes: false },
            ]}
            action={{
              type: "internal",
              route: "/deployment",
              color: "dark",
              label: "Continue",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            title="Premium Trial"
            price={{ currency: "", value: premium }}
            specifications={[
              { label: "5 creators", includes: true },
              { label: "20 Models", includes: true },
              { label: "10 deployments", includes: true },
              { label: "100 Viewers", includes: true },
              { label: "Xplainer Integration", includes: true },
              { label: "Optimisation Tools", includes: true },
              { label: "2 Experiments", includes: true },
              { label: "Integration help", includes: false },
            ]}
            action={{
              type: "",
              route: "https://www.xplainable.io/#contact",
              color: "xpblue",
              label: "Get in Touch",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            title="Premium"
            price={{ currency: "", value: enterprise }}
            specifications={[
              { label: "Unlimited creators", includes: true },
              { label: "Unlimited Models", includes: true },
              { label: "Unlimited + On-prem deployments", includes: true },
              { label: "Unlimited Viewers", includes: true },
              { label: "Xplainer Integration", includes: true },
              { label: "6 Experiments", includes: true },
              { label: "Optimisation Tools", includes: true },
              { label: "Integration help", includes: true },
            ]}
            action={{
              type: "",
              route: "https://www.xplainable.io/#contact",
              color: "xpblue",
              label: "Get in Touch",
            }}
          />
        </Grid>
      </Grid>
    </XBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;
