import React from 'react';
import PropTypes from 'prop-types';
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import DragAndDropIcon from "assets/images/icons/drag-and-drop-icon.svg";
import DownloadIcon from "assets/images/icons/batch/download-icon.svg";

import { StyledBox } from "./styled";
import { XDivider } from "components/XDivider";

import { ReactComponent as UploadIconDataset } from "assets/images/icons/train/database-icon.svg";
import { ReactComponent as UploadIconExcel } from "assets/images/icons/train/upload-icon-excel.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/train/close-icon.svg";

import { useEffect, useState } from "react";
import { useAutoTrain } from 'hooks';
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { UploadDataSet, UploadModal, UploadedFile } from "layouts/datasets/components";
import { Box, Modal, Pagination } from "@mui/material";
import colors from "assets/theme/base/colors";
import { useXplainableController } from "context";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useDatasets } from "hooks";
import { useDatasetDownloadQuery } from "api/query/useDataSetDownloadQuery";
import { ProgressBar } from "layouts/batchOptimisation/BatсhOptimisation/components/OptimiserRuns/components/Run/components";
import { useToast } from 'hooks';

export const UploadDataset = ({
  fileNames,
  getRootProps,
  getInputProps,
  onDropAccepted,
  setDataSetName,
}) => {
  const { onClick, ...rest } = getRootProps();
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const { activeSubscription } = useApiKey();
  const { logout } = useAuth0();
  const { showErrorToast } = useToast();
  //TODO Add the column keys to the SetColumn keys array
  const {
    setColumnKeys
  } = useAutoTrain();

  const [openDataSets, setOpenDataSets] = useState(false);
  const [dataSetId, setDataSetId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Only run the query if the subscription is active
  const { data, isFetching: isDatasetLoading } = useDatasetDownloadQuery(
    dataSetId,
    logout,
    {
      enabled: !!activeSubscription, // Only enable the query when subscription is active
    }
  );

  const {
    dataSets,
    isModalOpen,
    fileToReplace,
    setIsModalOpen,
    handleOpenModal,
    handleOpenReplaceModal,
  } = useDatasets();


  const handleSelectDataSet = (dataset) => {
    if (!activeSubscription) {
      showErrorToast("Subscription is inactive. Please upgrade to use this feature.");
      return;
    }
    setDataSetName(dataset?.name);
    setDataSetId(dataset?.dataset_id);
    setOpenDataSets(false);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const paginatedData = dataSets?.data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    if (!data) return;
    if (!activeSubscription) {
      showErrorToast("Subscription is inactive. Please upgrade to use this feature.");
      return;
    }
    onDropAccepted([data.data]);
  }, [data, activeSubscription]);

  useEffect(() => {
    if (!isModalOpen) {
      setDataSetId("");
    }
  }, [isModalOpen]);

  return (
    <>
      {isDatasetLoading ? (
        <XBox
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
          }}
        >
          <ProgressBar bgColor="#DE4188" completed={0.1} width="70%" height="8px" />
          <XBox sx={{ alignSelf: "flex-end", color: darkMode ? "#FFF" : "#000" }}>{`1%`}</XBox>
        </XBox>
      ) : (
        <XBox display="flex" flexDirection="column" gap={1}>
          <StyledBox
            {...rest}
            sx={{
              height: "560px",
              border: "1px dashed grey",
              display: "flex",
              padding: "24px",
              flexDirection: "column",
              gap: "24px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input {...getInputProps()} />
            {fileNames.length === 0 ? (
              <>
                <XTypography
                  variant="caption"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Drag and drop
                </XTypography>
                <XBox display="flex" gap={2} alignItems="center" width="100%">
                  <XDivider />
                  <XTypography
                    style={{
                      color: "#EAEAEA",
                      fontSize: "14px",
                    }}
                    variant="caption"
                  >
                    or
                  </XTypography>
                  <XDivider />
                </XBox>

                <XBox display="flex" alignItems="center" gap="60px">
                  <XBox
                    display="flex"
                    flexDirection="column"
                    sx={{ border: "1px dashed #33C481", borderRadius: "16px", cursor: "pointer" }}
                    onClick={onClick}
                  >
                    <XBox
                      sx={{
                        padding: "58px 78px",
                        background:
                          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(51, 196, 129, 0.5) 100%)",
                      }}
                    >
                      <UploadIconExcel />
                    </XBox>
                    <XBox py={2} display="flex" justifyContent="center">
                      <XTypography
                        variant="button"
                        sx={{
                          backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Upload CSV
                      </XTypography>
                      <XBox component="img" src={DownloadIcon} />
                    </XBox>
                  </XBox>

                  <XBox
                    display="flex"
                    flexDirection="column"
                    sx={{
                      border: "1px dashed #AFAFAF",
                      borderRadius: "16px",
                      height: "230px",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenDataSets(true)}
                  >
                    <XBox
                      sx={{
                        padding: "56px 76px",
                        backgroundColor: "#F7F7F8",
                        borderTopLeftRadius: "16px",
                        borderTopRightRadius: "16px",
                      }}
                    >
                      <UploadIconDataset />
                    </XBox>
                    <XBox py={2} display="flex" justifyContent="center">
                      <XTypography sx={{ color: "#000", fontSize: "14px", fontWeight: 700 }}>
                        Select from datasets
                      </XTypography>
                    </XBox>
                  </XBox>
                </XBox>
              </>
            ) : (
              fileNames.map((fileName, i) => (
                <XBox key={i} display="flex" alignItems="center" flexDirection="column">
                  <XBox component="img" src={DragAndDropIcon} />
                  <XTypography key={fileName}>{fileName}</XTypography>
                </XBox>
              ))
            )}

            <Modal
              open={openDataSets}
              onClose={() => setOpenDataSets(false)}
              aria-labelledby="request-title"
              aria-describedby="request-description"
              {...rest}
            >
              <XBox
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  maxWidth: "1000px",
                  width: "100%",
                  transform: "translate(-50%, -60%)",
                  background: darkMode ? colors.background.dark : colors.background.default,
                  borderRadius: "16px",
                  padding: 3,
                  outline: 0,
                }}
              >
                {false ? (
                  <XBox
                    sx={{
                      height: "300px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingSpinner animationType="pulse" size={50} />
                  </XBox>
                ) : (
                  <>
                    <XBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "24px",
                      }}
                    >
                      <XTypography sx={{ fontWeight: 700 }}>Choose from dataset</XTypography>
                      <Box
                        sx={{ cursor: "pointer", stroke: darkMode ? "white" : "black" }}
                        onClick={() => setOpenDataSets(false)}
                      >
                        <CloseIcon />
                      </Box>
                    </XBox>
                    <XBox display="flex" gap="24px" flexWrap="wrap">
                      <UploadDataSet openModal={handleOpenModal} width="301px" />

                      <UploadModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        fileToReplace={fileToReplace}
                        setOpenDataSets={setOpenDataSets}
                      />

                      {dataSets?.data?.length > 0 &&
                        paginatedData?.map((file) => (
                          <UploadedFile
                            file={file}
                            key={file.dataset_id}
                            onReplace={handleOpenReplaceModal}
                            width="301px"
                            onSelect={handleSelectDataSet}
                          />
                        ))}
                    </XBox>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                      <Pagination
                        count={Math.ceil(dataSets?.data?.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{ marginTop: "24px" }}
                        color="secondary"
                      />
                    </Box>
                  </>
                )}
              </XBox>
            </Modal>
          </StyledBox>
        </XBox>
      )}
    </>
  );
};

UploadDataset.propTypes = {
  fileNames: PropTypes.any,
  getRootProps: PropTypes.func,
  getInputProps: PropTypes.func,
  onDropAccepted: PropTypes.func,
  setDataSetName: PropTypes.func,
};
