import { forwardRef } from "react";
import PropTypes from "prop-types";
import XTypography from "components/XTypography";
import XProgressRoot from "components/XProgress/XProgressRoot";
import { Box } from "@mui/system";

const XProgress = forwardRef(({ variant, color, value, label, location = "top", ...rest }, ref) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: location === "right" ? "row" : "column-reverse", // Changed to 'column-reverse'
      justifyContent: location === "right" ? "space-between" : "flex-start",
      alignItems: location === "right" ? "center" : "flex-start",
    }}
  >
    <Box width={"100%"} sx={{ flexGrow: 1 }}>
      <XProgressRoot
        {...rest}
        ref={ref}
        variant="determinate"
        value={+value}
        ownerState={{ color, value, variant }}
      />
    </Box>
    {label && (
      <XTypography
        variant="button"
        fontWeight="medium"
        color="text"
        sx={{
          marginBottom: location === "right" ? 0 : 1,
          marginLeft: location === "right" ? 1 : 0,
        }}
      >
        {value}%
      </XTypography>
    )}
  </Box>
));

XProgress.defaultProps = {
  variant: "contained",
  color: "info",
  value: 0,
  label: false,
  location: "top",
};

XProgress.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOfType([
    PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "xppink",
      "xpblue"
    ]),
    PropTypes.string,
  ]),
  value: PropTypes.string,
  label: PropTypes.bool,
  location: PropTypes.oneOf(["top", "right"]),
};

export default XProgress;
