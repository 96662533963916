import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useOrganisationsQuery = (logout) => {
  return useQuery([QUERY_CONSTANTS.ORGANISATIONS], () => apiHandlers.organisations.getData(), {
    onError: (err) => {
      handleTokenExpired(err, logout);
    },
  });
};

export const useManageOrganisationQuery = (logout) => {
  return useQuery([QUERY_CONSTANTS.MANAGE_ORGANISATION], () => apiHandlers.organisations.manageOrganisation(), {
    onError: (err) => {
      handleTokenExpired(err, logout);
    },
  });
}