import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import PropTypes from "prop-types";

import { useTheme } from "@mui/system";

import { ReactComponent as DarkNotebookIcon } from "assets/images/icons/api-keys/dark-notebook-icon.svg";
import { ReactComponent as DarkDeploymentIcon } from "assets/images/icons/api-keys/dark-deployment-icon.svg";
import { ReactComponent as DarkOtherIcon } from "assets/images/icons/api-keys/dark-other-icon.svg";
import { useXplainableController } from "context";

function UsecaseSelect({ onSelection }) {
  const [selected, setSelected] = useState("");
  const theme = useTheme();

  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const handleClick = (value) => {
    setSelected(value);
    onSelection(value);
  };

  const customButtonStyles = (theme) => {
    const { pxToRem, rgba } = theme.functions;
    const { borderWidth } = theme.borders;
    const { transparent, dark, secondary, xpblue } = theme.palette;

    return {
      width: pxToRem(100),
      height: pxToRem(100),
      borderWidth: borderWidth[2],
      mb: 1,

      "&.MuiButton-contained, &.MuiButton-contained:hover": {
        boxShadow: "none",
        border: `${borderWidth[2]} solid ${transparent.main}`,
      },

      "&:hover": {
        // backgroundColor: `${transparent.secondary} !important`,
        // border: `${borderWidth[2]} solid ${secondary.main} !important`,

        "& i": {
          color: rgba(xpblue.main, 0.75),
        },
      },
    };
  };

  const buttonBackgroundColor = () => {
    if (darkMode) {
      return "#1D1B1B !important";
    }

    return "#F7F7F8 !important";
  };

  const buttonStyles = (theme, active) => ({
    ...customButtonStyles(theme),
    border: active ? "2px solid #0080EA !important" : "none !important",
    borderRadius: "16px !important",
    backgroundColor: active ? "#F0F8FF !important" : buttonBackgroundColor(),
  });

  return (
    <Grid container gap="10px">
      <Grid item xs={12} lg={2} mx={1}>
        <XBox textAlign="center">
          <XButton
            onClick={() => handleClick("Notebook")}
            sx={buttonStyles(theme, selected === "Notebook")}
          >
            <DarkNotebookIcon
              style={{
                stroke: selected === "Notebook" ? "#0080EA" : "#7C7C7C",
              }}
            />
          </XButton>
          <XTypography variant="h6" fontSize="12px">
            Notebook
          </XTypography>
        </XBox>
      </Grid>
      <Grid item xs={12} lg={2} mx={1}>
        <XBox textAlign="center">
          <XButton
            onClick={() => handleClick("Deployment")}
            sx={buttonStyles(theme, selected === "Deployment")}
          >
            <DarkDeploymentIcon
              style={{
                stroke: selected === "Deployment" ? "#0080EA" : "#7C7C7C",
              }}
            />
          </XButton>
          <XTypography variant="h6" fontSize="12px">
            Deployment
          </XTypography>
        </XBox>
      </Grid>
      <Grid item xs={12} lg={2} mx={1}>
        <XBox textAlign="center">
          <XButton
            onClick={() => handleClick("Other")}
            sx={buttonStyles(theme, selected === "Other")}
          >
            <DarkOtherIcon
              style={{
                stroke: selected === "Other" ? "#0080EA" : "#7C7C7C",
              }}
            />
          </XButton>
          <XTypography variant="h6" fontSize="12px">
            Other
          </XTypography>
        </XBox>
      </Grid>
    </Grid>
  );
}

export default UsecaseSelect;

UsecaseSelect.propTypes = {
  onSelection: PropTypes.func,
};
