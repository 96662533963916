import React, { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReuseableHeader from "shared/Headers/ReuseableHeader";

import PropTypes from "prop-types";

import { animateGradient } from "assets/theme/base/animations";
import { Grid } from "@mui/material";

import XButton from "components/XButton";
import XBox from "components/XBox";
import XSelect from "components/XSelect";
import XTypography from "components/XTypography";

import { ReportPageWithData } from "./components/ReportPageWithData";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { DroppableContainer, ShareReport } from "./components";
import { useCreateReport } from "./hooks";
import { useUsersQuery } from "api/query";
import { useXplainableController, setOpenConfigurator } from "context";
import { useApp } from "hooks";
import { useAuth0 } from "@auth0/auth0-react";

import ReportIcon from "assets/images/icons/reports/report-icon.svg";

export const CreateReport = () => {
  const { reportList, reportInfo, setActiveStep, setActiveTitle } = useApp();
  const { logout } = useAuth0();
  const { activeWorkspace, setViewState } = useApiKey();
  const { data } = useUsersQuery({ activeWorkspace, logout });

  const [controller, dispatch] = useXplainableController();

  const {
    access,
    activeStep,
    lists,
    selectedVersion,
    handleVersionChange,
    globalImage,
    globalColor,
    viewState,
    listsFromRequest,
    setListsFromRequest,
    reportVersion,
    versions,
    handleNextButton,
    handleReportVersionChange,
  } = useCreateReport();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setActiveTitle(listsFromRequest ? listsFromRequest[0]?.data[0].info.name : reportInfo.name);
  }, [listsFromRequest, reportInfo.name]);

  useEffect(() => {
    setOpenConfigurator(dispatch, true);
  }, []);

  useEffect(() => {
    if (!data) return;

    setUsers(data.data || []);
  }, [data]);

  const handleOpen = async () => {
    setIsModalOpen(true);
  };

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        rhsElement={
          access === "creator" && viewState === "creator" && activeStep === 0 ? (
            <XBox display="flex" flexDirection="column" gap={2} width="100%">
              <XBox display="flex" gap="12px" alignItems="center">
                <XBox
                  component="img"
                  src={ReportIcon}
                  p={1}
                  sx={{ background: "#F7F7F8", borderRadius: "50%" }}
                />
                <XBox display="flex" flexDirection="column">
                  <XTypography variant="button" fontSize="24px">
                    {listsFromRequest ? listsFromRequest[0]?.data[0].info.name : reportInfo.name}
                  </XTypography>
                  <XTypography variant="button" fontWeight="regular" color="secondary">
                    {listsFromRequest
                      ? listsFromRequest[0]?.data[0].info.name
                      : reportInfo.description}
                  </XTypography>
                </XBox>
              </XBox>

              <XBox display="flex" justifyContent="space-between" alignItems="center">
                <XBox display="flex" flexDirection="column" gap={0.5}>
                  <XTypography variant="button" fontWeight="regular">
                    Versions
                  </XTypography>
                  <XSelect
                    value={selectedVersion}
                    onChange={handleVersionChange}
                    fullWidth
                    placeholder="Select Version"
                    options={versions.map((version) => ({
                      value: version.version_id,
                      label: `Version ${version.version_number}`,
                    }))}
                  />
                </XBox>
                <XBox>
                  <XButton variant="gradient" color="button" onClick={handleNextButton}>
                    Save
                  </XButton>
                </XBox>
              </XBox>
            </XBox>
          ) : (
            activeStep === 1 && (
              <XBox display="flex" flexDirection="column" gap={2} width="100%">
                <XBox display="flex" gap="12px" alignItems="center">
                  <XBox
                    component="img"
                    src={ReportIcon}
                    p={1}
                    sx={{
                      background: controller.darkMode ? "#1D1B1B" : "#F7F7F8",
                      borderRadius: "50%",
                    }}
                  />
                  <XBox display="flex" flexDirection="column">
                    <XTypography variant="button" fontSize="24px">
                      {listsFromRequest ? listsFromRequest[0]?.data[0].info.name : reportInfo.name}
                    </XTypography>
                    <XTypography variant="button" fontWeight="regular" color="secondary">
                      {listsFromRequest
                        ? listsFromRequest[0]?.data[0].info.name
                        : reportInfo.description}
                    </XTypography>
                  </XBox>
                </XBox>

                <XBox display="flex" justifyContent="space-between" alignItems="center">
                  <XBox display="flex" flexDirection="column" gap={0.5}>
                    <XTypography variant="button" fontWeight="regular">
                      Versions
                    </XTypography>
                    <XBox width={"100%"}>
                      {reportList ? (
                        <XSelect
                          value={reportVersion}
                          onChange={(e) => handleReportVersionChange(e)}
                          fullWidth
                          placeholder="Select Report Version"
                          options={reportList.map((item) => ({
                            value: item.version_number,
                            label: `Version ${item.version_number}`,
                          }))}
                        />
                      ) : listsFromRequest ? (
                        <XSelect
                          value={reportVersion}
                          onChange={(e) => handleReportVersionChange(e)}
                          fullWidth
                          placeholder="Select Report Version"
                          options={listsFromRequest.map((item) => ({
                            value: item.version_number,
                            label: `Version ${item.version_number}`,
                          }))}
                        />
                      ) : null}
                    </XBox>
                  </XBox>
                  <XBox display="flex" gap={3}>
                    <XButton
                      variant="gradient"
                      color="button"
                      onClick={() => {
                        setActiveStep(0);
                      }}
                    >
                      Edit
                    </XButton>
                    <XButton
                      variant="outlined"
                      sx={{
                        background: controller.darkMode ? "#1D1B1B" : "#F7F7F8",
                        borderColor: controller.darkMode ? "#1D1B1B" : "#F7F7F8",
                        color: controller.darkMode ? "white !important" : "black !important",
                      }}
                      onClick={handleOpen}
                    >
                      Share report
                    </XButton>
                  </XBox>
                </XBox>
              </XBox>
            )
          )
        }
      />

      <XBox>
        {activeStep === 0 ? (
          <Grid container spacing={1} mt={2} justifyContent="space-between" gap={2}>
            {Object.keys(lists).map((list, index) => {
              return <DroppableContainer key={index} lists={lists} list={list} />;
            })}
          </Grid>
        ) : (
          <ReportPageWithData
            lists={lists}
            listsFromRequest={listsFromRequest}
            setListsFromRequest={setListsFromRequest}
            reportVersion={reportVersion}
            reportList={reportList}
          />
        )}
      </XBox>
      <ShareReport isModalOpen={isModalOpen} users={users} setIsModalOpen={setIsModalOpen} />
    </DashboardLayout>
  );
};

CreateReport.propTypes = {
  value: PropTypes.object,
  row: PropTypes.object,
};
