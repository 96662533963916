// useMixPanelQuery.js
import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useUserQuery = (api_key, logout) => {
  return useQuery([QUERY_CONSTANTS.USER], () => apiHandlers.userManagement.getUser(api_key), {
    onError: (err) => {
      handleTokenExpired(err, logout);
    },
  });
};
