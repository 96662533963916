import React from "react";
import { RunCard } from "../RunCard";
import { Card } from "@mui/material";
import { OptimisationMadeChart } from "../OptimisationMadeChart";

import PropTypes from "prop-types";

import XBox from "components/XBox";
import XTypography from "components/XTypography";

export const OptimisationMade = ({ batchAnalysis }) => {
  const data = [
    {
      title: "AVERAGE SCORE",
      value: batchAnalysis?.averageScore,
      descriptionValue: "+13%",
      descriptionText: "from initial state",
    },
    {
      title: "TOTAL EXPECTED CONST",
      value: batchAnalysis?.totalExpectedCost,
      descriptionValue: "-$1,301",
      descriptionText: "from initial state",
    },
    {
      title: "TOTAL EXPECTED BENEFIT",
      value: "$65,022",
      descriptionValue: "+133%",
      descriptionText: "from initial state",
    },
    {
      title: "TOTAL OPTIMISATIONS",
      value: "193",
      descriptionValue: "12%",
      descriptionText: "of dataset",
    },
  ];

  const chartData = [
    { name: "16-20", value: 15 },
    { name: "21-25", value: 20 },
    { name: "26-30", value: 11 },
    { name: "31-36", value: 60 },
    { name: "36-42", value: 20 },
    { name: "42+", value: 35 },
  ];
  return (
    <>
      <XBox display="flex" sx={{ width: "100%" }} gap="20px">
        <Card sx={{ width: "100%" }}>
          <XBox sx={{ padding: "16px 10px 0px" }}>
            <XTypography fontSize="14px" color="secondary" fontWeight="medium">
              OPTIMISATIONS MADE
            </XTypography>
            <XBox>
              <OptimisationMadeChart data={chartData} />
            </XBox>
          </XBox>
        </Card>
        <XBox display="flex" flexDirection="column" justifyContent="space-between" width="100%">
          {data.slice(0, 2).map((item, index) => (
            <RunCard
              key={index}
              title={item.title}
              value={item.value}
              descriptionText={item.descriptionText}
              descriptionValue={item.descriptionValue}
            />
          ))}
        </XBox>
      </XBox>
      {/* <XBox display="flex" gap="20px">
        {data.slice(2).map((item, index) => (
          <RunCard
            key={index}
            title={item.title}
            value={item.value}
            descriptionText={item.descriptionText}
            descriptionValue={item.descriptionValue}
          />
        ))}
      </XBox> */}
    </>
  );
};

OptimisationMade.propTypes = {
  batchAnalysis: PropTypes.object,
};
