import React from "react";
import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";
import XBox from "components/XBox";

import { defaultMentionStyle } from "shared/Comments/defaultMentionStyle";
import { defaultStyle } from "shared/Comments/defaultStyle";
import { useXplainableController } from "context";
import { Mention, MentionsInput } from "react-mentions";

import InputIcon from "assets/images/icons/comment/input-icon.svg";

export const MentionInput = ({ mentionValue, setMentionValue, handleSubmit, data }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  return (
    <>
      <MentionsInput
        className="mention-input"
        style={defaultStyle(darkMode)}
        value={mentionValue}
        onChange={(e) => setMentionValue(e.target.value)}
        placeholder="Write your comment"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }
        }}
      >
        <Mention
          className="mention-menu"
          trigger="@"
          style={defaultMentionStyle(darkMode)}
          data={data}
          renderSuggestion={(suggestion) => <div>@{suggestion.display}</div>}
          appendSpaceOnAdd={true}
          markup="@[__display__]#(__id__)"
        />
      </MentionsInput>
      <XBox
        component="img"
        src={InputIcon}
        sx={{
          color: colors.primary.main,
          borderRadius: "50%",
          cursor: "pointer",

          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: "15px",
        }}
        onClick={handleSubmit}
      />
    </>
  );
};

MentionInput.propTypes = {
  mentionValue: PropTypes.string,
  setMentionValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  data: PropTypes.func,
};
