import { useEffect, useRef, useState } from "react";

import { useCommentsMutation } from "api/mutations";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { debouncedSearch, handleTokenExpired } from "utils";

import { useAuth0 } from "@auth0/auth0-react";
import { useCommentsQuery, useCommentsUsersQuery } from "api/query";
import breakpoints from "assets/theme/base/breakpoints";

export const useComments = ({
  modelId,
  deploymentId,
  collectionId,
  versionId,
  type,
  tabValue,
  version,
}) => {
  const { apiKey, user } = useApiKey();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreComments, setHasMoreComments] = useState(true);

  const itemsPerPage = 10;

  const { logout } = useAuth0();

  // Map resource IDs based on type
  const idMapping = {
    models: modelId,
    deployments: deploymentId,
    collections: collectionId,
  };

  const resourceId = idMapping[type];

  // Fetch comments using the updated query
  const {
    data,
    isLoading,
    refetch: commentsRefetch,
  } = useCommentsQuery({
    resourceId,
    pageNumber: currentPage,
    itemsPerPage,
    logout,
  });

  // Fetch users for mentions
  const { refetch } = useCommentsUsersQuery({ logout });
  const { addCommentMutation } = useCommentsMutation();

  const inputRef = useRef(null);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [currentUser] = useState(user ? user.username : "Username not set");

  const [fetchedComments, setFetchedComments] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const [selectionValue, setSelectionValue] = useState(0);
  const [commentValue, setCommentValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  // Fetch more comments by incrementing the page number
  const fetchMoreComments = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Fetch users for the mentions feature
  const fetchUsers = (query, callback) => {
    if (!query) {
      refetch().then(({ data }) => {
        const mentionData =
          data?.data?.map((user) => ({
            id: user.user_id,
            display: `${user.given_name} ${user.family_name}`,
          })) || [];

        callback([...mentionData, { id: "__all__", display: "all" }]);
        return;
      });
    } else {
      debouncedSearch(query, apiKey, (data) => {
        const mentionData =
          data?.map((user) => ({
            id: user.user_id,
            display: `${user.given_name} ${user.family_name}`,
          })) || [];

        callback([...mentionData, { id: "__all__", display: "all" }]);
      });
    }
  };

  // Update comments when data changes
  useEffect(() => {
    if (data) {
      if (currentPage === 1) {
        setFetchedComments(data?.data);
      } else {
        setFetchedComments((prevItems) => [...prevItems, ...data.data]);
      }

      if (data.data.length < itemsPerPage) {
        setHasMoreComments(false);
      }
    }
  }, [data, currentPage]);

  // Reset pagination and comments when the resource ID changes
  useEffect(() => {
    setCurrentPage(1);
    setFetchedComments([]);
    setHasMoreComments(true);
  }, [resourceId]);

  // Handle tabs orientation based on screen size
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setSelectionValue(newValue);

    if (newValue === 0) {
      setFilteredComments(fetchedComments);
    } else {
      const filtered = fetchedComments.filter((comment) => comment?.resource_version_id === versionId);
      setFilteredComments(filtered);
    }
  };

  // Map tab values to their keys
  const tabMap = Object.entries({
    Profile: 0,
    Scenario: 1,
    Performance: 2,
    Preprocessing: 3,
    Predict: 4,
    Config: 5,
  }).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle adding a new comment
  const handleAddComment = () => {
    if (!commentValue.trim()) return;

    //Comment Object Creation
    const now = new Date();
    const regex = /#\(([^)]+)\)/g;
    const matches = commentValue.match(regex);
    const tagged_users = matches ? matches.map((match) => match.slice(2, -1)) : [];
    const resultString = commentValue.replace(/#\([^)]+\)/g, "");

    const payload = JSON.stringify({
      comment: resultString,
      resource_id: resourceId,
      resource_version_id: versionId,
      parent_id: null,
      comment: resultString,
      component: tabMap[tabValue],
      tagged_users: tagged_users,
    });

    const newComment = {
      comment_id: "",
      model_id: modelId,
      version_id: versionId,
      resource_version_number: version,
      parent_id: null,
      created: now,
      last_edited: now,
      comment: resultString,
      component: tabMap[tabValue],
      like_count: 0,
      user: {
        user_id: user?.id,
        username: user?.username,
        given_name: user?.personal_details?.given_name,
        family_name: user?.personal_details?.family_name,
        position: user?.position,
        image: user?.profile_image_url
      },
      replies: [],
    };

    setFetchedComments((prevComments) => [...prevComments, newComment]);
    setCommentValue("");

    addCommentMutation([payload], {
      onSuccess: ({ data }) => {
        newComment.comment_id = data.comment_id;

        setFetchedComments((prevComments) => [
          ...prevComments.filter((comment) => comment !== newComment),
          newComment,
        ]);
        setCommentValue("");
      },
      onError: (error) => {
        console.error(error);
        setFetchedComments((prevComments) =>
          prevComments.filter((comment) => comment !== newComment)
        );
        handleTokenExpired();
      },
    });
  };

  return {
    inputRef,
    currentUser,
    selectionValue,
    handleInputChange,
    handleAddComment,
    handleSetTabValue,
    tabsOrientation,
    data,
    isLoading,
    user,
    inputValue,
    fetchedComments,
    id: resourceId,
    apiKey,
    setFetchedComments,
    tabMap,
    commentValue,
    setCommentValue,
    fetchUsers,
    hasMoreComments,
    fetchMoreComments,
  };
};
