import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useCollectionMutation = () => {
  const { mutate: createCollection } = useMutation((params) =>
    apiHandlers.collections.createCollection(...params)
  );

  const { mutate: deleteCollectionMutation } = useMutation((params) =>
    apiHandlers.collections.deleteCollection(...params)
  );

  const { mutate: deleteModelCollectionsMutation } = useMutation((params) =>
    apiHandlers.collections.deleteModelCollections(...params)
  );

  const { mutate: updateCollectionTitleMutation } = useMutation((params) =>
    apiHandlers.collections.updateTitle(...params)
  );

  const { mutate: updateCollectionDescriptionMutation } = useMutation((params) =>
    apiHandlers.collections.updateDescription(...params)
  );

  const { mutate: updateScenarioNotesMutation } = useMutation((params) =>
    apiHandlers.collections.updateScenarioNotes(...params)
  );

  return {
    deleteCollectionMutation,
    deleteModelCollectionsMutation,
    updateCollectionTitleMutation,
    updateCollectionDescriptionMutation,
    createCollection,
    updateScenarioNotesMutation,
  };
};
