import XBox from "components/XBox";
import React from "react";
import { PropTypes } from "prop-types";
import XTypography from "components/XTypography";

import DownloadIcon from "assets/images/icons/batch/download-icon.svg";
import { Box } from "@mui/material";

export const UploadItem = ({ 
  icon, 
  text, 
  isReady, 
  getInputProps, 
  onClick,
}) => {
  return (
    <XBox
      sx={{
        border: isReady 
          ? "1px dashed #33C481" 
          : "1px dashed #AFAFAF",
        borderRadius: "16px",
        width: "220px",
        cursor: "pointer"
      }}
      onClick={onClick ? onClick : () => {}}
    >
      {getInputProps && <input {...getInputProps()} />}
      <XBox
        sx={{
          padding: "58px 78px",
          background: isReady 
            ?  "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(51, 196, 129, 0.5) 100%)"
            : "#F7F7F8",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          textAlign: "center"
        }}
      >
        <Box sx={{
          stroke: text === 'Google Sheet coming soon' && "#EAEAEA"
        }}>
          {icon}
        </Box>
      </XBox>

      <XBox py={2} display="flex" justifyContent="center" gap="10px">
        <XTypography
          variant="button"
          sx={{
            backgroundImage: isReady && "linear-gradient(to right, #E14086, #0080EA)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: isReady && "transparent",
            whiteSpace: "nowrap",
            color: !isReady && "#7C7C7C"
          }}
        >
          {text}
        </XTypography>
        {isReady && text === "Upload CSV" && <XBox component="img" src={DownloadIcon} />}
      </XBox>
    </XBox>
  );
};

UploadItem.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  isReady: PropTypes.bool,
  getInputProps: PropTypes.func,
  onClick: PropTypes.func
};
