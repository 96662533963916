import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Card, Tooltip } from "@mui/material";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XSelect from "components/XSelect";
import XButton from "components/XButton";
import PlaceholderCard from "shared/Cards/PlaceholderCard";
import PreprocessorInfoCard from "shared/Cards/PreprocessorCard";
import { ReactComponent as DarkXCloseIcon } from "assets/images/dark-x-close-icon.svg";
import { XImg } from "components/XImg";
import { usePreprocessorQuery, useRelevantPreprocessorQuery } from "api/query";
import { usePreprocessorMutations } from "api/mutations/usePreprocessorMutation";
import { useToast } from "hooks"; // Assuming you have a useToast hook for notifications
import LoadingSpinner from "shared/Animations/LoadingAnimation";

function PreprocessorCard({ modelVersion, setExampleData }) {
  const { logout } = useAuth0();
  const [preprocessorData, setPreprocessorData] = useState(null);
  const [isLinking, setIsLinking] = useState(false);
  const [addingPreprocessor, setAddingPreprocessor] = useState(false);
  
  // Form states
  const [preprocessors, setPreprocessors] = useState([]);
  const [selectedPreprocessor, setSelectedPreprocessor] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [versions, setVersions] = useState([]);

  // Fetch the preprocessor data
  const { data: fetchedPreprocessor } = usePreprocessorQuery(modelVersion, logout);
  const { data: allPreprocessorsData, isLoading: isLoadingPreprocessors } = useRelevantPreprocessorQuery(
    modelVersion, 
    logout
  );
  const { linkPreprocessorMutation, unlinkPreprocessorMutation } = usePreprocessorMutations();
  const { showSuccessToast, showErrorToast } = useToast();

  // Keep preprocessor data handling separate and simple
  useEffect(() => {
    if (fetchedPreprocessor?.data) {
      const data = fetchedPreprocessor.data;
      setPreprocessorData({
        id: data?.preprocessor_id,
        version: data?.preprocessor_version_id,
        preprocessor_name: data?.preprocessor_name,
        selected_version: data?.version_number,
        user: {
          image: data?.created_by?.image,
          given_name: data?.created_by?.given_name,
          family_name: data?.created_by?.family_name,
        },
        created: data?.created,
        preprocessor_description: data?.preprocessor_description,
        position: data?.created_by?.position,
      });

      if (fetchedPreprocessor?.data?.deltas?.[0]?.start[0]) {
        const rawData = fetchedPreprocessor?.data?.deltas?.[0].start[0]
        setExampleData?.(rawData);
      } else {
        setExampleData?.(null);
      }
    } else {
      setPreprocessorData(null);
    }
  }, [fetchedPreprocessor]);

  // Handle available preprocessors
  useEffect(() => {
    if (allPreprocessorsData?.data) {
      const preprocessorsArray = Object.keys(allPreprocessorsData.data).map(key => ({
        preprocessor_id: key,
        versions: allPreprocessorsData.data[key]
      }));
      setPreprocessors(preprocessorsArray);
    } else {
      setPreprocessors([]);
    }
  }, [allPreprocessorsData]);

  const handlePreprocessorChange = (event) => {
    setSelectedPreprocessor(event);
    setSelectedVersion(null);

    const preprocessorId = event.value;
    const selectedPreprocessorObj = preprocessors.find(
      (preprocessor) => preprocessor.preprocessor_id === preprocessorId
    );

    if (selectedPreprocessorObj) {
      const versionOptions = (selectedPreprocessorObj.versions || []).map((version) => ({
        value: version.id,
        label: version.id,
      }));
      setVersions(versionOptions);
    } else {
      setVersions([]);
    }
  };

  const handleVersionChange = (event) => {
    setSelectedVersion(event);
  };

  const handleLinkPreprocessor = () => {
    if (!selectedPreprocessor || !selectedVersion) {
      showErrorToast("Please select both preprocessor and its version.");
      return;
    }

    setIsLinking(true);

    // Find the full preprocessor data from our selection
    const foundPreprocessor = preprocessors.find(
      (preprocessor) => preprocessor.preprocessor_id === selectedPreprocessor.value
    );

    const foundVersion = foundPreprocessor?.versions?.find(
      (version) => version.id === selectedVersion.value
    );

    const linkData = {
      version_id: modelVersion,
      preprocessor_version_id: selectedVersion.value
    };

    linkPreprocessorMutation([linkData], {
      onSuccess: (data) => {

        // Use the data we already have from the selection
        setPreprocessorData({
          id: foundPreprocessor.id,
          version: foundVersion.preprocessor_id,
          preprocessor_name: foundVersion.preprocessor_name || 'Unknown Preprocessor',
          user: {
            image: foundVersion.user?.image || '',
            given_name: foundVersion.user?.given_name || '',
            family_name: foundVersion.user?.family_name || '',
          },
          created: foundVersion.created,
          preprocessor_description: foundVersion.preprocessor_description || 'No description provided.',
          position: foundVersion.user?.position || 'User'
        });
        setIsLinking(false);
        setAddingPreprocessor(false);
        showSuccessToast("Preprocessor linked successfully.");

        if (foundVersion?.deltas?.[0]?.start) {
          const rawData = foundVersion.deltas[0].start[0];
          setExampleData?.(rawData);
        } else {
          setExampleData?.(null);
        }
      },
      onError: (error) => {
        console.error("Failed to link preprocessor:", error);
        console.error("Link Data that failed:", linkData);
        setIsLinking(false);
        showErrorToast("Failed to link preprocessor.");
      },
    });
  };

  const handleUnlinkPreprocessor = () => {
    if (!preprocessorData) return;

    setIsLinking(true);
    const unlinkData = {
      version_id: modelVersion,
      preprocessor_version_id: preprocessorData?.version,
    };

    unlinkPreprocessorMutation([unlinkData], {
      onSuccess: () => {
        setPreprocessorData(null);
        setIsLinking(false);
        setAddingPreprocessor(false);
        setExampleData?.(null);
        showSuccessToast("Preprocessor unlinked successfully.");
      },
      onError: (error) => {
        console.error("Failed to unlink preprocessor:", error);
        setIsLinking(false);
        showErrorToast("Failed to unlink preprocessor.");
      },
    });
  };

  return (
    <Card sx={{ height: "280px" }}>
      <XBox sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, justifyContent: "center", alignItems: "center" }}>
        <Grid container item xs={12} alignItems="start">
          <Grid item xs={12}>
            <XBox>
              {addingPreprocessor ? (
                <Card sx={{ position: "relative", zIndex: 1, overflow: "visible", display: "flex", flexDirection: "column", justifyContent: "space-around", p: 2, height: "280px" }}>
                  <XBox sx={{ position: "absolute", top: 15, right: 15 }} onClick={() => setAddingPreprocessor(false)}>
                    <XImg><DarkXCloseIcon /></XImg>
                  </XBox>

                  <XTypography variant="h6" align="start">Link Preprocessor</XTypography>

                  <XTypography variant="overline" gutterBottom>Preprocessor</XTypography>
                  <XSelect
                    value={selectedPreprocessor}
                    onChange={handlePreprocessorChange}
                    fullWidth
                    placeholder="Select Preprocessor"
                    options={isLoadingPreprocessors 
                      ? [{ value: 'loading', label: (<XBox sx={{ display: 'flex', alignItems: 'center', gap: 2 }}><LoadingSpinner size={20} /><XTypography variant="overline" color="secondary">Loading preprocessors...</XTypography></XBox>), disabled: true }]
                      : preprocessors.map((preprocessor) => ({
                          value: preprocessor.preprocessor_id,
                          label: preprocessor.preprocessor_name || preprocessor.preprocessor_id,
                        }))}
                  />

                  <XBox mb={2}>
                    <XTypography variant="overline" gutterBottom>Version</XTypography>
                    <XSelect
                      value={selectedVersion}
                      onChange={handleVersionChange}
                      fullWidth
                      placeholder="Select Version"
                      options={versions}
                      disabled={!selectedPreprocessor}
                    />
                  </XBox>

                  <XBox display="flex" justifyContent="flex-end">
                    <XButton
                      color="button"
                      variant="gradient" 
                      onClick={handleLinkPreprocessor}
                      disabled={!selectedPreprocessor || !selectedVersion}
                    >
                      Link
                    </XButton>
                  </XBox>
                </Card>
              ) : preprocessorData ? (
                <PreprocessorInfoCard
                  title={preprocessorData?.preprocessor_name}
                  version={preprocessorData?.selected_version}
                  author={`${preprocessorData?.user.given_name} ${preprocessorData?.user.family_name}`}
                  imgUrl={preprocessorData?.user.image}
                  description={preprocessorData.preprocessor_description}
                  role={preprocessorData?.position}
                  date={preprocessorData?.created}
                  setAddingPreprocessor={setAddingPreprocessor}
                  onUnlinkPreprocessor={handleUnlinkPreprocessor}
                />
              ) : (
                <XBox sx={{ zIndex: "0", width: { xs: "100%" }, justifyContent: { xs: "center", lg: "flex-start" } }}>
                  <PlaceholderCard
                    title={{ variant: "h6", text: "Click to link preprocessor" }}
                    height="280px"
                    outlined
                    onClick={() => setAddingPreprocessor(!addingPreprocessor)}
                  />
                </XBox>
              )}
            </XBox>
          </Grid>
        </Grid>
      </XBox>
    </Card>
  );
}

PreprocessorCard.propTypes = {
  model_id: PropTypes.string,
  modelVersion: PropTypes.object,
  setExampleData: PropTypes.func
};

export default PreprocessorCard;

