import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import React, { useEffect, useRef, useState } from "react";

import { Card, Divider, Switch } from "@mui/material";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";

//Xplainable Components
import XAvatar from "components/XAvatar";
import XBox from "components/XBox";
import XButton from "components/XButton";
import XEditor from "components/XEditor";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import "react-quill/dist/quill.bubble.css";

import { marked } from "marked";

const markdownToHtml = (markdown) => {
  return marked(markdown);
};
const stripHtmlTags = (str) => {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return doc.body.textContent || "";
};

//User Defined Functions
import LinkAnimation from "shared/Animations/LinkAnimation";
import HoverMenu from "./components/HoverMenu";

import { useAuth0 } from "@auth0/auth0-react";
import { useCommentaryMutation } from "api/mutations";
import { useCommentaryQuery } from "api/query";
import { getTimeDifference } from "shared/Functions/Date";

const Commentary = ({ id, version }) => {
  const { viewState, activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const [controller, dispatch] = useXplainableController();
  const { darkMode } = controller;

  const [quillType, setQuillType] = useState("bubble");
  const [readOnly, setReadOnly] = useState(true);
  const [editedHeader, setEditedHeader] = useState("");
  const [editedCaption, setEditedCaption] = useState("");
  const [author, SetAuthor] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [editedDate, setEditedDate] = useState(null);
  const [published, setPublished] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [buttonText, setButtonText] = useState("Edit");
  const [isLoading, setIsLoading] = useState(false);
  const [typing, setTyping] = useState(false);

  const { data: commentaryData } = useCommentaryQuery(version?.value, logout);
  const { autoGenerateReportMutation, changeCommentaryMutation, handlePublishCommentaryMutation } =
    useCommentaryMutation();

  useEffect(() => {
    if (!commentaryData || commentaryData?.data === "No commentary found for this model.") return;

    const { data } = commentaryData;

    setImgUrl(data.last_edited_by.image);
    setPublished(data.published);
    setEditedDate(data.last_edited);
    SetAuthor(data.last_edited_by.given_name + " " + data.last_edited_by.family_name);
    setEditedHeader(data.heading);
    setEditedCaption(data.subheading);
    setEditorValue(data.text);
  }, [commentaryData, id, version]);

  const typeWriter = (markdownText) => {
    const htmlText = markdownToHtml(markdownText);

    if (!htmlText || typeof htmlText !== "string") {
      console.error("Invalid input for typeWriter:", htmlText);
      return;
    }

    let words = htmlText.split(/\s+/);
    let index = 0;
    setTyping(true);

    const interval = setInterval(() => {
      setEditorValue((prevHtml) => prevHtml + words[index] + " ");
      index++;

      if (index === words.length) {
        clearInterval(interval);
        setTyping(false);
      }
    }, 10); // Adjust the interval as needed
  };

  const autoGenerateReport = async (target_description, project_objective, temperature, type) => {
    // Set loading status
    setIsLoading(true);
    const reportData = {
      type: type, // "report" or "insights"
      target_description: target_description,
      project_objective: project_objective,
      temperature: temperature,
      model_version_id: version.value,
    };

    autoGenerateReportMutation([activeWorkspace, id, version, JSON.stringify(reportData)], {
      onSuccess: (data) => {
        console.log(data);
        setEditorValue("");
        updateQuill();

        setEditedHeader(data.data.heading);
        setEditedCaption(data.data.tagline);

        typeWriter(data.data.body);
        setIsLoading(false);
      },
      onError: (err) => {
        console.log(err);
        setIsLoading(false);
      },
    });
  };

  const handleSubmit = async () => {
    const data = JSON.stringify({
      text: editorValue,
      heading: editedHeader,
      subheading: editedCaption,
    });

    changeCommentaryMutation([activeWorkspace, id, version, data], {
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handlePublishChange = async () => {
    const action = published ? "unpublish" : "publish";
    const publishData = JSON.stringify({
      model_version_id: version?.value,
      page: "Profile",
    });

    handlePublishCommentaryMutation([action, publishData], {
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  //Handle the select of the dropdown
  async function updateQuill() {
    setReadOnly(!readOnly);
    setQuillType(quillType === "bubble" ? "snow" : "bubble");
    setButtonText(buttonText === "Save" ? "Edit" : "Save");
    if (buttonText === "Save") {
      await handleSubmit();
    }
  }

  // Create a ref to store the debounced function
  const debouncedSetEditorValue = useRef(debounce((value) => setEditorValue(value), 300)).current;

  return (
    <>
      {viewState !== "creator" && !published ? (
        <></>
      ) : (
        <XBox mt={{ xs: 10, lg: 3 }}>
          <Card sx={{ p: { sm: 2, lg: 4 }, position: "relative", overflow: "visible" }}>
            <XBox p={2}>
              <XBox display="flex" justifyContent="space-between" alignItems="center">
                <XBox width={"70%"}>
                  {buttonText === "Save" ? (
                    <XInput
                      inputProps={{
                        style: { height: "40px", fontSize: 24, fontWeight: "bold", width: "100%" },
                      }}
                      value={editedHeader}
                      onChange={(e) => setEditedHeader(e.target.value)}
                      autoFocus
                    />
                  ) : (
                    <XTypography variant="h4" fontWeight="bold" mr={1}>
                      {editedHeader}
                    </XTypography>
                  )}
                  {buttonText === "Save" ? (
                    <XInput
                      inputProps={{ style: { fontSize: 14, fontWeight: "bold", width: "100%" } }}
                      value={editedCaption}
                      onChange={(e) => {
                        setEditedCaption(e.target.value);
                      }}
                      autoFocus
                    />
                  ) : (
                    <XTypography variant="subtitle2" color="secondary" mr={1}>
                      {editedCaption}
                    </XTypography>
                  )}
                </XBox>
                <XBox display="flex" alignItems="center">
                  <XBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <XTypography variant="h6" lineHeight={1}>
                      {author}
                    </XTypography>
                    <XTypography variant="caption" color="secondary" lineHeight={1.4}>
                      {editedDate ? getTimeDifference(editedDate, true) : ""}
                    </XTypography>
                  </XBox>
                  <XBox ml={1}>
                    <XAvatar variant="rounded" src={imgUrl} />
                  </XBox>
                </XBox>
              </XBox>
              {viewState === "creator" && (
                <XBox display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                  <XBox>
                    <XButton
                      color={darkMode ? "dark" : "white"}
                      onClick={() => {
                        updateQuill();
                      }}
                      p={0.1}
                    >
                      <Icon color={darkMode ? "white" : "secondary"}>edit</Icon>
                      <XTypography variant="button" color={darkMode ? "white" : "secondary"} ml={1}>
                        {buttonText}
                      </XTypography>
                    </XButton>
                    <XTypography variant="button" mx={2}>
                      {"Publish"}
                    </XTypography>
                    <Switch
                      checked={published}
                      onChange={() => {
                        setPublished(!published);
                        handlePublishChange();
                      }}
                    />
                  </XBox>
                  <XBox>
                    <HoverMenu autoGenerateReport={autoGenerateReport} />
                  </XBox>
                </XBox>
              )}
              <Divider />
              <XBox mt={4} sx={{ zIndex: 10 }}>
                {isLoading ? (
                  <LinkAnimation />
                ) : (
                  <XEditor
                    readOnly={readOnly}
                    theme={"bubble"}
                    onChange={(content, delta, source, editor) => debouncedSetEditorValue(content)}
                    value={editorValue}
                  />
                )}
              </XBox>
            </XBox>
          </Card>
        </XBox>
      )}
    </>
  );
};

export default Commentary;

// Typechecking props for the DataTable
Commentary.propTypes = {
  id: PropTypes.string,
  version: PropTypes.object,
};

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}


