import React, { useState, useEffect } from "react";

import { AppBar, Tabs, Tab, Grid, Card } from "@mui/material";

import XBox from "components/XBox";
import breakpoints from "assets/theme/base/breakpoints";

import ModelGeneralSettings from "./components/general";
import ModelLogicRules from "./components/logic-rules";

import ScrollReveal from "scrollreveal";

const ModelConfiguration = () => {
  const [tabValue, setTabValue] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, []);

  return (
    <XBox py={2} className="scrollreveal">
      <Card sx={{ p: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <XBox display="flex" flexDirection="column" justifyContent="center" alignItems="start">
              <XBox mb={3}>
                <AppBar position="static">
                  <Tabs
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                    sx={{
                      "& .Mui-selected": {
                        color: "#DE4188!important",
                        fontWeight: "600",
                      },
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#DE4188",
                      },
                    }}
                  >
                    <Tab label="Feature Info" sx={{ minWidth: "0" }} />
                    <Tab label="Logic Rules" sx={{ minWidth: "0" }} />
                  </Tabs>
                </AppBar>
              </XBox>
            </XBox>
          </Grid>
          <Grid item xs={12}>
            <XBox>
              {tabValue === 0 && <ModelGeneralSettings />}
              {tabValue === 1 && <ModelLogicRules />}
            </XBox>
          </Grid>
        </Grid>
      </Card>
    </XBox>
  );
};

export default ModelConfiguration;
