function transformDataToTimelineItem(data) {
  return data.map((item) => {
    let key, color, icon, title, description;
    switch (item.event) {
      case "new_login":
        key = "new_login";
        color = "info";
        icon = "login"; // Example icon for login
        title = "New Login";
        description = "A new user login was detected.";
        break;

      case "created_deploy_key":
        key = "created_deploy_key";
        color = "success";
        icon = "vpn_key"; // Key icon for deploy key
        title = "Deploy Key Created";
        description = "A new deploy key was created.";
        break;

      case "deployed_model":
        key = "deployed_model";
        color = "success";
        icon = "cloud_upload"; // Cloud icon for deployment
        title = "Model Deployed";
        description = `Model with ID ${item.properties.model_id} was deployed.`;
        break;

      case "deleted_deployment":
        key = "deleted_deployment";
        color = "error";
        icon = "cloud_off"; // Cloud off icon for deleted deployment
        title = "Deployment Deleted";
        description = "A model deployment was deleted.";
        break;

      case "created_model":
        key = "created_model";
        color = "success";
        icon = "add_box"; // Box icon for created model
        title = "Model Created";
        description = `Model with ID ${item.properties.model_id} was created.`;
        break;

      case "deleted_model":
        key = "deleted_model";
        color = "error";
        icon = "delete"; // Delete icon for deleted model
        title = "Model Deleted";
        description = `Model with ID ${item.properties.model_id} was deleted.`;
        break;

      case "activated_model":
        key = "activated_model";
        color = "success";
        icon = "check_circle"; // Check circle for activated model
        title = "Model Activated";
        description = `Model with ID ${item.properties.model_id} was activated.`;
        break;

      case "deactivated_model":
        key = "deactivated_model";
        color = "error";
        icon = "error_outline"; // Error icon for deactivated model
        title = "Model Deactivated";
        description = `Model with ID ${item.properties.model_id} was deactivated.`;
        break;

      case "generated_gpt_report":
        key = "generated_gpt_report";
        color = "xpblue";
        icon = "report"; // Report icon for GPT report
        title = "GPT Report Generated";
        description = "A GPT report was generated.";
        break;

      case "created_api_key":
        key = "created_api_key"
        color = "success";
        icon = "vpn_key"; // Key icon for API key creation
        title = "API Key Created";
        description = "A new API key was created.";
        break;

      case "revoked_api_key":
        key = "revoked_api_key"
        color = "error";
        icon = "block"; // Block icon for revoked key
        title = "API Key Revoked";
        description = "An API key was revoked.";
        break;

      case "deleted_api_key":
        key = "deleted_api_key"
        color = "error";
        icon = "delete"; // Delete icon for deleted key
        title = "API Key Deleted";
        description = "An API key was deleted.";
        break;

      case "revoked_all_user_api_keys":
        key = "revoked_all_user_api_keys"
        color = "error";
        icon = "warning"; // Warning icon for mass revocation
        title = "All User API Keys Revoked";
        description = "All API keys for a user were revoked.";
        break;

      case "created_deploy_key":
        key = "created_deploy_key"
        color = "success";
        icon = "vpn_key"; // Key icon for deploy key creation
        title = "Deploy Key Created";
        description = "A new deploy key was created.";
        break;

      case "revoked_deploy_key":
        key = "revoked_deploy_key"
        color = "error";
        icon = "block"; // Block icon for revoked key
        title = "Deploy Key Revoked";
        description = "A deploy key was revoked.";
        break;

      case "deleted_deploy_key":
        key = "deleted_deploy_key"
        color = "error";
        icon = "delete"; // Delete icon for deleted key
        title = "Deploy Key Deleted";
        description = "A deploy key was deleted.";
        break;

      case "revoked_deploy_keys":
        key = "revoked_deploy_keys"
        color = "error";
        icon = "warning"; // Warning icon for mass revocation
        title = "All Deploy Keys Revoked";
        description = "All deploy keys were revoked.";
        break;

      default:
        key = "unknown_event";
        color = "burgundy";
        icon = "info";
        title = "Unknown Event";
        description = "No description available.";
    }
    const badges = [item.properties.model_id, item.properties.key_type];
    const dateTime = new Date(item.properties.time).toLocaleString();

    return {
      color,
      key,
      icon,
      title,
      dateTime,
      description,
      badges,
    };
  });
}

export default transformDataToTimelineItem;
