import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useOptimiserMutation = () => {
  const { mutate: createOptimiserMutation } = useMutation((params) =>
    apiHandlers.batchOptimisation.createOptimiser(...params)
  );
  const { mutate: deleteOptimiserMutation } = useMutation((params) =>
    apiHandlers.batchOptimisation.deleteOptimiser(...params)
  );
  const { mutate: deleteOptimiserVersion } = useMutation((params) =>
    apiHandlers.batchOptimisation.deleteOptimiserVersion(...params)
  );
  const { mutate: resetOptimiserVersion } = useMutation((params) =>
    apiHandlers.batchOptimisation.resetOptimiserVersion(...params)
  );
  const { mutate: updateOptimiserVersion } = useMutation((params) =>
    apiHandlers.batchOptimisation.updateOptimiserVersion(...params)
  );
  const { mutate: createOptimiserVersion } = useMutation((params) =>
    apiHandlers.batchOptimisation.createOptimiserVersion(...params)
  );

  const { mutate: createBatchesMutation } = useMutation((params) =>
    apiHandlers.batchOptimisation.createBatches(...params)
  );

  return {
    deleteOptimiserMutation,
    createOptimiserMutation,
    deleteOptimiserVersion,
    resetOptimiserVersion,
    createOptimiserVersion,
    updateOptimiserVersion,
    createBatchesMutation,
  };
};
