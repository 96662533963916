import { useRef, useState } from "react";
import { Card, Grid, Icon, Modal } from "@mui/material";
import { useModel } from "hooks";
import { useXplainableController } from "context";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

import PropTypes from "prop-types";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XInput from "components/XInput";
import XButton from "components/XButton";
import colors from "assets/theme/base/colors";
import Collections from "./collections";

function CollectionModal({ onClose, open, handleSelectCollection }) {
  const { model_id, model_name } = useModel();
  const [inputValue, setInputValue] = useState("");

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="collection-modal-title"
      aria-describedby="collection-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          p: 4,
          minHeight: { lg: "30vh" },
          minWidth: { xs: "90%", lg: "80vw", xxxl: "40vw" },
        }}
      >
        <XBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: 3,
          }}
        >
          <XBox sx={{ mt: 2, mb: 1, width: "100%", textAlign: "center" }}>
            <XTypography variant="h3" fontWeight="bold">
              {model_name} Collections
            </XTypography>
          </XBox>
          <XBox sx={{ mb: 1, textAlign: "center" }}>
            <XTypography variant="body2" fontWeight="regular" color="secondary">
              Create collections of scenarios to plan, optimise, track, and monitor actions.
            </XTypography>
          </XBox>
        </XBox>
        <Grid item xs={12} sm={6} md={4} display="flex" sx={{ justifyContent: "center" }}>
          <XInput
            placeholder="Type here..."
            onChange={handleOnChange}
            value={inputValue}
            sx={{ width: { xs: "100%", sm: "310px" }, marginRight: { xs: 0, sm: "16px" } }}
            startAdornment={
              <Icon fontSize="small" style={{ marginRight: "6px" }}>
                search
              </Icon>
            }
          />
        </Grid>
        <XBox sx={{ mt: 2 }}>
          <Collections handleSelectCollection={handleSelectCollection} inputValue={inputValue} />
        </XBox>
      </Card>
    </Modal>
  );
}

export default CollectionModal;

// Typechecking props for the Feature Chart
CollectionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleSelectCollection: PropTypes.func,
};
