import React, { useRef, useEffect, useState, useContext } from "react";
import ModelContext from "context/ModelContext";
import XBox from "components/XBox";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

import { select, scaleLinear, max, min, brushY, axisLeft } from "d3";
import { event as d3event } from "d3-selection";
import { usePrevious } from "shared/models/ModelProfile/components/shared";
import rgba from "assets/theme/functions/rgba";

export const BrushChart = ({
  data,
  children,
  selectionRef,
  isSorted,
  collapsedWidth: propCollapsedWidth,
  height: propHeight,
  isBarChart = false,
  isCollapsed,
  plotType,
}) => {
  //Make the component modular to accept collapse value from parent if not passed through context
  const contextValue = useContext(ModelContext);

  const svgRef = useRef();
  const svgContainer = useRef(null); // The PARENT of the SVG

  const [selection, setSelection] = useState(
    selectionRef.current[0] === 0 ? [data?.length, 0] : selectionRef.current
  );
  const previousSelection = usePrevious(selection);

  useEffect(() => {
    if (!data) return;
    setSelection([data?.length, 0]);
  }, [data]);

  // State to track width and height of SVG Container
  const [width, setWidth] = useState(0);

  const height = propHeight || 600;

  const padding = 1; // adjust this to increase or decrease the padding

  useEffect(() => {
    const container = svgContainer.current;

    const handleResize = (entries) => {
      for (let entry of entries) {
        const newWidth = entry.contentRect.width;
        setWidth(newWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Observe the container element
    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      // Disconnect the observer when the component unmounts
      resizeObserver.disconnect();
    };
  }, [svgContainer]);

  // will be called initially and on every data change
  useEffect(() => {
    const svg = select(svgRef.current);

    const sortedData = isSorted && data ? data.sort((a, b) => a.value - b.value) : data;
    const reversedSortedData = [...sortedData].reverse();

    // scales
    const yScale = scaleLinear().domain([0, reversedSortedData.length]).range([height, 10]);

    const xScale = scaleLinear()
      .domain([
        min(reversedSortedData.map((d) => d.value)),
        max(reversedSortedData.map((d) => d.value)),
      ])
      .range([10, width]);

    // render the bars
    svg
      .selectAll(".brush-bar")
      .data(reversedSortedData)
      .join("rect")
      .attr("class", "brush-bar")
      .attr("fill", (d, index) => {
        const isInRange =
          selection && index >= selection[1].toFixed(0) && index <= selection[0].toFixed(0);

        const isTotal = d.keyName === "Total";
        const isBaseValue = d.keyName === "Base Value";
        const isSelected = d.checked;

        if (isInRange) {
          return isTotal
            ? colors.gradients.xpgreen.main
            : isBaseValue || isSelected
            ? rgba("#7d7c87", 0.4)
            : (isBarChart && d.start > d.end) || (!isBarChart && d.start < d.end)
            ? rgba(plotType === "bar" ? colors.xppink.main : colors.xpblue.main, 0.4)
            : rgba(plotType === "bar" ? colors.xpblue.main : colors.xppink.main, 0.4);
        }

        return colors.light.main;
      })

      .attr("y", (d, index) => yScale(index) - 32 || 0)
      .attr("x", 5)
      .attr("height", height / reversedSortedData.length - 5 * padding) // subtract twice the padding from the height
      .attr("width", (d) => 20 || 0) //||xScale(d.value) || 0)
      .attr("rx", 5) // Add this line for x-axis corner radius
      .attr("ry", 5); // Add this line for y-axis corner radius

    const yAxis = axisLeft(yScale).tickSize(0);

    svg.select(".y-axis").call(yAxis).select(".domain").attr("stroke", "none"); // hides the domain line;

    const brush = brushY()
      .extent([
        [0, 0],
        [30, height],
      ])
      .on("start brush end", function () {
        const event = d3event;
        const indexSelection = event.selection.map(yScale.invert);

        if (indexSelection[0] === 0 && indexSelection[1] === reversedSortedData.length - 1) {
          setSelection([0, reversedSortedData.length - 1]);
          selectionRef.current = [0, reversedSortedData.length - 1];
          return;
        }

        if (event.selection) {
          setSelection(indexSelection);
          selectionRef.current = indexSelection;
        }
      });

    // initial position + retaining position on resize
    // Code to apply styles to the brush
    svg
      .select(".brush")
      .call(brush)
      .selectAll(".selection")
      .style("stroke", rgba(colors.secondary.main, 0.8)) // Change the stroke color of the selection
      .style("fill", colors.light.main) // Change the fill color of the selection
      .style("fill-opacity", 0.2); // Change the fill opacity of the selection

    svg
      .select(".brush")
      .call(brush)
      .selectAll(".handle")
      .style("fill", rgba(colors.secondary.main, 0.8)) // Change the fill color of the handles
      .style("stroke", rgba(colors.secondary.main, 0.8)) // Change the stroke color of the handles
      .style("stroke-width", "1.5px"); // Change the stroke width of the handles

    if (previousSelection === selection) {
      if (selection) {
        svg.select(".brush").call(brush).call(brush.move, selection.map(yScale));
      }
    }
  }, [data, previousSelection, selection, width, height, isCollapsed]);

  return (
    <XBox display="flex" ref={svgContainer} mt={3}>
      <XBox width={width} flexGrow={1}>
        {children(selection, svgContainer)}
      </XBox>
      <XBox width="40px" sx={{ marginTop: "10px", position: "relative", pr: 5 }}>
        <svg ref={svgRef} width="30px" height={height}>
          <g className="brush" />
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </XBox>
    </XBox>
  );
};

BrushChart.propTypes = {
  data: PropTypes.array,
  children: PropTypes.element,
  selectionRef: PropTypes.object,
  isSorted: PropTypes.bool,
  collapsedWidth: PropTypes.bool,
  height: PropTypes.number,
  isBarChart: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  plotType: PropTypes.string,
};
