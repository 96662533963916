import React, { useEffect, useState } from "react";
import { Divider, Icon } from "@mui/material";

import XBox from "components/XBox";
import XButton from "components/XButton";
import XTypography from "components/XTypography";

import DataTable from "shared/Tables/DataTable";
import PropTypes from "prop-types";
import TableCellComponent from "layouts/batchOptimisation/AllBatchOptimisations/components/TableCellComponent";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";

import { useOptimiserVersionsQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getTimeDifference } from "shared/Functions/Date";
import { useOptimiserMutation } from "api/mutations";
import { useAuth0 } from "@auth0/auth0-react";

export const OptimisationsVersions = ({ setActiveStep, setVersionId }) => {
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();
  const { id } = useParams();

  const url = new URL(window.location.href);
  const datasetId = url.searchParams.get("dataset_id");
  const location = useLocation();
  const navigate = useNavigate();

  const [optimisationsVersions, setOptimisationsVersions] = useState([]);
  const { createOptimiserVersion } = useOptimiserMutation();

  const { data, isLoading } = useOptimiserVersionsQuery(activeWorkspace, id, logout);
  const { deleteOptimiserVersion } = useOptimiserMutation();

  useEffect(() => {
    if (!data) return;

    setOptimisationsVersions(data.data);
  }, [data]);

  const handleDeleteOptimiser = (_, props) => {
    deleteOptimiserVersion(
      [activeWorkspace, props.row.original.optimiser_id, props.row.original.version_id],
      {
        onSuccess: () => {
          const filteredOptimisationsVersions = optimisationsVersions.filter(
            (item) => item.version_id !== props.row.original.version_id
          );
          setOptimisationsVersions(filteredOptimisationsVersions);
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };

  const columns = [
    {
      Header: "Name",
      accessor: "optimisations_version_name",
      id: "optimisations_version_name",
      Cell: (props) => {
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            <XTypography fontWeight="regular" fontSize="12px">
              {props.row.original.description}
            </XTypography>
          </XBox>
        );
      },
    },
    {
      Header: "Last Update",
      accessor: "optimisations_version_last_update",
      id: "optimisations_version_last_update",
      Cell: (props) => {
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            <XTypography fontSize="12px" color="secondary">
              {props.row.original.created
                ? getTimeDifference(props.row.original.created, true)
                : null}
            </XTypography>
          </XBox>
        );
      },
    },
    {
      Header: "Batches",
      accessor: "optimisations_version_batches",
      id: "optimisations_version_batches",
      Cell: (props) => {
        return (
          <XBox display="flex" gap="0 10px" alignItems="center">
            <XTypography fontSize="12px" color="secondary">
              {props.row.original.version_number}
            </XTypography>
          </XBox>
        );
      },
    },
    {
      Header: "",
      width: "5%",
      id: "function",
      Cell: (props) => <TableCellComponent {...props} handleDelete={handleDeleteOptimiser} />,
    },
  ];

  const handleRowClick = (e) => {
    const versionId = e.original.version_id;
    setVersionId(versionId);

    if (datasetId) {
      navigate(`${location.pathname}?dataset_id=${datasetId}&version_id=${versionId}`);
      setActiveStep(1);
      return;
    }

    navigate(`${location.pathname}?version_id=${versionId}`);
    setActiveStep(1);
  };

  const handleCreateVersion = () => {
    const data = { description: "test" };
    createOptimiserVersion([activeWorkspace, id, data], {
      onSuccess: (data) => {
        console.log("success", data);
        setVersionId(data.data.version_id);

        if (datasetId) {
          navigate(
            `${location.pathname}?dataset_id=${datasetId}&version_id=${data.data.version_id}`
          );
          setActiveStep(1);
          return;
        }

        navigate(`${location.pathname}?version_id=${data.data.version_id}`);
        setActiveStep(1);
      },
    });
  };

  if (isLoading) {
    return (
      <XBox sx={{ minHeight: "500px" }} display="flex" alignItems="center" justifyContent="center">
        <LoadingSpinner size={50} animationType="pulse" />
      </XBox>
    );
  }

  if (optimisationsVersions.length === 0) {
    return (
      <XBox p={4}>
        <XTypography fontSize="18px" variant="button">
          Optimisation Configuration
        </XTypography>
        <XTypography fontSize="14px" color="secondary" fontWeight="medium">
          Configure the optimiser
        </XTypography>

        <XBox
          sx={{
            minHeight: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Divider />
          <XBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <XTypography
              fontSize="14px"
              color="secondary"
              fontWeight="regular"
              sx={{ marginBottom: "21px" }}
            >
              No versions. Creates one to get started
            </XTypography>
            <XButton
              size="small"
              color="button"
              variant="gradient"
              sx={{ maxWidth: "125px" }}
              onClick={handleCreateVersion}
            >
              {/* <XBox component="img" src={LightPlusIcon} /> */}
              <XTypography color="white" fontSize="12px" ml={0.1} sx={{ whiteSpace: "nowrap" }}>
                Create Version
              </XTypography>
            </XButton>
          </XBox>
          <Divider />
        </XBox>
      </XBox>
    );
  }

  return (
    <XBox p={3} sx={{ maxWidth: "450px" }}>
      <XBox display="flex" alignItems="center">
        <XBox>
          <XTypography fontSize="18px" variant="button">
            Optimisation Configuration
          </XTypography>
          <XTypography fontSize="16px" color="secondary" fontWeight="light">
            Configure the optimiser
          </XTypography>
        </XBox>
      </XBox>
      <XBox display="flex" justifyContent="center" mt={1}>
        <XButton
          size="small"
          color="button"
          variant="gradient"
          sx={{ maxWidth: "125px", alignSelf: "end" }}
          onClick={handleCreateVersion}
        >
          {/* <XBox component="img" src={LightPlusIcon} /> */}
          <XTypography color="white" fontSize="12px" ml={0.1} sx={{ whiteSpace: "nowrap" }}>
            Create Version
          </XTypography>
        </XButton>
      </XBox>
      <XBox
        sx={{
          minHeight: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <DataTable
          table={{ rows: optimisationsVersions, columns: columns }}
          entriesPerPage={{
            defaultValue: 5,
            entries: [5, 7, 10, 15, 20, 25],
          }}
          handleRowClick={handleRowClick}
          // eventComponent={<ReportModal />}
        />
      </XBox>
    </XBox>
  );
};

OptimisationsVersions.propTypes = {
  setActiveStep: PropTypes.func,
  value: PropTypes.number,
  row: PropTypes.number,
  setVersionId: PropTypes.func,
};
