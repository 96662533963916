import React from "react";
import { Grid } from "@mui/material";
import Shimmer from "../../ShimmerEffect";

import PropTypes from "prop-types";

const MockFeatureChart = ({ tabsCount = 10 }) => {
  // Initialize starting width and accumulate
  let accumulatedWidth = 100;

  return (
    <Grid container spacing={2} height="100%" p={1}>
      {[...Array(tabsCount)].map((_, i) => {
        // For the last item, set width to accumulated value
        if (i === 10) {
          accumulatedWidth = 100; // Set to 100% for the last item
        } else {
          // Randomly add between 5% and 15% to the accumulatedWidth
          accumulatedWidth -= Math.floor(Math.random() * 10) + 5;
          accumulatedWidth = Math.min(accumulatedWidth, 100); // Cap at 100%
        }

        return (
          <Grid key={i} item xs={12} container alignItems="center" spacing={1}>
            <Grid item xs={2} height={"100%"}>
              <Shimmer height={"100%"} sx={{ my: 1, borderRadius: "5px" }} />
            </Grid>
            <Grid item xs={10} height={"100%"} display="flex">
              <Shimmer
                width={`${accumulatedWidth}%`}
                height={"100%"}
                sx={{ my: 1, borderRadius: "5px" }}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MockFeatureChart;

MockFeatureChart.propTypes = {
  tabsCount: PropTypes.number,
};
