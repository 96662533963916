// xplainable dashboard base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// xplainable dashboard helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { grey } = colors;
const { borderRadius } = borders;

const tabs = {
  styleOverrides: {
    root: {
      position: "relative",
      borderRadius: borderRadius.lg,
      minHeight: "unset",
      backgroundColor: "transparent",
      padding: pxToRem(4),
    },
    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
      gap: "24px",
    },
    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },
    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },
  },
};

export default tabs;
