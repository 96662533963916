import React from 'react';
import { Grid } from '@mui/material';
import { useApiKey } from 'components/Authorisation/ApiKeyContext';
import XBox from 'components/XBox';
import ProgressLineChart from 'shared/Charts/ProgressLineChart';
import MiniStatisticsCard from 'shared/Cards/MiniStatisticsCard';
import { TeamUsers } from '../TeamUsers';

function Admin() {
  const { activeWorkspace, workspaces } = useApiKey();
  return (
    <>
      <Grid container spacing={3} mt={3} mb={3}>
        <Grid item xs={12} lg={9}>
          <XBox>
            <ProgressLineChart
              color={"xpblue"}
              icon="groups"
              title="Total Users"
              height={"200px"}
              event={["new_login"]}
              organisation_id={activeWorkspace?.organisation_id}
            />
          </XBox>
        </Grid>
        <Grid item xs={12} lg={3} display="flex">
          <XBox display="flex" flexDirection="column" gap={4} width="100%">
            <MiniStatisticsCard
              title={{ text: "Organisations", fontWeight: "medium" }}
              count={workspaces?.length}
              color={"xpblue"}
            />
            <MiniStatisticsCard
              title={{ text: "Teams", fontWeight: "medium" }}
              count={workspaces?.reduce((total, org) => total + org.teams.length, 0)}
              color={"xpblue"}
            />
          </XBox>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12}>
          <TeamUsers organisationId={activeWorkspace?.organisation_id}/>
        </Grid>
      </Grid>
    </>
  );
}

export default Admin;
