import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// MUI material components
import { AppBar, Card, Icon, Modal, Tab, Tabs, Tooltip } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

// Custom components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import InfiniteScroll from "react-infinite-scroll-component";
import { ChatbotComment, Comment, MentionInput } from "./components";
import { useComments } from "./hooks";
import { useXplainableController } from "context";
import { EXPLAIN_DATA } from "./constants";

// Assets and styles
import ErrorChecklistIcon from "assets/images/chatbot.png";
import "./style.css";
import colors from "assets/theme/base/colors";

const CommentSkeleton = () => (
  <XBox px={2} >
    <XBox display="flex" gap={2}>
      <Skeleton variant="circular" width={40} height={40} />
      <XBox width="100%">
        <XBox display="flex" justifyContent="space-between" mb={1}>
          <Skeleton variant="text" width={120} height={24} />
          <Skeleton variant="text" width={80} height={24} />
        </XBox>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="80%" />
        

      </XBox>
    </XBox>
  </XBox>
);

export const Comments = ({
  type,
  modelId,
  deploymentId,
  collectionId,
  versionId,
  tabValue,
  setTabValue,
  setSelectedVersion,
  version,
}) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const [commentTabValue, setCommentTabValue] = useState(0);
  const [fetchedChatBotsComments, setFetchedChatBotsComments] = useState([]);
  const [pinnedChatBotsComments, setPinnedChatBotsComments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const suggestions = [
    "What do true positive, true negative, false positive, and false negative mean?",
    "What is a confusion matrix?",
    "Explain",
    "Why is the confusion matrix important?",
  ];

  useEffect(() => {
    setFetchedChatBotsComments(EXPLAIN_DATA);
  }, []);

  useEffect(() => {
    if (!fetchedChatBotsComments) return;

    const pinnedComments = fetchedChatBotsComments.filter((item) => item.isPinned);
    setPinnedChatBotsComments(pinnedComments);
  }, [fetchedChatBotsComments]);

  const {
    currentUser,
    handleAddComment,
    fetchedComments,
    id,
    setFetchedComments,
    tabMap,
    commentValue,
    setCommentValue,
    fetchUsers,
    fetchMoreComments,
    hasMoreComments,
    isLoading
  } = useComments({ modelId, deploymentId, collectionId, versionId, type, tabValue, version });

  const handleCommentTabValue = (event, newValue) => setCommentTabValue(newValue);

  return (
    <Card
      sx={{
        background: darkMode ? "#262525" : "#fefefe",
        borderRadius: "16px",
        overflow: "unset",
      }}
    >
      {/* Modal for pinned chatbot comments */}
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <XTypography fontSize="16px" fontWeight="bold">
              {`Pinned (${pinnedChatBotsComments?.length})`}
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: darkMode ? white.main : dark.main,
                stroke: darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>
          <XBox
            mt={4}
            id="scrollableDiv"
            px={2}
            style={{
              height: "100%",
              minHeight: "50vh",
              maxHeight: "50vh",
              overflow: "auto",
            }}
          >
            {pinnedChatBotsComments &&
              pinnedChatBotsComments.map((comment, index) => (
                <ChatbotComment
                  comment={comment}
                  key={index}
                  commentKey={index}
                  currentUser={currentUser}
                  type={type}
                  id={id}
                  setFetchedComments={setFetchedComments}
                  fetchedComments={fetchedComments}
                  tabMap={tabMap}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  setSelectedVersion={setSelectedVersion}
                  setFetchedChatBotsComments={setFetchedChatBotsComments}
                  fetchedChatBotsComments={fetchedChatBotsComments}
                />
              ))}
          </XBox>
        </XBox>
      </Modal>

      <XBox width="100%">
        <XBox
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "flex-end", md: "space-between" }}
          alignItems="center"
          width="100%"
          flexShrink={0}
          p={2}
        >
          <AppBar position="static">
            <Tabs
              value={commentTabValue}
              onChange={handleCommentTabValue}
              sx={{
                backgroundColor: `${darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,
                transition: "all 500ms ease",
                color: "#AFAFAF",
                "& .Mui-selected": {
                  fontWeight: "600",
                  color: `${darkMode ? "white" : "black"} !important`,
                  backgroundColor: `${darkMode ? "#262525" : "white"} !important`,
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab label="Comment" sx={{ minWidth: 0, fontSize: "0.8rem" }} />
              <Tooltip title="Chat interface coming soon.">
                <Tab label="Explain" sx={{ minWidth: 0, fontSize: "0.8rem" }} disabled />
              </Tooltip>
            </Tabs>
          </AppBar>
        </XBox>

        {commentTabValue === 0 && (
          <>
            <XBox
              id="scrollableDiv"
              px={2}
              style={{
                height: "100%",
                maxHeight: "50vh",
                overflow: "auto",
              }}
            >
              {isLoading ? (
                <>
                  <CommentSkeleton />
                </>
              ) : (
                <InfiniteScroll
                  dataLength={fetchedComments.length}
                  next={fetchMoreComments}
                  hasMore={hasMoreComments}
                  loader={<CommentSkeleton />}
                  scrollableTarget="scrollableDiv"
                >
                  {fetchedComments.map((comment, index) => (
                    <Comment
                      comment={comment}
                      key={index}
                      commentKey={index}
                      currentUser={currentUser}
                      type={type}
                      id={id}
                      setFetchedComments={setFetchedComments}
                      fetchedComments={fetchedComments}
                      tabMap={tabMap}
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      setSelectedVersion={setSelectedVersion}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </XBox>
            <XBox display="flex" alignItems="center" mt={3} width="100%" pb={2}>
              <XBox
                mx="auto"
                sx={{
                  position: "relative",
                  flexGrow: 1,
                  maxWidth: "85%",
                  alignSelf: "center",
                  width: "100%",
                }}
              >
                <MentionInput
                  setMentionValue={setCommentValue}
                  handleSubmit={handleAddComment}
                  data={fetchUsers}
                  mentionValue={commentValue}
                />
              </XBox>
            </XBox>
          </>
        )}

        {commentTabValue === 1 && (
          <>
            {fetchedChatBotsComments.length === 0 ? (
              <XBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: "35vh" }}
              >
                <XBox component="img" src={ErrorChecklistIcon} width="100px" />
              </XBox>
            ) : (
              <>
                <XBox px={2} mt={1} mb={2}>
                  <XBox
                    display="flex"
                    justifyContent="space-between"
                    p="12px"
                    sx={{ border: "1px solid #EAEAEA", borderRadius: "8px" }}
                  >
                    <XTypography fontSize="14px" fontWeight="medium">
                      {`Pinned (${pinnedChatBotsComments.length})`}
                    </XTypography>
                    <XTypography
                      fontSize="14px"
                      sx={{ cursor: "pointer", color: "#0080EA" }}
                      onClick={() => setIsModalOpen(true)}
                    >
                      See all
                    </XTypography>
                  </XBox>
                </XBox>
                <XBox
                  id="scrollableDiv"
                  px={2}
                  style={{
                    height: "100%",
                    minHeight: "50vh",
                    maxHeight: "50vh",
                    overflow: "auto",
                  }}
                >
                  {fetchedChatBotsComments.map((comment, index) => (
                    <ChatbotComment
                      comment={comment}
                      key={index}
                      commentKey={index}
                      currentUser={currentUser}
                      type={type}
                      id={id}
                      setFetchedComments={setFetchedComments}
                      fetchedComments={fetchedComments}
                      tabMap={tabMap}
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      setSelectedVersion={setSelectedVersion}
                      setFetchedChatBotsComments={setFetchedChatBotsComments}
                      fetchedChatBotsComments={fetchedChatBotsComments}
                    />
                  ))}
                </XBox>
              </>
            )}

            <XBox display="flex" sx={{ flexWrap: "wrap" }} gap="12px" mt={2} p={2}>
              {suggestions.map((item, index) => (
                <XBox
                  key={index}
                  px="10px"
                  py="6px"
                  sx={{ border: "1px solid #DE4188", borderRadius: "12px", cursor: "pointer" }}
                  onClick={() => setCommentValue(item)}
                >
                  <XTypography sx={{ color: "#DE4188", fontSize: "14px" }}>{item}</XTypography>
                </XBox>
              ))}
            </XBox>

            <XBox display="flex" alignItems="center" mt={1} width="100%" pb={2}>
              <XBox
                mx="auto"
                sx={{
                  position: "relative",
                  flexGrow: 1,
                  maxWidth: "85%",
                  alignSelf: "center",
                  width: "100%",
                }}
              >
                <MentionInput
                  setMentionValue={setCommentValue}
                  handleSubmit={handleAddComment}
                  data={fetchUsers}
                  mentionValue={commentValue}
                />
              </XBox>
            </XBox>
          </>
        )}
      </XBox>
    </Card>
  );
};

Comments.propTypes = {
  type: PropTypes.string,
  modelId: PropTypes.string,
  deploymentId: PropTypes.string,
  collectionId: PropTypes.string,
  versionId: PropTypes.string,
  versionNumber: PropTypes.string,
  tabValue: PropTypes.number,
  setTabValue: PropTypes.func,
  setSelectedVersion: PropTypes.func,
  version: PropTypes.number,
};

