import { useState } from "react";
import { useCallback } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid, Icon } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XAvatar from "components/XAvatar";
import XInput from "components/XInput";
import { useDropzone } from "react-dropzone";

// NewUser page components
import FormField from "../FormField";
import { XDivider } from "components/XDivider";

import ImagePickerIcon from "assets/images/image-picker-button.svg";

function UserInfo({ formData, setAvatar }) {
  const [avatarDataUrl, setAvatarDataUrl] = useState(null);
  const { formField, values, errors, touched } = formData;
  const { userName, firstName, lastName, city, country, position, email } = formField;
  const {
    userName: userNameV,
    firstName: firstNameV,
    lastName: lastNameV,
    city: cityV,
    country: countryV,
    position: positionV,
    email: emailV,
  } = values;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file && file.size <= 2097152) {
        // 2MB in bytes
        const reader = new FileReader();
        reader.onloadend = () => {
          setAvatarDataUrl(reader.result); // Set the DataURL for the avatar
          setAvatar(file); // Set the actual file for upload
        };
        reader.readAsDataURL(file);
      } else if (file && file.size > 2097152) {
        alert("The selected file is too large. Please select a file less than 2MB.");
      }
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
    maxSize: 2097152,
  });

  return (
    <XBox>
      <XBox
        width={"100%"}
        lineHeight={0}
        display="flex"
        height={"100%"}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb={2}
        p={2}
        overflow="hidden"
      >
        <XTypography variant="h5" fontWeight="bold" fontSize="24px">
          About me
        </XTypography>
        <XTypography variant="button" fontWeight="regular" color="text">
          {"Let's start with some information about yourself!"}
        </XTypography>
      </XBox>
      <XBox display="flex" flexDirection={{ xs: "column", sm: "row" }} mt="15px">
        <Grid item xs={12} lg={5}>
          <XBox display="flex" height={"100%"} flexDirection="column">
            {!avatarDataUrl ? (
              <XBox
                {...getRootProps()}
                sx={{
                  height: "230px",
                  width: "230px",
                  border: "1px dashed #AFAFAF",
                  borderRadius: "12px",
                  display: "flex",
                  padding: "24px",
                  flexDirection: "column",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input {...getInputProps()} />

                <XTypography variant="caption" style={{ fontSize: "14px" }}>
                  Drag and drop image
                </XTypography>
                <XBox display="flex" gap={2} alignItems="center" width="100%">
                  <XDivider />
                  <XTypography style={{ color: "#EAEAEA", fontSize: "14px" }} variant="caption">
                    or
                  </XTypography>
                  <XDivider />
                </XBox>
                <XBox src={ImagePickerIcon} component="img" />
              </XBox>
            ) : (
              <XAvatar
                src={avatarDataUrl}
                alt="profile picture"
                size="xxl"
                variant="rounded"
                sx={{ mt: "15px" }}
              />
            )}
          </XBox>
        </Grid>
        <Grid item xs={12}>
          <XBox>
            <XBox mt={1.625}>
              <Grid container spacing={5} mb={2}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    as={"input"}
                    type={firstName.type}
                    name={firstName.name}
                    value={firstNameV}
                    placeholder={firstName.placeholder}
                    error={errors.firstName && touched.firstName}
                    success={firstNameV.length > 0 && !errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    as={"input"}
                    type={lastName.type}
                    name={lastName.name}
                    value={lastNameV}
                    placeholder={lastName.placeholder}
                    error={errors.lastName && touched.lastName}
                    success={lastNameV.length > 0 && !errors.lastName}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} mb={2}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    as={"input"}
                    type={city.type}
                    name={city.name}
                    value={cityV}
                    placeholder={city.placeholder}
                    error={errors.city && touched.city}
                    success={cityV.length > 0 && !errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    as={"input"}
                    type={country.type}
                    name={country.name}
                    value={countryV}
                    placeholder={country.placeholder}
                    error={errors.country && touched.country}
                    success={countryV.length > 0 && !errors.country}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} mb={2}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    as={"input"}
                    type={position.type}
                    name={position.name}
                    value={positionV}
                    placeholder={position.placeholder}
                    error={errors.position && errors.position}
                    success={positionV.length > 0 && !errors.position}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <XBox mb={1.5}>
                    <XInput value={emailV} color={"secondary"} disabled={true} />
                  </XBox>
                </Grid>
              </Grid>
              <Grid container spacing={5} mb={2}>
                <Grid item xs={12}>
                  <FormField
                    as={"input"}
                    type={userName.type}
                    name={userName.name}
                    value={userNameV}
                    placeholder={userName.placeholder}
                    error={errors.userName && touched.userName}
                    success={userNameV.length > 0 && !errors.userName}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} mb={2}>
                <Grid item xs={12}>
                  <XBox mb={1.5}>
                    <XInput placeholder="Please enter a Bio..." multiline rows={5} />
                  </XBox>
                </Grid>
              </Grid>
            </XBox>
          </XBox>
        </Grid>
      </XBox>
    </XBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setAvatar: PropTypes.func,
};

export default UserInfo;
