// src/ApiKeyContext.js
import { createContext, useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";
// import { setApiKeyConfig } from "api";

export const ApiKeyContext = createContext();

export const useApiKey = () => {
  return useContext(ApiKeyContext);
};

export const ApiKeyProvider = ({ children }) => {
  const [apiKey, setApiKey] = useState(null);
  const [apiKeyChecked, setApiKeyChecked] = useState(false);
  const [user, setUser] = useState(null);
  const [preferences, setPreferences] = useState(null);
  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null)
  const [workspaces, setWorkspaces] = useState(null);
  const [requests, setRequests] = useState([]);
  const [invites, setInvites] = useState([]);
  const [viewState, setViewState] = useState("creator");
  const [checkEmail, setCheckEmail] = useState(false);

  useEffect(() => {
    const role = getRoleName(workspaces, activeWorkspace);

    if (role) {
      //This will also return admin but will update render logic
      setViewState(role === "viewer" ? "viewer" : "creator");
      
    }
  }, [activeWorkspace?.organisation_id, activeWorkspace?.user_id, activeWorkspace?.team_id]);

  return (
    <ApiKeyContext.Provider
      value={{
        apiKey,
        setApiKey,
        user,
        setUser,
        apiKeyChecked,
        setApiKeyChecked,
        preferences,
        setPreferences,
        activeWorkspace,
        setActiveWorkspace,
        activeSubscription,
        setActiveSubscription,
        workspaces,
        setWorkspaces,
        requests,
        setRequests,
        invites,
        setInvites,
        viewState,
        setViewState,
        checkEmail,
        setCheckEmail,
      }}
    >
      {children}
    </ApiKeyContext.Provider>
  );
};

ApiKeyProvider.propTypes = {
  children: PropTypes.any,
};

export const getRoleName = (workspaces, activeWorkspace) => {
  if (!workspaces || !Array.isArray(workspaces)) return null;

  // Find the organization using the organisation_id
  const organisation = workspaces.find(
    (org) => org.organisation_id === activeWorkspace?.organisation_id
  );

  if (!organisation || !Array.isArray(organisation.teams)) return null; // If organization not found or teams is not an array

  // Find the team using the team_id
  const team = organisation.teams.find((team) => team.team_id === activeWorkspace?.team_id);

  return team ? team.role_name : null; // If team is found, return role_name, otherwise null
};
