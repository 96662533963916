import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// xplainable Dashboard components
import XBox from "components/XBox";

// xplainable dashboard context
import { useXplainableController, setLayout } from "context";
import { useApp } from "hooks";

function DashboardLayout({ bgColor, children, ...rest }) {
  const [controller, dispatch] = useXplainableController();
  const { activeStep } = useApp();
  const { miniSidenav, darkMode } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  const background = darkMode && !bgColor ? "transparent" : bgColor;

  return (
    <XBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,

        [breakpoints.up("xl")]: {
          marginLeft: activeStep !== 1 ? (miniSidenav ? pxToRem(160) : pxToRem(312)) : 0,
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {/* Add this to make sure the max width so it doesnt flex*/}
      <XBox maxWidth="1430px" sx={{ marginLeft: "auto", marginRight: "auto" }}>
        {children}
      </XBox>
    </XBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
