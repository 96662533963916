import React, { useEffect, useState } from "react";
import { usePreviewQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Card, Icon } from "@mui/material";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import DataTable from "shared/Tables/DataTable";

import PropTypes from "prop-types";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { useAuth0 } from "@auth0/auth0-react";

import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";
import ChevronLeftIcon from "assets/images/icons/batch/chevron-left-icon.svg";
import XInput from "components/XInput";
import { useRunsMutation } from "api/mutations";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const Preview = ({ setActiveStep, dataset, versionId }) => {
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const datasetId = url.searchParams.get("dataset_id");

  const [previewData, setPreviewData] = useState([]);
  const [batchesInformation, setBatchesInformation] = useState({ name: "", description: "" });

  const { data, isLoading } = usePreviewQuery(activeWorkspace, datasetId, logout);
  const { createRunsMutation } = useRunsMutation();

  const currentDataset = dataset.find((item) => item.dataset_id === datasetId);
  const megabytes = currentDataset.metadata.upload_file_size / (1024 * 1024);

  const cards = [
    {
      title: "Rows",
      value: currentDataset.metadata.rows,
    },
    {
      title: "Columns",
      value: currentDataset.metadata.columns,
    },
    {
      title: "Size",
      value: `${megabytes.toFixed(2)}MB`,
    },
  ];

  const columns = [
    {
      Header: "Customer ID",
      accessor: "customer_id",
      id: "customer_id",
      Cell: (props) => {
        return (
          <XTypography fontSize="12px" textTransform="capitalize" fontWeight="medium">
            {props.row.original.CustomerID}
          </XTypography>
        );
      },
    },
    {
      Header: "Count",
      accessor: "count",
      id: "count",
      Cell: (props) => {
        return (
          <XTypography fontSize="12px" textTransform="capitalize" fontWeight="medium">
            {props.row.original.Count}
          </XTypography>
        );
      },
    },
    {
      Header: "Country",
      accessor: "country",
      id: "country",
      Cell: (props) => {
        return (
          <XTypography fontSize="12px" textTransform="capitalize" fontWeight="medium">
            {props.row.original.Country}
          </XTypography>
        );
      },
    },
    {
      Header: "State",
      accessor: "state",
      id: "state",
      Cell: (props) => {
        return (
          <XTypography fontSize="12px" textTransform="capitalize" fontWeight="medium">
            {props.row.original.State}
          </XTypography>
        );
      },
    },
    {
      Header: "City",
      accessor: "city",
      id: "city",
      Cell: (props) => {
        return (
          <XTypography fontSize="12px" textTransform="capitalize" fontWeight="medium">
            {props.row.original.City}
          </XTypography>
        );
      },
    },
    {
      Header: "Zip Code",
      accessor: "zip_code",
      id: "zip_code",
      Cell: (props) => {
        return (
          <XTypography fontSize="12px" textTransform="capitalize" fontWeight="medium">
            {props.row.original["Zip Code"]}
          </XTypography>
        );
      },
    },
    {
      Header: "Lat Long",
      accessor: "lat_long",
      id: "lat_long",
      Cell: (props) => {
        return (
          <XTypography fontSize="12px" textTransform="capitalize" fontWeight="medium">
            {props.row.original["Lat Long"]}
          </XTypography>
        );
      },
    },
    {
      Header: "Latitude",
      accessor: "latitude",
      id: "latitude",
      Cell: (props) => {
        return (
          <XTypography fontSize="12px" textTransform="capitalize" fontWeight="medium">
            {props.row.original["Latitude"]}
          </XTypography>
        );
      },
    },
  ];

  useEffect(() => {
    if (!data) return;

    setPreviewData(data.data);
  }, [data]);

  const handleBackButton = () => {
    setActiveStep(1);
  };

  const handleCreateRunButton = () => {
    const data = {
      run_name: batchesInformation.name,
      run_description: batchesInformation.description,
      dataset_id: datasetId,
    };
    createRunsMutation([activeWorkspace, params.id, versionId, data], {
      onSuccess: (data) => {
        navigate(`${location.pathname}${url.search}&&run_id=${data.data.run_id}`, {
          state: { run_id: data.data.run_id },
        });
        setActiveStep(4);
      },
    });
  };

  if (isLoading) {
    return (
      <Card
        sx={{ minHeight: "500px", display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoadingSpinner size={50} animationType="pulse" />
      </Card>
    );
  }
  return (
    <Card height={"100%"} mt={10} pb={{ xs: 0, lg: 10 }}>
      <XBox>
        <XBox display="flex" flexDirection="column" gap="10px">
          <XButton
            variant="outlined"
            sx={{
              padding: 0,
              alignSelf: "start",
              minHeight: "20px",
              background: "transparent",
              border: "none",
              marginTop: "8px",
              marginLeft: "8px",
            }}
            onClick={handleBackButton}
          >
            <XBox display="flex" gap="4px" alignItems="center">
              <XBox src={ChevronLeftIcon} component="img" />
              <XTypography color="xpblue" variant="button">
                Back
              </XTypography>
            </XBox>
          </XButton>
          <XBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
            sx={{ padding: "0 20px" }}
          >
            <XTypography fontSize="16px">{currentDataset.dataset_name}</XTypography>
            <XButton
              color="button"
              variant="gradient"
              onClick={handleCreateRunButton}
              sx={{ whiteSpace: "noWrap" }}
              disabled={!versionId}
            >
              {/* <XBox component="img" src={LightPlusIcon} /> */}
              Create Run
            </XButton>
          </XBox>
          <XBox display="flex" width="100%" px="20px" gap={2}>
            <XInput
              placeholder="eg. March 2023"
              value={batchesInformation.name}
              sx={{ width: "100%" }}
              onChange={(event) => {
                setBatchesInformation({
                  ...batchesInformation,
                  name: event.target.value,
                });
              }}
            />
            <XInput
              placeholder="eg. March 2023"
              value={batchesInformation.description}
              sx={{ width: "100%" }}
              onChange={(event) => {
                setBatchesInformation({
                  ...batchesInformation,
                  description: event.target.value,
                });
              }}
            />
          </XBox>
          <XBox display="flex" gap="10px" sx={{ padding: "0 20px" }} width="100%" mt={2}>
            {cards.map((item, index) => (
              <Card
                key={index}
                sx={{
                  padding: "10px 20px",
                  width: "100%",
                  borderRadius: "25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <XTypography fontSize="18px" variant="button">
                  {item.title}
                </XTypography>
                <XTypography fontSize="24px" fontWeight="medium" secondary="24px" color="xpblue">
                  {item.value}
                </XTypography>
              </Card>
            ))}
          </XBox>
          <XBox sx={{ padding: "0 20px" }}>
            <DataTable
              table={{ rows: previewData, columns: columns }}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 7, 10, 15, 20, 25],
              }}
            />
          </XBox>
        </XBox>
      </XBox>
    </Card>
  );
};

Preview.propTypes = {
  setActiveStep: PropTypes.func,
  value: PropTypes.number,
  row: PropTypes.number,
  dataset: PropTypes.array,
  versionId: PropTypes.string,
};
