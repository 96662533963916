// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// xplainable Dashboard components
import XInput from "components/XInput";
import { Input } from "@mui/material";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import { useXplainableController } from "context";

function XDatePicker({ input, ...rest }) {
  const [controller] = useXplainableController();
  return (
    <Flatpickr
      {...rest}
      render={({ defaultValue }, ref) => (
        <Input
          sx={{
            px: 0,
            border: "none",
            width: "100%",
            maxWidth: "200px",
            input: {
              background: controller.darkMode ? rgba("#1D1B1B", 0.3) : rgba(colors.light.main, 0.3),
              color: controller.darkMode ? "#ffffff" : "black",
              borderRadius: "8px",
            },
            "&.MuiInput-underline:before": {
              border: "none", // Remove default underline
            },
            "&.MuiInput-underline:after": {
              border: "none", // Remove underline on focus
            },
            "&:hover:not(.Mui-disabled):before": {
              border: "none", // Remove underline on hover
            },
            "&:focus-within": {
              boxShadow: "none",
            },
          }}
          {...input}
          defaultValue={defaultValue}
          inputRef={ref}
        />
      )}
    />
  );
}

// Setting default values for the props of XDatePicker
XDatePicker.defaultProps = {
  input: {},
};

// Typechecking props for the XDatePicker
XDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default XDatePicker;
