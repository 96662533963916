import { useMemo, useEffect, useState } from "react";
import { useXplainableController } from "context";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import { useTheme } from "@emotion/react";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Skeleton from '@mui/material/Skeleton';

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XSelect from "components/XSelect";
import XInput from "components/XInput";
import XPagination from "components/XPagination";

// xplainable dashboard example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import colors from "assets/theme/base/colors";

import { ReactComponent as DarkArrowLeft } from "assets/images/icons/pagination/dark-arrow-left.svg";
import { ReactComponent as DarkArrowRight } from "assets/images/icons/pagination/dark-arrow-right.svg";
import { XImg } from "components/XImg";

function DataTable({
  handleRowClick,
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  eventComponent,
  isLoading,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns || [], [table]);
  const data = useMemo(() => table?.rows || [], [table]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [controller] = useXplainableController();

  const theme = useTheme();
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  const handleMenuOpen = (event, index) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setOpenMenuIndex(null);
  };

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <XPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </XPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const onRowClick = (row, e) => {
    if (
      handleRowClick &&
      e.target.tagName !== "LI" &&
      e.target.tagName !== "DIV" &&
      e.target.tagName !== "SPAN"
    ) {
      handleRowClick(row, e);
    }

    setSelectedRowId(row.id);
  };

  const renderSkeletonRows = () => {
    return Array(5).fill(0).map((_, index) => (
      <TableRow key={`skeleton-${index}`}>
        {columns.map((column, cellIndex) => (
          <DataTableBodyCell key={`skeleton-cell-${cellIndex}`}>
            <Skeleton animation="wave" width="100%" height={24} />
          </DataTableBodyCell>
        ))}
      </TableRow>
    ));
  };

  return (
    <>
      <XBox
        width={100}
        sx={{
          width: "100%",
          position: "relative",
          zIndex: 1,
          overflow: "visible",
        }}
      >
        {entriesPerPage || canSearch ? (
          <XBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            {entriesPerPage && (
              <XBox display="flex" alignItems="center">
                <XBox width="25%" gap="12px" display="flex">
                  <XSelect
                    defaultValue={{ value: defaultValue, label: defaultValue }}
                    options={entries.map((entry) => ({ value: entry, label: entry }))}
                    onChange={setEntriesPerPage}
                    size="small"
                  />
                </XBox>
                <XTypography variant="caption" sx={{ color: "#AFAFAF" }} fontSize="16px">
                  &nbsp;&nbsp;entries per page
                </XTypography>
              </XBox>
            )}
            {canSearch && (
              <XBox width="12rem" ml="auto">
                <XInput
                  placeholder="Search..."
                  value={search}
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </XBox>
            )}
            {eventComponent && eventComponent}
          </XBox>
        ) : null}
      </XBox>
      <TableContainer sx={{ boxShadow: "none", px: 2 }} id="table">
        <Table {...getTableProps()}>
          <XBox component="thead">
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <DataTableHeadCell
                    key={index}
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </XBox>
          <TableBody {...getTableBodyProps()}>
            {isLoading ? (
              renderSkeletonRows()
            ) : (
              page?.map((row, key) => {
                prepareRow(row);
                return (
                  <TableRow
                    key={key}
                    hover
                    {...row.getRowProps()}
                    onClick={(e) => onRowClick(row, e)}
                  >
                    {row.cells.map((cell, index) => (
                      <DataTableBodyCell
                        key={index}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell", {
                          handleMenuOpen,
                          handleMenuClose,
                          menuAnchorEl,
                          openMenuIndex,
                        })}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <XBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <XBox mb={{ xs: 3, sm: 0 }}>
            <XTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </XTypography>
          </XBox>
        )}
        {pageOptions.length > 1 && (
          <XPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "button"}
          >
            {canPreviousPage && (
              <XPagination item onClick={() => previousPage()}>
                <XImg>
                  <DarkArrowLeft />
                </XImg>
              </XPagination>
            )}
            {renderPagination.length > 6 ? (
              <XBox width="5rem" mx={1}>
                <XInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </XBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <XPagination item onClick={() => nextPage()}>
                <XImg>
                  <DarkArrowRight />
                </XImg>
              </XPagination>
            )}
          </XPagination>
        )}
      </XBox>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "button" },
  isSorted: true,
  noEndBorder: false,
  isLoading: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  handleRowClick: PropTypes.func,
  eventComponent: PropTypes.object,
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "button",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DataTable;
