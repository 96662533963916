import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { useRegressionPerformanceQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useModel } from "hooks";

import { useAuth0 } from "@auth0/auth0-react";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import ResidualChart from "layouts/models/model/components/performance/components/RegressionPerformance/components/ResidualChart2";

export const ReportResidualChart = () => {
  const { logout } = useAuth0();

  const { activeWorkspace } = useApiKey();
  const { selectedPartition, selectedVersion, model_id } = useModel();

  const { data } = useRegressionPerformanceQuery(
    activeWorkspace,
    model_id,
    selectedVersion?.value,
    selectedPartition?.value,
    logout
  );

  const [performanceData, setPerformanceData] = useState([]);
  const [errorData, setErrorData] = useState([]);

  const selectedDataset = { value: "train", label: "Train" };
  const sliderValue = useState(26);

  useEffect(() => {
    if (!data) return;
    setPerformanceData(data.data[0]);
  }, [data]);

  const filterAndSetData = (data) => {
    if (data) {
      const { observed_min, observed_max, prediction_bins } = data;

      const filterBinsByClass = (bins, className) =>
        bins?.filter((bin) => bin.class === className)[0].values;

      const formattedData = {
        min: observed_min,
        max: observed_max,
        bins: {
          true: filterBinsByClass(prediction_bins, "true"),
          pred: filterBinsByClass(prediction_bins, "pred"),
        },
      };

      setErrorData(formattedData);
    }
  };

  //   Fetch the performance data from the model endpoint

  useEffect(() => {
    if (selectedDataset.value === "validation") {
      filterAndSetData(performanceData?.evaluation?.validation);
      return;
    }

    if (selectedDataset.value === "train") {
      filterAndSetData(performanceData?.evaluation?.train);
      return;
    }
  }, [performanceData, selectedVersion, selectedPartition]);

  return (
    <Grid item lg={12}>
      <XBox pt={2} px={2} lineHeight={1}>
        <XTypography variant="h6" color="secondary">
          Predicted vs Actual (Histogram)
        </XTypography>
      </XBox>
      {errorData && <ResidualChart regressionBins={errorData} numBins={sliderValue} />}
    </Grid>
  );
};
