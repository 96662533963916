import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useToggleMutation = () => {
  const { mutate: deploymentToggleMutation } = useMutation((params) =>
    apiHandlers.toggle.toggleDeployment(...params)
  );

  const { mutate: modelToggleMutation } = useMutation((params) =>
    apiHandlers.toggle.toggleModel(...params)
  );

  return {
    deploymentToggleMutation,
    modelToggleMutation,
  };
};
