const form = {
  formId: "new-user-form",
  formField: {
    userName: {
      name: "userName",
      label: "user name",
      type: "text",
      placeholder: "XP-1234",
      errorMsg: "Please add a username.",
    },
    firstName: {
      name: "firstName",
      label: "first name",
      type: "text",
      placeholder: "eg. Micheal",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "lastName",
      label: "last name",
      type: "text",
      placeholder: "eg. Prior",
      errorMsg: "Last name is required.",
    },
    city: {
      name: "city",
      label: "city",
      type: "text",
      placeholder: "Auckland",
      errorMsg: "City is required.",
    },
    country: {
      name: "country",
      label: "country",
      type: "text",
      placeholder: "New Zealand",
      errorMsg: "Country is required.",
    },
    email: {
      name: "email",
      label: "email address",
      type: "email",
      placeholder: "eg. madeupemail@gmail.com",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    position: {
      name: "position",
      label: "position",
      type: "text",
      placeholder: "eg. Data Analyst",
      errorMsg: "Please enter your position title.",
      invalidMsg: "Please enter a position role",
    },
    bio: {
      name: "bio",
      label: "bio",
      placeholder: "Say a few words about who you are or what you're working on.",
    },
    survey: {
      name: "survey",
      label: "survey",
      placeholder: "Where have you heard about us?",
    },
  },
};

export default form;


// const form = {
//   formId: "new-user-form",
//   formField: {
//     userName: {
//       name: "userName",
//       label: "user name",
//       type: "text",
//       placeholder: "XP-1234",
//       errorMsg: "Please add a username.",
//     },
//     firstName: {
//       name: "firstName",
//       label: "first name",
//       type: "text",
//       placeholder: "eg. Micheal",
//       errorMsg: "First name is required.",
//     },
//     lastName: {
//       name: "lastName",
//       label: "last name",
//       type: "text",
//       placeholder: "eg. Prior",
//       errorMsg: "Last name is required.",
//     },
//     city: {
//       name: "city",
//       label: "city",
//       type: "text",
//       placeholder: "Auckland",
//       errorMsg: "City is required.",
//     },
//     country: {
//       name: "country",
//       label: "country",
//       type: "text",
//       placeholder: "New Zealand",
//       errorMsg: "Country is required.",
//     },
    // company: {
    //   name: "company",
    //   label: "company",
    //   type: "text",
    //   placeholder: "eg. xplainable",
    //   errorMsg: "The company name is required.",
    //   invalidMsg: "Your company name no. is invalid",
    // },
    // companyPhone: {
    //   name: "companyPhone",
    //   label: "company phone",
    //   type: "text",
    //   placeholder: "(07) 1234 5678",
    //   errorMsg: "Company Phone is required.",
    //   invalidMsg: "Your company phone no. is invalid",
    // },
    // companyCity: {
    //   name: "companyCity",
    //   label: "city",
    //   type: "text",
    //   placeholder: "New Zealand",
    //   errorMsg: "The city is required.",
    //   invalidMsg: "The city is invalid.",
    // },
    // companyCountry: {
    //   name: "companyCountry",
    //   label: "country",
    //   type: "text",
    //   placeholder: "Auckland",
    //   errorMsg: "The country is required.",
    //   invalidMsg: "The city is invalid.",
    // },
    // email: {
    //   name: "email",
    //   label: "email address",
    //   type: "email",
    //   placeholder: "eg. madeupemail@gmail.com",
    //   errorMsg: "Email address is required.",
    //   invalidMsg: "Your email address is invalid",
    // },
    // role: {
    //   name: "role",
    //   label: "role",
    //   type: "text",
    //   placeholder: "eg. Datascientist",
    //   errorMsg: "Please enter your role title.",
    //   invalidMsg: "Please enter a valid role",
    // },
    // password: {
    //   name: "password",
    //   label: "password",
    //   type: "password",
    //   placeholder: "******",
    //   errorMsg: "Password is required.",
    //   invalidMsg: "Your password should be more than 6 characters.",
    // },
    // repeatPassword: {
    //   name: "repeatPassword",
    //   label: "repeat password",
    //   type: "password",
    //   placeholder: "******",
    //   errorMsg: "Password is required.",
    //   invalidMsg: "Your password doesn't match.",
    // },
    // address1: {
    //   name: "address1",
    //   label: "address 1",
    //   type: "text",
    //   placeholder: "eg. Street 111",
    //   errorMsg: "Address is required.",
    // },
    // address2: {
    //   name: "address2",
    //   label: "address 2",
    //   type: "text",
    //   placeholder: "eg. Street 221",
    // },
    // zip: {
    //   name: "zip",
    //   label: "zip",
    //   type: "number",
    //   placeholder: "7 letters",
    //   errorMsg: "Zip is required.",
    //   invalidMsg: "Zipcode is not valie (e.g. 70000).",
    // },
    // twitter: {
    //   name: "twitter",
    //   label: "twitter handle",
    //   type: "text",
    //   placeholder: "@soft",
    //   errorMsg: "Twitter profile is required.",
    // },
    // facebook: {
    //   name: "facebook",
    //   label: "facebook account",
    //   type: "text",
    //   placeholder: "https://...",
    // },
    // instagram: {
    //   name: "instagram",
    //   label: "instagram account",
    //   type: "text",
    //   placeholder: "https://...",
    // },
    // publicEmail: {
    //   name: "publicEmail",
    //   label: "public email",
    //   type: "email",
    //   placeholder: "Use an address you don't use frequently",
    // },
//     bio: {
//       name: "bio",
//       label: "bio",
//       placeholder: "Say a few words about who you are or what you're working on.",
//     },
//     survey: {
//       name: "survey",
//       label: "survey",
//       placeholder: "Where have you heard about us?",
//     },
//   },
// };

// export default form;

