import { useAuth0 } from "@auth0/auth0-react";
import { useCommentsMutation } from "api/mutations";
import { useCommentsUsersQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useState } from "react";
import { debouncedSearch } from "utils";

export const useReplies = ({
  comment,
  tabValue,
  activeWorkspace,
  tabMap,
  fetchedComments,
  setFetchedComments,
  id,
  type,
}) => {
  const { comment_id, parent_id, model_id, version_id, version_number, component } = comment;
  const { logout } = useAuth0();
  const { user } = useApiKey();

  const { addCommentMutation } = useCommentsMutation();

  const [replyInputValue, setReplyInputValue] = useState("");

  const { refetch } = useCommentsUsersQuery({ activeWorkspace, logout });

  const fetchUsers = (query, callback) => {
    if (!query) {
      refetch().then(({ data }) => {
        const mentionData =
          data?.data?.map((user) => ({
            id: user.user_id,
            display: `${user.given_name} ${user.family_name}`,
          })) || [];

        callback([...mentionData, { id: "__all__", display: "all" }]);
        return;
      });
    } else {
      debouncedSearch(activeWorkspace, query, apiKey, (data) => {
        const mentionData =
          data?.map((user) => ({
            id: user.user_id,
            display: `${user.given_name} ${user.family_name}`,
          })) || [];

        callback([...mentionData, { id: "__all__", display: "all" }]);
      });
    }
  };

  const handleReplyInputChange = (e) => {
    setReplyInputValue(e.target.value);
  };

  const handleAddReply = () => {
    const regex = /#\(([^)]+)\)/g;
    const matches = replyInputValue.match(regex);

    const tagged_users = matches ? matches.map((match) => match.slice(2, -1)) : [];

    const resultString = replyInputValue.replace(/#\([^)]+\)/g, "");

    const data = JSON.stringify({
      comment: resultString,
      resource_id: id,
      resource_version_id: version_id,
      version_id: version_id,
      parent_id: comment_id,
      tagged_users: tagged_users,
      component: component,
    });

    const now = new Date();

    const commentValue = {
      comment_id: "",
      model_id: model_id,
      version_id: version_id,
      version_number: version_number,
      parent_id: parent_id,
      created: now,
      last_edited: now,
      comment: resultString,
      component: tabMap[tabValue],
      like_count: 0,
      user: {
        user_id: user?.user_id,
        username: user?.username,
        given_name: user?.given_name,
        family_name: user?.family_name,
        position: user?.position,
        image: user?.image,
      },
      replies: [],
    };

    const updatedCommentList = fetchedComments.map((comment) => {
      if (comment.comment_id === comment_id) {
        comment.replies.push(commentValue);
      }
      return comment;
    });

    setFetchedComments(updatedCommentList);
    setReplyInputValue("");

    addCommentMutation([data], {
      onSuccess: ({ data }) => {
        commentValue.comment_id = data.comment_id;

        setFetchedComments((prevComments) => [
          ...prevComments.filter((comment) => comment !== commentValue),
        ]);
        setReplyInputValue("");
      },
      onError: (err) => {
        setFetchedComments((prevComments) => [
          ...prevComments.filter((comment) => comment !== commentValue),
        ]);
        handleTokenExpired();
      },
    });
  };
  return {
    handleReplyInputChange,
    handleAddReply,
    replyInputValue,
    setReplyInputValue,
    user,
    fetchUsers,
  };
};
