import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useModelsMutation = () => {
  const { mutate: deleteModelMutation } = useMutation((params) =>
    apiHandlers.models.deleteModel(...params)
  );

  const { mutate: deleteModelVersionMutation } = useMutation((params) =>
    apiHandlers.models.deleteModelVersion(...params)
  );

  const { mutate: archiveModelMutation } = useMutation((params) =>
    apiHandlers.models.archiveModel(...params)
  );

  const { mutate: moveModelMutation } = useMutation((params) =>
    apiHandlers.models.moveModel(...params)
  );

  const { mutate: handleActiveVersionMutation } = useMutation((params) =>
    apiHandlers.models.handleActiveVersion(...params)
  );

  return {
    deleteModelMutation,
    archiveModelMutation,
    moveModelMutation,
    deleteModelVersionMutation,
    handleActiveVersionMutation,
  };
};
