import React from "react";
import XBox from "components/XBox";
import ComingSoon from "shared/Cards/ComingSoon";

const ModelSettings = () => {
  return (
    <>
      <XBox display="flex" width="100%">
        <ComingSoon
          title={"Model Settings"}
          caption={"No settings are available for Models just yet."}
        />
      </XBox>
    </>
  );
};

export default ModelSettings;
