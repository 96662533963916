import React from "react";
import { Tooltip, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useXplainableController } from "context";
import { useToast } from "hooks";

function EventIcon({ tooltipText, icon, successToast = true, onClick }) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const { showSuccessToast } = useToast();

  const handleClick = () => {
    if (successToast) {
      showSuccessToast("Item copied to clipboard"); // Display success toast
    }

    if (onClick) {
      onClick(); // Call the passed onClick function
    }
  };

  return (
    <Tooltip title={tooltipText}>
      <Box
        mx={1}
        display="flex"
        alignItems="center"
        onClick={handleClick}
        sx={{
          "& > svg": {
            // Target the child SVG directly
            fontSize: "24px",
            fill: darkMode ? "white" : "#0D0C0C",
          },
        }}
      >
        {React.cloneElement(icon, { fontSize: "inherit" })}
      </Box>
    </Tooltip>
  );
}

EventIcon.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired, // Since the icon will be a React element
  successToast: PropTypes.bool,
  onClick: PropTypes.func, // Define the prop type for onClick
};

export default EventIcon;
