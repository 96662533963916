import React from 'react';
import { Grid } from '@mui/material';
import Shimmer from '../../ShimmerEffect';

const MockArticle = () => {
    return (
        <Grid container direction="column" spacing={3} height="90%">
            {/* Title */}
            <Grid item xs={2}>
                <Shimmer height="100%" width="90%" sx={{ borderRadius: "5px" }} />
            </Grid>
            
            {/* Subtitle */}
            <Grid item xs={1}>
                <Shimmer height="100%" width="70%" sx={{ borderRadius: "5px" }} />
            </Grid>

            {/* Author and Date Section */}
            <Grid item container xs={3} spacing={2}>
                <Grid item xs={2}>
                    <Shimmer shape="circular" height="100%" width="100%" />
                </Grid>
                <Grid item xs={9}>
                    <Shimmer height="50%" width="40%" sx={{ borderRadius: "5px", mb: 1 }} />
                    <Shimmer height="50%" width="25%" sx={{ borderRadius: "5px" }} />
                </Grid>
            </Grid>

            {/* Edit and Publish Section */}
            <Grid item container xs={2} spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <Shimmer height="100%" width="60px" sx={{ borderRadius: "5px" }} />
                </Grid>
                <Grid item xs={6}>
                    <Shimmer height="100%" width="30px" sx={{ borderRadius: "5px" }} />
                </Grid>
            </Grid>

            {/* Introduction */}
            <Grid item xs={4}>
                <Shimmer height="25%" width="100%" sx={{ borderRadius: "5px", mb: 2 }} />
                <Shimmer height="25%" width="85%" sx={{ borderRadius: "5px", mb: 2 }} />
                <Shimmer height="25%" width="90%" sx={{ borderRadius: "5px", mb: 2  }} />
                <Shimmer height="25%" width="50%" sx={{ borderRadius: "5px" }} />
            </Grid>
        </Grid>
    );
}

export default MockArticle;
