// xplainable dashboard base styles
import colors from "assets/theme/base/colors";

// xplainable dashboard helper functions
import rgba from "assets/theme/functions/rgba";

export const wrapMentionsInSpan = (text) => {
  const regex = /@\[([^\]]+)\]/g;

  const wrappedText = text.replace(regex, (match, mention) => {
    if (mention === "all") {
      return `<span style="background-color: ${rgba(
        colors.xpblue.main,
        0.3
      )}; border-radius: 5px; padding: 2px; color: ${colors.xpblue.main}">@${mention}</span>`;
    }

    return `<span style="background-color: ${rgba(
      colors.xppurple.main,
      0.3
    )}; border-radius: 5px; padding: 2px; color: ${colors.xppurple.main}">@${mention}</span>`;
  });

  return wrappedText;
};
