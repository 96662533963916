import RegressionPerformance from "./components/RegressionPerformance";
import BinaryPerformance from "../../../../../shared/models/BinaryPerformance/index.js";
import { useModel } from "hooks";

function Performance() {
  const { model_type } = useModel();

  console.log(model_type, "modelType");

  return (
    <>
      {model_type === "binary_classification" && <BinaryPerformance />}
      {model_type === "regression" && <RegressionPerformance />}
    </>
  );
}

export default Performance;
